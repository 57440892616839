import React, { useContext } from 'react';
import { IFNTheme, mixin } from '../../utils/theme';
import { cns, registerClass, currySC } from '@food/css-manager';
import { Button, Icon, IconPositions } from '@food/ui';
import t from '../../utils/labels';
import { loadable, LoadableDirection, loadDelayStyle } from '../../utils/loadable';
import { Link } from 'react-router-dom';
import { socialLinks } from './Header';
import mainLogo from '../../static/assets/logo.svg';
import { renderContext } from '../../utils/context/renderContext';
// import { Experiment, Variant } from 'react-optimize';
import { MATERIAL } from '@food/ui/build/dist/src/icons';

export enum footerLayouts {
	STANDARD = 'STANDARD',
	COMPACT = 'COMPACT',
	MINI = 'MINI',
}

interface IFooterProps {
	layout?: footerLayouts;
}

const styleClass = registerClass(
	(theme: IFNTheme, s) => `
	position: relative;
	line-height: ${theme.ratios.l}rem;
	padding: ${theme.ratios.l}rem;
	color: ${theme.colors.grey.light};
	background: ${theme.colors.grey.dark};

	.${s('block-divide')} {
		flex-basis: 33.333%;
		white-space: nowrap;
		display: flex;
		align-items: center;
	}

	.${s('menu')} {
		display: flex;
		padding: ${theme.ratios.s}rem 0;
		flex-direction: column;
		align-items: center;

		> a {
			color: ${theme.colors.inkWhite};
		}

		&.${s('menu-layout-MINI')} {
			padding: 0;
			padding-bottom: ${theme.ratios.l}rem;
		}
	}

	.${s('service')} {
		display: flex;
		justify-content: space-around;
		padding: ${theme.ratios.l}rem 0;
		font-weight: ${theme.font.weights.light};

		> a {
			color: ${theme.colors.inkWhite};
			text-decoration: none;
		}

		&.${s('service-layout-MINI')} {
			flex-direction: column;
			align-items: center;
			padding: 0;
			
			> a {
				margin-bottom: ${theme.ratios.l}rem;
			}
		}
	}
	
	.${s('shortcuts')} {
		display: flex;
		text-transform: uppercase;
		
		&.${s('shortcuts-layout-STANDARD')} {
			align-items: flex-start;
			margin-bottom: ${theme.ratios.l}rem;
		}
		
		&.${s('shortcuts-layout-COMPACT')}, &.${s('shortcuts-layout', footerLayouts.MINI)} {
			flex-direction: column;

			> div {
				justify-content: center;
				margin-bottom: ${theme.ratios.s}rem;
				white-space: normal;
				flex-wrap: wrap;
			}
			
			.${s('vertical-links')} {
				text-align: center;
				line-height: ${theme.ratios.l ** 2}em;
				
				> .${s('title')} {
					line-height: ${theme.ratios.xl}em;
				}
			}
			
			.${s('social')} {
				margin-top: 1rem;
			}
			
		}
		
		> div {
			flex-basis: 25%;
		}
	}
	
	.${s('logoGruppo')} {
		//flex-basis: 50%;
		display: inline-flex;
		
		> img {
			width: ${theme.ratios.l * 7}rem;
		}
	}

	.${s('social')} {
		//flex-basis: 50%
		flex-direction: row-reverse;
		> a {
			margin: 0 ${theme.ratios.xs}rem;
			font-size: ${theme.ratios.xl}em;
		}
	}

	.${s('commands')} {
		display: flex;
		justify-content: flex-end;
		flex-basis: 40%;
	}
	
	.${s('scroll-top')} {
		position: absolute;
		right: ${theme.ratios.xl}rem;
		top: -.5rem;
	}

	.${s('mini-logoGruppo')} {
		margin-left: ${theme.ratios.s}rem;
		> img {
			width: ${theme.ratios.l * 7}rem;
		}
	}

	.${s('info')} {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		font-size: ${theme.ratios.xs}rem;
		padding-top: ${theme.ratios.xs}rem;
		border-top: 1px solid ${theme.colors.grey.plain};

		.${s('contacts')} {
			display: flex;
			margin-right: auto;

			> a {
				color: ${theme.colors.inkWhite};
				margin-right: ${theme.ratios.l}rem;
			}
		}

		.${s('address')} {
			text-align: center;
			flex-shrink: 1;
		}
		
		.${s('copyright')} {
			justify-content: flex-end;
		}

		&.${s('info-layout-MINI')} {
			flex-direction: column;
			align-items: center;

			> .${s('contacts')} {
				margin: 0;
				flex-wrap: wrap;
				justify-content: center;

				> a {
					margin-left: ${theme.ratios.l}rem;
				}
			}

			> div {
				white-space: normal;
				text-align: center;
			}
		}
	}
	
	// nota bene: questi comportamenti sono il default ma vengono sovrascritti nei layout COMPACT e MINI, poco sopra, in definizioni annidate
	.${s('vertical-links')} {
		display: flex;
		flex-direction: column;
		line-height: ${theme.ratios.l ** 3}em;
		
		> .${s('title')} {
			font-size: ${theme.ratios.l}em;
			font-weight: ${theme.font.weights.semibold};
			line-height: ${theme.ratios.xl ** 2}em;
		}
	}
`,
);

export const footerLayoutChooser = (width: number) => {
	if (width > 600 && width < 1000) {
		return footerLayouts.COMPACT;
	} else if (width >= 1000) {
		return footerLayouts.STANDARD;
	}
	return footerLayouts.MINI;
};

const sc = currySC(styleClass);

export const Footer: React.FC<IFooterProps> = ({ layout = footerLayouts.STANDARD }) => {
	const { isBot } = useContext(renderContext);

	return (
		<footer
			className={cns(styleClass, loadable(isBot, LoadableDirection.BOTTOM))}
			style={loadDelayStyle(0.3)}
		>
			<Button
				label={layout === footerLayouts.STANDARD ? t`Back to top` : undefined}
				iconPos={layout === footerLayouts.STANDARD ? IconPositions.RIGHT : undefined}
				icon={'arrow_upward'}
				className={sc('scroll-top')}
				onClick={() => {
					window.scroll({
						top: 0,
						left: 0,
						behavior: 'smooth',
					});
				}}
			/>
			<div
				className={cns(
					mixin.limitedWidthPage,
					sc('shortcuts'),
					sc('shortcuts-layout', layout),
				)}
			>
				<div className={cns(sc('block-divide'), sc('logoGruppo'))}>
					<img src={mainLogo} alt={'logo italianfood.net'} />
				</div>

				<div className={sc('vertical-links')}>
					<div className={sc('title')}>{t`Sitemap`}</div>
					<Link to={'/buyers-introduction'}>{t`For Buyers`}</Link>
					<Link to={'/suppliers-introduction'}>{t`For Suppliers`}</Link>
					<Link to={'/tradeshows'}>{t`Tradeshows`}</Link>
					<Link to={'/about-us'}>{t`About us`}</Link>
					<Link to={'/ads'}>{t`Contact us`}</Link>
				</div>

				<div className={sc('vertical-links')}>
					<div className={sc('title')}>{t`Browse our sites`}</div>
					<a href={'https://corporate.italianfood.net/'}>{t`Corporate`}</a>
					<a href={'https://news.italianfood.net/'}>{t`News`}</a>
					<a href={'https://www.italianfoodshowcase.net/'}>{t`Showcase`}</a>
					<a href={'https://news.italianfood.net/magazines/'}>{t`Magazine`}</a>
				</div>

				<div className={cns(sc('block-divide'), sc('social'))}>
					{socialLinks.map(({ label, link, icon, bg }, i) => (
						<a href={link} key={i} title={t(label)} rel="nofollow">
							<Icon name={icon as MATERIAL} />
						</a>
					))}
				</div>
			</div>
			<div
				className={cns(mixin.limitedWidthPage, sc('info'), sc('info-layout', layout))}
			>
				<div className={cns(sc('block-divide'), sc('contacts'))}>
					<Link to={'/about-us'}>{t`Contacts`}.</Link>{' '}
					<Link to={'/privacy-policy'}>{t`Privacy Policy`}.</Link>
				</div>
				<div className={cns(sc('block-divide'), sc('address'))}>
					Food Srl Via Mazzini, 6 - 43121 Parma - Italy | P.IVA: 01756990345
				</div>
				<div className={cns(sc('block-divide'), sc('copyright'))}>
					Copyright © 2015-{new Date().getFullYear()} Food S.r.l. - All rights
					reserved
				</div>
			</div>
		</footer>
	);
};
