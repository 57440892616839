import React, { useContext } from 'react';
import { Fair, FrequentSearch, FrequentSearchCollection } from '../server-types';
import { IFNTheme, mixin } from '../utils/theme';
import t from '../utils/labels';
import { Link } from 'react-router-dom';
import { cns, currySC, registerClass } from '@food/css-manager';
import filterImage from '../static/assets/filter.svg';
import { loadable, LoadableDirection } from '../utils/loadable';
import { renderContext } from '../utils/context/renderContext';
import { Button, Collapsible } from '@food/ui';
import { ENTITY } from '../utils/entities';
import { addWhere, useTracking, WhereBlock } from '../utils/tracking';
import { SearchTargetType, TWhere } from '../tracking-types';
import { ACTIONS, generateUrl } from '../utils/urls';
import { getFrequentSearchUrl } from './entities/FrequentSearchCard';

export enum SuggestedFSCardLayout {
	STANDARD = 'standard',
	COMPACT = 'compact',
}

interface ISuggestedFSCardProps {
	fs: ReadonlyArray<FrequentSearch>;
	layout: SuggestedFSCardLayout;
}

const registerColorClass = (color: string) =>
	registerClass(
		(t: IFNTheme) => `
	background-color: ${t.colors[color].plain};
	color: ${t.colors[color].light};
`,
	);

const colorClasses = {
	alt: registerColorClass('alt'),
	primary: registerColorClass('primary'),
};

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	box-shadow: ${t.boxShadow(1)};
	
	&.${sc('layout', 'standard')} {
		padding: ${t.ratios.xl}rem;
	
		&:after {
			content: "";
			background-image: url("${filterImage}");
			opacity: 0.5;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			position: absolute;
			z-index: -1;
			background-position: top left;
			background-repeat: no-repeat;
			background-size: 30%;
		}
	
		.${sc('content')} {
			margin-left: 30%;
			margin-top: ${t.ratios.l ** 2}rem;
		}
	}
	
	&.${sc('layout', 'compact')} {
		padding: ${t.ratios.s}rem;
		
		.${sc('expand-state-icon')} {
		 
		   margin-top: 0;
		 
			button {
				padding-top: 0;
			}
		}
	}
	
	.${sc('no-open-row')} {
		margin-bottom: ${t.ratios.l ** 2}rem;
	}
	
	.${sc('title')} {
		font-size: ${t.ratios.xl}em;
		font-weight: ${t.font.weights.bold};
		margin-bottom: ${t.ratios.xs}rem;
	}
	
	.${sc('see-more')} {
		margin-top: ${t.ratios.xl}rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-style: italic;
	}
	
	.${sc('tags')} {
		list-style-type: square;
		margin-left: ${t.ratios.l}em;
		word-break: break-word;
		
		>li {
			margin-bottom: .4em;
		}
		
		.${sc('tag')} {
			line-height: 1em;
			font-size: 1.1em;
			
			&:hover {
				text-decoration: underline;
			}
		}
	}
	
	.${sc('expand-state-icon')} {
		margin-top: ${t.ratios.xs}rem;
		text-align: center;
		font-size: ${t.ratios.xl}rem;
		
		> button {
			background-color: transparent;
			color: inherit;
			padding-bottom: 0;
			line-height: 1;
		}
	}
`,
);

const sc = currySC(styleClass);
const closedLimit = 3;
const maxClosedLimit = 10;

export const SuggestedFSCard: React.FC<ISuggestedFSCardProps> = ({ fs, layout }) => {
	const { isBot } = useContext(renderContext);
	const track = useTracking();

	if (fs === undefined || fs.length === 0) {
		return null;
	}
	const forcedOpen = fs.length <= closedLimit + 1;
	const hasRow = !forcedOpen;
	const where: TWhere = { type: 'Block', name: 'SuggestedFSCard' };
	const fairMode = fs.every((f) => f.relatedFair);
	const color = fairMode ? 'primary' : 'alt';
	let fair: Fair | null = null;
	if (fairMode) {
		fair = fs[0].relatedFair;
	}
	const cardTitle =
		fairMode && fair
			? `${fair.name} ${fair.year}${fair.edition ? ' - ' + fair.edition : ''}`
			: t`Related Searches` + ':';

	return (
		<WhereBlock step={where}>
			<Collapsible startOpen={false}>
				{({ open: collapsibleOpen, expand, collapse, CollapsibleBlockClass }) => {
					const open = forcedOpen || collapsibleOpen;

					return (
						<div
							className={cns(
								mixin.directoryCard,
								styleClass,
								loadable(isBot, LoadableDirection.BOTTOM),
								colorClasses[color],
								sc('layout', layout),
							)}
							onClick={() => {
								track(
									{ type: open ? 'close' : 'open', what: where },
									addWhere(where),
								);
								(open ? collapse : expand)();
							}}
						>
							<div className={cns(sc('content'), !hasRow && sc('no-open-row'))}>
								<div className={sc('title')}>
									{fairMode ? (
										<Link
											to={generateUrl(ENTITY.FAIR, ACTIONS.DETAIL, fair.slug)}
										>
											{cardTitle}
										</Link>
									) : (
										cardTitle
									)}
								</div>
								<ul className={sc('tags')}>
									{fs.slice(0, closedLimit).map((f) => (
										<li key={f.id}>
											<Link to={getFrequentSearchUrl(f)} className={sc('tag')}>
												{f.name}
											</Link>
										</li>
									))}
								</ul>
								<CollapsibleBlockClass>
									{open && (
										<ul className={sc('tags')}>
											{fs
												.slice(
													closedLimit,
													Math.max(fs.length, maxClosedLimit),
												)
												.map((f) => {
													let target: SearchTargetType =
														f.collection ===
														FrequentSearchCollection.Company
															? 'CompanyDirectory'
															: 'GoodDirectory';
													if (f.linkUrl) {
														target = 'FrequentSearchDirectory';
													}
													return (
														<li key={f.id}>
															<Link
																to={getFrequentSearchUrl(f)}
																className={sc('tag')}
																onClick={(event) => {
																	// @ts-ignore
																	track(
																		{
																			type: 'do_search',
																			target,
																			filterStatus: {
																				FrequentSearch: f.id,
																			},
																		},
																		addWhere(where),
																	);
																	event.stopPropagation();
																}}
															>
																{f.name}
															</Link>
														</li>
													);
												})}
										</ul>
									)}
								</CollapsibleBlockClass>

								{hasRow && (
									<div className={sc('expand-state-icon')}>
										<Button
											icon={open ? 'expand_less' : 'expand_more'}
											onClick={(event) => {
												event.preventDefault();
												event.stopPropagation();
												track(
													{ type: open ? 'close' : 'open', what: where },
													addWhere(where),
												);
												(open ? collapse : expand)();
											}}
										/>
									</div>
								)}
							</div>
						</div>
					);
				}}
			</Collapsible>
		</WhereBlock>
	);
};
