import React, { useCallback, useContext, useMemo } from 'react';
import { renderContext } from '../utils/context/renderContext';
import { loadable, LoadableDirection } from '../utils/loadable';
import { IFNTheme } from '../utils/theme';
import t from '../utils/labels';
import { Button, ButtonLayouts } from '@food/ui';
import { openRenewModal } from '@food/auth';
import { registerClass, cns } from '@food/css-manager';
import { loginMessageFactory } from './LoginMessage';
import { ACTIONS, generateUrl } from '../utils/urls';
import { ENTITY } from '../utils/entities';

interface IForbiddenFiltersErrorProps {
	entityType: ENTITY;
	notBuyerMode: boolean;
}

const forbiddenError = registerClass(
	(t: IFNTheme) => `
	box-shadow: ${t.boxShadow(1)};
	background: ${t.colors.white};
	padding: ${t.ratios.xl}rem;
	text-align: center;
	font-size: ${t.ratios.l}rem;
	
	> p {
		margin-bottom: ${t.ratios.l}rem;
	}
		
	a, button {
		margin-left: 1em;
	}
`,
);

export const ForbiddenFiltersError: React.FC<
	IForbiddenFiltersErrorProps
> = ({ entityType, notBuyerMode }) => {
	const { isBot } = useContext(renderContext);
	const openLogin = useCallback(
		() => openRenewModal({ customComponent: loginMessageFactory() }),
		[],
	);
	const resetUrl = useMemo(() => generateUrl(entityType, ACTIONS.LIST), [entityType]);
	const explanation =
		'Not enough permission for these filters - not ' +
		(notBuyerMode ? 'buyer' : 'logged');

	return (
		<div className={cns(forbiddenError, loadable(isBot, LoadableDirection.BOTTOM))}>
			{t(explanation)}.{' '}
			<div>
				{!notBuyerMode && <Button label={t`Sign in`} onClick={openLogin} />}
				<Button
					layout={ButtonLayouts.BUTTON}
					linkTo={resetUrl}
					label={t`Reset filters`}
				/>
			</div>
		</div>
	);
};
