import React from 'react';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { IFNTheme } from '../../utils/theme';
import {
	mediaImgFragment,
	layouts as MediaLayouts,
	MediaImg,
} from '../../components/entities/MediaImg';
import { cns, currySC, registerClass } from '@food/css-manager';
import { EditorialSection, MediaType } from '../../server-types';

// typing
interface Props {
	entities: ReadonlyArray<EditorialSection>;
	activeEntities?: ReadonlyArray<EditorialSection>;
	className?: string;
	layout?: EditorialSectionBlockLayouts;
	decoration?: Decorations;
	getLinkTo?: (any) => string | undefined | { pathname: string; search?: string };
	limit?: number;
	inline?: boolean;
}

export enum EditorialSectionBlockLayouts {
	FIRST_ICONTEXT = 'FIRST_ICONTEXT', // Mostra icona e testo della prima, nei box (con sfondo con i triangolini)
	FIRST_TEXTONLY = 'FIRST_TEXTONLY', // Mostro solo il testo della prima, usato in news (sfondo colorato, scritta bianca),
	ICONTEXT_OR_ICONS = 'ICONTEXT_OR_ICONS',
	ICONS_ONLY = 'ICONS_ONLY', // mostra tutte le icone in fila, usato in card Company e Brand quando chiuse
	ICONS_TEXTS = 'ICONS_TEXTS', // mostra tutte le icone e i testi, usato nelle cards in forma aperta
	ICONS_ONLY_OVER = 'ICONS_ONLY_OVER', // come icons_only, ma con
}

export enum Decorations {
	NONE = 'NONE',
	DOTTED_PADDING = 'DOTTED_PADDING', // con sfondo con i triangolini colorati
	// PLAIN = 'PLAIN', // sfondo colorato, scritta bianca
}

// graphql fragment
export const Fragment = gql`
	fragment EditorialSectionBlockFragment on EditorialSection {
		id
		name
		editorialBrand
		color
		iconMedia {
			...MediaImgFragment
		}
		styleClass
		Parent {
			id
			name
			editorialBrand
			color
			iconMedia {
				...MediaImgFragment
			}
			styleClass
			Parent {
				id
				name
				editorialBrand
				color
				iconMedia {
					...MediaImgFragment
				}
				styleClass
				Parent {
					id
					name
					editorialBrand
					color
					iconMedia {
						...MediaImgFragment
					}
					styleClass
				}
			}
		}
	}
	${mediaImgFragment}
`;

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	&, * {
		line-height: 1
	}
	
	.${sc('block')} {
		display: inline-flex;
		align-items: center;
	}
	
	.${sc('logo')} {
		font-size: 2em;
	}
	
	.${sc('label')} {
		margin-left: 0.5em;
	}
	
	&.${sc('layout', 'FIRST_TEXTONLY')} {
		margin-left: 0;
	}
	
	&.${sc('layout', 'ICONTEXT_OR_ICONS')},
	&.${sc('layout', 'ICONS_ONLY')},
	&.${sc('layout', 'FIRST_ICONTEXT')} {

		.${sc('blockContainer')} {
			display: inline-block;
		}
		
		.${sc('block')} {
			padding-right: ${t.ratios.xs / 2}em;

			&:last-child {
				padding-right: 0;
			}
		}
	}
	
	&.${sc('layout', 'ICONS_TEXTS')} {
	
		.${sc('blockContainer')} {
			display: block;
		}
		
		.${sc('block')} {
			display: flex;
			margin-bottom: ${t.ratios.xs / 2}em;
			
			&:last-child {
				padding-bottom: 0;
			}
		}
	}
	
	&.${sc('layout', 'ICONS_TEXTS')}.${sc('inline')} {
		.${sc('block')} {
			display: inline-flex;
			margin-right: 1rem;
			
			&:last-child {
				margin-right: 0;
			}
		}
	}
	
	&.${sc('decoration', 'DOTTED_PADDING')} {
		margin-top: ${t.ratios.l}rem;
		padding: ${t.ratios.l / 2}rem;
		padding-top: 0;
		background-image: url(${t.assets.patternTriangle}); /* , linear-gradient(270deg, ${
		t.colors.primary.plain
	}, ${t.colors.alt2.plain}); */
		background-color: ${t.colors.primary.plain};

		.${sc('blockContainer')} {
			padding: 0.3rem; // FIXME - numero arbitrario
			background: ${t.colors.white};
		}
	}
	
	.${sc('disabled')} {
		filter: grayscale(100%);
	}
	
	.${sc('spaced-label')} {
		margin-left: 2.5em;
		font-style: italic;
	}
	
	&.${sc('layout', 'ICONS_ONLY_OVER')} {
		.${sc('block')} {
			padding: ${t.ratios.xs / 4}rem;
			background-color: ${t.colors.white};
			border-radius: 50%;
		}
	}

`,
);

const sc = currySC(styleClass);

/**
 * Dato una EditorialSection restituisce la radice del suo albero di genitori
 *
 * @param e: EditorialSection
 * @return EditorialSection
 */
const getEsParent = (e: EditorialSection): EditorialSection =>
	e.Parent ? getEsParent(e.Parent) : e;

export const EditorialSectionBlock: React.FC<Props> = ({
	entities,
	activeEntities,
	className,
	layout = EditorialSectionBlockLayouts.ICONTEXT_OR_ICONS,
	decoration = Decorations.NONE,
	getLinkTo,
	limit = entities.length,
	inline,
}) => {
	if (!entities || entities.length === 0) {
		return null;
	}

	const es: ReadonlyArray<EditorialSection> = entities
		.filter((e) => e.editorialBrand === 'IFN')
		.map(getEsParent)
		.filter((es) => es.iconMedia)
		.reduce((res, es: EditorialSection) => {
			if (res.some((les) => les.id === es.id)) {
				return res;
			} else {
				return res.concat([es]);
			}
		}, []);

	const activeIds =
		activeEntities && activeEntities.length
			? activeEntities.map(getEsParent).map((ent) => ent.id)
			: undefined;

	const isActive = (item) =>
		activeIds === undefined || activeIds.indexOf(item.id) !== -1;

	const getBlock = (item, children) => {
		const className = cns(
			sc('block'),
			activeIds && activeIds.indexOf(item.id) === -1 && sc('disabled'),
		);
		const linkTo = getLinkTo && getLinkTo(item);
		return linkTo ? (
			<Link key={item.id} className={className} to={linkTo}>
				{children}
			</Link>
		) : (
			<div key={item.id} className={className}>
				{children}
			</div>
		);
	};

	return (
		<div
			className={cns(
				className,
				styleClass,
				sc('layout', layout),
				sc('decoration', decoration),
				inline && sc('inline'),
			)}
		>
			<div className={sc('blockContainer')}>
				{layout === EditorialSectionBlockLayouts.FIRST_TEXTONLY &&
					es[0] &&
					getBlock(es[0], <span className={sc('label')}>{es[0].name}</span>)}

				{((layout === EditorialSectionBlockLayouts.FIRST_ICONTEXT && es[0]) ||
					(layout === EditorialSectionBlockLayouts.ICONTEXT_OR_ICONS &&
						es.length === 1)) &&
					getBlock(
						es[0],
						<>
							{es[0].iconMedia && (
								<MediaImg
									type={MediaType.Icon}
									entity={es[0].iconMedia}
									className={sc('logo')}
									layout={MediaLayouts.ICON}
									alt={`Editorial Section "${es[0].name}" icon`}
								/>
							)}
							<span className={sc('label')}>{es[0].name}</span>
						</>,
					)}

				{((layout === EditorialSectionBlockLayouts.ICONTEXT_OR_ICONS &&
					es.length > 1) ||
					layout === EditorialSectionBlockLayouts.ICONS_ONLY ||
					layout === EditorialSectionBlockLayouts.ICONS_ONLY_OVER) &&
					es.map((item) =>
						getBlock(
							item,
							<>
								{item.iconMedia && (
									<MediaImg
										type={MediaType.Icon}
										entity={item.iconMedia}
										className={sc('logo')}
										layout={MediaLayouts.ICON}
										alt={`Editorial Section "${item.name}" icon`}
									/>
								)}
							</>,
						),
					)}

				{layout === EditorialSectionBlockLayouts.ICONS_TEXTS && (
					<>
						{es
							.filter(isActive)
							.filter((e, i) => i < limit)
							.map((item) =>
								getBlock(
									item,
									<>
										{item.iconMedia && (
											<MediaImg
												type={MediaType.Icon}
												entity={item.iconMedia}
												className={sc('logo')}
												layout={MediaLayouts.ICON}
												alt={`Editorial Section "${item.name}" icon`}
											/>
										)}
										<span className={sc('label')}>{item.name}</span>
									</>,
								),
							)}
						{es.filter(isActive).length > limit && (
							<span className={sc('spaced-label')}>... and more</span>
						)}
					</>
				)}
			</div>
		</div>
	);
};
