import React, { RefObject } from 'react';
import { UserData } from '@food/auth';
import { Query } from 'react-apollo';
import t from '../utils/labels';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { SearchTypes } from '../utils/frequentSearch';
import { Dropdown, Icon } from '@food/ui';
import { ACTIONS, generateUrl } from '../utils/urls';
import { ENTITY } from '../utils/entities';
import { registerClass } from '@food/css-manager';
import { IFNTheme } from '../utils/theme';
import { currySC } from '@food/css-manager';
import { stringify } from 'querystring';
import { nodes, pluralEntityLabel } from '../utils/misc';
import { UserLists } from './UserLists';
import {
	getFrequentSearchUrl,
	FrequentSearchLinkFragment,
} from './entities/FrequentSearchCard';
import { ApolloError } from 'apollo-client';

const searchFragment = gql`
	fragment searchFragment on FrequentSearchConnection {
		edges {
			node {
				id
				name
				collection
				linkUrl
				...FrequentSearchLink
			}
		}
	}
	${FrequentSearchLinkFragment}
`;

export const frequentSearchQuery = gql`
	query FrequestSearchQuery($filter: FrequentSearchFilterInput) {
		notMine: frequentSearchs(filter: $filter, first: 10) {
			...searchFragment
		}
	}
	${searchFragment}
`;

export const frequentSearchWithMineQuery = gql`
	query FrequestSearchWithMineQuery(
		$filterMine: FrequentSearchFilterInput
		$filterNotMine: FrequentSearchFilterInput
	) {
		notMine: frequentSearchs(filter: $filterNotMine, first: 10) {
			...searchFragment
		}
		mine: frequentSearchs(filter: $filterMine, first: 10) {
			...searchFragment
		}
	}
	${searchFragment}
`;

const searchStyle = registerClass(
	(t: IFNTheme, sc) => `
	background-color: ${t.colors.white};
	border: 1px solid ${t.colors.grey.plain};
	line-height: ${t.ratios.xl}rem;
	font-size: 1rem;
	
	.${sc('text-search')} {
		background-color: ${t.colors.grey.light};padding-left: ${t.ratios.l / 2}rem;
		padding-top: ${t.ratios.l / 3}rem;
		padding-bottom: ${t.ratios.l / 3}rem;
		
	}
	
	.${sc('divider')} {
		color: ${t.colors.grey.dark};
		text-transform: uppercase;
		font-style: italic;
		font-weight: ${t.font.weights.bold};
		padding-top: ${t.ratios.l / 3}rem;
	}
	
	> div, .${sc('text-search')} {
		padding-left: ${t.ratios.l / 3}rem;
	}
	
	.${sc('active')} {
		color: ${t.colors.primary.plain};
	}
`,
);

const sc = currySC(searchStyle);

interface IFrequentSearchResultProps {
	open: boolean;
	element: RefObject<any>;
	textSearch: string;
	searchType: SearchTypes;
	onClickOutside: (event) => void;
	close: () => void;
}

export const FrequentSearchResult: React.FC<IFrequentSearchResultProps> = ({
	open,
	element,
	textSearch,
	searchType,
	onClickOutside,
	close,
}) => {
	const fsClickHandler = (id: string) => () => close();

	return (
		<Dropdown open={open} element={element} onClickOutside={onClickOutside}>
			<UserData>
				{({ isLogged }) => (
					<UserLists>
						{({ favoritesCompanies, favoritesGoods }) => (
							<Query
								query={
									isLogged ? frequentSearchWithMineQuery : frequentSearchQuery
								}
								skip={!textSearch}
								variables={
									isLogged
										? {
												filterMine: {
													name: textSearch,
													collection: searchType,
													mine: true,
												},
												filterNotMine: {
													name: textSearch,
													collection: searchType,
													mine: false,
												},
										  }
										: {
												filter: {
													name: textSearch,
													collection: searchType,
												},
										  }
								}
							>
								{({
									error,
									loading,
									data,
								}: {
									loading: boolean;
									error?: ApolloError;
									data: any;
								}) => {
									if (loading) {
										return null; // TODO
									}

									if (error) {
										console.error(error); // TODO
										return null;
									}

									if (!data) {
										return null;
									}

									const entity =
										searchType === SearchTypes.GOOD
											? ENTITY.GOOD
											: ENTITY.COMPANY;

									return (
										<div tabIndex={1}>
											<div className={searchStyle}>
												<Link
													to={{
														pathname: generateUrl(entity, ACTIONS.LIST),
														search: stringify({
															textSearch,
														}),
													}}
												>
													<div className={sc('text-search')}>
														Search for text <strong>"{textSearch}"</strong>{' '}
														in{' '}
														<strong>{t(pluralEntityLabel(entity))}</strong>
													</div>
												</Link>

												<div>
													<ul>
														{nodes(data.notMine).map((f) => {
															return (
																<li key={f.id}>
																	<Link
																		onClick={fsClickHandler(f.id)}
																		to={getFrequentSearchUrl(f)}
																	>
																		{f.name}
																	</Link>
																</li>
															);
														})}
													</ul>
												</div>

												{data.mine &&
													Array.isArray(data.mine) &&
													data.mine.length > 0 && (
														<div>
															<div className={sc('divider')}>
																{t(`Your lists`)}:
															</div>
															<ul>
																{nodes(data.mine).map((f) => {
																	const isFavourite =
																		f.id === favoritesCompanies.id ||
																		f.id === favoritesGoods.id;
																	return (
																		<li key={f.id}>
																			<Link
																				onClick={fsClickHandler(f.id)}
																				to={getFrequentSearchUrl(
																					entity,
																				)}
																			>
																				<Icon
																					className={
																						isFavourite
																							? sc('active')
																							: ''
																					}
																					name={
																						isFavourite
																							? 'favorite'
																							: 'youtube_searched_for'
																					}
																				/>{' '}
																				{f.name}
																			</Link>
																		</li>
																	);
																})}
															</ul>
														</div>
													)}
											</div>
										</div>
									);
								}}
							</Query>
						)}
					</UserLists>
				)}
			</UserData>
		</Dropdown>
	);
};
