import React, { useContext } from 'react';
import { cns, registerClass, currySC } from '@food/css-manager';
import { IFNTheme, mixin } from '../../utils/theme';
import { loadable, LoadableDirection } from '../../utils/loadable';
import t from '../../utils/labels';
import { renderContext } from '../../utils/context/renderContext';
import { GenericHead } from '../head/GenericHead';
import { WhereBlock } from '../../utils/tracking';
import { openRenewModal, UserData } from '@food/auth/build/dist';
import { Button } from '@food/ui/build/dist';

export enum AboutUsLayouts {
	Main = 'Main',
}

interface IAboutUsProps {
	layout?: AboutUsLayouts;
	className?: string;
}

const styleClass = registerClass(
	(theme: IFNTheme, s) => `
	.${s('subtitle')} {
		margin: 0 ${theme.ratios.xl}rem ${theme.ratios.xl}rem;
		font-size: ${theme.ratios.xl}rem;
		line-height: ${theme.ratios.l};
	}
	
	.${s('instant-action')} {
		margin-top: ${theme.ratios.xl}rem;
		margin-bottom: ${theme.ratios.xl - 1}rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: ${theme.ratios.xl}em;
	}
	
	h2 {
		font-size: ${theme.ratios.xl}em;
		margin-top: 2em;
		margin-bottom: .5em;
		text-align: center;
	}
	
	h3 {
		font-weight: ${theme.font.weights.semibold};
		margin-top: 1em;
		margin-bottom: .5em;
	}
	
	p {
		margin-top: 0;
		margin-bottom: 0;
		line-height: 1.5em;
	}
	
	.${sc('divider')} {
		margin-top: 2em;
		margin-bottom: 1em;
		font-size: ${theme.ratios.l}em;
		font-style: italic;
	}
	
	.${sc('divider2')} {
		margin-top: 1em;
		margin-bottom: .5em;
		font-size: ${theme.ratios.l}em;
		font-style: italic;
	}
	
	ul {
		padding-left: 1em;
		margin-bottom: .5em;
	}
	
	li {
		margin-top: .25em;
		margin-bottom: .25em;
	}
`,
);

const sc = currySC(styleClass);

export const AboutUs: React.FC<IAboutUsProps> = ({
	className,
	layout = AboutUsLayouts.Main,
}) => {
	const { isBot } = useContext(renderContext);

	return (
		<UserData>
			{({ isLogged }) => {
				return (
					<WhereBlock step={{ type: 'Page', name: 'About us' }}>
						<GenericHead title={t`About Us`} />
						<div
							className={cns(
								mixin.textPage,
								styleClass,
								sc('layout', layout),
								mixin.limitedWidthPage,
								loadable(isBot, LoadableDirection.BOTTOM),
								className,
							)}
						>
							<h1>
								<span>About us</span>
							</h1>
							<div className={sc('subtitle')}>
								ItalianFOOD.net is the first platform entirely devoted to Italian
								food &amp; Beverage sector: it is an integrated tool for buyers
								and food lovers looking for great products and valuable partners.
							</div>
							<h2>OUR HISTORY</h2>
							<p>
								Gruppo Food is an Italian publishing house with over 30 years of
								leadership in B2B communication in the food sector. Our
								headquarters are on the main central square of Parma, the capital
								of Italy’s food valley.
							</p>
							<p>
								ItalianFOOD.net is the group’s international brand founded in 2014
								to report about the activities of Italian food companies in
								international markets.
							</p>
							<p>
								With an expert editorial team and key business contacts around the
								world, ItalianFOOD.net provides reliable, strategic market
								information and in-depth analyses of global food and beverage
								trends, corporate news and product launches from the most
								important Italian manufacturers.
							</p>
							<p>
								With exclusive interviews and reporting from the United States,
								Europe, Middle East and Asia, ItalianFOOD.net is aimed at expert
								readers who want to stay updated on the key issues, latest news
								and consumer trends in all the major food and beverage sectors.
							</p>
							<div className={sc('divider')}>ItalianFOOD.net includes:</div>
							<h3>MAGAZINE</h3>
							<p>
								The BIMONTHLY issues offer analytical content about all the main
								categories (pasta, olive oil, dairy, condiments, etc) and
								sub-categories of Italian food in international markets.
							</p>
							<p>
								The editorial plan offers buyers exclusive information on emerging
								trends and the most innovative products launched by Italian
								companies in international markets, divided by category.
							</p>
							<h3>NEWSFEED</h3>
							<p>
								Our newsfeed news.italianfood.net offers comprehensive analyses
								through directories, interviews, facts, opinions, video, market
								studies and case histories.
							</p>
							<h3>PLATFORM</h3>
							<p>
								The directory reserved to professionals allows to:
								<ul>
									<li>
										Search efficiently new authentic suppliers and products,
									</li>
									<li>Save and compare the most interesting prospects;</li>
									<li>Ask for a sample and information from the suppliers</li>
									<li>Ask us dedicated research about suppliers/products</li>
									<li>Create lists of products and prospect suppliers</li>
								</ul>
							</p>
							<p>The platform is integrated with the newsfeed and the magazine.</p>
							<h3>AWARDS</h3>
							<p>
								Launched in 2015, Gruppo FOOD promotes and organizes the ITALIAN
								FOOD AWARDS.
							</p>
							<p>
								For every edition, we collect entries from Italian companies that
								have launched innovative quality products in international markets
								and submit them to a jury of buyers of the Italianfood.net
								network.
							</p>
							<p>
								The awarding ceremony takes place every year in the month of
								October (during the major ANUGA and SIAL food shows).
							</p>
							<p>
								The US edition of the ITALIAN FOOD AWARDS was launched in 2017 and
								takes place in June during the Summer Fancy Food Show, America’s
								largest specialty food trade fair.
							</p>
							<h3>EVENTS – SHOWCASE</h3>
							<p>
								Over the years, the ItalianFOOD.net team has developed special
								events dedicated to retailers and foodservice companies around the
								world.
							</p>
							<h2>ARE YOU A PROFESSIONAL?</h2>
							<p>
								The service is entirely free for: buyers, importers, distributors
								and brokers.
							</p>
							<p>
								<div className={sc('divider2')}>Subscribe to:</div>
								<ul>
									<li>
										Receive the bimonthly magazine (digital or paper issue)
									</li>
									<li>Use the platform (unlimited filters for your searches)</li>
									<li>
										Ask for information and samples from suppliers, privately
										and anonymously
									</li>
									<li>Receive our newsletter and reports</li>
									<li>Be part of our Italian Food Awards Jury</li>
									<li>Receive invitations to our exclusive events</li>
								</ul>
							</p>
							{!isLogged && (
								<div className={sc('instant-action')}>
									<Button
										label={t`JOIN NOW`}
										onClick={() => openRenewModal()}
										type={'success'}
									/>
								</div>
							)}
						</div>
					</WhereBlock>
				);
			}}
		</UserData>
	);
};
