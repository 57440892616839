import React from 'react';
import { registerClass, IFNTheme, currySC } from '@food/css-manager';

interface InstantAction {
	title: string;
	content: string;
	image: string;
}

interface ThreeColumnsInstantActionProps {
	action1: InstantAction;
	action2: InstantAction;
	action3: InstantAction;
}

const styleClass = registerClass((theme: IFNTheme, s) => `
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2rem;

    .${s('item')} {
        padding: ${theme.ratios.xl}rem;
        width: calc(100% / 3);
        padding: 0 2rem;
        text-align: center;

        .${s('imageContainer')} {

            .${s('imageWrapper')} {
                width: 5rem
                height: 5rem;
                border-radius: 100%;
                background:#d12e26;
                
                margin: 0 auto 1.2em;
                display: flex;
                align-items: center;
                justify-items: center;

                img {
                    margin: auto;
                    display: block;
                    height: 3rem;
                }
            }
        }

        .${s('textWrapper')} {
            max-width: 350px;
            margin: auto;

            h2 {
                margin-bottom: 0.9em;
                font-size: 1.5rem;
            }
            p {
                font-size: 1.3rem;
                color: #706c6c;
                opacity: 0.8;
                line-height: 1.5;
            }

            > * {
                margin-top: 0;
                margin-bottom: 0.5rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        @media all and (max-width: ${theme.breakpoints.tabletMax}px) {
            width: 50%;
            padding: 2rem 1rem 0 1rem;

            &:nth-child(1), &:nth-child(2) {
                padding-top: 0;
            }
        }

        @media all and (max-width: ${theme.breakpoints.mobileMax}px) {
            width: 100%;
            padding: 2rem 1rem 0 1rem;

            &:nth-child(1) {
                padding-top: 0;
            }
            &:nth-child(2) {
                padding-top: 2rem;
            }
        }
        
    }

    @media all and (max-width: ${theme.breakpoints.tabletMax}px) {
        margin: 0 -1rem;
        justify-content: center;
    }
  `,
);

const sc = currySC(styleClass);

export const ThreeColumnsInstantAction: React.FC<ThreeColumnsInstantActionProps> = ({
	action1,
	action2,
	action3,
}) => {

    

    return <section className={styleClass}>
        <div className={sc('item')}>
            <div className={sc('imageContainer')}>
                <div className={sc('imageWrapper')}><img src={action1.image} /></div>
            </div>
            <div className={sc('textWrapper')}>
                <h2>{action1.title}</h2>
                <p dangerouslySetInnerHTML={{__html: action1.content}} />
            </div>
        </div>
        <div className={sc('item')}>
        <div className={sc('imageContainer')}>
                <div className={sc('imageWrapper')}><img src={action2.image} /></div>
            </div>
            <div className={sc('textWrapper')}>
                <h2>{action2.title}</h2>
                <p dangerouslySetInnerHTML={{__html: action2.content}} />
            </div>
        </div>
        <div className={sc('item')}>
        <div className={sc('imageContainer')}>
                <div className={sc('imageWrapper')}><img src={action3.image} /></div>
            </div>
            <div className={sc('textWrapper')}>
                <h2>{action3.title}</h2>
                <p dangerouslySetInnerHTML={{__html: action3.content}} />
            </div>
        </div>
    </section>
}
