import React from 'react';
import gql from 'graphql-tag';
import { cachedQueryFactory } from './factories/cachedQueryFactory';
import { ENTITY } from '../utils/entities';
import { client } from '../utils/client';
import { nodes } from '../utils/misc';

export const userListsQuery = gql`
	query UserListsQuery {
		frequentSearchs(filter: { mine: true, isDynamic: false }) {
			edges {
				node {
					id
					goods {
						id
					}
					companies {
						id
					}
					collection
					systemType
				}
			}
		}
	}
`;

const editFavMutation = gql`
	mutation addToFavouritesMutation(
		$refs: [RefInputObject!]!
		$addFavs: [RefInputObject!]
		$removeFavs: [RefInputObject!]
	) {
		frequentSearchAddRemoveItems(
			items: $refs
			addToFrequentSearches: $addFavs
			removeFromFrequentSearches: $removeFavs
		)
	}
`;

const { consumer: Consumer, reset } = cachedQueryFactory({
	query: userListsQuery,
	ttl: 600000,
	needLogin: true,
});

interface UserListChildrenProps {
	loading: boolean;
	favoritesGoods: any;
	favoritesCompanies: any;
	isInList: (entityId: string) => boolean;
	addToFavourites: (entityId: string, collection: ENTITY) => void;
	removeFromFavourites: (entityId: string, collection: ENTITY) => void;
	isStaticList: (fsId: string) => boolean | null;
}

interface UserListsProps {
	children: (args: UserListChildrenProps) => JSX.Element;
}

const UserLists: React.FC<UserListsProps> = ({ children }) => (
	<Consumer>
		{({ data, loading, mutator }) => {
			const lists = data && data.frequentSearchs ? nodes(data.frequentSearchs) : [];
			const favGoods = lists.find((list) => list.collection === 'GOOD');
			const favCompanies = lists.find((list) => list.collection === 'COMPANY');

			const favGoodsId = favGoods ? favGoods.id : null;
			const favCompaniesId = favCompanies ? favCompanies.id : null;

			const isInList = (entityId) =>
				loading
					? false
					: lists.some(
							(list) =>
								list.goods.some((entity) => entity.id === entityId) ||
								list.companies.some((entity) => entity.id === entityId),
					  );

			const addToFavourites = (entityId, collection) => {
				const favId = collection === ENTITY.GOOD ? favGoodsId : favCompaniesId;
				client.mutate({
					mutation: editFavMutation,
					variables: {
						refs: [{ id: entityId }],
						addFavs: [{ id: favId }],
					},
					update: () =>
						mutator((data) => {
							const lens = collection === ENTITY.GOOD ? 'goods' : 'companies';
							const parsedCollection =
								collection === ENTITY.GOOD ? 'GOOD' : 'COMPANY';
							
							return {
								...data,
								frequentSearchs: {
									...data.frequentSearchs,
									edges: data.frequentSearchs.edges.map((edge) => {
										if (edge.node.collection === parsedCollection) {
											return {
												...edge,
												node: {
													...edge.node,
													[lens]: edge.node[lens].concat([
														{
															id: entityId,
															__typename: collection,
														},
													]),
												},
											};
										} else {
											return edge;
										}
									}),
								},
							};
						}),
				});
			};

			const removeFromFavourites = (entityId, collection) => {
				const favId = collection === ENTITY.GOOD ? favGoodsId : favCompaniesId;
				client.mutate({
					mutation: editFavMutation,
					variables: {
						refs: [{ id: entityId }],
						removeFavs: [{ id: favId }],
					},
					update: () =>
						mutator((data) => {
							const lens = collection === ENTITY.GOOD ? 'goods' : 'companies';
							const parsedCollection =
								collection === ENTITY.GOOD ? 'GOOD' : 'COMPANY';
							
							return {
								...data,
								frequentSearchs: {
									...data.frequentSearchs,
									edges: data.frequentSearchs.edges.map((edge) => {
										if (edge.node.collection === parsedCollection) {
											return {
												...edge,
												node: {
													...edge.node,
													[lens]: edge.node[lens].filter(
														({ id }) => id !== entityId,
													),
												},
											};
										} else {
											return edge;
										}
									}),
								},
							};
						}),
				});
			};

			const isStaticList = (fsId) => {
				if (loading) {
					return null;
				} // non lo posso sapere, non e' false ma neanche true...
				return lists.some((list) => list.id === fsId);
			};

			return children({
				loading,
				isInList,
				addToFavourites,
				removeFromFavourites,
				favoritesGoods: favGoods,
				favoritesCompanies: favCompanies,
				isStaticList,
			});
		}}
	</Consumer>
);

export { UserLists, reset };
