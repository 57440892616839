interface ICacheEntry<Props, IData> {
	p: Props;
	promise: Promise<IData>;
	startedOn: Date;
	lastRequested: Date;
}

export class AsyncOperationCache<Props, IData> {
	private p2key: ((p: Props) => string);
	private operation: ((p: Props) => Promise<IData>);
	private cache: { [key: string]: ICacheEntry<Props, IData> };
	private sizeLimit = 10;
	constructor(
		p2key: ((p: Props) => string),
		operation: ((p: Props) => Promise<IData>),
		sizeLimit?: number,
	) {
		this.p2key = p2key;
		this.operation = operation;
		this.cache = {};
		if (sizeLimit !== undefined) {
			if (sizeLimit < 0) {
				throw new Error(
					`Argument sizeLimit if present must be >= 0 but got ${sizeLimit}`,
				);
			}
			this.sizeLimit = sizeLimit;
		}
	}
	private startOperation(p: Props, key: string) {
		if (this.cache[key]) {
			return;
		}
		const op = this.operation(p);
		this.cache[key] = {
			p,
			promise: op,
			startedOn: new Date(),
			lastRequested: new Date(),
		};
		this.checkCacheSize();
	}
	private checkCacheSize() {
		if (!this.sizeLimit || Object.keys(this.cache).length <= this.sizeLimit) {
			return;
		}
		/*
		 Invece di ottimizzare per una riduzione massiva uso questo metodo più semplice
		 perché solitamente dovrò toglierne solo uno
		 */
		interface IDischargeInfo {
			key?: string;
			lastRequested?: Date;
		}
		while (Object.keys(this.cache).length > this.sizeLimit) {
			// Trovo quello con lastRequested più vecchio e lo scarto
			const dischargeInfo: IDischargeInfo = Object.keys(this.cache).reduce(
				(prev: IDischargeInfo, key) => {
					const cur = this.cache[key];
					if (!prev.lastRequested || prev.lastRequested > cur.lastRequested) {
						return {
							key,
							lastRequested: cur.lastRequested,
						};
					}
					return prev;
				},
				{},
			);
			if (dischargeInfo.key) {
				delete this.cache[dischargeInfo.key];
			}
		}
	}
	getResult(p: Props): Promise<IData> {
		const key = this.p2key(p);
		if (!this.cache[key]) {
			this.startOperation(p, key);
		}
		this.cache[key].lastRequested = new Date();
		return this.cache[key].promise;
	}
}
