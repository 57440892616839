import {
	registerClass,
	registerGlobal,
	defaultColor,
	IFNTheme as defaultThemeType,
	themeGenerator,
} from '@food/css-manager';
import categorySearchBackground from '../static/assets/intro2.jpeg';
import pt from '../static/assets/pattern-triangle.svg';
import * as background from '../static/assets/pattern_ifn.jpeg';

export interface IFNTheme extends defaultThemeType {
	assets: {
		patternTriangle: string;
		categorySearchBackground: string;
	};
	columnSize: number;
}

export const mixin = {
	mainPageContent: registerClass(
		() => `
		min-height: 80vh; // FIXME
	`,
	),
	limitedWidthPage: registerClass(
		() => `
		margin: 0 auto;
		max-width: 1536px;
	`,
	),
	cardBlock: registerClass(
		(t: IFNTheme) => `
		background-color: ${t.colors.white};
		box-shadow: ${t.boxShadow(1)};
		padding: ${t.ratios.xl}rem ${t.ratios.xl}rem;
		max-width: 100%;
		position: relative;
	`,
	),
	card: registerClass(
		(t: IFNTheme) => `
		background-color: ${t.colors.white};
		position: relative;
		display: block;
		width: ${t.columnSize / 2}px;
		max-width: 100%;
	`,
	),
	fullWidthCard: registerClass(
		(t: IFNTheme) => `
		background-color: ${t.colors.white};
		position: relative;
		display: block;
		width: ${t.columnSize}px;
		max-width: 100%;
	`,
	),
	directoryCard: registerClass(
		(t: IFNTheme) => `
		background-color: ${t.colors.white};
		position: relative;
		display: block;
		max-width: 100%;
		> * {
			box-sizing: border-box;
		}
	`,
	),
	cardCornerActions: registerClass(
		(t: IFNTheme) => `
		position: absolute;
		z-index: ${t.zIndexes.P2_AreaAbove};
		background: ${t.colors.white};
		right: ${t.ratios.l / 2}rem;
		top: ${t.ratios.l / 2}rem;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: flex-end;
		border-radius: 50%;
	`,
	),
	dataSheet: registerClass(
		(t: IFNTheme) => `
		> div {
			display: flex;
			margin-top: ${t.ratios.l}rem;
			
			&:first-child {
				margin-top: 0;
			}

			> strong {
				flex-basis: 40%;
				font-weight: ${t.font.weights.semibold};
			}
			> a {
				color: ${t.colors.alt.plain};
				text-decoration: none;
			}
			> ul {
				flex-basis: 60%;

				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				> li {
					flex-basis: 50%;
				}
			}
		}
	`,
	),
	bulletedList: registerClass(
		(t: IFNTheme) => `
		list-style: none;
		padding: 0;
		margin: 0;

		> li {
			padding-left: ${t.ratios.xs * 2}rem;
		
			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				width: ${t.ratios.xs}rem;
				height: ${t.ratios.xs}rem;
				margin-right: ${t.ratios.xs}rem;
				margin-left: -${t.ratios.xs * 2}rem;
				background-color: ${t.colors.grey.plain};
				vertical-align: middle;
			}
		}
	`,
	),
	productImage: registerClass(
		(t: IFNTheme, sc) => `
		position: relative;
		// padding: ${t.ratios.l}rem;
		text-align: center;
		flex: 1 1 auto;
		width: 100%;
		align-self: center;
/*
		.${sc('brand')} {
			position: absolute !important;
			z-index: ${t.zIndexes.P1_SlightlyAbove};
			width: 25%;
			left: ${t.ratios.l / 2}rem;
			top: ${t.ratios.l / 2}rem;
			background: ${t.colors.white};
			box-shadow: ${t.boxShadow(1)};
			display: flex;
			align-items: center;
		}
		
		.${sc('main')} {
			position: relative;
			width: 100%;
		}*/
	`,
	),
	pageDetailHeader: registerClass(
		(t: IFNTheme) => `
		position: relative;

		h1 {
			font-size: 2rem;
			font-weight: ${t.font.weights.semibold};
		}
	`,
	),
	pageDetailDesc: registerClass(
		(t: IFNTheme) => `
		margin-top: ${t.ratios.l}rem;
		line-height: ${t.ratios.l};
	`,
	),
	pageWithSidebar: registerClass(
		(t: IFNTheme) => `
		display: flex;

		> * {
			&:first-child {
				flex-basis: 70%;
				margin-right: ${t.ratios.xl / 2}rem;
			}
			&:last-child {
				flex-basis: 30%;
				margin-left: ${t.ratios.xl / 2}rem;
			}
		}
	`,
	),
	textPage: registerClass(
		(t: IFNTheme) => `
		box-shadow: ${t.boxShadow(1)};
		background: ${t.colors.white};
		// IMPORTANT - auto serve per non sovrascrivere la centratura orizzontale
		margin: ${t.ratios.xl}rem auto;
		padding: ${t.ratios.xl}rem;
		
		ol {
			list-style: decimal;
		}
		
		ul {
			list-style: initial;
		}
		
		&, p {
			margin-top: ${t.ratios.l}rem;
			margin-bottom: ${t.ratios.l}rem;
			line-height: 1.5;
			font-size: ${t.ratios.l}rem;
		}

		h1 {
			line-height: 1;
			font-size: ${t.ratios.xl * 2}rem;
			margin-bottom: ${t.ratios.xl}rem;
			background-color: ${t.colors.alt.plain};
			background-image: url('${t.assets.patternTriangle}');
			
			> span {
				margin-left: ${t.ratios.xl * 2}rem;
				background-color: ${t.colors.white};
				padding-left: ${t.ratios.s}rem;
				padding-right: ${t.ratios.s}rem;
			}
		}
		
		h2 {
			font-weight: ${t.font.weights.semibold};
			font-size: ${t.ratios.l}em;
		}
		
		li {
			margin-left: ${t.ratios.l}rem;
			margin-top: ${t.ratios.xs}em;
		}
		
		strong {
			font-weight: ${t.font.weights.bold};
		}
		
		a {
			color: ${t.colors.alt.plain};
			text-decoration: underline;
		}
	`,
	),
	itemsGrid: registerClass(
		(t: IFNTheme) => `
		display: flex;
		flex-wrap: wrap;
		margin-left: ${t.ratios.xl / 2}rem;
		margin-right: ${t.ratios.xl / 2}rem;
		justify-content: space-between;

		> * {
			padding-left: ${t.ratios.xl / 2}rem;
			padding-right: ${t.ratios.xl / 2}rem;
			margin-bottom: ${t.ratios.xl / 2}rem;

			> * {
				height: 100%;
			}
		}
	`,
	),
	expandableItemsGrid: registerClass(
		(t: IFNTheme, sc) => `
		display: flex;
		justify-content: space-between;

		> * {
			flex: 1 1 20rem; /* FIXME */
			display: flex;
			flex-direction: column;
			margin: 0 ${t.ratios.s / 2}rem;
			
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}

			> * {
				margin: ${t.ratios.s / 2}rem 0;
				
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		
		&.${sc('compact')} {
			> * {
				//margin: 0 ${t.ratios.s / 2}rem;
				
				&:first-child {
					margin-left: 0;
				}
				&:last-child {
					margin-right: 0;
				}
				
				> * {
					margin: 0 0 ${t.ratios.s}rem;
					&:first-child {
						margin-top: 0;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	`,
	),
	maestroBadge: registerClass(
		(t: IFNTheme) => `
		display: inline-block;
		padding: ${t.ratios.s / 2}em ${t.ratios.s}em;
		background: radial-gradient(circle, ${t.colors.premium.light}, ${t.colors.premium.plain});
		color: ${t.colors.white};
		border-radius: ${t.radius}rem;
	`,
	),
	cardBadgeContainer: registerClass(
		(t: IFNTheme, sc) => `
		position: absolute;
		top: ${t.ratios.xl / 2}rem;
		left: ${t.ratios.xl / 2}rem;
		z-index: ${t.zIndexes.P1_SlightlyAbove};
		display: flex;
		flex-direction: row;
		align-items: start; // Evito si strachino e li allineo in alto

		.${sc('badge')} {
			font-size: ${t.ratios.xl * 2}rem;
			margin-left: ${t.ratios.xs / 2}rem; // CHECK
			width: 2.5rem;
			max-height: 2.5rem;
		}

		.${sc('badgeIcon')} {
			font-size: ${t.ratios.xl * 2}rem;
			margin-right: 0.5rem; // CHECK
			width: 2rem;
			vertical-align: middle;
		}`,
	),
	listBadgeContainer: registerClass(
		(t: IFNTheme, sc) => `
		display: flex;
		flex-direction: column;
		align-items: start;
		margin-top: ${t.ratios.xs}rem;
		
		.${sc('badgeMaestro')},
		.${sc('badge')} {
			margin-bottom: ${t.ratios.xs}rem;
		}
		
		.${sc('badgeIcon')} {
			font-size: ${t.ratios.xl * 2}rem;
			margin-right: .5rem; // CHECK
			width: 2rem;
			vertical-align: middle;
		}`,
	),
	detailBadgeContainer: registerClass(
		(t: IFNTheme, sc) => `
		display: flex;
		flex-direction: row;
		align-items: start;
		margin-top: ${t.ratios.l}rem;
		
		
		.${sc('badgeMaestro')},
		.${sc('badge')} {
			margin-bottom: ${t.ratios.xs}rem;
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			margin-right: ${t.ratios.l}rem;
		}
		
		.${sc('badge', 'title')} {
			font-weight: ${t.font.weights.semibold};
			margin-bottom: ${t.ratios.xs / 3}rem;
		}
		
		.${sc('badgeIcon')} {
			font-size: ${t.ratios.xl * 2}rem;
			width: 3.5rem;
			flex: 0 0 auto;
		}`,
	),
	row: registerClass(
		(t: IFNTheme) => `
		display: flex;
		margin-left: ${t.ratios.xl / 2}rem;
		margin-right: ${t.ratios.xl / 2}rem;

		> * {
			padding-left: ${t.ratios.xl / 2}em;
			padding-right: ${t.ratios.xl / 2}em;
		}
	`,
	),
	textCenter: registerClass(() => `text-align: center`),
	shadowed: registerClass((t: IFNTheme) => `box-shadow: ${t.boxShadow(1)};`),
};

// helpers
// export const initTheme = () => {

// scaffold
registerGlobal(
	(t: IFNTheme) => `
	
	/* RESET CSS */
	/*
		http://meyerweb.com/eric/tools/css/reset/
      v2.0 | 20110126
      License: none (public domain)
	*/
	
	font-family: ${t.font.family};
	font-size: ${t.font.baseSize}px;
	font-weight: ${t.font.weights.regular};
	color: ${t.colors.ink};
	button {
	font-family: ${t.font.family};
	}
	&, * {
	box-sizing: border-box;
	}
	
	/* sticky footer (https://css-tricks.com/couple-takes-sticky-footer/) */
	&, > body {
	min-height: 100%;
	}
	> body {
	display: flex;
	flex-direction: column;
	background-color: ${t.colors.primary.disabled.light};
	background-image: url(${background});
	
	}
	
	a, a:hover {
	text-decoration: none;
	}
	a, a:hover, a:active, a:visited {
	color: inherit;
	}

	body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed,
	figure, figcaption, footer, header, hgroup,
	main, menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}
	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure,
	footer, header, hgroup, main, menu, nav, section {
		display: block;
	}
	body {
		line-height: 1;
	}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	
	
	/* Valori iniziali generali */

	body, html {
		margin: 0;
		padding: 0;
		font-display: swap;
	}

	@media only screen and (max-width: ${t.breakpoints.mobileMax}px) {
		font-size: ${(t.font.baseSize * 7) / 8}px;
	}

	/* latin-ext */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 400;
		src: url('/fonts/Montserrat-Regular.woff2') format('woff2');
		unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	}
	/* latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 400;
		src: url('/fonts/Montserrat-Regular.woff2') format('woff2');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}
	
	/* latin-ext */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 700;
		src: url('/fonts/Montserrat-Bold.woff2') format('woff2');
		unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	}
	/* latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 700;
		src: url('/fonts/Montserrat-Bold.woff2') format('woff2');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}
	
	input, textarea {
		font-size: ${t.font.baseSize}px;
	}
	`,
);

const input = {
	alt2Hue: 41,
	primaryHue: 358,
	secondaryHue: 149,
	altHue: 198.71,
	greyHue: 0,
	disabledSaturation: 0.3,
	ink: '#212121',
	white: '#ffffff',
	inkWhite: '#ededed',
	light: 0.95,
	plain: 0.6,
	dark: 0.3,
	dimmerOpacity: 0.5,
	baseTiming: 0.28,
	directShadowX: 0.2,
	directShadowY: 0.4,
	directShadowDiffusion: 0.4,
	ambientShadowDiffusion: 1.1,
	directShadowColor: 'rgba(0,0,0,.15)',
	ambientShadowColor: 'rgba(0,0,0,.15)',
};

const defaultTheme = themeGenerator(input);
defaultTheme.font.baseSize = 16; // CHECK
defaultTheme.colors.alt = defaultColor(
	input.altHue,
	{ dark: input.dark, plain: 0.46, light: input.light },
	{ plain: 0.3957, disabled: input.disabledSaturation },
);
defaultTheme.colors.info = defaultTheme.colors.alt;
defaultTheme.colors.alt2 = defaultColor(
	input.alt2Hue,
	{ dark: input.dark, plain: 0.51, light: 0.757 },
	{ plain: 0.56, disabled: input.disabledSaturation },
);
defaultTheme.colors.premium = defaultTheme.colors.alt2;

export const theme: IFNTheme = {
	...defaultTheme,
	columnSize: defaultTheme.font.baseSize * 23, // FIXME
	button: {
		fontWeight: defaultTheme.font.weights.semibold,
	},
	assets: {
		patternTriangle: pt,
		categorySearchBackground,
	},
};
