import { SearchTypes } from './utils/frequentSearch';
import { QuestionSetRequest } from './server-types';

type TGlobalId = string;

type TFilterStatus = {
	// Prevede anche la 'page'
	[key: string]: any;
};

export type SearchTargetType =
	| 'CompanyDirectory'
	| 'GoodDirectory'
	| 'FairDirectory'
	| 'FrequentSearchDirectory';

export type TTrackAction =
	| { type: 'filter_reset' }
	| {
			type: 'filter_update';
			key: string; // Prevede anche la 'page' per il cambio di pagina
			oldValue: any;
			newValue: any;
	  }
	| {
			type:
				| 'open'
				| 'close'
				| 'go_to_detail'
				| 'bookmark'
				| 'unbookmark'
				| 'go_to_related';
			what: TWhere;
	  }
	| {
			type: 'view'; // IFN - fatto da chi prende il risultato della query aggiungendo un suo XXTrackFragment
			what: TWhere[]; // Spacchettato server-side
	  }
	| {
			type: 'open_usernote_modal';
			source: TWhere; // ??
	  }
	| {
			type: 'send_usernote';
	  }
	| {
			type: 'send_questionset';
			source: TWhere;
			requests: QuestionSetRequest[];
	  }
	| {
			type: 'open_questionset_modal';
			source: TWhere;
			requests: QuestionSetRequest[];
	  }
	| {
			/**
			 * Solo se fatto esplicitamente dall'utente
			 * Non registro le chiusure automatiche
			 */
			type: 'close_usernote_modal';
	  }
	| {
			/**
			 * Solo se fatto esplicitamente dall'utente
			 * Non registro le chiusure automatiche
			 */
			type: 'close_questionset_modal';
			requests: QuestionSetRequest[];
	  }
	| {
			type: 'embedded_interaction';
			name: string;
	  }
	| {
			type: 'open_new_dynamic_list_modal';
			source: TWhere; // ??
	  }
	| {
			type: 'saved_dynamic_list';
			name: string;
			filterStatus: TFilterStatus;
	  }
	| {
			type: 'removed_dynamic_list';
			id: TGlobalId;
			entityType: SearchTypes;
	  }
	| {
			/**
			 * E' così raro che lo traccerei anche su sentry
			 * perché può essere sintomo di un bug
			 */
			type: 'save_dynamic_list_error';
			name: string;
			filterStatus: TFilterStatus;
			error: Error; // Da res.errors[0].state ... forse sbaglio io come ti do l'errore
	  }
	| {
			/**
			 * Solo se fatto esplicitamente dall'utente
			 * Non registro le chiusure automatiche
			 */
			type: 'close_new_dynamic_list_modal';
	  }
	| {
			type: 'requested_authentication_notlogged';
			reason: string;
	  }
	| {
			type: 'requested_upgrade_logged';
			reason: string;
			currentRole: string;
			validRoles: string[];
	  }
	| {
			type: 'denied_access_logged';
			reason: string;
			currentRole: string;
			validRoles: string[];
	  }
	| {
			type: 'debug';
			// ??
	  }
	| {
			type: 'entryPoint'; // Su nuova tab valuto se parent window viene sempre dalla piattaforma per capire se è un punto di ingresso o una navigazione multi-tab
			// campaign?: { // Post-elaborazione pre-analisi da url?
			// 	/**
			// 	 * Recuperati da parametri del link (campagna google, pardot, qr-code custom, ecc.)
			// 	 * poi eventualmente in fase di analisi verrà portato avanti su tutta la sessione
			// 	 */
			// 	id: string;
			// 	channel: string;
			// 	[key: string]: any;
			// };
			url: string;
	  }
	| {
			/**
			 * Sarebbe importante tracciare se è l'ultima tab aperta sul sito
			 * Probabilmente la verifica sulla parent window (presente e sullo stesso sito) è un modo approssimativo, ma robusto
			 * Altrimenti possiamo metterlo a tutti e determinarlo a posteriori in fase di analisi
			 */
			type: 'exit_point';
			url: string;
	  }
	| {
			type: 'generic_click';
			name: string;
			url?: string;
			isExternal?: boolean;
	  }
	| {
			type: 'switch_target_directory';
			to: SearchTypes;
	  }
	| {
			type: 'do_search';
			target: SearchTargetType;
			filterStatus: TFilterStatus;
	  }
	| {
			type: 'route_changed';
			url: string;
			params: {
				[key: string]: string | string[];
			};
	  }
	| {
			type: 'redirect';
			targetUrl: string;
			url: string;
			params: {
				[key: string]: string;
			};
	  }
	/**
	 * AUTH
	 */
	| {
			type: 'auth_expired_renew'; // vuoi il rinnovo avvenuto o la scadenza?
	  }
	| {
			type: 'auth_step_changed'; // non salvo lo step, lo calcolo in fase di render, salvo solo la risposta
			new: string;
			old?: string; // Quello iniziale non ha 'old'
	  }
	| {
			type: 'auth_server_request';
			step: string;
			request: any; // Togliere elementi sensibili
			response: {
				// tipizzazione troppo stretta
				type: string;
				message: string;
				key: string;
				raw: any; // Togliere elementi sensibili
			};
	  }
	| {
			type: 'auth_getting_social_token';
			socialType: 'GOOGLE' | 'FACEBOOK' | 'LINKEDIN';
	  }
	| {
			type: 'auth_getting_social_token_error';
			socialType: 'GOOGLE' | 'FACEBOOK' | 'LINKEDIN';
			errorType: string; // Es. 'USER_ABORTED', ...
	  }
	| {
			type: 'auth_modal_toggle';
			open: boolean;
	  }
	| {
			type: 'successful_login';
			authData: any; // Togliere elementi sensibili
	  }
	| {
			type: 'auth_aborted';
			step: string;
	  };

export enum CardLayout {
	BASE = 'BASE',
	'RELATED' = 'RELATED',
}

export type TWhere =
	| {
			type: 'Page';
			name: string;
	  }
	| {
			type: 'CompanyDetail';
			level: string; // enum
			CompanyId: TGlobalId;
	  }
	| {
			type: 'FrequentSearchCard';
			FrequentSearchId: TGlobalId;
			layout: CardLayout;
	  }
	| {
			type: 'GoodDetail';
			level: string; // enum
			GoodId: TGlobalId;
			BrandId: TGlobalId;
			CompanyId: TGlobalId;
	  }
	| {
			type: 'FairDetail';
			FairId: TGlobalId;
	  }
	| {
			type: 'CompanyDirectory';
			filterStatus: TFilterStatus;
			page: number;
			//total: number; TODO come posso implementarlo?
	  }
	| {
			type: 'GoodDirectory';
			filterStatus: {
				[key: string]: any;
			};
			page: number;
			//total: number; TODO come posso implementarlo?
	  }
	| {
			type: 'FairDirectory';
			filterStatus: {
				[key: string]: any;
			};
			page: number;
	  }
	| {
			type: 'Block';
			name: string; // 'main', 'related', 'header', 'blocking_popup', 'toaster', ...
			index?: number;
			row?: number;
			column?: number;
	  }
	| {
			type: 'CompanyCard';
			level: string; // enum
			CompanyId: TGlobalId;
			layout: CardLayout;
	  }
	| {
			type: 'BrandCard';
			level: string; // enum
			BrandId: TGlobalId;
			CompanyId: TGlobalId;
			layout: CardLayout;
	  }
	| {
			type: 'GoodCard';
			level: string; // enum
			GoodId: TGlobalId;
			BrandId: TGlobalId;
			CompanyId: TGlobalId;
			layout: CardLayout;
	  }
	| {
			type: 'FairCard';
			FairId: TGlobalId;
			layout: CardLayout;
	  }
	| {
			type: 'Route';
			url: string;
	  }
	| {
			type: 'FrequentSearchDirectory';
			filterStatus: {
				[key: string]: any;
			};
			page: number;
	  };

export interface ITrackEvent {
	software: {
		name: string; // IFN AUTH, ...
		version: number;
	};
	timestamp: string; // (ISO) / number;
	where: TWhere[];

	who: {
		tabId: string;
		sessionId: string;
		browserId: string;
		browserName?: string;
		browserVersion?: string;
		// userId?: TGlobalId; // Impostato da un processo che percorre a ritroso le session
	};

	action: TTrackAction;
}
