import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Button, ButtonLayouts, IconPositions } from '@food/ui';
import { cns, currySC, registerClass } from '@food/css-manager';
import { IFNTheme } from '../../utils/theme';
import { MediaImg, mediaImgFragment } from './MediaImg';
import { ACTIONS, generateUrl } from '../../utils/urls';
import { ENTITY } from '../../utils/entities';
import { levelOrMore } from '../../utils/misc';
import t from '../../utils/labels';
import { CompanyLevelEnum, Fair, MediaType } from '../../server-types';
import { addWhere, useTracking, WhereBlock } from '../../utils/tracking';
import { fairCardWhere } from '../../utils/where';
import { useInView } from 'react-intersection-observer';

interface IFairCardProps {
	entity: Fair;
	className?: string;
	errors?: ReadonlyArray<Error>;
	openInNewTab?: boolean;
	companyLevel?: CompanyLevelEnum;
	style?: any;
	participation?: {
		pavilion: string;
		stand: string;
		note: string;
	};
	forceShowDirectoryBtn?: boolean;
}

export const Fragment = gql`
	fragment FairCardFragment on Fair {
		id
		slug
		name
		year
		edition
		slug
		Country {
			id
			name
			area
			code
		}
		city
		begin
		end
		logoMedia {
			...MediaImgFragment
		}
		participations {
			total
		}
	}
	${mediaImgFragment}
`;

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	height: 100%;
	position: relative;
	padding: ${t.ratios.l}rem;
	
	> header {
		display: flex;
	}
	
	.${sc('tn')} {
		width: 30%;
		margin-right: ${t.ratios.l}em;
		display: flex;
		justify-content: center;

		.${sc('media')} {
			width: 100%;
			border-radius: ${t.radius}em;
			
			img {
				border: 1px solid ${t.colors.grey.light};    // CSS-NUMBER
			}
		}
	}
	
	.${sc('info')} {
		width: 60%;
		line-height: ${t.ratios.l};
		align-self: center;

		.${sc('fair-visit-icon')} {
			position: absolute;
			top: 0;
			right: 0;
			padding: 0 ${t.ratios.xs / 3}em;
			font-weight: ${t.font.weights.bold};
			color: ${t.colors.inkWhite};
			background: ${t.colors.primary.plain};
		}

		h4 {
			font-size: ${t.ratios.l}rem;
			font-weight: ${t.font.weights.bold};
		}
		
		> * {
			margin-bottom: ${t.ratios.xs}rem;
			
			&:last-child: {
				margin-bottom: 0;
			}
		}
	}

	.${sc('period')} {
		clear: both;
		padding-top: ${t.ratios.l}em;
		text-align: center;

		> span {
			+ span {
				&:before {
					display: inline-block;
					content: '-';
					padding: 0 ${t.ratios.xs / 2}em;
				}
			}
		}
	}
	
	.${sc('participation')} {
		text-align: center;
		margin-top: ${t.ratios.s}rem;
		
		.${sc('content')} {
			font-weight: ${t.font.weights.bold};
		}
	}
	
	&.${sc('with-directory-btn')} {
		padding-bottom: ${t.ratios.l * 2 + t.ratios.xl}rem;
	}
	
	.${sc('directory-btn')} {
		width: 100%;
		padding: 0 ${t.ratios.l}rem;
		display: flex;
		justify-content: flex-end;
		position: absolute;
		bottom: ${t.ratios.l}rem;
		left: 0;
	}

	.${sc('directory-btn-children')} {
		justify-content: flex-end;
		
		> * {
			margin-left: ${t.ratios.s}rem;
		}
	}
`,
);

const sc = currySC(styleClass);

export const FairCard: React.FC<IFairCardProps> = ({
	className,
	entity,
	openInNewTab = false,
	companyLevel = CompanyLevelEnum.Free,
	participation,
	forceShowDirectoryBtn,
}) => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0,
	});
	const track = useTracking();
	const l1OrMore = levelOrMore(companyLevel, CompanyLevelEnum.PaidL1);
	const l2OrMore = levelOrMore(companyLevel, CompanyLevelEnum.PaidL2);
	const hasParticipations = entity.participations.total > 0;
	const showDirectoryBtn =
		forceShowDirectoryBtn || (hasParticipations && !participation);
	const where = fairCardWhere(entity);

	useEffect(() => {
		if (inView) {
			track({
				type: 'view',
				what: [where],
			});
		}
	}, [inView]);

	/*const eventLinks = getCalendarLinks({
		id: entity.id,
		title: entity.name,
		begin: new Date(entity.begin),
		end: new Date(entity.end),
	});*/

	/*const options = [
		{
			label: 'iCalendar',
			url: eventLinks.iCal,
			type: 'ics',
		},
		{
			label: 'Google Calendar',
			url: eventLinks.gCal,
			type: 'link',
		},
		{
			label: 'Outlook',
			url: eventLinks.iCal,
			type: 'ics',
		},
		{
			label: 'Outlook Online',
			url: eventLinks.outlook,
			type: 'link',
		},
		{
			label: 'Yahoo',
			url: eventLinks.yahoo,
			type: 'link',
		},
	];
*/
	return (
		<WhereBlock step={where}>
			<Link
				to={generateUrl(ENTITY.FAIR, ACTIONS.DETAIL, entity.slug)}
				onClick={() =>
					track(
						{
							type: 'go_to_detail',
							what: where,
						},
						addWhere(where),
					)
				}
				style={{ display: 'inline-block' }}
				className={className}
				target={openInNewTab ? '_blank' : undefined}
			>
				<article
					ref={ref}
					className={cns(styleClass, showDirectoryBtn && sc('with-directory-btn'))}
				>
					<header>
						{entity.logoMedia && (
							<div className={sc('tn')}>
								<MediaImg
									type={MediaType.Logo}
									className={sc('media')}
									entity={entity.logoMedia}
									alt={`Fair ${entity.name} ${entity.year} logo`}
								/>
							</div>
						)}
						<div className={sc('info')}>
							<h4>{entity.name}</h4>
							<div>{entity.year}</div>
							<div>
								{entity.city}, {entity.Country.name}
							</div>
						</div>
					</header>
					<div className={sc('period')}>
						{entity.begin && <span>{dayjs(entity.begin).format('MMMM D')}</span>}{' '}
						{entity.end && <span>{dayjs(entity.end).format('MMMM D, YYYY')}</span>}
					</div>
					{l1OrMore && participation && (
						<div className={sc('participation')}>
							{participation.pavilion && (
								<>
									<span>{t`Pavilion`}</span>{' '}
									<span className={sc('content')}>{participation.pavilion}</span>
								</>
							)}
							{participation.stand && (
								<>
									{participation.pavilion && ' - '}
									<span>{t`Stand`}</span>{' '}
									<span className={sc('content')}>{participation.stand}</span>
								</>
							)}
							{l2OrMore && participation.note && (
								<div>
									<span>{t`Note`}</span>{' '}
									<span className={sc('content')}>{participation.note}</span>
								</div>
							)}
						</div>
					)}
					{showDirectoryBtn ? (
						<div className={cns(sc('directory-btn'), sc('directory-btn-children'))}>
							{/*<AddEventButton type={'alt'} options={options} />*/}
							<Button
								label={t`See exhibitors`}
								type={'success'}
								linkTo={
									generateUrl(ENTITY.COMPANY, ACTIONS.LIST) +
									'/?fairs=' +
									encodeURIComponent(entity.id)
								}
								layout={ButtonLayouts.BUTTON}
								iconPos={IconPositions.RIGHT}
								icon={'chevron_right'}
								onClick={() =>
									track(
										{
											type: 'do_search',
											target: 'CompanyDirectory',
											filterStatus: { fairs: entity.id },
										},
										addWhere(where),
									)
								}
							/>
						</div>
					) : (
						<div className={sc('directory-btn')}>
							{/*<AddEventButton type={'alt'} options={options} />*/}
						</div>
					)}
				</article>
			</Link>
		</WhereBlock>
	);
};
