import { ENTITY } from './entities';
import { CompanyLevel, CompanyLevelEnum } from '../server-types';

const CURSOR_PREFIX = 'arrayconnection:';

export const order = (n: number) => {
	switch (n) {
		case 1:
			return '1st';
		case 2:
			return '2nd';
		case 3:
			return '3rd';
		default:
			return `${n}th`;
	}
};

export const nameLabel = (e: any) => e.name;

export const offsetToCursorDirectory = (offset: number) => offset.toString();

export const offsetToCursor = (offset) => btoa(CURSOR_PREFIX + offset);

export const pluralEntityLabel = (e: ENTITY) => e + '/labelPlural';

export const html = (html) => ({ __html: html });

// export const isItalianLang = (lang: string) => lang.toLowerCase() === 'it-it';

export function companyLevel(levels: ReadonlyArray<CompanyLevel>): CompanyLevelEnum {
	if (!levels || levels.length === 0) {
		return CompanyLevelEnum.Free;
	}
	if (levels.length === 1) {
		return levels[0].level;
	}
	throw new Error('Troppi livelli sulla compagnia');
}

export function levelOrMore(level: CompanyLevelEnum, min: CompanyLevelEnum) {
	const payments = [
		CompanyLevelEnum.Free,
		CompanyLevelEnum.PaidL1,
		CompanyLevelEnum.PaidL2,
		CompanyLevelEnum.PaidL3,
	];
	return payments.indexOf(level) >= payments.indexOf(min);
}

export const companyLevelStr = (level: ReadonlyArray<CompanyLevel>): string => {
	const l = companyLevel(level);

	if (l === CompanyLevelEnum.PaidL2) {
		return 'Maestro';
	} else if (l === CompanyLevelEnum.PaidL1) {
		return 'Export Pro';
	}

	return 'Presente';
};

export const companyLevel2Price = (level: ReadonlyArray<CompanyLevel>): number => {
	const l = companyLevel(level);
	
	if (l === CompanyLevelEnum.PaidL2) {
		return 20;
	} else if (l === CompanyLevelEnum.PaidL1) {
		return 5;
	}
	
	return 1;
};


export type DefaultSetter = (val: any) => void;

export const nodes = (query: { edges: Array<{ node: any }> }) =>
	query.edges.map((o) => o.node);

/*
 * convertHtmlToText
 */
const htmlMap: {
	[key: string]: string;
} = {
	amp: '&',
	lt: '<',
	gt: '>',
	quot: '"',
	apos: "'",
	'#x27': "'",
	'#x2F': '/',
	'#39': "'",
	'#47': '/',
	nbsp: ' ',
};

const convertHtmlEntities = (m0: string, m1: string) => {
	if (htmlMap[m1]) {
		return htmlMap[m1];
	}
	try {
		if (m1[0] === '#') {
			return String.fromCharCode(parseInt(m1.substr(1), 10));
		}
		if (parseInt(m1, 10) && String.fromCharCode(parseInt(m1, 10))) {
			return String.fromCharCode(parseInt(m1, 10));
		}
	} catch (err) {
		console.error('Char conversion error', m1, err);
	}
	return '&';
};

/**
 * [convertHtmlToText description]
 * @param  {string} html [description]
 * @return {string}      [description]
 */
export const toText = (html: string) =>
	html &&
	html
		.replace(/<[^>]+>/g, '')
		.replace(/&([^;]+);/g, convertHtmlEntities)
		.trim();

/**
 * Funzione identita
 * @param a: any
 * @return a: any
 */
export const identity = (a: any) => a;

/**
 * Confronta due id. A causa dei vari formati che abbiamo assunto nel tempo non e' possibile affidarsi al confronto
 * fra stringhe
 *
 * @param id1: string
 * @param id2: string
 * @return boolean
 */
export const idEquals = (id1: string, id2: string): boolean => {
	const id1ContainsTilde = id1.includes('~');
	const id2ContainsTilde = id2.includes('~');

	if (id1ContainsTilde && id2ContainsTilde) {
		return id1.toLowerCase() === id2.toLowerCase();
	} else {
		const realId1 = id1.split('~')[0];
		const realId2 = id2.split('~')[0];
		return realId1 === realId2;
	}
};
