import './utils/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import CONFIG from './static/config';
import { ApolloProvider } from 'react-apollo';
import { Router } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import * as git from './static/rev.json';
import { client } from './utils/client';
import { theme } from './utils/theme';
import { injectCSS } from '@food/css-manager';
import { init, getTab, getSession, getBId } from '@food/tracking';
import { RenderProvider } from './utils/context/renderContext';
import { history } from './utils/history';
import { isLogged, getUserData } from '@food/auth';
import { ITrackEvent, TWhere } from './tracking-types';
//import './utils/analytics';

injectCSS({ theme, styleTagId: 'css-manager-exported' });

const serializeWhere = (w: ReadonlyArray<TWhere>): string =>
	JSON.stringify(w.filter((ww) => ww.type !== 'Block'));

init({
	baseUrl: CONFIG.API_ENDPOINT.TRACKING,
	bufferLength: 10,
	targetSite: CONFIG.TARGET_SITE,
	policy: (
		event: ITrackEvent,
		events: ReadonlyArray<ITrackEvent>,
	): ReadonlyArray<ITrackEvent> => {
		let hasAggregated = false;
		const newBuffer: ITrackEvent[] = [];
		events.forEach((e) => {
			if (
				e.action &&
				event.action &&
				e.action.type === 'view' &&
				event.action.type === 'view' &&
				serializeWhere(e.where) === serializeWhere(event.where)
			) {
				// posso suppore un aggregazione a questo evento. A determinarlo sara'
				// la differenza di tempo (per ora max 200 ms)
				const timestamp1 = new Date(e.timestamp).getTime();
				const timestamp2 = new Date(event.timestamp).getTime();
				const difference = Math.abs(timestamp1 - timestamp2);

				if (difference < 500) {
					newBuffer.push({
						...e,
						action: {
							...e.action,
							what: [...e.action.what, ...event.action.what],
						},
					});
					hasAggregated = true;
					return;
				}
			}
			newBuffer.push(e);
		});

		if (hasAggregated) {
			// se ho aggregato la modifica e' nell'evento presente nell'array, posso inviare
			return newBuffer;
		} else {
			// se non ho aggregato gli eventi nell'array sono uguali agli originali
			// devo quindi aggiungere quello nuovo
			newBuffer.push(event);
			return newBuffer;
		}
	},
});

// configuro sentry, il nostro gestore di segnalazioni bug
Sentry.init({
	dsn: CONFIG.SENTRY_SERVER,
	release: (git as any).rev,
	environment: process.env.NODE_ENV,
});

Sentry.configureScope((scope) => {
	scope.setTag('backend', CONFIG.BACKEND_URL);
	scope.setTag('session', getSession());
	scope.setTag('tab', getTab());
	scope.setTag('bID', getBId());
	scope.setTag('SSR', 'false');

	if (isLogged()) {
		const user = getUserData();
		scope.setUser({
			id: user.id,
			username: user.name,
			email: user.email,
		});
	}
});


export const WrappedApp = (
	<Router history={history}>
		<ApolloProvider client={client}>
			<RenderProvider value={{ isBot: true }}>
				<App />
			</RenderProvider>
		</ApolloProvider>
	</Router>
);

ReactDOM.render(WrappedApp, document.getElementById('page'));
