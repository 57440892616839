import React, { useMemo } from 'react';
import { ACTIONS, generateUrl } from '../../utils/urls';
import { ENTITY } from '../../utils/entities';
import { GenericHead, jsonldStringEscape } from './GenericHead';
import { Company } from '../../server-types';

interface ICompanyDetailHeadProps {
	entity: Company;
}

export const CompanyDetailHead: React.FC<ICompanyDetailHeadProps> = ({ entity }) => {
	const semantic = useMemo(() => {
		const s: any = {
			'@context': 'http://schema.org',
			'@type': 'Organization',
			name: jsonldStringEscape(entity.name),
			logo: entity.logoMedia && entity.logoMedia.largeThumbUrl,
			url: entity.website || generateUrl(ENTITY.COMPANY, ACTIONS.DETAIL, entity.slug),
		};

		const description = entity.description || entity.descriptionLong;

		if (description) {
			s.description = jsonldStringEscape(description);
		}

		if (entity.billingBranch && entity.billingBranch.address1) {
			s.address = {
				streetAddress: jsonldStringEscape(entity.billingBranch.address1),
				addressCountry: 'Italy',
			};
			if (entity.billingBranch.postalCode) {
				s.address.postalCode = jsonldStringEscape(entity.billingBranch.postalCode);
			}
		}
		return JSON.stringify(s);
	}, [entity]);

	return (
		<GenericHead
			title={entity.name}
			description={entity.description}
			image={entity.logoMedia && entity.logoMedia.largeThumbUrl}
			jsonld={[semantic]}
		/>
	);
};
