// Questo componente a volte puo' risultare inutilizzato, ma non lo e': dipende dal fatto che in homepage venga
// mostrata una lista di fiere o meno

import React from 'react';
import { cns, registerClass, currySC } from '@food/css-manager';
import { IFNTheme, mixin } from '../../utils/theme';
import gql from 'graphql-tag';
import { mediaImgFragment, MediaImg } from './MediaImg';
import { Link } from 'react-router-dom';
import { ACTIONS, generateUrl } from '../../utils/urls';
import { ENTITY } from '../../utils/entities';
import day from 'dayjs';
import { WhereBlock } from '../../utils/tracking';
import { CardLayout } from '../../tracking-types';
import { Fair, MediaType } from '../../server-types';

interface FairRelatedCardProps {
	className?: string;
	entity: Fair;
	customActions?: any;
	openInNewTab?: boolean;
}

const shortDate = (begin: string, end: string) => {
	const b = day(begin);
	const e = day(end);
	const sameYear = b.year() === e.year();
	const sameMonth = b.month() === e.month();

	return [
		b.format('DD') + ' ',
		!sameMonth ? b.format('MMM') + ' ' : '',
		!sameYear ? b.format('YYYY') + ' ' : '',
		'- ',
		e.format('DD MMM YYYY'),
	];
};

export const FairRelatedCardFragment = gql`
	fragment FairRelatedCardFragment on Fair {
		id
		name
		slug
		logoMedia {
			...MediaImgFragment
		}
		year
		edition
		slug
		Country {
			id
			name
			code
		}
		city
		begin
		end
	}
	${mediaImgFragment}
`;

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 100%;
	
	.${sc('img')} {
		width: 70%; // FIXME
	}
	
	> div {
		text-align: center;
		max-width: 100%;
	}
	
	.${sc('title')} {
		font-size: ${t.ratios.l}rem;
		font-weight: ${t.font.weights.bold};
		margin-bottom: ${t.ratios.l / 2}rem;
		margin-top: ${t.ratios.xs}rem;
	}
	
	.${sc('value')} {
		font-weight: ${t.font.weights.semibold};
	}
	
	.${sc('date')} {
		display: block;
		margin-top: ${t.ratios.xs}rem;
	}
`,
);

const sc = currySC(styleClass);

export const FairRelatedCard: React.FC<FairRelatedCardProps> = ({
	className,
	entity,
	customActions,
	openInNewTab = false,
}) => (
	<WhereBlock step={{ type: 'FairCard', FairId: entity.id, layout: CardLayout.RELATED }}>
		<Link
			target={openInNewTab ? '_blank' : undefined}
			to={generateUrl(ENTITY.FAIR, ACTIONS.DETAIL, entity.slug)}
			className={cns(className, mixin.card)}
		>
			<div className={cns(styleClass)}>
				<div className={mixin.cardCornerActions}>{customActions}</div>
				<MediaImg
					type={MediaType.GoodImage}
					className={sc('img')}
					entity={entity.logoMedia}
					alt={`Fair ${entity.name} ${entity.year} logo`}
				/>
				<div>
					<div className={sc('title')}>
						{entity.name} {entity.year} {entity.edition}
					</div>
					<span className={sc('label')}>
						{entity.city} - {entity.Country.name}
					</span>
					<span className={cns(sc('label'), sc('date'))}>
						{shortDate(entity.begin, entity.end)}
					</span>
				</div>
			</div>
		</Link>
	</WhereBlock>
);
