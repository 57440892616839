export default {
	'Welcome title': 'ITALIANFOOD.NET EVOLVED!',
	'welcome text html': `
<p>ItalianFOOD.net is now the most advanced directory to look for truly Italian products, suppliers and their presence at
the main tradeshows around the globe.</p>

<p>As a food lover you can take a look to everything but if you’re a Buyer your possibilities take off!</p>

<p>Register Now! It’s Free!</p>
`,
	FAMILY_BUSINESS: 'Family Business',
	MULTINATIONAL_CORPORATION: 'Multinational Corporation',
	PRIVATE_EQUITY: 'Private Equity',
	COOPERATIVE: 'Cooperative',
	plProducer: 'Private Label Manufacturer',
	'COMPANY/labelPlural': 'Suppliers',
	COMPANY: 'Supplier',
	'GOOD/labelPlural': 'Products',
	'Company/labelPlural': 'Suppliers', // WAT TODO
	GOOD: 'Product',
	PERISHABLE: 'Perishable',
	FROZEN: 'Frozen',
	SHELF_STABLE: 'Shelf stable',
	'ROLE/CONSUMER': 'Food Lover',
	'ROLE/BUYER': 'Professional',
	'ROLE/BUYER_PL_ITALIA': 'Professional',
	'ROLE/PRODUCER': 'Supplier',
	'ROLE/INTERNAL': 'Internal',
	'GOODS/labelPlural': 'Products',
	'Good/labelPlural': 'Products',
	Company: 'Supplier',
	'cookie banner text': `We use cookies to improve your online experience. By using this site, you agree to such use as described to our`,
	'add your company html':
		"<p>Show your Italian company and products here.  <strong>It's easy and FREE!</strong></p>",
	'save dynamic search intro text':
		'You are saving the combination of filters you set in a dynamic list. Use a smart name to define it.',
	'need to login explanation text':
		'<strong>We value our content.</strong><br /><br /> To protect our work we reserve full access to registered users. Please Sign In. ',
	'no buyer filter message html':
		"You're registered as a Food Lover. Combination of more filters are reserved to Food Market Professionals." +
		' If you are so <a href="mailto:support@italianfood.net">Contact Us</a> to upgrade your status',
	'Not Found': 'Mannaggia!',
	'Authentic Italian COMPANY found':
		'Authentic Italian <b style="font-weight: bold;">Suppliers</b> and Brands found',
	'Authentic Italian GOOD found':
		'Authentic Italian <b style="font-weight: bold;">Products</b> found',
	'INTERNAL no edit profile html':
		'You seems to be an internal user. To edit your preferences please <a href="https://hub.italianfood.net">go to the HUB</a>',
	'BUYER no edit profile html':
		"We deduced your name from your Social Login and Company and Role from your work email. If this details aren't correct please write us.\n" +
		"We'll review them as soon as possible.",
	'SUPPLIER no edit profile html':
		"We deduced your name from your Social Login and Company and Role from your work email. If this details aren't correct please contact us and we'll review them as soon as possible.",
	'PRODUCER no edit profile html':
		"We deduced your name from your Social Login and Company and Role from your work email. If this details aren't correct please contact us and we'll review them as soon as possible.",
	'CONSUMER no edit profile html':
		'If you want to upgrade to a different Role (BUYER or SUPPLIER) we ask you to contact us describing your work email, title, company website and a small description.\n' +
		'Please remember that the Role "SUPPLIER" is reserved to employees of Italian Food Industries and "BUYER" to professionals of Retail, Importers, Brokers or Food Service Operators.',
	'Not enough permission for these filters - not logged':
		'You can not use these filters as an anonymous user',
	'Not enough permission for these filters - not buyer':
		'You can not use these filters with your professional role',
	'options.email error': 'Wrong e-mail or password',
	'not found explanation html':
		"Either the page doesn't exists anymore or your link is broken.",
	'error explanation html':
		"A message has been sent to our technicians and we'll work on it as soon as possible.",
	'Not enough permission for this page - not logged':
		'As an anonymous user you can see only few results. Please sign in or return to the first page.',
	'Not enough permission for this page - not logged - modal':
		'As an anonymous user you can see only few results. <br /><br />Please sign in to see all the results.',
	'Temporary consumer explanation':
		'In the meanwhile you can continue to browse the platform making bookmarks and saving your searches.',
	'Registration intro': 'Do you work in the Food and Beverage market?',
	'Role CONSUMER': "No, I'm a food lover",
	'Role BUYER': "Yes, I'm a professional",
	'sign in to see distribution channels':
		'Sign In as a Professional to see how this supplier distribute in your country and download the catalogue.',
	'only buyers allowed to see distribution channels':
		"This Information is reserved to professionals of Retail and Foodservice. Your profile doesn't allow you to view it. Contact Us to change your profile.",
	'no distribution channel in company_pre': 'Discover how',
	'no distribution channel in company': 'distribute in your country',
	'contact us btn': 'Contact Us',
	'distribution channel box': 'distribution in your country',
	'contact the supplier': 'Contact the Supplier',
	'distributionChannel mode': 'Mode',
	'distributionChannel channels': 'Channels',
	'distributionChannel catalogue': 'Catalogue',
	'download catalogue btn': 'Download the Catalogue',
	'distributionChannel notes': 'Notes',
	'distributionChannel contact email': 'Email address',
	'DistributionChannel/channels/DISTRIBUTOR': 'Distributor',
	'DistributionChannel/channels/RETAIL': 'Retail',
	'DistributionChannel/channels/GOURMET_SHOPS': 'Gourmet Shops',
	'DistributionChannel/channels/LOCAL_BRANCH': 'Local Branch',
	'DistributionChannel/channels/FOODSERVICE': 'Foodservice',
	'DistributionChannel/channels/WHOLESALER': 'Wholesaler',
	'DistributionChannel/channels/BROKER': 'Broker',
	'consumerOnlyEmail error':
		'This email domain is public, we cannot associate your company from it.',
	// 'login as professional title': 'Do you work in the F&B Industry?',
	'login as professional title': 'Sign in as a professional to',
	'login as professional html':
		'<ul>' +
		'<li><span>&check;</span>Save your favourite products <i class="c0" style="color: red; margin-left: .3em;">favorite</i></li>' +
		'<li><span>&check;</span>Receive our Magazine for Free</li></ul>',
	'options.password error': 'Empty password not allowed!',
	BUYER_PL_ITALIA: 'Italian Retailer',
	'Waiting review explanation html':
		'<p>Your user has been created and your email verified.</p><p>To grant you full access to the platform tools is still necessary we create your company profile. In the meantime you can enjoy Italianfood.net saving favourites and searches. You will be notified at the end of our controls.</p>',
	'no participants fair text':
		'No company has confirmed us its presence to the Tradeshow at this moment.',
	'grantToken error':
		'Technical error with your Social Sign In. Please try again or change Social.',
	'contact us': 'Contact Us',
	'loading map': 'Loading Map...',
	'tradeshow directory title': 'Food & Beverage World Tradeshows',
	'tradeshow directory description':
		'Every world tradeshow about Food & Beverage with focus on Italian Exhibitors',
	'fair detail map box title': 'How to get there',
	'No entities text': 'No items in this list yet',
	'Billing Address': 'Address',
	'Billing Name': 'Company Name',
	'message will be anonymous explanation':
		'The message will be sent to the company anonymously',
	'no fair participations for supplier': 'No future tradeshow uploaded',
	'switch to social explanation title': 'We are deprecating accounts with password',
	// 'Associate a social account to your user': '',
	'Maybe later': 'Maybe later',
	'switch to social explanation html': `Connect your social account and log in with a only a click, without having to keep your credentials in mind.`,
	'Associate a social account to your user':
		'Select a social account to associate it with your user',
	'Sign in': 'Sign in',
	'Sign in with FACEBOOK': 'Sign in with Facebook',
	'Sign in with GOOGLE': 'Sign in with Google',
	'Sign in with LINKEDIN': 'Sign in with Linkedin',
	'alreadyUsedEmail error':
		'A user account associated with this email address already exists.<br />If you own this email address <a href="/login?traditional=true">sign in using your credentials</a> or <a href="mailto:$SUPPORT_EMAIL">contact us</a>.',
	'Billing Info': 'Company Detail',
	'register now': 'Register now!',
	contact_us: 'Request more info',
	share: 'Share',
	request_catalogue: 'Request catalogue',
	request_prices: 'Request prices',
	'save dynamic list intro': `Save this combination of filters for your next visit.
	You'll find your dynamic lists in the profile page`,
	GRAMS: 'g',
	MILLIGRAMS: 'mg',
	MILLILITER: 'ml',
	'login intro text': 'Complete the form or',
	'company name introduction': '',
	Firstname: 'First name',
	Lastname: 'Last name',
	CATALOG: 'Catalogue',
	PRICE: 'Price list',
	Catalog: 'Catalogue',
	Price: 'Price list',
	Resellers: 'Retailers list',
	Contact: 'Contact representative',
	Message: 'Send a message',
	'commercial newsletter consent':
		'I want to receive Italianfood.net newsletter and updates',
	'questionset creation success message html':
		'<p>Thanks for you request.</p>' +
		"<p>Your enquiry will be managed by our staff in a few days, we'll forward it to the Supplier.</p>" +
		'<p>This service is reserved to potential international customers of the Italian industry.</p>',
	SERVER_ERROR_HTML:
		'Technical error, our please retry in few minutes.<br />If the problem persists please contact us at',
	'remember switch to social title': 'Social account connected',
	'remember social account html':
		'You can log in without typing your email and password by using your social account.',
	'social login type associated': 'Social network used',
	Proceed: 'Proceed',
};
