import React, { useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import t from '../../utils/labels';
import { IFNTheme, mixin, theme } from '../../utils/theme';
import { Logout } from '../Logout';
import { ACTIONS, generateUrl } from '../../utils/urls';
import { ENTITY } from '../../utils/entities';
import { openRenewModal, UserData } from '@food/auth';
import { cns, currySC, registerClass } from '@food/css-manager';
import {
	MainSearch,
	MainSearchLayout as MainSearchLayouts,
	MainSearchLayout,
} from '../../components/MainSearch';
import { SearchTypes } from '../../utils/frequentSearch';
import {
	CategorySearch,
	Layouts as CategorySearchBlockLayouts,
} from '../../components/CategorySearch';
import { Dropdown, DropdownAlignments, Icon, Collapsible } from '@food/ui';
import Logo from '../../static/assets/logo.svg';
import { useLocation } from '../../utils/hooks';
import { addWhere, useTracking, WhereBlock } from '../../utils/tracking';
import { TWhere } from '../../tracking-types';
import { UserRole } from '../../server-types';
import { MATERIAL } from '@food/ui/build/dist/src/icons';

export enum headerLayouts {
	STANDARD = 'STANDARD',
	COMPACT = 'COMPACT',
	MINI = 'MINI',
}

interface HeaderProps {
	layout: headerLayouts;
	searchType?: SearchTypes;
}

export const HeaderLayoutChooser = (width: number) => {
	if (width >= theme.breakpoints.mobileMax && width < theme.breakpoints.tabletMax) {
		return headerLayouts.COMPACT;
	} else if (width >= theme.breakpoints.tabletMax) {
		return headerLayouts.STANDARD;
	}
	return headerLayouts.MINI;
};

export const socialLinks = [
	{
		label: 'LinkedIn',
		link: 'https://www.linkedin.com/showcase/italianfoodnet',
		icon: 'linkedin',
		bg: '#007bb5',
	},
	{
		label: 'Facebook',
		link: 'https://www.facebook.com/pages/Italianfoodnet/603478786359091?ref=br_tf',
		icon: 'facebook',
		bg: '#3b5998',
	},
	{
		label: 'Twitter',
		link: 'https://twitter.com/italianfood_net',
		icon: 'twitter',
		bg: '#1DA1F2',
	},
	{
		label: 'Instagram',
		link: 'https://www.instagram.com/italianfoodnet/',
		icon: 'instagram',
		bg: '#d73a88',
	},
	{
		label: 'Vimeo',
		link: 'https://vimeo.com/channels/italianfood',
		icon: 'vimeo',
		bg: '#1ab7ea',
	},
];

const menuLinks = [
	{ label: 'Homepage', link: '/', icon: 'home' },
	{
		label: 'For Suppliers',
		link: '/suppliers-introduction',
		icon: 'business',
	},
	{
		label: 'For Buyers',
		link: '/buyers-introduction',
		icon: 'shopping_cart',
	},
	{
		label: 'Tradeshows',
		link: generateUrl(ENTITY.FAIR, ACTIONS.LIST),
		icon: 'event',
	},
	{
		label: 'News',
		link: 'https://news.italianfood.net',
		icon: 'subject',
		target: '_blank',
	},
	{
		label: 'About Us',
		link: '/about-us',
		icon: 'people',
	},
	{
		label: 'Privacy Policy',
		link: '/privacy-policy',
		icon: 'cloud_done',
	},
];
const profileLinks = [
	{ label: 'Profile', link: '/profile' },
	{ label: 'Favorites', link: '/profile#favorites' },
	{ label: 'Dynamic Lists', link: '/profile#dynamicLists' },
];

const styleClass = registerClass(
	(th: IFNTheme, s) => `
	background: ${th.colors.white};

	> header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: ${th.ratios.xl}rem ${th.ratios.xl}rem;

		.${s('toggler')},
		.${s('userLabel')},
		.${s('user')} {
			position: relative;
			color: ${th.colors.grey.plain};
			> span {
				cursor: pointer;
			}
		}

		.${s('logo')} {
			padding: 0 ${th.ratios.l}rem;
			text-align: center;
			
			img {
				max-width: 100%;
				width: 200px;
			}
		}
		
		.${s('userLabel')} {
			display: flex;
			justify-content: flex-end;
			> span {
				font-size: 1rem;
				margin-right: 0.5em;
				padding-left: ${th.ratios.l}rem;
			}
		}
		
		.${s('user')} {
			display: flex;
			justify-content: right;
			align-items: center;

			.${s('user', 'label')} {
				font-size: 1rem;
				color: ${th.colors.grey.plain};
				margin-right: 0.5em;
			}
		}
	}

	&.${s('layout', 'STANDARD')} {
		> header {
			.${s('toggler')},
			.${s('user')} {
				flex: 0 0 auto;
			}
			.${s('logo')},
			.${s('userLabel')} {
				flex: 1 1 4rem;
			}
			.${s('searchWrapper')} {
				flex: 1 1 auto;
				max-width: 60rem; // FIXME
			}
		}
	}
	
	&.${s('layout', 'COMPACT')},
	&.${s('layout', 'MINI')} {
		> header {
			padding-bottom: 0;
		}
		
		.${s('logo')} {
			flex: 1 1 auto;
			* {
				max-width: 100%;
				display: block;
				margin: 0 auto;
			}
		}
		
		.${s('userLabel')} {
			flex: 0 0 auto;
			width: 0;
			overflow: visible;
			white-space: nowrap;
		}
		
		.${s('searchWrapper')} {
			padding: ${th.ratios.xl}rem ${th.ratios.xl}rem ${th.ratios.xl * 2}rem;
		}
		
		&.${s('categorySearchForcedOpen')} {
			.${s('searchWrapper')} {
				padding-bottom: ${th.ratios.xl}rem;
			}
		}
	}
	
	&.${s('layout', 'COMPACT')} {
		.${s('logo')} > * {
			max-width: 10rem;
		}
	}
	
	&.${s('layout', 'MINI')} {
	
		.${s('userLabel')} {
			display: none;
		}
		
		.${s('searchWrapper')} {
			padding: ${th.ratios.xl}rem 0 ${th.ratios.xl * 2}rem;
		}
		
		> header {
			position: relative;
			.${s('toggler')},
			.${s('userLabel')},
			.${s('user')} {
				position: static;
			}
		}
		
		.${s('logo')} > * {
			max-width: 8rem;
		}
	}

	.${s('main', 'icon')},
	.${s('user', 'icon')} {
		font-size: ${th.ratios.l * 2}rem;
		color: ${th.colors.grey.plain};
	}
`,
);

const menuClass = registerClass(
	(th: IFNTheme, s) => `
.${s('container')} {
	position: relative;
	margin-top: ${th.ratios.l}rem;
	box-sizing: border-box;
	min-width: 20vw; // CHECK - Perché non sia troppo piccolo su desktop
	max-width: 100%;
	padding: ${th.ratios.l}rem;
	font-size: ${th.ratios.l}rem;
	background: ${th.colors.grey.dark};

	&:before {
		position: absolute;
		bottom: 100%;
		content: '';
		width: 0;
		height: 0;
		border-left: ${th.ratios.l}rem solid transparent;
		border-right: ${th.ratios.l}rem solid transparent;
		border-bottom: ${th.ratios.l}rem solid ${th.colors.grey.dark};
	}
}

.${s('close')} {
	position: absolute;
	font-size: ${th.ratios.xl}rem;
	right: ${th.ratios.l / 2}rem;
	top: ${th.ratios.l / 2}rem;
	cursor: pointer;
}

&,
a {
	color: ${th.colors.white};
	text-decoration: none;
}

&.${s('main')} {
	.${s('container')} {
		left: -${th.ratios.xs}rem;
		
		&:before {
			left: ${th.ratios.xs}rem;
		}
	}
	
	.${s('links')} {
		padding: ${th.ratios.l}rem 0;
		a {
			display: flex;
			align-items: center;
			> span {
				font-size: ${th.ratios.l}rem;
				padding-right: .5em;
			}
			&:not(:last-child) {
				padding-bottom: ${th.ratios.l}rem;
			}
		}
	}
	
	.${s('social')} {
		text-align: center;
		a {
			display: inline-block;
			margin: .5em .5em 0 .5em;
			width: ${th.ratios.xl}rem;
			height: ${th.ratios.xl}rem;
			font-size: ${th.ratios.l}rem;
			border-radius: ${th.radius}rem;
			background: ${th.colors.grey.dark};
			> span {
				line-height: ${th.ratios.l};
			}
		}
	}
}

&.${s('profile')} {
	.${s('container')} {
		right: -${th.ratios.xs}rem;
		
		&:before {
			right: ${th.ratios.xs}rem;
		}
	}
	
	header {
		padding-bottom: ${th.ratios.l}rem;
	}
	
	.${s('links')},
	.${s('recent')} {
		padding: ${th.ratios.l}rem 0;
		border-bottom: 1px solid ${th.colors.white};
		a {
			display: block;

			&:not(:last-child) {
				padding-bottom: ${th.ratios.l}rem;
			}
		}
	}
	
	.${s('recent')} {
		> div {
			padding-bottom: ${th.ratios.l}rem;
		}
		> a {
			font-size: 1rem;
		}
	}
	
	.${s('logout')} {
		padding-top: ${th.ratios.l}rem;
	}
}

&.${s('layout', 'MINI')} {
	max-width: 100vw !important;
	left: 0 !important;
	right: 0 !important;

	.${s('container')} {
		left: 0;
		right: 0;
	}
	
	&.${s('main')} {
		.${s('container')} {
			&:before {
				left: ${th.ratios.xs * 2}rem;
			}
		}
		a, a > span {
			font-size: 2rem;
		}
	}
	
	&.${s('profile')} {
		.${s('container')} {
			&:before {
				right: ${th.ratios.xs * 2}rem;
			}
		}
		a, header {
			font-size: 2rem;
		}
	}
}
`,
	true,
);

const valignFix = registerClass(() => `vertical-align: top;`);
const sc = currySC(styleClass);
const menuSC = currySC(menuClass);

export const Header: React.FC<HeaderProps> = ({ layout }) => {
	const { location } = useLocation();
	const track = useTracking();
	const [searchType, changeSearchType] = React.useState(SearchTypes.GOOD);
	const [mainMenuOpen, toggleMainMenu] = React.useState(false);
	const [userMenuOpen, toggleUserMenu] = React.useState(false);
	//const [categoryMenuOpen, toggleCategoryMenu] = React.useState(false);
	const mainMenuRef = useRef(null);
	const userMenuRef = useRef(null);
	const categoryRef = useRef(null);
	const where: TWhere = { type: 'Block', name: 'Header' };

	useEffect(() => {
		if (location.pathname.indexOf('/suppliers') !== -1) {
			changeSearchType(SearchTypes.COMPANY);
		}
	}, [location.pathname]);

	const mainToggler = useCallback(
		(open: boolean) => {
			track(
				{
					type: mainMenuOpen ? 'close' : 'open',
					what: { type: 'Block', name: 'MainMenu' },
				},
				addWhere(where),
			);
			toggleMainMenu(open);
		},
		[toggleMainMenu, track, mainMenuOpen],
	);

	const userToggler = useCallback(
		(open: boolean) => {
			track(
				{
					type: userMenuOpen ? 'close' : 'open',
					what: { type: 'Block', name: 'UserMenu' },
				},
				addWhere(where),
			);
			toggleUserMenu(open);
		},
		[toggleUserMenu, track, userMenuOpen],
	);

	const categorySearchForcedOpen =
		location.pathname === '/' || location.pathname === '/login';

	return (
		<WhereBlock step={where}>
			<UserData>
				{({ userData, isLogged }) => {
					const user = isLogged && userData;

					return (
						<Collapsible
							forceOpenState={categorySearchForcedOpen || undefined}
							startOpen={categorySearchForcedOpen || undefined}
						>
							{({ open, expand, collapse, CollapsibleBlockClass }) => {
								const toggleCategory = () => {
									if (open) {
										collapse();
									} else {
										expand();
									}
								};

								return (
									<div
										className={cns(
											styleClass,
											sc('layout', layout),
											categorySearchForcedOpen &&
												sc('categorySearchForcedOpen'),
										)}
									>
										<header className={mixin.limitedWidthPage}>
											<div
												className={sc('toggler')}
												tabIndex={0}
												ref={mainMenuRef}
												onClick={() => mainToggler(!mainMenuOpen)}
											>
												<Icon className={sc('main', 'icon')} name={'menu'} />
											</div>
											<Dropdown
												element={mainMenuRef}
												open={mainMenuOpen}
												onClickOutside={() => mainToggler(false)}
												className={cns(
													menuClass,
													menuSC('main'),
													menuSC('layout', layout),
												)}
											>
												<div className={menuSC('container')}>
													<div className={menuSC('close')}>
														<Icon
															name={'close'}
															onClick={() => mainToggler(false)}
														/>
													</div>
													<div className={menuSC('links')}>
														{menuLinks.map(
															({ label, link, icon, target }, i) =>
																/^https?:\/\//.exec(link) ? (
																	<a href={link} key={i} target={target}>
																		<Icon name={icon as MATERIAL} />
																		{t(label)}
																	</a>
																) : (
																	<Link
																		to={link}
																		key={i}
																		target={target}
																	>
																		<Icon name={icon as MATERIAL} />
																		{t(label)}
																	</Link>
																),
														)}
													</div>
												</div>
											</Dropdown>
											<div className={sc('logo')}>
												<Link to="/">
													<img src={Logo} alt={'logo italianfood.net'} />
												</Link>
											</div>

											{layout === headerLayouts.STANDARD && (
												<MainSearch
													layout={MainSearchLayout.STANDARD}
													className={sc('searchWrapper')}
													searchType={searchType}
													onSearchTypeChange={changeSearchType}
													openCategorySearch={toggleCategory}
												/>
											)}

											{/* se l'utente non e' loggato mostro il menu disabilitato */}
											{isLogged && (
												<>
													<div
														className={sc('userLabel')}
														tabIndex={0}
														onClick={() => userToggler(!userMenuOpen)}
													>
														<span className={sc('user', 'label')}>
															{user.name}
														</span>
													</div>
													<div
														className={sc('user')}
														tabIndex={0}
														ref={userMenuRef}
														onClick={() => userToggler(!userMenuOpen)}
													>
														<Icon
															className={sc('user', 'icon')}
															name={'person'}
														/>
													</div>
													<Dropdown
														element={userMenuRef}
														open={userMenuOpen}
														preferredAlignment={DropdownAlignments.End}
														onClickOutside={() => userToggler(false)}
														className={cns(
															menuClass,
															menuSC('profile'),
															menuSC('layout', layout),
														)}
													>
														<div className={menuSC('container')}>
															<div
																className={menuSC('close')}
																onClick={() => userToggler(false)}
															>
																<Icon name={'close'} />
															</div>
															<header>
																{t(`Hi,`)} {user.firstName || user.name}
															</header>
															{user.role === UserRole.Producer && (
																<div className={menuSC('links')}>
																	<a
																		href={'https://hub.italianfood.net'}
																	>
																		{t`Go to HUB`}{' '}
																		<Icon
																			className={valignFix}
																			name={'open_in_new'}
																		/>
																	</a>
																</div>
															)}
															<div className={menuSC('links')}>
																{profileLinks.map(
																	({ label, link }, i) => (
																		<Link to={link} key={i}>
																			{t(label)}
																		</Link>
																	),
																)}
															</div>
															<div className={menuSC('logout')}>
																<Logout />
															</div>
														</div>
													</Dropdown>
												</>
											)}

											{!isLogged && (
												<>
													<div
														className={sc('userLabel')}
														tabIndex={0}
														onClick={() => {
															track(
																{
																	type:
																		'requested_authentication_notlogged',
																	reason: 'trying to open user menu',
																},
																addWhere(where),
															);
															openRenewModal();
														}}
													>
														<span className={sc('user', 'label')}>
															{t('Sign in')}
														</span>
													</div>
													<div
														className={sc('user')}
														tabIndex={0}
														onFocus={() => openRenewModal()}
													>
														<Icon
															className={sc('user', 'icon')}
															name={'person_outline'}
														/>
													</div>
												</>
											)}
										</header>

										{layout !== headerLayouts.STANDARD && (
											<MainSearch
												layout={
													layout === headerLayouts.MINI
														? MainSearchLayouts.COMPACT
														: MainSearchLayouts.MINI
												}
												className={sc('searchWrapper')}
												searchType={searchType}
												onSearchTypeChange={changeSearchType}
												openCategorySearch={
													categorySearchForcedOpen
														? undefined
														: toggleCategory
												}
											/>
										)}

										<CollapsibleBlockClass>
											<footer>
												{open && (
													<CategorySearch
														layout={
															layout === headerLayouts.STANDARD
																? CategorySearchBlockLayouts.STANDARD
																: CategorySearchBlockLayouts.COMPACT
														}
														searchType={searchType}
														onSearchTypeChange={changeSearchType}
														customRef={categoryRef}
														closeHandler={() => collapse()}
														forced={categorySearchForcedOpen}
													/>
												)}
											</footer>
										</CollapsibleBlockClass>
									</div>
								);
							}}
						</Collapsible>
					);
				}}
			</UserData>
		</WhereBlock>
	);
};
