import { useState, useEffect, useContext } from 'react';
// @ts-ignore
import { __RouterContext as RouterContext } from 'react-router';

const useDebounce = (value: any, delay: number) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => setDebouncedValue(value), delay);
		return () => clearTimeout(handler);
	}, [value, delay]);

	return debouncedValue;
};

function useRouter() {
	return useContext(RouterContext);
}

function useParams() {
	// @ts-ignore
	const { match } = useRouter();
	return match.params;
}

function useLocation() {
	// @ts-ignore
	const { location, history } = useRouter();

	function navigate(to, { replace = false } = {}) {
		if (replace) {
			history.replace(to);
		} else {
			history.push(to);
		}
	}

	return {
		location,
		navigate,
	};
}

export { useDebounce, useRouter, useLocation, useParams };
