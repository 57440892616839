import React, { useCallback, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { stringify } from 'querystring';
import {
	Box,
	Button,
	ButtonLayouts,
	Icon,
	IconPositions,
	Modal2 as Modal,
} from '@food/ui';
import { openRenewModal, UserData } from '@food/auth';
import { cns, currySC, registerClass, subClass } from '@food/css-manager';
import t from '../../utils/labels';
import { IFNTheme, mixin, theme } from '../../utils/theme';
import { ENTITY } from '../../utils/entities';
import { html, nodes, pluralEntityLabel } from '../../utils/misc';
import { ProfileListModal } from '../ProfileListModal';
import { ACTIONS, generateUrl } from '../../utils/urls';
import * as sfondo from '../../static/assets/pattern_blu.jpg';
import * as bannerNSL from '../../static/assets/banner-nsl-800-01.jpg';
import { UserLists } from '../UserLists';
import { deleteNode } from '../../utils/graphql';
import { GenericHead } from '../head/GenericHead';
import { sentryHandler } from '../../utils/errors';
import { useTracking, WhereBlock } from '../../utils/tracking';
import { TWhere } from '../../tracking-types';
import { companyCardWhere, goodCardWhere } from '../../utils/where';
import { SearchTypes } from '../../utils/frequentSearch';
import { ApolloError, ApolloQueryResult } from 'apollo-client';

export enum ProfileLayouts {
	STANDARD = 'STANDARD',
	COMPACT = 'COMPACT',
	MINI = 'MINI',
}

interface ProfileProps {
	layout?: ProfileLayouts;
}

export function profileLayoutChooser(w: number): ProfileLayouts {
	const { mobileMax, notebookMax } = theme.breakpoints;
	if (w <= mobileMax) {
		return ProfileLayouts.MINI;
	} else if (w <= notebookMax) {
		return ProfileLayouts.COMPACT;
	} else {
		return ProfileLayouts.STANDARD;
	}
}

export const profileQuery = gql`
	query ProfileQuery(
		$goodFSLimit: Int
		$companyFSLimit: Int
		$goodFavLimit: Int
		$companyFavLimit: Int
		$favGoodId: ID!
		$favCompanyId: ID!
	) {
		CompanyFrequentSearch: frequentSearchs(
			filter: { mine: true, collection: COMPANY, isDynamic: true }
			first: $companyFSLimit
		) {
			edges {
				node {
					id
					name
				}
			}
			total
		}
		GoodFrequentSearch: frequentSearchs(
			filter: { mine: true, collection: GOOD, isDynamic: true }
			first: $goodFSLimit
		) {
			edges {
				node {
					id
					name
				}
			}
			total
		}
		goodsDir(filter: { FrequentSearch: { id: $favGoodId } }, first: $goodFavLimit) {
			edges {
				node {
					... on Good {
						id
						name
						slug
						Brand {
							id
							Company {
								id
							}
						}
					}
				}
			}
			total
		}
		companiesDir(
			filter: { FrequentSearch: { id: $favCompanyId }, types: [Company] }
			first: $companyFavLimit
		) {
			edges {
				node {
					... on Company {
						id
						name
						slug
					}
				}
			}
			total
		}
	}
`;

export const frequentSearchModalQuery = gql`
	query frequentSearchModalQuery(
		$filter: FrequentSearchFilterInput
		$first: Int
		$after: String
	) {
		frequentSearchs(filter: $filter, after: $after, first: $first) {
			pageInfo {
				hasNextPage
				endCursor
			}
			total
			edges {
				node {
					id
					name
				}
			}
		}
	}
`;

const frequentSearchTotalExtractor = (data) => data.frequentSearchs.total;

const profileStyle = registerClass(
	(theme: IFNTheme, sc) => `
	background: url('${sfondo}');
	padding: ${theme.ratios.xl / 2}rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	
	.${sc('profile')} {
		color: ${theme.colors.inkWhite};
		flex-basis: 66%;
		padding: ${theme.ratios.xl / 2}rem;
		margin-right: ${theme.ratios.xl / 2}rem;
		
		a {
			color: ${theme.colors.inkWhite};
		}
		
		> div {
			padding-bottom: ${theme.ratios.xs}rem;

			&:last-child {
				padding-bottom: 0;
			}

			> a {
				font-style: italic;
				font-weight: ${theme.font.weights.light};
				font-size: ${theme.ratios.s}rem;
				margin-left: ${theme.ratios.xs}rem;
			}
		}
		
		.${sc('profile', 'title')} {
			font-size: ${theme.ratios.l * 2}rem;
			padding-bottom: ${theme.ratios.xs}rem;
			display: inline-block;
			font-weight: ${theme.font.weights.semibold};
		}
		
		.${sc('profile', 'label')} {
			font-size: ${theme.ratios.l}rem;
			font-style: italic;
		}
		
		.${sc('profile', 'value')} {
			font-size: ${theme.ratios.l}rem;
			font-weight: ${theme.font.weights.semibold};
		}
		
	}

	.${sc('goto')} {

		> a {
			text-align: right;
			display: block;
			font-style: italic;
			color: ${theme.colors.ink};
			margin-top: ${theme.ratios.xl}rem;
		}
	}

	.${sc('section-box')} {
		flex: 1 1 31%;
		min-width: 30rem; // FIXME
		margin: ${theme.ratios.xl / 2}rem;
		border-radius: ${theme.radius}rem ${theme.radius}rem 0 0;

		> header > div {
			color: ${theme.colors.grey.plain};
		}
		

		> div {
			> div {
				line-height: ${theme.ratios.xl}rem;
				> span {
					color: ${theme.colors.grey.plain};
					font-size: ${theme.ratios.l}rem;
					display: block;
				}
			}
		}
		
		ul {
			margin-bottom: ${theme.ratios.xs}rem;
			
			> li {
			
				&::before {
					color: ${theme.colors.grey.plain};
					margin-right: ${theme.ratios.xs}rem;
					font-size: ${theme.ratios.s}rem;
					position: relative;
					vertical-align: middle;
				}
				
				> a {
					display: inline;
					color: ${theme.colors.alt.plain};
					vertical-align: middle;
				}
				
				> .${sc('remove')} {
					cursor: pointer;
					color: ${theme.colors.grey.plain};
					margin-left: ${theme.ratios.xs}rem;
					vertical-align: middle;
					&:hover {
						color: ${theme.colors.danger.plain};
					}
				}
			}
		}
	}

	.${sc('section-box', 'content-split')} {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 -${theme.ratios.xl * 1.5}rem;
		
		> * {
			flex: 1 1 40%;
			min-width: 12rem;
			margin: 0 ${theme.ratios.xl * 1.5}rem;
		}
	}

	&.${sc('container-layout-MINI')} {
		.${sc('section-box')} {
			min-width: auto;
		}

		> div {
			flex-basis: 100%;
		}

		.${sc('section-box', 'content-split')} {
			display: block;
			> * {
				min-width: auto;
			}
		}
	}
	
	.${sc('label')} {
		display: block;
		font-weight: ${theme.font.weights.semibold};
	}
	
	.${sc('see-more-btn')} {
		text-align: right;
	}
	
	.${sc('edit-btn')} {
		margin-left: ${theme.ratios.l}rem;
	}
	
	.${sc('special-banner')} {
		background-color: white;
		padding: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.${sc('special-banner')} > a > img {
		max-width: 100%;
	}
	
	&.${sc('container-layout-STANDARD')} .${sc('special-banner')} > a > img {
		width: 100%;
		max-width: 400px;
	}
	`,
);

const editDataModal = registerClass(
	(theme: IFNTheme, s) => `
	
	.${s('actions')} {
		margin-top:  ${theme.ratios.s}rem;
		font-size: ${theme.ratios.l}rem;
		text-align: right;
	}
`,
);

const sc = currySC(profileStyle);
const fsLimit = 5;
const favLimit = 5;

export const Profile: React.FC<ProfileProps> = ({ layout = 'STANDARD' }) => {
	const [dataModalOpen, setDataModalOpen] = useState(false);
	const [fsCompanyModalOpen, setFSCompanyModalOpen] = useState(false);
	const [fsGoodModalOpen, setFSGoodModalOpen] = useState(false);
	const track = useTracking();
	const fsGoodModalWhere: TWhere = {
		type: 'Block',
		name: 'Good Frequent Searches Modal',
	};
	const fsCompanyModalWhere: TWhere = {
		type: 'Block',
		name: 'Company Frequent Searches Modal',
	};
	const trackFSModalStatus = useCallback(
		(what: TWhere, open: boolean) =>
			track({
				type: open ? 'open' : 'close',
				what,
			}),
		[],
	);

	return (
		<>
			<GenericHead title={t`Your Profile`} />
			<UserData>
				{({ userData, loading, managedCompany, isLogged }) => {
					if (loading) {
						return <Redirect to={'/'} />;
					}
					if (!isLogged) {
						track({
							type: 'requested_authentication_notlogged',
							reason: 'view profile',
						});
						openRenewModal();
						return <div />;
					}

					return (
						<UserLists>
							{({
								loading,
								favoritesCompanies,
								favoritesGoods,
								removeFromFavourites,
							}) => {
								if (loading) {
									return <div />;
								}
								return (
									<Query
										query={profileQuery}
										variables={{
											goodFSLimit: fsLimit,
											companyFSLimitL: fsLimit,
											companyFavLimit: favLimit,
											goodFavLimit: favLimit,
											favGoodId: favoritesGoods.id,
											favCompanyId: favoritesCompanies.id,
										}}
										fetchPolicy={'cache-first'} // Faccio sempre la query perché i dati potrebbero essere cambiati
									>
										{({
											error,
											loading,
											data,
											refetch,
										}: {
											loading: boolean;
											error?: ApolloError;
											data: any;
											refetch(
												variables?: any,
											): Promise<ApolloQueryResult<any>>;
										}) => {
											if (loading) {
												return null;
											}

											if (error) {
												sentryHandler([error]);
												console.error(error);
												return <Redirect to={'/error'} />;
											}

											const companyFS = nodes(data.CompanyFrequentSearch);
											const goodFS = nodes(data.GoodFrequentSearch);
											const companyFSTotal = data.CompanyFrequentSearch.total;
											const goodFSTotal = data.GoodFrequentSearch.total;
											const goodFavTotal = favoritesGoods.goods.length;
											const companyFavTotal =
												favoritesCompanies.companies.length;

											const removeFs = async (
												fsId: string,
												type: SearchTypes,
											) => {
												await deleteNode(fsId);
												track({
													type: 'removed_dynamic_list',
													id: fsId,
													entityType: type,
												});
												refetch();
											};

											// filtro i preferiti delle rispettive entita' perche' potrei aver rimosso alcuni preferiti dalla lista
											// ma posso appoggiarmi alle modifiche fatte su UserList tramite i suoi helper
											const goodFavs = nodes(data.goodsDir).filter((good) =>
												favoritesGoods.goods.some(
													(good2) => good2.id === good.id,
												),
											);

											// in questo modo non sono costretto ad aggiornare / scaricare di nuovo la query principale del profilo
											const companyFavs = nodes(
												data.companiesDir,
											).filter((company) =>
												favoritesCompanies.companies.some(
													(company2) => company2.id === company.id,
												),
											);

											const editBlock = (
												<Button
													className={sc('edit-btn')}
													label={t`edit`}
													layout={ButtonLayouts.LINK}
													onClick={(e) => {
														e.preventDefault();
														track({
															type: 'generic_click',
															name: 'edit credentials',
														});
														setDataModalOpen(true);
													}}
												/>
											);

											const editModalHtml = t(
												userData.role + ' no edit profile html',
											);

											return (
												<div
													className={cns(
														profileStyle,
														mixin.mainPageContent, // IMPORTANT - altrimenti rimane ua banda chiara sotto se la pagina è corta
														sc('container-layout', layout),
													)}
												>
													<ProfileListModal
														title={t`Supplier Frequent Searches`}
														open={fsCompanyModalOpen}
														toggle={() => {
															trackFSModalStatus(
																fsCompanyModalWhere,
																!fsCompanyModalOpen,
															);
															setFSCompanyModalOpen(!fsCompanyModalOpen);
														}}
														filter={{
															collection: ENTITY.COMPANY.toUpperCase(),
														}}
														query={frequentSearchModalQuery}
														totalExtractor={frequentSearchTotalExtractor}
														type={ENTITY.COMPANY}
													/>

													<ProfileListModal
														title={t`Products Frequent Searches`}
														open={fsGoodModalOpen}
														toggle={() => {
															trackFSModalStatus(
																fsGoodModalWhere,
																!fsGoodModalOpen,
															);
															setFSGoodModalOpen(!fsGoodModalOpen);
														}}
														filter={{
															collection: ENTITY.GOOD.toUpperCase(),
														}}
														query={frequentSearchModalQuery}
														totalExtractor={frequentSearchTotalExtractor}
														type={ENTITY.GOOD}
													/>

													<Modal
														open={dataModalOpen}
														closeHandler={() => setDataModalOpen(false)}
													>
														{() => (
															<Box
																action={() => (
																	<Icon
																		name={'close'}
																		onClick={() =>
																			setDataModalOpen(false)
																		}
																	/>
																)}
																title={t`Edit profile`}
															>
																<div className={editDataModal}>
																	<p>Hello {userData.name},</p>
																	<p
																		dangerouslySetInnerHTML={html(
																			editModalHtml,
																		)}
																	/>
																	<div
																		className={subClass(
																			editDataModal,
																			'actions',
																		)}
																	>
																		<Button
																			label={t`Write us`}
																			iconPos={IconPositions.RIGHT}
																			icon={'mail'}
																			linkTo={
																				'mailto:support@italianfood.net'
																			}
																			layout={ButtonLayouts.BUTTON}
																		/>
																	</div>
																</div>
															</Box>
														)}
													</Modal>

													<div className={sc('profile')}>
														<div>
															<span className={sc('profile', 'title')}>
																{userData.name}
															</span>
															{editBlock}
														</div>
														{managedCompany && (
															<div>
																<span className={sc('profile', 'label')}>
																	{t`Supplier/Retailer`}:
																</span>{' '}
																<span className={sc('profile', 'value')}>
																	{managedCompany.Company.name}
																</span>
																{editBlock}
															</div>
														)}
														{userData.workEmail && (
															<div>
																<span className={sc('profile', 'label')}>
																	{t`Work email`}:
																</span>{' '}
																<span className={sc('profile', 'value')}>
																	{userData.workEmail}
																</span>
																{editBlock}
															</div>
														)}
														<div>
															<span className={sc('profile', 'label')}>
																{t`Role`}:
															</span>{' '}
															<span className={sc('profile', 'value')}>
																{t('ROLE/' + userData.role)}
															</span>
															{editBlock}
														</div>
													</div>

													<WhereBlock
														step={{
															type: 'Block',
															name: 'ProfileSpecialBanner',
														}}
													>
														<div
															className={cns(
																sc('section-box'),
																sc('special-banner'),
															)}
														>
															<a
																target={'_blank'}
																href={
																	'https://www.calameo.com/read/0004501540e5e400d9641?authid=5S4eWeuid7dA'
																}
															>
																<img
																	src={(bannerNSL as unknown) as string}
																	alt={t('ifn products pdf logo')}
																/>
															</a>
														</div>
													</WhereBlock>

													<Box
														title={`${t`Your favorites`} ${t(
															pluralEntityLabel(ENTITY.GOOD),
														)} (${goodFavTotal})`}
														className={sc('section-box')}
													>
														<div>
															{goodFavTotal === 0 && t`No entities text`}
															{goodFavTotal > 0 && (
																<>
																	<ul className={mixin.bulletedList}>
																		{goodFavs.map((entity) => (
																			<li key={entity.id}>
																				<Link
																					to={generateUrl(
																						ENTITY.GOOD,
																						ACTIONS.DETAIL,
																						entity.slug,
																					)}
																				>
																					{entity.name}
																				</Link>
																				<Icon
																					className={sc('remove')}
																					name={'close'}
																					onClick={(e) => {
																						e.preventDefault();
																						e.stopPropagation();
																						track({
																							type: 'unbookmark',
																							what: goodCardWhere(
																								entity,
																							),
																						});
																						removeFromFavourites(
																							entity.id,
																							ENTITY.GOOD,
																						);
																					}}
																				/>
																			</li>
																		))}
																	</ul>
																	<div className={sc('see-more-btn')}>
																		<Button
																			label={t`See all in explorer`}
																			icon={'launch'}
																			iconPos={IconPositions.RIGHT}
																			type="alt"
																			onClick={() =>
																				track({
																					type: 'do_search',
																					target: 'GoodDirectory',
																					filterStatus: {
																						FrequentSearch:
																							favoritesGoods.id,
																					},
																				})
																			}
																			linkTo={
																				generateUrl(
																					ENTITY.GOOD,
																					ACTIONS.LIST,
																				) +
																				'?FrequentSearch=' +
																				encodeURIComponent(
																					favoritesGoods.id,
																				)
																			}
																		/>
																	</div>
																</>
															)}
														</div>
													</Box>

													<Box
														title={`${t`Your favorites`} ${t(
															pluralEntityLabel(ENTITY.COMPANY),
														)} (${companyFavTotal})`}
														className={sc('section-box')}
													>
														<div>
															{companyFavTotal === 0 &&
																t`No entities text`}
															{companyFavTotal > 0 && (
																<>
																	<ul className={mixin.bulletedList}>
																		{companyFavs.map((entity) => (
																			<li key={entity.id}>
																				<Link
																					to={generateUrl(
																						ENTITY.COMPANY,
																						ACTIONS.DETAIL,
																						entity.slug,
																					)}
																				>
																					{entity.name}
																				</Link>
																				<Icon
																					className={sc('remove')}
																					name={'close'}
																					onClick={(e) => {
																						e.preventDefault();
																						e.stopPropagation();
																						track({
																							type: 'unbookmark',
																							what: companyCardWhere(
																								entity,
																							),
																						});
																						removeFromFavourites(
																							entity.id,
																							ENTITY.COMPANY,
																						);
																					}}
																				/>
																			</li>
																		))}
																	</ul>
																	<div className={sc('see-more-btn')}>
																		<Button
																			label={t`See all in explorer`}
																			icon={'launch'}
																			iconPos={IconPositions.RIGHT}
																			type="alt"
																			onClick={() =>
																				track({
																					type: 'do_search',
																					target: 'CompanyDirectory',
																					filterStatus: {
																						FrequentSearch:
																							favoritesCompanies.id,
																					},
																				})
																			}
																			linkTo={
																				generateUrl(
																					ENTITY.COMPANY,
																					ACTIONS.LIST,
																				) +
																				'?FrequentSearch=' +
																				encodeURIComponent(
																					favoritesCompanies.id,
																				)
																			}
																		/>
																	</div>
																</>
															)}
														</div>
													</Box>

													<Box
														title={t`Your Dynamic Lists`}
														className={sc('section-box')}
													>
														<div
															className={sc(
																'section-box',
																'content-split',
															)}
														>
															<div>
																<span className={sc('label')}>
																	{t(pluralEntityLabel(ENTITY.GOOD))}:
																</span>
																{goodFSTotal === 0 && t`No entities text`}
																{goodFSTotal > 0 && (
																	<>
																		<ul className={mixin.bulletedList}>
																			{goodFS.map((fs) => (
																				<li key={fs.id}>
																					<Link
																						to={{
																							pathname: generateUrl(
																								ENTITY.GOOD,
																								ACTIONS.LIST,
																							),
																							search: stringify({
																								FrequentSearch:
																									fs.id,
																							}),
																						}}
																					>
																						{fs.name}
																					</Link>
																					<Icon
																						className={sc('remove')}
																						name={'close'}
																						onClick={() =>
																							removeFs(
																								fs.id,
																								SearchTypes.GOOD,
																							)
																						}
																					/>
																				</li>
																			))}
																		</ul>
																		{goodFSTotal > fsLimit && (
																			<Button
																				onClick={() => {
																					trackFSModalStatus(
																						fsGoodModalWhere,
																						true,
																					);
																					setFSGoodModalOpen(true);
																				}}
																				label={t`See all`}
																				layout={ButtonLayouts.LINK}
																			/>
																		)}
																	</>
																)}
															</div>
															<div>
																<span className={sc('label')}>
																	{t(pluralEntityLabel(ENTITY.COMPANY))}:
																</span>
																{companyFSTotal === 0 &&
																	t`No dynamic list text`}
																{companyFSTotal > 0 && (
																	<>
																		<ul className={mixin.bulletedList}>
																			{companyFS.map((fs) => (
																				<li key={fs.id}>
																					<Link
																						to={{
																							pathname: generateUrl(
																								ENTITY.COMPANY,
																								ACTIONS.LIST,
																							),
																							search: stringify({
																								FrequentSearch:
																									fs.id,
																							}),
																						}}
																					>
																						{fs.name}
																					</Link>
																					<Icon
																						className={sc('remove')}
																						name={'close'}
																						onClick={() =>
																							removeFs(
																								fs.id,
																								SearchTypes.COMPANY,
																							)
																						}
																					/>
																				</li>
																			))}
																		</ul>
																		{companyFSTotal > fsLimit && (
																			<Button
																				onClick={() => {
																					trackFSModalStatus(
																						fsCompanyModalWhere,
																						true,
																					);
																					setFSCompanyModalOpen(
																						true,
																					);
																				}}
																				label={t`See all`}
																				layout={ButtonLayouts.LINK}
																			/>
																		)}
																	</>
																)}
															</div>
														</div>
													</Box>
												</div>
											);
										}}
									</Query>
								);
							}}
						</UserLists>
					);
				}}
			</UserData>
		</>
	);
};
