import React, { useEffect, useRef } from 'react';
import { Modal2 as Modal } from '@food/ui';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Home, homeLayoutChooser } from './Home';
import { ErrorModal } from './ErrorModal';
import { setErrorModal } from '../utils/errors';
import { AuthRoutes, AuthContext } from '@food/auth';
import { CompanyDirectoryRoute } from './entities/CompanyDirectory';
import { authConfig, authManager, onRedirect } from '../utils/auth';
import t from '../utils/labels';
import { GoodDirectoryRoute } from './entities/GoodDirectory';
import { Privacy } from './blocks/Privacy';
import { AboutUs } from './blocks/AboutUs';
import { genericPageFactory } from './pages/GenericPage';
// import { HowItWorks } from './blocks/HowItWorks';
import { Profile, profileLayoutChooser } from './blocks/Profile';
import { SinglePage } from './pages/SinglePage';
import { directoryLayoutChooser } from './Directory';
import ScrollToTop from './UI/ScrollToTop';
import { NotFound, notFoundLayoutChooser } from './blocks/NotFound';
import { ErrorComponent, errorLayoutChooser } from './blocks/Error';
//import { CookiePolicyBanner } from './banners/CookiePolicyBanner';
import { Advertise } from './blocks/Advertise';
import { canUseDOM } from 'exenv';
import { BannerSwitch } from './BannerSwitch';
import { FairDirectory, fairDirectoryLayoutChooser } from './entities/FairDirectory';
import { GenericHead } from './head/GenericHead';
import { ExtendContext, useTracking } from '../utils/tracking';
import { useLocation } from '../utils/hooks';
import { TWhere } from '../tracking-types';
import { trackingBase } from '../utils/tracking';
import { FrequentSearchDirectory } from './FrequentSearchDirectory';
import { BuyersIntro } from './blocks/BuyersIntro';
import { ProducersIntro } from './blocks/ProducersIntro';

// const HowItWorksPage = genericPageFactory(HowItWorks, () => '', 'HowItWorks');
const ProfilePage = genericPageFactory(Profile, profileLayoutChooser, 'Profile');
const AboutUsPage = genericPageFactory(AboutUs, () => '', 'AboutUs');
const PrivacyPage = genericPageFactory(Privacy, () => '', 'Privacy');
const HomePage = genericPageFactory(Home, homeLayoutChooser, 'Home');
const NotFoundPage = genericPageFactory(NotFound, notFoundLayoutChooser, 'NotFound');
const ErrorPage = genericPageFactory(ErrorComponent, errorLayoutChooser, 'Error');
const BuyersIntroPage = genericPageFactory(BuyersIntro, () => '', 'Buyers Introduction');
const ProducersIntroPage = genericPageFactory(
	ProducersIntro,
	() => '',
	'Suppliers Introduction',
);
const CompanyDirectoryPage = genericPageFactory(
	CompanyDirectoryRoute,
	directoryLayoutChooser,
	'CompanyDirectory',
);
const GoodDirectoryPage = genericPageFactory(
	GoodDirectoryRoute,
	directoryLayoutChooser,
	'GoodDirectory',
);
const FairDirectoryPage = genericPageFactory(
	FairDirectory,
	fairDirectoryLayoutChooser,
	'FairDirectory',
);
const AdvertisePage = genericPageFactory(Advertise, () => '', 'Advertise');
const FrequentSearchDirectoryPage = genericPageFactory(
	FrequentSearchDirectory,
	directoryLayoutChooser,
	'FrequentSearchDirectory',
);

const AuthContainer: React.FC = ({ children }) => {
	const history = useHistory();
	return (
		<>
			<Modal open={true} closeHandler={() => history.push('/')}>
				{() => <>{children}</>}
			</Modal>
			<HomePage />
		</>
	);
};

const App: React.FC = () => {
	const errorModal = useRef<ErrorModal>(null);
	const { location } = useLocation();
	const track = useTracking();

	useEffect(() => {
		setErrorModal(errorModal.current);
		track({ type: 'entryPoint', url: location.pathname + location.search });
	});

	return (
		<React.StrictMode>
			<ExtendContext
				transformer={(oldContext) => ({
					...oldContext,
					...trackingBase,
					where: [
						{ type: 'Route', url: location.pathname + location.search } as TWhere,
					],
				})}
			>
				<GenericHead />
				{canUseDOM && <BannerSwitch />}
				<ErrorModal ref={errorModal} />
				<ScrollToTop>
					<AuthContext>
						<AuthRoutes
							config={authConfig}
							authManager={authManager}
							t={t}
							container={AuthContainer}
							onRedirect={onRedirect}
						>
							<Switch>
								{/*
									NOTE: everytime a route get added to the list below it's necessary to inform Alessandro so he can update the sitemap.
								*/}
								<Route path="/" exact component={HomePage} />
								<Route path="/profile" exact component={ProfilePage} />
								<Route path="/suppliers/:slug" exact component={SinglePage} />
								<Route path="/privacy-policy" exact component={PrivacyPage} />
								<Route path="/about-us" exact component={AboutUsPage} />
								<Route
									path="/buyers-introduction"
									exact
									component={BuyersIntroPage}
								/>
								<Route
									path="/suppliers-introduction"
									exact
									component={ProducersIntroPage}
								/>
								<Route path="/goods/:slug" exact component={SinglePage} />
								<Route path="/products/:slug" exact component={SinglePage} />
								<Route path="/tradeshows/:slug" exact component={SinglePage} />
								<Route path="/suppliers" exact component={CompanyDirectoryPage} />
								<Route path="/goods" exact component={GoodDirectoryPage} />
								<Route path="/products" exact component={GoodDirectoryPage} />
								<Route path="/ads" exact component={AdvertisePage} />
								<Route path="/error" exact component={ErrorPage} />
								<Route path="/tradeshows" exact component={FairDirectoryPage} />
								<Route
									path={'/frequentsearches/:id'}
									exact
									component={FrequentSearchDirectoryPage}
								/>
								<NotFoundPage />
							</Switch>
						</AuthRoutes>
					</AuthContext>
				</ScrollToTop>
			</ExtendContext>
		</React.StrictMode>
	);
};

export default App;
