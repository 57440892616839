import React, { useCallback, useEffect, useState } from 'react';
import { Modal2 as Modal, Button, Box, Icon, ButtonLayouts } from '@food/ui';
import t from '../utils/labels';
import { IFNTheme } from '../utils/theme';
import { registerClass, currySC } from '@food/css-manager';

export interface ISaveFrequentSearchResult {
	result?: any;
	error?: Error;
}

interface SaveFrequentSearchModalProps {
	open: boolean;
	mutation: (s: string) => ISaveFrequentSearchResult;
	onSave: (name: string, r: ISaveFrequentSearchResult) => void;
	onClose: () => void;
}

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	p {
		text-align: justify;
	}
	
	input {
		font-family: monospace;
		width: 100%;
		padding: ${t.ratios.xs / 2}em;
		margin-bottom: ${t.ratios.xs}em;
		margin-top: ${t.ratios.l}em;
		
		&::placeholder {
			color: ${t.colors.grey.plain};
		}
	}
	
	.${sc('button-container')} {
		text-align: right;
		font-size: ${t.ratios.l}rem;
		
		> button {
			margin-left: ${t.ratios.xs}rem;
		}
	}
`,
);

const sc = currySC(styleClass);
const nameMaxLength = 50;

export const SaveFrequentSearchModal: React.FC<SaveFrequentSearchModalProps> = ({
	open,
	onSave,
	mutation,
	onClose,
}) => {
	const [name, setName] = useState('');

	useEffect(() => {
		if (open) {
			setName('');
		}
	}, [open]);

	const save = useCallback(async () => {
		try {
			const { result } = await mutation(name);
			onSave(name, { result });
		} catch (e) {
			onSave(name, { error: e }); // TODO handle!
		}
	}, [mutation, name, onSave]);

	const sendDisabled = name.length < 3;

	return (
		<Modal open={open} closeHandler={() => onClose()}>
			{() => (
				<Box
					title={t`Save as a dynamic list`}
					className={styleClass}
					action={() => <Icon name={'close'} onClick={() => onClose()} />}
				>
					<p>{t`save dynamic search intro text`}</p>
					<input
						value={name}
						onChange={(e) => setName(e.target.value.substring(0, nameMaxLength))}
						placeholder={t`Insert list name here`}
						onKeyPress={(e) => {
							if (e.key === 'Enter' && !sendDisabled) {
								save();
							}
						}}
					/>
					<div className={sc('button-container')}>
						<Button
							label={t`Cancel`}
							onClick={() => {
								setName('');
								onClose();
							}}
							layout={ButtonLayouts.LINK}
						/>
						<Button
							label={t`Save`}
							disabled={sendDisabled}
							type={sendDisabled ? 'disabled' : 'success'}
							onClick={() => save()}
						/>
					</div>
				</Box>
			)}
		</Modal>
	);
};
