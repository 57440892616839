import React, { useContext } from 'react';
import { CompanyCard, CompanyCardFragment, CompanyCardLayout } from './CompanyCard';
import { Query } from 'react-apollo';
import { IFNTheme, mixin } from '../../utils/theme';
import t from '../../utils/labels';
import { DefaultSetter, identity, nodes } from '../../utils/misc';
import { NameFilter } from '../filters/NameFilter';
import { PrivateManifacturerFilter } from '../filters/PrivateManifacturerFilter';
import gql from 'graphql-tag';
import {
	Directory,
	DirectoryLayouts,
	DirectoryViewLayouts,
	IDirectoryFilterDefinition,
	moduleFilter,
} from '../Directory';
import { FilterTags } from '../UI/FilterTags';
import { SearchTypes } from '../../utils/frequentSearch';
import { ItalianRegionsFilter } from '../filters/ItalianRegionsFilter';
import { BrandCard, BrandCardFragment } from './BrandCard';
import { cns, registerClass, subClass } from '@food/css-manager';
import { loadable, LoadableDirection, loadDelayStyle } from '../../utils/loadable';
import { getQuery } from '../../utils/graphql';
import { renderContext } from '../../utils/context/renderContext';
import { DirectoryHead } from '../head/DirectoryHead';
import { ENTITY } from '../../utils/entities';
import { SuggestedFSCard, SuggestedFSCardLayout } from '../SuggestedFSCard';
import { goodClassesDirectoryFilter } from '../../utils/directory';
import { UserLists } from '../UserLists';
import { frequentSearchQuery } from '../../graphql/frequentSearchQuery';
import { WhereBlock } from '../../utils/tracking';
import { companyDirectoryWhere } from '../../utils/where';
import { FrequentSearchLinkFragment } from './FrequentSearchCard';
import {
	CompanyCertificationFilter,
	CompanyCertificationFilterQuery,
} from '../filters/CompanyCertificationFilter';
import { regions } from '../../static/regions';
import { ApolloError } from 'apollo-client';

export const CompanyDirectoryQuery = gql`
	query CompanyDirectoryQuery(
		$filter: CompaniesFilterType
		$first: Int
		$cursor: String
	) {
		entities: companiesDir(filter: $filter, first: $first, after: $cursor) {
			edges {
				node {
					__typename
					... on Node {
						id
					}
					... on Company {
						...CompanyCardFragment
					}
					... on Brand {
						...BrandCardFragment
					}
				}
			}
			total
			pageInfo {
				hasNextPage
				startCursor
			}
			facet {
				goodClasses {
					value
					count
				}
				plProducer {
					value
					count
				}
				stateProvince {
					value
					count
				}
				revenue {
					value
					count
				}
				companyCertifications {
					value
					count
				}
			}
			relatedFrequentSearches {
				id
				name
				linkUrl
				relatedFair {
					id
					name
					year
					edition
					slug
				}
				...FrequentSearchLink
			}
		}
	}
	${FrequentSearchLinkFragment}
	${CompanyCardFragment}
	${BrandCardFragment}
`;

export const fairQuery = gql`
	query FairName($id: ID!) {
		fair: node(id: $id) {
			id
			... on Fair {
				name
				edition
				year
			}
		}
	}
`;

const shadowChildrenClass = registerClass(
	(t: IFNTheme) => `
	> * {
		box-shadow: ${t.boxShadow(1)};
	}
`,
);

export const CompanyDirectoryFilters: { [key: string]: IDirectoryFilterDefinition } = {
	textSearch: {
		component: NameFilter,
		key: 'textSearch',
		fromUrl: identity,
		toUrl: identity,
	},
	goodClasses: goodClassesDirectoryFilter,
	companyCertifications: {
		key: 'companyCertifications',
		title: t`Supplier certifications`,
		query: CompanyCertificationFilterQuery,
		component: CompanyCertificationFilter,
		extractor: (data: any) =>
			nodes(data.companyCertifications).map(({ id, name }) => ({ id, name })),
		toUrl: (v: string[]) => v.join(','),
		fromUrl: (v: string) => v.split(','),
		facetExtractor: (data: any) => (possibleValue) => {
			if (!data || !data.entities || !data.entities.facet) {
				return 0;
			}
			const ar = data.entities.facet.companyCertifications.find(
				(ar) => ar.value === possibleValue.id,
			);
			if (ar) {
				return Number(ar.count);
			}
			return 0;
		},
		serializer: (v: string[]) => v.map((id) => ({ id })),
	},
	stateProvinces: {
		key: 'stateProvinces',
		title: t`Italian Region`,
		component: ItalianRegionsFilter,
		extractor: () => Object.keys(regions).map((r) => ({ id: r, name: r })),
		toUrl: (v: string[]) => v.join(','),
		fromUrl: (v: string) => v.split(','),
		serializer: (rs: string[]) =>
			rs.reduce(
				(total, region) => total.concat(regions[region].map((id) => ({ id }))),
				[],
			),
		facetExtractor: (data: any) => (possibleValue: any) => {
			if (
				!data ||
				!data.entities ||
				!data.entities.facet ||
				!data.entities.facet.stateProvince
			) {
				return 0;
			}

			// trasformo i facet in una struttura dati piu' facile da consultare
			const spFacets = data.entities.facet.stateProvince.reduce(
				(res, { value, count }) => {
					res[value] = count;
					return res;
				},
				{},
			);
			// trovo tutti gli ID delle citta' corrispondenti alla regione
			const citiesId = regions[possibleValue.name];
			// poi sommo tutti i valori dei facet (i quali sono elencati per citta')
			// da questo valore so se ci possono essere elementi o meno
			return citiesId.reduce((sum, id) => sum + (spFacets[id] || 0), 0);
		},
	},
	plProducer: {
		key: 'plProducer',
		component: PrivateManifacturerFilter,
		fromUrl: (v: string) => v === 'true',
		toUrl: (v: boolean) => (v ? 'true' : null),
		title: t`Private Label Manufacturer`,
	},
	FrequentSearch: {
		key: 'FrequentSearch',
		toUrl: (obj) => encodeURIComponent(obj.id),
		fromUrl: (id: string) => ({ id: decodeURIComponent(id) }),
		tagList: (data: any, setter: DefaultSetter, value: any) =>
			value && (
				<UserLists>
					{({ favoritesGoods, favoritesCompanies }) => (
						<Query query={frequentSearchQuery} variables={value}>
							{({
								error,
								loading,
								data,
							}: {
								loading: boolean;
								error?: ApolloError;
								data: any;
							}) => {
								return (
									!loading &&
									!error && (
										<FilterTags
											title={data.isDynamic ? t`Dynamic list` : t`Selection`}
											tags={[
												{
													icon:
														(favoritesCompanies &&
															value.id === favoritesCompanies.id) ||
														(favoritesGoods &&
															value.id === favoritesGoods.id)
															? 'favorite'
															: null,
													label: data.frequentSearch.name,
													onRemove: () => setter(null),
												},
											]}
											type="primary"
										/>
									)
								);
							}}
						</Query>
					)}
				</UserLists>
			),
	},
	fairs: {
		key: 'fairs',
		fromUrl: (v: string) => v.split(',').map(decodeURIComponent),
		toUrl: (v: string[]) => v.map(encodeURIComponent).join(','),
		serializer: (v: string[]) =>
			v.map((id) => ({
				id,
			})),
		tagList: (data: any, setter: DefaultSetter, value: string[]) =>
			value && (
				<Query query={fairQuery} variables={{ id: value[0] }}>
					{({
						error,
						loading,
						data,
					}: {
						loading: boolean;
						error?: ApolloError;
						data: any;
					}) =>
						!loading &&
						!error && (
							<FilterTags
								title={t`On display at`}
								tags={[
									{
										label:
											`${data.fair.name} ${data.fair.year}` +
											(data.fair.edition ? ` - ${data.fair.edition}` : ''),
										onRemove: () => setter([]),
									},
								]}
								type="alt"
							/>
						)
					}
				</Query>
			),
	},
};

export const companyFiltersQuery = getQuery(
	Object.values(CompanyDirectoryFilters)
		.filter((f) => !!f.query)
		.map((f) => f.query),
	'CompanyFilterQuery',
);

export const CompanyDirectoryRoute: React.FC<{
	layout: DirectoryLayouts;
}> = ({ layout: dirLayout }) => {
	const { isBot } = useContext(renderContext);

	return (
		<Directory
			type={SearchTypes.COMPANY}
			name={'Suppliers'}
			query={CompanyDirectoryQuery}
			filters={CompanyDirectoryFilters}
			filtersQuery={companyFiltersQuery}
			layout={dirLayout}
		>
			{({ entities, layout, page, parsedFilters, suggestedFS }) => {
				const directoryWhere = companyDirectoryWhere(page, parsedFilters);
				let columns = [0, 1];
				if (layout === DirectoryViewLayouts.THREE_COL) {
					columns = [0, 1, 2];
				}
				if (layout === DirectoryViewLayouts.FOUR_COL) {
					columns = [0, 1, 2, 3];
				}
				if (layout === DirectoryViewLayouts.FIVE_COL) {
					columns = [0, 1, 2, 3, 4];
				}

				let relatedFSShowColumn = 2;
				if (layout === DirectoryViewLayouts.TWO_COL) {
					relatedFSShowColumn = 1;
				}

				const prerenderCards = columns.map((i) =>
					moduleFilter(entities, columns.length, i).map((e, i2) => {
						const isCompany = e.__typename === 'Company'; // altrimenti e' un brand
						const Component = isCompany ? CompanyCard : BrandCard;

						return (
							<WhereBlock
								key={e.id}
								step={{ type: 'Block', name: 'CardPosition', column: i, row: i2 }}
							>
								<Component
									entity={e}
									key={e.id}
									className={loadable(isBot, LoadableDirection.BOTTOM)}
									style={loadDelayStyle(0.2 * i2)}
									layout={
										dirLayout === DirectoryLayouts.TWO_COL
											? CompanyCardLayout.COMPACT
											: CompanyCardLayout.FULL
									}
								/>
							</WhereBlock>
						);
					}),
				);

				if (Array.isArray(suggestedFS) && suggestedFS.length > 0) {
					const suggestedCardElem = (
						<SuggestedFSCard
							layout={
								dirLayout === DirectoryLayouts.TWO_COL
									? SuggestedFSCardLayout.COMPACT
									: SuggestedFSCardLayout.STANDARD
							}
							fs={suggestedFS}
						/>
					);
					if (relatedFSShowColumn === 0) {
						prerenderCards[relatedFSShowColumn].splice(1, 0, suggestedCardElem);
					} else {
						prerenderCards[relatedFSShowColumn] = [
							suggestedCardElem,
							...prerenderCards[relatedFSShowColumn],
						];
					}
				}

				return (
					<WhereBlock step={directoryWhere}>
						<DirectoryHead
							type={ENTITY.COMPANY}
							filters={parsedFilters}
							filtersOnCanonical
						/>
						<div
							className={cns(
								mixin.expandableItemsGrid,
								dirLayout === DirectoryLayouts.TWO_COL &&
									subClass(mixin.expandableItemsGrid, 'compact'),
							)}
						>
							{columns.map((i) => (
								<div key={i} className={shadowChildrenClass}>
									{prerenderCards[i]}
								</div>
							))}
						</div>
					</WhereBlock>
				);
			}}
		</Directory>
	);
};
