import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { targetSiteLink, RenewLink, isLogged } from '@food/auth';
import { authConfig, authManager } from './auth';
import CONFIG from '../static/config';
import { canUseDOM } from 'exenv';
import { LightFragmentMatcher } from './LightFragmentMatcher';
import { retryFetch } from './retryFetch';

const link = ApolloLink.from([
	new (targetSiteLink(authConfig))(),
	new (RenewLink(authManager))(),
	// new ErrorLink(),
	new HttpLink({
		uri: CONFIG.GRAPHQL_API,
		fetch: retryFetch,
		fetchOptions: {
			'no-cors': true,
		},
	}),
]);

const fragmentMatcher = new LightFragmentMatcher();

export const cache = new InMemoryCache({
	fragmentMatcher,
	dataIdFromObject: (object: any) => object.id,
	freezeResults: true,
});

if (canUseDOM && !isLogged()) {
	cache.restore((window as any).__APOLLO_STATE__);
}

export const client = new ApolloClient({
	link,
	cache,
	assumeImmutableResults: true,
});
