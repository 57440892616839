import gql from 'graphql-tag';
import { GenericChoiceFilter } from './GenericChoiceFilter';

export const ItalianRegionsFilterQuery = gql`
	query ItalianRegionsFilterQuery {
		stateProvinces {
			edges {
				node {
					id
					name
					regionIta
				}
			}
		}
	}
`;

export const ItalianRegionsFilter = GenericChoiceFilter;
