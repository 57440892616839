import React, { useContext } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { cns, currySC, registerClass } from '@food/css-manager';
import { Box, Slider, SliderLayouts } from '@food/ui';
import { CompanyCard, CompanyCardLayout, CompanyCardFragment } from './CompanyCard';
import { sentryHandler } from '../../utils/errors';
import t from '../../utils/labels';
import { idEquals, nodes } from '../../utils/misc';
import { IFNTheme } from '../../utils/theme';
import { CompanyDetailLayouts } from './CompanyDetail';
import { WhereBlock } from '../../utils/tracking';
import { Company } from '../../server-types';
import { TWhere } from '../../tracking-types';
import { loadable, LoadableDirection } from '../../utils/loadable';
import { renderContext } from '../../utils/context/renderContext';
import { ApolloError } from 'apollo-client';

export interface IRelatedCompaniesProps {
	entity: Company;
	layout: CompanyDetailLayouts;
}

const relatedCompanyQuery = gql`
	query RelatedCompanyQuery($filter: CompaniesFilterType) {
		companiesDir(filter: $filter, first: 10) {
			edges {
				node {
					... on Company {
						id
						...CompanyCardFragment
					}
				}
			}
		}
	}
	${CompanyCardFragment}
`;

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
  box-shadow: ${t.boxShadow(1)};

  .${sc('card')} {
		width: ${t.columnSize / 2}px;
	}
  `,
);

const sc = currySC(styleClass);

export const RelatedCompanies: React.FC<IRelatedCompaniesProps> = ({
	entity,
	layout,
}) => {
	const { isBot } = useContext(renderContext);
	const groupWhere: TWhere = { type: 'Block', name: 'relatedCompanies' };
	const sliderLayout =
		layout === CompanyDetailLayouts.FOUR_COL
			? SliderLayouts.SINGLE_ROW
			: SliderLayouts.SINGLE_ELEMENT;

	return (
		<Query
			query={relatedCompanyQuery}
			variables={{
				filter: {
					types: ['Company'],
					goodClasses: entity
						? entity.goodClassesFull.map((obj) => ({
								id: obj.id,
						  }))
						: [],
				},
			}}
		>
			{({
				error,
				loading,
				data,
			}: {
				loading: boolean;
				error?: ApolloError;
				data: any;
			}) => {
				if (loading) {
					return null;
				}

				if (error) {
					// di default non mostro niente ma segnalo a me stesso l'errore
					sentryHandler([error]);
					return null;
				}

				const companies = nodes(data.companiesDir).filter(
					(c) => !idEquals(c.id, entity.id),
				);

				if (companies.length === 0) {
					return null;
				}

				return (
					<WhereBlock step={groupWhere}>
						<Box
							title={t`Similar suppliers`}
							className={cns(
								styleClass,
								loadable(isBot, LoadableDirection.BOTTOM),
							)}
							fullWidth={sliderLayout === SliderLayouts.SINGLE_ELEMENT}
						>
							<Slider
								layout={sliderLayout}
								carusel={sliderLayout === SliderLayouts.SINGLE_ELEMENT}
							>
								{companies.map((c) => (
									<CompanyCard
										className={sc('card')}
										entity={c}
										key={c.id}
										layout={CompanyCardLayout.COMPACT}
									/>
								))}
							</Slider>
						</Box>
					</WhereBlock>
				);
			}}
		</Query>
	);
};
