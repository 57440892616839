import * as dotenv from 'dotenv'
import { TargetSite } from "../server-types";

dotenv.config()

// api base url
const apiUrl = process.env.REACT_APP_STAGE_API_URL;

const publicUrl = process.env.REACT_APP_PUBLIC_URL;

const CONFIG = {
	// site name
	TARGET_SITE: TargetSite.Ifn,
	// url where the site will be reachable
	PUBLIC_URL: publicUrl,
	// backed address
	BACKEND_URL: apiUrl,
	// items per page
	ENTITIES_PER_PAGE: 50,

	// graphql api endpoint
	GRAPHQL_API: apiUrl + '/graphql',
	// api endpoints
	API_ENDPOINT: {
		AUTH: apiUrl + '/api/authenticate',
		VERIFY: apiUrl + '/api/verify',
		TRACKING: apiUrl + '/api/bucket/tracking',
	},
	// sentry
	SENTRY_SERVER: 'https://fc46f822da804e5eb2cbdb34e66b5416@sentry.io/273087',
	SENTRY_NOTIFICATION_TIMEOUT: 1000, // ms

	AUTH_ROLES: ['BUYER', 'CONSUMER'],
	// oauth redirect (required for social login)
	GA_ID: 'UA-21094558-16',

	// social login oauth url
	GOOGLE_AUTH_URL:
		'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&response_type=code&client_id=686200956517-ekcd649hqj7nuf6q19grqp9ofqlvnqu4.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/plus.login+https://www.googleapis.com/auth/userinfo.profile',
	LINKEDIN_AUTH_URL:
		'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86cpchio1khn04',
	FACEBOOK_AUTH_URL:
		'https://www.facebook.com/v2.11/dialog/oauth?access_type=offline&client_id=167338723698292',
	// Remote sites
	NEWS_SITE_BASE_URL: 'https://news.italianfood.net',
	SUPPORT_EMAIL: 'support@italianfood.net',
	MAPS_KEY: 'AIzaSyCt0bsn7PNgiCOAdBvjHOSLrFXfbkg7-XU',
};

export default CONFIG;