import React, { useMemo, Fragment } from 'react';
import { cns, registerClass, currySC } from '@food/css-manager';
import t from '../../utils/labels';
import { calculateMaxPage } from '../../utils/directory';
import { IFNTheme } from '../../utils/theme';
import { Link } from 'react-router-dom';
import { parse, stringify } from 'querystring';
import { History } from 'history';

interface IPagesListProps {
	currentPage: number;
	total: number;
	history: History;
	className?: string;
	entitiesPerPage?: number;
}

const styleClass = registerClass(
	(theme: IFNTheme, sc) => `
	margin-top: ${theme.ratios.s}rem;
	line-height: ${theme.ratios.xl}em;
	
	.${sc('active')} {
		color: ${theme.colors.alt.plain};
		font-weight: ${theme.font.weights.bold};
		text-decoration: underline;
	}
	
	.${sc('page-link')} {
		display: inline-block;
		margin-left: ${theme.ratios.xs}em;
		margin-right: ${theme.ratios.xs}em;
	}
`,
);

const sc = currySC(styleClass);

export const PagesList: React.FC<IPagesListProps> = ({
	entitiesPerPage ,
	currentPage,
	total,
	className,
	history,
}) => {
	const { search, pathname } = history.location;
	const pages = useMemo(
		() =>
			new Array(calculateMaxPage(total, entitiesPerPage))
				.fill('')
				.map((v, i) => i + 1),
		[total, entitiesPerPage],
	);
	const actualSearch = useMemo(() => parse(search.substr(1)), [search]);

	if (pages.length < 2) {
		return <section />;
	}

	return (
		<div className={cns(className, styleClass)}>
			{t`Pages`}:{' '}
			{pages.map((page) => (
				<Fragment key={page}>
					{page !== 1 && ' - '}
					<Link
						className={cns(sc('page-link'), page === currentPage && sc('active'))}
						to={{ pathname, search: stringify({ ...actualSearch, page }) }}
					>
						{page}
					</Link>
				</Fragment>
			))}
		</div>
	);
};
