type Fn = (...args: any[]) => void;

const simpleDebounce = (func: Fn, waitMilliseconds = 50): Fn => {
	let timeoutId: NodeJS.Timer | undefined;

	return (...args: any[]) => {
		const doLater = function() {
			timeoutId = undefined;
			func.apply(null, args);
		};

		if (timeoutId !== undefined) {
			clearTimeout(timeoutId);
		}

		timeoutId = setTimeout(doLater, waitMilliseconds);
	};
};

const stacked = (func: Fn, waitMilliseconds = 50) => {
	const stack: any[] = [];

	const handler = simpleDebounce(
		() => stacked.length > 0 && func(stack.splice(0, stack.length)),
		waitMilliseconds,
	);

	return (event: any) => {
		stack.push(event);
		handler();
	};
};

export { stacked };
