import React from 'react';
import * as Sentry from '@sentry/browser';
import { GraphQLError } from 'graphql';
import { Redirect } from 'react-router';
import { Button } from '@food/ui';
import { openRenewModal } from '@food/auth';
import t from '../utils/labels';
import CONFIG from '../static/config';
import { IFNTheme } from './theme';
import { registerClass } from '@food/css-manager';
import { html } from './misc';
import { ErrorComponent } from '../components/blocks/Error';

let errorModal = null;

const CONTEXTS = {
	NETWORK: 'NETWORK',
	GRAPHQL: 'GRAPHQL',
	VALIDATION: 'VALIDATION',
	INTERNAL: 'INTERNAL',
	LOCAL: 'LOCAL',
};

const ERRORS = {
	USER_NOT_FOUND: 'USER_NOT_FOUND',
	WRONG_PASSWORD: 'WRONG_PASSWORD',
	NETWORK_ERROR: 'NETWORK_ERROR',
	UNAUTHORIZED: 'UNAUTHORIZED',
	SERVER_ERROR: 'SERVER_ERROR',
	DATA_CLONE_ERR: 'DATA_CLONE_ERR',
	ILLICIT_STORAGE_UPDATE: 'ILLICIT_STORAGE_UPDATE',
	TOO_MANY_WAITING_QUERIES: 'TOO_MANY_WAITING_QUERIES',
};

// prendo un Error javascript e aggiungo i miei campi personalizzati
const generateError = (error: string, context: string, data?: any) =>
	Object.assign(new Error(error), {
		context,
		data,
		timestamp: new Date().getTime(),
	});

function parseGraphqlError(e) {
	if (e.graphQLErrors && e.graphQLErrors.length > 0) {
		return e.graphQLErrors.map((er) =>
			generateError(ERRORS.SERVER_ERROR, CONTEXTS.GRAPHQL, er),
		);
	} else if (e.networkError) {
		return [
			generateError(ERRORS.SERVER_ERROR, CONTEXTS.NETWORK, {
				originalError: e,
			}),
		];
	}

	return null;
}

// ho bisogno di queste due funzioni per poter dare all'handler "modal" l'elemento a cui fare riferimento
// per ora vengono usate solo quando si attacca/stacca il layout principale (sidebar layout)
function setErrorModal(modal) {
	errorModal = modal;
	return errorModal;
}
// ---

// segnalo gli errori all'utente tramite modale
function modalHandler(errs) {
	if (errorModal !== null) {
		errorModal._open(errs);
	}
	return errs;
}

const errorClass = registerClass(
	(t: IFNTheme) => `
text-align: center
font-size: ${t.ratios.l}em;
line-height: 1.5;

a {
	text-decoration: underline;
}
`,
);

// traduco i vari errori con del testo personalizzato, così da avere più controllo su cosa mostrare all'utente
const errorToComponent = ({ message, context }) => {
	if (message === 'SERVER_ERROR' && context === 'GRAPHQL') {
		return (
			<p className={errorClass} key={message}>
				<span dangerouslySetInnerHTML={html(t('SERVER_ERROR_HTML'))} />{' '}
				<a href={'mailto:' + CONFIG.SUPPORT_EMAIL}>{CONFIG.SUPPORT_EMAIL}</a>.
			</p>
		);
	}

	return (
		<p className={errorClass} key={message}>
			{t([message, context, 'error', 'explication'].join('/'))}
		</p>
	);
};

function arrayMatch(a: Array<string | number>, b: Array<string | number>): boolean {
	let i;
	for (i = 0; i < b.length; i++) {
		if (a.length <= i || a[i] !== b[i]) {
			return false;
		}
	}
	return true;
}

function arrayIsEqual(a: Array<string | number>, b: Array<string | number>): boolean {
	let i;

	if (a === b) {
		return true;
	}
	if (a == null || b == null) {
		return false;
	}
	if (a.length !== b.length) {
		return false;
	}

	for (i = 0; i < a.length; i++) {
		if (a[i] !== b[i]) {
			return false;
		}
	}

	return true;
}

function extractByPriority(errors: ReadonlyArray<Error>): any {
	let i;
	const codes = [401, 403, 404];
	const check = (code: number) => (e: any) => e.status === code;

	if (!errors || (Array.isArray(errors) && errors.length === 0)) {
		return null;
	}

	for (i = 0; i < codes.length; i++) {
		const tmp = errors.find(check(codes[i]));
		if (tmp) {
			return tmp;
		}
	}

	return errors[0];
}

const filterErrors = (
	errors: ReadonlyArray<any>,
	paths: Array<Array<string | number>>,
	exact: boolean = false,
): ReadonlyArray<Error> => {
	if (!errors) {
		return [];
	}

	const comparator = exact ? arrayIsEqual : arrayMatch;

	return errors.filter(
		(e) => !e.path || e.path.length === 0 || paths.some((p) => comparator(e.path, p)),
	);
};

function renderErrors(
	errors: ReadonlyArray<GraphQLError>,
	paths: Array<Array<string | number>> = [[]],
	overrideComponents?: { [key: string]: JSX.Element },
) {
	// ==============================================
	// TODO rimuovere il cast ad any!!!
	// ==============================================

	const pertinentErrors = filterErrors(errors as any, paths, true);
	const mostImportantError = extractByPriority(pertinentErrors);

	if (mostImportantError === null) {
		if (pertinentErrors.length > 0) {
			// caso strano, non sono riuscito ad estrarre un errore eppure ne ho alcuni a disposizione
			// segnalo il caso a sentry e track
			// @ts-ignore
			sentryHandler(errors as Error[]);
		}
		return null;
	}

	// l'errore 404 mi arriva in forma un po' strana, se sono in questo caso devo trasformare l'oggetto
	// io non ho parole.
	if (
		mostImportantError.state &&
		mostImportantError.state[0] &&
		mostImportantError.state[0].message &&
		mostImportantError.state[0].message.status === 404
	) {
		mostImportantError.status = 404;
		mostImportantError.message = mostImportantError.state[0].message.message;
	}

	const components = {
		__DEFAULT: <ErrorComponent />,
		401: (
			<section>
				<h2>{t`Auth error`}</h2>
				<Button label={t`Login`} onClick={() => openRenewModal()} />
			</section>
		),
		403: (
			<section>
				<h2>{t`Auth error`}</h2>
				<Button label={t`Login`} onClick={() => openRenewModal()} />
			</section>
		),
		404: <Redirect to={'/notfound'} />,
		...overrideComponents,
	};

	const code = mostImportantError.status || mostImportantError.statusCode || '__DEFAULT';

	if (code === 404) {
		const notFoundError: any = new Error('Entity not found');
		notFoundError.data = {
			originalError: mostImportantError,
		};
		sentryHandler([notFoundError]);
	}

	if (mostImportantError.message === 'Renew chiuso / errato') {
		// sono nel caso di rinnovo non riuscito / non continuato. Non si tratta di un vero errore
		// metto un div vuoto che riempia la pagina mentre la cache si resetta. Al reset, la pagina verra' ricaricata
		// nel modo adeguato. Non mi sembra necessario creare una classe css per un solo tratto ben definito
		return <div style={{ minHeight: '75vh' }} />;
	}

	return components[code];
}

// segnalo gli errori a sentry
const sentryHandler = (errs: Error[]) => {
	// segnalo solo il primo errore
	const error = errs[0];
	Sentry.withScope((scope) => {
		scope.setExtra('data', (error as any).data);
		Sentry.captureException(error);
	});
};

export {
	CONTEXTS,
	ERRORS,
	generateError,
	parseGraphqlError,
	sentryHandler,
	setErrorModal,
	modalHandler,
	errorToComponent,
	filterErrors,
	renderErrors,
};
