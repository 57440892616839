import React from 'react';
import { Button, IconPositions, Slider, SliderLayouts } from '@food/ui';
import { IFNTheme, mixin, theme } from '../utils/theme';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import {
	BorderColors,
	Layouts as RemoteWordpressWidgetLayouts,
	RemoteWordpressWidget,
	Widths as RemoteWordpressWidgetWidths,
} from './blocks/RemoteWordpressWidget';
import CONFIG from '../static/config';
import { CompanyCardFragment } from './entities/CompanyCard';
import { cns, currySC, registerClass } from '@food/css-manager';
import t from '../utils/labels';
import { CompanyDirType } from '../types';
import { siteTitle } from '../utils/seo';
import logoSocial from '../static/assets/logo_social.jpg';
import { nodes } from '../utils/misc';
import { FairRelatedCardFragment } from './entities/FairRelatedCard';
import { GenericHead } from './head/GenericHead';
import { WhereBlock } from '../utils/tracking';
import { TWhere } from '../tracking-types';
import {
	FairRelatedFrequentSearchCardFragment,
	// FrequentSearchCard,
} from './entities/FrequentSearchCard';
import { CompanyCard, CompanyCardLayout } from './entities/CompanyCard';
import { ButtonLayouts } from '@food/ui/build/dist';
import { ACTIONS, generateUrl } from '../utils/urls';
import { ENTITY } from '../utils/entities';
import { CompanyLevelEnum } from '../server-types';

interface IHomeProps {
	layout: HomeLayouts;
}

export enum HomeLayouts {
	ONE_COL = 'ONE_COL',
	TWO_COL = 'TWO_COL',
	THREE_COL = 'THREE_COL',
	FOUR_COL = 'FOUR_COL',
}

export function homeLayoutChooser(w: number): HomeLayouts {
	const { mobileMax, tabletMax, notebookMax } = theme.breakpoints;
	if (w <= mobileMax) {
		return HomeLayouts.ONE_COL;
	} else if (w <= tabletMax) {
		return HomeLayouts.TWO_COL;
	} else if (w <= notebookMax) {
		return HomeLayouts.THREE_COL;
	} else {
		return HomeLayouts.FOUR_COL;
	}
}

const anuga2019Id = '2KKqlzElXq0~d47';

export const HomeQuery = gql`
	query HomeQuery {
		companiesDir(filter: {
				types: [${CompanyDirType.Company}]
				levels: [${CompanyLevelEnum.PaidL1}, ${CompanyLevelEnum.PaidL2}, ${CompanyLevelEnum.PaidL3}]
			}
			first: 10
		) {
			edges {
				node {
					... on Company {
						id
						...CompanyCardFragment
					}
				}
			}
		}
       fairs(filter: {isConcluded: false, minParticipants: 1}, first: 10) {
           edges {
               node {
	                ...FairRelatedCardFragment
	                participations {
		                 total
	                }
               }
           }
       }
		 anuga2019FrequentSearches: frequentSearchs(filter: { relatedFair: { id: "${anuga2019Id}"}}) {
			  edges {
				   node {
					    ... on FrequentSearch {
						     id
						     ...FrequentSearchCard
					    }
				   }
			  }
		 }
		 anuga: node(id: "${anuga2019Id}") {
			  id
			  ... on Fair {
				   logoMedia {
					    id
					    smallThumbUrl
				   }
			  }
       }
	}
	${CompanyCardFragment}
	${FairRelatedCardFragment}
	${FairRelatedFrequentSearchCardFragment}
`;

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	.${sc('claimContainer')} {
		margin-left: 0;
		margin-right: 0;
		text-align: center;
		padding-top: ${t.ratios.xl}rem;
		background: ${t.colors.white};
		
		.${sc('claimBackground')} {
			display: block;
			background-image: url('${t.assets.patternTriangle}');
			background-color: ${t.colors.primary.plain};
			height: ${t.ratios.xl * t.ratios.l}rem;
		}
		
		.${sc('claim')} {
			display: block;
			font-size: ${t.ratios.xl * 2}rem;
			font-style: italic;
			font-weight: ${t.font.weights.bold};
			margin-bottom: -${(t.ratios.xl * 2) / 3}rem;
		}
	}
	
	> .${sc('guided')} {
		box-shadow: ${t.boxShadow(1)};
	}

	.${sc('seeAllContainer')} {
		display: block;
		text-align: right;
		a {
			display: inline-flex;
			align-items: center;
			padding: ${t.ratios.xl / 2}rem ${t.ratios.xl}rem ${t.ratios.xl}rem;
			text-transform: uppercase;
		}
	}
	
	.${sc('spaced-block')} {
		margin: ${t.ratios.l}rem;
	}
	
	.${sc('padded-logo')} {
		margin-top: ${t.ratios.s}rem;
		background-color: white;
		padding: ${t.ratios.xl ** 2}rem;
	}
`,
);

const hiddenTitleClass = registerClass(
	() => `
	display: none;
`,
);

const newSliderStyle = registerClass(
	(theme: IFNTheme, sc) => `
	background-color: ${theme.colors.white};
	position: relative;
	box-shadow: ${theme.boxShadow(1)};

	> .${sc('contentContainer')} > .${sc('content')},
	> footer {
		padding: ${(theme.ratios.xl / 2) * theme.font.baseSize}px ${
		theme.ratios.xl * theme.font.baseSize
	}px;
	}
	
	&.${sc('compact')} {
		.${sc('category')} {

			padding-left: 0;
			
			> span {
				display: none;
			}
		}
	}
	
	> header {
		position: relative;
		text-transform: uppercase;
		font-size: ${theme.ratios.l * theme.font.baseSize}px;
		font-weight: ${theme.font.weights.bold};
		line-height: 1;
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
		background: ${theme.colors.action.light};
		color: ${theme.colors.grey.dark};
		overflow: hidden;
	}
	
	.${sc('category')} {
		background-color: ${theme.colors.premium.plain};
		color: ${theme.colors.white};
		padding-left: 1em;
		padding-right: 1em;
		padding-top: .5em;
		padding-bottom: .5em;
		display: flex;
		align-items: center;
	}
	
	.${sc('title')} {
		background-color: ${theme.colors.premium.plain};
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		
		> div {
			padding-top: .5em;
			padding-bottom: .5em;
			background: ${theme.colors.action.light};
			padding-left: 1em;
			font-weight: ${theme.font.weights.regular};
			border-radius: 15px 0 0 0;
			box-shadow: -5px 0px 5px 0px rgba(119,92,33, .35);

			
			> strong {
				color: ${theme.colors.premium.plain};
				font-weight: ${theme.font.weights.bold};
			}
		}
	}
	
	.${sc('action')} {
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 100;
		font-size: ${theme.ratios.l}em;
		font-weight: ${theme.font.weights.regular}
		border-radius: 1rem 0 0 0;
	}
`,
);

const nsc = currySC(newSliderStyle);

/*const newSliderStyle2 = registerClass(
	(theme: IFNTheme, sc) => `
	background-color: ${theme.colors.white};
	position: relative;
	box-shadow: ${theme.boxShadow(1)};

	> .${sc('contentContainer')} > .${sc('content')},
	> footer {
		padding: ${(theme.ratios.xl / 2) * theme.font.baseSize}px ${theme.ratios.xl *
		theme.font.baseSize}px;
	}
	
	&.${sc('compact')} {
		.${sc('category')} {

			padding-left: 0;

			> span {
				display: none;
			}
		}
	}
	
	> header {
		position: relative;
		text-transform: uppercase;
		font-size: ${theme.ratios.l * theme.font.baseSize}px;
		font-weight: ${theme.font.weights.bold};
		line-height: 1;
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
		background: ${theme.colors.action.light};
		color: ${theme.colors.grey.dark};
		overflow: hidden;
	}
	
	.${sc('category')} {
		background-color: ${theme.colors.alt.plain};
		color: ${theme.colors.white};
		padding-left: 1em;
		padding-right: 1em;
		padding-top: .5em;
		padding-bottom: .5em;
		display: flex;
		align-items: center;
	}
	
	.${sc('title')} {
		background-color: ${theme.colors.alt.plain};
		display: flex;
		align-items: stretch;
		
		> div {
			padding-top: .5em;
			padding-bottom: .5em;
			background: ${theme.colors.action.light};
			padding-left: 1em;
			font-weight: ${theme.font.weights.regular};
			border-radius: 15px 0 0 0;
			box-shadow: -5px 0px 5px 0px rgba(46,87,106, .5);

			
			> strong {
				color: ${theme.colors.alt.plain};
				font-weight: ${theme.font.weights.bold};
			}
		}
	}
	
	.${sc('action')} {
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 100;
		font-size: ${theme.ratios.l}em;
		font-weight: ${theme.font.weights.regular}
		border-radius: 1rem 0 0 0;
	}
`,
);*/

// const nsc2 = currySC(newSliderStyle2);

/*const newSliderStyle3 = registerClass(
	(theme: IFNTheme, sc) => `
	background-color: ${theme.colors.white};
	position: relative;
	box-shadow: ${theme.boxShadow(1)};

	> .${sc('contentContainer')} > .${sc('content')},
	> footer {
		padding: ${(theme.ratios.xl / 2) * theme.font.baseSize}px ${theme.ratios.xl *
		theme.font.baseSize}px;
	}
	
	&.${sc('compact')} {
		.${sc('category')} {

			padding-left: 0;

			> span {
				display: none;
			}
		}
	}
	
	> header {
		position: relative;
		text-transform: uppercase;
		font-size: ${theme.ratios.l * theme.font.baseSize}px;
		font-weight: ${theme.font.weights.bold};
		line-height: 1;
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
		background: ${theme.colors.grey.light};
		color: ${theme.colors.grey.dark};
		overflow: hidden;
	}
	
	.${sc('category')} {
		background-color: ${theme.colors.primary.plain};
		color: ${theme.colors.white};
		padding-left: 1em;
		padding-right: 1em;
		padding-top: .5em;
		padding-bottom: .5em;
		display: flex;
		align-items: center;
	}
	
	.${sc('title')} {
		background-color: ${theme.colors.primary.plain};
		display: flex;
		align-items: stretch;
		
		> div {
			padding-top: .5em;
			padding-bottom: .5em;
			background: ${theme.colors.grey.light};
			padding-left: 1em;
			font-weight: ${theme.font.weights.regular};
			border-radius: 15px 0 0 0;
			box-shadow: -5px 0px 5px 0px rgba(46,87,106, .5);

			
			> strong {
				color: ${theme.colors.primary.plain};
				font-weight: ${theme.font.weights.bold};
			}
		}
	}
	
	.${sc('action')} {
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 100;
		font-size: ${theme.ratios.l}em;
		font-weight: ${theme.font.weights.regular}
		border-radius: 1rem 0 0 0;
	
	}
`,
);*/

// const nsc3 = currySC(newSliderStyle3);
const sc = currySC(styleClass);
const proCompaniesWhere: TWhere = { type: 'Block', name: 'ProCompanies' };
// const nextFairsWhere: TWhere = { type: 'Block', name: 'NextFair' };
// const anuga2019Where: TWhere = { type: 'Block', name: 'Anuga 2019 Candidates' };

export const Home: React.FC<IHomeProps> = ({ layout }) => {
	// const track = useTracking();

	const sidedBoxSliderLayout =
		layout === HomeLayouts.FOUR_COL
			? SliderLayouts.SINGLE_ROW
			: SliderLayouts.SINGLE_ELEMENT;

	const newsBoxWidth =
		layout === HomeLayouts.ONE_COL
			? RemoteWordpressWidgetWidths.ONE_COL
			: layout === HomeLayouts.TWO_COL
			? RemoteWordpressWidgetWidths.TWO_COL
			: RemoteWordpressWidgetWidths.FULLWIDTH;

	return (
		<>
			<h1 className={hiddenTitleClass}>{siteTitle}</h1>
			<GenericHead
				jsonld={[
					JSON.stringify({
						'@context': 'https://schema.org',
						'@type': 'WebSite',
						'@id': '#website',
						url: 'https://www.italianfood.net/',
						name: siteTitle,
					}),
					JSON.stringify({
						'@context': 'https://schema.org',
						'@type': 'Organization',
						url: 'https://www.italianfood.net/',
						sameAs: [
							'https://www.facebook.com/theauthenticitalianfoodplatform',
							'https://twitter.com/italianfood_net',
						],
						'@id': 'https://www.italianfood.net/#organization',
						name: 'FOOD Srl',
						logo: CONFIG.PUBLIC_URL + logoSocial,
					}),
				]}
			/>
			<Query query={HomeQuery} errorPolicy="all">
				{({ loading, data }) => {
					if (loading) {
						return null;
					}

					const companies = data && data.companiesDir && nodes(data.companiesDir);
					// const fairs = data && data.fairs && nodes(data.fairs);
					// const anuga2019Candidates =
					// 	data && data.anuga2019Candidates && nodes(data.anuga2019Candidates);
					// const anuga2019FS =
					// 	data &&
					// 	data.anuga2019FrequentSearches &&
					// 	nodes(data.anuga2019FrequentSearches);
					// const anuga = data && data.anuga;

					const latestNewsUrl = CONFIG.NEWS_SITE_BASE_URL + '/';

					return (
						<div className={cns(styleClass, sc('layout', layout))}>
							<RemoteWordpressWidget
								className={cns('news-widget', sc('spaced-block'))}
								url={latestNewsUrl}
								selector={'#head-news article, #main > article'}
								title={
									layout === HomeLayouts.ONE_COL
										? 'News'
										: 'news.italianfood.net'
								}
								titleLinkTo={latestNewsUrl}
								titleTarget="_blank"
								viewAllAfter={true}
								viewAllLabel={'Go To News'}
								viewAllLinkTo={latestNewsUrl}
								viewAllTarget="_blank"
								isBordered={true}
								layout={RemoteWordpressWidgetLayouts.tripleWidth}
								width={newsBoxWidth}
								borderColor={BorderColors.alt}
								hasBorderPattern={true}
							/>

							{/*{anuga2019Candidates && anuga2019Candidates.length > 0 && (
								<WhereBlock step={anuga2019Where}>
									<div
										className={cns(
											newSliderStyle3,
											sc('spaced-block'),
											layout === HomeLayouts.ONE_COL && nsc3('compact'),
										)}
									>
										<header>
											<div className={nsc3('category')}>
												<span>Anuga</span>
											</div>
											<div className={nsc3('title')}>
												<div>
													Italian FOOD Awards 2019 <strong>Winners</strong>
												</div>
											</div>
										</header>
										<Slider
											carusel={
												sidedBoxSliderLayout === SliderLayouts.SINGLE_ELEMENT
											}
											layout={sidedBoxSliderLayout}
										>
											{anuga2019Candidates.map((c) => (
												<GoodCard
													layout={GoodCardLayout.COMPACT}
													entity={c}
													key={c.id}
													className={mixin.card}
												/>
											))}
										</Slider>
										<Button
											className={nsc3('action')}
											label={t`See all`}
											rounded={true}
											icon={MATERIAL.icon_chevron_right}
											type={'primary'}
											iconPos={IconPositions.RIGHT}
											layout={ButtonLayouts.BUTTON}
											linkTo={'/goods?FrequentSearch=fVwWpdKwc34~e4a'}
										/>
									</div>
								</WhereBlock>
							)}*/}

							{companies && companies.length > 0 && (
								<WhereBlock step={proCompaniesWhere}>
									<div
										className={cns(
											newSliderStyle,
											sc('spaced-block'),
											layout === HomeLayouts.ONE_COL && nsc('compact'),
										)}
									>
										<header>
											<div className={nsc('category')}>
												<span>Suppliers</span>
											</div>
											<div className={nsc('title')}>
												<div>Highlighted Suppliers</div>
											</div>
										</header>
										<Slider
											carusel={
												sidedBoxSliderLayout === SliderLayouts.SINGLE_ELEMENT
											}
											layout={sidedBoxSliderLayout}
										>
											{companies.map((c) => (
												<CompanyCard
													layout={CompanyCardLayout.COMPACT}
													entity={c}
													key={c.id}
													className={mixin.card}
												/>
											))}
										</Slider>
										<Button
											className={nsc('action')}
											label={t`See all`}
											rounded={true}
											icon={'chevron_right'}
											type={'premium'}
											iconPos={IconPositions.RIGHT}
											layout={ButtonLayouts.BUTTON}
											linkTo={generateUrl(ENTITY.COMPANY, ACTIONS.LIST)}
										/>
									</div>
								</WhereBlock>
							)}

							{/*{anuga2019FS && anuga && anuga2019FS.length > 0 && (
								<WhereBlock step={anuga2019Where}>
									<div
										className={cns(
											newSliderStyle2,
											sc('spaced-block'),
											layout === HomeLayouts.ONE_COL && nsc2('compact'),
										)}
									>
										<header>
											<div className={nsc2('category')}>
												<span>Trends</span>
											</div>
											<div className={nsc2('title')}>
												<div>
													Suggested trends at <strong>Anuga</strong>
												</div>
											</div>
										</header>
										<Slider
											carusel={
												sidedBoxSliderLayout === SliderLayouts.SINGLE_ELEMENT
											}
											layout={sidedBoxSliderLayout}
										>
											{anuga2019FS.map((c) => (
												<FrequentSearchCard entity={c} key={c.id} />
											))}
										</Slider>
										<Button
											className={nsc2('action')}
											label={t`See all`}
											rounded={true}
											icon={'chevron_right'}
											type={'alt'}
											iconPos={IconPositions.RIGHT}
											layout={ButtonLayouts.BUTTON}
											linkTo={'/tradeshows/anuga-2019-2KKqlzElXq0~d47'}
										/>
									</div>
								</WhereBlock>
							)}*/}

							{/*{fairs && fairs.length > 0 && (
								<WhereBlock step={nextFairsWhere}>
									<SidedBox
										layout={sidedBoxLayout}
										className={cns(
											headerStyles.bigIconAndText,
											sc('spaced-block'),
											sc('special-slider-block'),
										)}
										headerColor={theme.colors.alt.plain}
										header1={() => (
											<Icon
												name={MATERIAL.icon_event}
												className={cns(
													sc('icon'),
													subClass(headerStyles.bigIconAndText, 'icon'),
												)}
											/>
										)}
										header2={() => (
											<div>
												<div
													className={subClass(
														headerStyles.bigIconAndText,
														'title',
													)}
												>{t`Next Tradeshows`}</div>
												<div
													className={subClass(
														headerStyles.bigIconAndText,
														'link',
													)}
												>
													<Button
														label={t`See more`}
														linkTo={generateUrl(ENTITY.FAIR, ACTIONS.LIST)}
														onClick={() =>
															track(
																{
																	type: 'do_search',
																	target: 'FairDirectory',
																	filterStatus: {},
																},
																addWhere(nextFairsWhere),
															)
														}
														layout={ButtonLayouts.LINK}
														icon={MATERIAL.icon_chevron_right}
														iconPos={IconPositions.RIGHT}
													/>
												</div>
											</div>
										)}
									>
										<Slider
											layout={sidedBoxSliderLayout}
											carusel={
												sidedBoxSliderLayout === SliderLayouts.SINGLE_ELEMENT
											}
										>
											{fairs.map((c) => (
												<FairRelatedCard entity={c} key={c.id} />
											))}
										</Slider>
									</SidedBox>
								</WhereBlock>
							)}*/}
						</div>
					);
				}}
			</Query>
		</>
	);
};
