import React from 'react';
import t from '../utils/labels';
import { logout } from '@food/auth/build/dist/logic/auth';

export const Logout: React.FC = () => (
	<a
		href="#"
		onClick={(event) => {
			event.preventDefault();
			event.stopPropagation();
			logout();
		}}
	>{t`Logout`}</a>
);
