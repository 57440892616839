import { ENTITY } from './entities';
import { sentryHandler } from './errors';

enum ACTIONS {
	CLONE,
	CREATE,
	EDIT,
	DETAIL,
	LIST,
}

function capitalizeFirstLetter(string: string): string {
	return string.charAt(0).toLowerCase() + string.slice(1);
}

export function pluralName(typeName: string): string {
	if (typeName === ENTITY.COMPANY) {
		typeName = 'SUPPLIER';
	}

	typeName = capitalizeFirstLetter(typeName);

	if (typeName === 'news') {
		return 'news';
	}

	switch (typeName[typeName.length - 1]) {
		case 's':
		case 'h':
			return typeName + 'es';
		case 'y':
			return typeName.substr(0, typeName.length - 1) + 'ies';
		default:
			return typeName + 's';
	}
}

function generateUrl(entityName: ENTITY, action: ACTIONS, entityId?) {
	if (!entityName) {
		sentryHandler([new Error('Generated url without entity name')]);
		return '/';
	}

	const pieces = entityName.split('_', 2);
	let url = '/' + pluralName(pieces[0]).toLowerCase();

	if (pieces[1]) {
		url += '_' + pieces[1];
	}

	if (entityName === ENTITY.FAIR) {
		url = '/tradeshows';
	}

	if (entityId) {
		url += '/' + encodeURIComponent(entityId);
	}

	if (action === ACTIONS.CREATE) {
		url += '/new';
	} else if (action === ACTIONS.EDIT) {
		url += '/edit';
	} else if (action === ACTIONS.CLONE) {
		url += '/clone';
	}
	return url;
}

/*function translateUrl(location, inverse: boolean = false) {
	const origHost = 'https://www.italianfood.net';
	return inverse ? location.replace(origHost, '') : origHost + location;
}*/

export { generateUrl, ACTIONS /*translateUrl*/ };
