import gql from 'graphql-tag';

export const frequentSearchQuery = gql`
	query frequentSearch($id: ID!) {
		frequentSearch: node(id: $id) {
			id
			... on FrequentSearch {
				name
				nameIta
				isDynamic
			}
		}
	}
`;

