import React, { CSSProperties, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import * as Sentry from '@sentry/browser';
import { IFNTheme, mixin } from '../../utils/theme';
import { MediaImg, mediaImgFragment } from './MediaImg';
import { cns, currySC, registerClass } from '@food/css-manager';
import { companyLevel, companyLevel2Price, companyLevelStr, levelOrMore } from '../../utils/misc';
import { Button, ButtonLayouts, Collapsible, IconPositions } from '@food/ui';
import t from '../../utils/labels';
import { ACTIONS, generateUrl } from '../../utils/urls';
import { ENTITY } from '../../utils/entities';
import {
	EditorialSectionBlock,
	EditorialSectionBlockLayouts,
	Fragment as EditorialSectionBlockFragment,
} from '../../components/entities/EditorialSectionBlock';
import { UserObjectSubscription } from './UserObjectSubscription';
import { Link } from 'react-router-dom';
import { addWhere, useTracking, WhereBlock } from '../../utils/tracking';
import { CompanyLevelEnum, Good, MediaType } from '../../server-types';
import { goodCardWhere } from '../../utils/where';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { useInView } from 'react-intersection-observer';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export enum BrandViews {
	FULL = 'FULL', // default
	NONE = 'NONE',
}

export enum GoodCardLayout {
	FULL = 'full',
	COMPACT = 'compact',
}

interface GoodCardProps {
	entity: Good;
	customActions?: any;
	askCompanyToggle?: (entity: any) => void;
	brandView?: BrandViews;
	forceOpen?: boolean;
	className?: string;
	openInNewTab?: boolean;
	style?: CSSProperties;
	layout?: GoodCardLayout;
}

export const GoodCardFragment = gql`
	fragment GoodCardFragment on Good {
		id
		slug
		name
		featuredImageMedia {
			...MediaImgFragment
		}
		goodFeatureCertifications {
			id
			name
		}
		GoodClass {
			id
			preservation
			editorialSections {
				...EditorialSectionBlockFragment
			}
		}
		Brand {
			id
			name
			logoMedia {
				...MediaImgFragment
			}
			Company {
				id
				name
				slug
				currentCompanyLevels {
					id
					level
				}
				companyCertifications {
					id
					name
				}
				logoMedia {
					...MediaImgFragment
				}
			}
		}
	}
	${EditorialSectionBlockFragment}
	${mediaImgFragment}
`;

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	position: relative;
	padding: ${t.ratios.s}rem;
	
		.${sc('media')} {
			width: 100%;
			margin-right: 0;
		}
		
		.${sc('tn')} {
			padding-bottom: 0;
		}

	.${sc('expand-state-icon')} {
		margin-top: ${t.ratios.xs}rem;
		text-align: center;
		
		> button {
			background-color: transparent;
			color: inherit;
			padding: 0;
		}
	}
	
	.${sc('tn')} {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-bottom: ${t.ratios.xs}rem;
		position: relative;
	}

	.${sc('media')} {
		width: 90%;
		margin-right: 0;
		transition: ${t.transition('margin-right')};
	}
	
	.${sc('brand')} {
		position: absolute !important;
		z-index: ${t.zIndexes.P1_SlightlyAbove};
		width: 25%;
		bottom: 0;
		right: 0;
		display: flex;
		align-items: center;
		background-color: ${t.colors.white};
	}
	
	.${sc('company-name')} {
		font-weight: ${t.font.weights.semibold};
		color: ${t.colors.alt.plain}
	}

	

	.${sc('title')} {
		text-align: center;
		font-size: ${t.ratios.l}em;
		line-height: ${t.ratios.l}em;
		display: block;
		word-break: break-word;
	}
	
	.${sc('info')} {
		position: relative;
		line-height: ${t.ratios.l}em;
		padding-top: ${t.ratios.xs}rem;
	}
	
	.${sc('value')} {
		font-weight: ${t.font.weights.semibold};

		span.${sc('bull')} {
			opacity: 0.5;
		}
	}
	
	.${sc('iconvalue')} {
		font-weight: ${t.font.weights.semibold};
		font-size: ${t.ratios.xl}em;
		vertical-align: middle;
	}
	
	.${sc('label')} {
		font-size: ${t.ratios.xs * 1.1}em;
		font-weight: ${t.font.weights.bold};
		letter-spacing: 0.05em;
		display: block;
		opacity: 0.5;
		text-transform: uppercase;
	}
	
	.${sc('block')} {
		margin-bottom: ${t.ratios.s}rem;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.${sc('actions')} {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		
		& > * {
			width: 80%;
			display: flex;
			margin-top: ${t.ratios.xs}rem;
			line-height: 1rem;
			font-weight: ${t.font.weights.bold};

			&:first-child {
				margin-top: 0;
			}
		}
	}
	
	/*.${sc('es')} {
		display: none;
		font-size: ${t.ratios.l}rem;
		position: absolute;
		z-index: ${t.zIndexes.P2_AreaAbove};
		right: ${t.ratios.l / 2 + t.ratios.xl ** 2 + t.ratios.xs}rem;
		top: ${t.ratios.l / 2}rem;
	}*/
	
	.${sc('es3')} {
		text-align: center;
	}
	
	.${sc('preservation')} {
		color: ${t.colors.grey.dark};
		font-style: italic;
	}
	
	/*&.${sc('layout-full')} {
	
		.${sc('es')} {
			display: block;
		}
		
		.${sc('media')} {
			margin-right: 10%;
		}
	}*/
	
	&.${sc('layout-compact')} {
		padding: ${t.ratios.s / 2}rem;
	}

	hr {
		display: block;
		border: none;
		height: 1px;
		background: ${t.colors.grey.light};
		margin-bottom: ${t.ratios.s}rem;
	}

	.${sc('brandViewFull')} {
		border-radius: ${t.ratios.s}rem;
		background: ${t.colors.grey.light};
		padding: ${t.ratios.xs}rem;

		.${sc('certifications')} {
			.${sc('value')} {
				font-size: 0.8rem;
			}
		}

	}
`,
);

const sc = currySC(styleClass);

export const GoodCard: React.FC<GoodCardProps> = ({
	entity,
	customActions,
	forceOpen = false,
	brandView = BrandViews.FULL,
	className,
	openInNewTab = false,
	style,
	layout = GoodCardLayout.FULL,
}) => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0,
	});
	const track = useTracking();
	const where = goodCardWhere(entity);

	useEffect(() => {
		if (inView) {
			track({
				type: 'view',
				what: [where],
			});
			
			// evento analytics di view card
			(window as any).gtag('event', 'view_item_list', {
				items: [
					{
						id: entity.id,
						name: entity.name,
						brand: entity.Brand.Company.name,
						variant: 'Product',
						category: companyLevelStr(entity.Brand.Company.currentCompanyLevels),
						price: companyLevel2Price(entity.Brand.Company.currentCompanyLevels),
						quantity: 1,
					},
				],
			});
			
		}
	}, [inView]);

	const level = companyLevel(entity.Brand.Company.currentCompanyLevels);
	const exportProOrMore = levelOrMore(level, CompanyLevelEnum.PaidL1);
	const block = sc('block');
	const label = sc('label');
	const value = sc('value');
	const isCompact = layout === GoodCardLayout.COMPACT;
	const companyMedia = entity.Brand.logoMedia || entity.Brand.Company.logoMedia;

	const trackClick = useCallback(
		(open, goodName, goodId) => {
			Sentry.addBreadcrumb({
				category: 'ui',
				message: open ? 'open Good card' : 'close Good card',
				level: Sentry.Severity.Info,
				data: {
					goodName,
					goodId,
				},
			});
			track({ type: open ? 'close' : 'open', what: where }, addWhere(where));
		},
		[track, where],
	);

	return (
		<WhereBlock step={where}>
			<Collapsible startOpen={false}>
				{({ open: collapsibleOpen, expand, collapse, CollapsibleBlockClass }) => {
					const open = collapsibleOpen || forceOpen;
					const doEllipse = layout === GoodCardLayout.COMPACT || !open;

					return (
						<Link
							to={generateUrl(ENTITY.GOOD, ACTIONS.DETAIL, entity.slug)}
							onClick={(event) => {
								if (!open && !isCompact) {
									event.preventDefault();
									trackClick(open, entity.name, entity.id);
									expand();
									return false;
								} else {
									track({ type: 'go_to_detail', what: where }, addWhere(where));
									return true;
								}
							}}
							target={openInNewTab ? '_blank' : undefined}
							className={cns(className, mixin.directoryCard)}
							style={style}
						>
							<article
								ref={ref}
								className={cns(
									styleClass,
									sc(open ? 'open' : 'closed'),
									sc('level', level),
									sc('layout', layout),
								)}
							>
								<div className={mixin.cardCornerActions}>
									<UserObjectSubscription
										entityId={entity.id}
										collection={ENTITY.GOOD}
										className={sc('additional-margin-top')}
									/>
									{customActions}
								</div>

								{/*{entity.GoodClass &&
									entity.GoodClass.editorialSections &&
									layout !== GoodCardLayout.COMPACT && (
										<EditorialSectionBlock
											className={cns(block, sc('es'))}
											entities={entity.GoodClass.editorialSections}
											layout={EditorialSectionBlockLayouts.ICONS_ONLY_OVER}
										/>
									)}*/}
								<div className={sc('tn')}>
									{entity.featuredImageMedia && (
										<MediaImg
											type={MediaType.GoodImage}
											className={sc('media')}
											entity={entity.featuredImageMedia}
											alt={entity.name}
										/>
									)}

									{companyMedia &&
										brandView !== BrandViews.NONE &&
										layout !== GoodCardLayout.COMPACT && (
											<MediaImg
												type={MediaType.Logo}
												className={sc('brand')}
												entity={companyMedia}
												alt={entity.Brand.name}
											/>
										)}
								</div>

								<div className={sc('info')}>
									<div className={cns(block, mixin.textCenter)}>
										<span className={sc('title')}>
											<ResponsiveEllipsis
												style={{ whiteSpace: 'pre-wrap' }}
												text={entity.name}
												maxLine={doEllipse ? 3 : 100}
												ellipsis="…"
												trimRight
												basedOn="words"
											/>
										</span>
									</div>

									{/* Preservation mode */}
									{!isCompact &&
										entity.GoodClass &&
										entity.GoodClass.preservation && (
											<div className={cns(mixin.textCenter, block)}>
												<span className={sc('preservation')}>
													{t(entity.GoodClass.preservation)}
												</span>
											</div>
										)}

									<CollapsibleBlockClass>
										<div>
											{open &&
												!isCompact &&
												entity.GoodClass &&
												entity.GoodClass.editorialSections && (
													<EditorialSectionBlock
														className={cns(block, sc('es3'))}
														entities={entity.GoodClass.editorialSections.filter(
															(es) => es.editorialBrand === 'IFN',
														)}
														layout={
															EditorialSectionBlockLayouts.ICONS_TEXTS
														}
													/>
												)}

											{/* Good features */}
											{open &&
												!isCompact &&
												entity.goodFeatureCertifications.length > 0 && (
													<div className={block}>
														<span className={label}>{t`Features`}:</span>
														<span className={value}>
															{entity.goodFeatureCertifications
																.map((c, i) => {
																	return (
																		<>
																			{c.name}
																			{
																				(i < entity.goodFeatureCertifications.length - 1) ?
																					<span className={sc('bull')}> &bull; </span>
																				:
																					''
																			}
																		</>
																	);
																})}
														</span>
													</div>
												)}

											{open && !isCompact && brandView !== BrandViews.NONE && (
												<div className={block}>
													<span className={label}>{t`Brand`}:</span>
													<span className={value}>{entity.Brand.name}</span>
												</div>
											)}

											{open && !isCompact && brandView !== BrandViews.NONE && (
												<div className={cns(sc('actions'), block)}>
													<Button
														icon={'arrow_forward'}
														label={t`Go to profile`}
														type="success"
														layout={ButtonLayouts.BUTTON}
														iconPos={IconPositions.RIGHT}
													/>
												</div>
											)}

											{open &&
												!isCompact &&
												brandView === BrandViews.FULL &&
												exportProOrMore && (
													<>
														<hr />
														<div className={sc('brandViewFull')}>
															<div className={block}>
																<span className={label}>{t`Company`}:</span>
																<span className={sc('company-name')}>
																	{entity.Brand.Company.name}
																</span>
															</div>

															{/* Company certifications */}
															<div className={cns(block,sc('certifications'))}>
																<span className={label}>
																	{t`Certifications`}:
																</span>{' '}
																<span className={value}>
																	{entity.Brand.Company
																		.companyCertifications &&
																		entity.Brand.Company.companyCertifications
																			.map((c, i) => {
																				return (
																					<>
																						{c.name}
																						{
																							(i < entity.Brand.Company.companyCertifications.length - 1) ?
																								<span className={sc('bull')}> &bull; </span>
																							:
																								''
																						}
																					</>
																				);
																			})
																	}
																</span>
															</div>
														</div>
													</>
												)}
										</div>
									</CollapsibleBlockClass>
								</div>

								{!forceOpen && !isCompact && (
									<div className={sc('expand-state-icon')}>
										<Button
											icon={
												open
													? 'expand_less'
													: 'expand_more'
											}
											onClick={(event) => {
												event.preventDefault();
												event.stopPropagation();
												trackClick(open, entity.name, entity.id);
												(open ? collapse : expand)();
											}}
										/>
									</div>
								)}
							</article>
						</Link>
					);
				}}
			</Collapsible>
		</WhereBlock>
	);
};
