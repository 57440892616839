import gql from 'graphql-tag';

export const goodFeatureCertificationsQuery = gql`
	query goodFeatureCertificationsQuery {
		 goodFeatureCertifications {
			  edges {
				   node {
					    id
					    name
				   }
			  }
		 }
	}
`;
