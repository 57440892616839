import React, { CSSProperties, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { IFNTheme, mixin, theme } from '../../utils/theme';
import {
	Button,
	ButtonLayouts,
	Collapsible,
	Icon,
	IconPositions,
} from '@food/ui';
import { MediaImg, mediaImgFragment } from './MediaImg';
import { cns, currySC, registerClass, subClass } from '@food/css-manager';
import {
	EditorialSectionBlock,
	EditorialSectionBlockLayouts,
	Fragment as EditorialSectionBlockFragment,
} from '../../components/entities/EditorialSectionBlock';
import t from '../../utils/labels';
import { ACTIONS, generateUrl } from '../../utils/urls';
import { ENTITY } from '../../utils/entities';
import { companyLevel, companyLevel2Price, companyLevelStr, levelOrMore } from '../../utils/misc';
import { UserObjectSubscription } from './UserObjectSubscription';
import { Link } from 'react-router-dom';
import badgeContainerBg from '../../static/assets/box_badge-horizontal.svg';
import { addWhere, useTracking, WhereBlock } from '../../utils/tracking';
import { Company, CompanyLevelEnum, MediaType } from '../../server-types';
import { TWhere } from '../../tracking-types';
import { companyCardWhere } from '../../utils/where';
import * as Sentry from '@sentry/browser';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { useInView } from 'react-intersection-observer';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export enum CompanyCardLayout {
	FULL = 'full',
	COMPACT = 'compact',
}

interface ICompanyCardProps {
	className?: string;
	entity: Company; // a dire il vero no, dovrei tipizzarlo usando il pick o deducendo il tipo dal fragment
	openInNewTab?: boolean;
	style?: CSSProperties;
	seoHtmlTags?: boolean;
	forceOpen?: boolean;
	layout?: CompanyCardLayout;
}

export const CompanyCardFragment = gql`
	fragment CompanyCardFragment on Company {
		id
		name
		slug
		logoMedia {
			...MediaImgFragment
		}
		plProducer
		billingBranch {
			id
			StateProvince {
				id
				region
			}
		}
		companyCertifications {
			id
			name
		}
		goodFeatureCertifications {
			id
			name
		}
		currentCompanyLevels {
			id
			level
			targetSite
			companyBadges {
				id
				name
				iconMedia {
					...MediaImgFragment
				}
			}
		}
		editorialSections {
			...EditorialSectionBlockFragment
		}
	}
	${mediaImgFragment}
	${EditorialSectionBlockFragment}
`;

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	position: relative;
	padding: ${t.ratios.l}rem;

	.${sc('media')} {
		width: 100%;
	}

	.${sc('expand-state-icon')} {
		margin-top: ${t.ratios.xs}rem;
		text-align: center;
		
		> button {
			background-color: transparent;
			color: inherit;
			padding: 0;
		}
	}
	
	.${sc('tn')} {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-bottom: ${t.ratios.xs}rem;
	}

	.${sc('media')} {
		width: 100%;
	}

	.${sc('info')} {
		position: relative;
		line-height: ${t.ratios.l}em;
		padding-top: ${t.ratios.xs}rem;

		> h4 {
			text-align: center;
			font-size: ${t.ratios.xl}em;
			line-height: ${t.ratios.l}em;
		}
		> div {
			> span {
				+ span {
					&:before {
						display: inline-block;
						content: '-';
						padding: 0 ${t.ratios.xs / 2}em;
					}
				}
			}
		}
	}
	
	.${sc('value')} {
		font-weight: ${t.font.weights.semibold};

		span.${sc('bull')} {
			opacity: 0.5;
		}
	}
	
	.${sc('plValue')} {
		font-weight: ${t.font.weights.bold};
	}
	
	.${sc('label')} {
		font-size: ${t.ratios.xs * 1.1}em;
		font-weight: ${t.font.weights.bold};
		letter-spacing: 0.05em;
		display: block;
		opacity: 0.5;
		text-transform: uppercase;
	}
	
	.${sc('block')} {
		margin-bottom: ${t.ratios.s}rem;
		word-break: break-word;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.${sc('actions')} {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		
		& > * {
			display: flex;
			margin-top: ${t.ratios.xs}rem;
			line-height: 1rem;

			&:first-child {
				margin-top: 0;
			}
		}
	}
	
	cursor: pointer;
	
	.${sc('block')} .${sc('badge')} {
		margin-bottom: ${t.ratios.xs}rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
	
	&.${sc('closed')} {
	
		.${sc('es')} {
			text-align: center;
		}
	
		.${sc('description')} {
			max-height: ${t.ratios.l}em;
			position: relative;
			overflow: hidden;
			
			&:after {
				/* Rende l'overflow: hidden sfumato */
				position: absolute;
				display: block;
				font-size: 0;
				content: ' ';
				bottom: 0;
				left: 0;
				right: 0;
				height: ${t.ratios.xl}rem;
				background: linear-gradient(0deg,rgba(255,255,255,1),rgba(255,255,255,0));
			}
		}
	}

	&.${sc('level', 'MAESTRO')} {
		border: 3px solid ${t.colors.premium.plain};
		.${sc('media')} {
			margin-top: ${t.ratios.xs * 2}rem;
		}
		.${sc('tn')} {
			padding-bottom: 0;
		}
		.${sc('info')} {
			padding-top: 0;
		}
	}

	.${sc('badgeContainer')} {
		width: calc(${t.ratios.l * 3}rem / 0.362); // ratio
		height: ${t.ratios.l * 3}rem;
		transform: rotate(-90deg) translateY(-100%);
		transform-origin: right top;
		position: absolute;
		top: 4rem;
		right: 0;
		z-index: ${t.zIndexes.P1_SlightlyAbove};

		> figure {
			width: 100%;
			height: 100%;
		}

		div {
			width: 100%;
			height: 100%;
			padding-top: ${t.ratios.s}rem;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			left: 0;

			> figure {
				width: calc(${(t.ratios.l * 3 * 3) / 4}rem * 0.87); // ratio
				height: ${(t.ratios.l * 3 * 3) / 4}rem;
				transform: rotate(90deg);

				&:not(:last-child) {
					margin-right: ${t.ratios.s}rem;
				}
			}
		}
	}
	
	&.${sc('layout-full')} {

		.${sc('es')} {
			display: block;
		}
	}

	&.${sc('layout-compact')} {
		padding: ${t.ratios.xl / 2}rem;

		.${sc('tn')} {
			padding-bottom: 0;
		}
		
		.${sc('info')} > h4 {
			font-size: ${t.ratios.l}em;
			font-weight: ${t.font.weights.regular};
		}
	}

	.${sc('certifications')} {
		.${sc('value')} {
			font-size: 0.8rem;
		}
	}
`,
);

const sc = currySC(styleClass);

const certificationEllipsis = (entities: ReadonlyArray<any>, maxLength: number = 70) => {
	let i;
	let resultString = '';
	let results = new Array();
	for (i = 0; i < entities.length; i++) {
		const c = entities[i];
		if (resultString.length + c.name.length < maxLength) {
			results.push(c.name);
			resultString += (i > 0 ? ' . ' : '') + c.name;
		} else {
			resultString += '…';
			results.push('…');
			break;
		}
	}
	
	return results.map((c, i) => {
		return (
			<>
				{c}
				{
					(i < results.length - 1) ?
						<span className={sc('bull')}> &bull; </span>
					:
						''
				}
			</>
		);
	});
};

export const CompanyCard: React.FC<ICompanyCardProps> = ({
	className,
	entity,
	openInNewTab = false,
	style,
	layout = CompanyCardLayout.FULL,
	forceOpen,
}) => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0,
	});
	const track = useTracking();
	const level = companyLevel(entity.currentCompanyLevels);
	const where: TWhere = companyCardWhere(entity);
	
	useEffect(() => {
		if (inView) {
			track({
				type: 'view',
				what: [where],
			});
		}
		// evento analytics di view card
		(window as any).gtag('event', 'view_item_list', {
			items: [
				{
					id: entity.id,
					name: entity.name,
					brand: entity.name,
					variant: 'Supplier',
					category: companyLevelStr(entity.currentCompanyLevels),
					price: companyLevel2Price(entity.currentCompanyLevels),
					quantity: 1,
				},
			],
		});
		
	}, [inView]);
	
	
	const isCompact = layout === CompanyCardLayout.COMPACT;
	const region =
		entity.billingBranch &&
		entity.billingBranch.StateProvince &&
		entity.billingBranch.StateProvince.region;
	const badges =
		entity.currentCompanyLevels &&
		Array.isArray(entity.currentCompanyLevels) &&
		entity.currentCompanyLevels.length > 0 &&
		entity.currentCompanyLevels[0].companyBadges
			? entity.currentCompanyLevels[0].companyBadges
			: [];
	const trackClick = useCallback(
		(open, companyName, companyId) => {
			Sentry.addBreadcrumb({
				category: 'ui',
				message: open ? 'open Company card' : 'close Company card',
				level: Sentry.Severity.Info,
				data: {
					companyName,
					companyId,
				},
			});
			track({ type: open ? 'close' : 'open', what: where }, addWhere(where));
		},
		[track, where],
	);

	return (
		<WhereBlock step={where}>
			<Collapsible startOpen={levelOrMore(level, CompanyLevelEnum.PaidL2)}>
				{({ open: collapsibleOpen, expand, CollapsibleBlockClass, collapse }) => {
					const open = forceOpen || collapsibleOpen;
					const doEllipse = layout === CompanyCardLayout.COMPACT || !open;

					return (
						<Link
							to={generateUrl(ENTITY.COMPANY, ACTIONS.DETAIL, entity.slug)}
							onClick={(event) => {
								if (!open && !isCompact) {
									event.preventDefault();
									trackClick(open, entity.name, entity.id);
									expand();
									return false;
								} else {
									track({ type: 'go_to_detail', what: where }, addWhere(where));
									return true;
								}
							}}
							target={openInNewTab ? '_blank' : undefined}
							className={cns(className, mixin.directoryCard)}
							style={style}
						>
							<article
								ref={ref}
								className={cns(
									styleClass,
									sc(open ? 'open' : 'closed'),
									sc('level', level),
									sc('layout', layout),
								)}
							>
								<div className={mixin.cardCornerActions}>
									<UserObjectSubscription
										entityId={entity.id}
										collection={ENTITY.COMPANY}
									/>
								</div>
								<div className={sc('tn')}>
									{entity.logoMedia && (
										<MediaImg
											type={MediaType.Logo}
											className={sc('media')}
											entity={entity.logoMedia}
											alt={`Company ${entity.name} logo`}
										/>
									)}
									<div className={mixin.cardBadgeContainer}>
										{levelOrMore(level, CompanyLevelEnum.PaidL2) && !isCompact && (
											<span className={mixin.maestroBadge}>
												<Icon name={'star'} /> {t`MAESTRO`}
											</span>
										)}
									</div>
								</div>

								<CollapsibleBlockClass className={sc('badgeContainer')}>
									{!isCompact && badges && badges.length > 0 && (
										<>
											<MediaImg
												type={MediaType.Icon}
												entity={{
													id: '',
													name: 'badge container',
													type: MediaType.Icon,
													smallThumbUrl: badgeContainerBg,
													mediumThumbUrl: badgeContainerBg,
													largeThumbUrl: badgeContainerBg,
													__typename: 'Media',
												}}
												alt={`Badge container background`}
											/>

											<div>
												{badges.map((badge) =>
													badge.iconMedia ? (
														<MediaImg
															key={badge.id}
															type={MediaType.Icon}
															entity={badge.iconMedia}
															className={subClass(
																mixin.cardBadgeContainer,
																'badge',
															)}
															alt={`Badge ${badge.name} symbol`}
														/>
													) : (
														<span />
													),
												)}
											</div>
										</>
									)}
								</CollapsibleBlockClass>

								<div className={sc('info')}>
									<h4 className={sc('block')}>
										<ResponsiveEllipsis
											style={{ whiteSpace: 'pre-wrap' }}
											text={entity.name}
											maxLine={doEllipse ? 2 : 100}
											ellipsis="…"
											trimRight
											basedOn="words"
										/>
									</h4>
									<CollapsibleBlockClass>
										<div>
											{!isCompact && (
												<EditorialSectionBlock
													className={cns(sc('block'), sc('es'))}
													entities={entity.editorialSections}
													layout={
														open
															? EditorialSectionBlockLayouts.ICONS_TEXTS
															: EditorialSectionBlockLayouts.ICONS_ONLY
													}
													limit={3}
												/>
											)}
											{region && open && !isCompact && (
												<div className={sc('block')}>
													<span className={sc('label')}>{t`Region`}:</span>{' '}
													<span className={sc('value')}>{region}</span>
												</div>
											)}
											{!isCompact &&
												levelOrMore(level, CompanyLevelEnum.PaidL1) &&
												entity.companyCertifications.length > 0 && (
													<div className={cns(sc('block'),sc('certifications'))}>
														<span className={sc('label')}>
															{t`Certifications`}:
														</span>{' '}
														<span className={sc('value')}>
															{certificationEllipsis(
																entity.companyCertifications,
																!open ? 75 : 1000,
															)}
														</span>
													</div>
												)}
											{open && !isCompact && badges.length > 0 && (
												<div
													className={cns(
														sc('block'),
														mixin.listBadgeContainer,
													)}
												>
													{badges.map((badge) =>
														badge.iconMedia ? (
															<div
																className={cns(sc('badge'),subClass(
																	mixin.listBadgeContainer,
																	'badge',
																))}
																key={badge.id}
															>
																<MediaImg
																	type={MediaType.Icon}
																	entity={badge.iconMedia}
																	className={subClass(
																		mixin.listBadgeContainer,
																		'badgeIcon',
																	)}
																	alt={`Badge ${badge.name} symbol`}
																/>
																<span
																	className={subClass(
																		mixin.listBadgeContainer,
																		'badgeName',
																	)}
																>
																	{badge.name}
																</span>
															</div>
														) : (
															<span />
														),
													)}
												</div>
											)}
											{levelOrMore(level, CompanyLevelEnum.PaidL1) &&
												open &&
												!isCompact && (
													<div className={sc('block')}>
														<span className={sc('label')}>
															{t`Private Label Producer`}:
														</span>
														<span
															className={sc('plValue')}
															style={{
																color: entity.plProducer
																	? theme.colors.success.plain
																	: theme.colors.danger.plain,
															}}
														>
															{' '}
															{t(entity.plProducer ? 'Yes' : 'No')}
														</span>
													</div>
												)}
											{open && !isCompact && (
												<div className={cns(sc('actions'), sc('block'))}>
													<Button
														icon={'arrow_forward'}
														label={t`Go to profile`}
														type="success"
														layout={ButtonLayouts.BUTTON}
														iconPos={IconPositions.RIGHT}
													/>
												</div>
											)}
										</div>
									</CollapsibleBlockClass>
								</div>
								{!forceOpen && !isCompact && (
									<div className={sc('expand-state-icon')}>
										<Button
											icon={
												open
													? 'expand_less'
													: 'expand_more'
											}
											onClick={(event) => {
												event.preventDefault();
												event.stopPropagation();
												trackClick(open, entity.name, entity.id);
												if (open) {
													collapse();
												} else {
													expand();
												}
											}}
										/>
									</div>
								)}
							</article>
						</Link>
					);
				}}
			</Collapsible>
		</WhereBlock>
	);
};
