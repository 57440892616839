import React from 'react';
import { Select, Icon } from '@food/ui';
import { registerClass, cns, currySC } from '@food/css-manager';
import t from '../utils/labels';
import { IFNTheme } from '../utils/theme';
import { ENTITY } from '../utils/entities';
import {
	EditorialSectionSelector,
	EditorialSectionSelectorLayouts,
} from './entities/EditorialSectionSelector';
import { pluralEntityLabel } from '../utils/misc';
import { editorialSections } from '../static/editorialSections';
import { addWhere, useTracking, WhereBlock } from '../utils/tracking';
import { TWhere } from '../tracking-types';

enum SearchTypes {
	GOOD = 'GOOD',
	COMPANY = 'COMPANY',
}

export enum Layouts {
	STANDARD = 'STANDARD',
	COMPACT = 'COMPACT',
}

interface CategorySearchProps {
	layout?: Layouts;
	className?: string;
	searchType: SearchTypes;
	onSearchTypeChange: (t: SearchTypes) => void;
	customRef: any;
	closeHandler: () => void;
	forced: boolean;
}

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: ${t.ratios.xl}rem;
	background-image: url('${t.assets.categorySearchBackground}');
	background-size: cover;
	background-position: center center;
	justify-content: center;

	.${sc('innerContainer')} {
		padding: ${t.ratios.xl}rem;
		font-size: ${t.ratios.xl}rem;
		background: rgba(255,255,255,.95);
	}

	.${sc('questionContainer')} {
		text-align: center;
	}
	.${sc('question')} {
		font-size: ${t.ratios.xl}rem;
		line-height: ${t.ratios.l};
		flex: 1px 1 0; /* IE fix */
	}

	.${sc('typeFilterContainer')} {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		font-size: ${t.ratios.l}rem;
	}
	
	.${sc('select')} {
		display: inline-block;
	}
	
	.${sc('special')} {
		background-color: ${t.colors.white};
	}
	
	
	&.${sc('layout', 'STANDARD')} {
		.${sc('select')} {
			margin: 0 ${t.ratios.xs}em;
		}
	}
	&.${sc('layout', 'COMPACT')} {
		.${sc('select')} {
			margin: ${t.ratios.xs / 2}em 0;
		}
		.${sc('questionContainer')} {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
	
	.${sc('close-handler')} {
		font-size: ${t.ratios.l * 2}rem;
		padding-left: ${t.ratios.xs / 2}rem;
		padding-right: ${t.ratios.xs / 2}rem;
		padding-bottom: ${t.ratios.xs / 2}rem;
		border-bottom-left-radius: 50%;
		border-bottom-right-radius: 50%;
		background: rgba(255,255,255,.95);
	}
`,
);

const sc = currySC(styleClass);
const dropdownClass = registerClass((t: IFNTheme) => `font-size: ${t.ratios.xl}em;`);
const validEs = editorialSections;
const options = Object.keys(SearchTypes).map((key) => ({
	label: t(pluralEntityLabel(key as ENTITY)),
	value: SearchTypes[key],
}));

export const CategorySearch: React.FC<CategorySearchProps> = ({
	className,
	layout = Layouts.STANDARD,
	searchType,
	onSearchTypeChange,
	customRef,
	closeHandler,
	forced,
}) => {
	const where: TWhere = { type: 'Block', name: 'CategorySearch' };
	const track = useTracking();

	return (
		<WhereBlock step={where}>
			<div
				className={cns(className, styleClass, sc('layout', layout))}
				ref={customRef}
			>
				<div className={sc('innerContainer')}>
					<div className={sc('questionContainer')}>
						<span className={sc('question')}>{t`I'm looking for`}</span>
						<Select
							className={sc('select')}
							type={'alt'}
							selected={searchType}
							options={options}
							onChange={(t) => {
								track(
									{ type: 'switch_target_directory', to: t as SearchTypes },
									addWhere(where),
								);
								onSearchTypeChange(t as SearchTypes);
							}}
							hideSelected={true}
							dropdownClassName={dropdownClass}
						/>
						<span className={sc('question')}>{t`in the following category`}:</span>
					</div>
					<div className={sc('typeFilterContainer')}>
						<EditorialSectionSelector
							layout={
								layout === Layouts.COMPACT
									? EditorialSectionSelectorLayouts.COMPACT
									: EditorialSectionSelectorLayouts.STANDARD
							}
							entities={validEs}
							searchType={searchType}
							closeHandler={closeHandler}
						/>
					</div>
				</div>
				{!forced && (
					<div className={sc('close-handler')}>
						<Icon
							name={'arrow_upward'}
							onClick={() => {
								track({ type: 'close', what: where }, addWhere(where));
								closeHandler();
							}}
						/>
					</div>
				)}
			</div>
		</WhereBlock>
	);
};
