import React, { useContext } from 'react';
import { openRenewModal, UserData } from '@food/auth';
import { cns, registerClass, currySC } from '@food/css-manager';
import { IFNTheme } from '../../utils/theme';
import { Icon } from '@food/ui';
import { ENTITY } from '../../utils/entities';
import { UserLists } from '../UserLists';
import { loginMessageFactory } from '../LoginMessage';
import { TrackingContext } from '@food/tracking';
import { getLastWhere, useTracking } from '../../utils/tracking';

interface Props {
	entityId: string;
	collection: ENTITY;
	className?: string;
}

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	cursor: pointer;
	font-size: ${t.ratios.xl ** 2}rem;
	color: ${t.colors.grey.plain};
	
	&.${sc('active')} {
		color: ${t.colors.primary.plain};
	}
`,
);

const sc = currySC(styleClass);

export const UserObjectSubscription: React.FC<Props> = ({
	className,
	entityId,
	collection,
}) => {
	const trackingContext = useContext(TrackingContext);
	const parentWhere = getLastWhere(trackingContext.where);
	const track = useTracking();

	return (
		<UserData>
			{({ isLogged }) => (
				<UserLists>
					{({ isInList, addToFavourites, removeFromFavourites }) => {
						const isFavourite = isInList(entityId);

						return (
							<Icon
								name={
									isLogged && isFavourite
										? 'favorite'
										: 'favorite_border'
								}
								className={cns(
									styleClass,
									className,
									isFavourite && sc('active'),
								)}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();

									if (!isLogged) {
										track({
											type: 'requested_authentication_notlogged',
											reason: 'bookmark',
										});
										openRenewModal({
											customComponent: loginMessageFactory(),
										});
									} else if (isFavourite) {
										track({ type: 'unbookmark', what: parentWhere });
										removeFromFavourites(entityId, collection);
									} else {
										track({ type: 'bookmark', what: parentWhere });
										addToFavourites(entityId, collection);
									}
								}}
							/>
						);
					}}
				</UserLists>
			)}
		</UserData>
	);
};
