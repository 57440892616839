import React, { useContext } from 'react';
import { useParams } from 'react-router';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { NotFound } from './blocks/NotFound';
import { cns, currySC, registerClass, subClass } from '@food/css-manager';
import { IFNTheme, mixin } from '../utils/theme';
import { DirectoryLayouts, DirectoryViewLayouts, moduleFilter } from './Directory';
import { isLogged, openRenewModal, UserData } from '@food/auth';
import { FrequentSearchCollection, MediaType, UserStatus } from '../server-types';
import t from '../utils/labels';
import { CompanyDirectoryQuery } from './entities/CompanyDirectory';
import { goodDirectoryQuery } from './entities/GoodDirectory';
import { CompanyCard } from './entities/CompanyCard';
import { addWhere, WhereBlock } from '../utils/tracking';
import { loadable, LoadableDirection, loadDelayStyle } from '../utils/loadable';
import { GoodCard } from './entities/GoodCard';
import { renderContext } from '../utils/context/renderContext';
import { TTrackAction, TWhere } from '../tracking-types';
import { nodes } from '../utils/misc';
import { Button, ButtonLayouts, IconPositions } from '@food/ui';
import { layouts as MediaLayouts, MediaImg } from './entities/MediaImg';
import { Link } from 'react-router-dom';
import { useTracking, ViewBlock } from '@food/tracking';
import { loginMessageFactory } from './LoginMessage';
import { ACTIONS, generateUrl } from '../utils/urls';
import { ENTITY } from '../utils/entities';
import { SuggestedFSCard, SuggestedFSCardLayout } from './SuggestedFSCard';
import { FrequentSearchLinkFragment } from './entities/FrequentSearchCard';
import { companyCardWhere, goodCardWhere } from '../utils/where';
import { ApolloError } from 'apollo-client';

interface FrequentSearchDirectoryProps {
	layout?: DirectoryLayouts;
}

const fsDirectoryQuery = gql`
	query FSDirectoryQuery($id: ID!) {
		frequentSearch: node(id: $id) {
			id
			... on FrequentSearch {
				name
				collection
				linkUrl
				linkRoles
				logoMedia {
					id
					origUrl
					mediumThumbUrl
				}
				relatedFair {
					id
					slug
					name
					year
					edition
				}
				...FrequentSearchLink
			}
		}
	}
	${FrequentSearchLinkFragment}
`;

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	margin-top: ${t.ratios.s}rem;
	margin-bottom: ${t.ratios.s}rem;
	
	.${sc('content')} {
		margin: 0 ${t.ratios.s}rem;
		display: flex;
		
		&.${sc('filterCollapsed')} {
			flex-direction: column;
			> div {
				margin-bottom: ${t.ratios.s}rem;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	
	.${sc('filter-summary')} {
		z-index: ${t.zIndexes.P2_AreaAbove};
	}
	
	.${sc('bottom-pager')} {
		box-shadow: ${t.boxShadow(1)};
		background-color: ${t.colors.white};
		padding: ${t.ratios.l}rem;
		text-align: center;
		margin-bottom: 0;
		
		> div {
			// di default prende il pager al centro
			margin-left: 1rem;
			display: inline-block;
		}
	}
	
	.${sc('name')} {
		display: block;
		font-size: ${t.ratios.xl}rem;
		font-weight: ${t.font.weights.semibold};
		margin: ${t.ratios.xl / 2}rem ${t.ratios.xl}rem;
	}
	
	.${sc('download-btn-container')} {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.${sc('download-btn')} {
		margin: ${t.ratios.xl}rem;
	}

	.${sc('filter')} {
		flex: 0 0 ${10 * t.ratios.xl}rem;
		position: relative;
		background-color: ${t.colors.white};

		> figure {
			width: 100%;
		}
	}

	&.${sc('layout', DirectoryLayouts.TWO_COL)} .${sc('filter')} {
		> figure {
			max-width: 100% !important;
		}
	}
`,
);

const contentStyle = registerClass(
	(t: IFNTheme, sc) => `
	padding-left: ${
		t.ratios.s
	}rem; // sarebbe meglio avere qui una gestione piu' simil alla directory tradizionale...
	flex: 1 1 auto;

	&.${sc('filterCollapsed')} {
		padding: 0;
	}
`,
);

const shadowChildrenClass = registerClass(
	(t: IFNTheme) => `box-shadow: ${t.boxShadow(1)};`,
);

const shadowOverClass = registerClass(
	(t: IFNTheme) => `
	transition: box-shadow 0.1s ease-in-out;
	&:hover {
		box-shadow: ${t.boxShadow(2)};
	}
`,
);

const sc = currySC(styleClass);

export const FrequentSearchDirectory: React.FC<FrequentSearchDirectoryProps> = ({
	layout,
}) => {
	const track = useTracking();
	const { id } = useParams<{ id: string }>();
	const { isBot } = useContext(renderContext);
	let viewLayout;
	let filterCollapsed = false;
	switch (layout) {
		case DirectoryLayouts.TWO_COL:
			viewLayout = DirectoryViewLayouts.TWO_COL;
			filterCollapsed = true;
			break;
		case DirectoryLayouts.FE_TWO_COL:
			viewLayout = DirectoryViewLayouts.TWO_COL;
			break;
		case DirectoryLayouts.FE_THREE_COL:
			viewLayout = DirectoryViewLayouts.THREE_COL;
			break;
		case DirectoryLayouts.FE_FOUR_COL:
			viewLayout = DirectoryViewLayouts.FOUR_COL;
			break;
		default:
			viewLayout = DirectoryViewLayouts.FIVE_COL;
			filterCollapsed = false;
			break;
	}

	let columns = [0, 1];
	if (viewLayout === DirectoryViewLayouts.THREE_COL) {
		columns = [0, 1, 2];
	}
	if (viewLayout === DirectoryViewLayouts.FOUR_COL) {
		columns = [0, 1, 2, 3];
	}
	if (viewLayout === DirectoryViewLayouts.FIVE_COL) {
		columns = [0, 1, 2, 3, 4];
	}

	return (
		<UserData>
			{({ loading, userData }) => {
				if (loading) {
					return null;
				}

				return (
					<Query query={fsDirectoryQuery} variables={{ id }}>
						{({
							error,
							loading,
							data: fsData,
						}: {
							loading: boolean;
							error?: ApolloError;
							data: any;
						}) => {
							if (error) {
								return <NotFound />;
							}
							if (loading) {
								return null;
							}

							const entity = fsData.frequentSearch;
							const where: TWhere = {
								type: 'FrequentSearchDirectory',
								filterStatus: {},
								page: 1,
							};

							const canViewLink =
								entity.linkRoles.includes('Anonymus') ||
								(isLogged() &&
									userData &&
									entity.linkRoles.includes(userData.role) &&
									userData.status === UserStatus.Active);

							const onLinkClickButCantView = () => {
								if (!isLogged()) {
									track(
										{
											type: 'requested_authentication_notlogged',
											reason: 'fs_link',
										},
										addWhere(where),
									);
									openRenewModal({
										customComponent: loginMessageFactory(),
									});
								} else {
									track(
										{
											type: 'requested_upgrade_logged',
											reason: 'fs_link',
											currentRole: userData.role,
											validRoles: entity.linkRoles,
										},
										addWhere(where),
									);
									openRenewModal({
										customComponent: loginMessageFactory(),
										upgradeRole: entity.linkRoles,
									});
								}
							};

							return (
								<Query
									query={
										entity.collection === FrequentSearchCollection.Company
											? CompanyDirectoryQuery
											: goodDirectoryQuery
									}
									variables={{
										filter: { FrequentSearch: { id: entity.id } },
									}}
								>
									{({
										error,
										loading,
										data,
									}: {
										loading: boolean;
										error?: ApolloError;
										data: any;
									}) => {
										if (error || loading) {
											return null;
										}

										const entities = nodes(data.entities);
										const relatedFS = data.entities.relatedFrequentSearches;
										let relatedFSShowColumn = 2;
										if (viewLayout === DirectoryViewLayouts.TWO_COL) {
											relatedFSShowColumn = 1;
										}

										const prerenderCards = columns.map((i) =>
											moduleFilter(entities, columns.length, i).map(
												(e, i2) => {
													const isCompany = e.__typename === 'Company'; // altrimenti e' un Good
													const Component = isCompany
														? CompanyCard
														: GoodCard;

													return (
														<WhereBlock
															key={e.id}
															step={{
																type: 'Block',
																name: 'CardPosition',
																column: i,
																row: i2,
															}}
														>
															<Component
																entity={e}
																key={e.id}
																className={cns(
																	loadable(
																		isBot,
																		LoadableDirection.BOTTOM,
																	),
																	shadowChildrenClass,
																	shadowOverClass,
																)}
																style={loadDelayStyle(0.2 * i2)}
																// @ts-ignore
																layout={
																	layout === DirectoryLayouts.TWO_COL
																		? 'compact'
																		: 'full'
																}
															/>
														</WhereBlock>
													);
												},
											),
										);

										if (Array.isArray(relatedFS) && relatedFS.length > 0) {
											const suggestedCardElem = (
												<SuggestedFSCard
													fs={relatedFS}
													layout={
														layout === DirectoryLayouts.TWO_COL
															? SuggestedFSCardLayout.COMPACT
															: SuggestedFSCardLayout.STANDARD
													}
												/>
											);
											if (relatedFSShowColumn === 0) {
												prerenderCards[relatedFSShowColumn].splice(
													1,
													0,
													suggestedCardElem,
												);
											} else {
												prerenderCards[relatedFSShowColumn] = [
													suggestedCardElem,
													...prerenderCards[relatedFSShowColumn],
												];
											}
										}

										const viewAction: TTrackAction = {
											type: 'view',
											what: [
												where,
												...entities.map((entity) =>
													entity.__typename === 'Company'
														? companyCardWhere(entity)
														: goodCardWhere(entity),
												),
											],
										};

										return (
											<WhereBlock step={where}>
												<ViewBlock event={viewAction}>
													<div
														className={cns(
															mixin.mainPageContent,
															styleClass,
															sc('layout', layout),
														)}
													>
														<div
															className={cns(
																sc('content'),
																filterCollapsed && sc('filterCollapsed'),
															)}
														>
															<div
																className={cns(
																	sc('filter'),
																	shadowChildrenClass,
																	loadable(
																		isBot,
																		LoadableDirection.LEFT,
																	),
																)}
															>
																<MediaImg
																	type={MediaType.Logo}
																	entity={entity.logoMedia}
																	layout={MediaLayouts.MEDIUM}
																	alt={`Frequent Search ${entity.name} header`}
																/>
																<div className={sc('filter-text')}>
																	<h1 className={sc('name')}>
																		{entity.name}
																		{entity.relatedFair && (
																			<>
																				{' at '}
																				<Link
																					to={generateUrl(
																						ENTITY.FAIR,
																						ACTIONS.DETAIL,
																						entity.relatedFair.slug,
																					)}
																				>
																					{entity.relatedFair.name}{' '}
																					{entity.relatedFair.year}
																				</Link>
																			</>
																		)}
																	</h1>
																	{entity.linkUrl && (
																		<div
																			className={sc(
																				'download-btn-container',
																			)}
																		>
																			<Button
																				className={sc('download-btn')}
																				label={t`Printable version`}
																				icon={'file_download'}
																				iconPos={IconPositions.RIGHT}
																				linkTarget={
																					canViewLink
																						? '_blank'
																						: undefined
																				}
																				linkTo={
																					canViewLink
																						? entity.linkUrl
																						: undefined
																				}
																				layout={ButtonLayouts.BUTTON}
																				type={'success'}
																				onClick={(event) => {
																					if (canViewLink) {
																						track(
																							{
																								type:
																									'generic_click',
																								name:
																									'url from frequent search',
																								url:
																									entity.linkUrl,
																							},
																							addWhere(where),
																						);
																					} else {
																						event.preventDefault();
																						event.stopPropagation();
																						track({
																							type:
																								'requested_authentication_notlogged',
																							reason:
																								'download_frequentsearch_pdf',
																						});
																						onLinkClickButCantView();
																					}
																				}}
																			/>
																		</div>
																	)}
																</div>
															</div>
															<div
																className={cns(
																	contentStyle,
																	filterCollapsed &&
																		subClass(
																			contentStyle,
																			'filterCollapsed',
																		),
																)}
															>
																<div
																	className={cns(
																		mixin.expandableItemsGrid,
																		layout ===
																			DirectoryLayouts.TWO_COL &&
																			subClass(
																				mixin.expandableItemsGrid,
																				'compact',
																			),
																	)}
																>
																	{columns.map((i) => (
																		<div key={i}>
																			{prerenderCards[i]}
																		</div>
																	))}
																</div>
															</div>
														</div>
													</div>
												</ViewBlock>
											</WhereBlock>
										);
									}}
								</Query>
							);
						}}
					</Query>
				);
			}}
		</UserData>
	);
};
