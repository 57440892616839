import t from "./labels";

export function iso8601duration(ms) {
	if (ms === 0) {
		return 'P0D';
	}
	let offset = Math.floor(ms);
	let days = 0;
	if (offset < 0) {
		days = Math.floor(offset % 86400000);
		offset -= 86400000 * days;
	}
	let milliseconds = (offset % 1000).toString();
	offset = Math.floor(offset / 1000);
	const seconds = (offset % 60).toString();
	offset = Math.floor(offset / 60);
	const minutes = (offset % 60).toString();
	offset = Math.floor(offset / 60);
	const hours = (offset % 24).toString();
	days += Math.floor(offset / 24);
	const parts = ['P'];
	if (days) {
		parts.push(days + 'D');
	}
	if (hours || minutes || seconds || milliseconds) {
		parts.push('T');
		if (hours) {
			parts.push(hours + 'H');
		}
		if (minutes) {
			parts.push(minutes + 'M');
		}
		if (seconds || milliseconds) {
			parts.push(seconds);
			if (milliseconds) {
				while (milliseconds.length < 3) {
					milliseconds = '0' + milliseconds;
				}
				parts.push('.' + milliseconds);
			}
			parts.push('S');
		}
	}
	return parts.join('');
}

export function daysInMonthsYears(days: number) {
	if (days === 365) {
		return '1 ' + t`year`
	}

	return days + ' ' + t`days`;
}