import React, { useCallback, useState } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import { parse } from 'querystring';
import { Button, Select } from '@food/ui';
import { cns, registerClass, currySC } from '@food/css-manager';
import { IFNTheme, mixin, theme } from '../../utils/theme';
import t from '../../utils/labels';
import { nodes, order } from '../../utils/misc';
import {
	FairCard,
	Fragment as FairCardFragment,
} from '../../components/entities/FairCard';
import { filterErrors } from '../../utils/errors';
import { loadable, LoadableDirection } from '../../utils/loadable';
import { GenericHead } from '../head/GenericHead';
import { addWhere, useTracking, WhereBlock } from '../../utils/tracking';
import { useLocation } from '../../utils/hooks';
import { TWhere } from '../../tracking-types';
import { continents } from '../../static/continents';
import { ApolloError } from 'apollo-client';

export enum FairDirectoryLayouts {
	ONE_COL = 'ONE_COL',
	TWO_COL = 'TWO_COL',
	THREE_COL = 'THREE_COL',
	FOUR_COL = 'FOUR_COL',
}

export function fairDirectoryLayoutChooser(w: number): FairDirectoryLayouts {
	const { mobileMax, tabletMax, notebookMax } = theme.breakpoints;
	if (w <= mobileMax) {
		return FairDirectoryLayouts.ONE_COL;
	} else if (w <= tabletMax) {
		return FairDirectoryLayouts.TWO_COL;
	} else if (w <= notebookMax) {
		return FairDirectoryLayouts.THREE_COL;
	} else {
		return FairDirectoryLayouts.FOUR_COL;
	}
}

interface FairDirectoryState {
	onlySaved: boolean;
	year: number;
	quarter: number;
	area?: string;
}

interface FairDirectoryProps {
	layout?: FairDirectoryLayouts;
}

const styleClass = registerClass(
	(t: IFNTheme, psc) => `
	padding: 0 ${t.ratios.l}rem;
	
	.${psc('title')} {
		font-size: ${t.ratios.l * 2}rem;
		font-weight: ${t.font.weights.semibold};
		text-transform: uppercase;
		display: block;
	}

	.${psc('nav')} {
		padding-bottom: ${t.ratios.l}rem;
	}

	.${psc('timeSelector')} {
		margin-right: ${t.ratios.xl * 2}rem;
	}

	.${psc('items')} {
		display: flex;
		flex-wrap: wrap;
		margin-left: -${t.ratios.xl / 2}rem;
		margin-right: -${t.ratios.xl / 2}rem;
		justify-content: space-between;

		> * {
			margin-left: ${t.ratios.xl / 2}rem;
			margin-right: ${t.ratios.xl / 2}rem;
			margin-bottom: ${t.ratios.xl / 2}rem;

			> * {
				height: 100%;
			}
		}

		> a {
			color: ${t.colors.ink};
			text-decoration: none;
			margin-bottom: ${t.ratios.l * 2}rem;
		}
	}
	
	.${psc('m-left')} {
		margin-left: 1rem;
	}
	
	.${psc('m-right')} {
		margin-right: 1rem;
	}
	
	.${psc('header')} {
		margin-top: ${t.ratios.xl}rem;
		margin-bottom: ${t.ratios.xl}rem;
		padding: ${t.ratios.xl}rem;
		background-color: ${t.colors.white};
		
		
		.${psc('commands')} {
			display: flex;
			flex-wrap: wrap;
			margin-top: 1rem;
		}
	}
	
	.${psc('fake-card')} {
		height: 0;
		margin-top: 0;
		margin-bottom: 0;
	}
	
	.${psc('above-select')} {
		z-index: ${t.zIndexes.P2_AreaAbove};
	}
	
	.${psc('with-shadow')} {
		box-shadow: ${t.boxShadow(1)};
	}
	
	&.${psc('layout-TWO_COL')} {
		.${psc('items')} {
			justify-content: center;
		}
	}
	
	&.${psc('layout-ONE_COL')} {
		.${psc('timeSelector')} {
			margin-bottom: ${theme.ratios.xs}rem;
		}
	}
	
`,
);

const sc = currySC(styleClass);

const fairsQuery = gql`
	query FairDirectoryQuery($filter: FairFilterInput) {
		fairs(filter: $filter) {
			edges {
				node {
					...FairCardFragment
				}
			}
			total
		}
	}
	${FairCardFragment}
`;

export const FairDirectory: React.FC<FairDirectoryProps> = ({ layout }) => {
	const now = new Date();
	const track = useTracking();
	const [filter, setFilter] = useState<FairDirectoryState>({
		onlySaved: false,
		year: now.getFullYear(),
		quarter: Math.floor((now.getMonth() + 1) / 4) + 1,
	});
	const { location } = useLocation();
	const { onlySaved, year, quarter, area } = filter;
	const from = dayjs()
		.set('month', (quarter - 1) * 3)
		.set('year', year)
		.set('date', 1)
		.startOf('day');
	const to = dayjs()
		.set('month', (quarter - 1) * 3 + 2)
		.set('year', year)
		.endOf('month')
		.endOf('day');
	const variables: any = {
		timespanMin: from.toDate(),
		timespanMax: to.toDate(),
	};
	const fairDirectoryWhere: TWhere = {
		type: 'FairDirectory',
		page: 1,
		filterStatus: variables,
	};

	const moveQuarter = useCallback(
		(n: number) => {
			const tmp = quarter + n;
			let newQuarter = tmp;
			let newYear = year;

			if (tmp === 0) {
				newYear -= 1;
				newQuarter = 4;
			} else if (tmp === 5) {
				newYear += 1;
				newQuarter = 1;
			}
			track(
				{
					type: 'filter_update',
					oldValue: `${filter.year} quarter ${filter.quarter}`,
					newValue: `${newYear} quarter ${newQuarter}`,
					key: 'quarter',
				},
				addWhere(fairDirectoryWhere),
			);
			setFilter({ ...filter, quarter: newQuarter, year: newYear });
		},
		[quarter, year, track, filter, fairDirectoryWhere],
	);

	if (onlySaved) {
		variables.hasMyVisit = true;
	}

	const keys = parse(location.search.replace('?', ''));
	const finalArea = area || (keys.area as string) || 'world';

	const areas = Object.keys(continents).map((a) => ({ label: a, value: a }));
	const selectedAreaCountries = (continents[finalArea] || []).map((id) => ({ id }));

	areas.unshift({
		label: t`World`,
		value: 'world',
	});

	if (finalArea !== 'world') {
		variables.Country = selectedAreaCountries;
	}

	return (
		<WhereBlock step={fairDirectoryWhere}>
			<GenericHead
				title={t`tradeshow directory title`}
				keywords={
					'F&B Food, Beverage, Retail, Tradeshows, Fairs, Fairshows, Events, World, Europe, Africa, United States, Asia, Mid East'
				}
				description={t`tradeshow directory description`}
			/>
			<div className={cns(styleClass, sc('layout' + layout))}>
				<div
					className={cns(
						sc('header'),
						mixin.cardBlock,
						loadable(false, LoadableDirection.BOTTOM),
					)}
				>
					<div className={sc('title')}>{t`Tradeshows`}</div>
					<div className={sc('commands')}>
						<div className={sc('timeSelector')}>
							<Button
								className={sc('m-right')}
								icon={'arrow_back'}
								onClick={() => moveQuarter(-1)}
								type={'alt'}
							/>
							<span>
								{year} - {order(quarter)} {t`quarter`}
							</span>
							<Button
								type={'alt'}
								className={sc('m-left')}
								icon={'arrow_forward'}
								onClick={() => moveQuarter(+1)}
							/>
						</div>
						<div>
							<Select
								className={sc('above-select')}
								selected={finalArea}
								options={areas}
								optionsOneLine={true}
								type={'alt'}
								onChange={(area) => {
									track(
										{
											type: 'filter_update',
											oldValue: filter.area,
											newValue: area,
											key: 'area',
										},
										addWhere(fairDirectoryWhere),
									);
									setFilter({ ...filter, area });
								}}
							/>
						</div>
					</div>
				</div>
				<Query
					query={fairsQuery}
					variables={{ filter: variables }}
					onCompleted={(data) => {
						track(
							{
								type: 'view',
								what: [fairDirectoryWhere],
							},
							addWhere(fairDirectoryWhere),
						);
					}}
				>
					{({
						loading,
						error,
						data,
					}: {
						loading: boolean;
						error?: ApolloError;
						data: any;
					}) => {
						if (loading) {
							return null;
						}

						const errors = error ? error.graphQLErrors : [];
						const fairs = !loading && !error ? nodes(data.fairs) : [];

						return (
							<div className={cns(sc('items'))}>
								{fairs.map((f, i) => {
									const fairErrors = filterErrors(
										errors,
										[['fairs', 'edges', i, 'node']],
										false,
									);

									return (
										<FairCard
											className={cns(
												loadable(false, LoadableDirection.BOTTOM),
												mixin.fullWidthCard,
												sc('with-shadow'),
											)}
											entity={f}
											key={f.id}
											errors={fairErrors}
										/>
									);
								})}
								{Array(10)
									.fill(null)
									.map((v, i) => (
										<div
											key={i}
											className={cns(mixin.fullWidthCard, sc('fake-card'))}
										/>
									))}
							</div>
						);
					}}
				</Query>
			</div>
		</WhereBlock>
	);
};
