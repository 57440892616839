/**
 * @description mette in relazione le regioni d'Italia con gli ID delle citta', necessarie per fare query su graphql
 */
export const regions = {
	Abruzzo: ['J702qu9i8I0~f4f', 'vjqb8qaQaTY~e90', 'GubFSbTXDLE~fd9', 'jST3_YQmh_c~a7e'],
	'Aosta Valley': ['1RSXuRwEhao~8af'],
	Apulia: [
		'R7CaY3Wa3Tw~b77',
		'18GKGW8Rd1Y~eff',
		'WnQt_KeQ7eg~9b5',
		'.LQ6DvYOvNM~edf',
		'671213EuJDA~f7f',
		'AGuRby9jCMw~b4b',
	],
	Basilicata: ['DU3JcQdgxjk~82f', 'e9NjMcezwGY~e16'],
	Calabria: [
		'U1SwrWgU47M~fa7',
		'dRJpXFQg7xA~d76',
		'UBp7ukCO9sc~9cb',
		'oSxcncn5Uzo~982',
		'mvT4mC4LMkw~9ec',
	],
	Campania: [
		'OolxByC_24M~fd1',
		'aF4ftLnODfk~9a6',
		'_59l1QUbS6Y~f77',
		'50cZ5HKaN9E~f7b',
		'XUKCI6A6YCQ~fff',
	],
	'Emilia Romagna': [
		'nBbskN5W1WY~fe2',
		'XSlTg65ndwY~c6b',
		'pRw7hRbkHuA~d2a',
		'WIacWH7yEcQ~d73',
		'E3tStkYGMIA~fcb',
		'hSRNYZGCmNk~afe',
		'4m9.VEXZUJk~bfd',
		'ZxCeA0TZTOU~ff5',
		'rSOtuuchgls~806',
	],
	'Friuli Venezia Giulia': [
		'icx2iOjdqhw~828',
		'lOyfgtdeV2E~f02',
		'2ynD3dTak_E~e59',
		'Fv7FklHUAzM~dcd',
	],
	Lazio: [
		'UagV154NZS4~ff9',
		'sJgOtDWdDmU~d6a',
		'UEHxv01EZxY~de7',
		'1AiEZvjykbo~81b',
		'PVUoMVBDbys~8f7',
	],
	Liguria: ['r8t_u2MNN.E~fea', 'B5cFogWZQ4w~bcb', 'Gh5skaNFb7Y~ec5', 'pufRdjeb0DM~f08'],
	Lombardy: [
		'Er1HEOt2Lds~9bd',
		'gJhanUb3jAY~ea2',
		'nG.ZIqtkELc~b1e',
		'0pyQElR1f5M~ed9',
		'TGyorjqIpgE~c83',
		'uP7dvHh4udA~ca6',
		'QJr4it4_7zM~dcb',
		'29U3_4.ltng~87f',
		'pYIfrTt8GxM~da6',
		'w9._SvjtWkE~d1e',
		'ExCCEZR8MnM~dfd',
		'thwzdcAOw3Q~ec0',
	],
	Marche: [
		'UzKUAI2TcyU~cfd',
		'PTXXKUBsDrs~97f',
		's48H45dQugg~8be',
		'yfRIas9KLBc~bcc',
		'E1O85EDft6o~a7f',
	],
	Molise: ['wADEazmBO4s~b8e', 'G9zoLu71HvM~dd3'],
	Piedmont: [
		'ZWMK08dljEA~e3f',
		'ZaPEbJgxkKk~a2d',
		'4pFFRpoPUtM~d9d',
		'qPPbrpen7j0~d06',
		'k.h0QaT86dw~9da',
		'nH7r8v2Yq3o~ad6',
		'81w_EDGE1jI~dfb',
		'igIplPQoDto~964',
	],
	Sardinia: [
		'IqZV5BC0SYI~ffd',
		'X7nOKIikfew~83b',
		'GINWg9U_FSo~bef',
		'aynUw_tyYxQ~d28',
		'rP6a1fB_oFA~ed6',
		'tclB2YFDv24~ef8',
		'8nABw1jGUe0~dad',
		'RTb.MZjAeXI~ebb',
	],
	Sicily: [
		'HwjUH2i1uwA~cb9',
		'0zaPI8jzLB4~f39',
		'4dEclGH9qr0~ce5',
		'jsVQDPlHOyo~9bc',
		'B1.zijDnYFU~f47',
		'XUJyuGKM5dY~de7',
		'qIDzTO6.sQ0~ef6',
		'Xbvqr88zYs8~d61',
		'4VlZNnJaTlw~95b',
	],
	'Trentino South Tyrol': ['P0EzoPnkO7k~b27', 'hqaZMfVCmqw~8d8'],
	Tuscany: [
		'Uph_L7PeKCk~b79',
		'ff9l7UKu8ks~974',
		'00mea6Rcyb4~c63',
		'JLJ.l99hLjE~d6f',
		'dIDoK8uze_k~a36',
		'j94B1YBnlqQ~c7e',
		'i.6SbaduRqY~d0e',
		'CqWc2_k9hnA~cb5',
		'hQB8quqYN9Q~f8e',
		'1Hw0FP539U4~ffb',
	],
	Umbria: ['4TuRbNxWMh0~dab', 'Vq97oS70AJw~bed'],
	Veneto: [
		'Ehk8Sn_08Ao~bd9',
		'InMWUx28M0M~fdd',
		'tpBvAUb6qBc~ab4',
		'jFW.MD3U9Y0~ffe',
		'BvXtGsaHRz8~d95',
		'7I5SOgw_M_k~b9f',
		'kUvcu5enUnE~d22',
	],
};
