import React, { CSSProperties, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { IFNTheme, mixin, theme } from '../../utils/theme';
import { Button, ButtonLayouts, Collapsible, IconPositions } from '@food/ui';
import { MediaImg, mediaImgFragment } from './MediaImg';
import { cns, currySC, registerClass } from '@food/css-manager';
import t from '../../utils/labels';
import { ACTIONS, generateUrl } from '../../utils/urls';
import { ENTITY } from '../../utils/entities';
import {
	EditorialSectionBlock,
	EditorialSectionBlockLayouts,
	Fragment as EditorialSectionBlockFragment,
} from '../../components/entities/EditorialSectionBlock';
import { companyLevel, companyLevel2Price, companyLevelStr, levelOrMore } from '../../utils/misc';
import { UserObjectSubscription } from './UserObjectSubscription';
import { Brand, CompanyLevelEnum, MediaType } from '../../server-types';
import { addWhere, useTracking, WhereBlock } from '../../utils/tracking';
import { brandCardWhere } from '../../utils/where';
import * as Sentry from '@sentry/browser';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { CompanyCardLayout } from './CompanyCard';
import { useInView } from 'react-intersection-observer';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

interface IBrandCardProps {
	layout?: CompanyCardLayout;
	className?: string;
	entity: Brand;
	forceOpen?: boolean;
	openInNewTab?: boolean;
	style?: CSSProperties;
	seoHtmlTags?: boolean; // se impostato a true uso determinati tag in modo da risultare piu' appetibile in temrini di SEO
}

// graphql fragment
export const BrandCardFragment = gql`
	fragment BrandCardFragment on Brand {
		id
		name
		logoMedia {
			...MediaImgFragment
		}
		editorialSections {
			...EditorialSectionBlockFragment
		}
		Company {
			id
			name
			slug
			plProducer
			billingBranch {
				id
				StateProvince {
					id
					region
				}
			}
			companyCertifications {
				id
				name
			}
			goodFeatureCertifications {
				id
				name
			}
			currentCompanyLevels {
				id
				level
			}
			editorialSections {
				...EditorialSectionBlockFragment
			}
		}
	}
	${mediaImgFragment}
	${EditorialSectionBlockFragment}
`;

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	position: relative;
	padding: ${t.ratios.s}rem;

	.${sc('media')} {
		width: 100%;
	}

	> article {
		display: flex;
	}
	
	.${sc('tn')} {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-bottom: ${t.ratios.xs}rem;
	}

	.${sc('media')} {
		width: 100%;
		max-width: ${t.columnSize / 2}px;
	}
	
	.${sc('expand-state-icon')} {
		margin-top: ${t.ratios.xs}rem;
		text-align: center;
		
		> button {
			background-color: transparent;
			color: inherit;
			padding: 0;
		}
	}

	.${sc('info')} {
		position: relative;
		line-height: ${t.ratios.l}em;
		padding-top: ${t.ratios.xs}rem;

		h4, h2, h3 {
			font-size: ${t.ratios.xl}em;
			font-weight: ${t.font.weights.regular};
			line-height: ${t.ratios.s}em;
			text-align: center;
		}

	}
	
	.${sc('brand-of-label')} {
		text-align: center;
		color: ${t.colors.grey.dark};
		font-style: italic;
		font-size: 1em;
		line-height: 1;
		padding: 0.4em 0 0.3em;
	}
	
	.${sc('value')} {
		font-weight: ${t.font.weights.semibold};

		span.${sc('bull')} {
			opacity: 0.5;
		}
	}
	
	.${sc('plValue')} {
		font-weight: ${t.font.weights.bold};
	}

	.${sc('label')} {
		font-size: ${t.ratios.xs * 1.1}em;
		font-weight: ${t.font.weights.bold};
		letter-spacing: 0.05em;
		display: block;
		opacity: 0.5;
		text-transform: uppercase;
	}
	
	.${sc('block')} {
		margin-bottom: ${t.ratios.s}rem;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.${sc('actions')} {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		
		& > * {
			margin-top: ${t.ratios.xs}rem;
			line-height: 1rem;

			&:first-child {
				margin-top: 0;
			}
		}
	}
	
	&.${sc('closed')} .${sc('es')} {
		text-align: center;
	}
	
	&.${sc('layout-full')} {

		.${sc('es')} {
			display: block;
		}

		.${sc('certifications')} {
			.${sc('value')} {
				font-size: 0.8rem;
			}
		}
	}

	&.${sc('layout-compact')} {
		padding: ${t.ratios.s / 2}rem;

		.${sc('tn')} {
			padding-bottom: 0;
		}
		
		.${sc('info')} > h4 {
			font-size: ${t.ratios.l}em;
			font-weight: ${t.font.weights.regular};
		}
	}
`,
);

const sc = currySC(styleClass);

export const BrandCard: React.FC<IBrandCardProps> = ({
	className,
	entity,
	openInNewTab = false,
	style,
	layout = CompanyCardLayout.FULL,
	forceOpen,
}) => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0,
	});
	const track = useTracking();
	const level = companyLevel(entity.Company.currentCompanyLevels);
	const where = brandCardWhere(entity);
	const region =
		entity.Company.billingBranch &&
		entity.Company.billingBranch.StateProvince &&
		entity.Company.billingBranch.StateProvince.region;
	const media = entity.logoMedia;
	const isCompact = layout === CompanyCardLayout.COMPACT;

	const trackClick = useCallback(
		(open) => {
			Sentry.addBreadcrumb({
				category: 'ui',
				message: open ? 'open Brand card' : 'close Brand card',
				level: Sentry.Severity.Info,
				data: {
					brandName: entity.name,
					brandId: entity.id,
				},
			});
			track({ type: open ? 'open' : 'close', what: where }, addWhere(where));
		},
		[track, where, entity],
	);

	useEffect(() => {
		if (inView) {
			track({
				type: 'view',
				what: [where],
			});
		}
		
		// evento analytics di view card
		(window as any).gtag('event', 'view_item_list', {
			items: [
				{
					id: entity.id,
					name: entity.name,
					brand: entity.Company.name,
					variant: 'Brand',
					category: companyLevelStr(entity.Company.currentCompanyLevels),
					price: companyLevel2Price(entity.Company.currentCompanyLevels),
					quantity: 1,
				},
			],
		});
		
	}, [inView, where]);

	return (
		<WhereBlock step={where}>
			<Collapsible startOpen={levelOrMore(level, CompanyLevelEnum.PaidL2)}>
				{({ open: collapsibleOpen, expand, collapse, CollapsibleBlockClass }) => {
					const open = collapsibleOpen || forceOpen;
					return (
						<Link
							to={generateUrl(ENTITY.COMPANY, ACTIONS.DETAIL, entity.Company.slug)}
							onClick={(event) => {
								if (!open && !isCompact) {
									event.preventDefault();
									trackClick(open);
									expand();
									return false;
								} else {
									track({ type: 'go_to_detail', what: where }, addWhere(where));
									return true;
								}
							}}
							target={openInNewTab ? '_blank' : undefined}
							className={cns(className, mixin.directoryCard)}
							style={style}
						>
							<article
								ref={ref}
								className={cns(
									styleClass,
									sc(open ? 'open' : 'closed'),
									sc('level', level),
									sc('layout', layout),
								)}
							>
								<div className={mixin.cardCornerActions}>
									<UserObjectSubscription
										entityId={entity.Company.id}
										collection={ENTITY.COMPANY}
									/>
								</div>
								<div className={sc('tn')}>
									{media && (
										<MediaImg
											type={MediaType.Logo}
											className={sc('media')}
											entity={media}
											alt={entity.name}
										/>
									)}
								</div>
								<div className={sc('info')}>
									<div className={sc('block')}>
									<h4>
										<ResponsiveEllipsis
											style={{ whiteSpace: 'pre-wrap' }}
											text={entity.name}
											maxLine={layout === CompanyCardLayout.COMPACT ? 2 : 100}
											ellipsis="…"
											trimRight
											basedOn="words"
										/>
									</h4>
									<div className={sc('brand-of-label')}>{t`is a brand of`}</div>
									<h4>
										<ResponsiveEllipsis
											style={{ whiteSpace: 'pre-wrap' }}
											text={entity.Company.name}
											maxLine={layout === CompanyCardLayout.COMPACT ? 2 : 100}
											ellipsis="…"
											trimRight
											basedOn="words"
										/>
									</h4>
									</div>

									<CollapsibleBlockClass>
										{!isCompact && (
											<div>
												<EditorialSectionBlock
													className={cns(sc('block'), sc('es'))}
													entities={entity.Company.editorialSections}
													activeEntities={entity.editorialSections}
													layout={
														open
															? EditorialSectionBlockLayouts.ICONS_TEXTS
															: EditorialSectionBlockLayouts.ICONS_ONLY
													}
												/>
												{region && open && (
													<div className={sc('block')}>
														<span className={sc('label')}>
															{t`Region`}:
														</span>
														<span className={sc('value')}>{region}</span>
													</div>
												)}
												{levelOrMore(level, CompanyLevelEnum.PaidL2) && (
													<>
														<div className={cns(sc('block'),sc('certifications'))}>
															<span className={sc('label')}>
																{t`Certifications`}:
															</span>
															<span className={sc('value')}>
																{entity.Company.companyCertifications
																.map((c, i) => {
																	return (
																		<>
																			{c.name}
																			{
																				(i < entity.Company.companyCertifications.length - 1) ?
																					<span className={sc('bull')}> &bull; </span>
																				:
																					''
																			}
																		</>
																	);
																})}
															</span>
														</div>
														{entity.Company.goodFeatureCertifications
															.length > 0 && (
															<div className={cns(sc('block'),sc('certifications'))}>
																<span className={sc('label')}>
																	{t`Features`}:
																</span>
																<span className={sc('value')}>
																	{entity.Company.goodFeatureCertifications
																	.map((c, i) => {
																		return (
																			<>
																				{c.name}
																				{
																					(i < entity.Company.goodFeatureCertifications.length - 1) ?
																						<span className={sc('bull')}> &bull; </span>
																					:
																						''
																				}
																			</>
																		);
																	})}
																</span>
															</div>
														)}
													</>
												)}
												{levelOrMore(level, CompanyLevelEnum.PaidL1) && open && (
													<div className={sc('block')}>
														<span className={sc('label')}>
															{t`Private Label Producer`}:
														</span>
														<span
															className={sc('plValue')}
															style={{
																color: entity.Company.plProducer
																	? theme.colors.success.plain
																	: theme.colors.danger.plain,
															}}
														>
															{' '}
															{t(entity.Company.plProducer ? 'Yes' : 'No')}
														</span>
													</div>
												)}
												{open && (
													<div className={cns(sc('actions'), sc('block'))}>
														<Button
															icon={'arrow_forward'}
															label={t`Go to profile`}
															type="success"
															layout={ButtonLayouts.BUTTON}
															iconPos={IconPositions.RIGHT}
														/>
													</div>
												)}
											</div>
										)}
									</CollapsibleBlockClass>
								</div>

								{!forceOpen && !isCompact && (
									<div className={sc('expand-state-icon')}>
										<Button
											icon={
												open
													? 'expand_less'
													: 'expand_more'
											}
											onClick={(event) => {
												event.preventDefault();
												event.stopPropagation();
												trackClick(open);
												if (open) {
													collapse();
												} else {
													expand();
												}
											}}
										/>
									</div>
								)}
							</article>
						</Link>
					);
				}}
			</Collapsible>
		</WhereBlock>
	);
};
