import gql from 'graphql-tag';
import { GoodCardFragment } from '../components/entities/GoodCard';

export const relatedProductsQuery = gql`
	query relatedProductsLowerQuery($filter: GoodsFilterType!) {
		goodsDir(filter: $filter, first: 12) {
			edges {
				node {
					...GoodCardFragment
				}
			}
		}
	}
	${GoodCardFragment}
`;
