import React from 'react';
import { CompanyDetailBillingInfo } from '../CompanyDetailBillingInfo';
import { ContactSupplierBox } from '../ContactSupplierBox';
import { NextFairsBlock } from '../NextFairsBlock';
import { CompanyDetailLayouts } from './CompanyDetail';
import { SCType } from '@food/css-manager';
import { WhereBlock } from '../../utils/tracking';
import { Company, CompanyLevelEnum } from '../../server-types';
import { Experiment, Variant } from 'react-optimize';

export interface ICompanyDetailAsideProps {
	className?: any;
	company: Company;
	layout: CompanyDetailLayouts;
	l1OrMore: boolean;
	sc: SCType;
	level: CompanyLevelEnum;
}

export const CompanyDetailAside: React.FC<ICompanyDetailAsideProps> = ({
	className,
	company,
	layout,
	l1OrMore,
	sc,
	level,
}) => (
	<aside className={className}>
		<section className={sc('sticky-wrapper')}>
			{layout === CompanyDetailLayouts.FOUR_COL && (
				<WhereBlock step={{ type: 'Block', name: 'companyContactBox' }}>
					<ContactSupplierBox company={company} l1OrMore={l1OrMore} />
					<CompanyDetailBillingInfo sc={sc} company={company} hasL1={l1OrMore} />
				</WhereBlock>
			)}

			{/* ho aggiunto un esperimento con optimize - se l'azienda ha profilo gratuito posso non far vedere il blocco delle prossime fiere */}
			{l1OrMore && (
				<NextFairsBlock participations={company.fairParticipations} level={level} />
			)}
			{!l1OrMore && (
				<Experiment id="TNfU8JVMSvyLk8qyBYA9tw">
					<Variant id="0">
						<NextFairsBlock
							participations={company.fairParticipations}
							level={level}
						/>
					</Variant>
				</Experiment>
			)}
		</section>
	</aside>
);
