import React from 'react';
import { GenericHead, jsonldStringEscape } from './GenericHead';
import { iso8601duration } from '../../utils/dates';
import { Fair } from '../../server-types';

interface IFairDetailProps {
	entity: Fair;
}

export const FairDetailHead: React.FC<IFairDetailProps> = ({ entity }) => {
	const begin = new Date(entity.begin).valueOf();
	const end = new Date(entity.end).valueOf();
	const duration = iso8601duration(end - begin);
	const json: any = {
		'@content': 'http://schema.org',
		'@type': 'FoodEvent',
		name: jsonldStringEscape(entity.name),
		url: entity.website,
		startDate: entity.begin,
		endDate: entity.end,
		duration,
		image: entity.logoMedia ? entity.logoMedia.largeThumbUrl : undefined,
		location: {
			'@type': 'PostalAddress',
			addressLocality: jsonldStringEscape(entity.city + ', ' + entity.Country.name),
			streetAddress: jsonldStringEscape(entity.address1 + ', ' + entity.address2),
		},
	};

	if (entity.postalCode) {
		json.location.postalCode = jsonldStringEscape(entity.postalCode);
	}

	if (entity.description) {
		json.description = jsonldStringEscape(entity.description);
	}

	return (
		<GenericHead
			title={`${entity.name} ${entity.year}`}
			description={entity.description}
			image={entity.logoMedia && entity.logoMedia.largeThumbUrl}
			jsonld={[JSON.stringify(json)]}
		/>
	);
};
