/**
 * @description Mette in relazione i continenti con gli ID degli stati, necessari per fare query su graphql
 */
export const continents = {
	Africa: [
		'iolAG887xu8~cf8',
		'qSHL397gkBY~e7e',
		'T8CwPkxc.BY~f17',
		'LsFnCuNajs4~c55',
		'xrjCt9.h_aw~968',
		'fnRIruetxXw~a0c',
		'WXY8bvot.2o~b0f',
		'DEedh4DMCRU~fe3',
		'3j1pF5RQcHY~ef5',
		'ZxVgVxrT1iw~995',
		'5GROhXQWtbI~cef',
		'zENdr8jTyFw~aa6',
		'wDvfp397ZXw~be2',
		'zf5Mjqah6xQ~d0c',
		'he2jnUY6tN4~ee4',
		'GcZFSFvH8Pg~bbd',
		'R.DC6dKWq_E~edf',
		'JUs46dOwb2A~e5b',
		'hFJBaJK1JFc~bee',
		'N6aNRxuquaA~c1b',
		'rwqhmIb5q6Y~ea0',
		'1H2fZjW5X4E~fd7',
		'5etVyjS84SU~fc9',
		'kNcCc1OtvFY~e6a',
		'H2328kn3jkU~c9f',
		'zkuIUKyFWwY~db8',
		'U5U4N7tb4G0~f3f',
		'oEnbIhsXdkE~c92',
		'sSDJT7d3Pmc~9be',
		'LX0lnejk54k~b07',
		'4CAwbxhJjHU~e87',
		'zN9XO.Z2g0k~afe',
		'7QwoGmytbLM~e13',
		'4eLxkaRlwq4~c45',
		'nLPRDXKR5y4~dfe',
		'qUcQeeRLBD8~fca',
		'Werowu32JJo~bc1',
		'pU_E869w5Bw~b7e',
		'64nOXTwh_RY~f3b',
		'DvfR9m_MLaI~dd9',
		'HzU6bnn0YJI~f8d',
		'K8a0kIDQiUo~aeb',
		'Lj9ZBTR9ng8~cfd',
		'H6qabaPW3so~9c3',
		'uH5CqotVGzY~d8e',
		'kbjs4xdyP0E~f10',
		'KSNvcFHNJiQ~de7',
		'CC6M81xaCK0~f3f',
		'OkuP1krp3FQ~f19',
		'nr6muwKSD60~fc4',
		'1Ru8dm2em3w~a4b',
		'qnUBKjzC8Fc~b9c',
		'H7P8nq7dFMc~b4f',
		'XjUD.yyqH8I~f1d',
		'SrSFYAH_UaQ~dfd',
	],
	Asia: [
		'0NO1KaOXkKI~edf',
		'932eI4YKr60~ef7',
		'Skb_xvmB.y4~d89',
		'RQwzPLcbTtE~d33',
		'Hu59K7w49i0~dbd',
		'S8j8HlPWfDY~edb',
		'dRZYT6noa.g~a3e',
		'YRXQm2_Z6as~9ef',
		'ANHTIuwC8tI~d9f',
		'vfzQfPrbdoc~828',
		'jLUI5Wkt9cU~d3e',
		'd0N9fX9yJZY~f6e',
		'5f76baRJEUA~fcd',
		'Ap72TonfFmY~d1d',
		'9RZbgkZto5E~e47',
		'gu5S8ge1LEM~f9c',
		'xDkc.ggAsX4~e92',
		'BmlAMyoev.c~a19',
		'Sw.qrdfCf50~e85',
		'4M.aLrsNuFY~e97',
		'6_.ImEoXIYM~faf',
		'bAQon4Ge59c~b66',
		'PFgyGrIr99E~f53',
		'urEoovw.FB4~f84',
		'V7YADtwQG4w~b9f',
		'qG8cE27ZfaI~cf6',
		'9wyGTx8eU_E~f59',
		'UKXloKU3elk~8e7',
		'30KY8jwrAfQ~d1f',
		'XKBNU.zVnxo~8bf',
		'AX_TmX_FW6M~fef',
		'xcsdYMnBclc~8b0',
		'010iDnCcIEQ~f57',
	],
	'East Europe': [
		'MdYfhpewGNs~b05',
		'uoR5vxVmMD0~f4c',
		'YrJA5nU.67o~bdd',
		'rFbaIHZ9op0~cf2',
		'snGf8mYtpSg~a54',
		'CnXT952gVVQ~f7d',
		'AFeRD0XQjUc~afb',
		'QPjW.vN2DeQ~ddb',
		'z0SJDdUP3aQ~dde',
		'.HvlhbT67Ps~bc3',
		'mbd4zum6tyw~888',
		'ebYJTBTKXLw~bfc',
		'zuWvjgiCyW0~e84',
		'ytGlpL1v7oo~964',
		'xATpULUclj8~c76',
		'AGqc8k3QaGM~ed3',
		'i0qkDLhJFIA~fb2',
		'_pL4TYg1n4w~abd',
		'b_Tt9Tmwf3Q~e36',
		'tWQ986qmYZo~b3e',
		'EwaPF1wNy70~eb9',
		'KMTx19HIXKs~bf7',
		'7H2kwDPyNQM~f67',
		'IPAyiKtxODk~b27',
	],
	'Middle East': [
		'.hxpbYoUK3o~ba1',
		'ZphwMQvZ0kk~9b1',
		'kBMKuTwJ.tM~dae',
		'dcqilzC_aik~8c0',
		'KUEQI1BnQ5o~b7f',
		'xartSEbE3dM~db0',
		'tviWO.Niqo4~c78',
		'RLarjpyFbuw~883',
		'pd3CTfGp3Y8~f5c',
		'WXhFEJKgGWU~f7b',
		'9hvFRsxqWJY~f19',
		'_T1i80O5L9A~ff7',
		'cQIUUm9b6aA~d5e',
		'B1WThofzJe0~d0f',
		'egtWIDSc5Rc~b78',
		'4tzBQaWMIoM~dd9',
	],
	'North America': [
		'4V2vBOnW3wg~9b7',
		'hZVPHlA.4Jk~bde',
		'mc4TxG_aH4w~b6c',
		'DJdWqtx67YI~f8b',
	],
	'Occidental Europe': [
		'WHnt7s6q0b8~d53',
		'7M.fHGlybOs~a37',
		'57D0ViwVnGg~a9f',
		'yeGit6xZuuM~ca4',
		'Q3._addyKrU~d0f',
		't0hTIC8eTXs~b7a',
		'lt58z8FHT.8~fec',
		'b332YU8lSmE~d7e',
		'nDXZOY09LwI~dfe',
		'lCbQgGmeN8o~b2a',
		'hRU.KWQpRTA~f7e',
		'_r0QzupgfOw~a0d',
		'BpRiTMLM_PA~ff5',
		'o0oGSFJ3Ya4~dfa',
		'XnAht9yuI.A~f25',
		'T4PWo8kvELI~f2f',
		'0Q1NjW3GfJo~aef',
		'vgCtWAJhzac~874',
		'5N1BdroXBh0~d8f',
		'odhr817HJqQ~df0',
		'jVJVW6B068w~bfe',
		'0xtNwYSjmDY~e69',
		'P_vOto8STxw~9cb',
		'gHYeECzUxkw~8b6',
		'WJDVIDurkVM~e3f',
		'82OzldBhRRE~f47',
		'Xe9yKgjvAw8~d15',
		'AfZI1.MSuLw~afd',
		'Ul8fd6uS8G0~fa5',
		'TEj9shhrhPs~a0b',
		'rAAj.14uMH4~f76',
		'pMBtK7cmI3M~f36',
	],
	'Oceania and Pacific': [
		'kM4CN9NoPBg~b7e',
		'M9X2w06lUxE~d6f',
		'ElQcN7x1voM~cb5',
		'UJNH5u9agJE~e5f',
		'KTLb9SjD2ZU~fb7',
		'YOZj9G.pb1E~e77',
		'fisZNThcYYU~f38',
		'4iTpg_Q_35o~be5',
		'AtdDdTq.lng~8a9',
		'.AAt.ErbrIk~a37',
		'MnkDLm.6FGU~fd9',
		'txtfFu8tRTg~b50',
		'ToOzNGyrsCk~a35',
		'J4kSOIn4l_8~ebb',
		'k51xjwclSdw~906',
		'BKjrQujKm7c~a93',
		'vwML.VG9EI0~ffc',
		'4QHyTRWjIU0~f77',
		'DBp7Ayz.mu4~c9b',
		'5__SkKcILZ0~faf',
		'hAOb3hGA9ek~9d6',
	],
	'South and Central America': [
		'xO4Q52avxOw~a3e',
		'fqfZXlP3rQ0~ed8',
		'n2dGDRIBZDs~bfa',
		'SdwhMSksrjI~c31',
		'J2IT6.awfjg~83f',
		'frUnZqec.bA~d14',
		'_KWoXqtjwHk~a17',
		'4.xH0v07t38~edb',
		'wKULWuKHmL8~ede',
		'eoiXAdQ9J.c~bd8',
		'cFpdVSJEhZQ~ef2',
		'7zri4lWtL_U~f51',
		'1khLes0ECvs~9c9',
		'iGrqretLiP8~e82',
		'kqCSH8Umiu4~c7c',
		'yIkzdYBpxi0~c62',
		'LBjF1Jvo8Oc~b3b',
		'SXt69z39Xg4~ddb',
		's4w.gX9RJh4~dea',
		'O8rEgz.jyC0~e4b',
		'9Q2CO.MOJ5g~bff',
		'aw6OLaAftto~85c',
		'yGANbK8Hijo~8ee',
		'tnlUlIq6ijs~8a8',
		'TwvKDU4ucjk~879',
		'td1nXk6ak0c~a54',
		'NgQh7F0I9AA~ff5',
		'mRnzuqmQz0Q~e82',
		'XuWSwILeu_o~a6d',
		'vDpW4Ve_n74~eba',
		'gsbqV1S8650~ff0',
		'WoSnTmMByCY~ed5',
		'144dwO.l_pU~d67',
		'5WXTg_btsZ0~e2f',
		'IGDqyMvMyCU~ea7',
		'6LGQ.nJ10pE~ddf',
		'dd6ckxfGkyM~c84',
		'TGWwxNj6liU~ca7',
		'iSnjgLN5Px8~de2',
		'mAuVA0BTJwY~dfa',
		'Ma1bQSO6yRc~af5',
		'fJWniC909S4~fe6',
		'id.Aosx1gi4~c8c',
		'NMt5xSYxbqk~86b',
		'wPhSJVZtVBg~b7a',
		'3O9CKf25ikk~8df',
		'nwVa4sMMaoY~cd4',
		'TOD3kdjTg2c~a8f',
		'FybVVenUMhI~d99',
		'U8l1dLJs1ZA~f6b',
	],
};
