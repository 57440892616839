import React from 'react';
import { registerClass, currySC, cns } from '@food/css-manager';
import { Icon } from '@food/ui';
import { QueryRemoteDomNodes } from '../QueryRemoteDomNodes';
import { IFNTheme } from '../../utils/theme';
import t from '../../utils/labels';

export enum BorderColors {
	primary = 'primary',
	secondary = 'secondary',
	alt = 'alt',
	alt2 = 'alt2',
	grey = 'grey',
	gradient1 = 'gradient1',
	gradient2 = 'gradient2',
}

export enum Widths {
	FULLWIDTH = 'FULLWIDTH',
	TWO_COL = 'TWO_COL',
	ONE_COL = 'ONE_COL',
}

export enum Layouts {
	singleWidth = 'single-width',
	doubleWidth = 'double-width',
	tripleWidth = 'triple-width',
}

interface IRemoteWordpressWidgetProps {
	url: string;
	selector: string;
	limit?: number;
	className?: string;
	layout?: Layouts;
	width?: Widths;
	title?: string;
	titleLinkTo?: any;
	titleTarget?: any;
	viewAllAfter?: boolean;
	viewAllLabel?: string;
	viewAllLinkTo?: any;
	viewAllTarget?: any;
	isBordered?: boolean;
	borderColor?: BorderColors;
	hasBorderPattern?: boolean;
}

const mercati_categorie_slugs = [
	'beverage',
	'olive-oil-vinegar',
	'tomato',
	'bakery-snacks',
	'pasta',
	'cheese',
	'meats-other-product',
	'coffee-other-product',
	'rice-legumes',
	'sweets-other-product',
	'frozen-food',
	'fruit-vegetables',
	'healthy-altro',
	'petfood-altro',
	'baby-food-altro',
	'seasonals',
	'basic-ingredients',
	'seafood',
];
const mercati_colors = [
	'#5db1e4',
	'#6ca547',
	'#bc1d1d',
	'#c99d66',
	'#dbaf00',
	'#f18c00',
	'#ec6f68',
	'#825e22',
	'#C59D5F',
	'#e099c2',
	'#1990ac',
	'#7cc08c',
	'#008E93',
	'#702382',
	'#93C01F',
	'#e7462f',
	'#8aacdb',
	'#de713d',
];

const styleClass = registerClass((t: IFNTheme, sc) => {
	const articleHeight = 17.2222222222;
	const articleVspace = 1.5;
	const twoColMaxRows = 3;
	const oneColMaxRows = 4;
	return `
	/* Normalization - spostare in injectGlobal? */
	a {
		color: inherit;
		background-color: transparent;
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
		&:active,
		&:hover {
			outline: 0;
		}
	}
	
	font-size: 1.1267605634rem;

	.${sc('widget-title')} {
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 0.5rem;
		word-break: break-word;
	}
	.${sc('upw-posts')} {
		height: ${articleHeight}rem;
		box-sizing: content-box;
		padding: 1rem;
		background-clip: padding-box;
		
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		overflow: hidden;
		
		> article {
			position: relative;
			overflow: hidden;
			display: block;
			margin-bottom: ${articleVspace}rem;
			width: 20.6666666667rem;
			max-width: 100%;
			flex: auto 0 0;

			position: relative;

			> .post-thumbnail {
				height: 90%;
				background-size: cover;
				background-position: center center;
				
				img {
					display: none;
				}
			}
			> header {
				position: absolute;
				bottom:0;
				left: 0;
			}

			> header {
				> .entry-image {
					a, img {
						display: block;
						/*
						* Faccio im modo che la thumb occupi tutto anche se più piccola
						* Poi ci penserà l'srcset a caricare quella giusta
						* */
						width: 100%;
					}
				}

				.entry-cat {
					display: block;
					line-height: 1;
					/* padding: #{$size__list_post_cat-vertical_padding-exs}ex #{$size__list_post_cat-horizontal_padding-ems}em;*/
					/* font-size: $font__list_post_cat; */
					position: absolute;
					left: 0;
					bottom: 100%;
					background: ${t.colors.alt.plain};
					color: ${t.colors.inkWhite};
					text-transform: uppercase;
					white-space: nowrap;
				}
				
				.entry-meta-container {
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;
					align-items: center;
				}
				.entry-meta,
				.entry-meta2 {
					display: inline-block;
					margin: 1ex 0; /* Non troppo perché se va a capo non deve occupare troppo, il resto per la linea singola va in .entry-meta-container */
					
					/* font-size: $font__list_post_meta; */
					font-weight: lighter;
					color: ${t.colors.alt.plain};
					
					a {
						color: ${t.colors.alt.plain};
					}
				}
				.entry-title {
					time {
						text-transform: capitalize;
					}
				}
				
				.entry-meta2 {
					
					> * {
						margin-right: 1em;
					}
					
					.social-shares {
						border: 1px solid;
						padding: 0.5em;
						border-radius: 50%;
					}
					
					.material-icons {
						font-size: 150%;
						vertical-align: middle;
					}
				}
			}

			.post-thumbnail {
				display: block;
				line-height: 0;
				background-size: cover;
				background-position: center, center;
			}

			/* Colori dei mercati */
			${mercati_categorie_slugs
				.map(
					(slug, idx) =>
						`&.category-${slug}  > header .entry-cat {
				background: ${mercati_colors[idx]};
			}`,
				)
				.join('\n')}
		}
	}

	&.${sc('double-width')} {
		/* width: #{$size__double-width-rems}rem; */
	}

	&.${sc('triple-width')} {
		height: auto;
	}

	&.${sc('bordered-section')} {
		border-top: 1rem solid transparent;
		/* background-size: 0.5em 0.5em; creava un difetto su Chrome a certe esatte dimensioni per credo problemi di arrotondamento */
		/* background-repeat: round; creava un difetto su Chrome a certe esatte dimensioni per credo problemi di arrotondamento */
		background-clip: padding-box;
		padding: 1rem; /* #{$size__bordered-section-padding-rems}rem; */
		position: relative; /* necessario per il bordino sfumato sotto fatto con &:aftr */
		border-bottom: 0.75rem solid transparent; /* occupato dal .widget-title - uso il border invece del margin perché il margin collasserebbe con il widget sopra */
		overflow: visible;
		position: relative;

		/*
		* Per far funzionare overflow: hidden a livello di .upw-posts
		* devo dargli una dimensione e quindi devo sostituire anche il padding
		* della section con un border transparent di .upw-posts
		* */
		padding: 0;
		/* In aggiunta, se resta posto, faccio in modo che la bordatura sia bilanciata */
		display: flex;
		justify-content: center;

		.${sc('widget-title')} {
			top: -1rem;
			line-height: 1;
			background: none;
			font-size: 175%; /* $font__secondary_bigwidget-title; */
			position: absolute;
			left: 0;
			z-index: ${
				t.zIndexes.P1_SlightlyAbove
			}; /* Così resta clickabile sopra al bordo ma non va sopra ad altre cose popup come il menu */
		}
		.${sc('upw-posts')} {
			overflow: hidden;
			border: 2rem /* #{$size__bordered-section-padding-rems}rem */ solid transparent;
			background-clip: padding-box;
		}
	
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 100%;
			margin-top: 0.25rem;
			left: 0;
			right: 0;
			height: 0.5rem;
		}
		
		> * {
			background: #fff;
		}

		/*
		 * Default - colore pieno $color__color-level2
		 * */
		background-color: ${t.colors.alt.plain};
		background-image: url('${t.assets.patternTriangle}');
		&:after {
			background: ${t.colors.alt.plain};
		}
		
		${Object.keys(t.colors)
			.map(
				(colorKey) => `&.${sc('borderColor', colorKey)} {
			background-color: ${t.colors[colorKey].plain};
			&:after {
				background: ${t.colors[colorKey].plain};
			}
		}`,
			)
			.join('')}
		
		
		/*
		 * Colori dei mercati in evidenza
		 * */
		/*
		@for $i from 1 through length($mercati_categorie_slugs) {
			&.mercati-#{nth($mercati_names,$i)}-border {
				background-color: nth($mercati_colors,$i);
				&:after {
					background: nth($mercati_colors,$i);
				}
			}
		}
		*/
	}

	/* Widget as a Banner - CHECK + TODO */
	&.${sc('widget_text')} {
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			max-width: 100%;
			max-height: 100%;
			/* Evito che se denro un <a> prenda spazio per il line-height */
			display: block;
		}
	}

	&.${sc('view-all-after')} {
		> .${sc('upw-after')} {
			position: absolute;
			bottom: 1rem;
			right: 1rem;
			padding: 0.5rem 1rem;

			/*
			* Occupando 1big + 2 colonne metto il limite sinistro
			* al centro del post piccolo a sinistra
			* */
			width: 35.5%;

			* {
				margin: 0;
				padding: 0;
			}

			.${sc('view-all')} {
				text-transform: uppercase;
				/* Allinea scritta e after */
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}
		&.${sc('width', 'TWO_COL')} {
			> .${sc('upw-after')} {
				/*
				* Occupando 2 colonne metto il limite sinistro
				* al centro del post di sinistra
				* */
				width: 70%;
			}
		}
		&.${sc('width', 'ONE_COL')} {
			> .${sc('upw-after')} {
				/*
				* Occupando 1 colonne lo metto largo quanto la colonna
				* ma con scalino ch scende a destra
				* */
				width: 80.5%;
			}
		}
	}

	&.launch-widget {
		.${sc('upw-posts')} {
			padding-left: 1rem;
			
			> article.type-launch {
				border: 1px solid #ccc;
				padding: 1rem;
				display: flex;
				flex-flow: column;
				justify-content: space-between;

				width: 18.6rem;
				height: 18.6rem;
				font-size: 90%;
				margin-bottom: 1rem;
				
				header {
					position: relative;
					margin-top: 0;
					padding: 0;
					flex-grow: 0;
					background-color: inherit;
					
					h2.entry-title {
						text-transform: uppercase;
						font-weight: 700;
						font-size: 1.2em;
					}
					
					h3.brand-name {
						text-transform: uppercase;
						font-weight: 400;
						font-size: 1em;
					}
				}
				
				a.post-thumbnail {
					background-size: contain;
					background-repeat: no-repeat;
					flex: 100% 1 1;
					margin: 0.5rem 0 0;
				}

				/*
				* Solitamente mostro solo i 3 più recenti per questioni di layout (riempio una sola riga)
				* mentre quando ce ne stanno 2 in una riga (visualizzazione più stretta) ne mostro 4 su due righe
				* */
				display: none;
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3) {
					display: flex;
				}
			}
		}
		// @media only screen and (max-width: #{($size__screen-desktop-max)}) {
		&.${sc('width', 'ONE_COL')},
		&.${sc('width', 'TWO_COL')} {
			> article.type-launch {
				&:nth-child(4) {
					display: flex;
				}

				/* In aggiunta riduco appena perché altrimenti non ce ne stanno 2 per riga */
				width: #{($size__single-width-rems *0.87)}rem;
				height: #{($size__single-width-rems *0.87)}rem;
				font-size: 87%;
			}
		}
	}

	&.news-widget {
		.${sc('upw-posts')} {
			/*
			* Imposto solo per i type "post" perché gli altri possono avere un template diverso
			* */
			article.type-post {
				height: ${articleHeight}rem;

				> .post-thumbnail {
					display: block;
					margin: 0;
					z-index: -1; // FIXME
					
					img {
						display: block;
						width: 100%;
						vertical-align: middle; /* evita il problema del line-height che lascia spazio sotto o sopra */
					}
				}
				
				> header {
					display: flex;
					flex-flow: column nowrap;
					justify-content: space-around;
					flex: 1px 1 0; /* IE fix */
					background: #fff;
					max-width: 87.8048780488%;
					margin-top: -5.5ex; /* CHECK */
					padding: 0.5em; /* CHECK */
					
					position: relative;
					.entry-cat {
						padding: 0.75ex 1.5em;
						font-size: 85%;
						color: #ffffff;
					}
					
					.entry-title {
						font-size: 125%;
					}
					
					.entry-image {
						/*
						* IE11-FIX
						* */
						flex: auto 0 0;
					}
				}
				
				> .post-thumbnail {
					/* 1/3 (width - gap) */
					flex: 32% 0 0;
					margin-right: 4%;
					
					img {
						display: block;
						width: 100%;
						vertical-align: middle; /* evita il problema del line-height che lascia spazio sotto o sopra */
					}
				}
			}
		}
		// @media only screen and (max-width: $size__screen-tablet-max) {
		&.${sc('width', 'ONE_COL')},
		&.${sc('width', 'TWO_COL')} {
			.${sc('upw-posts')} {
				height: auto;
				max-height: ${articleHeight * twoColMaxRows + articleVspace * (twoColMaxRows - 1)}rem;
			}
			article.type-post {
				> .post-thumbnail {
					/* L'immagine può diventare molto stretta e quindi se mantenessi le proporzioni più bassa dell'header alla sua dx */
					background-size: cover;
					background-position: center;
					
					img {
						/* se usassi display:none salterebbero le proporzioni anche quando non l'header a dx è più basso */
						visibility: hidden;
					}
				}
			}
		}
		&.${sc('width', 'ONE_COL')} {
			.${sc('upw-posts')} {
				max-height: ${articleHeight * oneColMaxRows + articleVspace * (oneColMaxRows - 1)}rem;
				justify-content: center;
			}
		}
	}
`;
});

const sc = currySC(styleClass);

export const RemoteWordpressWidget: React.FC<IRemoteWordpressWidgetProps> = ({
	url,
	selector,
	limit = 6,
	className,
	layout = Layouts.tripleWidth,
	width = Widths.FULLWIDTH,
	title,
	titleLinkTo,
	titleTarget,
	viewAllAfter,
	viewAllLabel = t('RemoteWordpressWidget/ViewAllLabel'),
	viewAllLinkTo,
	viewAllTarget,
	isBordered,
	borderColor = BorderColors.primary,
	hasBorderPattern,
}) => (
	<section
		className={cns(
			className,
			styleClass,
			sc(layout),
			sc('width', width),
			viewAllAfter ? sc('view-all-after') : null,
			isBordered ? sc('bordered-section') : null,
			sc('borderColor', borderColor),
			hasBorderPattern ? sc('patterned-border') : null,
		)}
	>
		{title && (
			<h2 className={sc('widget-title')}>
				{titleLinkTo ? (
					<a href={titleLinkTo} target={titleTarget} rel="nofollow">
						{title}
					</a>
				) : (
					title
				)}
			</h2>
		)}
		<QueryRemoteDomNodes url={url} selector={selector}>
			{({ loading, error, nodes }) => {
				if (loading) {
					return (
						<div className={sc('upw-posts')}>
							<div>Loading...</div>
						</div>
					);
				}

				if (error) {
					console.error(error);
					return (
						<div className={sc('upw-posts')}>
							<div>Error!</div>
						</div>
					);
				}

				if (!nodes) {
					return null;
				}

				const res: string[] = [];
				for (let i = 0; i < nodes.length && i < limit; i++) {
					res.push(nodes.item(i).outerHTML);
				}
				return (
					<div
						className={sc('upw-posts')}
						dangerouslySetInnerHTML={{ __html: res.join('') }}
					/>
				);
			}}
		</QueryRemoteDomNodes>

		{viewAllAfter && (
			<div className={sc('upw-after')}>
				<p>
					{viewAllLinkTo ? (
						<a
							className={sc('view-all')}
							href={viewAllLinkTo}
							target={viewAllTarget}
						>
							{viewAllLabel}
							<Icon name={'chevron_right'} />
						</a>
					) : (
						<>
							{viewAllLabel}
							<Icon name={'chevron_right'} />
						</>
					)}
				</p>
			</div>
		)}
	</section>
);
