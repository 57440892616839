import React from 'react';
import { ZenObservable } from 'zen-observable-ts';
import Observable from 'zen-observable';
import { AsyncOperationCache } from '../utils/AsyncOperationCache';
import { canUseDOM } from 'exenv';
import 'isomorphic-fetch';

// Definisco fetchHtmlCall
export interface IHtmlResponse {
	status?: number;
	success?: boolean;
	error?: any;
	message?: string;
	document?: Document;
}

async function fetchHtmlCall(url: string): Promise<IHtmlResponse> {
	return new Promise((resolve) => {
		if (canUseDOM) {
			// sono sul client, faccio una chiamata a wordpress
			const parser = new DOMParser();
			fetch(url, {
				method: 'GET',
			})
				.then(async (response) => {
					const html = await response.text();
					resolve({
						status: response.status,
						success: true,
						document: parser.parseFromString(html, 'text/html'),
					});
				})
				.catch((err) => {
					resolve({
						status: err.status || 500,
						success: false,
						error: err,
						message: err.message,
					});
				});
		} else {
			// sono sul server, restituisco testo vuoto
			resolve({
				status: 200,
				success: true,
				document: undefined,
			});
		}
	});
}

// Creo CACHE
export interface DomNodesInterface {
	loading: boolean;
	nodes?: NodeListOf<HTMLElement>;
	error?: Error;
}
const KEY_SEPARATOR = ' ';
const DEFAULT_CACHE = new AsyncOperationCache<Props, DomNodesInterface>(
	(p) => {
		return [p.url, p.selector].join(KEY_SEPARATOR);
	},
	async (p) => {
		const res = await fetchHtmlCall(p.url);
		if (!res.success) {
			return {
				loading: false,
				error: res.error,
			};
		}
		const nodes: NodeListOf<any> =
			res && res.document
				? res.document.body.querySelectorAll(p.selector)
				: new NodeList();
		for (let i = 0; i < nodes.length; i++) {
			const anodes = nodes
				.item(i)
				.querySelectorAll('a[href]:not([target])');
			for (let j = 0; j < anodes.length; j++) {
				anodes.item(j).setAttribute('target', '_blank');
			}
		}
		return {
			nodes,
			loading: false,
		};
	},
	10,
);

export interface Props {
	children: (args: DomNodesInterface) => React.ReactNode | Element;
	url: string;
	selector: string;
	skip?: boolean;
	cacheInstance?: AsyncOperationCache<Props, DomNodesInterface>;
}

const DefaultValue: DomNodesInterface = { loading: true };

export class QueryRemoteDomNodes extends React.Component<Props> {
	private cache: AsyncOperationCache<Props, DomNodesInterface> = DEFAULT_CACHE;
	private hasMounted: boolean = false;
	// request / action storage. Note that we delete querySubscription if we
	// unsubscribe but never delete queryObservable once it is created. We
	// only delete queryObservable when we unmount the component.
	private queryObservable?: Observable<DomNodesInterface>;
	private querySubscription?: ZenObservable.Subscription;
	private lastValue: DomNodesInterface = DefaultValue;

	componentDidMount() {
		this.hasMounted = true;
		if (this.props.skip) {
			return;
		}
		this.startQuerySubscription();
	}

	componentWillReceiveProps(nextProps: Props) {
		// the next render wants to skip
		if (nextProps.skip && !this.props.skip) {
			this.removeQuerySubscription();
			return;
		}

		if (
			this.props.url === nextProps.url &&
			this.props.selector === nextProps.selector
		) {
			// La HeaderQuery non è cambiata quindi non devo fare nulla
			return;
		}

		if (nextProps.skip) {
			return;
		}
		this.removeQuerySubscription();
		this.updateQuery(nextProps);
		this.startQuerySubscription();
	}

	componentWillUnmount() {
		this.removeQuerySubscription();
		this.hasMounted = false;
	}

	render() {
		const { children } = this.props;
		return children(this.lastValue!);
	}

	private initializeQueryObservable(props: Props) {
		if (this.queryObservable) {
			return;
		}

		this.lastValue = DefaultValue;
		this.queryObservable = new Observable<DomNodesInterface>((observer) => {
			this.cache.getResult(props).then(
				(res) => {
					observer.next(res);
					observer.complete();
				},
				(err) => {
					observer.error(err);
				},
			);
		});
	}

	private updateQuery(props: Props) {
		delete this.queryObservable;
		this.initializeQueryObservable(props);
	}

	private startQuerySubscription = () => {
		if (this.querySubscription) {
			return;
		}
		this.initializeQueryObservable(this.props);
		this.querySubscription =
			this.queryObservable &&
			this.queryObservable.subscribe((newVal) => {
				this.lastValue = newVal;
				// force a rerender that goes through shouldComponentUpdate
				if (this.hasMounted) {
					this.forceUpdate();
				}
			});
	};

	private removeQuerySubscription = () => {
		if (this.querySubscription) {
			this.querySubscription.unsubscribe();
			delete this.querySubscription;
		}
	};
}
