import React, { useRef, useState } from 'react';
import { Button, Icon, Select } from '@food/ui';
import { cns, currySC, registerClass } from '@food/css-manager';
import t from '../utils/labels';
import { SearchTypes } from '../utils/frequentSearch';
import { ACTIONS, generateUrl } from '../utils/urls';
import { ENTITY } from '../utils/entities';
import { pluralEntityLabel } from '../utils/misc';
import { FrequentSearchResult } from './FrequentSearchResult';
import { IFNTheme, theme } from '../utils/theme';
import { useDebounce, useLocation } from '../utils/hooks';
import { addWhere, useTracking, WhereBlock } from '../utils/tracking';
import { TWhere } from '../tracking-types';

const delay = 600; // ms
const searchTypes = [
	{ label: t(pluralEntityLabel(ENTITY.GOOD)), value: SearchTypes.GOOD },
	{ label: t(pluralEntityLabel(ENTITY.COMPANY)), value: SearchTypes.COMPANY },
];

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	display: flex;
	justify-content: center;
	justify-items: stretch;
	font-size: ${t.ratios.l}em;
	
	&.${sc('layout', 'COMPACT')} {
		padding-bottom: ${t.ratios.xl ** 2}rem !important; // TODO ALERT
	
		.${sc('search')} {
			margin-left: ${t.ratios.xl}rem;
			margin-right: ${t.ratios.xl}rem;
		}
	}
	
	&.${sc('layout', 'MINI')} {
		padding-bottom: ${t.ratios.xl ** 2}rem !important; // TODO ALERT
	}

	.${sc('search')} {
		position: relative;
		flex: 1 1 auto;
		display: flex;
		border-radius: ${t.radius}rem;
		border: 1px solid ${t.colors.alt.plain};

		> div:first-child,
		> button {
			flex: 0 0 auto;
		}
		> div:first-child,
		> div:first-child *,
		> button {
			border-radius: 0 !important;
			border: none !important;
		}

		input {
			flex: 1 1 auto;
			width: 1em; // IMPORTANT - altrimenti non si stringe più di tanto
			border-radius: 0;
			font-size: ${t.ratios.l}rem;
			border: none;
			padding: 0 ${t.ratios.l}rem;
		}

		.${sc('catsearch')} {
			position: absolute;
			right: 0;
			top: 100%;
			display: inline-block;
			margin-top: ${t.ratios.xl / 2}rem;
			cursor: pointer;
			color: ${theme.colors.grey.plain};
			
			&:hover {
				text-decoration: underline;
			}
		}
	}
	
	.${sc('thin-btn')} {
		font-weight: normal;
	}
`,
);

const dropdownClass = registerClass(
	(t: IFNTheme, sc) => `
	font-size: ${t.ratios.l}em;
	
	.${sc('voice')} {
		display: flex;
		align-items: center;
		
		span:first-child {
			margin-right: .3em;
		}
	}
`,
);
const sc = currySC(styleClass);
const dsc = currySC(dropdownClass);

const compactSearchTypes = [
	{
		label: (isMain) => (
			<span className={dsc('voice')}>
				<Icon name={'local_dining'} />{' '}
				{!isMain && ' ' + t(pluralEntityLabel(ENTITY.GOOD))}
			</span>
		),
		value: SearchTypes.GOOD,
	},
	{
		label: (isMain) => (
			<span className={dsc('voice')}>
				<Icon name={'business'} />{' '}
				{!isMain && ' ' + t(pluralEntityLabel(ENTITY.COMPANY))}
			</span>
		),
		value: SearchTypes.COMPANY,
	},
];

export enum MainSearchLayout {
	STANDARD = 'STANDARD',
	COMPACT = 'COMPACT',
	MINI = 'MINI',
}

interface MainSearchProps {
	layout?: MainSearchLayout;
	className?: string;
	searchType: SearchTypes;
	onSearchTypeChange: (t: SearchTypes) => void;
	openCategorySearch: () => void;
}

const prepareSearchText = (searchType: SearchTypes, searchText: string | null) => {
	const link =
		searchType === SearchTypes.GOOD
			? generateUrl(ENTITY.GOOD, ACTIONS.LIST)
			: generateUrl(ENTITY.COMPANY, ACTIONS.LIST);
	return (
		link + (searchText ? `?textSearch=${encodeURIComponent(searchText.trim())}` : '')
	);
};

export const MainSearch: React.FC<MainSearchProps> = ({
	layout = MainSearchLayout.STANDARD,
	searchType,
	onSearchTypeChange,
	openCategorySearch,
	className,
}) => {
	const [searchText, setSearchText] = useState('');
	const [inputFocused, setInputFocused] = useState(false);
	const debouncedText = useDebounce(searchText, delay);
	const textInput = useRef();
	const track = useTracking();
	const hasSearch = !!debouncedText && inputFocused;
	const { navigate } = useLocation();
	const where: TWhere = { type: 'Block', name: 'MainSearch' };

	return (
		<WhereBlock step={where}>
			<div className={cns(className, styleClass, sc('layout', layout))}>
				<div className={sc('search')}>
					<Select
						type={'alt'}
						selected={searchType}
						options={
							layout === MainSearchLayout.COMPACT
								? compactSearchTypes
								: searchTypes
						}
						onChange={(t) => {
							track(
								{ type: 'switch_target_directory', to: t as SearchTypes },
								addWhere(where),
							);
							onSearchTypeChange(t as SearchTypes);
						}}
						hideSelected={layout !== MainSearchLayout.COMPACT}
						dropdownClassName={dropdownClass}
					/>
					<input
						type="text"
						placeholder=""
						value={searchText}
						onChange={(event) => setSearchText(event.target.value)}
						ref={textInput}
						onFocus={() => {
							setInputFocused(true);
						}}
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								track(
									{
										type: 'do_search',
										filterStatus: { textSearch: searchText },
										target:
											searchType === SearchTypes.COMPANY
												? 'CompanyDirectory'
												: 'GoodDirectory',
									},
									addWhere(where),
								);
								navigate(prepareSearchText(searchType, searchText));
							}
						}}
					/>

					<Button
						type={'alt'}
						className={sc('thin-btn')}
						label={layout !== MainSearchLayout.COMPACT && t(`Search`)}
						icon={'search'}
						onClick={() => {
							track(
								{
									type: 'do_search',
									filterStatus: { textSearch: searchText },
									target:
										searchType === SearchTypes.COMPANY
											? 'CompanyDirectory'
											: 'GoodDirectory',
								},
								addWhere(where),
							);
							navigate(prepareSearchText(searchType, searchText));
						}}
					/>
					{openCategorySearch && (
						<div className={sc('catsearch')} onClick={openCategorySearch}>
							{t`Search by Category`}
						</div>
					)}
				</div>
				<FrequentSearchResult
					open={hasSearch}
					element={textInput}
					textSearch={debouncedText}
					searchType={searchType}
					onClickOutside={(event) => {
						// @ts-ignore
						if (textInput.current && !textInput.current.contains(event.target)) {
							setInputFocused(false);
						}
					}}
					close={() => setInputFocused(false)}
				/>
			</div>
		</WhereBlock>
	);
};
