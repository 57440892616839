import * as Sentry from '@sentry/browser';
import { canUseDOM } from 'exenv';
import { auth, RedirectHandler } from '@food/auth';
import CONFIG from '../static/config';
import logo from '../static/assets/logo.svg';
import { client } from './client';
import { reset } from '../components/UserLists';
import { Channel } from '@food/sync';
import { stacked } from './broadcastChannel';
import { UserRole } from '../server-types';

export const authConfig = {
	AUTH_ROLES: [UserRole.Buyer, UserRole.Consumer],
	TARGET_SITE: CONFIG.TARGET_SITE,
	AUTH_ENDPOINT: CONFIG.API_ENDPOINT.AUTH,
	LOGO: logo,
};

const onRedirect: RedirectHandler = (user) => {
	if (canUseDOM && user && window.location.pathname === '/login') {
		window.location.href = '/';
		return;
	}
};

const userChangedHandler = async (oldUser: any, newUser: any): Promise<void> => {
	if (!canUseDOM) {
		return;
	}

	Sentry.configureScope((scope) => {
		if (newUser) {
			scope.setUser({
				id: newUser.id,
				username: newUser.name,
				email: newUser.email,
			});
		} else {
			scope.setUser(null);
		}
	});

	// resetto le query in localStorage
	reset();

	await client.cache.reset();
	await client.resetStore();
	
	onRedirect(newUser, '/');
};

const authManager = auth(authConfig);
const authChannel = new Channel('@food/auth');

authChannel.onmessage = stacked((events: any[]) => {
	const lastEvent = events.filter((e) => e.data.type === 'USER_CHANGED')[0];
	if (lastEvent) {
		const { oldUser, newUser } = lastEvent.data.args;
		userChangedHandler(oldUser, newUser);
	}
}, 100);

export { authManager, onRedirect };
