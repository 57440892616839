import React from 'react';
import { ENTITY } from '../../utils/entities';
import t from '../../utils/labels';
import { HeadQueryFetcher } from './HeadQueryFetcher';
import { GenericHead } from './GenericHead';

interface IDirectoryHeadProps {
	filters: any;
	type: ENTITY.COMPANY | ENTITY.GOOD;
	filtersOnCanonical?: boolean;
}

export const DirectoryHead: React.FC<IDirectoryHeadProps> = ({
	type,
	filters,
	filtersOnCanonical,
}) => {
	const oneFilter = Object.keys(filters).length === 1;

	const isEditorialSectionSearch = oneFilter && filters.goodClasses;
	const esId = isEditorialSectionSearch && filters.goodClasses[0];

	const isFrequentSearch = oneFilter && filters.FrequentSearch;
	const fsId = isFrequentSearch && filters.FrequentSearch.id;

	const isFairs = oneFilter && filters.fairs;
	const fairId = isFairs && filters.fairs[0];

	const id = esId || fsId || fairId;
	const name = type === ENTITY.GOOD ? 'Products' : 'Suppliers';
	let title = t('Find your ' + name);
	let description = t('Look for authentic Italian ' + name);
	let keywords = '';
	let img = null;

	return (
		<HeadQueryFetcher id={id}>
			{(data) => {
				if (data) {
					const { node } = data;

					if (node) {
						if (isFrequentSearch) {
							title = `${node.name} ${name}`;
							keywords = node.akas ? node.akas.replace(/ +/g, ',') : '';
							img = node.iconMedia && node.iconMedia.largeThumbUrl;
						}
						if (isEditorialSectionSearch) {
							title = `${node.name} ${name}`;
							keywords =
								node.nameIta +
								',' +
								(node.akas ? node.akas.replace(/ +/g, ',') : '');
							const hasEntityAttached =
								(node.fairs && node.fairs[0]) || (node.awards && node.awards[0]);
							img =
								hasEntityAttached &&
								hasEntityAttached.logoMedia &&
								hasEntityAttached.logoMedia.largeThumbUrl;
						} else if (isFairs) {
							title = `${node.name} ${node.year} ${name}`;
							description = node.description;
							img = node.logoMedia && node.logoMedia.largeThumbUrl;
						}
					}
				}
				return (
					<GenericHead
						title={title}
						description={description}
						keywords={keywords}
						image={img}
						filtersOnCanonical={filtersOnCanonical}
					/>
				);
			}}
		</HeadQueryFetcher>
	);
};
