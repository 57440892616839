import React, { useContext } from 'react';
import { Fair, FrequentSearch } from '../server-types';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { sentryHandler } from '../utils/errors';
import { nodes } from '../utils/misc';
import {
	FairRelatedFrequentSearchCardFragment,
	FrequentSearchCard,
} from './entities/FrequentSearchCard';
import t from '../utils/labels';
import { registerClass, currySC, cns } from '@food/css-manager';
import { IFNTheme } from '../utils/theme';
import { loadable, LoadableDirection } from '../utils/loadable';
import { renderContext } from '../utils/context/renderContext';
import { ApolloError } from 'apollo-client';

interface FairRelatedFrequentSearchesProps {
	fair: Fair;
}

const fairRelatedFSQuery = gql`
	query fairRelatedFSQuery($id: ID!) {
		frequentSearchs(filter: { relatedFair: { id: $id } }) {
			edges {
				node {
					...FrequentSearchCard
				}
			}
		}
	}
	${FairRelatedFrequentSearchCardFragment}
`;

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	margin: ${t.ratios.xl}rem 0;

	.${sc('cardHeader')} {
		margin: ${t.ratios.xl * 1.5}rem 0 ${t.ratios.xl}rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		
		> .${sc('title')} {
			font-size: ${t.ratios.xl}em;
		}
	}

	.${sc('cardContainer')} {
		display: flex;
		flex-wrap: wrap;
		margin: -${t.ratios.xl / 2}rem;

		> * {
			margin: ${t.ratios.xl / 2}rem;
			flex: 1 1 15rem;
		}
		> .${sc('cardFiller')} {
			height: 0;
			margin-top: 0;
			margin-bottom: 0;
		}
	}
`,
);
const sc = currySC(styleClass);

const shadowChildrenClass = registerClass(
	(t: IFNTheme) => `
	> * {
		box-shadow: ${t.boxShadow(1)};
	}
`,
);

export const FairRelatedFrequentSearches: React.FC<FairRelatedFrequentSearchesProps> = ({
	fair,
}) => {
	const { isBot } = useContext(renderContext);

	return (
		<Query query={fairRelatedFSQuery} variables={{ id: fair.id }}>
			{({
				error,
				loading,
				data,
			}: {
				loading: boolean;
				error?: ApolloError;
				data: any;
			}) => {
				if (loading) return null;
				if (error) {
					sentryHandler([error]);
					return null;
				}
				const fs = nodes(data.frequentSearchs);
				return fs && fs.length > 0 ? (
					<div
						className={cns(styleClass, loadable(isBot, LoadableDirection.BOTTOM))}
					>
						<div className={sc('cardHeader')}>
							<div className={sc('title')}>{t`Suggested Trends`}</div>
						</div>
						<div className={sc('cardContainer')}>
							{fs.map((f: FrequentSearch) => (
								<FrequentSearchCard
									entity={f}
									key={f.id}
									className={shadowChildrenClass}
								/>
							))}
							<div className={sc('cardFiller')} />
							<div className={sc('cardFiller')} />
							<div className={sc('cardFiller')} />
						</div>
					</div>
				) : null;
			}}
		</Query>
	);
};
