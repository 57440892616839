import { History } from 'history';
import { isLogged, openRenewModal } from '@food/auth';
import { loginMessageFactory } from '../components/LoginMessage';
import { parse, stringify } from 'querystring';
import { ENTITY } from './entities';
import { ACTIONS, generateUrl } from './urls';
import { /*DefaultSetter,*/ nameLabel } from './misc';
// import { FilterTags } from '../components/UI/FilterTags';
import t from './labels';
// import React from 'react';
import {
	EditorialSectionFilter,
	EditorialSectionFilterQuery,
} from '../components/filters/EditorialSectionFilter';
import { TTrack } from './tracking';
import { EditorialSection } from '../server-types';
import { IDirectoryFilterDefinition } from '../components/Directory';

const LoginMessage = loginMessageFactory(
	'Not enough permission for this page - not logged - modal',
);

const pageChanger = (history: History, track: TTrack) => (page: number) => {
	const { search, pathname } = history.location;
	const parsedSearch = parse(search.replace('?', ''));

	if (!isLogged() && page !== 1) {
		track({ type: 'requested_authentication_notlogged', reason: 'change_page' });
		openRenewModal({ customComponent: LoginMessage });
	} else {
		track({
			type: 'filter_update',
			key: 'page',
			oldValue: parsedSearch.page as string,
			newValue: page,
		});
		history.push({
			pathname,
			search: stringify({ ...parse(search.substr(1)), page }),
		});
	}
};

const calculateMaxPage = (total: number, perPage: number): number =>
	Math.ceil(total / perPage);

const generateDirectoryURL = (type: ENTITY.GOOD | ENTITY.COMPANY, args: any = {}) =>
	generateUrl(type, ACTIONS.LIST) + '?' + stringify(args);

const resolveGoodClassesFromTree = (
	ids: ReadonlyArray<string>,
	ess: ReadonlyArray<EditorialSection>,
): string[] => {
	let toDoEs = [...ids];
	let doneEs = [];
	let results = [];
	const hasMatch = (id: string): boolean =>
		toDoEs.includes(id) || toDoEs.includes(id.substr(0, id.length - 4));
	while (true) {
		ess.forEach((es) => {
			if (hasMatch(es.id)) {
				results = results.concat(es.goodClasses.map((gc) => gc.id));
			}
		});
		doneEs = doneEs.concat(toDoEs);
		toDoEs = ess.filter((es) => es.Parent && hasMatch(es.Parent.id)).map((es) => es.id);

		if (toDoEs.length === 0) {
			return results;
		}
	}
};

const goodClassesDirectoryFilter: IDirectoryFilterDefinition = {
	key: 'goodClasses',
	title: t`Category`,
	query: EditorialSectionFilterQuery,
	component: EditorialSectionFilter,
	toLabel: nameLabel,
	// prende i dati dalla HeaderQuery graphql grezza
	extractor: (data: any) =>
		data.editorialSectionTree
			.filter((es) => !es.Parent)
			.map((es) => {
				const newEs = { ...es };
				newEs.goodClasses = resolveGoodClassesFromTree(
					[es.id],
					data.editorialSectionTree,
				);
				return newEs;
			}),
	// trasforma il valore del filtro in un valore adatto alla HeaderQuery graphql
	serializer: (s: string[], data: any) => {
		return resolveGoodClassesFromTree(s, data.editorialSectionTree).map((id) => ({
			id,
		}));
	},
	fromUrl: (v: string) => v.split(','),
	toUrl: (v: string[]) => v.map(encodeURIComponent).join(','),
	facetExtractor: (data: any) => (possibleValue: any) => {
		if (!data || !data.entities || !data.entities.facet) {
			return 0;
		}

		const lens = 'goodClasses' in data.entities.facet ? 'goodClasses' : 'goodClass';

		const gcs = data.entities.facet[lens].reduce((result, facet) => {
			result[facet.value] = parseInt(facet.count, 10);
			return result;
		}, {});

		return possibleValue.goodClasses.reduce((tot, id) => tot + (gcs[id] || 0), 0);
	},
};

export {
	pageChanger,
	LoginMessage,
	calculateMaxPage,
	generateDirectoryURL,
	goodClassesDirectoryFilter,
};
