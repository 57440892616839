import React, { useContext } from 'react';
import { cns, currySC, registerClass } from '@food/css-manager';
import { /*Icon, MATERIAL,*/ Slider, SliderLayouts } from '@food/ui';
import {
	headerStyles,
	Layouts as SidedBoxLayouts,
	SidedBox,
} from '../../components/UI/SidedBox';
import { BrandViews, GoodCard, GoodCardLayout } from './GoodCard';
import { MediaImg } from './MediaImg';
// import t from '../../utils/labels';
import { IFNTheme, theme } from '../../utils/theme';
import { CompanyDetailLayouts } from './CompanyDetail';
import { /*Brand,*/ MediaType } from '../../server-types';
import { WhereBlock } from '../../utils/tracking';
import { loadable, LoadableDirection, loadDelayStyle } from '../../utils/loadable';
import { renderContext } from '../../utils/context/renderContext';

export interface IBrandGroupsProps {
	groups: any;
	layout: CompanyDetailLayouts;
	entity: any;
}

const styleClass = registerClass(
	(t: IFNTheme) => `
		box-shadow: ${t.boxShadow(1)};
		margin-bottom: ${t.ratios.l}rem;
		
		&:last-child {
			margin-bottom: 0;
		}
		
		.${sc('listCard')} {
			width: ${t.columnSize /2 }px;
		}
		
		.${sc('social-icons')} {
			color: white;
			font-size: ${t.ratios.xl}em;
			margin-top: ${t.ratios.l}rem;
			display: flex;
			align-items: center;
			justify-content: center;
			
			> a {
				margin-right: ${t.ratios.l}rem;
			}
			
			> a:last-child {
				margin-right: 0;
			}
			
			.${sc('home-icon')} {
				font-size: ${t.ratios.l}em;
			}
		}
	
	/*.${sc('slider')} {
		display: flex;
		align-items: center;
	}*/
  `,
);

const sc = currySC(styleClass);
const hsc = currySC(headerStyles.similRelatedCard);

export const BrandGroups: React.FC<IBrandGroupsProps> = ({ groups, layout }) => {
	const { isBot } = useContext(renderContext);
	const sidedBoxLayout =
		layout === CompanyDetailLayouts.ONE_COL
			? SidedBoxLayouts.ONE_COL
			: layout === CompanyDetailLayouts.TWO_COL
			? SidedBoxLayouts.TWO_COL
			: SidedBoxLayouts.FULLWIDTH;

	const sidedBoxSliderLayout =
		layout === CompanyDetailLayouts.FOUR_COL
			? SliderLayouts.SINGLE_ROW
			: SliderLayouts.SINGLE_ELEMENT;

	// const hasSocial = (b: Brand) =>
	// 	b.socialFacebookPage || b.socialInstagramPage || b.website;

	return groups.map((group) => (
		<WhereBlock
			step={{ type: 'Block', name: 'CompanyDetailBrandsList' }}
			key={group.brand.id}
		>
			<SidedBox
				layout={sidedBoxLayout}
				style={loadDelayStyle(.5)}
				className={cns(
					headerStyles.similRelatedCard,
					styleClass,
					loadable(isBot, LoadableDirection.BOTTOM),
				)}
				headerColor={theme.colors.alt.plain}
				header1={() => (
					<div className={headerStyles.imageIcon}>
						<div className={hsc('img-container')}>
							<MediaImg
								className={hsc('img')}
								type={MediaType.Logo}
								entity={group.brand.logoMedia || group.brand.Company.logoMedia}
								naturalWidth={true}
								alt={`Brand ${group.brand.name} logo`}
							/>
						</div>
					</div>
				)}
				header2={() => (
					<div>
						{/*<h3 className={hsc('title')}>{group.brand.name}</h3>
						<span className={hsc('label')}>{t`Price positioning`}:</span>{' '}
						<span className={hsc('value')}>{group.brand.pricePositioning}</span>
						{hasSocial(group.brand) && (
							<div className={sc('social-icons')}>
								{group.brand.website && (
									<a
										rel={'noopener nofollow noreferrer'}
										href={group.brand.website}
										target={'_blank'}
									>
										<Icon
											name={MATERIAL.icon_public}
											className={sc('home-icon')}
										/>
									</a>
								)}
								{group.brand.socialFacebookPage && (
									<a
										rel={'noopener nofollow noreferrer'}
										href={group.brand.socialFacebookPage}
										target={'_blank'}
									>
										<Icon name={MATERIAL.icon_facebook} />
									</a>
								)}
								{group.brand.socialInstagramPage && (
									<a
										rel={'noopener nofollow noreferrer'}
										href={group.brand.socialInstagramPage}
										target={'_blank'}
									>
										<Icon name={MATERIAL.icon_instagram} />
									</a>
								)}
							</div>
						)}*/}
					</div>
				)}
			>
				<Slider
					layout={sidedBoxSliderLayout}
					carusel={sidedBoxSliderLayout === SliderLayouts.SINGLE_ELEMENT}
					className={sc('slider')}
				>
					{group.hgoods.map((hgood) => (
						<GoodCard
							className={sc('listCard')}
							key={hgood.id}
							entity={hgood.Good}
							layout={GoodCardLayout.COMPACT}
							forceOpen={true}
							brandView={BrandViews.NONE}
						/>
					))}
				</Slider>
			</SidedBox>
		</WhereBlock>
	));
};
