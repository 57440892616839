import React from 'react';
import { Modal2 as Modal, Button } from '@food/ui';
import { IFNTheme, registerClass, currySC, cns } from '@food/css-manager';
import { IMediaImgProps, layouts, MediaImg } from '../entities/MediaImg';

export enum LightBoxLayouts {
	DESKTOP = 'DESKTOP',
	MOBILE = 'MOBILE',
}

interface ILightboxState {
	open: boolean;
	selected: number;
}

interface ILightboxInterface extends IMediaImgProps {
	siblings?: any[];
	lightboxLayout?: LightBoxLayouts;
}

const modalClass = registerClass(
	(theme: IFNTheme, sc) => `

	> div {
		background: rgba(0,0,0,0.8)!important;
		> div {
			background: transparent!important;
			box-shadow: none!important;
			display: flex;
			justify-content: center;
			align-items: center;
			user-select: none;
		}
	}

	figure {
		display: block;
	}
	
	.${sc('icon')} {
		margin: 0;
		background: transparent;
		border: 0;
		outline: none;
		font-size: ${theme.ratios.xl * 2}em;
		padding: 0.2em;
	}
	
	&.${sc('layout-MOBILE')} {
		> div:first-child {
			padding: 0 !important;
		}
		
		.${sc('icon')} {
			margin: ${theme.ratios.xs}rem;
		}
	}
	
	.${sc('close')} {
		position: absolute;
		top: auto;
		bottom: 100%;
		left: 100%;
		font-size: 2rem;
		padding: 0.2em 0.4em;
		z-index: ${theme.zIndexes.P1_SlightlyAbove};
	}
	
	.${sc('image-container')} {
		position: relative;
		background-color: ${theme.colors.white};
		display: block;
		
		> .${sc('label')} {
			padding: ${theme.ratios.l}rem;
			text-align: center;
		}
	}
`,
);

const scm = currySC(modalClass);

const styleClass = registerClass(
	() => `
	align-self: center;
	
	> figure {
		width: 100%;
		cursor: pointer;
	}
`,
);

export class LightBox extends React.PureComponent<ILightboxInterface, ILightboxState> {
	private readonly originalIndex;

	constructor(props) {
		super(props);

		const { siblings, entity } = this.props;
		const selected =
			siblings !== undefined
				? siblings.findIndex((media) => media.id === entity.id)
				: 0;

		this.originalIndex = selected;

		this.state = {
			open: false,
			selected,
		};
	}

	close = () => this.setState({ open: false, selected: this.originalIndex });

	showNext = () => {
		const { siblings } = this.props;
		const { selected } = this.state;
		if (siblings === undefined) {
			return;
		}
		this.setState({
			selected: selected === siblings.length - 1 ? 0 : selected + 1,
		});
	};

	showPrevious = () => {
		const { siblings } = this.props;
		const { selected } = this.state;
		if (siblings === undefined) {
			return;
		}
		this.setState({
			selected: selected === 0 ? siblings.length - 1 : selected - 1,
		});
	};

	render() {
		const { open, selected } = this.state;
		const { siblings, entity, lightboxLayout = LightBoxLayouts.DESKTOP } = this.props;
		const hasSiblings = siblings !== undefined && siblings.length > 1;
		const selectedMedia = hasSiblings ? siblings[selected] : entity;

		return (
			<>
				<Modal
					open={open}
					closeHandler={this.close}
					className={cns(modalClass, scm('layout', lightboxLayout))}
				>
					{() => (
						<>
							{hasSiblings && (
								<Button
									className={scm('icon')}
									onClick={this.showPrevious}
									icon={'chevron_left'}
								/>
							)}
							<div className={scm('image-container')}>
								<MediaImg
									{...this.props}
									entity={selectedMedia}
									layout={layouts.LARGE}
									naturalWidth={true}
								/>
								{selectedMedia.caption.indexOf('.jpg') === -1 &&
									selectedMedia.caption.indexOf('.jpeg') === -1 &&
									selectedMedia.caption.indexOf('.png') === -1 && (
										<div className={scm('label')}>{selectedMedia.caption}</div>
									)}
								<Button
									className={cns(scm('icon'), scm('close'))}
									onClick={this.close}
									icon={'close'}
								/>
							</div>
							{hasSiblings && (
								<Button
									className={scm('icon')}
									onClick={this.showNext}
									icon={'chevron_right'}
								/>
							)}
						</>
					)}
				</Modal>
				<div onClick={() => this.setState({ open: true })} className={styleClass}>
					<MediaImg {...this.props} />
				</div>
			</>
		);
	}
}
