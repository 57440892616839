import React, { useContext, useEffect } from 'react';
import gql from 'graphql-tag';
import { IFNTheme, mixin, theme } from '../../utils/theme';
import { Box, Icon, Slider, SliderLayouts, Tooltip } from '@food/ui';
import { layouts, MediaImg, mediaImgFragment } from './MediaImg';
import {
	EditorialSectionBlock,
	EditorialSectionBlockLayouts as ESlayouts,
	Fragment as EditorialSectionBlockFragment,
} from './EditorialSectionBlock';
import t from '../../utils/labels';
import { ENTITY } from '../../utils/entities';
import { renderErrors } from '../../utils/errors';
import { cns, currySC, registerClass, subClass } from '@food/css-manager';
import {
	companyLevel,
	companyLevel2Price,
	companyLevelStr,
	html,
	levelOrMore,
} from '../../utils/misc';
import { Fragment as FairCardFragment } from '../../components/entities/FairCard';
import {
	BorderColors,
	Layouts as RemoteWordpressWidgetLayouts,
	RemoteWordpressWidget,
} from '../blocks/RemoteWordpressWidget';
import CONFIG from '../../static/config';
import { GoodCardFragment } from './GoodCard';
import { loadable, LoadableDirection } from '../../utils/loadable';
import { renderContext } from '../../utils/context/renderContext';
import { LightBox, LightBoxLayouts } from '../UI/LightBox';
import {
	DistributionChannelBlock,
	distributionChannelBlockFragment,
} from '../DistributionChannelBlock';
// import { UserNoteCommand } from '../UserNoteCommand';
import { CompanyDetailBillingInfo } from '../CompanyDetailBillingInfo';
import { ContactSupplierBox } from '../ContactSupplierBox';
import { CompanyDetailHead } from '../head/CompanyDetailHead';
import { CompanyDetailAside } from './CompanyDetailAside';
import { BrandGroups } from './BrandGroups';
import { RelatedCompanies } from './RelatedCompanies';
import { addWhere, useTracking, WhereBlock } from '../../utils/tracking';
import {
	Brand,
	Company,
	CompanyLevelEnum,
	HighlightedGood,
	MediaType,
} from '../../server-types';
import { TWhere } from '../../tracking-types';
import { UserObjectSubscription } from './UserObjectSubscription';
import { questionSetCreationModalFragment } from './QuestionSetCreationModal';
// import TagManager from 'react-gtm-module';

export enum CompanyDetailLayouts {
	ONE_COL = 'ONE_COL',
	TWO_COL = 'TWO_COL',
	THREE_COL = 'THREE_COL',
	FOUR_COL = 'FOUR_COL',
}

export function companyDetailLayoutChooser(w: number): CompanyDetailLayouts {
	const { mobileMax, tabletMax, notebookMax } = theme.breakpoints;
	if (w <= mobileMax) {
		return CompanyDetailLayouts.ONE_COL;
	} else if (w <= tabletMax) {
		return CompanyDetailLayouts.TWO_COL;
	} else if (w <= notebookMax) {
		return CompanyDetailLayouts.THREE_COL;
	} else {
		return CompanyDetailLayouts.FOUR_COL;
	}
}

interface CompanyDetailProps {
	entity: Company;
	errors?: any[];
	layout?: CompanyDetailLayouts;
}

const BranchAddressFragment = gql`
	fragment BranchAddressFragment on Branch {
		id
		name
		lat
		lng
		address1
		address2
		Country {
			id
			name
		}
		StateProvince {
			id
			name
			region
		}
		postalCode
		city
	}
`;

// graphql fragment
export const companyDetailFragment = gql`
	fragment CompanyDetailFragment on Company {
		id
		name
		slug
		description
		website
		companyEmail
		ownership
		yearEstablished
		billingName
		vatNumber
		plProducer
		description
		descriptionLong
		companyCertifications {
			id
			name
		}
		fairParticipations(isConcluded: false) {
			id
			pavilion
			stand
			note
			Fair {
				id
				...FairCardFragment
			}
		}
		goodFeatureCertifications {
			id
			name
		}
		goodClassesFull {
			id
			editorialSections {
				id
			}
		}
		editorialSections {
			...EditorialSectionBlockFragment
		}
		brands {
			id
			name
			pricePositioning
			socialFacebookPage
			socialInstagramPage
			website
			logoMedia {
				...MediaImgFragment
			}
			Company {
				id
				name
				logoMedia {
					...MediaImgFragment
				}
			}
		}

		highlightedGoods {
			id
			Good {
				...GoodCardFragment
				Brand {
					id
				}
			}
		}

		geoIndications {
			name
			website
		}

		billingBranch {
			...BranchAddressFragment
		}

		headquarterBranch {
			...BranchAddressFragment
		}

		branches {
			...BranchAddressFragment
		}

		currentCompanyLevels {
			id
			level
			newsPath
			targetSite
			companyBadges {
				id
				name
				descriptionLong
				iconMedia {
					...MediaImgFragment
				}
			}
		}

		companyYears {
			id
			year
			revenue
			ebitda
			gain
			numEmployees
		}

		logoMedia {
			...MediaImgFragment
		}

		additionalImagesMedia {
			id
			...MediaImgFragment
			caption
		}

		distributionChannels {
			...distributionChannelBlockFragment
		}

		...QuestionSetCreationModalFragment
	}
	${questionSetCreationModalFragment}
	${BranchAddressFragment}
	${mediaImgFragment}
	${EditorialSectionBlockFragment}
	${FairCardFragment}
	${GoodCardFragment}
	${distributionChannelBlockFragment}
`;

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	margin: ${theme.ratios.xl}rem;
	
	.${sc('badgeMaestro')} {
		position: absolute;
		z-index: ${t.zIndexes.P1_SlightlyAbove + 1};
		border-radius: ${t.radius}rem 0;
	}
	
	.${sc('contentHeader')} {
		display: flex;
		align-items: start; // Così la cornice del logo non si estende in basso quando sarebbe più bassa
		padding-top: ${t.ratios.xl / 2}rem;
	}

	.${sc('logo')} {
		max-width: ${t.columnSize}px; // FIXME
		flex-basis: 40%; // FIXME
		border: 1px solid ${t.colors.grey.plain};
		padding: ${t.ratios.l}rem;
		margin-right: ${t.ratios.l}rem;
		margin-bottom: ${t.ratios.l}rem;
		flex: 1 1 25%;
		
		> * {
			width: 100%;
		}
	}

	&.${sc('level-MAESTRO')} {
		.${sc('logo')} {
			border-color: ${t.colors.premium.plain};
			border-radius: ${t.radius}rem;
		}
	}

	.${sc('row')} {
		margin-top: ${t.ratios.l}rem;
	}

	.${sc('tooltip')} {
		max-width: 80vw;
			width: 350px;
		> div {
			

			&::after {
				left: 1.75rem;
				margin-left: 0;
				transform: translateX(-50%);
				
			}
		}
	}

	.${sc('desc')} {
		line-height: ${t.ratios.xl}rem;
		margin-bottom: ${t.ratios.l}rem;
	}
	
	.${sc('dataSheet')} {
		line-height: ${t.ratios.xl}rem;
		
		.${sc('label')} {
			flex-shrink: 0;
		}
	}
	
	.${sc('since')} {
		font-style: italic;
		color: ${t.colors.grey.dark};
		margin-bottom: ${t.ratios.s}rem;
		font-size: ${t.ratios.l}rem;
	}

	.${sc('brandGroupContent')} {
		box-shadow: ${t.boxShadow(1)};
	}

	.${sc('info')} {
		margin-top: ${t.ratios.s}rem;
		flex: 1 1 75%;
	}
	
	.${sc('name')} {
		display: block;
		font-size: ${t.ratios.l * 2}rem;
		font-weight: ${t.font.weights.semibold};
		margin-bottom: ${t.ratios.s}rem;
	}
	
	.${sc('sticky-wrapper')} {
		position: sticky;
		top: ${t.ratios.l}rem;
	}
	.${sc('sidebar')} .${sc('sticky-wrapper')} > div, .${sc('mainColumn')} > div > div {
		box-shadow: ${t.boxShadow(1)};
		margin-bottom: ${t.ratios.l}rem;
	}
	
	&.${sc('layout-FOUR_COL')} {
		.${sc('sidebar')} > div, .${sc('mainColumn')} > div > div {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	
	&.${sc('layout-FOUR_COL')} {
		.${sc('mainColumn')}, .${sc('sidebar')} {
			margin-bottom: ${t.ratios.xl}rem;
		}
	}
	
	.${sc('actions')} {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		flex-direction: row;
		z-index: ${t.zIndexes.P1_SlightlyAbove};
	}
	
	.${sc('label')} {
		font-weight: ${t.font.weights.semibold};
		margin-right: ${t.ratios.xs}rem;
	}

	&.${sc('layout-ONE_COL')} {
		.${sc('badgeMaestro')} {
			border-radius: ${t.radius}rem;
		}
		.${sc('contentHeader')} {
			flex-direction: column;
		}
		.${sc('logo')} {
			align-self: center;
			width: 60%;
			border: none
			padding: 0;
			margin-right: 0;
		}
		.${sc('dataSheet')} {
		
			> div {
				flex-direction: column;
				align-items: start;
			}
		}
	}
	
	.${sc('button-center')} {
		display: flex;
		justify-content: center;
		width: 100%;
	}
	
	.${sc('plValue')} {
		font-weight: ${t.font.weights.bold};
	}
	
	.${sc('es-datasheet')} {
		line-height: 1;
		margin-top: -${(t.ratios.xl - 1) / 2}rem;
	}
	
	.${sc('es-image-side')},
	 &.${sc('layout-ONE_COL')} .${sc('es-datasheet')} {
		line-height: 1;
		margin-top: ${(t.ratios.xl - 1) / 2}rem;
	}
	
	.${sc('html')} {
		strong {
			font-weight: ${t.font.weights.bold};
		}
		
		ol {
			list-style: decimal outside;
		}
		
		ul {
			list-style: circle outside;
		}
		
		ol, ul {
			padding-left: ${t.ratios.xl}em;
		}
	}
`,
);

const sc = currySC(styleClass);

export const CompanyDetail: React.FC<CompanyDetailProps> = ({
	entity,
	errors,
	layout = CompanyDetailLayouts.FOUR_COL,
}) => {
	const { isBot } = useContext(renderContext);
	const track = useTracking();
	const level = companyLevel(entity.currentCompanyLevels);
	const companyDetailWhere: TWhere = {
		type: 'CompanyDetail',
		level,
		CompanyId: entity.id,
	};
	const errorElement = renderErrors(errors || []);

	useEffect(() => {
		if (!errorElement) {
			// se non ho errori invio la view a tracking
			track(
				{ type: 'view', what: [companyDetailWhere] },
				addWhere(companyDetailWhere),
			);

			// evento analytics di visualizzazione dettaglio
			(window as any).gtag('event', 'view_item', {
				items: [
					{
						id: entity.id,
						name: entity.name,
						brand: entity.name,
						variant: 'Supplier',
						category: companyLevelStr(entity.currentCompanyLevels),
						price: companyLevel2Price(entity.currentCompanyLevels),
						quantity: 1,
					},
				],
			});
		}
	}, [
		companyDetailWhere,
		entity.currentCompanyLevels,
		entity.id,
		entity.name,
		errorElement,
		track,
	]);

	if (errorElement) {
		return errorElement;
	}

	const baseEdSections: any[] = entity.editorialSections;
	const newsPath =
		entity.currentCompanyLevels &&
		entity.currentCompanyLevels[0] &&
		entity.currentCompanyLevels[0].newsPath;

	const l1OrMore = levelOrMore(level, CompanyLevelEnum.PaidL1);
	const l2OrMore = levelOrMore(level, CompanyLevelEnum.PaidL2);
	const description = l2OrMore ? entity.descriptionLong : entity.description;

	const badges =
		entity.currentCompanyLevels &&
		entity.currentCompanyLevels.length > 0 &&
		entity.currentCompanyLevels[0].companyBadges;

	// lanci da wordpress su sito news
	const relatedNewsUrl = CONFIG.NEWS_SITE_BASE_URL + newsPath;

	// Preparo per mostrare i brand con associalto l'elenco dei prodotti
	const brandGroups = l1OrMore
		? entity.highlightedGoods.reduce<
				Array<{ id: string; brand: Brand | undefined; hgoods: HighlightedGood[] }>
		  >((res, hgood) => {
				const brandId =
					hgood && hgood.Good && hgood.Good.Brand && hgood.Good.Brand.id;
				if (!brandId) return res;
				const group = res.find((g) => g.id === brandId);
				if (!group) {
					res.push({
						id: brandId,
						brand: undefined,
						hgoods: [hgood],
					});
				} else {
					group.hgoods.push(hgood);
				}
				return res;
		  }, [])
		: [];

	if (brandGroups.length) {
		entity.brands.forEach((brand) => {
			const group = brandGroups.find((g) => g.id === brand.id);
			if (group) {
				group.brand = brand;
			}
		});
	}

	const galleryLayout =
		layout === CompanyDetailLayouts.ONE_COL
			? LightBoxLayouts.MOBILE
			: LightBoxLayouts.DESKTOP;

	const hasBadgesBlock = l1OrMore && badges && badges.length > 0;

	return (
		<WhereBlock step={companyDetailWhere}>
			<CompanyDetailHead entity={entity} />

			<div className={cns(styleClass, sc('layout', layout))}>
				{/* Container delle generale */}
				<div
					className={
						layout === CompanyDetailLayouts.FOUR_COL ? mixin.pageWithSidebar : ''
					}
				>
					{/* Container delle due colonne */}

					<div className={sc('mainColumn')}>
						<div className={loadable(isBot, LoadableDirection.BOTTOM)}>
							<Box>
								<header
									className={cns(mixin.pageDetailHeader, sc('contentHeader'))}
								>
									{l2OrMore && (
										<span
											className={cns(mixin.maestroBadge, sc('badgeMaestro'))}
										>
											<Icon name={'star'} /> {t`MAESTRO`}
										</span>
									)}
									<div className={sc('actions')}>
										{/*<UserNotesControl
											entity={entity}
											notes={entity.userNotes}
											openComponent={UserNoteCommand}
										/>*/}
										<UserObjectSubscription
											entityId={entity.id}
											collection={ENTITY.COMPANY}
										/>
									</div>
									{entity.logoMedia && (
										<div className={sc('logo')}>
											<MediaImg
												type={MediaType.Logo}
												entity={entity.logoMedia}
												layout={layouts.MEDIUM}
												alt={`Company ${entity.name} logo`}
											/>
										</div>
									)}
									<div className={sc('info')}>
										<h1 className={sc('name')}>{entity.name}</h1>

										{/* Foundation Year */}
										{l1OrMore && entity.yearEstablished && (
											<div className={sc('since')}>
												{t`Since`} {entity.yearEstablished}
											</div>
										)}

										{/* Ownership */}
										{l1OrMore && entity.ownership && (
											<div>{t(entity.ownership)}</div>
										)}

										{hasBadgesBlock && (
											<div className={mixin.detailBadgeContainer}>
												{badges &&
													badges.map((badge) =>
														badge.iconMedia ? (
															<div
																className={subClass(
																	mixin.detailBadgeContainer,
																	'badge',
																)}
															>
																<Tooltip
																	key={badge.id}
																	centerArrow={true}
																	component={() => (
																		<span
																			dangerouslySetInnerHTML={html(
																				badge.descriptionLong,
																			)}
																		/>
																	)}
																>
																	<MediaImg
																		type={MediaType.Icon}
																		entity={badge.iconMedia}
																		className={subClass(
																			mixin.detailBadgeContainer,
																			'badgeIcon',
																		)}
																		alt={`Badge ${badge.name} symbol`}
																	/>
																</Tooltip>
																<div
																	className={subClass(
																		mixin.detailBadgeContainer,
																		'badge',
																		'title',
																	)}
																>
																	{badge.name}
																</div>
															</div>
														) : (
															<span />
														),
													)}
											</div>
										)}
										{!hasBadgesBlock && description && (
											<div
												className={cns(
													sc('desc'),
													mixin.pageDetailDesc,
													sc('html'),
												)}
											>
												<span className={sc('label')}>{t`Description`}:</span>
												<div dangerouslySetInnerHTML={html(description)} />
											</div>
										)}
										{!hasBadgesBlock && !description && (
											<div className={cns(sc('desc'), mixin.pageDetailDesc)}>
												<span className={sc('label')}>
													{t`Products Categories`}:
												</span>
												<EditorialSectionBlock
													entities={baseEdSections}
													layout={ESlayouts.ICONS_TEXTS}
													inline={true}
													className={sc('es-image-side')}
												/>
											</div>
										)}
									</div>
								</header>

								{/* Description */}
								{hasBadgesBlock && description && (
									<div
										className={cns(
											sc('desc'),
											mixin.pageDetailDesc,
											sc('html'),
										)}
									>
										<span className={sc('label')}>{t`Description`}:</span>
										<div dangerouslySetInnerHTML={html(description)} />
									</div>
								)}

								<div className={cns(sc('dataSheet'), mixin.dataSheet)}>
									{/* EditorialSections */}
									{(hasBadgesBlock || description) && (
										<div>
											<span className={sc('label')}>
												{t`Products Categories`}:
											</span>
											<EditorialSectionBlock
												entities={baseEdSections}
												layout={ESlayouts.ICONS_TEXTS}
												inline={true}
												className={sc('es-datasheet')}
											/>
										</div>
									)}
									{/* Certifications */}
									{l1OrMore &&
										entity.companyCertifications &&
										entity.companyCertifications.length > 0 && (
											<div>
												<span className={sc('label')}>
													{t`Certifications`}:
												</span>
												<ul className={mixin.bulletedList}>
													{entity.companyCertifications.map(
														({ id, name }) => (
															<li key={id}>{name}</li>
														),
													)}
												</ul>
											</div>
										)}

									{/* Features */}
									{l1OrMore &&
										entity.goodFeatureCertifications &&
										entity.goodFeatureCertifications.length > 0 && (
											<div>
												<span className={sc('label')}>{t`Features`}:</span>
												<ul className={mixin.bulletedList}>
													{entity.goodFeatureCertifications.map(
														({ id, name }) => (
															<li key={id}>{name}</li>
														),
													)}
												</ul>
											</div>
										)}

									{/* PL Manufacturer */}
									{l1OrMore && typeof entity.plProducer === 'boolean' && (
										<div>
											<span className={sc('label')}>
												{t`Private Label Manufacturer`}:
											</span>
											<span
												className={sc('plValue')}
												style={{
													color: entity.plProducer
														? theme.colors.success.plain
														: theme.colors.danger.plain,
												}}
											>
												{' '}
												{t(entity.plProducer ? 'Yes' : 'No')}
											</span>
										</div>
									)}

									{/* Revenue */}
									{entity.geoIndications && entity.geoIndications.length > 0 && (
										<div>
											<span className={sc('label')}>
												{t`Manifactured Geographical Indications`}:
											</span>
											<ul className={mixin.bulletedList}>
												{entity.geoIndications.map((geo, i) => (
													<li key={i}>
														<a
															href={geo.website}
															target="_blank"
															rel="noopener noreferrer"
														>
															{geo.name}
														</a>
													</li>
												))}
											</ul>
										</div>
									)}
								</div>
							</Box>

							{layout !== CompanyDetailLayouts.FOUR_COL && (
								<>
									<ContactSupplierBox company={entity} l1OrMore={l1OrMore} />
									<CompanyDetailBillingInfo
										sc={sc}
										company={entity}
										hasL1={l1OrMore}
									/>
								</>
							)}

							{/* gallery */}
							{l2OrMore &&
								entity.additionalImagesMedia &&
								entity.additionalImagesMedia.length > 0 && (
									<Box
										title={t`Gallery`}
										fullWidth={true}
										className={loadable(isBot, LoadableDirection.BOTTOM)}
									>
										<Slider
											layout={SliderLayouts.SINGLE_ELEMENT}
											carusel={true}
										>
											{entity.additionalImagesMedia.map((media, index) => (
												<LightBox
													key={media.id}
													type={MediaType.GoodImage}
													entity={media}
													layout={layouts.MEDIUM}
													lightboxLayout={galleryLayout}
													siblings={entity.additionalImagesMedia}
													alt={
														media.caption ||
														`Company ${entity.name} image ${index} in gallery`
													}
												/>
											))}
										</Slider>
									</Box>
								)}

							{/* blocco distribution channel, nelle sue varie forme che cambiano a seconda dello stato dell'utente */}
							<DistributionChannelBlock
								company={entity}
								entities={entity.distributionChannels}
								hasPaymentLevel={l1OrMore}
								psc={sc}
							/>
						</div>
					</div>

					<CompanyDetailAside
						className={cns(loadable(isBot), sc('sidebar'))}
						company={entity}
						layout={layout}
						l1OrMore={l1OrMore}
						sc={sc}
						level={level}
					/>
				</div>

				{/* se e' presente o piu' faccio vedere i suoi brand con i prodotti */}
				{l1OrMore ? (
					<BrandGroups groups={brandGroups} layout={layout} entity={entity} />
				) : (
					<RelatedCompanies entity={entity} layout={layout} />
				)}

				{l1OrMore && newsPath && (
					<RemoteWordpressWidget
						className={'news-widget'}
						url={relatedNewsUrl}
						selector={'#main article'}
						title={t`News`}
						isBordered={true}
						layout={RemoteWordpressWidgetLayouts.tripleWidth}
						borderColor={BorderColors.alt}
						hasBorderPattern={true}
					/>
				)}
			</div>
		</WhereBlock>
	);
};
