import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-client';

const headQuery = gql`
	query HeadQuery($id: ID!) {
		node(id: $id) {
			... on EditorialSection {
				id
				name
				akas
				iconMedia {
					id
					largeThumbUrl
				}
			}
			... on FrequentSearch {
				id
				name
				akas
				nameIta
				fairs {
					id
					logoMedia {
						id
						largeThumbUrl
					}
				}
				awards {
					id
					logoMedia {
						id
						largeThumbUrl
					}
				}
			}
			... on Fair {
				id
				name
				description
				year
				logoMedia {
					id
					largeThumbUrl
				}
			}
			... on Award {
				id
				name
				publicDescription
				logoMedia {
					id
					largeThumbUrl
				}
			}
		}
	}
`;

export interface IHeadQueryFetcherProps {
	id: string | false;
	children: (node: any) => JSX.Element;
}

export const HeadQueryFetcher: React.FC<IHeadQueryFetcherProps> = ({ id, children }) => (
	<Query query={headQuery} variables={{ id }} skip={!id} errorPolicy={'all'}>
		{({
			error,
			loading,
			data,
		}: {
			loading: boolean;
			error?: ApolloError;
			data: any;
		}) => {
			if (error || loading) {
				return null;
			}

			return children(data as any);
		}}
	</Query>
);
