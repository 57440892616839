import React from 'react';
import { Tag, IconPositions } from '@food/ui';
import { registerClass, subClass } from '@food/css-manager';
import { IFNTheme } from '../../utils/theme';
import { MATERIAL } from '@food/ui/build/dist/src/icons';

interface IFilterTagsProps {
	title?: string;
	tags: Array<{ onRemove: () => void; label: string; icon?: MATERIAL | null }>;
	show?: number;
	type?: string;
}

const styleClass = registerClass(
	(theme: IFNTheme, sc) => `
	margin-bottom: ${theme.ratios.xs}rem;
	
	> h4 {
		display: inline-block;
		margin-right: ${theme.ratios.xs}rem;
	}
	
	.${sc('or')} {
		font-size: ${theme.ratios.s}em;
		padding-left: ${theme.ratios.xs / 2}rem;
		padding-right: ${theme.ratios.xs / 2}rem;
	}
`,
);

const orBlock = <span className={subClass(styleClass, 'or')}>or</span>;

export const FilterTags: React.FC<IFilterTagsProps> = ({
	title,
	tags,
	show = 3,
	type,
}) => (
	<div className={styleClass}>
		{title && <span>{title}: </span>}
		{tags
			.filter((t, i) => i < show)
			.map(({ label, onRemove, icon }, index) => (
				<React.Fragment key={label}>
					{index !== 0 && orBlock}
					<Tag
						label={label}
						onRemove={onRemove}
						type={type}
						icon={icon}
						iconPos={IconPositions.LEFT}
					/>
				</React.Fragment>
			))}
		{tags.length > show && (
			<>
				{orBlock}
				<Tag onClick={() => null} label={`+${tags.length - show}`} type={type} />
			</>
		)}
	</div>
);
