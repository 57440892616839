import React from 'react';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router';
import { siteSlogan, siteTitle } from '../../utils/seo';
import CONFIG from '../../static/config';
import logoSocial from '../../static/assets/logo_social.jpg';
import { toText } from '../../utils/misc';
import { parse, stringify } from 'querystring';

interface IGenericHeadProps {
	title?: string;
	description?: string | null | undefined;
	image?: string;
	keywords?: string;
	jsonld?: string[];
	imageVariableWidth?: boolean;
	filtersOnCanonical?: boolean;
}

/**
 * Teoricamente JSON.stringify dovrebbe fare l'escape corretto
 * ma non so bene perché non funziona bene come dovrebbe.
 * Per ora in maniera molto grezza tolgo i caratteri " e \
 * che sono quelli che danno il problema.
 * @param s: string
 * @return string
 */
export const jsonldStringEscape = (s: string): string =>
	s.replace('"', '').replace('\\', '');

export const GenericHead: React.FC<IGenericHeadProps> = ({
	title = siteTitle,
	description = siteSlogan,
	image = CONFIG.PUBLIC_URL + logoSocial,
	imageVariableWidth = false,
	keywords = '',
	jsonld = [],
	filtersOnCanonical,
}) => (
	<Route>
		{({ location }) => {
			const completeTitle = title !== siteTitle ? `${title} - ${siteTitle}` : title;
			const finalTitle =
				completeTitle.substring(0, 69) + (completeTitle.length > 69 ? '…' : '');
			const params = {};
			if (filtersOnCanonical) {
				const acceptedFilters = [
					'stateProvinces',
					'goodClasses',
					'plProducer',
					'preservations',
					'goodFeatureCertifications',
					'page',
					'perPage',
					'textSearch',
					'FrequentSearch',
					'fairs',
				];
				const filters = parse(location.search.slice(1));
				for (const key in filters) {
					if (
						filters &&
						key in filters &&
						filters[key] &&
						acceptedFilters.find((k) => k === key)
					) {
						params[key] = filters[key];
					}
				}
			}
			const filters = Object.keys(params).length ? '?' + stringify(params) : '';
			const parsedDescription = description ? toText(description) : '';

			return (
				<Helmet>
					<meta name="twitter:card" content="summary" />
					<meta name="twitter:site" content="@italianfood_net" />
					<meta
						property="og:url"
						content={CONFIG.PUBLIC_URL + location.pathname + filters}
					/>
					<link
						rel="canonical"
						href={CONFIG.PUBLIC_URL + location.pathname + filters}
					/>
					<meta property="og:locale" content="en_US" />
					<meta property="og:type" content="website" />
					<meta property="og:site_name" content={siteTitle} />

					<title>{finalTitle}</title>
					<meta property="og:title" content={finalTitle} />
					<meta name="twitter:title" content={finalTitle} />

					{description && (
						<meta property="og:description" content={parsedDescription} />
					)}
					{description && (
						<meta name="twitter:description" content={parsedDescription} />
					)}
					{description && <meta name="description" content={parsedDescription} />}

					<meta name="keywords" content={keywords} />

					<meta property="og:image" content={image} />
					<meta name="twitter:image" content={image} />

					{!imageVariableWidth && <meta property="og:image:width " content="800" />}
					{!imageVariableWidth && <meta property="og:image:height" content="800" />}
					{jsonld.map((content, i) => (
						<script key={i} type="application/ld+json">
							{content}
						</script>
					))}
				</Helmet>
			);
		}}
	</Route>
);
