import React, { useState } from 'react';
import gql from 'graphql-tag';
import { IFNTheme } from '../../utils/theme';
import { registerClass, cns, currySC } from '@food/css-manager';
import * as placeholder from '../../static/image_placeholder.png';
import { Media, MediaType } from '../../server-types';
import { useInView } from 'react-intersection-observer';

// typing
export interface IMediaImgProps {
	type: MediaType;
	entity: Partial<Media>;
	alt: string;
	className?: string;
	layout?: layouts;
	naturalWidth?: boolean;
}

export enum layouts { // KEEP-SYNC
	LARGE = 'LARGE',
	MEDIUM = 'MEDIUM',
	SMALL = 'SMALL',
	ICON = 'ICON',
}

interface MediaThumbProps {
	width?: number;
	height?: number;
}

interface MediaProp {
	ratio?: number;
	thumbs: {
		[key: string]: MediaThumbProps;
	};
}
interface MediaProps {
	// KEEP-SYNC
	LOGO: MediaProp;
	GOOD_IMAGE: MediaProp;
	ICON: MediaProp;
	PDF_WEB: MediaProp;
	BANNER_FULL: MediaProp;
	BANNER_HALF: MediaProp;
	BANNER_LEADERBOARD: MediaProp;
	BANNER_POPUP: MediaProp;
	NEWS: MediaProp;
	MAGAZINE_COVER: MediaProp;
	LOGO_PRINT: MediaProp;
	GOOD_PRINT_IMAGE: MediaProp;
}

const mediaProps: MediaProps = {
	[MediaType.Logo]: {
		ratio: 1,
		thumbs: {
			LARGE: {
				width: 800,
				height: 800,
			},
			MEDIUM: {
				width: 400,
				height: 400,
			},
			SMALL: {
				width: 200,
				height: 200,
			},
		},
	},
	[MediaType.GoodImage]: {
		ratio: 1,
		thumbs: {
			LARGE: {
				width: 1200,
				height: 1200,
			},
			MEDIUM: {
				width: 600,
				height: 600,
			},
			SMALL: {
				width: 300,
				height: 300,
			},
		},
	},
	[MediaType.Icon]: {
		// ratio: 1,
		thumbs: {
			LARGE: {
				width: 1200,
				height: 1200,
			},
			MEDIUM: {
				width: 600,
				height: 600,
			},
			SMALL: {
				width: 300,
				height: 300,
			},
		},
	},
	[MediaType.PdfWeb]: {
		ratio: 1,
		thumbs: {
			LARGE: {
				// CHECK
				width: 1200,
				height: 1200,
			},
			MEDIUM: {
				// CHECK
				width: 600,
				height: 600,
			},
			SMALL: {
				// CHECK
				width: 300,
				height: 300,
			},
		},
	},
	[MediaType.BannerFull]: {
		ratio: 300 / 250,
		thumbs: {
			MEDIUM: {
				width: 300,
				height: 250,
			},
			SMALL: {
				width: 150,
				height: 125,
			},
		},
	},
	[MediaType.BannerHalf]: {
		ratio: 300 / 120,
		thumbs: {
			MEDIUM: {
				width: 300,
				height: 120,
			},
			SMALL: {
				width: 150,
				height: 60,
			},
		},
	},
	[MediaType.BannerLeaderboard]: {
		// TODO
		ratio: 600 / 62,
		thumbs: {
			MEDIUM: {
				width: 600,
				height: 62,
			},
			SMALL: {
				width: 300,
				height: 31,
			},
		},
	},
	[MediaType.BannerPopup]: {
		// TODO
		ratio: 1,
		thumbs: {
			LARGE: {
				width: 600,
				height: 600,
			},
			SMALL: {
				width: 300,
				height: 300,
			},
		},
	},
	[MediaType.News]: {
		// TODO
		ratio: 1,
		thumbs: {
			LARGE: {
				width: 1200,
				height: 1200,
			},
			MEDIUM: {
				width: 600,
				height: 600,
			},
			SMALL: {
				width: 300,
				height: 300,
			},
		},
	},
	[MediaType.MagazineCover]: {
		// TODO - non può essere la semplice cover di PDF_WEB? Forse serve per lo storico o comunque per evitare di caricarli sui media?
		thumbs: {
			LARGE: {
				height: 1200,
			},
			MEDIUM: {
				height: 600,
			},
			SMALL: {
				height: 300,
			},
		},
	},
	[MediaType.LogoPrint]: {
		ratio: 1,
		thumbs: {
			LARGE: {
				width: 1200,
				height: 1200,
			},
			SMALL: {
				width: 300,
				height: 300,
			},
		},
	},
	[MediaType.GoodPrintImage]: {
		ratio: 1,
		thumbs: {
			LARGE: {
				width: 1200,
				height: 1200,
			},
			SMALL: {
				width: 300,
				height: 300,
			},
		},
	},
};

// graphql fragment
export const mediaImgFragment = gql`
	fragment MediaImgFragment on Media {
		id
		type
		smallThumbUrl
		mediumThumbUrl
		largeThumbUrl
		vecThumbUrl
	}
`;

// style
const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	display: inline-block;
	position: relative;

	> .${sc('ratioSpacer')},
	> img {
		display: block;
		width: 100%;
	}

	&.${sc('fixedRatio')} {
		/* Tiene lo spazio libero intanto che si carica l'immagine sfruttando il fatto che l'immagine sarà quadrata */
		/* Il padding tieneil posto e poi l'immagine è piazzata in absolute */
		position: relative;
	
		&.${sc('loading')} {
			background: ${t.colors.grey.light}; /* CHECK */
		}
	
		> img {
			position: absolute;
			z-index: ${t.zIndexes.P1_SlightlyAbove};
			top: 0;
			left: 0;
			max-width: 100%;
			max-height: 100%;
		}
	}

	&.${sc('layout', 'ICON')} {
		vertical-align: middle;
		width: 1em;
	}
	
	> .${sc('context-overlay')} {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: ${t.zIndexes.P1_SlightlyAbove};
	}
`,
);

const sc = currySC(styleClass);

export const MediaImg: React.FC<IMediaImgProps> = ({
	type,
	entity: item,
	className,
	layout = layouts.SMALL,
	naturalWidth,
	alt,
}) => {
	const [loaded, setLoaded] = useState(false);
	const [ref, inView] = useInView({
		triggerOnce: true,
		rootMargin: '100px 0px',
	});
	const currentType = (item && item.type) || (!Array.isArray(type) && type);
	const ratio = currentType && mediaProps[currentType] && mediaProps[currentType].ratio;
	let src;
	const maxWidth =
		mediaProps[currentType] &&
		mediaProps[currentType].thumbs[layout] &&
		mediaProps[currentType].thumbs[layout].width;

	if (item) {
		switch (layout) {
			case layouts.MEDIUM:
				src =
					item.mediumThumbUrl ||
					item.largeThumbUrl ||
					item.origUrl ||
					item.smallThumbUrl;
				break;
			case layouts.LARGE:
				src =
					item.largeThumbUrl ||
					item.origUrl ||
					item.mediumThumbUrl ||
					item.smallThumbUrl;
				break;
			case layouts.SMALL:
			case layouts.ICON:
			default:
				src =
					item.smallThumbUrl ||
					item.mediumThumbUrl ||
					item.largeThumbUrl ||
					item.origUrl;
				break;
		}
		src = item.vecThumbUrl || src;
	}

	return (
		<figure
			className={cns(
				className,
				styleClass,
				sc('layout', layout),
				!loaded && sc('loading'),
				sc(ratio ? 'fixedRatio' : 'variableRatio'),
			)}
			style={{
				[!naturalWidth ? 'maxWidth' : 'width']: maxWidth ? `${maxWidth}px` : 'auto',
			}}
			ref={ref}
		>
			{ratio && (
				<div
					className={sc('ratioSpacer')}
					style={{ paddingBottom: `${100 / ratio}%` }}
				/>
			)}
			{(inView || loaded) && (
				<img src={src ? src : placeholder} onLoad={() => setLoaded(true)} alt={alt} />
			)}
			<div className={sc('context-overlay')} />
		</figure>
	);
};
