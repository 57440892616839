import React, { useContext, useEffect, useState /*useEffect*/ } from 'react';
import { cns, registerClass, currySC } from '@food/css-manager';
import { IFNTheme, mixin } from '../../utils/theme';
import { loadable, LoadableDirection } from '../../utils/loadable';
import t from '../../utils/labels';
import { renderContext } from '../../utils/context/renderContext';
import { GenericHead } from '../head/GenericHead';
import { WhereBlock } from '../../utils/tracking';
import { Button, ButtonLayouts, Icon } from '@food/ui/build/dist';
import { openRenewModal, UserData } from '@food/auth';
import { Modal2 as Modal } from '@food/ui';

import icon1 from '../../static/assets/howto/search.svg';
import icon2 from '../../static/assets/howto/save.svg';
import icon3 from '../../static/assets/howto/contatti.svg';
import { ThreeColumnsInstantAction } from '../ThreeColumnsInstantAction';

import illustration from '../../static/assets/howto/hiw02.1ed93a9f.png';
import intestazione from '../../static/assets/howto/intestazione.jpg';

export enum BuyersIntroLayouts {
	Main = 'Main',
}

interface IBuyersIntroProps {
	layout?: BuyersIntroLayouts;
	className?: string;
}

const styleClassModal = registerClass(
	(theme: IFNTheme, s) => `
		background: #000;

		.${s('icon')} {
			position: absolute;
			top: -1rem;
			right: 0;
			transform: translateY(-100%);
			font-size: 2rem;
			color: #FFF;
		}
		.${s('iframeWrapper')} {
			position: relative;
			padding-bottom: 56.25%;

			iframe {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: block;
			}
		}
	`,
);
const scM = currySC(styleClassModal);

const styleClass = registerClass(
	(theme: IFNTheme, s) => `
	/* override base page layout */
	margin: 0;
	padding: 0;
	max-width: none;
	box-shadow: none;
	line-height: 1.5;

	.${s('maxWidth')} {
		max-width: 1280px;
		margin: auto;
		padding: 0 3rem;

		@media only screen and (max-width: ${theme.breakpoints.tabletMax}px) {
			padding: 0 2rem;
		}
	}

	h1,
	h2,
	h3 {
		line-height: 1.2;
		font-weight: 400;
	}

	.${s('hero')} {
		position: relative;
		background: #000;
		padding: 7rem 0 6rem;
		@media all and (max-width: ${theme.breakpoints.mobileMax}px) {
			padding: 5rem 0 4rem;
		}

		.${s('bg')} {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-image: url('${intestazione}');
			background-size: cover;
			background-position: center center;
			opacity: 1;

		}

		.${s('contents')} {
			position: relative;
			z-index: 1;
			text-align: center;
			padding: 0 2rem;

			h1 {
				letter-spacing: 0.005em;
				position: relative;
				background: none;
				margin: 0;
				text-align: center;
				margin-bottom: 1.7rem;
				font-size: 2.8rem;

				@media only screen and (max-width: ${theme.breakpoints.tabletMax}px) {
					font-size: 2rem;
				}

				span {
					background: none;
					margin: 0;
					padding: 0;
					color: #FFF;

					em {
						color: #e30613;
						font-style: none;
					}
				}

				&.${sc('hasLine')} {
					padding-bottom: 0.5em;
					span {
						
						&:after {
							content: " ";
							display: block;
							height: 2px;
							background: #009640;
							width: 60px;
							position: absolute;
							top: 100%;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}

			
			p {
				opacity: 0.9;
				color: #FFF;
				font-size: 1.5rem;
				margin-bottom: 0;
			}

			h1, p {
				text-shadow: 0px 0px 8px rgba(0,0,0,0.9);
			}

			.${sc('play')} {
				cursor: pointer;
				display: inline-block;
				margin: 1.7rem auto 0;
				padding: 0.6rem 30px 0.5rem 30px;
				background: #d12e26;
				border-radius: 30px;
				line-height: 1;
				box-shadow: 0px 0px 8px rgba(0,0,0,0.5);
				border: none;
				outline:none;
				
				div {
					width: 16px;
					height: 16px;
					display: inline-block;
					margin-right: 0.5rem;
					vertical-align: middle;

					&:after {
						content: " ";
						width: 0;
						height: 0;
						font-size: 0;
						display: block;
						border-top: 6px solid transparent;
						border-bottom: 6px solid transparent;
						border-left: 10px solid #FFFFFF;
					}
				}

				span {
					display: inline-block;
					font-size: 0.65rem;
					text-transform: uppercase;
					letter-spacing: 1px;
					color: #FFF;
					font-weight: bold;
					vertical-align: middle;
					margin-top: -2px;
				}
			}
		}
	}
	
	.${s('instant-action')} {
		font-size: 1.1rem;
		padding: 4rem 0;

		@media all and (max-width: ${theme.breakpoints.mobileMax}px) {
			padding: 3rem 0;
		}

		.${sc('maxWidth')} {
			display: flex;
			align-items: flex-start;
			text-align: center;
			justify-content: center;
		}

		.${sc('item')} {
			flex-basis: 50%;

			&:first-child {
				padding-right: 2rem;
			}

			&:last-child {
				padding-left: 2rem;
			}

			.text {
				margin-bottom: 1.5rem;
			}
	
			.cta {
				margin-bottom: 2.5rem;

				a, button {
					font-weight: normal;
					margin-top: 1.56rem;
					text-decoration: none;
					font-size: 1rem;
					padding: 0.8em 1.5em 0.65em;
					background: #4ba55b;
					line-height: 1;
					
					&:first-child {
						margin-top: 0;
					}
				}
			}
	
			.text, .cta {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		

		@media only screen and (max-width: ${theme.breakpoints.tabletMax}px) {
			.${sc('maxWidth')} {
				flex-wrap: wrap;

				.${sc('item')} {
					flex-basis: 100%;
					padding: 0 0 3rem 0;

					&:last-child {
						padding-bottom: 0;
					}
				}
			}
		}
	}

	.${s('section')}.${s('bulletsSection')} {
		.${s('sectionHead')} {
			h2 {
				margin-bottom: 1em;
			}
		}
		.${sc('ctaContainer')} {
			margin-top: 1.6em;
		}
	}
	.${s('sectionHead')} {

		&:last-child {
			margin-bottom: 0;
		}

		h2 {
			letter-spacing: 0.005em;
			position: relative;
			background: none;
			margin: 0;
			text-align: center;
			padding-bottom: 0.54em;
			margin-bottom: 0.8em;
			font-size: 2rem;

			&:after {
				content: " ";
				display: block;
				height: 2px;
				background: #009640;
				width: 60px;
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		p {
			text-align: center;
			font-size: 1rem;
			max-width: 700px;
			margin: 1rem auto;
			color: #706c6c;
			line-height: 1.5;
		}
	}

	.${sc('section')} {
		padding: 4rem 0 0;

		&:last-child {
			padding-bottom: 4rem;
		}

		@media all and (max-width: ${theme.breakpoints.mobileMax}px) {
			padding-top: 3rem;
			&:last-child {
				padding-bottom: 3rem;
			}
		}

		p {
			text-align: center;
			font-size: 1rem;
			max-width: 700px;
			margin: 1rem auto;
			color: #706c6c;
		}

		.${sc('ctaContainer')} {
			margin-top: 0.8rem;
			text-align: center;

			a, button {
				padding: 0.8em 1.5em 0.65em;
				display: inline-block;
				background: #4ba55b;
				text-decoration: none;
				color: #FFF;
				border-radius: 0.3em;
				font-size: 1.3rem;
				font-weight: normal;
				line-height: 1;
			}
		}
	}

	

	.${s('descriptionSection')} {
		background: #fff1f1;
		padding: 4rem 0;

		@media all and (max-width: ${theme.breakpoints.mobileMax}px) {
			padding: 3rem 0;
		}

		.${s('contents')} {
			display: flex;
			justify-content: center;

			.${s('texts')} {
				padding-right: 3rem;
				flex-basis: 60%;

				h3 {
					font-size: 1.5rem;
					line-height: 1.4;

					@media only screen and (max-width: ${theme.breakpoints.tabletMax}px) {
						font-size: 1.4rem;
					}

					margin-bottom: 0.8em;
				}

				p {
					margin-top: 3em;
					font-size: 1rem;
					color: #706c6c;
				}

				ul {
					list-style: none;
					padding: 0;
					margin: 0 0 2rem;

					&:last-child {
						margin-bottom: 0;
					}

					li {
						display: block;
						padding-left: 2rem;
						position: relative;
						margin: 0;
						font-size: 1rem;
						color: #706c6c;
						margin-bottom: 1.2em;

						&:before {
							content: " ";
							display: "block";
							height: 2px;
							background: #e30613;
							position: absolute;
							left: 0;
							width: 1.5rem;
							top: 0.5em;
						}

						&:last-child {
							margin-bottom: 0;
						}
					}
				}

			}

			.${s('image')} {
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 90%;
				}
			}

			@media only screen and (max-width: ${theme.breakpoints.tabletMax}px) {
				flex-direction: column-reverse;

				.${s('image')} {
					img {
						width: auto;
						height: 10rem;
					}
				}

				.${s('texts')} {
					padding-right: 0;
					padding-top: 3rem;
				}
			}
		}
	}
`,
);

const sc = currySC(styleClass);

export const BuyersIntro: React.FC<IBuyersIntroProps> = ({
	className,
	layout = BuyersIntroLayouts.Main,
}) => {
	const { isBot } = useContext(renderContext);
	const [videoModalState, setVideoModalState] = useState(false);

	useEffect(() => {
		const escFunction = (event) => {
			if (event.keyCode === 27 && videoModalState === true) {
				setVideoModalState(false);
			}
		};

		document.addEventListener('keydown', escFunction, false);
		return () => {
			document.removeEventListener('keydown', escFunction, false);
		};
	}, [videoModalState]);

	return (
		<UserData>
			{() => {
				return (
					<WhereBlock step={{ type: 'Page', name: 'Buyers Introduction' }}>
						<Modal
							open={videoModalState}
							closeHandler={() => {
								setVideoModalState(false);
							}}
						>
							{() => (
								<div className={styleClassModal}>
									<Icon
										className={scM('icon')}
										name={'close'}
										onClick={() => {
											setVideoModalState(false);
										}}
									/>
									<div className={scM('iframeWrapper')}>
										<iframe
											src="https://player.vimeo.com/video/419846737/?autoplay=1"
											width="640"
											height="360"
											allow="autoplay; fullscreen"
										/>
									</div>
								</div>
							)}
						</Modal>

						<GenericHead title={t`Buyers Introduction`} />
						<div
							className={cns(
								mixin.textPage,
								styleClass,
								sc('layout', layout),
								mixin.limitedWidthPage,
								loadable(isBot, LoadableDirection.BOTTOM),
								className,
							)}
						>
							<div>
								<div className={sc('hero')}>
									<div className={sc('bg')}></div>
									<div className={sc('contents')}>
										<h1 className={sc('hasLine')}>
											<span>
												ItalianFOOD.net
												<br />
												The Authentic Italian Food Platform
											</span>
										</h1>
										<p>
											Discover new suppliers and amaze your customers with true
											Italian products
										</p>
										<button
											className={sc('play')}
											onClick={() => {
												setVideoModalState(true);
												//https://vimeo.com/419846737/46cde6b9d2
											}}
										>
											<div className="ico"></div>
											<span>watch the video</span>
										</button>
									</div>
								</div>
							</div>

							<div className={sc('maxWidth')}>
								<div className={cns(sc('section'), sc('bulletsSection'))}>
									<div className={sc('sectionHead')}>
										<h2>How it works</h2>
									</div>
									<ThreeColumnsInstantAction
										action1={{
											title: 'Discover new products',
											content:
												'Browse our unique list of true Italian suppliers and discover new products',
											image: icon1,
										}}
										action2={{
											title: 'Contact suppliers',
											content:
												'Directly contact suppliers and enquire for further details',
											image: icon2,
										}}
										action3={{
											title: 'Create your profile',
											content:
												'Save your favorite suppliers and products, create custom lists',
											image: icon3,
										}}
									/>

									<div className={sc('ctaContainer')}>
										<Button
											type={'success'}
											label={t`BROWSE THE PLATFORM`}
											linkTo={'/'}
											layout={ButtonLayouts.BUTTON}
										/>
									</div>
								</div>
							</div>
							<div className={sc('descriptionSection')}>
								<div className={cns(sc('maxWidth'), sc('contents'))}>
									<div className={sc('texts')}>
										<h3>
											Italianfood.net is the first platform designed for
											international retail buyers, distributors, wholesalers
											looking for authentic Made in Italy products.
										</h3>
										<h3>
											It represents an updated and complete database, which
											allow you to find the best-matching supplier
										</h3>
										<p>
											On <strong>ITALIANFOOD.NET you can</strong>:
										</p>
										<ul>
											<li>find ONLY authentic Italian products</li>
											<li>
												contact companies directly and without intermediaries
											</li>
											<li>send us your sourcing request</li>
											<li>
												search by tradeshow and request a meeting to your
												favorite suppliers
											</li>
										</ul>
									</div>
									<div className={sc('image')}>
										<img src={illustration} />
									</div>
								</div>
							</div>

							<div className={sc('instant-action')}>
								<div className={sc('maxWidth')}>
									<div className={sc('item')}>
										<div className="text">
											<div className={sc('sectionHead')}>
												<h2>Join</h2>
												<p>
													Join our network of qualified buyers, and register
													to access the platform!
												</p>
											</div>
										</div>
										<div className="cta">
											<Button
												type={'success'}
												label={t`BROWSE THE PLATFORM`}
												onClick={() => openRenewModal()}
												linkTo={'/'}
												layout={ButtonLayouts.BUTTON}
											/>
										</div>
									</div>
									<div className={sc('item')}>
										<div className="text">
											<div className={sc('sectionHead')}>
												<h2>Ask us</h2>
												<p>
													Are you looking for specific products?
													<br />
													Send to us your request!{' '}
												</p>
											</div>
										</div>
										<div className="cta">
											<Button
												type={'success'}
												label={t`SUBMIT YOUR SOURCING REQUEST`}
												onClick={() => openRenewModal()}
												linkTo={'https://gruppofood.typeform.com/to/COXnVJ'}
												linkTarget={'_blank'}
												layout={ButtonLayouts.BUTTON}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</WhereBlock>
				);
			}}
		</UserData>
	);
};
