import React from 'react';
import { Query } from 'react-apollo';
import { Redirect, Route } from 'react-router';
import { LayoutHandler } from '@food/ui';
import { cns } from '@food/css-manager';
import { Header, HeaderLayoutChooser, headerLayouts } from '../blocks/Header';
import { Footer, footerLayoutChooser, footerLayouts } from '../blocks/Footer';
import { filterErrors, renderErrors } from '../../utils/errors';
import { mixin } from '../../utils/theme';
import { defaultDetailQuery } from '../../utils/graphql';
import { ENTITY } from '../../utils/entities';
import { GoodDetail, goodDetailLayoutChooser } from '../entities/GoodDetail';
import { CompanyDetail, companyDetailLayoutChooser } from '../entities/CompanyDetail';
import { FairDetail, fairDetailLayoutChooser } from '../entities/FairDetail';
import { ACTIONS, generateUrl } from '../../utils/urls';
import { WhereBlock } from '../../utils/tracking';
import { TWhere } from '../../tracking-types';
import { ApolloError } from 'apollo-client';

interface ISinglePageProps {}

const nodeTypeAssociations = {
	[ENTITY.GOOD]: { component: GoodDetail, layout: goodDetailLayoutChooser },
	[ENTITY.COMPANY]: { component: CompanyDetail, layout: companyDetailLayoutChooser },
	[ENTITY.FAIR]: { component: FairDetail, layout: fairDetailLayoutChooser },
};

const mainPageLoader = <div className={mixin.mainPageContent} />;

export const SinglePage: React.FC<ISinglePageProps> = () => {
	return (
		<LayoutHandler
			layouts={{
				header: HeaderLayoutChooser,
				footer: footerLayoutChooser,
			}}
		>
			{({ header, footer }) => (
				<Route>
					{({ match: { params }, location }) => {
						let name: string = '';
						if (location.pathname.includes('suppliers/')) {
							name = 'CompanyDetail';
						} else if (location.pathname.includes('goods/')) {
							name = 'GoodDetail';
						} else if (location.pathname.includes('fairs/')) {
							name = 'FairDetail';
						}

						const where: TWhere = { type: 'Page', name };

						return (
							<WhereBlock step={where}>
								<Header layout={header as headerLayouts} />
								<Query
									query={defaultDetailQuery}
									variables={params}
									errorPolicy={'all'}
								>
									{({
										loading,
										error,
										data,
									}: {
										loading: boolean;
										error?: ApolloError;
										data: any;
									}) => {
										if (loading) {
											return mainPageLoader;
										}

										const entity = data ? (data as any).entity : null;
										let errors = [];
										if (error) {
											if (
												error.graphQLErrors &&
												error.graphQLErrors.length > 0
											) {
												error.graphQLErrors.forEach((e) => errors.push(e));
											} else if (error.networkError) {
												if (error.networkError.message === 'EAUTH_BLOCKED') {
													return mainPageLoader;
												}
												errors.push(error.networkError);
											}
										}

										// Fix per un caso strano di url corretto ma con slug inesistente su single page in SSR
										// TODO cercare soluzioni su apollo
										if (error === undefined && data && data.entity === null) {
											return <Redirect to={'/notfound'} />;
										}

										const type = entity && entity.__typename;
										if (type) {
											const expectedUrl = generateUrl(
												type,
												ACTIONS.DETAIL,
												params.slug,
											);

											if (expectedUrl !== location.pathname) {
												return <Redirect to={expectedUrl} />;
											}
										}

										const handlers =
											nodeTypeAssociations[type] ||
											nodeTypeAssociations[ENTITY.GOOD];
										const Component = handlers.component;

										return (
											<LayoutHandler
												layouts={{
													component: handlers.layout,
												}}
											>
												{({ component }) =>
													renderErrors(errors, [['entity']]) || (
														<div
															className={cns(
																mixin.mainPageContent,
																mixin.limitedWidthPage,
															)}
														>
															<Component
																layout={component}
																entity={entity}
																errors={filterErrors(
																	(error && error.graphQLErrors) || [],
																	[['entity']],
																)}
															/>
														</div>
													)
												}
											</LayoutHandler>
										);
									}}
								</Query>
								<Footer layout={footer as footerLayouts} />
							</WhereBlock>
						);
					}}
				</Route>
			)}
		</LayoutHandler>
	);
};
