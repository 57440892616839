import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import {
	Box,
	Button,
	ButtonLayouts,
	Checkbox,
	Icon,
	IconPositions,
	Modal2 as Modal,
	ModalLayouts,
} from '@food/ui';
import { cns, currySC, registerClass } from '@food/css-manager';
import { isLogged, openRenewModal } from '@food/auth';
import { IFNTheme } from '../../utils/theme';
import t from '../../utils/labels';
import { modalHandler, parseGraphqlError, sentryHandler } from '../../utils/errors';
import { client } from '../../utils/client';
import {
	Company,
	Good,
	MediaType,
	QuestionSetCreateInput,
	QuestionSetRequest,
} from '../../server-types';
import { getLastWhere, useTracking } from '../../utils/tracking';
import { TrackingContext } from '@food/tracking';
import { layouts, MediaImg, mediaImgFragment } from './MediaImg';
import Logo from '../../static/assets/logo.svg';
import { canUseDOM } from 'exenv';
import { event, ga } from 'react-ga';
import { companyLevel2Price, companyLevelStr } from '../../utils/misc';
// import TagManager from 'react-gtm-module';

type fields =
	| 'question'
	| 'firstName'
	| 'lastName'
	| 'email'
	| 'country'
	| 'company'
	| 'newsletter';

type FormErrors = {
	[K in fields]: boolean;
};

type FormValues = {
	[K in fields]?: string;
};

const initialFormErrors: FormErrors = {
	question: false,
	firstName: false,
	lastName: false,
	email: false,
	country: false,
	company: false,
	newsletter: false,
};

const userLocalStorageKey = 'italianfood.net/contactData';

const validateEmail = (email: string): boolean => {
	const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return re.test(
		String(email)
			.trim()
			.toLowerCase(),
	);
};

export const questionSetCreationModalFragment = gql`
	fragment QuestionSetCreationModalFragment on Node {
		id
		... on Company {
			logoMedia {
				...MediaImgFragment
			}
		}
		... on Good {
			Brand {
				Company {
					id
					logoMedia {
						...MediaImgFragment
					}
				}
			}
		}
	}
	${mediaImgFragment}
`;

export interface IQuestionSetOpenerProps {
	opener: (buttonText: string, request?: QuestionSetRequest) => void;
}

interface IQuestionSetCreationModalProps {
	company: Company;
	good: Good | null;
	openComponent: React.FC<IQuestionSetOpenerProps>;
}

const modalClass = registerClass(
	(t: IFNTheme, sc) => `
	box-shadow: none !important; // TODO chiedere ad Ale
	
	strong {
		font-weight: ${t.font.weights.bold};
	}
	
	input, textarea {
		border: 1px solid ${t.colors.grey.plain};
	}
	
	textarea {
		resize: none;
	}
	
	input {
		font-family: monospace;
		padding: ${t.ratios.xs / 2}em;
		
		&::placeholder {
			color: ${t.colors.grey.plain};
		}
	}
	
	label, .${sc('title')}, div.${sc('error-message')} {
		margin-bottom: ${t.ratios.xs / 4}rem;
	}
	
	textarea {
		width: 100%;
		height: 10vh;
		min-height: 1rem;
		padding: ${t.ratios.xs / 2}rem;
		font-size: ${t.ratios.l}em;
	}
	
	.${sc('buttons')} {
		display: flex;
		justify-content: center;
		font-size: ${t.ratios.l}em;
		
		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}
	
	.${sc('question-block')} {
		display: flex;
		flex-direction: row;
		margin-bottom: 1rem;
	}
	
	.${sc('question-container')} {
		flex: 70%;
	}
	
	.${sc('check-container')} {
		flex: 30%;
	}
	
	.${sc('registration-data')} {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		flex-wrap: wrap;
		
		> .${sc('form-group')} {
			flex-basis: 100%;
			flex: 50%;
			display: flex;
			flex-direction: column;
			margin-bottom: 1rem;
			
			&:nth-child(odd) {
				padding-right: .5rem;
			}
			
			&:nth-child(even) {
				padding-left: .5rem;
			}
			
			&.${sc('required-label')} {
				// per pareggiare con gli input. posso trovare un modo migliore?
				padding-bottom: calc(1px + ${t.ratios.xs / 2}rem);
				justify-content: flex-end;
			}
		}
	}
	
	.${sc('error')} {
	
		input, textarea {
			border-color:${t.colors.danger.plain};
		}
		
		.${sc('check')} > span:first-child {
			color: ${t.colors.danger.plain};
		}
	}
	
	div.${sc('error-message')} {
		color: ${t.colors.danger.plain};
	}
	
	textarea.${sc('error')} {
		border-color: ${t.colors.danger.plain};
	}
	
	.${sc('check')} {
		padding: ${t.ratios.xs / 4}rem; 0 ${t.ratios.xs / 4}rem;
	}
	
	.${sc('header-block')} {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	
		figure {
			max-width: 8rem;
			margin-right: ${t.ratios.s}rem;
		}
	}
	
	.${sc('company-block')} {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 1rem;
	}
	
	.${sc('company-name')} {
		display: block;
		font-size: ${t.ratios.xl}em;
	}
	
	.${sc('login-btn')} {
		font-weight: ${t.font.weights.bold};
		text-decoration: underline;
	}
	
	.${sc('login-block')} {
		margin-top: ${t.ratios.xl / 2}rem;
	}
	
	.${sc('footer')} {
		font-size: ${t.ratios.s}em;
		text-align: center;
	}
	
	.${sc('consent')} > button {
		padding: 0;
	}
	
	.${sc('underline')}, .${sc('underline')} > span {
		font-weight: ${t.font.weights.semibold};
		text-decoration: underline;
	}
	
	.${sc('success-block')} {
		.${sc('message')} {
			text-align: center;
			font-size: ${t.ratios.xl}em;
			margin-bottom: 1rem;
			
			// so che la traduzione conterra' dei tag <p>, applico lo stile a quelli per non dividere il testo
			> p {
				line-height: ${t.ratios.l}em;
				margin-bottom: .5em;
				
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		
		.${sc('logo-container')} {
			display: flex;
			justify-content: center;
			
			> img {
				max-width: 15rem;
				margin-bottom: 1rem;
			}
		}
	}
	
	.${sc('login-block-mobile')} {
		margin-bottom: ${t.ratios.xl}rem;
		margin-top: ${t.ratios.xl}rem;
		text-align: center;
	}
	
	&.${sc('layout', ModalLayouts.Compact)} {
	
		textarea {
			height: 20vh;
		}
		
		.${sc('question-block')} {
			flex-direction: column;
		}
		
		.${sc('question-container')} {
			flex: 100%;
		}
		
		.${sc('check-container')} {
			flex: 100%;
			margin-bottom: 1rem;
		}
		
		.${sc('form-group')} {
			flex: 100%;
			
			&:nth-child(odd), &:nth-child(even) {
				padding: 0;
			}
		}
		
		.${sc('required-label')} {
			padding-bottom: 0;
			
			> div {
				text-align: center;
			}
		}
	}
	
	.${sc('textarea-label')} {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.${sc('italic')} {
		font-style: italic;
	}
`,
);

const createQuestionSetMutation = gql`
	mutation QuestionSetCreate($values: QuestionSetCreateInput!) {
		questionSetCreate(values: $values) {
			id
			sentDate
			question1
		}
	}
`;

const scm = currySC(modalClass);
const noteMaxLength = 150;

const initialState = () => ({
	open: false,
	success: false,
	loading: false,
	noteText: '',
});

const intrestingTargets = ['CompanyDetail', 'GoodDetail'];

export const QuestionSetCreationModal: React.FC<IQuestionSetCreationModalProps> = ({
	company,
	good,
	openComponent: Action,
}) => {
	const [state, setState] = useState(initialState());

	const initialFormValues = canUseDOM
		? localStorage.getItem(userLocalStorageKey)
		: false;
	const [formValues, setFormValues] = useState<FormValues>(
		initialFormValues ? JSON.parse(initialFormValues) : { newsletter: 'yes' },
	);
	const [requests, setRequests] = useState<QuestionSetRequest[]>([]);
	const [errors, setErrors] = useState<FormErrors>({ ...initialFormErrors });
	const logged = isLogged();
	const track = useTracking();
	const trackingContext = useContext(TrackingContext);
	const where = getLastWhere(
		trackingContext.where,
		(t) => intrestingTargets.indexOf(t.type) !== -1,
	);
	const saveUserData = useCallback((values) => {
		if (canUseDOM) {
			localStorage.setItem(userLocalStorageKey, JSON.stringify(values));
		}
	}, []);
	const { success } = state;

	useEffect(() => {
		saveUserData(formValues);
	}, [formValues]);

	const open = useCallback(
		(buttonText, requests?: QuestionSetRequest) => {
			// la funzione di open si deve occupare di inizializzare lo stato della modal e di
			// inviare diversi eventi

			// evento tracking di apertura
			track({
				type: 'open_questionset_modal',
				source: where,
				requests: requests ? [requests] : [],
			});

			// evento analytics di apertura
			(window as any).gtag('event', 'add_to_cart', {
				items: [
					{
						id: company.id,
						name: company.name,
						brand: company.name,
						variant: 'Supplier',
						category: companyLevelStr(company.currentCompanyLevels),
						price: companyLevel2Price(company.currentCompanyLevels),
						quantity: 1,
					},
				],
			});

			/*TagManager.dataLayer({
				event: 'addToCart',
				ecommerce: {
					add: {
						products: [
							{
								name: company.name,
								id: company.id,
								price: 0,
								category: companyLevelStr(company.currentCompanyLevels),
								quantity: 1,
							},
						],
					},
				},
			});*/

			// resetto gli errori. Uso lo spread operator cosi' che non prenda come input il puntatore dell'oggeto,
			// creando malfunzionamenti
			setErrors({ ...initialFormErrors });

			setRequests(requests ? [requests] : []);
			setState({
				open: true,
				success: false,
				loading: false,
				noteText: '',
			});
		},
		[setState],
	);

	const close = useCallback(
		(message_sent: boolean) => {
			if (!message_sent) {
				// nel caso il messaggio non sia stato mandato avviso analytics del fatto
				// evento analytics di apertura

				ga('event', 'remove_from_cart', {
					items: [
						{
							id: company.id,
							name: company.name,
							brand: company.name,
							variant: 'Supplier',
							category: companyLevelStr(company.currentCompanyLevels),
							price: companyLevel2Price(company.currentCompanyLevels),
							quantity: 1,
						},
					],
				});

				/*TagManager.dataLayer({
					event: 'removeFromCart',
					ecommerce: {
						remove: {
							products: [
								{
									name: company.name,
									id: company.id,
									price: 0,
									category: companyLevelStr(company.currentCompanyLevels),
									quantity: 1,
								},
							],
						},
					},
				});*/
			}

			track({ type: 'close_questionset_modal', requests });
			setState({ open: false, success: false, loading: false, noteText: '' });
		},
		[setState],
	);

	const validate = useCallback(() => {
		let hasErrors = false;
		const finalErrors = { ...initialFormErrors };

		// gestisco i casi semplici tutti assieme
		if (!logged) {
			const independentFields: ReadonlyArray<fields> = [
				'firstName',
				'lastName',
				'email',
				'company',
				'country',
			];
			independentFields.forEach(
				(fieldName) => {
					if (!formValues[fieldName]) {
						finalErrors[fieldName] = true;
						hasErrors = true;
					}
				},
				[logged],
			);

			// devo fare un controllo speciale per il caso email, avendo una forma fissata
			if (!validateEmail(formValues.email as string)) {
				finalErrors.email = true;
				hasErrors = true;
			}
		}

		// invece il blocco iniziale richiede piu' logica
		const primaryBlockIsEmpty = requests.length === 0 && !state.noteText;

		if (primaryBlockIsEmpty) {
			finalErrors.question = true;
			hasErrors = true;
		}

		if (hasErrors) {
			setErrors(finalErrors);
		}
		return hasErrors;
	}, [state, formValues, setErrors, requests]);

	const save = useCallback(async () => {
		const { noteText } = state;
		const hasErrors = validate();
		if (hasErrors) {
			return;
		}
		const goods = good ? [{ id: good.id }] : [];
		const values: QuestionSetCreateInput = {
			question1: noteText,
			answerSets: [],
			answerSets__inline: [{ Company: { id: company.id }, isSeen: false, goods }],
			requests: requests,
			userNewsletterAccepted: formValues.newsletter === 'yes',
		};

		if (!logged) {
			values.userFirstName = formValues.firstName;
			values.userLastName = formValues.lastName;
			values.userCompanyName = formValues.company;
			values.userEmail = formValues.email;
			values.userCountry = formValues.country;
		}

		try {
			const result = await client.mutate({
				mutation: createQuestionSetMutation,
				variables: { values },
			});


			// segnalo l'evento a tracking
			track({ type: 'send_questionset', source: where, requests });

			// segnalo l'evento ad analytics
			event({
				category: 'User',
				action: 'Send contact request',
			});

			//pageview('/virtual/contact-request-sent');

			// evento analytics di invio nota
			(window as any).gtag('event', 'purchase', {
				transaction_id: result.data.questionSetCreate.id,
				items: [
					{
						id: company.id,
						name: company.name,
						brand: company.name,
						variant: 'Supplier',
						category: companyLevelStr(company.currentCompanyLevels),
						price: companyLevel2Price(company.currentCompanyLevels),
						quantity: 1,
					},
				],
			});

			setState({
				...state,
				success: true,
			});
		} catch (e) {
			const errors = parseGraphqlError(e);
			modalHandler(errors);
			sentryHandler(errors);
		}
	}, [company, state, validate, requests]);

	const write = useCallback(
		(event) => {
			if (errors.question) {
				setErrors({ ...errors, question: false });
			}
			setState({
				...state,
				noteText: event.target.value,
			});
		},
		[state.open, errors],
	);

	const registrationFormUpdate = useCallback(
		(fieldName: fields) => (event: ChangeEvent<HTMLInputElement>): void => {
			const val = event.target.value;
			if (errors[fieldName]) {
				setErrors({ ...errors, [fieldName]: false });
			}
			setFormValues((v) => ({
				...v,
				[fieldName]: val,
			}));
		},
		[errors],
	);

	// "isLogged" lo prendo da localstorage in quanto mi serve in alcune callback, definite fuori da "UserData"
	// se avessi un hook probabilmente questo non succederebbe
	return (
		<>
			<Action opener={open} />
			<Modal open={state.open}>
				{({ layout }) => {
					return (
						<Box
							title={t`ask the supplier`}
							action={() => (
								<Icon name={'close'} onClick={() => close(false)} />
							)}
							className={cns(modalClass, scm('layout', layout))}
						>
							{success && (
								<div className={scm('success-block')}>
									<div className={scm('logo-container')}>
										<img src={Logo} alt={'logo italianfood.net'} />
									</div>
									<div
										className={scm('message')}
										dangerouslySetInnerHTML={{
											__html: t`questionset creation success message html`,
										}}
									/>
									<div className={scm('buttons')}>
										<Button
											label={t`Close`}
											type={'success'}
											onClick={() => close(true)}
										/>
									</div>
								</div>
							)}
							{!success && (
								<form>
									<div className={scm('header-block')}>
										{layout !== ModalLayouts.Compact && (
											<div className={scm('company-block')}>
												<MediaImg
													layout={layouts.SMALL}
													type={MediaType.Logo}
													entity={company.logoMedia}
													alt={company.name + ' logo'}
													naturalWidth={true}
												/>
												<div className={scm('company-info')}>
													<span>{t`company name introduction`}</span>
													<span className={scm('company-name')}>
														{company.name}
													</span>
												</div>
											</div>
										)}
									</div>
									<div
										className={cns(
											scm('question-block'),
											errors.question && scm('error'),
										)}
									>
										<div className={scm('check-container')}>
											<div className={scm('title')}>{t`Requests`}:</div>
											{Object.keys(QuestionSetRequest).map((v) => {
												const voice = QuestionSetRequest[v];
												const checked = requests.indexOf(voice) !== -1;
												return (
													<Checkbox
														key={v}
														label={t(v)}
														checked={checked}
														className={scm('check')}
														onChange={() => {
															if (errors.question) {
																setErrors({
																	...errors,
																	question: false,
																});
															}
															setRequests(
																checked
																	? requests.filter((c) => c !== voice)
																	: [...requests, voice],
															);
														}}
													/>
												);
											})}
										</div>
										<div className={scm('question-container')}>
											<div
												className={cns(
													scm('textarea-label'),
													state.noteText.length > noteMaxLength &&
														scm('error-message'),
												)}
											>
												<div className={scm('title')}>{t`Message`}:</div>
												<div className={scm('italic')}>
													{state.noteText.length}/{noteMaxLength}
												</div>
											</div>
											<textarea
												className={cns(
													scm('textarea'),
													state.noteText.length > noteMaxLength &&
														scm('error'),
												)}
												value={state.noteText}
												onChange={write}
												placeholder={t`Write your question here...`}
											/>
										</div>
									</div>

									{!logged && (
										<>
											<div className={scm('login-block-mobile')}>
												{t`login intro text`}{' '}
												<Button
													label={t`Sign in`}
													onClick={() => openRenewModal()}
													layout={ButtonLayouts.LINK}
													className={scm('login-btn')}
												/>
											</div>
											<div className={scm('registration-data')}>
												<div
													className={cns(
														scm('form-group'),
														errors.firstName && scm('error'),
													)}
												>
													<label htmlFor={'first-name'}>
														{t`Firstname`}:
													</label>
													<input
														id={'first-name'}
														type={'text'}
														placeholder={''}
														value={formValues.firstName}
														onChange={registrationFormUpdate('firstName')}
													/>
												</div>
												<div
													className={cns(
														scm('form-group'),
														errors.lastName && scm('error'),
													)}
												>
													<label htmlFor={'last-name'}>{t`Lastname`}:</label>
													<input
														id={'last-name'}
														type={'text'}
														placeholder={''}
														value={formValues.lastName}
														onChange={registrationFormUpdate('lastName')}
													/>
												</div>
												<div
													className={cns(
														scm('form-group'),
														errors.email && scm('error'),
													)}
												>
													<label htmlFor={'email'}>{t`Email`}:</label>
													<input
														id={'email'}
														type={'email'}
														placeholder={''}
														value={formValues.email}
														onChange={registrationFormUpdate('email')}
													/>
												</div>
												<div
													className={cns(
														scm('form-group'),
														errors.company && scm('error'),
													)}
												>
													<label htmlFor={'company'}>Company:</label>
													<input
														id={'company'}
														type={'text'}
														placeholder={''}
														value={formValues.company}
														onChange={registrationFormUpdate('company')}
													/>
												</div>
												<div
													className={cns(
														scm('form-group'),
														errors.country && scm('error'),
													)}
												>
													<label htmlFor={'country'}>{t`Country`}:</label>
													<input
														id={'country'}
														type={'text'}
														placeholder={''}
														value={formValues.country}
														onChange={registrationFormUpdate('country')}
													/>
												</div>
												<div
													className={cns(
														scm('form-group'),
														scm('required-label'),
													)}
												>
													<div>
														{t`Every field is`}{' '}
														<strong>{t`required`}</strong>
													</div>
												</div>
												<div
													className={cns(scm('form-group'), scm('consent'))}
												>
													<Checkbox
														label={t`commercial newsletter consent`}
														checked={formValues.newsletter === 'yes'}
														onChange={(c) =>
															setFormValues({
																...formValues,
																newsletter: c ? 'yes' : 'no',
															})
														}
													/>
												</div>
											</div>
										</>
									)}

									<div className={scm('buttons')}>
										<Button
											label={t`Send`}
											type={'success'}
											onClick={save}
											icon={'send'}
											iconPos={IconPositions.RIGHT}
											disabled={
												state.noteText.length > noteMaxLength ||
												Object.values(errors).reduce((t, v) => t || v, false)
											}
										/>
									</div>

									{!logged && (
										<div className={scm('footer')}>
											By clicking on SEND, you consent to the communication of
											your data to the companies for which you raise a request
											in order to receive a response (
											<a
												className={scm('underline')}
												href={'/privacy-policy'}
												target={'_blank'}
											>
												Privacy Policy
											</a>
											). The service is reserved to registered users only.
											Please{' '}
											<Button
												className={scm('underline')}
												layout={ButtonLayouts.LINK}
												label={t`Sign in`}
												onClick={() => openRenewModal()}
											/>{' '}
											or enter your details to subscribe to the site.
										</div>
									)}
								</form>
							)}
						</Box>
					);
				}}
			</Modal>
		</>
	);
};
