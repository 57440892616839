import React, { useContext } from 'react';
import { cns, registerClass, currySC } from '@food/css-manager';
import { IFNTheme, mixin } from '../../utils/theme';
import { loadable, LoadableDirection } from '../../utils/loadable';
import t from '../../utils/labels';
import { renderContext } from '../../utils/context/renderContext';
import { GenericHead } from '../head/GenericHead';

export enum IAdvertiseLayouts {
	UNO = 'UNO',
}

interface AdvertiseProps {
	className?: string;
	layout?: IAdvertiseLayouts;
}

const styleClass = registerClass(
	(theme: IFNTheme, s) => `
	.${s('title')} {
		font-weight: ${theme.font.weights.semibold};
		margin-top: ${theme.ratios.s}rem;
		margin-bottom: ${theme.ratios.xs}rem;
		display: block;
	}
`,
);

const sc = currySC(styleClass);

export const Advertise: React.FC<AdvertiseProps> = ({ className, layout }) => {
	const { isBot } = useContext(renderContext);

	return (
		<>
			<GenericHead title={t`Contact Us`} />
			<div
				className={cns(
					mixin.textPage,
					styleClass,
					layout && sc('layout', layout),
					mixin.limitedWidthPage,
					loadable(isBot, LoadableDirection.BOTTOM),
					className,
				)}
			>
				<h1>
					<span>Contact Us</span>
				</h1>
				<div>
					<span className={sc('title')}>Subscriptions</span>
					<a href="mailto:digital@italianfood.net">digital@italianfood.net</a>
					<span className={sc('title')}>Advertising</span>
					Tel: 0521.388570
					<br />
					<a href="mailto:adv@gruppofood.com">adv@gruppofood.com</a>
				</div>
			</div>
		</>
	);
};
