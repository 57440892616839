import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {IFNTheme} from '../../utils/theme';
import {layouts as MediaLayouts, MediaImg,} from '../../components/entities/MediaImg';
import {cns, currySC, registerClass} from '@food/css-manager';
import {useTracking} from '../../utils/tracking';
import {EditorialSection, MediaType} from '../../server-types';
import {SearchTypes} from '../../utils/frequentSearch';
import {ACTIONS, generateUrl} from '../../utils/urls';
import {ENTITY} from '../../utils/entities';
import {stringify} from 'querystring';

interface IEditorialSectionSelectorProps {
	entities: any[];
	className?: string;
	layout?: EditorialSectionSelectorLayouts;
	searchType: SearchTypes;
	closeHandler?: Function;
}

export enum EditorialSectionSelectorLayouts {
	STANDARD = 'STANDARD', // Lungo elenco di icona con sotto nome che va su 1-2 righe a seconda dello spazio
	COMPACT = 'COMPACT', // Lungo elenco di icona che va su 1-2 righe a seconda dello spazio
}

const styleClass = registerClass((t: IFNTheme, sc) => {
	const blockIconSizeFactor = 4;
	const blockLabelSizeFactor = 6;
	const blockLabelAdditionalHalfSpaceFactor =
		(blockLabelSizeFactor - blockIconSizeFactor) / 2;
	return `
	padding: .5rem;
	display: block;
	flex-flow: row wrap;
	justify-content: center;

	.${sc('blockContainer')} {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		flex: 1 1 auto;
	}
	
	.${sc('spacer')},
	.${sc('block')} {
		padding: .5em;
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 1 1 auto;
	}
	
	.${sc('spacer')} {
		height: 0;
		padding-top: 0;
		padding-bottom: 0;
	}
	
	.${sc('block')}:not(:hover) {
		.${sc('label')} {
			color: ${t.colors.ink} !important;
		}
	}

	.${sc('logo')} {
		width: ${blockIconSizeFactor}em;
	}
	
	.${sc('label')} {
		text-align: center;
		margin: 0.5em 0 0 0;
		line-height: ${t.ratios.l};
		flex: 1 1 auto;
		width: 100%; /* IE fix */
		display: flex;
		justify-content: center;
	}
	
	&.${sc('layout', 'STANDARD')} {
	
		.${sc('innerBlock')} {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		
		.${sc('innerBlock')},
		.${sc('innerSpacer')} {
			width: ${blockIconSizeFactor + 2 * blockLabelAdditionalHalfSpaceFactor}em;
		}
	}
	
	&.${sc('layout', 'COMPACT')} {
		.${sc('label')} {
			display: none;
		}
		.${sc('innerBlock')},
		.${sc('innerSpacer')} {
			width: ${blockIconSizeFactor}em;
		}
		.${sc('blockContainer')} {
			flex-flow: row wrap;
			justify-content: center;
		}
	}
`;
});

const sc = currySC(styleClass);

const getLinkTo = (searchType: SearchTypes, es: EditorialSection) => ({
	pathname: generateUrl(
		searchType === SearchTypes.GOOD ? ENTITY.GOOD : ENTITY.COMPANY,
		ACTIONS.LIST,
	),
	search: stringify({ goodClasses: es.id }),
});

export const EditorialSectionSelector: React.FC<
	IEditorialSectionSelectorProps
> = ({
	entities: items,
	className,
	layout = EditorialSectionSelectorLayouts.STANDARD,
	searchType,
	closeHandler = () => {}
}) => {
	const track = useTracking();
	const spacerCount =
		layout === EditorialSectionSelectorLayouts.STANDARD ? items.length % 2 : 5; // CHECK
	const trackSearch = useCallback(
		(type: SearchTypes, item: EditorialSection) =>
			track({
				type: 'do_search',
				filterStatus: { goodClasses: item.id },
				target: type === SearchTypes.COMPANY ? 'CompanyDirectory' : 'GoodDirectory',
			}),
		[track],
	);

	return (
		<div className={cns(className, styleClass, sc('layout', layout))}>
			{layout === EditorialSectionSelectorLayouts.STANDARD && (
				<div className={sc('blockContainer')}>
					{items.map(
						(item, index) =>
							index < items.length / 2 && (
								<Link
									key={item.id}
									className={sc('block')}
									to={getLinkTo(searchType, item)}
									onClick={() => {
										closeHandler();
										trackSearch(searchType, item)}
									}
								>
									<span className={sc('innerBlock')}>
										<MediaImg
											type={MediaType.Icon}
											entity={item.iconMedia}
											className={sc('logo')}
											layout={MediaLayouts.ICON}
											alt={`Editorial Section "${item.name}" icon`}
										/>
										{layout === EditorialSectionSelectorLayouts.STANDARD && (
											<span className={sc('label')}>{item.name}</span>
										)}
									</span>
								</Link>
							),
					)}
				</div>
			)}
			<div className={sc('blockContainer')}>
				{items.map(
					(item, index) =>
						(layout === EditorialSectionSelectorLayouts.COMPACT ||
							index >= items.length / 2) && (
							<Link
								key={item.id}
								className={sc('block')}
								to={getLinkTo(searchType, item)}
								onClick={() => {
									closeHandler();
									trackSearch(searchType, item)}
								}
							>
								<span className={sc('innerBlock')}>
									{item.iconMedia && (
										<MediaImg
											type={MediaType.Icon}
											entity={item.iconMedia}
											className={sc('logo')}
											layout={MediaLayouts.ICON}
											alt={`Editorial Section "${item.name}" icon`}
										/>
									)}
									{layout === EditorialSectionSelectorLayouts.STANDARD && (
										<span className={sc('label')}>{item.name}</span>
									)}
								</span>
							</Link>
						),
				)}
				{new Array(spacerCount).fill('spacer').map((k, idx) => (
					<div key={idx} className={sc('spacer')}>
						<span className={sc('innerSpacer')} />
					</div>
				))}
			</div>
		</div>
	);
};
