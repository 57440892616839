import React, { useState } from 'react';
import { Box, Icon, Modal2 as Modal, Select } from '@food/ui';
import { Query } from 'react-apollo';
import { nodes, offsetToCursor } from '../utils/misc';
import { Link } from 'react-router-dom';
import { sentryHandler } from '../utils/errors';
import { addWhere, useTracking } from '../utils/tracking';
import { TWhere } from '../tracking-types';
import { ENTITY } from '../utils/entities';
import { ACTIONS, generateUrl } from '../utils/urls';
import { ApolloError } from 'apollo-client';

interface ProfileListModalProps {
	query: any;
	filter?: any;
	title: string;
	totalExtractor: (queryResult: any) => number;
	open: boolean;
	toggle: () => void;
	type: ENTITY.COMPANY | ENTITY.GOOD;
}

function totalToPageArray(total: number, perPage: number) {
	const maxPage = Math.floor(total / perPage) + 1;
	return new Array(maxPage).fill(null);
}

export const ProfileListModal: React.FC<ProfileListModalProps> = ({
	title,
	filter,
	query,
	open,
	totalExtractor,
	toggle,
	type,
}) => {
	const track = useTracking();
	const where: TWhere = {
		type: 'Block',
		name:
			type === ENTITY.GOOD
				? 'Good frequent searches modal'
				: 'Company frequent searches modal',
	};
	const [page, setPage] = useState(1);
	const perPage = 50;

	return (
		<Modal open={open} closeHandler={toggle}>
			{() => (
				<Box title={title} action={() => <Icon name={'close'} onClick={toggle} />}>
					<Query
						query={query}
						variables={{
							filter,
							first: perPage,
							after: offsetToCursor(perPage * (page - 1)),
						}}
					>
						{({
							error,
							loading,
							data,
						}: {
							loading: boolean;
							error?: ApolloError;
							data: any;
						}) => {
							if (error) {
								console.error(error);
								sentryHandler([error]);
								return <div>Error!</div>; // TODO handle
							}

							if (loading) {
								return <div>Loading..</div>; // TODO handle
							}

							const fs = nodes(data.frequentSearchs);

							return (
								<div>
									<Select
										selected={page.toString()}
										options={totalToPageArray(
											totalExtractor(data),
											perPage,
										).map((c, i) => ({
											label: `${i * perPage}-${Math.min(
												(i + 1) * perPage,
												totalExtractor(data),
											)}`,
											value: (i + 1).toString(),
										}))}
										onChange={(e) => setPage(Number(e))}
									/>
									<ul>
										{fs.map((frequentSearch) => {
											const link =
												generateUrl(type, ACTIONS.LIST) +
												'?FrequentSearch=' +
												encodeURIComponent(frequentSearch.id);
											return (
												<li key={link}>
													<Link
														to={link}
														target={'_blank'}
														onClick={() =>
															track(
																{
																	type: 'do_search',
																	target:
																		type === ENTITY.GOOD
																			? 'GoodDirectory'
																			: 'CompanyDirectory',
																	filterStatus: {
																		FrequentSearches: frequentSearch.id,
																	},
																},
																addWhere(where),
															)
														}
													>
														{frequentSearch.name}
													</Link>
												</li>
											);
										})}
									</ul>
								</div>
							);
						}}
					</Query>
				</Box>
			)}
		</Modal>
	);
};
