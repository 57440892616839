import React, { CSSProperties } from 'react';
import { registerClass, subClass, cns } from '@food/css-manager';
import { IFNTheme } from '../../utils/theme';

export enum Layouts {
	FULLWIDTH = 'FULLWIDTH',
	TWO_COL = 'TWO_COL',
	ONE_COL = 'ONE_COL',
}

export interface SidedBoxInterface {
	className?: string;
	header1?: any;
	header2?: any;
	footer1?: any;
	footer2?: any;
	layout?: Layouts;
	headerColor?: string;
	footerColor?: string;
	underlineColor?: string;
	style?: CSSProperties;
}

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	display: flex;
	flex-flow: row nowrap;
	background: ${t.colors.white};
	background-clip: content-box;

	> header,
	> footer {
		padding: ${t.ratios.xl / 2}rem ${t.ratios.xl}rem;
		flex: 0 0 auto;
	}
	> .${sc('content')} {
		padding: ${t.ratios.xl / 2}rem ${t.ratios.xl}rem;
		flex: 1 1 auto;
	}
	&.${sc('has-underline')} {
		padding-bottom: .25rem;
		border-bottom-width: .5rem;
		border-bottom-style: solid;
	}
	&:not(.${sc('has-underline')}) {
		border-radius: ${t.radius}rem;
		border-width: 1px;
		border-style: solid;
	}

	&.${sc('layout', 'FULLWIDTH')} {
		>.${sc('content')} {
			width: 1rem; // CHECK
		}
		
		.${sc('header')} {
			//width: ${t.ratios.xl * 15}rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		
	}
	&.${sc('layout', 'TWO_COL')} {
		flex-direction: column;
		> header,
		> footer {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			> * {
				flex: 1 1 1rem;
			}
		}
	}
	&.${sc('layout', 'ONE_COL')} {
		flex-direction: column;
		> .${sc('content')} {
			padding: ${t.ratios.xl / 2}rem 0;
		}
	}
`,
);

// IMPORTANT - Posizionato dopo style per avere priorità sulle sue definizioni
export const headerStyles = {
	bigIconAndText: registerClass(
		(t: IFNTheme, sc) => `
			> header,
			> footer {
			
				.${sc('icon')} {
					font-size: ${t.columnSize}px;
					width: 100%;
					color: ${t.colors.grey.light};
					text-align: center;
				}
				
				.${sc('title')} {
					margin-top: ${t.ratios.l}rem;
					margin-bottom: ${t.ratios.s}rem;
					font-size: ${t.ratios.l}rem;
					font-weight: ${t.font.weights.bold};
					text-transform: uppercase;
				}
				
				.${sc('title-only')} {
					margin-top: ${t.ratios.l}rem;
					margin-bottom: ${t.ratios.s}rem;
					font-size: ${t.ratios.xl * 2}rem;
					font-weight: ${t.font.weights.bold};
					text-transform: uppercase;
					color: ${t.colors.grey.light};
				}
				
				.${sc('link')} {
					margin-top: ${t.ratios.l}rem;
					text-align: right;
					text-transform: uppercase;
				}
				
				.${sc('ink-text')} {
					color: ${t.colors.ink};
				}
			}
		`,
	),
	similRelatedCard: registerClass(
		(t: IFNTheme, sc) => `
			> header,
			> footer {
				padding: 0;
				text-align: center;
				
				> * {
					margin-top: ${t.ratios.l / 2}rem;

					&:first-child {
						margin-top: 0;
					}
				}
				
				.${sc('title')} {
					font-size: ${t.ratios.l}rem;
					font-weight: ${t.font.weights.bold};
					margin-bottom: ${t.ratios.l / 2}rem;
				}
				.${sc('value')} {
					font-weight: ${t.font.weights.semibold};
				}
				.${sc('link')} {
					margin-top: ${t.ratios.l}rem;
					text-align: right;
					text-transform: uppercase;
				}
			}
			
			.${sc('img-container')} {
				padding: ${t.ratios.xl}rem;
				background: ${t.colors.white};
				border-radius: ${t.radius}rem;
			}
		`,
	),
	imageIcon: registerClass((t: IFNTheme) => `
		padding: ${t.ratios.xl}rem;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		
		> img {
			width: 100%;
		}
		
	`)
};

const sc = (...e: string[]) => subClass(styleClass, ...e);

export const SidedBox: React.FC<SidedBoxInterface> = ({
	className,
	header1,
	header2,
	children,
	footer1,
	footer2,
	layout = Layouts.FULLWIDTH,
	headerColor,
	footerColor,
	underlineColor,
	style: styleObj,
}) => (
	<div
		className={cns(
			className,
			styleClass,
			sc('layout', layout),
			underlineColor && sc('has-underline'),
		)}
		style={{ borderColor: underlineColor || headerColor || footerColor, ...styleObj }}
	>
		{(header1 || header2) && (
			<header className={sc('header')} style={{ backgroundColor: headerColor }}>
				{header1 && header1()}
				{header2 && header2()}
			</header>
		)}
		<div className={sc('content')}>{children}</div>
		{(footer1 || footer2) && (
			<footer style={{ backgroundColor: footerColor }}>
				{footer1 && footer1()}
				{footer2 && footer2()}
			</footer>
		)}
	</div>
);
