export enum ENTITY {
	COMPANY = 'Company',
	GOOD = 'Good',
	BANNER = 'Banner',
	WIDGET = 'Widget',
	CANDIDACY = 'Candidacy',
	AWARD = 'Award',
	USER = 'User',
	EDITORIAL_SECTION = 'EditorialSection',
	RETAILER = 'Retailer',
	NEWS = 'News',
	TEAM = 'Team',
	EMAIL_DOMAIN = 'EmailDomain',
	FAIR_PARTICIPATION = 'FairParticipation',
	FAIR = 'Fair',
	GEO_INDICATION = 'GeoIndication',
	COMPANY_GROUP = 'CompanyGroup',
	HTML_TEMPLATE = 'HtmlTemplate',
	EU_CATEGORY = 'EuCategory',
	SERVICE = 'Service',
	NEWS_FORMAT = 'NewsFormat',
	EDITORIAL_TAG = 'EditorialTag',
	OBJECT_HISTORY = 'ObjectHistory',
	BRANCH = 'Branch',
	COMPANY_LEVEL = 'CompanyLevel',
	COMPANY_YEAR = 'CompanyYear',
	HIGHLIGHTED_GOOD = 'HighlightedGood',
	GOOD_FORMAT = 'GoodFormat',
	AWARD_CATEGORY = 'AwardCategory',
	AWARD_INVITED_USER = 'AwardInvitedUser',
	AWARD_INVITED_COMPANY = 'AwardInvitedCompany',
	COMPANY_CERTIFICATION = 'CompanyCertification',
	BRAND = 'Brand',
	AWARD_RESULT = 'AwardResult',
	GOOD_CLASS = 'GoodClass',
	MANAGED_BY_USER = 'ManagedByUser',
	GOOD_FEATURE_CERTIFICATION = 'GoodFeatureCertification',
	NEWS_CITATION = 'NewsCitation',
	CANDIDACY_RATING = 'CandidacyRating',
	TERMS_OF_SERVICE = 'ToS',
	DISTRIBUTION_CHANNEL = 'DistributionChannel',
	FREQUENT_SEARCH = 'FrequentSearch',
}
