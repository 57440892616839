import React from 'react';
import { Query } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import { parse, stringify } from 'querystring';
import { cns, currySC, registerClass } from '@food/css-manager';
import { openRenewModal, UserData } from '@food/auth';
import { IFNTheme, mixin, theme } from '../utils/theme';
import { DefaultSetter, nodes, offsetToCursorDirectory } from '../utils/misc';
import CONFIG from '../static/config';
import { SearchTypes } from '../utils/frequentSearch';
import { DirectoryFilter, DirectoryFilterLayouts } from './DirectoryFilter';
import { ENTITY } from '../utils/entities';
import { UserLists } from './UserLists';
import { renderErrors } from '../utils/errors';
import { LoginMessage } from '../utils/directory';
import { ForbiddenPageError } from './ForbiddenPageError';
import { ForbiddenFiltersError } from './ForbiddenFiltersError';
import { PagesList } from './UI/PagesList';
import { addWhere, useTracking } from '../utils/tracking';
import { TTrackAction } from '../tracking-types';
import * as Sentry from '@sentry/browser';
import { useRouter } from '../utils/hooks';
import { companyDirectoryWhere, goodDirectoryWhere } from '../utils/where';
import { Brand, Company, Good } from '../server-types';
import { ApolloError } from 'apollo-client';

export enum DirectoryLayouts {
	FE_FIVE_COL = 'FE_FIVE_COL',
	FE_FOUR_COL = 'FE_FOUR_COL',
	FE_THREE_COL = 'FE_THREE_COL',
	FE_TWO_COL = 'FE_TWO_COL',
	TWO_COL = 'TWO_COL',
}

export enum DirectoryViewLayouts {
	TWO_COL = 'TWO_COL',
	THREE_COL = 'THREE_COL',
	FOUR_COL = 'FOUR_COL',
	FIVE_COL = 'FIVE_COL',
}

export interface IDirectoryChoiceFilter {
	loading: boolean;
	choices?: ReadonlyArray<{ id: string; name: string }>;
	setter: (v: string[]) => void;
	value: string[];
	facet?: (v: { id: string; name: string }) => number;
	title?: string;
}

export interface IDirectoryTextFilter {
	setter: (v: string) => void;
	value: string | null;
	layout: DirectoryFilterLayouts;
}

export interface IDirectoryBooleanFilter {
	setter: (v: boolean) => void;
	value: boolean;
	facet: undefined | ((v: boolean) => number);
	title?: string;
}

export interface IDirectoryFilterDefinition {
	component?: any;
	key: string;
	toUrl: (obj: any) => string;
	fromUrl: (url: string) => any;
	query?: any;
	serializer?: (value: any, data?: any) => any;
	toLabel?: (v: any) => string;
	extractor?: (pureData: any) => any;
	tagList?: (data: any, setter: DefaultSetter, value: any) => JSX.Element | '';
	facetExtractor?: (data: any, filterData: any) => (value: any) => number;
	title?: string;
}

export interface IDirectoryViewProps {
	entities?: ReadonlyArray<Good | Company | Brand>;
	layout: DirectoryViewLayouts;
	directoryLayout: DirectoryLayouts;
	page: number;
	perPage: number;
	parsedFilters: any;
	suggestedFS: any[] | undefined;
	total: number;
}

export interface DirectoryPageProps {
	query: any;
	children: (args: IDirectoryViewProps) => JSX.Element | null;
	type: SearchTypes;
	name: string;
	filters: {
		[key: string]: IDirectoryFilterDefinition;
	};
	filtersQuery: any;
	layout?: DirectoryLayouts;
}

export function directoryLayoutChooser(w: number): DirectoryLayouts {
	const columnSize = theme.columnSize;
	if (w <= columnSize * 2) {
		return DirectoryLayouts.TWO_COL;
	} else if (w <= columnSize * 3) {
		return DirectoryLayouts.FE_TWO_COL;
	} else if (w <= columnSize * 4) {
		return DirectoryLayouts.FE_THREE_COL;
	} else if (w <= columnSize * 5) {
		return DirectoryLayouts.FE_FOUR_COL;
	} else {
		return DirectoryLayouts.FE_FIVE_COL;
	}
}

export const moduleFilter = (list, m, c) => list.filter((e, i) => i % m === c);

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	// devo uniformare le spaziature del contenuto, in questo caso, a quelle fra le card - quindi s
	margin-top: ${t.ratios.s}rem;
	margin-bottom: ${t.ratios.s}rem;
	
	.${sc('content')} {
		margin: 0 ${t.ratios.s}rem;
		display: flex;
		
		&.${sc('filterCollapsed')} {
			flex-direction: column;
			
			> div {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	
	.${sc('filter-summary')} {
		z-index: ${t.zIndexes.P2_AreaAbove};
	}
	
	.${sc('bottom-pager')} {
		box-shadow: ${t.boxShadow(1)};
		background-color: ${t.colors.white};
		padding: ${t.ratios.l}rem;
		text-align: center;
		margin-bottom: 0;
		
		> div {
			// di default prende il pager al centro
			margin-left: 1rem;
			display: inline-block;
		}
	}
	
	.${sc('directory')} {
		flex: 1 1 auto;
		
		&.${sc('filterCollapsed')} {
			padding: 0;
		}
	}
	
	&.${sc('layout', DirectoryLayouts.TWO_COL)} {
		
		.${sc('content')} {
			> * {
				&:first-child {
					margin-top: 0;
					margin-bottom: ${t.ratios.s}rem;
				}
			}
		}
		
	}
}
`,
);

const sc = currySC(styleClass);

const filtersToSearch = (
	values,
	filters: {
		[key: string]: IDirectoryFilterDefinition;
	},
	currentPage: number,
	perPage: number,
) => {
	const base = {
		page: 1,
		perPage: perPage.toString(),
	};

	Object.keys(values).forEach((key) => {
		const value = values[key];
		const { toUrl } = filters[key];
		if ((Array.isArray(value) && value.length > 0) || value) {
			base[key] = toUrl(value);
		}
	});

	return '?' + stringify(base);
};

const setter = (
	track: (action: TTrackAction) => void,
	oldFilterValues: {
		[key: string]: any;
	},
	filter: IDirectoryFilterDefinition,
	filters: {
		[key: string]: IDirectoryFilterDefinition;
	},
	history: any,
	currentPage: number,
	perPage: number,
) => (newValue: any) => {
	const { key } = filter;
	const newValues = {
		...oldFilterValues,
		[key]: newValue,
	};
	const oldValue = oldFilterValues[key];
	Sentry.addBreadcrumb({
		category: 'ui',
		message: 'changed directory filter',
		level: Sentry.Severity.Info,
		data: {
			oldValue,
			newValue,
		},
	});
	track({ type: 'filter_update', key, oldValue, newValue });
	history.push({ search: filtersToSearch(newValues, filters, currentPage, perPage) });
};

export const Directory: React.FC<DirectoryPageProps> = ({
	children,
	query,
	filters,
	type,
	layout = DirectoryLayouts.TWO_COL,
	filtersQuery,
}) => {
	// const { isBot } = useContext(renderContext);
	// @ts-ignore
	const { history } = useRouter();
	const track = useTracking();
	// const changePage = pageChanger(history, track);
	const args = parse(history.location.search.substr(1));
	const filterValues = Object.keys(filters).reduce((result, key) => {
		if (key in filters) {
			const filterDefinition = filters[key];
			const value = args[key] as string;
			if (value) {
				result[key] = filterDefinition.fromUrl(value);
			}
		}
		return result;
	}, {});
	const perPage = Number(args.perPage) || 36;
	const currentPage = Number(args.page) || 1;
	const entityType = type === SearchTypes.COMPANY ? ENTITY.COMPANY : ENTITY.GOOD;

	return (
		<UserData>
			{({ loading: userDataLoading, isLogged }) => {
				if (userDataLoading) {
					return <div className={mixin.mainPageContent} />;
				}
				return (
					<div
						className={cns(
							mixin.mainPageContent,
							styleClass,
							layout && sc('layout', layout),
						)}
					>
						<Query
							errorPolicy={'all'}
							query={filtersQuery}
							variables={{
								eb: CONFIG.TARGET_SITE,
								...args,
							}}
						>
							{({
								error: filterError,
								loading: filterLoading,
								data: filterData,
							}: {
								loading: boolean;
								error?: ApolloError;
								data: any;
							}) => {
								let viewLayout;
								let filterCollapsed = false;
								switch (layout) {
									case DirectoryLayouts.TWO_COL:
										viewLayout = DirectoryViewLayouts.TWO_COL;
										filterCollapsed = true;
										break;
									case DirectoryLayouts.FE_TWO_COL:
										viewLayout = DirectoryViewLayouts.TWO_COL;
										break;
									case DirectoryLayouts.FE_THREE_COL:
										viewLayout = DirectoryViewLayouts.THREE_COL;
										break;
									case DirectoryLayouts.FE_FOUR_COL:
										viewLayout = DirectoryViewLayouts.FOUR_COL;
										break;
									default:
										viewLayout = DirectoryViewLayouts.FIVE_COL;
										filterCollapsed = false;
										break;
								}
								if (filterError) {
									return <Redirect to={'/error'} />;
								}
								if (filterLoading) {
									return (
										<div
											className={cns(
												sc('content'),
												filterCollapsed && sc('filterCollapsed'),
											)}
										/>
									);
								}

								const finalFilters = Object.keys(filterValues)
									.filter((key) => filters[key])
									.reduce((result, key) => {
										const serializer = filters[key].serializer;
										if (serializer) {
											result[key] = serializer(filterValues[key], filterData);
										} else {
											result[key] = filterValues[key];
										}
										return result;
									}, {});

								const variables = {
									filter: finalFilters,
									first: Number(perPage),
									cursor: offsetToCursorDirectory((currentPage - 1) * perPage),
								};

								const where =
									type === SearchTypes.COMPANY
										? companyDirectoryWhere(currentPage, finalFilters)
										: goodDirectoryWhere(currentPage, finalFilters);

								if (currentPage > 1) {
									if (userDataLoading) {
										return (
											<div
												className={cns(
													sc('content'),
													filterCollapsed && sc('filterCollapsed'),
												)}
											/>
										);
									} else if (!isLogged) {
										track({
											type: 'requested_authentication_notlogged',
											reason: 'change_page',
										});
										openRenewModal({
											customComponent: LoginMessage,
										});
										return <ForbiddenPageError history={history} />;
									}
								}

								// devo fare un hack terribile per le frequent search statiche in modo da forzarne il refetch
								// questo perche' apollo non ha ancora la cache invalidation...
								return (
									<UserLists>
										{({ loading, isStaticList }) => {
											if (loading) {
												return <div />;
											}
											// isStatic significa che si tratta dei "favourites"
											const fs = (variables.filter as any).FrequentSearch;
											const isStatic = fs && isStaticList(fs.id);

											return (
												<Query
													query={query}
													variables={variables}
													errorPolicy={'all'}
													fetchPolicy={isStatic ? 'cache-first' : undefined}
													onCompleted={(data) => {
														track(
															{
																type: 'view',
																what: [where],
															},
															addWhere(where),
														);
													}}
												>
													{({
														error,
														loading,
														data,
													}: {
														loading: boolean;
														error?: ApolloError;
														data: any;
													}) => {
														const curriedSetter = (f) =>
															setter(
																(e: TTrackAction) =>
																	track(e, addWhere(where)),
																filterValues,
																f,
																filters,
																history,
																currentPage,
																perPage,
															);

														let entities: ReadonlyArray<
															Good | Company | Brand
														> = [];
														if (!error && !loading) {
															entities = nodes(data.entities);
														}

														return (
															<div
																className={cns(
																	sc('content'),
																	filterCollapsed &&
																		sc('filterCollapsed'),
																)}
															>
																<DirectoryFilter
																	layout={
																		filterCollapsed
																			? DirectoryFilterLayouts.COLLAPSED
																			: DirectoryFilterLayouts.EXPANDED
																	}
																	filters={filters}
																	filterValues={filterValues}
																	setter={curriedSetter}
																	loading={loading}
																	data={data}
																	filterData={filterData}
																	entityType={entityType}
																	onSave={({ data }) =>
																		history.push({
																			search: stringify({
																				FrequentSearch:
																					data.frequentSearchCreate
																						.id,
																			}),
																		})
																	}
																/>
																<div
																	className={cns(
																		sc('directory'),
																		filterCollapsed &&
																			sc('filterCollapsed'),
																	)}
																>
																	{/*<FilterSummary
																		collection={type}
																		filters={filters}
																		values={filterValues}
																		data={filterData}
																		setter={curriedSetter}
																		pageChanger={changePage}
																		className={cns(
																			loadable(
																				isBot,
																				LoadableDirection.TOP,
																			),
																			sc('filter-summary'),
																		)}
																		layout={
																			layout ===
																			DirectoryLayouts.TWO_COL
																				? FilterSummaryLayout.PRIMARY_COMPACT
																				: FilterSummaryLayout.PRIMARY
																		}
																		onSave={({ data }) =>
																			history.push({
																				search: stringify({
																					FrequentSearch:
																						data
																							.frequentSearchCreate
																							.id,
																				}),
																			})
																		}
																		total={
																			loading || error
																				? null
																				: data.entities
																				? data.entities.total
																				: 0
																		}
																		currentPage={currentPage}
																	/>*/}

																	{loading && null}
																	{!loading &&
																		error &&
																		renderErrors(
																			error.graphQLErrors,
																			[['entities']],
																			{
																				401: (
																					<ForbiddenFiltersError
																						entityType={entityType}
																						notBuyerMode={false}
																					/>
																				),
																				403: (
																					<ForbiddenFiltersError
																						entityType={entityType}
																						notBuyerMode={true}
																					/>
																				),
																			},
																		)}
																	{!loading && !error && (
																		<>
																			{children({
																				entities,
																				layout: viewLayout,
																				directoryLayout: layout,
																				page: currentPage,
																				perPage,
																				parsedFilters: filterValues,
																				total: data.entities.total,
																				suggestedFS:
																					data &&
																					data.entities &&
																					data.entities
																						.relatedFrequentSearches,
																			})}
																			<PagesList
																				entitiesPerPage={perPage}
																				className={sc('bottom-pager')}
																				currentPage={currentPage}
																				total={data.entities.total}
																				history={history}
																			/>
																		</>
																	)}
																</div>
															</div>
														);
													}}
												</Query>
											);
										}}
									</UserLists>
								);
							}}
						</Query>
					</div>
				);
			}}
		</UserData>
	);
};
