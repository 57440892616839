import React, { useEffect } from 'react';
import { LayoutChooser, LayoutHandler } from '@food/ui';
import { Header, HeaderLayoutChooser, headerLayouts } from '../blocks/Header';
import { Footer, footerLayoutChooser, footerLayouts } from '../blocks/Footer';
import { mixin } from '../../utils/theme';
import { TWhere } from '../../tracking-types';
import { addWhere, useTracking, WhereBlock } from '../../utils/tracking';

export function genericPageFactory(
	Component: React.FC<any>,
	layoutChooser: LayoutChooser,
	name: string,
): React.FC {
	const w: TWhere = { type: 'Page', name };
	const blockView = name.substr(-9) === 'Directory';

	return () => {
		const track = useTracking();
		useEffect(() => {
			if (!blockView) {
				track({ type: 'view', what: [w] }, addWhere(w));
			}
		}, []);

		return (
			<LayoutHandler
				layouts={{
					header: HeaderLayoutChooser,
					footer: footerLayoutChooser,
					component: layoutChooser,
				}}
			>
				{({ header, footer, component }) => (
					<WhereBlock step={w}>
						<Header layout={header as headerLayouts} />
						<div className={mixin.mainPageContent}>
							<Component layout={component} />
						</div>
						<Footer layout={footer as footerLayouts} />
					</WhereBlock>
				)}
			</LayoutHandler>
		);
	};
}
