export const editorialSections =[
	{
		"id": "viahqNcHp0g~aa0",
		"name": "Bakery & Snacks",
		"iconMedia": {
			"id": "4Q3YjAKi6Lo~b6f",
			"name": "BAKERY & SNACKS.svg",
			"vecThumbUrl": "https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/007/190_V.svg"
		}
	},
	{
		"id": "W2m8E0M8kwI~cfb",
		"name": "Beverage & Wine",
		"iconMedia": {
			"id": "2oMdfR9JuSo~ae5",
			"name": "BEVERAGE & WINE.svg",
			"vecThumbUrl": "https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/007/193_V.svg"
		}
	},
	{
		"id": "8dC4kmGjVGI~f4d",
		"name": "Coffee & Tea",
		"iconMedia": {
			"id": "eL7tENwq_yA~d36",
			"name": "COFFEE & TEA.svg",
			"vecThumbUrl": "https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/007/195_V.svg"
		}
	},
	{
		"id": "FTLu8ynvyWI~e17",
		"name": "Condiments",
		"iconMedia": {
			"id": "WqCviJs02a4~da5",
			"name": "CONDIMENTS.svg",
			"vecThumbUrl": "https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/007/192_V.svg"
		}
	},
	{
		"id": "yV212ZdrixM~c3e",
		"name": "Dairy",
		"iconMedia": {
			"id": "Jq_bqm2UR20~fc5",
			"name": "DAIRY.svg",
			"vecThumbUrl": "https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/007/189_V.svg"
		}
	},
	{
		"id": "PvyqnOxl9Yg~b21",
		"name": "Flours & Eggs",
		"iconMedia": {
			"id": ".cW88lE3GWE~fdd",
			"name": "FLOURS & EGGS (1).svg",
			"vecThumbUrl": "https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/007/185_V.svg"
		}
	},
	{
		"id": "WXex3ODQc0I~ef3",
		"name": "Frozen",
		"iconMedia": {
			"id": "8LVqQ95cJok~977",
			"name": "FROZEN.svg",
			"vecThumbUrl": "https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/007/194_V.svg"
		}
	},
	{
		"id": "Frp7aAI.J.E~fe9",
		"name": "Fruit & Vegetables",
		"iconMedia": {
			"id": "iw8.T8f4RAs~bbc",
			"name": "FRUIT & VEGETABLES.svg",
			"vecThumbUrl": "https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/007/186_V.svg"
		}
	},
	{
		"id": "fOicH1RoY8Q~f72",
		"name": "Meat & Salumi",
		"iconMedia": {
			"id": "RZtujsWxbs8~c43",
			"name": "MEAT & SALUMI.svg",
			"vecThumbUrl": "https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/007/188_V.svg"
		}
	},
	{
		"id": "JzUU7cXQMeM~ddd",
		"name": "Pasta & Rice",
		"iconMedia": {
			"id": "QYXEIvMcPtw~95f",
			"name": "PASTA & RICE.svg",
			"vecThumbUrl": "https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/007/187_V.svg"
		}
	},
	{
		"id": "ip0coAB8SsM~de4",
		"name": "Seafood",
		"iconMedia": {
			"id": ".urwQpwSn4o~a91",
			"name": "SEAFOOD.svg",
			"vecThumbUrl": "https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/007/197_V.svg"
		}
	},
	{
		"id": "RLmkABcDXyg~9b3",
		"name": "Seasonals & Gift",
		"iconMedia": {
			"id": "ex4vxG.0Tfs~9e4",
			"name": "SEASONALS & GIFTS.svg",
			"vecThumbUrl": "https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/007/184_V.svg"
		}
	},
	{
		"id": "9NktcbvhobY~c03",
		"name": "Sweets & Confectionery",
		"iconMedia": {
			"id": "NFnLTItanfg~83b",
			"name": "SWEETS & CONFECTIONERY.svg",
			"vecThumbUrl": "https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/007/196_V.svg"
		}
	},
	{
		"id": "6QhsNCnE7jg~9b3",
		"name": "Tomato, Preserves & Sauces",
		"iconMedia": {
			"id": "fqnrgNs8Nws~9a0",
			"name": "TOMATO PRESERVES & SAUCES.svg",
			"vecThumbUrl": "https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/007/191_V.svg"
		}
	}
]
