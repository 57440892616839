import React from 'react';
import { SCType, cns } from '@food/css-manager';
import { Box } from '@food/ui';
import t from '../utils/labels';
import { mixin } from '../utils/theme';
import { Company } from '../server-types';

interface ICompanyDetailBillingInfoProps {
	sc: SCType;
	company: Company;
	hasL1: boolean;
}

export const CompanyDetailBillingInfo: React.FC<ICompanyDetailBillingInfoProps> = ({
	sc,
	company,
	hasL1,
}) => {
	const { billingName, billingBranch, website } = company;
	const label = sc('label');

	if (!billingBranch || !billingName) {
		return null;
	}

	return (
		<Box title={t`Billing Info`} className={sc('billing-info')}>
			<div className={cns(sc('dataSheet'), mixin.dataSheet)}>
				{/* Billing Name */}
				{billingName && (
					<div>
						<span className={label}>{t`Billing Name`}:</span>
						{billingName}
					</div>
				)}

				{/* Region */}
				{hasL1 && billingBranch && billingBranch.StateProvince && (
					<div>
						<span className={label}>{t`Region`}:</span>
						{billingBranch.StateProvince.region}
					</div>
				)}

				{/* Billing Address */}
				{billingBranch && (
					<div>
						<span className={label}>{t`Billing Address`}:</span>
						{billingBranch.address1}
						{billingBranch.address2 && (
							<>
								<br />
								{billingBranch.address2}
							</>
						)}
						<br />
						{billingBranch.postalCode} {billingBranch.city}
						<br />
						{billingBranch.StateProvince && billingBranch.StateProvince.name}
						<br />
						{billingBranch.Country && billingBranch.Country.name}
					</div>
				)}

				{/* Website */}
				{hasL1 && website && (
					<div>
						<span className={label}>{t`Website`}:</span>
						<a href={website} target="_blank" rel="noopener noreferrer nofollow">
							{website}
						</a>
					</div>
				)}
			</div>
		</Box>
	);
};
