import gql from 'graphql-tag';

export const preservationQuery = gql`
	query PreservationQuery {
		preservations: __type(name: "Preservation") {
			kind
			enumValues {
				name
			}
		}
	}
`;
