import { createBrowserHistory } from 'history';
import { track } from './tracking';
import { parse } from 'querystring';
import { TTrackAction, TWhere } from '../tracking-types';
import { trackingBase } from './tracking';

const history = createBrowserHistory();

history.listen((location) => {
	const action: TTrackAction = {
		type: 'route_changed',
		url: location.pathname,
		params: parse(location.search.replace('?', '')),
	};
	track({
		...trackingBase,
		where: [] as TWhere[],
		action,
	});

	(window as any).gtag('set', 'page_path', window.location.pathname + window.location.search);
	(window as any).gtag('event', 'page_view');
});

export { history, trackingBase };
