import { cns, registerClass, subClass } from '@food/css-manager';
import { IFNTheme } from './theme';
import { canUseDOM } from 'exenv';

export enum LoadableDirection {
	TOP = 'TOP',
	LEFT = 'LEFT',
	BOTTOM = 'BOTTOM',
	RIGHT = 'RIGHT',
}

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	animation-duration: ${(t.timings.base / 1000) * 2}s;
	animation-fill-mode: both;
	
	&.${sc('RIGHT')} {
		animation-name: fadeInRight;
	}
	
	&.${sc('LEFT')} {
		animation-name: fadeInLeft;
	}
	
	&.${sc('TOP')} {
		animation-name: fadeInTop;
	}
	
	&.${sc('BOTTOM')} {
		animation-name: fadeInBottom;
	}
	
	@keyframes fadeInBottom {
		from {
			opacity: 0;
			transform: translate3d(0, 5%, 0);
		}
		
		to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
	
	@keyframes fadeInTop {
		from {
			opacity: 0;
			transform: translate3d(0, -5%, 0);
		}
		
		to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
	
	@keyframes fadeInLeft {
		from {
			opacity: 0;
			transform: translate3d(-5%, 0, 0);
		}
		
		to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
	
	@keyframes fadeInRight {
		from {
			opacity: 0;
			transform: translate3d(5%, 0, 0);
		}
		
		to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
`,
);

const opaqueClass = registerClass(() => 'opacity: 0;');
const isServer = !canUseDOM;

/**
 * Utility per inserire un effetto di transizione con direzione ai componenti React - usato per i caricamenti
 * @param isBot: boolean
 * @param direction: LoadableDirection
 */
export const loadable: (isBot: boolean, direction?: LoadableDirection) => string = (
	isBot,
	direction = LoadableDirection.BOTTOM,
) =>
	isServer && !isBot ? opaqueClass : cns(styleClass, subClass(styleClass, direction));

export const loadDelayStyle = (delay: number = 0) => ({ animationDelay: delay + 's' });
