import gql from 'graphql-tag';
import { GenericChoiceFilter } from './GenericChoiceFilter';

export const CompanyCertificationFilterQuery = gql`
	query CompanyCertificationFilterQuery {
		companyCertifications {
			edges {
				node {
					id
					name
				}
			}
		}
	}
`;

export const CompanyCertificationFilter = GenericChoiceFilter;

