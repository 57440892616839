import React from 'react';
import { Box } from '@food/ui';
import { registerClass } from '@food/css-manager';
import t from '../utils/labels';
import { FairCard } from './entities/FairCard';
import { mixin } from '../utils/theme';
import { levelOrMore } from '../utils/misc';
import { WhereBlock } from '../utils/tracking';
import { CompanyLevelEnum } from '../server-types';

interface INextFairsBlockProps {
	participations: ReadonlyArray<any>;
	level: CompanyLevelEnum;
	showEmptyMessage?: boolean;
	className?: string;
}

const styleClass = registerClass(
	() => `
	display: flex;
	flex-direction: column;
	align-items: center;`,
);

export const NextFairsBlock: React.FC<INextFairsBlockProps> = ({
	participations,
	level,
	className,
}) =>
	participations && participations.length > 0 ? (
		<WhereBlock step={{ type: 'Block', name: 'companyNextFairs' }}>
			<Box title={t`Next tradeshows`} className={className || ''}>
				<div className={styleClass}>
					{participations.slice(0, 4).map((fp) => (
						<FairCard
							className={mixin.fullWidthCard}
							key={fp.Fair.id}
							entity={fp.Fair}
							companyLevel={level}
							participation={fp}
							forceShowDirectoryBtn={!levelOrMore(level, CompanyLevelEnum.PaidL1)}
						/>
					))}
				</div>
			</Box>
		</WhereBlock>
	) : null;
