import gql from 'graphql-tag';
import { client } from './client';
import { companyDetailFragment } from '../components/entities/CompanyDetail';
import { fairDetailFragment } from '../components/entities/FairDetail';
import { goodDetailFragment } from '../components/entities/GoodDetail';
import { DocumentNode } from 'apollo-link';

/**
 * Funzione per unire diverse query GraphQL in un'unica query
 * Contiene diversi ignore, probabilmente a causa della tipizzazione mal utilizzata di apollo
 * @param queries: ReadonlyArray<DocumentNode>
 * @param name: string
 */
export function getQuery(queries: ReadonlyArray<DocumentNode>, name: string) {
	// raggruppo le eventuali HeaderQuery

	try {
		const finalQuery = JSON.parse(JSON.stringify(queries[0])); // Deep-clone

		// prendo la query con indice 0 e la utilizzo come base su cui andare ad aggiungere
		// tutte le altre query
		finalQuery.definitions[0].name.value = name;
		queries.slice(1).forEach((q) => {
			// unisco tutte le HeaderQuery in una sola
			const selections = q.definitions
				.filter((d) => d.kind === 'OperationDefinition')
				// @ts-ignore
				.map((d) => d.selectionSet.selections);

			const variables = q.definitions
				.filter((d) => d.kind === 'OperationDefinition')
				// @ts-ignore
				.map((d) => d.variableDefinitions)
				.reduce((result, v) => (v ? result.concat(v) : result), []);

			if (variables.length > 0) {
				finalQuery.definitions[0].variableDefinitions = finalQuery.definitions[0].variableDefinitions.concat(
					variables,
				);
			}

			// siccome una duplice definizione di un Fragment risulta errata per apollo sono costretto a renderli "unici",
			// presupponendo che ad un nome frammento corrisponda sempre lo stesso set di dati
			const fragments = q.definitions.filter((d) => d.kind === 'FragmentDefinition');

			finalQuery.definitions[0].selectionSet.selections = finalQuery.definitions[0].selectionSet.selections.concat(
				selections[0],
			);

			fragments.forEach((d) => {
				// @ts-ignore
				if (finalQuery.definitions.every((d2) => d.name.value !== d2.name.value)) {
					finalQuery.definitions.push(d);
				}
			});
		});

		return finalQuery;
	} catch (e) {
		console.error(e);
	}
}

export const deleteMutation = gql`
	mutation DeleteNode($id: ID!) {
		nodeDelete(id: $id)
	}
`;

export const defaultDetailQuery = gql`
	query DefaultQuery($slug: String!) {
		entity: url2node(url: $slug) {
			__typename
			...CompanyDetailFragment
			...FairDetailFragment
			...GoodDetailFragment
		}
	}
	${companyDetailFragment}
	${fairDetailFragment}
	${goodDetailFragment}
`;

export const deleteNode = (nodeId: string) =>
	client.mutate({
		mutation: deleteMutation,
		variables: { id: nodeId },
	});

export const toRefInput = (id: string) => ({ id });
