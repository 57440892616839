import React from 'react';
import t from '../utils/labels';
import { errorToComponent } from '../utils/errors';
import { Modal2 as Modal, Box, Icon } from '@food/ui';

interface IState {
	open: boolean;
	errors: any[];
	closeHandler?: () => void;
}

export class ErrorModal extends React.Component<{}, IState> {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			errors: [],
		};
	}

	_open = (errors, closeHandler = null) =>
		this.setState({
			open: true,
			errors,
			closeHandler,
		});

	_close = () => {
		const handler = this.state.closeHandler;
		this.setState({
			open: false,
			errors: [],
		});
		if (handler) {
			handler();
		}
	};

	render() {
		const { open, errors } = this.state;

		return (
			<Modal open={open} closeHandler={this._close}>
				{() => (
					<Box
						title={t`Error`}
						action={() => <Icon name={'close'} onClick={this._close} />}
					>
						{errors &&
							errors.map((e, i) => (
								<section key={i}>{errorToComponent(e)}</section>
							))}
					</Box>
				)}
			</Modal>
		);
	}
}
