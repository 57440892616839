import React, { useContext } from 'react';
import { Query } from 'react-apollo';
import { relatedProductsQuery } from '../../graphql/RelatedProductsQuery';
import { Box, Slider, SliderLayouts } from '@food/ui';
import { cns, currySC, registerClass } from '@food/css-manager';
import { toRefInput } from '../../utils/graphql';
import { loadable, LoadableDirection } from '../../utils/loadable';
import { nodes } from '../../utils/misc';
import { renderContext } from '../../utils/context/renderContext';
import t from '../../utils/labels';
import { IFNTheme } from '../../utils/theme';
import { GoodDetailLayouts } from './GoodDetail';
import { GoodCard, GoodCardLayout } from './GoodCard';
import { WhereBlock } from '../../utils/tracking';
import { sentryHandler } from '../../utils/errors';
import { CompanyLevelEnum } from '../../server-types';
import { ApolloError } from 'apollo-client';

export interface IRelatedProductsProps {
	entity: any;
	layout: GoodDetailLayouts;
	level: CompanyLevelEnum;
}

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
  margin: 0;
  
  .${sc('card')} {
    width: ${t.columnSize / 2}px;
  }`,
);

const sc = currySC(styleClass);

export const RelatedProducts: React.FC<IRelatedProductsProps> = ({ entity, layout }) => {
	const { isBot } = useContext(renderContext);
	const sliderLayout =
		layout === GoodDetailLayouts.FOUR_COL
			? SliderLayouts.SINGLE_ROW
			: SliderLayouts.SINGLE_ELEMENT;

	return (
		<Query
			query={relatedProductsQuery}
			variables={{
				filter: { goodClasses: [toRefInput(entity.GoodClass.id)] },
			}}
		>
			{({
				error,
				loading,
				data,
			}: {
				loading: boolean;
				error?: ApolloError;
				data: any;
			}) => {
				if (loading) {
					return null;
				}
				if (error) {
					sentryHandler([error]);
					console.error(error);
					return null; // TODO maybe track?
				}

				const goods = nodes(data.goodsDir).filter((e) => e.id !== entity.id);

				if (goods.length === 0) {
					return null;
				}

				return (
					<WhereBlock step={{ type: 'Block', name: 'RelatedGoods' }}>
						<Box
							title={t`Similar products`}
							className={cns(
								styleClass,
								loadable(isBot, LoadableDirection.BOTTOM),
							)}
							fullWidth={sliderLayout === SliderLayouts.SINGLE_ELEMENT}
						>
							<Slider
								layout={sliderLayout}
								carusel={sliderLayout === SliderLayouts.SINGLE_ELEMENT}
							>
								{goods.map((c) => (
									<GoodCard
										className={sc('card')}
										entity={c}
										key={c.id}
										layout={GoodCardLayout.COMPACT}
									/>
								))}
							</Slider>
						</Box>
					</WhereBlock>
				);
			}}
		</Query>
	);
};
