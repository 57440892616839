import React, { useCallback, useContext } from 'react';
import { History } from 'history';
import { renderContext } from '../utils/context/renderContext';
import { loadable, LoadableDirection } from '../utils/loadable';
import { IFNTheme, mixin } from '../utils/theme';
import t from '../utils/labels';
import { Button, IconPositions } from '@food/ui';
import { openRenewModal } from '@food/auth';
import { registerClass, cns } from '@food/css-manager';
import { pageChanger } from '../utils/directory';
import { useTracking } from '../utils/tracking';

const forbiddenError = registerClass(
	(t: IFNTheme) => `
	box-shadow: ${t.boxShadow(1)};
	background: ${t.colors.white};
	padding: ${t.ratios.xl}rem;
	text-align: center;
	font-size: ${t.ratios.l}rem;
	
	> p {
		margin-bottom: ${t.ratios.l}rem;
	}
	
	a, button {
		margin-left: 1em;
	}
`,
);

export const ForbiddenPageError: React.FC<{ history: History }> = ({
	history,
}) => {
	const { isBot } = useContext(renderContext);
	const track = useTracking();
	const goToBegin = useCallback(() => pageChanger(history, track)(1), [history, track]);

	return (
		<div
			className={cns(
				forbiddenError,
				loadable(isBot, LoadableDirection.BOTTOM),
				mixin.limitedWidthPage,
			)}
		>
			<p>{t`Not enough permission for this page - not logged`}</p>
			<div>
				<Button
					label={t`Sign in`}
					type={'success'}
					icon={'person'}
					iconPos={IconPositions.RIGHT}
					onClick={() => openRenewModal()}
				/>
				<Button
					label={t`Return to first page`}
					icon={'filter_1'}
					iconPos={IconPositions.RIGHT}
					onClick={goToBegin}
				/>
			</div>
		</div>
	);
};
