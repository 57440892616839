import React, { useContext, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import dayjs from 'dayjs';
import { stringify } from 'querystring';
import { cns, currySC, registerClass } from '@food/css-manager';
import { Box, Button, ButtonLayouts, Icon, IconPositions } from '@food/ui';
import { IFNTheme, mixin, theme } from '../../utils/theme';
import { mediaImgFragment, layouts, MediaImg } from './MediaImg';
// import { Map } from '../GoogleMaps';
import { renderErrors } from '../../utils/errors';
import t from '../../utils/labels';
import { html } from '../../utils/misc';
import {
	EditorialSectionBlock,
	Fragment as EditorialSectionBlockFragment,
} from '../../components/entities/EditorialSectionBlock';
import { ACTIONS, generateUrl } from '../../utils/urls';
import { ENTITY } from '../../utils/entities';
import {
	BorderColors,
	Layouts as RemoteWordpressWidgetLayouts,
	RemoteWordpressWidget,
} from '../../components/blocks/RemoteWordpressWidget';
import CONFIG from '../../static/config';
import { loadable, LoadableDirection } from '../../utils/loadable';
import { FairDetailHead } from '../head/FairDetailHead';
// import { canUseDOM } from 'exenv';
import { Redirect } from 'react-router';
import { addWhere, useTracking, WhereBlock } from '../../utils/tracking';
import { TWhere } from '../../tracking-types';
import { EditorialSection, Fair, MediaType } from '../../server-types';
import { FairRelatedFrequentSearches } from '../FairRelatedFrequentSearches';
import { renderContext } from '../../utils/context/renderContext';
import { ApolloError } from 'apollo-client';

export enum FairDetailLayouts {
	ONE_COL = 'ONE_COL',
	TWO_COL = 'TWO_COL',
	THREE_COL = 'THREE_COL',
	FOUR_COL = 'FOUR_COL',
}

export function fairDetailLayoutChooser(w: number): FairDetailLayouts {
	const { mobileMax, tabletMax, notebookMax } = theme.breakpoints;
	if (w <= mobileMax) {
		return FairDetailLayouts.ONE_COL;
	} else if (w <= tabletMax) {
		return FairDetailLayouts.TWO_COL;
	} else if (w <= notebookMax) {
		return FairDetailLayouts.THREE_COL;
	} else {
		return FairDetailLayouts.FOUR_COL;
	}
}

interface IFairDetailProps {
	className?: string;
	entity: Fair & { wwebsite: string };
	errors?: any[];
	layout?: FairDetailLayouts;
}

export const fairDetailFragment = gql`
	fragment FairDetailFragment on Fair {
		id
		name
		edition
		logoMedia {
			...MediaImgFragment
		}
		year
		wwebsite: website
		begin
		end
		newsPath
		postalCode
		description
		Country {
			id
			name
		}
		StateProvince {
			id
			name
		}
		postalCode
		address1
		address2
		goodClasses {
			id
			editorialSections {
				...EditorialSectionBlockFragment
			}
		}
		city
		postalCode
		Country {
			id
			name
		}
	}
	${mediaImgFragment}
	${EditorialSectionBlockFragment}
`;

const participantsQuery = gql`
	query ParticipantQuery($fairId: ID!) {
		companiesDir(filter: { fairs: [{ id: $fairId }] }) {
			total
		}
	}
`;

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	padding: 0 ${t.ratios.xl}rem;
	margin-top: ${t.ratios.xl}rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;

	.${sc('fairDetailContainer')},
	.${sc('mapContainer')},
	.${sc('newsContainer')} {
		flex-basis: 100%;
		margin-bottom: ${t.ratios.xl}rem;
		max-width: 100%;
	}

	.${sc('mapContainer')} {
		padding-bottom: ${t.ratios.xl / 2}rem;
	}

	&.${sc('layout', FairDetailLayouts.FOUR_COL)} {
		.${sc('fairDetailContainer')} {
			flex-basis: 64%;
		}
		.${sc('mapContainer')} {
			flex-basis: 34%;
			margin-bottom: 0;
		}
		.${sc('noMap')} {
			flex-basis: 100%;
		}
	}

	.${sc('fairDetailHeader')} {
		display: flex;
		flex-wrap: wrap;
	}

	.${sc('logo')} {
		max-width: ${t.ratios.xl * 6}rem;
		border: 1px solid ${t.colors.grey.plain};
		padding: ${t.ratios.l}rem;
		margin-right: ${t.ratios.l}rem;
	}

	.${sc('head-title')} {
		margin-right: auto;
		> h2 {
			margin-top: ${t.ratios.s}rem;
			font-weight: ${t.font.weights.semibold};
			font-size: ${t.ratios.xl}rem;
		}
		> h4 {
			margin-top: ${t.ratios.s}rem;
			font-size: ${t.ratios.l}rem;
		}
	}

	.${sc('planButton')} {
		margin-top: ${t.ratios.xl}rem;
		align-self: flex-end;
	}

	.${sc('websiteContainer')} {
		margin-top: ${t.ratios.l}rem;

		> strong {
			margin-right: ${t.ratios.xl * 6}rem;
		}
		> a {
			text-decoration: none;
			color: ${t.colors.alt.plain};
		}
	}

	.${sc('desc')} {
		line-height: ${t.ratios.xl}rem;
	}

	.${sc('textCenter')} {
		text-align: center;

		> button {
			margin-top: ${t.ratios.l}rem;
		}
	}
	
	.${sc('label')} {
		font-weight: ${t.font.weights.semibold};
	}
	
	.${sc('directory-link-container')} {
		margin-top: ${t.ratios.l}em;
		font-size: ${t.ratios.l}rem;
		text-align: center;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		> * {
			margin: ${t.ratios.xl / 2}rem ${t.ratios.xl}rem;
		}
	}
	
	.${sc('address-block')} {
		margin-bottom: 1rem;
		line-height: ${t.ratios.xl};
		display: flex;
		flex-direction: row;
		
		span {
			display: block;
		}
		
		& > *:first-child {
			margin-right: ${t.ratios.xs}rem;
		}
	}
	
	.${sc('margin-left-btn')} {
		margin-left: ${theme.ratios.xs}rem;
	}
	
	.${sc('map')} {
		width: 100%;
	}
	
	.${sc('html')} {
		strong {
			font-weight: ${t.font.weights.bold};
		}
		
		ol {
			list-style: decimal outside;
		}
		
		ul {
			list-style: circle outside;
		}
		
		ol, ul {
			padding-left: ${t.ratios.xl}em;
		}
	}
`,
);

const sc = currySC(styleClass);

export const FairDetail: React.FC<IFairDetailProps> = ({
	className,
	entity,
	errors,
	layout,
}) => {
	const { isBot } = useContext(renderContext);
	const track = useTracking();
	const editorialSections = React.useMemo(
		(): ReadonlyArray<EditorialSection> =>
			entity &&
			Object.values(
				entity.goodClasses.reduce((obj, goodClass) => {
					if (goodClass.editorialSections) {
						goodClass.editorialSections.forEach((es) => {
							if (!obj[es.id]) {
								obj[es.id] = es;
							}
						});
					}
					return obj;
				}, {}),
			),
		[entity],
	);
	const fairDetailWhere: TWhere = { type: 'FairDetail', FairId: entity.id };
	const errorComponent = renderErrors(errors);

	useEffect(() => {
		if (!errorComponent) {
			track({ type: 'view', what: [fairDetailWhere] }, addWhere(fairDetailWhere));
		}
	}, [track]);
	if (errorComponent) {
		return errorComponent;
	}

	// lanci da wordpress su sito news
	const relatedNewsUrl = CONFIG.NEWS_SITE_BASE_URL + entity.newsPath;
	const { address1, address2, city, Country } = entity;
	const hasAddress = (address1 && city) || (address2 && city);
	const addressString = hasAddress
		? [address1, address2, city, Country && Country.name].filter((e) => e).join(',')
		: '';
	const addr = encodeURIComponent(addressString);
	const url = `https://maps.googleapis.com/maps/api/staticmap?center=${addr}&key=${CONFIG.MAPS_KEY}&zoom=13&size=450x400&maptype=roadmap&markers=color:red%7C${addr}`;
	const detailUrl = `https://www.google.com/maps/search/?api=1&query=${addr}`;

	return (
		<WhereBlock step={fairDetailWhere}>
			<FairDetailHead entity={entity} />
			<div className={cns(className, styleClass, sc('layout', layout))}>
				<div className={cns(sc('fairDetailContainer'), !hasAddress && sc('noMap'))}>
					<Box className={loadable(false, LoadableDirection.BOTTOM)}>
						<header className={cns(mixin.pageDetailHeader, sc('fairDetailHeader'))}>
							{entity.visits &&
								entity.visits.length > 0 &&
								entity.visits[0].fairParticipations.length > 0 && (
									<div className="fair-visit-icon">
										<Icon name={'flag'} />
									</div>
								)}
							{entity.logoMedia && (
								<MediaImg
									type={MediaType.Logo}
									entity={entity.logoMedia}
									className={sc('logo')}
									layout={layouts.MEDIUM}
									naturalWidth={true}
									alt={`Fair ${entity.name} ${entity.year} logo`}
								/>
							)}
							<div className={sc('head-title')}>
								<h2>{entity.name}</h2>
								<h2>
									{entity.year}
									{entity.edition && ` - ${entity.edition}`}
								</h2>
								<h4>
									<span className={sc('label')}>{t`Start`}:</span>{' '}
									{dayjs(entity.begin).format('dddd, MMMM DD YYYY')}
								</h4>
								<h4>
									<span className={sc('label')}>{t`End`}:</span>{' '}
									{dayjs(entity.end).format('dddd, MMMM DD YYYY')}
								</h4>
							</div>
						</header>
						<div>
							<div>
								<EditorialSectionBlock entities={editorialSections} />
							</div>
						</div>

						{entity.description && (
							<div className={cns(sc('desc'), mixin.pageDetailDesc, sc('html'))}>
								<div dangerouslySetInnerHTML={html(entity.description)} />
							</div>
						)}

						<div className={sc('websiteContainer')}>
							<span className={sc('label')}>{t`Website`}:</span>{' '}
							<a
								href={entity.wwebsite}
								target="_blank"
								rel="nofollow noopener noreferrer"
								onClick={() =>
									track(
										{
											type: 'generic_click',
											isExternal: true,
											name: 'Fair website',
											url: entity.wwebsite,
										},
										addWhere(fairDetailWhere),
									)
								}
							>
								{entity.wwebsite}
							</a>
						</div>
						<Query query={participantsQuery} variables={{ fairId: entity.id }}>
							{({
								error,
								loading,
								data,
							}: {
								loading: boolean;
								error?: ApolloError;
								data: any;
							}) => {
								if (loading) {
									return null;
								}
								if (error) {
									console.error(error);
									return <Redirect to={'/error'} />;
								}

								const total = data.companiesDir.total;
								const hasParticipants = Number(total) > 0;

								return (
									<div className={sc('directory-link-container')}>
										{hasParticipants && (
											<Button
												type={'success'}
												linkTo={
													generateUrl(ENTITY.COMPANY, ACTIONS.LIST) +
													'/?' +
													stringify({ fairs: entity.id })
												}
												label={t`See all Exhibitors`}
												icon={'chevron_right'}
												iconPos={IconPositions.RIGHT}
												layout={ButtonLayouts.BUTTON}
												onClick={() =>
													track(
														{
															type: 'do_search',
															filterStatus: { fairs: entity.id },
															target: 'CompanyDirectory',
														},
														addWhere(fairDetailWhere),
													)
												}
											/>
										)}
										{hasParticipants && (
											<Button
												type={'success'}
												className={sc('margin-left-btn')}
												linkTo={
													generateUrl(ENTITY.GOOD, ACTIONS.LIST) +
													'/?' +
													stringify({ fairs: entity.id })
												}
												label={t`See all Products`}
												icon={'chevron_right'}
												iconPos={IconPositions.RIGHT}
												layout={ButtonLayouts.BUTTON}
												onClick={() =>
													track(
														{
															type: 'do_search',
															filterStatus: { fairs: entity.id },
															target: 'GoodDirectory',
														},
														addWhere(fairDetailWhere),
													)
												}
											/>
										)}
										{!hasParticipants && t`no participants fair text`}
									</div>
								);
							}}
						</Query>
					</Box>
					<FairRelatedFrequentSearches fair={entity} />
				</div>
				{hasAddress && (
					<Box
						className={cns(
							sc('mapContainer'),
							loadable(isBot, LoadableDirection.RIGHT),
						)}
						title={t`fair detail map box title`}
					>
						<div className={sc('address-block')}>
							<div className={sc('label')}>{t`Address`}:</div>
							<div>
								{address1 && <span>{address1}</span>}
								{address2 && <span>{address2}</span>}
								{city && (
									<span>
										{entity.postalCode} {city}
									</span>
								)}
								{entity.Country && <span>{entity.Country.name}</span>}
							</div>
						</div>
						<a href={detailUrl} target={'_blank'}>
							<img
								src={url}
								alt={'google map render location'}
								className={sc('map')}
							/>
						</a>
						{/*{canUseDOM && (
							<Map zoom={12} hasGeolocation geolocationString={addressString} />
						)}*/}
					</Box>
				)}
				{entity.newsPath && (
					<RemoteWordpressWidget
						className={cns('news-widget', sc('newsContainer'))}
						url={relatedNewsUrl}
						selector={'#main article'}
						title={t`News`}
						isBordered={true}
						layout={RemoteWordpressWidgetLayouts.tripleWidth}
						borderColor={BorderColors.alt}
						hasBorderPattern={true}
					/>
				)}
			</div>
		</WhereBlock>
	);
};
