import React, { useContext, useEffect } from 'react';
import gql from 'graphql-tag';
import * as Sentry from '@sentry/browser';
import { Box } from '@food/ui';
import { cns, currySC, registerClass } from '@food/css-manager';
import { IFNTheme, mixin, theme } from '../../utils/theme';
import { layouts, MediaImg, mediaImgFragment } from './MediaImg';
import { UserObjectSubscription } from './UserObjectSubscription';
import {
	EditorialSectionBlock,
	EditorialSectionBlockLayouts as ESlayouts,
	Fragment as EditorialSectionBlockFragment,
} from '../../components/entities/EditorialSectionBlock';
import t from '../../utils/labels';
import { renderErrors } from '../../utils/errors';
import {
	companyLevel,
	companyLevel2Price,
	companyLevelStr,
	html,
	levelOrMore,
} from '../../utils/misc';
import { ENTITY } from '../../utils/entities';
import { loadable, LoadableDirection } from '../../utils/loadable';
import { renderContext } from '../../utils/context/renderContext';
import {
	DistributionChannelBlock,
	distributionChannelBlockFragment,
} from '../DistributionChannelBlock';
import { Fragment as FairCardFragment } from './FairCard';
import { CompanyCard, CompanyCardFragment, CompanyCardLayout } from './CompanyCard';
import { ContactSupplierBox } from '../ContactSupplierBox';
import { GoodDetailHead } from '../head/GoodDetailHead';
import { GoodDetailAside } from './GoodDetailAside';
import { RelatedProducts } from './RelatedProducts';
import { CompanyOtherProducts } from './CompanyOtherProducts';
import { addWhere, useTracking, WhereBlock } from '../../utils/tracking';
import { TWhere } from '../../tracking-types';
import { CompanyLevelEnum, Good, MediaType } from '../../server-types';
import { questionSetCreationModalFragment } from './QuestionSetCreationModal';
import { daysInMonthsYears } from '../../utils/dates';
// import TagManager from 'react-gtm-module';

export enum GoodDetailLayouts {
	ONE_COL = 'ONE_COL',
	TWO_COL = 'TWO_COL',
	THREE_COL = 'THREE_COL',
	FOUR_COL = 'FOUR_COL',
}

export function goodDetailLayoutChooser(w: number): GoodDetailLayouts {
	const { mobileMax, tabletMax, notebookMax } = theme.breakpoints;
	if (w <= mobileMax) {
		return GoodDetailLayouts.ONE_COL;
	} else if (w <= tabletMax) {
		return GoodDetailLayouts.TWO_COL;
	} else if (w <= notebookMax) {
		return GoodDetailLayouts.THREE_COL;
	} else {
		return GoodDetailLayouts.FOUR_COL;
	}
}

interface IGoodDetailProps {
	entity: Good;
	errors?: any[];
	layout?: GoodDetailLayouts;
}

export const goodDetailFragment = gql`
	fragment GoodDetailFragment on Good {
		id
		name
		slug
		range
		description
		descriptionLong
		shelfLifeLength

		highlightedGoods {
			id
			claim1
			claim2
			claim3
		}

		goodFeatureCertifications {
			id
			name
		}

		GoodClass {
			id
			name
			preservation
			editorialSections {
				id
				...EditorialSectionBlockFragment
			}
		}

		GeoIndication {
			id
			name
			website
		}

		featuredImageMedia {
			id
			...MediaImgFragment
		}

		Brand {
			id
			name

			Company {
				...CompanyCardFragment
				id
				currentCompanyLevels {
					id
					level
				}
				distributionChannels {
					...distributionChannelBlockFragment
				}
				fairParticipations(isConcluded: false) {
					id
					pavilion
					stand
					note
					Fair {
						id
						...FairCardFragment
					}
				}
			}
		}

		formats {
			id
			cuMesureUnit
			cuMesureValue
			cuPerSellingPack
		}

		candidacies {
			id
			__typename
			awardResults {
				id
				__typename
				finalPosition
			}
		}

		...QuestionSetCreationModalFragment
	}

	${questionSetCreationModalFragment}
	${mediaImgFragment}
	${EditorialSectionBlockFragment}
	${distributionChannelBlockFragment}
	${FairCardFragment}
	${CompanyCardFragment}
`;

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	margin: ${theme.ratios.xl}rem;
	
	.${sc('sticky-wrapper')} {
		position: sticky;
		top: ${t.ratios.l}rem;
	}
	.${sc('sidebar')} .${sc('sticky-wrapper')} > div, .${sc('mainColumn')} > div > div {
		margin-bottom: ${t.ratios.l}rem;
	}
	
	&.${sc('layout-FOUR_COL')} {
		.${sc('sidebar')} > div, .${sc('mainColumn')} > div > div {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	
	&.${sc('layout-FOUR_COL')} {
		.${sc('mainColumn')}, .${sc('sidebar')} {
			margin-bottom: ${t.ratios.xl}rem;
		}
	}
	
	.${sc('html')} {
		strong {
			font-weight: ${t.font.weights.bold};
		}
		
		ol {
			list-style: decimal outside;
		}
		
		ul {
			list-style: circle outside;
		}
		
		ol, ul {
			padding-left: ${t.ratios.xl}em;
		}
	}
	
	.${sc('mainColumn')} {
		
		> section {
			> div {
				position: relative;
				margin-bottom: ${t.ratios.xl}rem;;

				&.${sc('isolatedBox')} {
					border-radius: ${t.radius}rem ${t.radius}rem 0 0;

					> header {
						color: ${t.colors.grey.plain};
						font-weight: ${t.font.weights.semibold};
					}
				}
			}
		}
		
		.${sc('contentHeader')} {
			padding-right: ${t.ratios.xl * 3}em;

			& h1 {
				text-transform: none;
			}
		}

		.${sc('row')} {
			display: flex;
			justify-content: space-between;

			.${sc('col')} {
				position: relative;
				flex-basis: 48%;
			}
			
			&.${sc('row-layout-ONE_COL')} {
				flex-direction: column;

				> .${sc('col')} {
					flex-basis: 100%;
				}
			}
		}

		.${sc('image-container')} {
			border-radius: ${t.radius}rem;
			border: 1px solid ${t.colors.grey.plain};
			padding: ${t.ratios.l}rem;
			margin-right: ${t.ratios.l}rem;
			position: relative;

			.${sc('category')} {
				padding: 0; // ${t.ratios.l / 2}rem ${t.ratios.l / 2}rem;
			}
		}

		.${sc('image')} {
			flex: 1;
			width: 100%;
		}

		.${sc('category')} {
			margin-top: 0;
		}

		.${sc('desc')} {
			margin-top: ${t.ratios.l}rem;
			line-height: ${t.ratios.xl}rem;
			
			> .${sc('label')} {
				margin-bottom: 0;
			}
		}

		.${sc('dataSheet')} {
			margin-top: ${t.ratios.l}rem;
			line-height: ${t.ratios.xl}rem;
		}
		
		.${sc('actions')} {
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			flex-direction: row;
			> .${sc('favorite')} {
				position: static;
			}
		}
		
		.${sc('label')} {
			font-weight: ${t.font.weights.semibold};
			display: block;
		}
		
		.${sc('big')} {
			line-height: ${t.ratios.l}em;
			font-size: ${t.ratios.l}rem;
			margin-bottom: 1rem;
		}
		
		.${sc('claims')} {

			.${sc('c2')} {
				font-weight: ${t.font.weights.bold};
				border-bottom: 3px solid ${t.colors.alt.plain};
			}

			> ul {
				list-style: none;
				padding: 0;
				margin: 0;
				
				li {
					margin-top: ${t.ratios.xs}em;
					padding-left: ${t.ratios.xs * 2}rem;

					> .${sc('point')} {
						background-color: ${t.colors.alt.plain};
						display: inline-block;
						vertical-align: middle;
						width: ${t.ratios.xs}rem;
						height: ${t.ratios.xs}rem;
						margin-right: ${t.ratios.xs}rem;
						margin-left: -${t.ratios.xs * 2}rem;
						vertical-align: middle;
					}
				}
			}
		}
	}

	&.${sc('layout', 'ONE_COL')} {
		.${sc('dataSheet')} {
			> div {
				flex-direction: column;
				align-items: stretch;
			}
		}
		
		.${sc('range')} {
			margin-bottom: 0;
		}
		
		.${sc('image-container')} {
			border-radius: none;
			border: none;
			padding: 0;
			margin-right: 0;
			position: relative;
			margin-top: ${t.ratios.l}rem;
		}
	}
	
	.${sc('range')}, .${sc('name')} {
		margin-bottom: 1rem;
	}
	`,
);

const sc = currySC(styleClass);

export const GoodDetail: React.FC<IGoodDetailProps> = (props) => {
	const { isBot } = useContext(renderContext);
	const track = useTracking();
	const { entity, errors, layout } = props;
	const highlighted = entity.highlightedGoods && entity.highlightedGoods[0];
	const editorialSection = entity.GoodClass.editorialSections[0];

	useEffect(() => {
		// faccio un controllo, una tantum per dettaglio prodotto, per vedere se possiede una categoria editoriale.
		// se non la possiede e' un errore nostro, lo segnalo a sentry ma non blocco l'esperienza dell'utente
		if (entity && !editorialSection) {
			Sentry.captureException(new Error('Prodotto senza editorial section'));
		}
	}, [entity, editorialSection]);

	const level = companyLevel(entity.Brand.Company.currentCompanyLevels);
	const goodDetailWhere: TWhere = {
		type: 'GoodDetail',
		level,
		GoodId: entity.id,
		BrandId: entity.Brand.id,
		CompanyId: entity.Brand.Company.id,
	};

	const errorComponent = renderErrors(errors || []);

	useEffect(() => {
		if (!errorComponent) {
			// se non ho errori invio la view a tracking
			track({ type: 'view', what: [goodDetailWhere] }, addWhere(goodDetailWhere));

			// evento analytics di invio nota
			(window as any).gtag('event', 'view_item', {
				items: [
					{
						id: entity.id,
						name: entity.name,
						brand: entity.Brand.Company.name,
						variant: 'Product',
						category: companyLevelStr(entity.Brand.Company.currentCompanyLevels),
						price: companyLevel2Price(entity.Brand.Company.currentCompanyLevels),
						quantity: 1,
					},
				],
			});
		}
	}, [
		entity.Brand.Company.currentCompanyLevels,
		entity.Brand.Company.name,
		entity.id,
		entity.name,
		errorComponent,
		goodDetailWhere,
		track,
	]);

	if (errorComponent) {
		return errorComponent;
	}

	const l1OrMore = levelOrMore(level, CompanyLevelEnum.PaidL1);
	const l2OrMore = levelOrMore(level, CompanyLevelEnum.PaidL2);
	const description = l1OrMore ? entity.descriptionLong : entity.description;
	const hasHighlight = l1OrMore && highlighted && highlighted.claim1;

	return (
		<WhereBlock step={goodDetailWhere}>
			<GoodDetailHead entity={entity} />

			<div className={cns(styleClass, sc('layout', layout))}>
				{/* Container delle generale */}
				<div
					className={
						layout === GoodDetailLayouts.FOUR_COL ? mixin.pageWithSidebar : ''
					}
				>
					{/* Container delle due colonne */}

					<div className={sc('mainColumn')}>
						{/* Colonna di principale (sinistra) */}
						<div className={loadable(isBot, LoadableDirection.BOTTOM)}>
							<Box>
								<header
									className={cns(mixin.pageDetailHeader, sc('contentHeader'))}
								>
									<div className={sc('actions')}>
										{/*<UserNotesControl
											entity={entity}
											notes={entity.userNotes}
											openComponent={UserNoteCommand}
										/>*/}
										<UserObjectSubscription
											entityId={entity.id}
											collection={ENTITY.GOOD}
											className={sc('favorite')}
										/>
									</div>

									<h1 className={sc('name')}>{entity.name}</h1>

									{/* Range */}
									{entity.range && (
										<h4 className={sc('range')}>{entity.range}</h4>
									)}
								</header>

								<div className={cns(sc('row'), sc('row-layout', layout))}>
									<div className={cns(sc('col'), sc('colInfo'))}>
										<div className={sc('image-container')}>
											{entity.featuredImageMedia && (
												<MediaImg
													type={MediaType.GoodImage}
													entity={entity.featuredImageMedia}
													className={cns(sc('image'), mixin.productImage)}
													layout={layouts.MEDIUM}
													alt={`Featured photo of the product`}
												/>
											)}
											<EditorialSectionBlock
												entities={entity.GoodClass.editorialSections}
												layout={ESlayouts.ICONS_TEXTS}
												className={sc('category')}
											/>
										</div>
									</div>
									<div className={sc('col')}>
										{/* Shelf life */}
										{l1OrMore && (
											<div
												className={cns(
													hasHighlight && sc('block'),
													hasHighlight && sc('big'),
													!hasHighlight && sc('desc'),
												)}
											>
												<span className={sc('label')}>{t`Shelf life`}:</span>
												{daysInMonthsYears(entity.shelfLifeLength)}
											</div>
										)}

										{/* Preservation mode */}
										{entity.GoodClass && entity.GoodClass.preservation && (
											<div
												className={cns(
													hasHighlight && sc('block'),
													hasHighlight && sc('big'),
													!hasHighlight && sc('desc'),
												)}
											>
												<span className={sc('label')}>
													{t`Preservation Mode`}:
												</span>
												{t(entity.GoodClass.preservation)}
											</div>
										)}

										{/* Claims */}
										{hasHighlight && (
											<div
												className={cns(sc('claims'), sc('block'), sc('big'))}
											>
												<span
													className={sc('c2')}
													style={{
														borderColor: editorialSection?.color,
													}}
												>{t`Highlights`}</span>
												<ul className={sc('highlight-list')}>
													{highlighted && highlighted.claim1 && (
														<li>
															<div
																className={sc('point')}
																style={{
																	backgroundColor:
																		editorialSection?.color,
																}}
															/>
															{highlighted.claim1}
														</li>
													)}
													{highlighted && highlighted.claim2 && (
														<li>
															<div
																className={sc('point')}
																style={{
																	backgroundColor:
																		editorialSection?.color,
																}}
															/>
															{highlighted.claim2}
														</li>
													)}
													{highlighted && highlighted.claim3 && (
														<li>
															<div
																className={sc('point')}
																style={{
																	backgroundColor:
																		editorialSection?.color,
																}}
															/>
															{highlighted.claim3}
														</li>
													)}
												</ul>
											</div>
										)}
										{!hasHighlight && description && (
											<div className={cns(sc('desc'), sc('html'))}>
												<span className={cns(sc('label'), sc('block'))}>
													{t`Description`}:
												</span>
												<div dangerouslySetInnerHTML={html(description)} />
											</div>
										)}
										{/* Features */}
										{!l1OrMore &&
											entity.goodFeatureCertifications &&
											entity.goodFeatureCertifications.length > 0 && (
												<div className={sc('desc')}>
													<span className={cns(sc('label'), sc('block'))}>
														{t`Features & Certification`}:
													</span>
													<ul className={mixin.bulletedList}>
														{entity.goodFeatureCertifications.map(
															(feat, i) => (
																<li key={i}>{feat.name}</li>
															),
														)}
													</ul>
												</div>
											)}
									</div>
								</div>

								{/* Description */}
								{hasHighlight && description && (
									<div className={cns(sc('desc'), sc('html'))}>
										<span className={cns(sc('label'), sc('block'))}>
											{t`Description`}:
										</span>
										<div dangerouslySetInnerHTML={html(description)} />
									</div>
								)}

								<div className={cns(sc('dataSheet'), mixin.dataSheet)}>
									{/* Features */}
									{l1OrMore &&
										entity.goodFeatureCertifications &&
										entity.goodFeatureCertifications.length > 0 && (
											<div>
												<strong>{t`Features & Certification`}:</strong>
												<ul className={mixin.bulletedList}>
													{entity.goodFeatureCertifications.map(
														(feat, i) => (
															<li key={i}>{feat.name}</li>
														),
													)}
												</ul>
											</div>
										)}

									{/* Sales formats */}
									{entity.formats && entity.formats.length > 0 && (
										<div>
											<strong>{t`Sales formats`}:</strong>
											<ul className={mixin.bulletedList}>
												{entity.formats.map((format, i) => (
													<li key={i}>
														{format.cuPerSellingPack} x{' '}
														{format.cuMesureValue}
														{t(format.cuMesureUnit)}
													</li>
												))}
											</ul>
										</div>
									)}
								</div>
							</Box>

							{layout !== GoodDetailLayouts.FOUR_COL && (
								<>
									<ContactSupplierBox
										company={entity.Brand.Company}
										good={entity}
										l1OrMore={l1OrMore}
									/>
									<div>
										<CompanyCard
											entity={entity.Brand.Company}
											seoHtmlTags={true}
											layout={CompanyCardLayout.FULL}
											forceOpen={true}
											className={mixin.shadowed}
										/>
									</div>
								</>
							)}

							{/* blocco distribution channel, nelle sue varie forme che cambiano a seconda dello stato dell'utente */}
							<DistributionChannelBlock
								company={entity.Brand.Company}
								good={entity}
								entities={entity.Brand.Company.distributionChannels}
								hasPaymentLevel={l2OrMore}
								psc={sc}
							/>
						</div>
					</div>

					<GoodDetailAside
						className={cns(loadable(isBot), sc('sidebar'))}
						good={entity}
						layout={layout}
						l1OrMore={l1OrMore}
						sc={sc}
						level={level}
					/>
				</div>

				{l1OrMore ? (
					<CompanyOtherProducts entity={entity} layout={layout} />
				) : (
					<RelatedProducts layout={layout} entity={entity} level={level} />
				)}
			</div>
		</WhereBlock>
	);
};
