import { CardLayout, TWhere } from '../tracking-types';
import { Brand, Company, Fair, Good } from '../server-types';
import { companyLevel } from './misc';

type TWhereGenerator = (...args: any[]) => TWhere;

const companyCardWhere: TWhereGenerator = (entity: Company) => ({
	type: 'CompanyCard',
	CompanyId: entity.id,
	level: companyLevel(entity.currentCompanyLevels),
	layout: CardLayout.BASE,
});
const companyRelatedCardWhere: TWhereGenerator = (entity: Company) =>
	Object.assign(companyCardWhere(entity), { layout: CardLayout.RELATED });

const brandCardWhere: TWhereGenerator = (entity: Brand) => ({
	type: 'BrandCard',
	level: companyLevel(entity.Company.currentCompanyLevels),
	BrandId: entity.id,
	CompanyId: entity.Company.id,
	layout: CardLayout.BASE,
});

const fairCardWhere: TWhereGenerator = (entity: Fair) => ({
	type: 'FairCard',
	FairId: entity.id,
	layout: CardLayout.BASE,
});

const fairRelatedCardWhere = (entity: Fair) =>
	Object.assign(fairCardWhere(entity), { layout: CardLayout.RELATED });

const goodCardWhere: TWhereGenerator = (entity: Good) => ({
	type: 'GoodCard',
	level: companyLevel(entity.Brand.Company.currentCompanyLevels),
	layout: CardLayout.BASE,
	GoodId: entity.id,
	BrandId: entity.Brand.id,
	CompanyId: entity.Brand.Company.id,
});

const goodRelatedCardWhere: TWhereGenerator = (entity: Good) =>
	Object.assign(goodCardWhere(entity), { layout: CardLayout.RELATED });

const goodDirectoryWhere: TWhereGenerator = (
	page: number,
	filterValues: { [key: string]: any },
) => ({ type: 'GoodDirectory', page, filterStatus: filterValues });

const companyDirectoryWhere: TWhereGenerator = (
	page: number,
	filterValues: { [key: string]: any },
) => ({
	type: 'CompanyDirectory',
	page,
	filterStatus: filterValues,
});

export {
	companyCardWhere,
	companyRelatedCardWhere,
	fairCardWhere,
	fairRelatedCardWhere,
	goodCardWhere,
	goodRelatedCardWhere,
	brandCardWhere,
	goodDirectoryWhere,
	companyDirectoryWhere,
};
