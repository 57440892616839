import React from 'react';
// import { isItalianLang } from '../utils/misc';
// import { InsertCompanyBanner } from './banners/InsertCompanyBanner';
// import { LoginAsProfessional } from './banners/LoginAsProfessional';

interface IBannerSwitchProps {}

export const BannerSwitch: React.FC<IBannerSwitchProps> = () => {
	/*const browserLanguage = navigator.language || (navigator as any).userLanguage;
	if (browserLanguage) {
		// sono nel browser e conosco la lingua
		/!*if (isItalianLang(browserLanguage)) {
			return <InsertCompanyBanner />;
		} else {*!/
			return <LoginAsProfessional />;
		// }
	}*/
	return null;
};
