import React from 'react';
import t from '../utils/labels';
import { registerClass, currySC } from '@food/css-manager';
import { IFNTheme } from '../utils/theme';
import { html } from '../utils/misc';
import { SOCIAL_LOGIN_LAYOUTS } from '@food/auth/';

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	display: flex;
	justify-content: center;
	padding: ${t.ratios.xl}rem;
	line-height: ${t.ratios.xl}rem;
	flex-direction: column;
	
	.${sc('title')} {
		font-size: ${t.ratios.l}em;
		display: block;
		margin-bottom: ${t.ratios.xs}rem;
		font-weight: ${t.font.weights.semibold};
	}
	
	.${sc('content')} {
		li {
			line-height: ${t.ratios.xl * t.ratios.l}em;
			display: flex;
			flex-direction: row;
			align-items: center;
		
			> span {
				margin: 0 .5em;
			}
		}
	}
`,
);

const sc = currySC(styleClass);

export const loginMessageFactory = (
	message = 'login as professional html',
	title = 'login as professional title'
): React.FC<{ layout: SOCIAL_LOGIN_LAYOUTS }> => () => (
	<div className={styleClass}>
		{title && <span className={sc('title')}>{t(title)}</span>}
		<div
			className={sc('content')}
			dangerouslySetInnerHTML={html(t(message))}
		/>
	</div>
);
