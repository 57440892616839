import React from 'react';
import { Link } from 'react-router-dom';
import { registerClass, cns, currySC } from '@food/css-manager';
import { IFNTheme, mixin, theme as defaultTheme } from '../../utils/theme';
import ops from '../../static/assets/ops.jpg';
import t from '../../utils/labels';
import { html } from '../../utils/misc';
import { GenericHead } from '../head/GenericHead';

export enum ErrorLayouts {
	FULL = 'FULL',
	COMPACT = 'COMPACT',
	MINI = 'MINI',
}

interface IErrorProps {
	layout?: ErrorLayouts;
}

export function errorLayoutChooser(w: number): ErrorLayouts {
	if (w <= defaultTheme.breakpoints.mobileMax) {
		return ErrorLayouts.MINI;
	} else if (w <= defaultTheme.breakpoints.tabletMax) {
		return ErrorLayouts.COMPACT;
	} else {
		return ErrorLayouts.FULL;
	}
}

const styleClass = registerClass(
	(theme: IFNTheme, s) => `
	.${s('content')} {
		margin: ${theme.ratios.xl}rem ${theme.ratios.xl / 2}rem;
		box-shadow: ${theme.boxShadow(1)};
		background: ${theme.colors.white};
		
		display: flex;
		flex-flow: row-reverse wrap;
		align-items: center;
		justify-content: center;
		line-height: 1.5;
		> div {
			flex: 1 1 1rem;
			padding: ${theme.ratios.xl}rem;
		}
	}

	.${s('explanation')} {
		text-align: right;
		font-size: ${theme.ratios.l}rem;
		margin-bottom: ${theme.ratios.xl}rem;
	}
	
	.${s('title')} {
		font-size: ${theme.ratios.xl * 3}rem;
		margin-bottom: ${theme.ratios.xl}rem;
	}
	
	.${s('subtitle')} {
		font-size: ${theme.ratios.l}rem;
	}
	
	.${s('return-home')} {
		text-decoration: underline;
		display: block;
	}
	
	.${s('illustration')} {
		img {
			max-width: 100%;
		}
	}

	&.${s('layout', 'COMPACT')},
	&.${s('layout', 'MINI')} {
		.${s('content')} {
			display: block;
		}
		.${s('explanation')} {
			text-align: center;
			padding-top: 0;
		}
		.${s('title')} {
			font-size: ${theme.ratios.xl * 2}rem;
		}
	}
`,
);

const sc = currySC(styleClass);

export const ErrorComponent: React.FC<IErrorProps> = ({ layout }) => (
	<>
		<GenericHead title={t`Technical error`} />
		<div
			className={cns(
				styleClass,
				mixin.limitedWidthPage,
				sc('layout', layout || ErrorLayouts.FULL),
			)}
		>
			<div className={sc('content')}>
				<div className={sc('illustration')}>
					<img src={ops} alt={t`ironic error illustration`} />
				</div>
				<div className={sc('explanation')}>
					<div className={sc('title')}>{t`Mannaggia!`}</div>
					<div className={sc('subtitle')}>{t`This is not supposed to happen.`}</div>
					<p dangerouslySetInnerHTML={html(t`error explanation html`)} />
					<Link to={'/'} className={sc('return-home')}>{t`Return to Home`}</Link>
				</div>
			</div>
		</div>
	</>
);
