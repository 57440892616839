import React from 'react';
import gql from 'graphql-tag';
import { IFNTheme, mixin } from '../../utils/theme';
import {
	mediaImgFragment,
	layouts as MediaLayouts,
	MediaImg,
} from '../../components/entities/MediaImg';
import { cns, currySC, registerClass } from '@food/css-manager';
import { Link } from 'react-router-dom';
import { addWhere, useTracking, WhereBlock } from '../../utils/tracking';
import { CardLayout, TWhere } from '../../tracking-types';
import { FrequentSearch, FrequentSearchCollection, MediaType } from '../../server-types';
import { ENTITY } from '../../utils/entities';
import { ACTIONS, generateUrl } from '../../utils/urls';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const FrequentSearchLinkFragment = gql`
	fragment FrequentSearchLink on FrequentSearch {
		id
		collection
		linkUrl
	}
`;

const fsBasePath = generateUrl(ENTITY.FREQUENT_SEARCH, ACTIONS.LIST) + '/';
export const getFrequentSearchUrl = (entity: any) => {
	const basePath = entity.linkUrl
		? fsBasePath
		: `${generateUrl(
				entity.collection === FrequentSearchCollection.Good
					? ENTITY.GOOD
					: ENTITY.COMPANY,
				ACTIONS.LIST,
		  )}?FrequentSearch=`;
	return basePath + encodeURIComponent(entity.id);
};

interface FrequentSearchCardProps {
	className?: string;
	entity: FrequentSearch;
	customActions?: any;
	openInNewTab?: boolean;
}

export const FairRelatedFrequentSearchCardFragment = gql`
	fragment FrequentSearchCard on FrequentSearch {
		id
		name
		logoMedia {
			...MediaImgFragment
		}
		linkUrl
		linkRoles
		...FrequentSearchLink
	}
	${FrequentSearchLinkFragment}
	${mediaImgFragment}
`;

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 100%;
	background-color: ${t.colors.white};
	height: 100%;

	.${sc('expand-state-icon')} {
		text-align: center;
	}
	
	.${sc('media')} {
		width: 100%;
	}

	.${sc('info')} {
		position: relative;
		line-height: ${t.ratios.xl}em;
		padding: 0 ${t.ratios.xl}rem ${t.ratios.xl / 2}rem;
		text-align: center;
		max-width: 100%; // IE11FIX

		> h4 {
			font-size: ${t.ratios.l}em;
		}
		
		> div {
			font-size: ${t.ratios.s}em;
			> span {
				+ span {
					&:before {
						display: inline-block;
						content: '-';
						padding: 0 ${t.ratios.xs / 2}em;
					}
				}
			}
		}
	}
	
	.${sc('text-center')} {
		text-align: center;
	}
	
	.${sc('es')} {
		font-size: ${t.ratios.l}rem;
		display: block;
	}
`,
);

const sc = currySC(styleClass);

export const FrequentSearchCard: React.FC<FrequentSearchCardProps> = ({
	className,
	entity,
	openInNewTab = false,
}) => {
	const track = useTracking();
	const where: TWhere = {
		type: 'FrequentSearchCard',
		FrequentSearchId: entity.id,
		layout: CardLayout.BASE,
	};

	return (
		<WhereBlock step={where}>
			<Link
				to={getFrequentSearchUrl(entity)}
				target={openInNewTab ? '_blank' : undefined}
				onClick={(e) => {
					e.stopPropagation();
					track(
						{
							type: 'do_search',
							target: 'FrequentSearchDirectory',
							filterStatus: { FrequentSearch: entity.id },
						},
						addWhere(where),
					);
				}}
				className={cns(className, mixin.card)}
			>
				<div className={cns(styleClass)}>
					{entity.logoMedia && (
						<MediaImg
							type={MediaType.Logo}
							className={sc('media')}
							entity={entity.logoMedia}
							layout={MediaLayouts.MEDIUM}
							alt={`Frequent Search ${entity.name} header`}
						/>
					)}
					<div className={sc('info')}>
						<h4>
							<ResponsiveEllipsis
								style={{ whiteSpace: 'pre-wrap' }}
								text={entity.name}
								maxLine={2}
								ellipsis="…"
								trimRight
								basedOn="words"
							/>
						</h4>
					</div>
				</div>
			</Link>
		</WhereBlock>
	);
};
