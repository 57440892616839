import React, { useContext } from 'react';
import { registerClass, currySC, cns } from '@food/css-manager';
import { IFNTheme, mixin } from '../../utils/theme';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { html } from '../../utils/misc';
import { loadable, LoadableDirection } from '../../utils/loadable';
import { Redirect } from 'react-router';
import t from '../../utils/labels';
import { renderContext } from '../../utils/context/renderContext';
import { GenericHead } from '../head/GenericHead';
import { WhereBlock } from '../../utils/tracking';
import { ApolloError } from 'apollo-client';

export enum PrivacyLayouts {}

interface IPrivacyProps {
	className?: string;
	layout?: PrivacyLayouts;
}

const styleClass = registerClass(
	(theme: IFNTheme) => `
	strong {
		display: block;
		font-size: ${theme.ratios.l}em;
		margin-top: ${theme.ratios.s}em;
	}
`,
);

export const tosQuery = gql`
	query tos {
		tos: toSs(first: 1) {
			edges {
				node {
					id
					name
					description
					descriptionExtra
				}
			}
		}
	}
`;

const sc = currySC(styleClass);

export const Privacy: React.FC<IPrivacyProps> = ({ className }) => {
	const { isBot } = useContext(renderContext);

	return (
		<WhereBlock step={{ type: 'Page', name: 'Privacy policy' }}>
			<GenericHead title={t`Privacy Policy`} />
			<Query query={tosQuery}>
				{({
					error,
					loading,
					data,
				}: {
					loading: boolean;
					error?: ApolloError;
					data: any;
				}) => {
					if (error) {
						console.error(error);
						return <Redirect to={'/error'} />;
					}

					if (loading) {
						return null;
					}

					const tos = data.tos.edges[0].node;
					const { description, descriptionExtra } = tos;

					return (
						<div
							className={cns(
								mixin.textPage,
								styleClass,
								mixin.limitedWidthPage,
								loadable(isBot, LoadableDirection.BOTTOM),
								className,
							)}
						>
							<h1>
								<span>Privacy Policy</span>
							</h1>
							<div
								className={sc('desc')}
								dangerouslySetInnerHTML={html(description)}
							/>
							{descriptionExtra && (
								<div dangerouslySetInnerHTML={html(descriptionExtra)} />
							)}
						</div>
					);
				}}
			</Query>
		</WhereBlock>
	);
};
