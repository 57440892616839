import gql from 'graphql-tag';
import { GenericChoiceFilter } from './GenericChoiceFilter';

export const EditorialSectionFilterQuery = gql`
	query EditorialSectionFilterQuery($eb: EditorialBrand!) {
		editorialSectionTree(EditorialBrand: $eb) {
			id
			name
			goodClasses {
				id
			}
			Parent {
				 id
			}
		}
	}
`;

export const EditorialSectionFilter = GenericChoiceFilter;
