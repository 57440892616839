import React, { useState } from 'react';
import { Button } from '@food/ui';
import { registerClass } from '@food/css-manager';
import { IFNTheme } from '../../utils/theme';
import { IDirectoryTextFilter } from '../Directory';
import t from '../../utils/labels';
import { DirectoryFilterLayouts } from '../DirectoryFilter';

const styleClass = registerClass(
	(t: IFNTheme) => `
	
	margin-right: ${t.ratios.s}rem;

	> div {
		display: flex;

		> input {
			display: block;
			border-radius: ${t.radius}rem 0 0 ${t.radius}rem;
			font-size: ${t.ratios.l}rem;
			padding: ${t.ratios.xs / 2}rem;
			font-family: ${t.font.family};
			font-size: ${t.ratios.s}rem;
			flex: 1 1 auto;
			border: 1px solid ${t.colors.grey.plain};
		}
	
		> button {
			flex: 0 0 auto;
			border-radius: 0 ${t.radius}rem ${t.radius}rem 0 !important;
		}
	}
	`,
);

export const NameFilter: React.FC<IDirectoryTextFilter> = ({ setter, value, layout }) => {
	const [text, setText] = useState(value || null);

	return (
		<div className={styleClass}>
			<div>
				<input
					value={text !== null ? text : value}
					onChange={(e) => setText(e.target.value || '')}
					onKeyPress={(e) => {
						if (e.key === 'Enter') {
							setter(text);
						}
					}}
				/>
				{layout === DirectoryFilterLayouts.EXPANDED && (
					<Button label={t`Search`} onClick={() => setter(text)} />
				)}
				{layout === DirectoryFilterLayouts.COLLAPSED && (
					<Button icon={'search'} onClick={() => setter(text)} />
				)}
			</div>
		</div>
	);
};
