import React, { useContext } from 'react';
import {
	GoodCard,
	GoodCardFragment as GoodCardFragment,
	GoodCardLayout,
} from '../../components/entities/GoodCard';
import { IFNTheme, mixin } from '../../utils/theme';
import t from '../../utils/labels';
import { DefaultSetter, identity } from '../../utils/misc';
import { NameFilter } from '../filters/NameFilter';
import gql from 'graphql-tag';
import {
	Directory,
	DirectoryLayouts,
	DirectoryViewLayouts,
	IDirectoryFilterDefinition,
	moduleFilter,
} from '../Directory';
import { FilterTags } from '../UI/FilterTags';
import { SearchTypes } from '../../utils/frequentSearch';
import { GenericChoiceFilter } from '../filters/GenericChoiceFilter';
import { preservationQuery } from '../../graphql/preservationsQuery';
import { goodFeatureCertificationsQuery } from '../../graphql/goodFeatureCertificationsQuery';
import { cns, registerClass, subClass } from '@food/css-manager';
import { loadable, LoadableDirection, loadDelayStyle } from '../../utils/loadable';
import { getQuery } from '../../utils/graphql';
import { renderContext } from '../../utils/context/renderContext';
import { DirectoryHead } from '../head/DirectoryHead';
import { ENTITY } from '../../utils/entities';
import { SuggestedFSCard, SuggestedFSCardLayout } from '../SuggestedFSCard';
import { WhereBlock } from '../../utils/tracking';
import { UserLists } from '../UserLists';
import { Query } from 'react-apollo';
import { frequentSearchQuery } from '../../graphql/frequentSearchQuery';
import { goodDirectoryWhere } from '../../utils/where';
import { goodClassesDirectoryFilter } from '../../utils/directory';
import { fairQuery } from './CompanyDirectory';
import { FrequentSearchLinkFragment } from './FrequentSearchCard';
import { ApolloError } from 'apollo-client';

export const goodDirectoryQuery = gql`
	query GoodDirectoryQuery($filter: GoodsFilterType, $first: Int, $cursor: String) {
		entities: goodsDir(filter: $filter, first: $first, after: $cursor) {
			edges {
				node {
					... on Good {
						id
						...GoodCardFragment
						Brand {
							id
							Company {
								id
								name
							}
						}
					}
				}
			}
			total
			pageInfo {
				hasNextPage
				startCursor
			}
			facet {
				goodClass {
					count
					value
				}
				preservation {
					value
					count
				}
				goodFeatureCertifications {
					value
					count
				}
			}
			relatedFrequentSearches {
				id
				name
				linkUrl
				relatedFair {
					id
					name
					year
					edition
					slug
				}
				...FrequentSearchLink
			}
		}
	}
	${FrequentSearchLinkFragment}
	${GoodCardFragment}
`;

const shadowChildrenClass = registerClass(
	(t: IFNTheme) => `
	> * {
		box-shadow: ${t.boxShadow(1)};
	}
`,
);

export const GoodDirectoryFilters: {
	[key: string]: IDirectoryFilterDefinition;
} = {
	textSearch: {
		component: NameFilter,
		key: 'textSearch',
		fromUrl: identity,
		toUrl: identity,
		/*tagList: (data: any, setter: DefaultSetter, value: string) =>
			value && (
				<FilterTags
					title={t`Textual search`}
					tags={[{ label: value, onRemove: () => setter('') }]}
					type="alt"
				/>
			),*/
	},
	goodClasses: goodClassesDirectoryFilter,
	preservations: {
		key: 'preservations',
		title: t`Conservation Method`,
		component: GenericChoiceFilter,
		query: preservationQuery,
		extractor: (data: any) =>
			data.preservations.enumValues.map((v) => ({
				name: t(v.name),
				id: v.name,
			})),
		facetExtractor: (data: any) => (possibleValue) => {
			if (!data || !data.entities || !data.entities.facet) {
				return 0;
			}
			const ar = data.entities.facet.preservation.find(
				(ar) => ar.value === possibleValue.id,
			);
			if (ar) {
				return Number(ar.count);
			}
			return 0;
		},
		toUrl: (v: string[]) => v.join(','),
		fromUrl: (v: string) => v.split(','),
		/*tagList: (data: any, setter: DefaultSetter, value: string[]) =>
			value &&
			value.length > 0 && (
				<FilterTags
					title={t`Convervation Method`}
					type="alt"
					tags={value.map((e) => ({
						label: t(e),
						onRemove: () => setter(value.filter((id) => id !== e)),
					}))}
				/>
			),*/
	},

	goodFeatureCertifications: {
		key: 'goodFeatureCertifications',
		title: t`Features`,
		component: GenericChoiceFilter,
		query: goodFeatureCertificationsQuery,
		extractor: (data: any) =>
			data.goodFeatureCertifications.edges.map((o) => ({
				name: o.node.name,
				id: o.node.id,
			})),
		facetExtractor: (data: any) => (possibleValue) => {
			if (!data || !data.entities || !data.entities.facet) {
				return 0;
			}
			const ar = data.entities.facet.goodFeatureCertifications.find(
				(ar) => ar.value === possibleValue.id,
			);
			if (ar) {
				return Number(ar.count);
			}
			return 0;
		},
		fromUrl: (v: string) => v.split(','),
		toUrl: (v: string[]) => v.map(encodeURIComponent).join(','),
		serializer: (v: string[]) => v.map((id) => ({ id })),
		/*tagList: (data: any, setter: DefaultSetter, value: string[]) =>
			value &&
			value.length > 0 && (
				<FilterTags
					title={t`Features`}
					type="alt"
					tags={value.map((e) => ({
						label: nameLabel(
							data.goodFeatureCertifications.edges.find((o) => o.node.id === e)
								.node,
						),
						onRemove: () => setter(value.filter((id) => id !== e)),
					}))}
				/>
			),*/
	},

	FrequentSearch: {
		key: 'FrequentSearch',
		toUrl: (obj) => encodeURIComponent(obj.id),
		fromUrl: (id: string) => ({
			id,
		}),
		tagList: (data: any, setter: DefaultSetter, value: any) =>
			value && (
				<UserLists>
					{({ favoritesGoods, favoritesCompanies }) => (
						<Query query={frequentSearchQuery} variables={value}>
							{({
								error,
								loading,
								data,
							}: {
								loading: boolean;
								error?: ApolloError;
								data: any;
							}) => {
								return (
									!loading &&
									!error && (
										<FilterTags
											title={data.isDynamic ? t`Dynamic list` : t`Selection`}
											tags={[
												{
													icon:
														(favoritesCompanies &&
															value.id === favoritesCompanies.id) ||
														(favoritesGoods &&
															value.id === favoritesGoods.id)
															? 'favorite'
															: null,
													label: data.frequentSearch.name,
													onRemove: () => setter(null),
												},
											]}
											type="primary"
										/>
									)
								);
							}}
						</Query>
					)}
				</UserLists>
			),
	},

	fairs: {
		key: 'fairs',
		fromUrl: (v: string) => v.split(',').map(decodeURIComponent),
		toUrl: (v: string[]) => v.map(encodeURIComponent).join(','),
		serializer: (v: string[]) =>
			v.map((id) => ({
				id,
			})),
		tagList: (data: any, setter: DefaultSetter, value: string[]) =>
			value && (
				<Query query={fairQuery} variables={{ id: value[0] }}>
					{({
						error,
						loading,
						data,
					}: {
						loading: boolean;
						error?: ApolloError;
						data: any;
					}) =>
						!loading &&
						!error && (
							<FilterTags
								title={t`On display at`}
								tags={[
									{
										label:
											`${data.fair.name} ${data.fair.year}` +
											(data.fair.edition ? ` - ${data.fair.edition}` : ''),
										onRemove: () => setter([]),
									},
								]}
								type="alt"
							/>
						)
					}
				</Query>
			),
	},
};

export const goodFiltersQuery = getQuery(
	Object.values(GoodDirectoryFilters)
		.filter((f) => !!f.query)
		.map((f) => f.query),
	'GoodFilterQuery',
);

export const GoodDirectoryRoute: React.FC<{
	layout: DirectoryLayouts;
}> = ({ layout: dirLayout }) => {
	const { isBot } = useContext(renderContext);

	return (
		<Directory
			type={SearchTypes.GOOD}
			name={'Products'}
			query={goodDirectoryQuery}
			filters={GoodDirectoryFilters}
			layout={dirLayout}
			filtersQuery={goodFiltersQuery}
		>
			{({ entities, layout, page, parsedFilters, suggestedFS, total }) => {
				const directoryWhere = goodDirectoryWhere(
					page,
					parsedFilters,
					entities,
					total,
				);
				let columns = [0, 1];
				if (layout === DirectoryViewLayouts.THREE_COL) {
					columns = [0, 1, 2];
				}
				if (layout === DirectoryViewLayouts.FOUR_COL) {
					columns = [0, 1, 2, 3];
				}
				if (layout === DirectoryViewLayouts.FIVE_COL) {
					columns = [0, 1, 2, 3, 4];
				}

				let relatedFSShowColumn = 2;
				if (layout === DirectoryViewLayouts.TWO_COL) {
					relatedFSShowColumn = 1;
				}

				const prerenderCards = columns.map((i) =>
					moduleFilter(entities, columns.length, i).map((e, i2) => (
						<WhereBlock
							key={e.id}
							step={{ type: 'Block', name: 'CardPosition', column: i, row: i2 }}
						>
							<GoodCard
								entity={e}
								className={loadable(isBot, LoadableDirection.BOTTOM)}
								style={loadDelayStyle(0.2 * i2)}
								layout={
									dirLayout === DirectoryLayouts.TWO_COL
										? GoodCardLayout.COMPACT
										: GoodCardLayout.FULL
								}
							/>
						</WhereBlock>
					)),
				);

				if (Array.isArray(suggestedFS) && suggestedFS.length > 0) {
					const suggestedCardElem = (
						<SuggestedFSCard
							fs={suggestedFS}
							layout={
								dirLayout === DirectoryLayouts.TWO_COL
									? SuggestedFSCardLayout.COMPACT
									: SuggestedFSCardLayout.STANDARD
							}
						/>
					);
					if (relatedFSShowColumn === 0) {
						prerenderCards[relatedFSShowColumn].splice(1, 0, suggestedCardElem);
					} else {
						prerenderCards[relatedFSShowColumn] = [
							suggestedCardElem,
							...prerenderCards[relatedFSShowColumn],
						];
					}
				}

				return (
					<WhereBlock step={directoryWhere}>
						<DirectoryHead
							type={ENTITY.GOOD}
							filters={parsedFilters}
							filtersOnCanonical
						/>
						<div
							className={cns(
								mixin.expandableItemsGrid,
								dirLayout === DirectoryLayouts.TWO_COL &&
									subClass(mixin.expandableItemsGrid, 'compact'),
							)}
						>
							{columns.map((i) => (
								<div key={i} className={shadowChildrenClass}>
									{prerenderCards[i]}
								</div>
							))}
						</div>
					</WhereBlock>
				);
			}}
		</Directory>
	);
};
