import React from 'react';
import { ContactSupplierBox } from '../ContactSupplierBox';
import { NextFairsBlock } from '../NextFairsBlock';
import { GoodDetailLayouts } from './GoodDetail';
import { CompanyCard, CompanyCardLayout } from './CompanyCard';
import { CompanyLevelEnum, Good } from '../../server-types';
import { registerClass, SCType } from '@food/css-manager';
import { IFNTheme } from '../../utils/theme';
import { Experiment, Variant } from 'react-optimize';

export interface IGoodDetailAsideProps {
	good: Good;
	layout: GoodDetailLayouts;
	className?: any;
	l1OrMore: boolean;
	sc: SCType;
	level: CompanyLevelEnum;
}

const cardContainer = registerClass(
	(t: IFNTheme) => `
	box-shadow: ${t.boxShadow(1)};
`,
);

export const GoodDetailAside: React.FC<IGoodDetailAsideProps> = ({
	good,
	layout,
	className,
	l1OrMore,
	sc,
	level,
}) => (
	<aside className={className}>
		<section className={sc('sticky-wrapper')}>
			{layout === GoodDetailLayouts.FOUR_COL && (
				<>
					<ContactSupplierBox
						company={good.Brand.Company}
						good={good}
						l1OrMore={l1OrMore}
					/>
					<div>
						<CompanyCard
							layout={CompanyCardLayout.FULL}
							forceOpen={true}
							entity={good.Brand.Company}
							seoHtmlTags={true}
							className={cardContainer}
						/>
					</div>
				</>
			)}

			{/* ho aggiunto un esperimento con optimize - se l'azienda ha profilo gratuito posso non far vedere il blocco delle prossime fiere */}
			{l1OrMore && (
				<NextFairsBlock
					participations={good.Brand.Company.fairParticipations}
					level={level}
				/>
			)}
			{!l1OrMore && (
				<Experiment id="TNfU8JVMSvyLk8qyBYA9tw">
					<Variant id="0">
						<NextFairsBlock
							participations={good.Brand.Company.fairParticipations}
							level={level}
						/>
					</Variant>
				</Experiment>
			)}
		</section>
	</aside>
);
