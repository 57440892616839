import { FragmentMatcherInterface, ReadStoreContext } from 'apollo-cache-inmemory';
import { IdValue } from 'apollo-utilities';

export class LightFragmentMatcher implements FragmentMatcherInterface {
	constructor() {
		this.match = this.match.bind(this);
	}

	public match(idValue: IdValue, typeCondition: string, context: ReadStoreContext) {
		const obj = context.store.get(idValue.id);

		if (!obj) {
			return false;
		}

		if (!obj.__typename) {
			throw new Error(
				`Cannot match fragment because __typename property is missing: ${JSON.stringify(
					obj,
				)}`,
			);
		}

		if (obj.__typename === typeCondition) {
			return true;
		}

		// Commentato perche' non usiamo interfacce, quindi possiamo evitare di scaricare il GIGANTESCO json dello schema
		// const implementingTypes = this.possibleTypesMap[typeCondition];
		// if (implementingTypes && implementingTypes.indexOf(obj.__typename) > -1) {
		// 	return true;
		// }

		return false;
	}
}
