import React from 'react';
import { Query } from 'react-apollo';
import { isLogged } from '@food/auth';
import { canUseDOM } from 'exenv';
import { client } from '../../utils/client';
import { ApolloError } from 'apollo-client';

interface CachedQueryConsumerProps {
	children: (args: {
		loading: boolean;
		data: any;
		mutator: (mutation: mutation) => void;
		error?: any;
	}) => JSX.Element;
}

interface ICachedQueryFactoryResult {
	reset: () => void;
	consumer: React.FC<CachedQueryConsumerProps>;
}

interface CachedQueryFactoryInterface {
	query: any;
	ttlEvaluator?: (lastTimestamp, nowTimestamp) => boolean;
	ttl?: number;
	needLogin?: boolean;
}

type mutation = (data: any) => any;

function cachedQueryFactory({
	query,
	ttlEvaluator,
	ttl,
	needLogin,
}: CachedQueryFactoryInterface): ICachedQueryFactoryResult {
	const key = 'cached-' + query.definitions[0].name.value;
	const dataKey = key + '-data';
	const ttlKey = key + '-ttl';

	const needToRefresh = () => {
		if (!canUseDOM) {
			return true;
		}
		const now = new Date().getTime();
		const storageTTL = localStorage.getItem(ttlKey);
		const lastRefresh = storageTTL ? parseInt(storageTTL, 10) : 0;
		if (ttl) {
			const diff = now - lastRefresh;
			return diff > ttl;
		} else {
			return ttlEvaluator(lastRefresh, now);
		}
	};

	const writeInLS = (str: string) => {
		const now = new Date().getTime();
		localStorage.setItem(dataKey, str);
		localStorage.setItem(ttlKey, now.toString());
	};

	const oncePerCall = (result: any) => {
		if (!canUseDOM) {
			return;
		} // sul server non devo salvare niente
		const oldData = localStorage.getItem(dataKey);
		const parsed = JSON.stringify(result);
		if (!oldData || parsed !== oldData) {
			writeInLS(parsed);
		} else if (parsed === oldData) {
			const now = new Date().getTime();
			localStorage.setItem(ttlKey, now.toString());
		}
	};

	const writeInCache = (str: string) => {
		// a volte, a causa di un bug (probabilmente non mio), cado in questa condizione
		// in questo caso non devo scrivere il risultato ma aspettare che una nuova query
		// o un'altra tab scrivano dei dati validi in localStorage
		if (str === 'undefined') {
			return;
		}

		const data = JSON.parse(str);
		if (!data) {
			console.error('writeInCache called with !data');
			return;
		}
		client.writeQuery({
			query,
			data,
		});
	};

	if (canUseDOM) {
		// controllo se i dati sono gia' presenti in localstorage && sono ancora validi
		const storageData = localStorage.getItem(dataKey);
		if (storageData && !needToRefresh()) {
			writeInCache(storageData);
		}

		// quando le altre tab scrivono in localstorage dei cambiamenti devo aggiornare la cache locale
		window.addEventListener('storage', (e) => {
			// console.log(e.key, e);
			if (e.key === dataKey) {
				if (!e.newValue) {
					console.error('window storage event triggered with !e.newValue');
					return;
				}
				writeInCache(e.newValue);
			}
		});
	}

	const reset = () => {
		localStorage.removeItem(dataKey);
		localStorage.removeItem(ttlKey);
	};

	const muter = (mutation: mutation) => {
		// prendo i dati da localStorage e non dalla cache a causa dell'imutabilita' dei risultati di quest'ultima
		const data = JSON.parse(localStorage.getItem(dataKey));
		const result = mutation(data);
		const serialized = JSON.stringify(result);
		writeInCache(serialized);
		writeInLS(serialized);
	};

	const consumer: React.FC<CachedQueryConsumerProps> = ({ children }) => (
		<Query
			onCompleted={oncePerCall}
			query={query}
			skip={needLogin && !isLogged()}
			fetchPolicy={needToRefresh() ? undefined : 'cache-first'}
			errorPolicy={'all'}
		>
			{({
				error,
				loading,
				data,
			}: {
				loading: boolean;
				error?: ApolloError;
				data: any;
			}) => {
				const mutator =
					loading || error
						? () => {
								throw new Error('Cannot perform mutation in invalid query state');
						  }
						: muter;
				return children({ loading, data, error, mutator });
			}}
		</Query>
	);

	return {
		consumer,
		reset,
	};
}

export { cachedQueryFactory };
