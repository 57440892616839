import React from 'react';
import { Checkbox } from '@food/ui';
import { registerClass, currySC } from '@food/css-manager';
import { IDirectoryBooleanFilter } from '../Directory';
import { IFNTheme } from '../../utils/theme';

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	display: flex;
	align-items: center;
	
	.${sc('label')} {
		margin: 0;
	}
	
	.${sc('check')} {
		padding: 0;
		margin-left: .5em;
	}
`,
);

const sc = currySC(styleClass);

export const PrivateManifacturerFilter: React.FC<
	IDirectoryBooleanFilter
> = ({ value, setter, title }) => (
	<div className={styleClass}>
		<span className={sc('label')}>{title}</span>
		<Checkbox label="" className={sc('check')} onChange={setter} checked={value} />
	</div>
);
