import React from 'react';
import { IDirectoryChoiceFilter } from '../Directory';
// import { Checkbox, Tag } from '@food/ui';
import { Checkbox } from '@food/ui';
import { registerClass, currySC, cns } from '@food/css-manager';
import { IFNTheme } from '../../utils/theme';

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	
	.${sc('choice')} {
		margin-bottom: 2px;
		margin-right: 2px;
		
	}
	
	.${sc('choices')} {
		overflow-y: auto;
		
		> button {
			padding: ${t.ratios.xs / 4}rem 0;
		}
	}
	
	.${sc('non-checked')} {
		max-height: 10rem;
	}
`,
);

const sc = currySC(styleClass);

export const GenericChoiceFilter: React.FC<IDirectoryChoiceFilter> = ({
	choices,
	value,
	setter,
	facet,
	title,
}) => {
	const checked = choices.filter(
		(c) =>
			value &&
			(value.includes(c.id) || value.includes(c.id.substr(0, c.id.length - 4))),
	);
	const nonChecked = choices.filter((c) => checked.indexOf(c) === -1);
	const hasOneVoice =
		checked.length > 0 || nonChecked.some((c) => facet && facet(c) > 0);

	if (!hasOneVoice) {
		return null;
	}

	return (
		<div className={styleClass}>
			{title && <span className={sc('title')}>{title}:</span>}
			<div className={sc('choices')}>
				{checked.map((c) => {
					return (
						<Checkbox
							key={c.id}
							label={c.name}
							checked={true}
							onChange={() => setter(value.filter((v) => v !== c.id))}
						/>
					);
				})}
			</div>
			<div className={cns(sc('choices'), sc('non-checked'))}>
				{nonChecked.map((c) => {
					const f = facet ? facet(c) : 0;

					if (f === 0) {
						return null;
					}

					return (
						<Checkbox
							key={c.id}
							label={c.name}
							checked={false}
							onChange={() => setter((value || []).concat([c.id]))}
						/>
					);
				})}
			</div>
		</div>
	);
};
