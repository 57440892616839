import React from 'react';
import {
	track as originalTrack,
	useTracking as originalUseTracking,
	ExtendContext as OES,
	WhereBlock as WB,
} from '@food/tracking';
import { ITrackEvent, TTrackAction, TWhere } from '../tracking-types';
import * as git from '../static/rev.json';

export type ContextTransformerT = (context: Partial<ITrackEvent>) => Partial<ITrackEvent>;
export type TTrack = (action: TTrackAction, transformer?: ContextTransformerT) => void;

const track = (event: Partial<ITrackEvent>) => originalTrack(event);
const useTracking = (overrides: Partial<ITrackEvent> = {}): TTrack =>
	originalUseTracking(overrides);
const ExtendContext: React.FC<{
	transformer: (c: Partial<ITrackEvent>) => Partial<ITrackEvent>;
}> = OES;
const WhereBlock: React.FC<{
	step: TWhere;
}> = WB;

const getLastWhere = (
	wheres: ReadonlyArray<TWhere>,
	selector: (w: TWhere) => boolean = () => true,
): TWhere | undefined =>
	wheres
		.slice()
		.reverse()
		.find(selector);

const addWhere = (...where: ReadonlyArray<TWhere>): ContextTransformerT => (context) => ({
	...context,
	where: [...context.where, ...where],
});

const trackingBase = {
	software: {
		name: 'IFN', // IFN AUTH, ...
		version: 1,
		commit: (git as any).rev,
	},
};

export {
	track,
	useTracking,
	ExtendContext,
	WhereBlock,
	getLastWhere,
	addWhere,
	trackingBase,
};
