import React, { useCallback, useContext, useState } from 'react';
import { Box, Button, ButtonLayouts, IconPositions } from '@food/ui';
import { cns, currySC, registerClass } from '@food/css-manager';
import t from '../utils/labels';
import { IDirectoryFilterDefinition } from './Directory';
import { IFNTheme } from '../utils/theme';
import { ENTITY } from '../utils/entities';
import * as sfondo from '../static/assets/prova_pattern_2-02.svg.jpg';
import { DefaultSetter } from '../utils/misc';
import { loadable, LoadableDirection } from '../utils/loadable';
import { renderContext } from '../utils/context/renderContext';
import { loginMessageFactory } from './LoginMessage';
import { openRenewModal, UserData } from '@food/auth';
import { getLastWhere, useTracking } from '../utils/tracking';
import { TrackingContext } from '@food/tracking';
import { ACTIONS, generateUrl } from '../utils/urls';
import { modalHandler, sentryHandler } from '../utils/errors';
import { SaveFrequentSearchModal } from './SaveFrequentSearchModal';
import { client } from '../utils/client';
import CONFIG from '../static/config';
import gql from 'graphql-tag';
import { FrequentSearchCollection } from '../server-types';

export enum DirectoryFilterLayouts {
	COLLAPSED = 'COLLAPSED',
	EXPANDED = 'EXPANDED',
}

export interface IDirectoryFilterProps {
	filters: { [key: string]: IDirectoryFilterDefinition };
	filterValues: { [key: string]: any };
	layout: DirectoryFilterLayouts;
	loading: boolean;
	setter: (f: IDirectoryFilterDefinition) => DefaultSetter;
	filterData: any;
	data: any;
	entityType: ENTITY;
	onSave: (result: any) => void;
}

const singleFilterStyle = registerClass(
	(t: IFNTheme) => `
	margin-bottom: ${t.ratios.xl}rem;

	> div {
		> span {
			display: block;
			margin-bottom: ${t.ratios.xs}rem;
		}
	}
`,
);

const filtersStyle = registerClass(
	(t: IFNTheme, sc) => `
	flex: 0 0 ${10 * t.ratios.xl}rem;
	position: relative;
	

	> div > div {
		padding: 0 0 ${t.ratios.s}rem ${t.ratios.s}rem !important;
	}

	> header {
		color: ${t.colors.white};
		padding: ${t.ratios.s}rem;
		background-color: transparent !important;
	}
	
	h4,
	label {
		font-weight: ${t.font.weights.bold};
		color: ${t.colors.grey.dark};
	}


	&.${sc('filter-layout', 'COLLAPSED')} {
		flex: 0 0 0;
	}
	
	&.${sc('filter-layout', 'EXPANDED')} {
		margin-right: ${t.ratios.s}rem;

		> header {
			margin: 0;
		}
	}
	
	&.${sc('active')} {
		background-color: ${t.colors.grey.light};
	}
	
	&:not(.${sc('active')}) {
		background-image: url('${sfondo}');
	}
	
	.${sc('hidden')} {
		display: none;
	}
	
	.${sc('reset-block')} {
		text-align: center;
		margin-bottom: ${t.ratios.s}rem;
	}
	
	.${sc('save-fs-block')} {
		display: flex;
		flex-direction: column;
		align-items: center;
		
		> div:first-child {
			margin-bottom: ${t.ratios.s}rem;
		}
	}
`,
);

const sc = currySC(filtersStyle);
const valHasMoreChoices = (value) => Array.isArray(value) && value.length > 1;

type ISaveFrequentSearchMutation = (
	collection: string,
	values: { [key: string]: any },
	filters: {
		[key: string]: IDirectoryFilterDefinition;
	},
	data: any,
) => (name: string) => any;

const FrequentSearchSaveMutation = gql`
	mutation FrequentSearchSaveMutation($values: FrequentSearchCreateInput!) {
		frequentSearchCreate(values: $values) {
			id
		}
	}
`;

const saveFrequentSearchMutation: ISaveFrequentSearchMutation = (
	collection,
	values,
	filters,
	data,
) => {
	if (!data) {
		return () => null;
	}
	const parsedValues = Object.keys(values).reduce((result, key) => {
		const serializer = filters[key].serializer;
		result[key] = serializer ? serializer(values[key], data) : values[key];
		return result;
	}, {});

	return async (name: string) => {
		try {
			const result = await client.mutate({
				mutation: FrequentSearchSaveMutation,
				variables: {
					values: {
						name,
						nameIta: name,
						targetSites: [CONFIG.TARGET_SITE],
						collection,
						...parsedValues,
					},
				},
			});
			return { result };
		} catch (error) {
			return { error };
		}
	};
};

export const DirectoryFilter: React.FC<IDirectoryFilterProps> = ({
	layout,
	filters,
	filterValues,
	loading,
	setter,
	filterData,
	data,
	entityType,
	onSave,
}) => {
	const trackingContext = useContext(TrackingContext);

	// la seconda condizione del where viene impostata su un blocco fasullo con "Error" nel nome
	// questo perche', nel caso in cui il software fosse ben impostato, non dovrebbe mai essere "undefined" il risultato
	// di "getLastWhere"; questo pero' il compilatore non puo' saperlo (essendo un array potenzialmente vuoto), quindi
	// mi restituisce un errore di compilazione. In ultima analisi, sto solo correggendo un limite legiittimo di typescript
	const where = getLastWhere(
		trackingContext.where,
		(w) => w.type === 'CompanyDirectory' || w.type === 'GoodDirectory',
	) || { type: 'Block', name: 'Error Directory Filter' };

	const track = useTracking();
	const { isBot } = useContext(renderContext);
	const [modalOpen, setModalOpen] = useState(false);

	const notifyResetFilters = useCallback(() => {
		track({ type: 'filter_reset' });
	}, [track]);

	const filtersWithComponents = Object.keys(filters)
		.filter((key) => !!filters[key].component)
		.map((key) => filters[key]);
	const setFilterNumbers = Object.keys(filterValues).length;
	const tagFilters = Object.values(filters).filter((f) => f.tagList);
	const hasMoreFilters =
		setFilterNumbers > 1 ||
		valHasMoreChoices(filterValues[Object.keys(filterValues)[0]]);

	// eseguo gli estrattori dei filtri con componenti perche' sia possibile eseguirvi
	// operazioni dispendiose una sola volta
	const computedFiltersData = filtersWithComponents.reduce((res, filterDefinition) => {
		res[filterDefinition.key] = (
			filterDefinition.extractor || ((data) => data[filterDefinition.key])
		)(filterData);
		return res;
	}, {});

	return (
		<UserData>
			{({ isLogged }) => (
				<>
					<Box
						className={cns(
							filtersStyle,
							sc('filter-layout', layout),
							loadable(isBot, LoadableDirection.LEFT),
						)}
						title={
							t('Filters') +
							(setFilterNumbers > 0 && layout === DirectoryFilterLayouts.COLLAPSED
								? ' (' + setFilterNumbers + ')'
								: '')
						}
						collapsible={layout === DirectoryFilterLayouts.COLLAPSED}
						startOpen={false}
					>
						{setFilterNumbers > 0 && (
							<div className={sc('reset-block')}>
								<Button
									layout={ButtonLayouts.LINK}
									type={'danger'}
									label={t`Reset all filters`}
									onClick={notifyResetFilters}
									icon={'close'}
									iconPos={IconPositions.RIGHT}
									linkTo={generateUrl(entityType, ACTIONS.LIST)}
								/>
							</div>
						)}
						{tagFilters
							.filter((f) => filterValues[f.key])
							.map((f) => (
								<div key={f.key} className={singleFilterStyle}>
									{f.tagList && f.tagList(data, setter(f), filterValues[f.key])}
								</div>
							))}
						{filtersWithComponents.map((f) => (
							<div className={singleFilterStyle} key={f.key}>
								<f.component
									layout={layout}
									loading={loading}
									title={f.title}
									setter={setter(f)}
									choices={computedFiltersData[f.key]}
									toLabel={f.toLabel || ((e) => e.name)}
									value={filterValues[f.key]}
									facet={f.facetExtractor && f.facetExtractor(data, filterData)}
								/>
							</div>
						))}
						{hasMoreFilters && (
							<div className={sc('save-fs-block')}>
								<div
									dangerouslySetInnerHTML={{
										__html: t`save dynamic list intro`,
									}}
								/>
								<Button
									type="success"
									label={t`Save as a Dynamic List`}
									onClick={() => {
										if (isLogged) {
											track({
												type: 'open_new_dynamic_list_modal',
												source: where,
											});
											setModalOpen(true);
										} else {
											track({
												type: 'requested_authentication_notlogged',
												reason: 'open_new_dynamic_list_modal',
											});
											openRenewModal({
												customComponent: loginMessageFactory(),
											});
										}
									}}
									icon={'search'}
									iconPos={IconPositions.RIGHT}
								/>
							</div>
						)}
					</Box>
					<SaveFrequentSearchModal
						open={modalOpen}
						onClose={() => {
							track({ type: 'close_new_dynamic_list_modal' });
							setModalOpen(false);
						}}
						mutation={saveFrequentSearchMutation(
							entityType === ENTITY.GOOD
								? FrequentSearchCollection.Good
								: FrequentSearchCollection.Company,
							filterValues,
							filters,
							filterData,
						)}
						onSave={(name, { result, error }) => {
							if (error) {
								sentryHandler([error]);
								track({
									type: 'save_dynamic_list_error',
									filterStatus: filterValues,
									name,
									error,
								});
								modalHandler([error]);
								console.error(error);
							} else {
								setModalOpen(false);
								track({
									type: 'saved_dynamic_list',
									filterStatus: filterValues,
									name,
								});
								onSave(result);
							}
						}}
					/>
				</>
			)}
		</UserData>
	);
};
