import React from 'react';
import gql from 'graphql-tag';
import { Box, Button, ButtonLayouts, IconPositions } from '@food/ui';
import { cns, currySC, IFNTheme, registerClass, SCType } from '@food/css-manager';
import { openRenewModal, UserData } from '@food/auth';
import { ENTITY } from '../utils/entities';
import t from '../utils/labels';
import { loadable, LoadableDirection } from '../utils/loadable';
import { useTracking } from '../utils/tracking';
import { Company, DistributionChannel, Good, QuestionSetRequest } from '../server-types';
import { QuestionSetCreationModal } from './entities/QuestionSetCreationModal';

interface IDistributionChannelBlockProps {
	entities: ReadonlyArray<DistributionChannel>;
	company: Company;
	good?: Good;
	className?: string;
	psc: SCType;
	hasPaymentLevel: boolean;
}

export const distributionChannelBlockFragment = gql`
	fragment distributionChannelBlockFragment on DistributionChannel {
		id
		mode
		channels
		contactEmail
		note
		countries {
			id
			name
		}
		catalogMedia {
			id
			origUrl
		}
	}
`;

const styleClass = registerClass(
	(theme: IFNTheme, sc) => `
	
	.${sc('message')} {
		text-align: center;
		line-height: ${theme.ratios.xl};
		
		> a, button {
			margin-top: ${theme.ratios.l / 2}rem;
			margin-bottom: ${theme.ratios.l / 2}rem;
		}
	}
	
	.${sc('link')} {
		color: ${theme.colors.alt.plain};
		text-decoration: underline;
	}
	
	.${sc('notes')} {
		line-height: ${theme.ratios.xl}em;
		margin-bottom: ${theme.ratios.l / 2}rem;
		margin-top: ${theme.ratios.l / 2}rem;
	}
	
	.${sc('line')} {
		&:after {
			clear: both;
			content: ' ';
			display: block;
		}
	}
	
	.${sc('with-margin')} {
		margin-right: ${6 * theme.ratios.l}rem;
	}
	
	hr {
		margin-top: ${theme.ratios.l / 2}rem;
		margin-bottom: ${theme.ratios.l}rem;
	}
	
	.${sc('label')} {
		margin-bottom: ${theme.ratios.l / 2}rem;
		margin-top: ${theme.ratios.l / 2}rem;
		display: inline-block;
	}
	
	.${sc('download-container')} {
		float: right;
	}

	.${sc('instant-actions')} {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: ${theme.ratios.xl / 4}rem;
		
		> a, button {
			margin: ${theme.ratios.xl / 4}rem ${theme.ratios.xl / 2}rem;
		}
	}
`,
);

const sc = currySC(styleClass);
const boxTitle = t`distribution channel box`;
const getCountryNameByManaged = (user: any) =>
	user &&
	user.managedByUsers &&
	Array.isArray(user.managedByUsers) &&
	user.managedByUsers[0] &&
	user.managedByUsers[0].Retailer &&
	user.managedByUsers[0].Retailer.Country &&
	user.managedByUsers[0].Retailer.Country.name;

export const DistributionChannelBlock: React.FC<IDistributionChannelBlockProps> = ({
	entities,
	className,
	psc,
	company,
	hasPaymentLevel,
	good,
}) => {
	const track = useTracking();

	if (!hasPaymentLevel) {
		return null;
	}

	return (
		<UserData>
			{({ isLogged, userIsBuyer, userData }) => {
				if (isLogged && userIsBuyer && entities.length > 0) {
					const countryName = getCountryNameByManaged(userData);

					return (
						<Box
							title={
								countryName
									? t`Distribution channels in` + ' ' + countryName
									: boxTitle
							}
							className={cns(
								styleClass,
								loadable(false, LoadableDirection.BOTTOM),
								className,
							)}
						>
							{entities.map((dc, index) => (
								<div key={dc.id} className={sc('distribution-channel')}>
									{index !== 0 && <hr />}
									{dc.note && <div className={sc('notes')}>{dc.note}</div>}
									<div className={sc('line')}>
										<span className={sc('with-margin')}>
											<span className={cns(psc('label'), sc('label'))}>
												{t`distributionChannel mode`}:{' '}
											</span>
											{t(`${ENTITY.DISTRIBUTION_CHANNEL}/channels/${dc.mode}`)}
										</span>
										<span>
											<span className={cns(psc('label'), sc('label'))}>
												{t`distributionChannel channels`}:
											</span>{' '}
											{dc.channels
												.map((e) =>
													t(`${ENTITY.DISTRIBUTION_CHANNEL}/channels/${e}`),
												)
												.join(', ')}
										</span>
									</div>
									{(dc.contactEmail || dc.catalogMedia) && (
										<div className={cns(sc('line'), sc('apart'))}>
											{dc.contactEmail ? (
												<span className={cns(sc('with-margin'), sc('label'))}>
													<span className={psc('label')}>
														{t`distributionChannel contact email`}:{' '}
													</span>
													<a
														className={sc('link')}
														href={'mailto:' + dc.contactEmail}
														onClick={() =>
															track({
																type: 'generic_click',
																name:
																	'email to distribution channel email',
																isExternal: false,
																url: dc.contactEmail,
															})
														}
													>
														{dc.contactEmail}
													</a>
												</span>
											) : (
												<span>&nbsp;</span>
											)}
											{dc.catalogMedia && (
												<div className={sc('download-container')}>
													<Button
														className={cns(sc('label'), sc('left'))}
														layout={ButtonLayouts.BUTTON}
														label={t`download catalogue btn`}
														linkTo={dc.catalogMedia.origUrl}
														iconPos={IconPositions.RIGHT}
														icon={'file_download'}
														linkTarget={'_blank'}
														type={'success'}
														onClick={() => {
															track({
																type: 'generic_click',
																isExternal: false,
																name: 'catalogue download',
																// il compilatore non capisce che, in questo caso, "dc.catalogMedia"
																// esiste per forza. Aggiungo un controllo forzato..
																url: dc.catalogMedia
																	? dc.catalogMedia.origUrl
																	: '',
															});
														}}
													/>
												</div>
											)}
										</div>
									)}
								</div>
							))}
						</Box>
					);
				} else {
					const countryName = getCountryNameByManaged(userData);

					return (
						<Box
							title={
								countryName
									? t`Distribution channels in` + ' ' + countryName
									: boxTitle
							}
							className={cns(
								styleClass,
								loadable(false, LoadableDirection.BOTTOM),
								className,
							)}
						>
							<div className={sc('message')}>
								<p>
									{t`no distribution channel in company_pre`} {company.name} {t`no distribution channel in company`}
								</p>
								<div className={sc('instant-actions')}>
								{!isLogged && (
									<Button
										label={t`Sign in`}
										onClick={() => openRenewModal()}
										type={'success'}
									/>
								)}
									<QuestionSetCreationModal
										company={company}
										good={good}
										openComponent={({ opener }) => (
											<Button
												label={t`contact_us`}
												layout={ButtonLayouts.BUTTON}
												iconPos={IconPositions.RIGHT}
												icon={'people'}
												onClick={() =>
													opener(
														t`request_catalogue`,
														QuestionSetRequest.Catalog,
													)
												}
												type={'success'}
											/>
										)}
									/>
								</div>
							</div>
						</Box>
					);
				}
			}}
		</UserData>
	);
};
