export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** The DateTime scalar type represents date time strings complying to ISO-8601. */
  DateTime: any,
  /** The URL scalar type represents URL addresses. */
  URL: any,
  /** The Email scalar type represents E-Mail addresses compliant to RFC 822. */
  Email: any,
  /** 
 * The `Int53` scalar type represents non-fractional signed whole numeric values.
   * Int53 can represent values between -(2^53 - 1) and 2^53 - 1. 
 */
  Int53: any,
  /** The `Upload` scalar type represents a file upload. */
  Upload: any,
};

/** {"description":"Abbonamento","canCreateRoles":{"INTERNAL":"MARKETING_EXTRA"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE","CONSUMER":true,"PRODUCER":"EDIT","BUYER":"EDIT","BUYER_PL_ITALIA":"EDIT"},"canDeleteRoles":{"INTERNAL":"MARKETING_EXTRA"}} */
export type Abbonamento = Node & {
   __typename?: 'Abbonamento',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"Record ID","required":false,"readonly":true,"max":18} */
  Id?: Maybe<Scalars['String']>,
  /** {"description":"Nome Abbonamento","required":false,"max":80} */
  Name?: Maybe<Scalars['String']>,
  /** {"description":"Inizio","dtPrecision":"DAY_END"} */
  Inizio?: Maybe<Scalars['DateTime']>,
  /** {"description":"Fine","dtPrecision":"DAY_END"} */
  Fine?: Maybe<Scalars['DateTime']>,
  /** {"description":"Attivo","required":true} */
  Attivo_Form: Scalars['Boolean'],
  /** {"description":"Indirizzo","allowFormInlining":true} */
  Indirizzo?: Maybe<Indirizzo>,
  /** {"description":"N°copie"} */
  N_copie?: Maybe<Scalars['Float']>,
  /** {"description":"Rivista"} */
  Rivista?: Maybe<Abbonamento__C_Rivista__C_Enum>,
  /** {"description":"Giorni alla scadenza"} */
  Giorni_alla_scadenza?: Maybe<Scalars['Float']>,
  /** {"description":"Package"} */
  Package?: Maybe<Abbonamento__C_Package__C_Enum>,
  /** {"allowFormInlining":true} */
  Abbonamento_Periodi: Array<AbbonamentoPeriodo>,
  /** {} */
  accountCompany?: Maybe<Company>,
  /** {} */
  accountCompanyGroup?: Maybe<CompanyGroup>,
  /** {} */
  accountService?: Maybe<Service>,
  /** {} */
  accountRetailer?: Maybe<Retailer>,
  /** {} */
  accountGeoIndication?: Maybe<GeoIndication>,
  /** {} */
  referenteUser?: Maybe<User>,
};

/** SF picklist */
export enum Abbonamento__C_Package__C_Enum {
  /** BB - Basic */
  BbBasic = 'BB___Basic',
  /** BB - Premium */
  BbPremium = 'BB___Premium',
  /** DOLCESALATO_Cartacei e Sfogliabili Digitali */
  DolcesalatoCartaceiESfogliabiliDigitali = 'DOLCESALATO_Cartacei_e_Sfogliabili_Digitali',
  /** DOLCESALATO_Sfogliabili Digitali */
  DolcesalatoSfogliabiliDigitali = 'DOLCESALATO_Sfogliabili_Digitali',
  /** FOOD_Cartacei e Sfogliabili Digitali */
  FoodCartaceiESfogliabiliDigitali = 'FOOD_Cartacei_e_Sfogliabili_Digitali',
  /** FOOD_Sfogliabili Digitali */
  FoodSfogliabiliDigitali = 'FOOD_Sfogliabili_Digitali',
  /** FOODSERVICE_Cartacei e Sfogliabili Digitali */
  FoodserviceCartaceiESfogliabiliDigitali = 'FOODSERVICE_Cartacei_e_Sfogliabili_Digitali',
  /** FOODSERVICE_Sfogliabili Digitali */
  FoodserviceSfogliabiliDigitali = 'FOODSERVICE_Sfogliabili_Digitali',
  /** ITALIANFOOD.NET_Cartacei e Sfogliabili Digitali */
  ItalianfoodNetCartaceiESfogliabiliDigitali = 'ITALIANFOOD_NET_Cartacei_e_Sfogliabili_Digitali',
  /** ITALIANFOOD.NET_Sfogliabili Digitali */
  ItalianfoodNetSfogliabiliDigitali = 'ITALIANFOOD_NET_Sfogliabili_Digitali'
}

/** SF picklist */
export enum Abbonamento__C_Rivista__C_Enum {
  /** FOOD */
  Food = 'FOOD',
  /** Dolcesalato */
  Dolcesalato = 'Dolcesalato',
  /** Bar Business */
  BarBusiness = 'Bar_Business',
  /** ITALIANFOOD.NET */
  ItalianfoodNet = 'ITALIANFOOD_NET',
  /** Food Service */
  FoodService = 'Food_Service'
}

/** SF picklist */
export enum Abbonamento_Periodo__C_Provenienza__C_Enum {
  /** CC postale */
  CcPostale = 'CC_postale',
  /** Paypal */
  Paypal = 'Paypal',
  /** Contanti */
  Contanti = 'Contanti',
  /** Carta di credito */
  CartaDiCredito = 'Carta_di_credito',
  /** Bonifico Bancario */
  BonificoBancario = 'Bonifico_Bancario',
  /** Omaggio Aziendale */
  OmaggioAziendale = 'Omaggio_Aziendale',
  /** Assegno Bancario */
  AssegnoBancario = 'Assegno_Bancario',
  /** Omaggio con scadenza */
  OmaggioConScadenza = 'Omaggio_con_scadenza',
  /** Omaggio Paolo */
  OmaggioPaolo = 'Omaggio_Paolo',
  /** Omaggio Davide */
  OmaggioDavide = 'Omaggio_Davide',
  /** Omaggio Valentina */
  OmaggioValentina = 'Omaggio_Valentina',
  /** Omaggio Cristina */
  OmaggioCristina = 'Omaggio_Cristina',
  /** Omaggio Nicola */
  OmaggioNicola = 'Omaggio_Nicola',
  /** Omaggio Agente */
  OmaggioAgente = 'Omaggio_Agente',
  /** Omaggio Annabella */
  OmaggioAnnabella = 'Omaggio_Annabella',
  /** Omaggio Fochi */
  OmaggioFochi = 'Omaggio_Fochi',
  /** Omaggio Bosini */
  OmaggioBosini = 'Omaggio_Bosini'
}

/** A connection to a list of items. */
export type AbbonamentoConnection = {
   __typename?: 'AbbonamentoConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AbbonamentoEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type AbbonamentoCreateInput = {
  /** {"description":"Nome Abbonamento","required":false,"max":80} */
  Name?: Maybe<Scalars['String']>,
  /** {"description":"Inizio","dtPrecision":"DAY_END"} */
  Inizio?: Maybe<Scalars['DateTime']>,
  /** {"description":"Fine","dtPrecision":"DAY_END"} */
  Fine?: Maybe<Scalars['DateTime']>,
  /** {"description":"Indirizzo","allowFormInlining":true} */
  Indirizzo?: Maybe<RefInputObject>,
  /** {"description":"Indirizzo","allowFormInlining":true} */
  Indirizzo__inline?: Maybe<Indirizzo_From_Abbonamento_Type>,
  /** {"description":"N°copie"} */
  N_copie?: Maybe<Scalars['Float']>,
  /** {"description":"Rivista"} */
  Rivista?: Maybe<Abbonamento__C_Rivista__C_Enum>,
  /** {"description":"Package"} */
  Package?: Maybe<Abbonamento__C_Package__C_Enum>,
  /** {"allowFormInlining":true} */
  Abbonamento_Periodi?: Maybe<Array<RefInputObject>>,
  /** {"allowFormInlining":true} */
  Abbonamento_Periodi__inline?: Maybe<Array<AbbonamentoPeriodo_From_Abbonamento_Type>>,
  /** {} */
  accountCompany?: Maybe<RefInputObject>,
  /** {} */
  accountCompanyGroup?: Maybe<RefInputObject>,
  /** {} */
  accountService?: Maybe<RefInputObject>,
  /** {} */
  accountRetailer?: Maybe<RefInputObject>,
  /** {} */
  accountGeoIndication?: Maybe<RefInputObject>,
  /** {} */
  referenteUser?: Maybe<RefInputObject>,
};

/** An edge in a connection. */
export type AbbonamentoEdge = {
   __typename?: 'AbbonamentoEdge',
  /** The item at the end of the edge */
  node?: Maybe<Abbonamento>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type AbbonamentoFilterInput = {
  Rivista?: Maybe<Abbonamento__C_Rivista__C_Enum>,
  isActive?: Maybe<Scalars['Boolean']>,
};

export type AbbonamentoPatchInput = {
  /** {"description":"Nome Abbonamento","required":false,"max":80} */
  Name?: Maybe<Scalars['String']>,
  /** {"description":"Inizio","dtPrecision":"DAY_END"} */
  Inizio?: Maybe<Scalars['DateTime']>,
  /** {"description":"Fine","dtPrecision":"DAY_END"} */
  Fine?: Maybe<Scalars['DateTime']>,
  /** {"description":"Indirizzo","allowFormInlining":true} */
  Indirizzo?: Maybe<RefInputObject>,
  /** {"description":"Indirizzo","allowFormInlining":true} */
  Indirizzo__inline?: Maybe<Indirizzo_From_Abbonamento_Type>,
  /** {"description":"N°copie"} */
  N_copie?: Maybe<Scalars['Float']>,
  /** {"description":"Rivista"} */
  Rivista?: Maybe<Abbonamento__C_Rivista__C_Enum>,
  /** {"description":"Package"} */
  Package?: Maybe<Abbonamento__C_Package__C_Enum>,
  /** {"allowFormInlining":true} */
  Abbonamento_Periodi?: Maybe<Array<RefInputObject>>,
  /** {"allowFormInlining":true} */
  Abbonamento_Periodi__inline?: Maybe<Array<AbbonamentoPeriodo_From_Abbonamento_Type>>,
  /** {} */
  accountCompany?: Maybe<RefInputObject>,
  /** {} */
  accountCompanyGroup?: Maybe<RefInputObject>,
  /** {} */
  accountService?: Maybe<RefInputObject>,
  /** {} */
  accountRetailer?: Maybe<RefInputObject>,
  /** {} */
  accountGeoIndication?: Maybe<RefInputObject>,
  /** {} */
  referenteUser?: Maybe<RefInputObject>,
};

/** {"description":"Abbonamento - Periodo","canCreateRoles":false,"canPatchRoles":false,"canDeleteRoles":false} */
export type AbbonamentoPeriodo = Node & {
   __typename?: 'AbbonamentoPeriodo',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"Record ID","required":false,"readonly":true,"max":18} */
  Id?: Maybe<Scalars['String']>,
  /** {"description":"Abbonamento - Periodo Name","required":false,"readonly":true,"max":80} */
  Name?: Maybe<Scalars['String']>,
  /** {"description":"Abbonamento","required":true,"readonly":true} */
  Abbonamento: Abbonamento,
  /** {"description":"Data di Inizio","dtPrecision":"DAY_END"} */
  Data_di_Inizio?: Maybe<Scalars['DateTime']>,
  /** {"description":"Data di Fine","dtPrecision":"DAY_END"} */
  Data_di_Fine?: Maybe<Scalars['DateTime']>,
  /** {"description":"Quote Line Item"} */
  Quote_Line_Item?: Maybe<QuoteLineItem>,
  /** {"description":"Provenienza"} */
  Provenienza?: Maybe<Abbonamento_Periodo__C_Provenienza__C_Enum>,
  /** {"description":"Approvato","required":false} */
  Approvato?: Maybe<Scalars['Boolean']>,
  /** {"description":"Stripe Transaction ID","max":32} */
  Stripe_Transaction_ID?: Maybe<Scalars['String']>,
  /** {"description":"Account Name","max":64} */
  Account_Name?: Maybe<Scalars['String']>,
  /** {"description":"Privato","required":false} */
  Privato?: Maybe<Scalars['Boolean']>,
  /** {"description":"First Name","max":64} */
  First_Name?: Maybe<Scalars['String']>,
  /** {"description":"Last Name","max":64} */
  Last_Name?: Maybe<Scalars['String']>,
  /** {"description":"Partita IVA","max":13} */
  Partita_IVA?: Maybe<Scalars['String']>,
  /** {"description":"Billing Country","max":32} */
  Billing_Country?: Maybe<Scalars['String']>,
  /** {"description":"Billing Street","max":128} */
  Billing_Street?: Maybe<Scalars['String']>,
  /** {"description":"Billing City","max":64} */
  Billing_City?: Maybe<Scalars['String']>,
  /** {"description":"Billing Postal Code","max":9} */
  Billing_Postal_Code?: Maybe<Scalars['String']>,
  /** {"description":"Billing Province State [Cod.]","max":3} */
  Billing_Province_State_Cod?: Maybe<Scalars['String']>,
  /** {"description":"Codice Fiscale","max":16} */
  Codice_Fiscale?: Maybe<Scalars['String']>,
  /** {"description":"Codice Fiscale Estero","max":16} */
  Codice_Fiscale_Estero?: Maybe<Scalars['String']>,
  /** {"description":"Billing Email","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  Billing_Email?: Maybe<Scalars['Email']>,
};

export type AbbonamentoPeriodo_From_Abbonamento_Type = {
  /** {"description":"Abbonamento - Periodo Name","required":false,"readonly":true,"max":80} */
  Name?: Maybe<Scalars['String']>,
  /** {"description":"Data di Inizio","dtPrecision":"DAY_END"} */
  Data_di_Inizio?: Maybe<Scalars['DateTime']>,
  /** {"description":"Data di Fine","dtPrecision":"DAY_END"} */
  Data_di_Fine?: Maybe<Scalars['DateTime']>,
  /** {"description":"Quote Line Item"} */
  Quote_Line_Item?: Maybe<RefInputObject>,
  /** {"description":"Provenienza"} */
  Provenienza?: Maybe<Abbonamento_Periodo__C_Provenienza__C_Enum>,
  /** {"description":"Approvato","required":false} */
  Approvato?: Maybe<Scalars['Boolean']>,
  /** {"description":"Stripe Transaction ID","max":32} */
  Stripe_Transaction_ID?: Maybe<Scalars['String']>,
  /** {"description":"Account Name","max":64} */
  Account_Name?: Maybe<Scalars['String']>,
  /** {"description":"Privato","required":false} */
  Privato?: Maybe<Scalars['Boolean']>,
  /** {"description":"First Name","max":64} */
  First_Name?: Maybe<Scalars['String']>,
  /** {"description":"Last Name","max":64} */
  Last_Name?: Maybe<Scalars['String']>,
  /** {"description":"Partita IVA","max":13} */
  Partita_IVA?: Maybe<Scalars['String']>,
  /** {"description":"Billing Country","max":32} */
  Billing_Country?: Maybe<Scalars['String']>,
  /** {"description":"Billing Street","max":128} */
  Billing_Street?: Maybe<Scalars['String']>,
  /** {"description":"Billing City","max":64} */
  Billing_City?: Maybe<Scalars['String']>,
  /** {"description":"Billing Postal Code","max":9} */
  Billing_Postal_Code?: Maybe<Scalars['String']>,
  /** {"description":"Billing Province State [Cod.]","max":3} */
  Billing_Province_State_Cod?: Maybe<Scalars['String']>,
  /** {"description":"Codice Fiscale","max":16} */
  Codice_Fiscale?: Maybe<Scalars['String']>,
  /** {"description":"Codice Fiscale Estero","max":16} */
  Codice_Fiscale_Estero?: Maybe<Scalars['String']>,
  /** {"description":"Billing Email","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  Billing_Email?: Maybe<Scalars['Email']>,
};

/** Per accesso in base al role. Flags */
export enum AccessRole {
  Anonymus = 'ANONYMUS',
  Internal = 'INTERNAL',
  Producer = 'PRODUCER',
  Buyer = 'BUYER',
  BuyerPlItalia = 'BUYER_PL_ITALIA',
  Consumer = 'CONSUMER'
}

export enum AccountType {
  Industry = 'INDUSTRY',
  RetailPl = 'RETAIL_PL',
  RetailNoPl = 'RETAIL_NO_PL',
  Institution = 'INSTITUTION',
  Service = 'SERVICE'
}

/** {"description":"Usato per Ask the Company","canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA","BUYER":true,"BUYER_PL_ITALIA":true},"canPatchRoles":{"INTERNAL":"DIRECTORY_EXTRA","PRODUCER":"EDIT","BUYER":true,"BUYER_PL_ITALIA":true},"canDeleteRoles":{"INTERNAL":"DIRECTORY_EXTRA"}} */
export type AnswerSet = Node & {
   __typename?: 'AnswerSet',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"readonly":true} */
  QuestionSet: QuestionSet,
  /** {"readonly":true} */
  Company: Company,
  /** {"description":"Inquired Goods"} */
  goods: Array<Good>,
  /** {"description":"Data/ora di invio","dtPrecision":"FULL"} */
  sentDate?: Maybe<Scalars['DateTime']>,
  /** {} */
  isSeen: Scalars['Boolean'],
  /** {} */
  exclusionReasons: Array<ExclusionReason>,
  /** {} */
  SampleRequest?: Maybe<SampleRequest>,
  /** {"mediaType":"PDF_GENERIC"} */
  catalogMedia?: Maybe<Media>,
  /** {"multiline":false,"max":250} */
  answer1?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer2?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer3?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer4?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer5?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer6?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer7?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer8?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer9?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer10?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer11?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer12?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer13?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer14?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer15?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer16?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer17?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer18?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer19?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer20?: Maybe<Scalars['String']>,
};

export type AnswerSet_From_QuestionSet_Type = {
  /** {"readonly":true} */
  Company: RefInputObject,
  /** {"description":"Inquired Goods"} */
  goods?: Maybe<Array<RefInputObject>>,
  /** {} */
  isSeen: Scalars['Boolean'],
  /** {} */
  exclusionReasons?: Maybe<Array<ExclusionReason>>,
  /** {} */
  SampleRequest?: Maybe<RefInputObject>,
  /** {"mediaType":"PDF_GENERIC"} */
  catalogMedia?: Maybe<RefInputObject>,
  /** {"multiline":false,"max":250} */
  answer1?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer2?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer3?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer4?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer5?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer6?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer7?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer8?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer9?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer10?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer11?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer12?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer13?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer14?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer15?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer16?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer17?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer18?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer19?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer20?: Maybe<Scalars['String']>,
};

/** A connection to a list of items. */
export type AnswerSetConnection = {
   __typename?: 'AnswerSetConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AnswerSetEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

/** An edge in a connection. */
export type AnswerSetEdge = {
   __typename?: 'AnswerSetEdge',
  /** The item at the end of the edge */
  node?: Maybe<AnswerSet>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type AnswerSetFilterInput = {
  isClosed?: Maybe<Scalars['Boolean']>,
  isSent?: Maybe<Scalars['Boolean']>,
};

export type AnswerSetPatchInput = {
  /** {"description":"Inquired Goods"} */
  goods?: Maybe<Array<RefInputObject>>,
  /** {} */
  isSeen?: Maybe<Scalars['Boolean']>,
  /** {} */
  exclusionReasons?: Maybe<Array<ExclusionReason>>,
  /** {} */
  SampleRequest?: Maybe<RefInputObject>,
  /** {"mediaType":"PDF_GENERIC"} */
  catalogMedia?: Maybe<RefInputObject>,
  /** {"multiline":false,"max":250} */
  answer1?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer2?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer3?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer4?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer5?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer6?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer7?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer8?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer9?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer10?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer11?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer12?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer13?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer14?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer15?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer16?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer17?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer18?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer19?: Maybe<Scalars['String']>,
  /** {"multiline":false,"max":250} */
  answer20?: Maybe<Scalars['String']>,
};

/** {"description":"@TODO DESCRIBE ME"} */
export type AtecoCode = Node & {
   __typename?: 'AtecoCode',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  code: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  description: Scalars['String'],
};

/** A connection to a list of items. */
export type AtecoCodeConnection = {
   __typename?: 'AtecoCodeConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AtecoCodeEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

/** An edge in a connection. */
export type AtecoCodeEdge = {
   __typename?: 'AtecoCodeEdge',
  /** The item at the end of the edge */
  node?: Maybe<AtecoCode>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type AtecoCodeFilterInput = {
  /** Contained in code or description */
  name?: Maybe<Scalars['String']>,
  /** Contained in code */
  code?: Maybe<Scalars['String']>,
  /** Contained in description */
  description?: Maybe<Scalars['String']>,
};

export enum AtecoCodeOrderBy {
  Code = 'code',
  Description = 'description'
}

/** 
 * {"description":"Ci sono i label dei rating/commenti specifici. Se NULL o \"\" il
 * campo non viene usato per questo Award.\nTODO:\n- campi per
 * grafica/sponsor/ecc.\n- label/attivazione campi per candidatura\n- azione
 * schedulata quando scade una fase?","canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"},"canSetStatusRoles":{"status":{"DRAFT":{"INTERNAL":"MARKETING_BASE"},"CANDIDANCY_OPEN":{"INTERNAL":"MARKETING_BASE"},"CANDIDANCY_CLOSED":{"INTERNAL":"MARKETING_BASE"},"VOTING_OPEN":{"INTERNAL":"MARKETING_BASE"},"VOTING_CLOSED":{"INTERNAL":"MARKETING_BASE"},"CONCLUDED":{"INTERNAL":"MARKETING_BASE"}}}}
 */
export type Award = Node & {
   __typename?: 'Award',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO"} */
  slug: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  edition?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":2100,"min":2000} */
  year: Scalars['Int'],
  /** {} */
  targetSites: Array<TargetSite>,
  /** {"description":"Default en_US"} */
  langCode?: Maybe<Scalars['String']>,
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>,
  /** {} */
  status: AwardStatus,
  /** {"required":true,"readonly":true} */
  managerUser?: Maybe<User>,
  /** {} */
  visibility: AwardVisibility,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  vademecumCompany?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  vademecumGoods?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  vademecumCandidacies?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  vademecumInvited?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionForProducer?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionForVoter?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionProducerHighlights?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  publicDescription?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  eventDescription?: Maybe<Scalars['String']>,
  /** {"description":"Magari poi aggiungiamo un campo delle candidature aggiuntive per le premium?","max":100,"min":1} */
  maxPerCompanyCandidacies: Scalars['Int'],
  /** 
 * {"description":"Se =1 nella candidatura prendo quasi tutti i campi dal Good
   * (che scelgo subito) e non li mostro nemmeno","max":100,"min":1}
 */
  maxGoodsPerCandidacy: Scalars['Int'],
  /** {"description":"GMT + countdown","dtPrecision":"DAY_END"} */
  candidaciesCloseAt?: Maybe<Scalars['DateTime']>,
  /** {"description":"GMT + countdown","dtPrecision":"DAY_END"} */
  votesCloseAt?: Maybe<Scalars['DateTime']>,
  /** {} */
  autovoterRoles: Array<AccessRole>,
  /** {} */
  options: Array<AwardOptionFlag>,
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  supportEmail?: Maybe<Scalars['Email']>,
  /** {"mediaType":"PDF_WEB"} */
  regulationsMedia?: Maybe<Media>,
  /** {"mediaType":"PDF_WEB"} */
  contractMedia?: Maybe<Media>,
  /** {} */
  extraMediaType?: Maybe<MediaType>,
  /** {"description":"@TODO DESCRIBE ME"} */
  extraMediaLabel?: Maybe<Scalars['String']>,
  /** {"description":"External website url","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>,
  /** {} */
  categories: Array<AwardCategory>,
  /** {} */
  candidacies: Array<Candidacy>,
  /** {} */
  invitedUsers: Array<AwardInvitedUser>,
  /** {} */
  invitedCompanies?: Maybe<AwardInvitedCompanyConnection>,
  /** {} */
  companyForm?: Maybe<Scalars['String']>,
  /** {} */
  candidacyForm?: Maybe<Scalars['String']>,
  /** {"description":"Se NULL o \"\" il campo uso come label il valore di default"} */
  ratingOverallLabel?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  rating1Label?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  rating2Label?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  rating3Label?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  rating4Label?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  rating5Label?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  comment1Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  comment1Max?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  comment2Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  comment2Max?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  comment3Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  comment3Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description1Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description1Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description2Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description2Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description3Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description3Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description4Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description4Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description5Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description5Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description6Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description6Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description7Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description7Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description8Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description8Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description9Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description9Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description10Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description10Max?: Maybe<Scalars['Int']>,
};


/** 
 * {"description":"Ci sono i label dei rating/commenti specifici. Se NULL o \"\" il
 * campo non viene usato per questo Award.\nTODO:\n- campi per
 * grafica/sponsor/ecc.\n- label/attivazione campi per candidatura\n- azione
 * schedulata quando scade una fase?","canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"},"canSetStatusRoles":{"status":{"DRAFT":{"INTERNAL":"MARKETING_BASE"},"CANDIDANCY_OPEN":{"INTERNAL":"MARKETING_BASE"},"CANDIDANCY_CLOSED":{"INTERNAL":"MARKETING_BASE"},"VOTING_OPEN":{"INTERNAL":"MARKETING_BASE"},"VOTING_CLOSED":{"INTERNAL":"MARKETING_BASE"},"CONCLUDED":{"INTERNAL":"MARKETING_BASE"}}}}
 */
export type AwardInvitedCompaniesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<AwardInvitedCompaniesFilterInput>
};

/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"}} */
export type AwardCategory = Node & {
   __typename?: 'AwardCategory',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  Award: Award,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"multiline":true} */
  description: Scalars['String'],
  /** {"mediaType":"ICON"} */
  Media?: Maybe<Media>,
  /** {"max":45} */
  color?: Maybe<Scalars['String']>,
  /** {"description":"URL dell'eventuale regalo scaricabile per chi ha votato tutta la AwardCategory","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  giftUrl?: Maybe<Scalars['URL']>,
  /** {"description":"// TODO"} */
  special: Scalars['Boolean'],
  /** {"description":"Usato per sync sito","forceCase":"LOWER"} */
  slug?: Maybe<Scalars['String']>,
  /** {} */
  candidacies: Array<Candidacy>,
  /** {} */
  candidacyRatings: Array<CandidacyRating>,
  /** {} */
  awardResults: Array<AwardResult>,
};

export type AwardCategoryCreateInput = {
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  Award: RefInputObject,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"multiline":true} */
  description: Scalars['String'],
  /** {"mediaType":"ICON"} */
  Media?: Maybe<RefInputObject>,
  /** {"max":45} */
  color?: Maybe<Scalars['String']>,
  /** {"description":"URL dell'eventuale regalo scaricabile per chi ha votato tutta la AwardCategory","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  giftUrl?: Maybe<Scalars['URL']>,
  /** {"description":"// TODO"} */
  special: Scalars['Boolean'],
  /** {"description":"Usato per sync sito","forceCase":"LOWER"} */
  slug?: Maybe<Scalars['String']>,
  /** {} */
  candidacyRatings?: Maybe<Array<RefInputObject>>,
};

export type AwardCategoryPatchInput = {
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  Award?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {"multiline":true} */
  description?: Maybe<Scalars['String']>,
  /** {"mediaType":"ICON"} */
  Media?: Maybe<RefInputObject>,
  /** {"max":45} */
  color?: Maybe<Scalars['String']>,
  /** {"description":"URL dell'eventuale regalo scaricabile per chi ha votato tutta la AwardCategory","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  giftUrl?: Maybe<Scalars['URL']>,
  /** {"description":"// TODO"} */
  special?: Maybe<Scalars['Boolean']>,
  /** {"description":"Usato per sync sito","forceCase":"LOWER"} */
  slug?: Maybe<Scalars['String']>,
  /** {} */
  candidacyRatings?: Maybe<Array<RefInputObject>>,
};

/** A connection to a list of items. */
export type AwardConnection = {
   __typename?: 'AwardConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AwardEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type AwardCreateInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  edition?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":2100,"min":2000} */
  year: Scalars['Int'],
  /** {} */
  targetSites?: Maybe<Array<TargetSite>>,
  /** {"description":"Default en_US"} */
  langCode?: Maybe<Scalars['String']>,
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** {"required":true,"readonly":true} */
  managerUser?: Maybe<RefInputObject>,
  /** {} */
  visibility: AwardVisibility,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  vademecumCompany?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  vademecumGoods?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  vademecumCandidacies?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  vademecumInvited?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionForProducer?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionForVoter?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionProducerHighlights?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  publicDescription?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  eventDescription?: Maybe<Scalars['String']>,
  /** {"description":"Magari poi aggiungiamo un campo delle candidature aggiuntive per le premium?","max":100,"min":1} */
  maxPerCompanyCandidacies: Scalars['Int'],
  /** 
 * {"description":"Se =1 nella candidatura prendo quasi tutti i campi dal Good
   * (che scelgo subito) e non li mostro nemmeno","max":100,"min":1}
 */
  maxGoodsPerCandidacy: Scalars['Int'],
  /** {"description":"GMT + countdown","dtPrecision":"DAY_END"} */
  candidaciesCloseAt?: Maybe<Scalars['DateTime']>,
  /** {"description":"GMT + countdown","dtPrecision":"DAY_END"} */
  votesCloseAt?: Maybe<Scalars['DateTime']>,
  /** {} */
  autovoterRoles?: Maybe<Array<AccessRole>>,
  /** {} */
  options?: Maybe<Array<AwardOptionFlag>>,
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  supportEmail?: Maybe<Scalars['Email']>,
  /** {"mediaType":"PDF_WEB"} */
  regulationsMedia?: Maybe<RefInputObject>,
  /** {"mediaType":"PDF_WEB"} */
  contractMedia?: Maybe<RefInputObject>,
  /** {} */
  extraMediaType?: Maybe<MediaType>,
  /** {"description":"@TODO DESCRIBE ME"} */
  extraMediaLabel?: Maybe<Scalars['String']>,
  /** {"description":"External website url","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>,
  /** {} */
  companyForm?: Maybe<Scalars['String']>,
  /** {} */
  candidacyForm?: Maybe<Scalars['String']>,
  /** {"description":"Se NULL o \"\" il campo uso come label il valore di default"} */
  ratingOverallLabel?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  rating1Label?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  rating2Label?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  rating3Label?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  rating4Label?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  rating5Label?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  comment1Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  comment1Max?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  comment2Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  comment2Max?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  comment3Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  comment3Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description1Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description1Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description2Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description2Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description3Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description3Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description4Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description4Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description5Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description5Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description6Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description6Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description7Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description7Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description8Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description8Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description9Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description9Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description10Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description10Max?: Maybe<Scalars['Int']>,
};

/** An edge in a connection. */
export type AwardEdge = {
   __typename?: 'AwardEdge',
  /** The item at the end of the edge */
  node?: Maybe<Award>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type AwardFilterInput = {
  /** Singolo Award */
  Object?: Maybe<RefInputObject>,
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  /** Contained in edition */
  edition?: Maybe<Scalars['String']>,
  year?: Maybe<Scalars['Int']>,
  statuses?: Maybe<Array<AwardStatus>>,
  visibility?: Maybe<AwardVisibility>,
};

export type AwardInvitedCompaniesFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
};

/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"}} */
export type AwardInvitedCompany = Node & {
   __typename?: 'AwardInvitedCompany',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME (reference)","readonly":true} */
  Award: Award,
  /** {"description":"@TODO DESCRIBE ME (reference)","readonly":true} */
  Company: Company,
  /** {} */
  signedContract: Scalars['Boolean'],
  /** {"description":"Se settato sovrascrive default in Award","max":100,"min":1} */
  maxPerCompanyCandidacies?: Maybe<Scalars['Int']>,
  /** {"description":"Se settato sovrascrive default in Award","max":100,"min":1} */
  maxGoodsPerCandidacy?: Maybe<Scalars['Int']>,
  /** {} */
  formValues?: Maybe<Scalars['String']>,
};

/** A connection to a list of items. */
export type AwardInvitedCompanyConnection = {
   __typename?: 'AwardInvitedCompanyConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AwardInvitedCompanyEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type AwardInvitedCompanyCreateInput = {
  /** {"description":"@TODO DESCRIBE ME (reference)","readonly":true} */
  Award: RefInputObject,
  /** {"description":"@TODO DESCRIBE ME (reference)","readonly":true} */
  Company: RefInputObject,
  /** {} */
  signedContract: Scalars['Boolean'],
  /** {"description":"Se settato sovrascrive default in Award","max":100,"min":1} */
  maxPerCompanyCandidacies?: Maybe<Scalars['Int']>,
  /** {"description":"Se settato sovrascrive default in Award","max":100,"min":1} */
  maxGoodsPerCandidacy?: Maybe<Scalars['Int']>,
  /** {} */
  formValues?: Maybe<Scalars['String']>,
};

/** An edge in a connection. */
export type AwardInvitedCompanyEdge = {
   __typename?: 'AwardInvitedCompanyEdge',
  /** The item at the end of the edge */
  node?: Maybe<AwardInvitedCompany>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type AwardInvitedCompanyPatchInput = {
  /** {} */
  signedContract?: Maybe<Scalars['Boolean']>,
  /** {"description":"Se settato sovrascrive default in Award","max":100,"min":1} */
  maxPerCompanyCandidacies?: Maybe<Scalars['Int']>,
  /** {"description":"Se settato sovrascrive default in Award","max":100,"min":1} */
  maxGoodsPerCandidacy?: Maybe<Scalars['Int']>,
  /** {} */
  formValues?: Maybe<Scalars['String']>,
};

/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"}} */
export type AwardInvitedUser = Node & {
   __typename?: 'AwardInvitedUser',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  Award: Award,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  User: User,
  /** {} */
  roles: Array<AwardRoles>,
};

export type AwardInvitedUserCreateInput = {
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  Award: RefInputObject,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  User: RefInputObject,
  /** {} */
  roles?: Maybe<Array<AwardRoles>>,
};

export type AwardInvitedUserPatchInput = {
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  Award?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  User?: Maybe<RefInputObject>,
  /** {} */
  roles?: Maybe<Array<AwardRoles>>,
};

/** // TODO */
export enum AwardOptionFlag {
  EanRequired = 'EAN_REQUIRED',
  LaunchdateRequired = 'LAUNCHDATE_REQUIRED',
  ExtramediaRequired = 'EXTRAMEDIA_REQUIRED',
  CompanyLogoprintRequired = 'COMPANY_LOGOPRINT_REQUIRED',
  SignedContractRequired = 'SIGNED_CONTRACT_REQUIRED',
  GalleryEnabled = 'GALLERY_ENABLED'
}

export enum AwardOrderBy {
  Name = 'name',
  Edition = 'edition',
  Year = 'year',
  Status = 'status',
  CandidaciesCloseAt = 'candidaciesCloseAt',
  VotesCloseAt = 'votesCloseAt'
}

export type AwardPatchInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  edition?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":2100,"min":2000} */
  year?: Maybe<Scalars['Int']>,
  /** {} */
  targetSites?: Maybe<Array<TargetSite>>,
  /** {"description":"Default en_US"} */
  langCode?: Maybe<Scalars['String']>,
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** {} */
  visibility?: Maybe<AwardVisibility>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  vademecumCompany?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  vademecumGoods?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  vademecumCandidacies?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  vademecumInvited?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionForProducer?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionForVoter?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionProducerHighlights?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  publicDescription?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  eventDescription?: Maybe<Scalars['String']>,
  /** {"description":"Magari poi aggiungiamo un campo delle candidature aggiuntive per le premium?","max":100,"min":1} */
  maxPerCompanyCandidacies?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"Se =1 nella candidatura prendo quasi tutti i campi dal Good
   * (che scelgo subito) e non li mostro nemmeno","max":100,"min":1}
 */
  maxGoodsPerCandidacy?: Maybe<Scalars['Int']>,
  /** {"description":"GMT + countdown","dtPrecision":"DAY_END"} */
  candidaciesCloseAt?: Maybe<Scalars['DateTime']>,
  /** {"description":"GMT + countdown","dtPrecision":"DAY_END"} */
  votesCloseAt?: Maybe<Scalars['DateTime']>,
  /** {} */
  autovoterRoles?: Maybe<Array<AccessRole>>,
  /** {} */
  options?: Maybe<Array<AwardOptionFlag>>,
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  supportEmail?: Maybe<Scalars['Email']>,
  /** {"mediaType":"PDF_WEB"} */
  regulationsMedia?: Maybe<RefInputObject>,
  /** {"mediaType":"PDF_WEB"} */
  contractMedia?: Maybe<RefInputObject>,
  /** {} */
  extraMediaType?: Maybe<MediaType>,
  /** {"description":"@TODO DESCRIBE ME"} */
  extraMediaLabel?: Maybe<Scalars['String']>,
  /** {"description":"External website url","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>,
  /** {} */
  companyForm?: Maybe<Scalars['String']>,
  /** {} */
  candidacyForm?: Maybe<Scalars['String']>,
  /** {"description":"Se NULL o \"\" il campo uso come label il valore di default"} */
  ratingOverallLabel?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  rating1Label?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  rating2Label?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  rating3Label?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  rating4Label?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  rating5Label?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  comment1Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  comment1Max?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  comment2Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  comment2Max?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"Se NULL o \"\" il campo non viene usato per questo
   * Award.\nAltrimenti compare con questo testo come label.\nSe il label finisce
   * con \"*\" il campo è required:"}
 */
  comment3Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  comment3Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description1Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description1Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description2Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description2Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description3Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description3Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description4Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description4Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description5Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description5Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description6Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description6Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description7Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description7Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description8Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description8Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description9Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description9Max?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  description10Label?: Maybe<Scalars['String']>,
  /** {"description":"Massimo numero di caratteri nel campo corrispondente","max":2147483647,"min":-2147483647} */
  description10Max?: Maybe<Scalars['Int']>,
};

/** 
 * {"description":"Posizionamento finale delle diverse Candidacy nei diversi
 * AwardCategory (anche special).\nBasta solo il vincitore ed al massimo i
 * finalisti, ma supporta la classifica completa usando final_position come ordinale (1 = Vincitore).","canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"}}
 */
export type AwardResult = Node & {
   __typename?: 'AwardResult',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  AwardCategory: AwardCategory,
  /** {} */
  Candidacy?: Maybe<Candidacy>,
  /** {} */
  legacyCandidacyName?: Maybe<Scalars['String']>,
  /** {} */
  legacyCompanyName?: Maybe<Scalars['String']>,
  /** {} */
  finalPosition?: Maybe<FinalPosition>,
};

export type AwardResultCreateInput = {
  /** {} */
  AwardCategory: RefInputObject,
  /** {} */
  Candidacy?: Maybe<RefInputObject>,
  /** {} */
  legacyCandidacyName?: Maybe<Scalars['String']>,
  /** {} */
  legacyCompanyName?: Maybe<Scalars['String']>,
  /** {} */
  finalPosition?: Maybe<FinalPosition>,
};

export type AwardResultPatchInput = {
  /** {} */
  AwardCategory?: Maybe<RefInputObject>,
  /** {} */
  Candidacy?: Maybe<RefInputObject>,
  /** {} */
  legacyCandidacyName?: Maybe<Scalars['String']>,
  /** {} */
  legacyCompanyName?: Maybe<Scalars['String']>,
  /** {} */
  finalPosition?: Maybe<FinalPosition>,
};

/** Ruoli che gli utenti (invitati) possono ricoprire per un premio. Flags */
export enum AwardRoles {
  SpecialJuror = 'SPECIAL_JUROR',
  Speaker = 'SPEAKER',
  SpecialGuest = 'SPECIAL_GUEST'
}

/** Award's possible statues */
export enum AwardStatus {
  Draft = 'DRAFT',
  CandidancyOpen = 'CANDIDANCY_OPEN',
  CandidancyClosed = 'CANDIDANCY_CLOSED',
  VotingOpen = 'VOTING_OPEN',
  VotingClosed = 'VOTING_CLOSED',
  Concluded = 'CONCLUDED'
}

/** Award's possible visibility statues */
export enum AwardVisibility {
  Vote = 'VOTE',
  Showcase = 'SHOWCASE'
}

/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"},"canSetStatusRoles":{"isValidated":{"FALSE":{"INTERNAL":"MARKETING_BASE","PRODUCER":"EDIT"},"TRUE":{"INTERNAL":"MARKETING_BASE"}}},"canPatchNeedCurrentStatusAccess":"isValidated"} */
export type Banner = Node & {
   __typename?: 'Banner',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"Solo uso interno"} */
  name: Scalars['String'],
  /** {} */
  targetSite: TargetSite,
  /** {} */
  targetMedia: TargetMedia,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'],
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>,
  /** {} */
  locations: Array<WidgetLocation>,
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  orderPriority: Scalars['Int'],
  /** {} */
  layout: BannerLayout,
  /** {"max":50} */
  title?: Maybe<Scalars['String']>,
  /** {"description":"Se esco dal sito apro in nuova tab","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$"} */
  titleUrl?: Maybe<Scalars['URL']>,
  /** {"max":50} */
  body?: Maybe<Scalars['String']>,
  /** {"description":"Mostrata dove applicabile"} */
  callToAction?: Maybe<Scalars['String']>,
  /** {"description":"Se esco dal sito apro in nuova tab","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$"} */
  url?: Maybe<Scalars['URL']>,
  /** {"description":"Determino il MediaType in base al layout"} */
  Media?: Maybe<Media>,
  /** {} */
  themeColor?: Maybe<WidgetStyleColor>,
  /** {"description":"Usato per generare il contenuto del Banner"} */
  FrequentSearch?: Maybe<FrequentSearch>,
  /** {"description":"Usato per generare il contenuto del Banner"} */
  MagazineCollection?: Maybe<MagazineCollection>,
  /** {"description":"Questo Banner è incluso in un NewsletterIssue"} */
  NewsletterIssue?: Maybe<NewsletterIssue>,
  /** {"description":"Questo Banner è incluso in un Magazine (impianti)"} */
  Magazine?: Maybe<Magazine>,
  /** {"max":2147483647,"min":-2147483647} */
  magazinePage?: Maybe<Scalars['Int']>,
  /** {"max":30} */
  sponsorName?: Maybe<Scalars['String']>,
  /** {} */
  sponsorCompany?: Maybe<Company>,
  /** {} */
  sponsorCompanyGroup?: Maybe<CompanyGroup>,
  /** {} */
  sponsorService?: Maybe<Service>,
  /** {} */
  sponsorRetailer?: Maybe<Retailer>,
  /** {} */
  visibilityByRole?: Maybe<VisibilityRole>,
  /** {} */
  visibilityByRelation?: Maybe<VisibilityRelations>,
  /** {} */
  visibilityByMinLevel?: Maybe<CompanyLevelEnum>,
  /** {} */
  visibilityByMaxLevel?: Maybe<CompanyLevelEnum>,
  /** {} */
  visibilityByMinCapability?: Maybe<Capability>,
  /** {"description":"Usato per visibilityByRelation"} */
  Company?: Maybe<Company>,
  /** {"description":"Usato per visibilityByRelation"} */
  GeoIndication?: Maybe<GeoIndication>,
  /** {"description":"Usato per visibilityByRelation"} */
  Fair?: Maybe<Fair>,
  /** {"description":"Usato per visibilityByRelation"} */
  Award?: Maybe<Award>,
  /** 
 * {"description":"Ha superato la validazione interna -> senza questo non è
   * pubblicato, dopo non è modificabile da producer o simili\nGestito tramite SetStatus"}
 */
  isValidated: Scalars['Boolean'],
  /** {"description":"Data entro cui tutto dev'essere pronto","dtPrecision":"DAY_END"} */
  deadlineDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"NULL a meno che target_media == 1 [NSL], is_validated == TRUE e poi sia stato assegnato"} */
  isSfAbandoned: Scalars['Boolean'],
};

/** A connection to a list of items. */
export type BannerConnection = {
   __typename?: 'BannerConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BannerEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type BannerCreateInput = {
  /** {"description":"Solo uso interno"} */
  name: Scalars['String'],
  /** {} */
  targetSite: TargetSite,
  /** {} */
  targetMedia: TargetMedia,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'],
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>,
  /** {} */
  locations?: Maybe<Array<WidgetLocation>>,
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  orderPriority: Scalars['Int'],
  /** {} */
  layout: BannerLayout,
  /** {"max":50} */
  title?: Maybe<Scalars['String']>,
  /** {"description":"Se esco dal sito apro in nuova tab","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$"} */
  titleUrl?: Maybe<Scalars['URL']>,
  /** {"max":50} */
  body?: Maybe<Scalars['String']>,
  /** {"description":"Mostrata dove applicabile"} */
  callToAction?: Maybe<Scalars['String']>,
  /** {"description":"Se esco dal sito apro in nuova tab","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$"} */
  url?: Maybe<Scalars['URL']>,
  /** {"description":"Determino il MediaType in base al layout"} */
  Media?: Maybe<RefInputObject>,
  /** {} */
  themeColor?: Maybe<WidgetStyleColor>,
  /** {"description":"Usato per generare il contenuto del Banner"} */
  FrequentSearch?: Maybe<RefInputObject>,
  /** {"description":"Usato per generare il contenuto del Banner"} */
  MagazineCollection?: Maybe<RefInputObject>,
  /** {"description":"Questo Banner è incluso in un NewsletterIssue"} */
  NewsletterIssue?: Maybe<RefInputObject>,
  /** {"description":"Questo Banner è incluso in un Magazine (impianti)"} */
  Magazine?: Maybe<RefInputObject>,
  /** {"max":2147483647,"min":-2147483647} */
  magazinePage?: Maybe<Scalars['Int']>,
  /** {"max":30} */
  sponsorName?: Maybe<Scalars['String']>,
  /** {} */
  sponsorCompany?: Maybe<RefInputObject>,
  /** {} */
  sponsorCompanyGroup?: Maybe<RefInputObject>,
  /** {} */
  sponsorService?: Maybe<RefInputObject>,
  /** {} */
  sponsorRetailer?: Maybe<RefInputObject>,
  /** {} */
  visibilityByRole?: Maybe<VisibilityRole>,
  /** {} */
  visibilityByRelation?: Maybe<VisibilityRelations>,
  /** {} */
  visibilityByMinLevel?: Maybe<CompanyLevelEnum>,
  /** {} */
  visibilityByMaxLevel?: Maybe<CompanyLevelEnum>,
  /** {} */
  visibilityByMinCapability?: Maybe<Capability>,
  /** {"description":"Usato per visibilityByRelation"} */
  Company?: Maybe<RefInputObject>,
  /** {"description":"Usato per visibilityByRelation"} */
  GeoIndication?: Maybe<RefInputObject>,
  /** {"description":"Usato per visibilityByRelation"} */
  Fair?: Maybe<RefInputObject>,
  /** {"description":"Usato per visibilityByRelation"} */
  Award?: Maybe<RefInputObject>,
};

/** An edge in a connection. */
export type BannerEdge = {
   __typename?: 'BannerEdge',
  /** The item at the end of the edge */
  node?: Maybe<Banner>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type BannerFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  /** Started and not expired at */
  targetDate?: Maybe<Scalars['DateTime']>,
  targetSite?: Maybe<TargetSite>,
  targetMedia?: Maybe<TargetMedia>,
  location?: Maybe<WidgetLocation>,
  isValidated?: Maybe<Scalars['Boolean']>,
  isSfAbandoned?: Maybe<Scalars['Boolean']>,
  sponsorCompany?: Maybe<RefInputObject>,
  sponsorCompanyGroup?: Maybe<RefInputObject>,
  sponsorService?: Maybe<RefInputObject>,
  sponsorRetailer?: Maybe<RefInputObject>,
  Company?: Maybe<RefInputObject>,
  GeoIndication?: Maybe<RefInputObject>,
  Fair?: Maybe<RefInputObject>,
  Award?: Maybe<RefInputObject>,
};

/** Per determinare il mediaType faccio : mediaType = BannerLayout2MediaType[bannerEntity.layout] */
export enum BannerLayout {
  Full = 'Full',
  SidedBoxWithFrequentSearch = 'SidedBoxWithFrequentSearch',
  SmallImageOnly = 'SmallImageOnly',
  LeaderboardImageOnly = 'LeaderboardImageOnly',
  PopupImageOnly = 'PopupImageOnly',
  DirectoryCard = 'DirectoryCard',
  Popup = 'Popup',
  Toaster = 'Toaster',
  ImpiantoMezza = 'Impianto_Mezza',
  ImpiantoMezzaVerticale = 'Impianto_MezzaVerticale',
  ImpiantoIntera = 'Impianto_Intera',
  ImpiantoBattente = 'Impianto_Battente',
  PubbliRedazionaleSingola = 'PubbliRedazionale_Singola',
  PubbliRedazionaleDoppia = 'PubbliRedazionale_Doppia'
}

export enum BannerOrderBy {
  Name = 'name',
  StartDate = 'startDate',
  ExpirationDate = 'expirationDate',
  IsValidated = 'isValidated',
  IsSfAbandoned = 'isSfAbandoned',
  DeadlineDate = 'deadlineDate',
  Size = 'size',
  Media = 'Media'
}

export type BannerPatchInput = {
  /** {"description":"Solo uso interno"} */
  name?: Maybe<Scalars['String']>,
  /** {} */
  targetSite?: Maybe<TargetSite>,
  /** {} */
  targetMedia?: Maybe<TargetMedia>,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_BEGIN"} */
  startDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>,
  /** {} */
  locations?: Maybe<Array<WidgetLocation>>,
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  orderPriority?: Maybe<Scalars['Int']>,
  /** {} */
  layout?: Maybe<BannerLayout>,
  /** {"max":50} */
  title?: Maybe<Scalars['String']>,
  /** {"description":"Se esco dal sito apro in nuova tab","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$"} */
  titleUrl?: Maybe<Scalars['URL']>,
  /** {"max":50} */
  body?: Maybe<Scalars['String']>,
  /** {"description":"Mostrata dove applicabile"} */
  callToAction?: Maybe<Scalars['String']>,
  /** {"description":"Se esco dal sito apro in nuova tab","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$"} */
  url?: Maybe<Scalars['URL']>,
  /** {"description":"Determino il MediaType in base al layout"} */
  Media?: Maybe<RefInputObject>,
  /** {} */
  themeColor?: Maybe<WidgetStyleColor>,
  /** {"description":"Usato per generare il contenuto del Banner"} */
  FrequentSearch?: Maybe<RefInputObject>,
  /** {"description":"Usato per generare il contenuto del Banner"} */
  MagazineCollection?: Maybe<RefInputObject>,
  /** {"description":"Questo Banner è incluso in un NewsletterIssue"} */
  NewsletterIssue?: Maybe<RefInputObject>,
  /** {"description":"Questo Banner è incluso in un Magazine (impianti)"} */
  Magazine?: Maybe<RefInputObject>,
  /** {"max":2147483647,"min":-2147483647} */
  magazinePage?: Maybe<Scalars['Int']>,
  /** {"max":30} */
  sponsorName?: Maybe<Scalars['String']>,
  /** {} */
  sponsorCompany?: Maybe<RefInputObject>,
  /** {} */
  sponsorCompanyGroup?: Maybe<RefInputObject>,
  /** {} */
  sponsorService?: Maybe<RefInputObject>,
  /** {} */
  sponsorRetailer?: Maybe<RefInputObject>,
  /** {} */
  visibilityByRole?: Maybe<VisibilityRole>,
  /** {} */
  visibilityByRelation?: Maybe<VisibilityRelations>,
  /** {} */
  visibilityByMinLevel?: Maybe<CompanyLevelEnum>,
  /** {} */
  visibilityByMaxLevel?: Maybe<CompanyLevelEnum>,
  /** {} */
  visibilityByMinCapability?: Maybe<Capability>,
  /** {"description":"Usato per visibilityByRelation"} */
  Company?: Maybe<RefInputObject>,
  /** {"description":"Usato per visibilityByRelation"} */
  GeoIndication?: Maybe<RefInputObject>,
  /** {"description":"Usato per visibilityByRelation"} */
  Fair?: Maybe<RefInputObject>,
  /** {"description":"Usato per visibilityByRelation"} */
  Award?: Maybe<RefInputObject>,
};

/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":"MARKETING_EXTRA"},"canPatchRoles":{"INTERNAL":"MARKETING_EXTRA"},"canDeleteRoles":{"INTERNAL":"MARKETING_EXTRA"}} */
export type BannerView = Node & {
   __typename?: 'BannerView',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME (reference)","readonly":true} */
  Banner: Banner,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  User?: Maybe<User>,
  /** {"readonly":true} */
  type: BannerViewType,
  /** {} */
  targetSite: TargetSite,
  /** {} */
  referrer?: Maybe<Scalars['String']>,
  /** {} */
  useragent?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  timestamp: Scalars['DateTime'],
  /** {} */
  ip?: Maybe<Scalars['String']>,
};

/** A connection to a list of items. */
export type BannerViewConnection = {
   __typename?: 'BannerViewConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BannerViewEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type BannerViewCreateInput = {
  /** {"description":"@TODO DESCRIBE ME (reference)","readonly":true} */
  Banner: RefInputObject,
  /** {"readonly":true} */
  type: BannerViewType,
};

/** An edge in a connection. */
export type BannerViewEdge = {
   __typename?: 'BannerViewEdge',
  /** The item at the end of the edge */
  node?: Maybe<BannerView>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type BannerViewFilterInput = {
  Banner?: Maybe<Array<RefInputObject>>,
  User?: Maybe<Array<RefInputObject>>,
  types?: Maybe<Array<BannerViewType>>,
  targetSite?: Maybe<TargetSite>,
  /** Contained in referrer */
  referrer?: Maybe<Scalars['String']>,
  /** Contained in useragent */
  useragent?: Maybe<Scalars['String']>,
  /** Contained in ip */
  ip?: Maybe<Scalars['String']>,
  timespanMin?: Maybe<Scalars['DateTime']>,
  timespanMax?: Maybe<Scalars['DateTime']>,
};

/** @TODO DESCRIBE ME */
export type BannerViewInput = {
  locations?: Maybe<Array<WidgetLocation>>,
  targetMedia: TargetMedia,
};

export enum BannerViewType {
  Impression = 'IMPRESSION',
  Click = 'CLICK'
}

/** Map boolean to enum for xxSetStatus usage */
export enum BooleanEnum {
  False = 'FALSE',
  True = 'TRUE'
}

/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"}} */
export type Branch = Node & {
   __typename?: 'Branch',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"Salesforce object ID","max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>,
  /** {} */
  Company: Company,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {} */
  type: Array<BranchType>,
  /** {"description":"@TODO DESCRIBE ME","max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country: Country,
  /** {} */
  StateProvince?: Maybe<StateProvince>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address1: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
};

export type Branch_From_Company_Type = {
  /** {} */
  Company?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {} */
  type?: Maybe<Array<BranchType>>,
  /** {"description":"@TODO DESCRIBE ME","max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country: RefInputObject,
  /** {} */
  StateProvince?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address1: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
};

export type BranchCreateInput = {
  /** {} */
  Company: RefInputObject,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {} */
  type?: Maybe<Array<BranchType>>,
  /** {"description":"@TODO DESCRIBE ME","max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country: RefInputObject,
  /** {} */
  StateProvince?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address1: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
};

export type BranchPatchInput = {
  /** {} */
  Company?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {} */
  type?: Maybe<Array<BranchType>>,
  /** {"description":"@TODO DESCRIBE ME","max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country?: Maybe<RefInputObject>,
  /** {} */
  StateProvince?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address1?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
};

export enum BranchType {
  ManufacturingPlant = 'MANUFACTURING_PLANT',
  PoBox = 'PO_BOX',
  LogisticDepartment = 'LOGISTIC_DEPARTMENT',
  SalesDepartment = 'SALES_DEPARTMENT'
}

/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"}} */
export type Brand = Node & {
   __typename?: 'Brand',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"Salesforce object ID","max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>,
  /** {"required":true,"readonly":true} */
  Company: Company,
  /** {"description":"@TODO"} */
  slug: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME","required":true,"max":50,"min":2} */
  name: Scalars['String'],
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>,
  /** {} */
  pricePositioning: PricePositioning,
  /** {"description":"No richtext, al max bold/italic\nValidazione: limitare maiuscolo (<30%) e lunghezza","multiline":true} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"No richtext, al max bold/italic\nValidazione: limitare maiuscolo (<30%) e lunghezza","multiline":true} */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":255,"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>,
  /** {"description":"@TODO DESCRIBE ME","max":255} */
  socialInstagramPage?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":255} */
  socialFacebookPage?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  retailChainDistribution: Scalars['Boolean'],
  /** {"description":"@TODO DESCRIBE ME"} */
  foodServiceChainDistribution: Scalars['Boolean'],
  /** {"description":"@TODO DESCRIBE ME"} */
  gourmetShopChainDistribution: Scalars['Boolean'],
  /** {"description":"@TODO DESCRIBE ME"} */
  order: Scalars['Int'],
  /** {} */
  goods: Array<Good>,
  /** {"description":"GoodClasses available for Brand's Goods"} */
  goodClasses: Array<GoodClass>,
  /** {"description":"GoodClasses directly and indirectly linked"} */
  goodClassesFull: Array<GoodClass>,
  /** {"description":"EditorialSections directly and indirectly linked"} */
  editorialSections: Array<EditorialSection>,
  /** {"description":"Not actual News but intermediate object with count"} */
  newsCitations?: Maybe<Array<NewsCitation>>,
};

/** A connection to a list of items. */
export type BrandConnection = {
   __typename?: 'BrandConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BrandEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type BrandCreateInput = {
  /** {"required":true,"readonly":true} */
  Company: RefInputObject,
  /** {"description":"@TODO DESCRIBE ME","required":true,"max":50,"min":2} */
  name: Scalars['String'],
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** {} */
  pricePositioning: PricePositioning,
  /** {"description":"No richtext, al max bold/italic\nValidazione: limitare maiuscolo (<30%) e lunghezza","multiline":true} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"No richtext, al max bold/italic\nValidazione: limitare maiuscolo (<30%) e lunghezza","multiline":true} */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":255,"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>,
  /** {"description":"@TODO DESCRIBE ME","max":255} */
  socialInstagramPage?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":255} */
  socialFacebookPage?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  retailChainDistribution: Scalars['Boolean'],
  /** {"description":"@TODO DESCRIBE ME"} */
  foodServiceChainDistribution: Scalars['Boolean'],
  /** {"description":"@TODO DESCRIBE ME"} */
  gourmetShopChainDistribution: Scalars['Boolean'],
  /** {"description":"GoodClasses available for Brand's Goods"} */
  goodClasses?: Maybe<Array<RefInputObject>>,
};

/** An edge in a connection. */
export type BrandEdge = {
   __typename?: 'BrandEdge',
  /** The item at the end of the edge */
  node?: Maybe<Brand>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type BrandFilterInput = {
  /** Contained in name or nameIta */
  name?: Maybe<Scalars['String']>,
  Company?: Maybe<RefInputObject>,
};

export type BrandPatchInput = {
  /** {"description":"@TODO DESCRIBE ME","required":true,"max":50,"min":2} */
  name?: Maybe<Scalars['String']>,
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** {} */
  pricePositioning?: Maybe<PricePositioning>,
  /** {"description":"No richtext, al max bold/italic\nValidazione: limitare maiuscolo (<30%) e lunghezza","multiline":true} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"No richtext, al max bold/italic\nValidazione: limitare maiuscolo (<30%) e lunghezza","multiline":true} */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":255,"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>,
  /** {"description":"@TODO DESCRIBE ME","max":255} */
  socialInstagramPage?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":255} */
  socialFacebookPage?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  retailChainDistribution?: Maybe<Scalars['Boolean']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  foodServiceChainDistribution?: Maybe<Scalars['Boolean']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  gourmetShopChainDistribution?: Maybe<Scalars['Boolean']>,
  /** {"description":"GoodClasses available for Brand's Goods"} */
  goodClasses?: Maybe<Array<RefInputObject>>,
};

/** {"description":"Campaign Member","canCreateRoles":false,"canPatchRoles":false,"canDeleteRoles":false} */
export type CampaignMember = Node & {
   __typename?: 'CampaignMember',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"Esito Campaign"} */
  Esito_Campaign?: Maybe<CampaignMember_Esito_Campaign__C_Enum>,
  /** {} */
  CampaignId: Scalars['String'],
};

/** SF picklist */
export enum CampaignMember_Esito_Campaign__C_Enum {
  /** Assente */
  Assente = 'Assente',
  /** Presente */
  Presente = 'Presente',
  /** Presente solo convegno */
  PresenteSoloConvegno = 'Presente_solo_convegno',
  /** Presente solo cena */
  PresenteSoloCena = 'Presente_solo_cena'
}

/** {"description":"Dare istruzioni di compilare nella lingua di Award.lang_code","canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"}} */
export type Candidacy = Node & {
   __typename?: 'Candidacy',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"readonly":true} */
  Award: Award,
  /** {"readonly":true} */
  Company: Company,
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageMedia?: Maybe<Media>,
  /** {"mediaType":"GALLERY_GENERIC","ordered":true} */
  additionalImagesMedia: Array<Media>,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  AwardCategory?: Maybe<AwardCategory>,
  /** {"description":"@TODO"} */
  slug: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true} */
  Brand: Brand,
  /** {"description":"@TODO DESCRIBE ME"} */
  range?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  extraMedia?: Maybe<Media>,
  /** {"description":"Candidated Goods","required":true,"min":1} */
  goods: Array<Good>,
  /** {} */
  candidacyRatings: Array<CandidacyRating>,
  /** {} */
  awardResults: Array<AwardResult>,
  /** {"description":"Solo mese/anno","dtPrecision":"MONTH_BEGIN"} */
  launchDate?: Maybe<Scalars['DateTime']>,
  /** {} */
  formValues?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description1?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description3?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description4?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description5?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description6?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description7?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description8?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description9?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description10?: Maybe<Scalars['String']>,
};

/** A connection to a list of items. */
export type CandidacyConnection = {
   __typename?: 'CandidacyConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CandidacyEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type CandidacyCreateInput = {
  /** {"readonly":true} */
  Award: RefInputObject,
  /** {"readonly":true} */
  Company: RefInputObject,
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageMedia?: Maybe<RefInputObject>,
  /** {"mediaType":"GALLERY_GENERIC","ordered":true} */
  additionalImagesMedia?: Maybe<Array<RefInputObject>>,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  AwardCategory?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true} */
  Brand: RefInputObject,
  /** {"description":"@TODO DESCRIBE ME"} */
  range?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  extraMedia?: Maybe<RefInputObject>,
  /** {"description":"Candidated Goods","required":true,"min":1} */
  goods?: Maybe<Array<RefInputObject>>,
  /** {"description":"Solo mese/anno","dtPrecision":"MONTH_BEGIN"} */
  launchDate?: Maybe<Scalars['DateTime']>,
  /** {} */
  formValues?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description1?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description3?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description4?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description5?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description6?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description7?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description8?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description9?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description10?: Maybe<Scalars['String']>,
};

/** An edge in a connection. */
export type CandidacyEdge = {
   __typename?: 'CandidacyEdge',
  /** The item at the end of the edge */
  node?: Maybe<Candidacy>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type CandidacyFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  producerChangedAfter?: Maybe<Scalars['DateTime']>,
  Award?: Maybe<RefInputObject>,
  Brand?: Maybe<RefInputObject>,
  Company?: Maybe<RefInputObject>,
};

export enum CandidacyOrderBy {
  Name = 'name',
  AwardId = 'awardId',
  AwardCategoryId = 'awardCategoryId'
}

export type CandidacyPatchInput = {
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageMedia?: Maybe<RefInputObject>,
  /** {"mediaType":"GALLERY_GENERIC","ordered":true} */
  additionalImagesMedia?: Maybe<Array<RefInputObject>>,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  AwardCategory?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true} */
  Brand?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME"} */
  range?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  extraMedia?: Maybe<RefInputObject>,
  /** {"description":"Candidated Goods","required":true,"min":1} */
  goods?: Maybe<Array<RefInputObject>>,
  /** {"description":"Solo mese/anno","dtPrecision":"MONTH_BEGIN"} */
  launchDate?: Maybe<Scalars['DateTime']>,
  /** {} */
  formValues?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description1?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description3?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description4?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description5?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description6?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description7?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description8?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description9?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","html":true,"htmlLevel":"FORMATTING"} */
  description10?: Maybe<Scalars['String']>,
};

/** 
 * {"description":"Voto ad un prodotto candidato\nDare istruzioni di compilare
 * nella lingua di Award.lang_code\nI label dei rating e commenti specifici sono in
 * Award, se il valore è NULL o \"\" vuol dire che il campo non è usato per quell'award","canCreateRoles":{"INTERNAL":true,"PRODUCER":true,"BUYER":true,"BUYER_PL_ITALIA":true},"canPatchRoles":{"INTERNAL":"DIRECTORY_EXTRA","PRODUCER":true,"BUYER":true,"BUYER_PL_ITALIA":true},"canDeleteRoles":{"INTERNAL":"DIRECTORY_EXTRA"}}
 */
export type CandidacyRating = Node & {
   __typename?: 'CandidacyRating',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  User: User,
  /** {} */
  Candidacy: Candidacy,
  /** {} */
  askSample: Scalars['Boolean'],
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  overall?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  rating1?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  rating2?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  rating3?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  rating4?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  rating5?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","multiline":true,"max":200} */
  comment1?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","multiline":true,"max":200} */
  comment2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","multiline":true,"max":200} */
  comment3?: Maybe<Scalars['String']>,
};

/** A connection to a list of items. */
export type CandidacyRatingConnection = {
   __typename?: 'CandidacyRatingConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CandidacyRatingEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type CandidacyRatingCreateInput = {
  /** {} */
  User: RefInputObject,
  /** {} */
  Candidacy: RefInputObject,
  /** {} */
  askSample: Scalars['Boolean'],
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  overall?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  rating1?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  rating2?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  rating3?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  rating4?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  rating5?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","multiline":true,"max":200} */
  comment1?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","multiline":true,"max":200} */
  comment2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","multiline":true,"max":200} */
  comment3?: Maybe<Scalars['String']>,
};

/** An edge in a connection. */
export type CandidacyRatingEdge = {
   __typename?: 'CandidacyRatingEdge',
  /** The item at the end of the edge */
  node?: Maybe<CandidacyRating>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type CandidacyRatingFilterInput = {
  Award?: Maybe<RefInputObject>,
  AwardCategory?: Maybe<RefInputObject>,
  askSample?: Maybe<Scalars['Boolean']>,
  comment1?: Maybe<Scalars['Boolean']>,
  comment2?: Maybe<Scalars['Boolean']>,
  comment3?: Maybe<Scalars['Boolean']>,
  User?: Maybe<RefInputObject>,
};

export enum CandidacyRatingOrderBy {
  User = 'User',
  Candidacy = 'Candidacy',
  LocalId = 'localId'
}

export type CandidacyRatingPatchInput = {
  /** {} */
  User?: Maybe<RefInputObject>,
  /** {} */
  Candidacy?: Maybe<RefInputObject>,
  /** {} */
  askSample?: Maybe<Scalars['Boolean']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  overall?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  rating1?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  rating2?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  rating3?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  rating4?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":10,"min":1} */
  rating5?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","multiline":true,"max":200} */
  comment1?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","multiline":true,"max":200} */
  comment2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","multiline":true,"max":200} */
  comment3?: Maybe<Scalars['String']>,
};

/** User possible managing Capabilities */
export enum Capability {
  Edit = 'EDIT',
  Account = 'ACCOUNT',
  Purchase = 'PURCHASE',
  Manage = 'MANAGE'
}

export type Change = {
   __typename?: 'change',
  type: Scalars['String'],
  id: Scalars['ID'],
  fields?: Maybe<Array<Scalars['String']>>,
};

export enum Channel {
  Retail = 'RETAIL',
  Foodservice = 'FOODSERVICE',
  GourmetShops = 'GOURMET_SHOPS',
  Wholesaler = 'WHOLESALER'
}

export type CompaniesDir = Company | Brand;

/** A connection to a list of items. */
export type CompaniesDirConnection = {
   __typename?: 'companiesDirConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompaniesDirEdge>>>,
  total?: Maybe<Scalars['Int']>,
  facet?: Maybe<CompaniesDirFacets>,
  relatedFrequentSearches?: Maybe<Array<Maybe<FrequentSearch>>>,
};

/** An edge in a connection. */
export type CompaniesDirEdge = {
   __typename?: 'companiesDirEdge',
  /** The item at the end of the edge */
  node?: Maybe<CompaniesDir>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
  score: Scalars['Float'],
};

export type CompaniesDirFacets = {
   __typename?: 'companiesDirFacets',
  plProducer?: Maybe<Array<FacetValueCount>>,
  type?: Maybe<Array<FacetValueCount>>,
  goodClasses?: Maybe<Array<FacetValueCount>>,
  companyCertifications?: Maybe<Array<FacetValueCount>>,
  stateProvince?: Maybe<Array<FacetValueCount>>,
  revenue?: Maybe<Array<FacetValueCount>>,
};

/** @TODO DESCRIBE ME */
export type CompaniesFilterType = {
  targetSite?: Maybe<TargetSite>,
  items?: Maybe<Array<RefInputObject>>,
  FrequentSearch?: Maybe<RefInputObject>,
  textSearch?: Maybe<Scalars['String']>,
  types?: Maybe<Array<CompaniesTypeEnum>>,
  levels?: Maybe<Array<CompanyLevelEnum>>,
  plProducer?: Maybe<Scalars['Boolean']>,
  goodClasses?: Maybe<Array<RefInputObject>>,
  companyCertifications?: Maybe<Array<RefInputObject>>,
  geoIndications?: Maybe<Array<RefInputObject>>,
  fairs?: Maybe<Array<RefInputObject>>,
  stateProvinces?: Maybe<Array<RefInputObject>>,
  reveneueRanges?: Maybe<Array<CompanyRevenueRange>>,
};

/** Companies possible types */
export enum CompaniesTypeEnum {
  Company = 'Company',
  Brand = 'Brand'
}

/** 
 * {"description":"Account che produce prodotti a proprio nome o brand oppure come
 * PL producer\nPer filtrare per Country filtro in base alla Country della
 * main_Branch\nQuando aggiorno SF devo checkare se ha un utente su applicativo aziende o meno","canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}}
 */
export type Company = Node & {
   __typename?: 'Company',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"Salesforce object ID","max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>,
  /** {"description":"@TODO"} */
  slug: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME","required":true,"max":80,"min":2} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME","required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'],
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>,
  /** {"mediaType":"LOGO_PRINT"} */
  logoPrintMedia?: Maybe<Media>,
  /** {"mediaType":"GALLERY_GENERIC","ordered":true} */
  additionalImagesMedia: Array<Media>,
  /** {} */
  industry: CompanyIndustry,
  /** {} */
  ownership?: Maybe<CompanyOwnership>,
  /** {"required":true,"allowFormInlining":true} */
  billingBranch?: Maybe<Branch>,
  /** {} */
  headquarterBranch?: Maybe<Branch>,
  /** {"description":"@TODO DESCRIBE ME","max":2020,"min":1000} */
  yearEstablished?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {} */
  AtecoCode?: Maybe<AtecoCode>,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_BEGIN"} */
  vatOpeningDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>,
  /** {"description":"Fare regola ad-hoc","required":true,"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"Required for pubblication","required":true,"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>,
  /** {"description":"Desunto  in automatico da website/companyEmail?","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$"} */
  emailDomain?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  plProducer?: Maybe<Scalars['Boolean']>,
  /** {"description":"Required for pubblication\nEs.: info@...","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  companyEmail?: Maybe<Scalars['Email']>,
  /** {} */
  brands: Array<Brand>,
  /** {} */
  branches: Array<Branch>,
  /** {} */
  distributionChannels: Array<DistributionChannel>,
  /** {} */
  companyYears?: Maybe<Array<CompanyYear>>,
  /** {} */
  currentCompanyLevels: Array<CompanyLevel>,
  /** {} */
  companyLevels: Array<CompanyLevel>,
  /** {"description":"Not actual User but intermediate object with user capabilities"} */
  managedByUsers: Array<ManagedByUser>,
  /** {"description":"Company's Goods"} */
  goods: Array<Good>,
  /** {"description":"Company's HighlightedGoods"} */
  highlightedGoods: Array<HighlightedGood>,
  /** {"description":"GoodClasses directly and indirectly linked"} */
  goodClassesFull: Array<GoodClass>,
  /** {"description":"EditorialSections directly and indirectly linked"} */
  editorialSections: Array<EditorialSection>,
  /** {"description":"Company's certifications"} */
  companyCertifications: Array<CompanyCertification>,
  /** {"description":"Company's features. Visible to buyers only or in premium companies"} */
  goodFeatureCertifications: Array<GoodFeatureCertification>,
  /** {"description":"Not actual Award but intermediate object"} */
  awardInvitedCompanies: Array<AwardInvitedCompany>,
  /** {"description":"Company's GeoIndications. Visible to buyers only or in premium companies"} */
  geoIndications: Array<GeoIndication>,
  /** {"description":"Not actual Fair but intermediate object with pavillion, stand, etc."} */
  fairParticipations?: Maybe<Array<FairParticipation>>,
  /** {"description":"Not actual News but intermediate object with count"} */
  newsCitations?: Maybe<Array<NewsCitation>>,
  /** {} */
  userObjectSubscriptions?: Maybe<Array<UserObjectSubscription>>,
  /** {} */
  userNotes?: Maybe<Array<UserNote>>,
};


/** 
 * {"description":"Account che produce prodotti a proprio nome o brand oppure come
 * PL producer\nPer filtrare per Country filtro in base alla Country della
 * main_Branch\nQuando aggiorno SF devo checkare se ha un utente su applicativo aziende o meno","canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}}
 */
export type CompanyCompanyYearsArgs = {
  filter?: Maybe<CompanyYearsFilter>
};


/** 
 * {"description":"Account che produce prodotti a proprio nome o brand oppure come
 * PL producer\nPer filtrare per Country filtro in base alla Country della
 * main_Branch\nQuando aggiorno SF devo checkare se ha un utente su applicativo aziende o meno","canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}}
 */
export type CompanyFairParticipationsArgs = {
  Fair?: Maybe<RefInputObject>,
  isConcluded?: Maybe<Scalars['Boolean']>
};

/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type CompanyBadge = Node & {
   __typename?: 'CompanyBadge',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","max":255} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME (reference)","mediaType":"ICON"} */
  iconMedia?: Maybe<Media>,
  /** {"max":255} */
  styleClass?: Maybe<Scalars['String']>,
  /** {"max":45} */
  color?: Maybe<Scalars['String']>,
  /** {"description":"Testo di spiegazione del badge","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"readonly":true} */
  targetSite: TargetSite,
};

/** A connection to a list of items. */
export type CompanyBadgeConnection = {
   __typename?: 'CompanyBadgeConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompanyBadgeEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type CompanyBadgeCreateInput = {
  /** {"description":"@TODO DESCRIBE ME","max":255} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME (reference)","mediaType":"ICON"} */
  iconMedia?: Maybe<RefInputObject>,
  /** {"max":255} */
  styleClass?: Maybe<Scalars['String']>,
  /** {"max":45} */
  color?: Maybe<Scalars['String']>,
  /** {"description":"Testo di spiegazione del badge","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"readonly":true} */
  targetSite: TargetSite,
};

/** An edge in a connection. */
export type CompanyBadgeEdge = {
   __typename?: 'CompanyBadgeEdge',
  /** The item at the end of the edge */
  node?: Maybe<CompanyBadge>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type CompanyBadgeFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  targetSite?: Maybe<TargetSite>,
};

export type CompanyBadgePatchInput = {
  /** {"description":"@TODO DESCRIBE ME","max":255} */
  name?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME (reference)","mediaType":"ICON"} */
  iconMedia?: Maybe<RefInputObject>,
  /** {"max":255} */
  styleClass?: Maybe<Scalars['String']>,
  /** {"max":45} */
  color?: Maybe<Scalars['String']>,
  /** {"description":"Testo di spiegazione del badge","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
};

/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type CompanyCertification = Node & {
   __typename?: 'CompanyCertification',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** 
 * {"description":"Also Known As\nUsato nella ricerca come alias minore (minor
   * peso) del nome\nUno per riga\nNon visibile, compilabile o editabile salvo
   * utenti interni\nPer le tabelle di sistema contiene anche le traduzioni a scopo
   * di full-text search.\nTutte le lingue stanno insieme","multiline":true}
 */
  akas?: Maybe<Scalars['String']>,
};

/** A connection to a list of items. */
export type CompanyCertificationConnection = {
   __typename?: 'CompanyCertificationConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompanyCertificationEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type CompanyCertificationCreateInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** 
 * {"description":"Also Known As\nUsato nella ricerca come alias minore (minor
   * peso) del nome\nUno per riga\nNon visibile, compilabile o editabile salvo
   * utenti interni\nPer le tabelle di sistema contiene anche le traduzioni a scopo
   * di full-text search.\nTutte le lingue stanno insieme","multiline":true}
 */
  akas?: Maybe<Scalars['String']>,
};

/** An edge in a connection. */
export type CompanyCertificationEdge = {
   __typename?: 'CompanyCertificationEdge',
  /** The item at the end of the edge */
  node?: Maybe<CompanyCertification>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type CompanyCertificationPatchInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Also Known As\nUsato nella ricerca come alias minore (minor
   * peso) del nome\nUno per riga\nNon visibile, compilabile o editabile salvo
   * utenti interni\nPer le tabelle di sistema contiene anche le traduzioni a scopo
   * di full-text search.\nTutte le lingue stanno insieme","multiline":true}
 */
  akas?: Maybe<Scalars['String']>,
};

/** A connection to a list of items. */
export type CompanyConnection = {
   __typename?: 'CompanyConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompanyEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type CompanyCreateInput = {
  /** {"description":"@TODO DESCRIBE ME","required":true,"max":80,"min":2} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME","required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'],
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** {"mediaType":"LOGO_PRINT"} */
  logoPrintMedia?: Maybe<RefInputObject>,
  /** {"mediaType":"GALLERY_GENERIC","ordered":true} */
  additionalImagesMedia?: Maybe<Array<RefInputObject>>,
  /** {} */
  industry: CompanyIndustry,
  /** {} */
  ownership?: Maybe<CompanyOwnership>,
  /** {"required":true,"allowFormInlining":true} */
  billingBranch?: Maybe<RefInputObject>,
  /** {"required":true,"allowFormInlining":true} */
  billingBranch__inline?: Maybe<Branch_From_Company_Type>,
  /** {} */
  headquarterBranch?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":2020,"min":1000} */
  yearEstablished?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {} */
  AtecoCode?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_BEGIN"} */
  vatOpeningDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>,
  /** {"description":"Fare regola ad-hoc","required":true,"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"Required for pubblication","required":true,"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  plProducer?: Maybe<Scalars['Boolean']>,
  /** {"description":"Required for pubblication\nEs.: info@...","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  companyEmail?: Maybe<Scalars['Email']>,
  /** {"description":"Company's certifications"} */
  companyCertifications?: Maybe<Array<RefInputObject>>,
  /** {"description":"Company's features. Visible to buyers only or in premium companies"} */
  goodFeatureCertifications?: Maybe<Array<RefInputObject>>,
  /** {"description":"Company's GeoIndications. Visible to buyers only or in premium companies"} */
  geoIndications?: Maybe<Array<RefInputObject>>,
};

/** An edge in a connection. */
export type CompanyEdge = {
   __typename?: 'CompanyEdge',
  /** The item at the end of the edge */
  node?: Maybe<Company>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type CompanyFilterInput = {
  /** Contained in name, billingName and brands.name(s) */
  name?: Maybe<Scalars['String']>,
  vatNumber?: Maybe<Scalars['String']>,
  producerChangedAfter?: Maybe<Scalars['DateTime']>,
  Award?: Maybe<Array<RefInputObject>>,
  hasLogo?: Maybe<Scalars['Boolean']>,
  profileCompleted?: Maybe<Scalars['Boolean']>,
  hasGoodsWithEan?: Maybe<Scalars['Boolean']>,
  hasCandidacies?: Maybe<Scalars['Boolean']>,
};

/** {"description":"Account che gestisce una GeoIndication o che comunque è un gruppo di Company","canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type CompanyGroup = Node & {
   __typename?: 'CompanyGroup',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"Salesforce object ID","max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","required":true,"max":80,"min":2} */
  name: Scalars['String'],
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>,
  /** {"readonly":true} */
  type: CompanyGroupType,
  /** {"description":"@TODO DESCRIBE ME","required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'],
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>,
  /** {"description":"@TODO DESCRIBE ME","max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country: Country,
  /** {} */
  StateProvince?: Maybe<StateProvince>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address1: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
  /** {"description":"Not actual User but intermediate object with user capabilities"} */
  managedByUsers: Array<ManagedByUser>,
  /** {"description":"Not actual News but intermediate object with count"} */
  newsCitations?: Maybe<Array<NewsCitation>>,
  /** {} */
  userObjectSubscriptions?: Maybe<Array<UserObjectSubscription>>,
  /** {} */
  userNotes?: Maybe<Array<UserNote>>,
};

/** A connection to a list of items. */
export type CompanyGroupConnection = {
   __typename?: 'CompanyGroupConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompanyGroupEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type CompanyGroupCreateInput = {
  /** {"description":"@TODO DESCRIBE ME","required":true,"max":80,"min":2} */
  name: Scalars['String'],
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** {"readonly":true} */
  type: CompanyGroupType,
  /** {"description":"@TODO DESCRIBE ME","required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'],
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>,
  /** {"description":"@TODO DESCRIBE ME","max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country: RefInputObject,
  /** {} */
  StateProvince?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address1: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
};

/** An edge in a connection. */
export type CompanyGroupEdge = {
   __typename?: 'CompanyGroupEdge',
  /** The item at the end of the edge */
  node?: Maybe<CompanyGroup>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type CompanyGroupFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  producerChangedAfter?: Maybe<Scalars['DateTime']>,
  types?: Maybe<Array<CompanyGroupType>>,
  Country?: Maybe<Array<RefInputObject>>,
};

export enum CompanyGroupOrderBy {
  Name = 'name',
  Type = 'type'
}

export type CompanyGroupPatchInput = {
  /** {"description":"@TODO DESCRIBE ME","required":true,"max":80,"min":2} */
  name?: Maybe<Scalars['String']>,
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>,
  /** {"description":"@TODO DESCRIBE ME","max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country?: Maybe<RefInputObject>,
  /** {} */
  StateProvince?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address1?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
};

/** CompanyGroup possible types */
export enum CompanyGroupType {
  ConsorzioTutela = 'CONSORZIO_TUTELA',
  ConsorzioPrivato = 'CONSORZIO_PRIVATO'
}

/** Company possible industries */
export enum CompanyIndustry {
  FoodIndustry = 'FOOD_INDUSTRY',
  Retail = 'RETAIL',
  FoodService = 'FOOD_SERVICE'
}

/** 
 * {"description":"Livello della Company su un determinato target_site per un certo
 * periodo (legato ad e-commerce SF)","canCreateRoles":{"INTERNAL":"MARKETING_EXTRA"},"canPatchRoles":{"INTERNAL":"MARKETING_EXTRA"},"canDeleteRoles":{"INTERNAL":"MARKETING_EXTRA"}}
 */
export type CompanyLevel = Node & {
   __typename?: 'CompanyLevel',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"readonly":true} */
  Company: Company,
  /** {"readonly":true} */
  targetSite: TargetSite,
  /** {"description":"@TODO DESCRIBE ME","readonly":true,"dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'],
  /** {"description":"@TODO DESCRIBE ME","readonly":true,"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>,
  /** {"readonly":true} */
  level: CompanyLevelEnum,
  /** 
 * {"description":"Es.: il valore \"/tag/la-doria/\" fa prendere le news relative
   * all'azienda da \"https://www.italianfood.net/tag/la-doria/\"","max":200}
 */
  newsPath?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Custom: se impostato ha priorità. Già nella lingua del
   * target_site. Copiato automaticamente (se impostato) nei periodi
   * succesivi","multiline":true,"max":250}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Custom: se impostato ha priorità. Già nella lingua del
   * target_site. Copiato automaticamente (se impostato) nei periodi
   * succesivi","max":1000,"html":true,"htmlLevel":"FORMATTING"}
 */
  descriptionLong?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Custom: se impostato ha priorità. Già nella lingua del
   * target_site. Copiato automaticamente (se impostato) nei periodi succesivi","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"}
 */
  website?: Maybe<Scalars['URL']>,
  /** {"description":"Se settato sovrascrive default in base al level","max":100,"min":1} */
  maxHightlightedGoods?: Maybe<Scalars['Int']>,
  /** {"description":"CompanyBadge linked to CompanyLevel"} */
  companyBadges: Array<CompanyBadge>,
};

export type CompanyLevelCreateInput = {
  /** {"readonly":true} */
  Company: RefInputObject,
  /** {"readonly":true} */
  targetSite: TargetSite,
  /** {"description":"@TODO DESCRIBE ME","readonly":true,"dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'],
  /** {"description":"@TODO DESCRIBE ME","readonly":true,"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>,
  /** {"readonly":true} */
  level: CompanyLevelEnum,
  /** 
 * {"description":"Es.: il valore \"/tag/la-doria/\" fa prendere le news relative
   * all'azienda da \"https://www.italianfood.net/tag/la-doria/\"","max":200}
 */
  newsPath?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Custom: se impostato ha priorità. Già nella lingua del
   * target_site. Copiato automaticamente (se impostato) nei periodi
   * succesivi","multiline":true,"max":250}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Custom: se impostato ha priorità. Già nella lingua del
   * target_site. Copiato automaticamente (se impostato) nei periodi
   * succesivi","max":1000,"html":true,"htmlLevel":"FORMATTING"}
 */
  descriptionLong?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Custom: se impostato ha priorità. Già nella lingua del
   * target_site. Copiato automaticamente (se impostato) nei periodi succesivi","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"}
 */
  website?: Maybe<Scalars['URL']>,
  /** {"description":"Se settato sovrascrive default in base al level","max":100,"min":1} */
  maxHightlightedGoods?: Maybe<Scalars['Int']>,
  /** {"description":"CompanyBadge linked to CompanyLevel"} */
  companyBadges?: Maybe<Array<RefInputObject>>,
};

/** Company possible level types */
export enum CompanyLevelEnum {
  Hidden = 'HIDDEN',
  Free = 'FREE',
  PaidL1 = 'PAID_L1',
  PaidL2 = 'PAID_L2',
  PaidL3 = 'PAID_L3',
  PaidL4 = 'PAID_L4'
}

export type CompanyLevelPatchInput = {
  /** 
 * {"description":"Es.: il valore \"/tag/la-doria/\" fa prendere le news relative
   * all'azienda da \"https://www.italianfood.net/tag/la-doria/\"","max":200}
 */
  newsPath?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Custom: se impostato ha priorità. Già nella lingua del
   * target_site. Copiato automaticamente (se impostato) nei periodi
   * succesivi","multiline":true,"max":250}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Custom: se impostato ha priorità. Già nella lingua del
   * target_site. Copiato automaticamente (se impostato) nei periodi
   * succesivi","max":1000,"html":true,"htmlLevel":"FORMATTING"}
 */
  descriptionLong?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Custom: se impostato ha priorità. Già nella lingua del
   * target_site. Copiato automaticamente (se impostato) nei periodi succesivi","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"}
 */
  website?: Maybe<Scalars['URL']>,
  /** {"description":"Se settato sovrascrive default in base al level","max":100,"min":1} */
  maxHightlightedGoods?: Maybe<Scalars['Int']>,
  /** {"description":"CompanyBadge linked to CompanyLevel"} */
  companyBadges?: Maybe<Array<RefInputObject>>,
};

export enum CompanyOrderBy {
  Name = 'name',
  Industry = 'industry'
}

/** Company possible ownership types */
export enum CompanyOwnership {
  FamilyBusiness = 'FAMILY_BUSINESS',
  MultinationalCorporation = 'MULTINATIONAL_CORPORATION',
  PrivateEquity = 'PRIVATE_EQUITY',
  Cooperative = 'COOPERATIVE'
}

export type CompanyPatchInput = {
  /** {"description":"@TODO DESCRIBE ME","required":true,"max":80,"min":2} */
  name?: Maybe<Scalars['String']>,
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** {"mediaType":"LOGO_PRINT"} */
  logoPrintMedia?: Maybe<RefInputObject>,
  /** {"mediaType":"GALLERY_GENERIC","ordered":true} */
  additionalImagesMedia?: Maybe<Array<RefInputObject>>,
  /** {} */
  industry?: Maybe<CompanyIndustry>,
  /** {} */
  ownership?: Maybe<CompanyOwnership>,
  /** {"required":true,"allowFormInlining":true} */
  billingBranch?: Maybe<RefInputObject>,
  /** {"required":true,"allowFormInlining":true} */
  billingBranch__inline?: Maybe<Branch_From_Company_Type>,
  /** {} */
  headquarterBranch?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":2020,"min":1000} */
  yearEstablished?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {} */
  AtecoCode?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_BEGIN"} */
  vatOpeningDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>,
  /** {"description":"Fare regola ad-hoc","required":true,"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"Required for pubblication","required":true,"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  plProducer?: Maybe<Scalars['Boolean']>,
  /** {"description":"Required for pubblication\nEs.: info@...","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  companyEmail?: Maybe<Scalars['Email']>,
  /** {"description":"Company's certifications"} */
  companyCertifications?: Maybe<Array<RefInputObject>>,
  /** {"description":"Company's features. Visible to buyers only or in premium companies"} */
  goodFeatureCertifications?: Maybe<Array<RefInputObject>>,
  /** {"description":"Company's GeoIndications. Visible to buyers only or in premium companies"} */
  geoIndications?: Maybe<Array<RefInputObject>>,
};

/** Company revenue ranges */
export enum CompanyRevenueRange {
  /** Under 30000000 */
  Before = 'BEFORE',
  /** Over 100000000 */
  After = 'AFTER',
  /** Range 30000000-100000000 */
  Range_0 = 'RANGE_0'
}

/** 
 * {"description":"Dati di una company che cambiano di anno in anno\n\nTODO:\n-
 * fatturato\n- numero dipendenti\n- utile/perdita\n- EBTDA\n???","canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"}}
 */
export type CompanyYear = Node & {
   __typename?: 'CompanyYear',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"readonly":true} */
  Company: Company,
  /** {"description":"@TODO DESCRIBE ME","max":2100,"min":1900} */
  year: Scalars['Int'],
  /** {"description":"Euro senza virgola","max":1000000000000,"min":0} */
  revenue?: Maybe<Scalars['Int53']>,
  /** {"description":"In percentuale","max":100,"min":-100} */
  ebitda?: Maybe<Scalars['Float']>,
  /** {"description":"Euro senza virgola","max":1000000000000,"min":-1000000000000} */
  gain?: Maybe<Scalars['Int53']>,
  /** {"description":"Euro senza virgola","max":1000000000000,"min":-1000000000000} */
  mol?: Maybe<Scalars['Int53']>,
  /** {"description":"@TODO DESCRIBE ME","max":1000000,"min":0} */
  numEmployees?: Maybe<Scalars['Int']>,
};

export type CompanyYearCreateInput = {
  /** {"readonly":true} */
  Company: RefInputObject,
  /** {"description":"@TODO DESCRIBE ME","max":2100,"min":1900} */
  year: Scalars['Int'],
  /** {"description":"Euro senza virgola","max":1000000000000,"min":0} */
  revenue?: Maybe<Scalars['Int53']>,
  /** {"description":"In percentuale","max":100,"min":-100} */
  ebitda?: Maybe<Scalars['Float']>,
  /** {"description":"Euro senza virgola","max":1000000000000,"min":-1000000000000} */
  gain?: Maybe<Scalars['Int53']>,
  /** {"description":"Euro senza virgola","max":1000000000000,"min":-1000000000000} */
  mol?: Maybe<Scalars['Int53']>,
  /** {"description":"@TODO DESCRIBE ME","max":1000000,"min":0} */
  numEmployees?: Maybe<Scalars['Int']>,
};

export type CompanyYearPatchInput = {
  /** {"description":"@TODO DESCRIBE ME","max":2100,"min":1900} */
  year?: Maybe<Scalars['Int']>,
  /** {"description":"Euro senza virgola","max":1000000000000,"min":0} */
  revenue?: Maybe<Scalars['Int53']>,
  /** {"description":"In percentuale","max":100,"min":-100} */
  ebitda?: Maybe<Scalars['Float']>,
  /** {"description":"Euro senza virgola","max":1000000000000,"min":-1000000000000} */
  gain?: Maybe<Scalars['Int53']>,
  /** {"description":"Euro senza virgola","max":1000000000000,"min":-1000000000000} */
  mol?: Maybe<Scalars['Int53']>,
  /** {"description":"@TODO DESCRIBE ME","max":1000000,"min":0} */
  numEmployees?: Maybe<Scalars['Int']>,
};

export type CompanyYearsFilter = {
  year?: Maybe<Scalars['Int']>,
  hasRevenue?: Maybe<Scalars['Boolean']>,
  hasEbitda?: Maybe<Scalars['Boolean']>,
  hasGain?: Maybe<Scalars['Boolean']>,
  hasNumEmployees?: Maybe<Scalars['Boolean']>,
};

/** {"description":"Contact","canCreateRoles":false,"canPatchRoles":false,"canDeleteRoles":false} */
export type Contact = Node & {
   __typename?: 'Contact',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"Contact ID","required":false,"readonly":true,"max":18} */
  Id?: Maybe<Scalars['String']>,
  /** {"description":"Email","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  Email?: Maybe<Scalars['Email']>,
  /** 
 * {"description":"Items Active Access : Elenco delle tipologie di contenuti
   * digitali (per ora) a cui il contatto ha diritto di accesso in questo momento"}
 */
  Items_Active_Access: Array<Contact_Items_Active_Access__C_Enum>,
};

/** SF picklist */
export enum Contact_Items_Active_Access__C_Enum {
  /** FOOD | Digitale | Sfogliabile Rivista */
  FoodDigitaleSfogliabileRivista = 'FOOD___Digitale___Sfogliabile_Rivista',
  /** FOOD | Digitale | Periodico Completo */
  FoodDigitalePeriodicoCompleto = 'FOOD___Digitale___Periodico_Completo',
  /** FOOD | Rivista | Periodico e Speciali */
  FoodRivistaPeriodicoESpeciali = 'FOOD___Rivista___Periodico_e_Speciali',
  /** IFNET | Rivista | Periodico e Speciali */
  IfnetRivistaPeriodicoESpeciali = 'IFNET___Rivista___Periodico_e_Speciali',
  /** IFNET | Digitale | Periodico Completo */
  IfnetDigitalePeriodicoCompleto = 'IFNET___Digitale___Periodico_Completo',
  /** DS | Rivista | Periodico e Speciali */
  DsRivistaPeriodicoESpeciali = 'DS___Rivista___Periodico_e_Speciali',
  /** DS | Digitale | Periodico Completo */
  DsDigitalePeriodicoCompleto = 'DS___Digitale___Periodico_Completo',
  /** FS | Rivista | Periodico e Speciali */
  FsRivistaPeriodicoESpeciali = 'FS___Rivista___Periodico_e_Speciali',
  /** FS | Digitale | Periodico Completo */
  FsDigitalePeriodicoCompleto = 'FS___Digitale___Periodico_Completo'
}

/** {"description":"@TODO DESCRIBE ME"} */
export type Country = Node & {
   __typename?: 'Country',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME","max":2} */
  code?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  area: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  continentMc?: Maybe<Scalars['String']>,
};

/** A connection to a list of items. */
export type CountryConnection = {
   __typename?: 'CountryConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CountryEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

/** An edge in a connection. */
export type CountryEdge = {
   __typename?: 'CountryEdge',
  /** The item at the end of the edge */
  node?: Maybe<Country>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type CountryFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  /** Contained in code */
  code?: Maybe<Scalars['String']>,
  /** Contained in area */
  area?: Maybe<Scalars['String']>,
  /** Contained in continentMc */
  continentMc?: Maybe<Scalars['String']>,
};

export enum CountryOrderByEnum {
  Name = 'name',
  Code = 'code',
  Area = 'area',
  ContinentMc = 'continentMc'
}


/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"}} */
export type DistributionChannel = Node & {
   __typename?: 'DistributionChannel',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"required":true,"readonly":true} */
  Company: Company,
  /** {} */
  mode: DistributionMode,
  /** {"required":true,"min":1} */
  channels: Array<Channel>,
  /** {"mediaType":"PDF_GENERIC"} */
  catalogMedia?: Maybe<Media>,
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  contactEmail?: Maybe<Scalars['Email']>,
  /** {"multiline":true,"max":150} */
  note?: Maybe<Scalars['String']>,
  /** {"required":true,"min":1} */
  countries: Array<Country>,
};

export type DistributionChannelCreateInput = {
  /** {"required":true,"readonly":true} */
  Company: RefInputObject,
  /** {} */
  mode: DistributionMode,
  /** {"required":true,"min":1} */
  channels?: Maybe<Array<Channel>>,
  /** {"mediaType":"PDF_GENERIC"} */
  catalogMedia?: Maybe<RefInputObject>,
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  contactEmail?: Maybe<Scalars['Email']>,
  /** {"multiline":true,"max":150} */
  note?: Maybe<Scalars['String']>,
  /** {"required":true,"min":1} */
  countries?: Maybe<Array<RefInputObject>>,
};

export type DistributionChannelPatchInput = {
  /** {} */
  mode?: Maybe<DistributionMode>,
  /** {"required":true,"min":1} */
  channels?: Maybe<Array<Channel>>,
  /** {"mediaType":"PDF_GENERIC"} */
  catalogMedia?: Maybe<RefInputObject>,
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  contactEmail?: Maybe<Scalars['Email']>,
  /** {"multiline":true,"max":150} */
  note?: Maybe<Scalars['String']>,
  /** {"required":true,"min":1} */
  countries?: Maybe<Array<RefInputObject>>,
};

export enum DistributionMode {
  Direct = 'DIRECT',
  Distributor = 'DISTRIBUTOR',
  LocalBranch = 'LOCAL_BRANCH',
  Broker = 'BROKER'
}

/** TODO */
export enum EditorialBrand {
  Barbusiness = 'BARBUSINESS',
  Foodweb = 'FOODWEB',
  Ifn = 'IFN',
  CmAttuali = 'CM_ATTUALI',
  Goods4Hub = 'GOODS4HUB',
  Food = 'FOOD',
  Foodservice = 'FOODSERVICE'
}

/** 
 * {"description":"Il editorialBrand ?+level? serve per sapere in quale campo
 * scrivere quando riporto in SF","canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}}
 */
export type EditorialSection = Node & {
   __typename?: 'EditorialSection',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"Anche uno per l'unione europea (IGP)\nServe anche decodifica
   * verso campo SF + per unione europea il fatto che non va su
   * SF","readonly":true}
 */
  editorialBrand: EditorialBrand,
  /** {"description":"@TODO DESCRIBE ME","required":true} */
  name: Scalars['String'],
  /** {"description":"Usato per redirect da url sito storico"} */
  slug?: Maybe<Scalars['String']>,
  /** {"description":"Per gestire categorie e sotto-categorie"} */
  Parent?: Maybe<EditorialSection>,
  /** 
 * {"description":"Also Known As\nUsato nella ricerca come alias minore (minor
   * peso) del nome\nUno per riga\nNon visibile, compilabile o editabile salvo
   * utenti interni\nPer le tabelle di sistema contiene anche le traduzioni a scopo
   * di full-text search."}
 */
  akas?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  level: Scalars['Int'],
  /** {"description":"@TODO DESCRIBE ME"} */
  styleClass?: Maybe<Scalars['String']>,
  /** {"max":45} */
  color?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME (reference)","mediaType":"ICON"} */
  iconMedia?: Maybe<Media>,
  /** {"max":1000} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"GoodClasses linked to EditorialSection"} */
  goodClasses: Array<GoodClass>,
};

/** A connection to a list of items. */
export type EditorialSectionConnection = {
   __typename?: 'EditorialSectionConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EditorialSectionEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type EditorialSectionCreateInput = {
  /** 
 * {"description":"Anche uno per l'unione europea (IGP)\nServe anche decodifica
   * verso campo SF + per unione europea il fatto che non va su
   * SF","readonly":true}
 */
  editorialBrand: EditorialBrand,
  /** {"description":"@TODO DESCRIBE ME","required":true} */
  name: Scalars['String'],
  /** {"description":"Per gestire categorie e sotto-categorie"} */
  Parent?: Maybe<RefInputObject>,
  /** 
 * {"description":"Also Known As\nUsato nella ricerca come alias minore (minor
   * peso) del nome\nUno per riga\nNon visibile, compilabile o editabile salvo
   * utenti interni\nPer le tabelle di sistema contiene anche le traduzioni a scopo
   * di full-text search."}
 */
  akas?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  level: Scalars['Int'],
  /** {"description":"@TODO DESCRIBE ME"} */
  styleClass?: Maybe<Scalars['String']>,
  /** {"max":45} */
  color?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME (reference)","mediaType":"ICON"} */
  iconMedia?: Maybe<RefInputObject>,
  /** {"max":1000} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"GoodClasses linked to EditorialSection"} */
  goodClasses?: Maybe<Array<RefInputObject>>,
};

/** An edge in a connection. */
export type EditorialSectionEdge = {
   __typename?: 'EditorialSectionEdge',
  /** The item at the end of the edge */
  node?: Maybe<EditorialSection>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type EditorialSectionFilterInput = {
  /** Contained in name or akas */
  name?: Maybe<Scalars['String']>,
  EditorialBrand?: Maybe<EditorialBrand>,
};

export enum EditorialSectionOrderBy {
  Name = 'name',
  EditorialBrand = 'editorialBrand'
}

export type EditorialSectionPatchInput = {
  /** {"description":"@TODO DESCRIBE ME","required":true} */
  name?: Maybe<Scalars['String']>,
  /** {"description":"Per gestire categorie e sotto-categorie"} */
  Parent?: Maybe<RefInputObject>,
  /** 
 * {"description":"Also Known As\nUsato nella ricerca come alias minore (minor
   * peso) del nome\nUno per riga\nNon visibile, compilabile o editabile salvo
   * utenti interni\nPer le tabelle di sistema contiene anche le traduzioni a scopo
   * di full-text search."}
 */
  akas?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  level?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  styleClass?: Maybe<Scalars['String']>,
  /** {"max":45} */
  color?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME (reference)","mediaType":"ICON"} */
  iconMedia?: Maybe<RefInputObject>,
  /** {"max":1000} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"GoodClasses linked to EditorialSection"} */
  goodClasses?: Maybe<Array<RefInputObject>>,
};

/** {"description":"@TODO","canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"EDITORIAL_BASE"}} */
export type EditorialTag = Node & {
   __typename?: 'EditorialTag',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"Usato per redirect da url sito storico"} */
  slug: Scalars['String'],
};

/** A connection to a list of items. */
export type EditorialTagConnection = {
   __typename?: 'EditorialTagConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EditorialTagEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type EditorialTagCreateInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"Usato per redirect da url sito storico"} */
  slug: Scalars['String'],
};

/** An edge in a connection. */
export type EditorialTagEdge = {
   __typename?: 'EditorialTagEdge',
  /** The item at the end of the edge */
  node?: Maybe<EditorialTag>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type EditorialTagFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
};

export type EditorialTagPatchInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {"description":"Usato per redirect da url sito storico"} */
  slug?: Maybe<Scalars['String']>,
};


/** 
 * {"description":"Domini email per registrazioni o dinieghi automatici.\nSe
 * creazione prendo tutti gli user con status: UserStatuses.WAITING_REVIEW e con
 * work_email che corrisponde ed eseguo la action + eventuale notifica via email (visto che è async)","canCreateRoles":{"INTERNAL":"USERS_BASE"},"canPatchRoles":{"INTERNAL":"USERS_BASE"},"canDeleteRoles":{"INTERNAL":"USERS_BASE"}}
 */
export type EmailDomain = Node & {
   __typename?: 'EmailDomain',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME","required":true,"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  emailDomain: Scalars['String'],
  /** {} */
  accountType?: Maybe<AccountType>,
  /** {} */
  action?: Maybe<EmailDomainAction>,
  /** {} */
  Retailer?: Maybe<Retailer>,
  /** {} */
  Company?: Maybe<Company>,
  /** {} */
  CompanyGroup?: Maybe<CompanyGroup>,
  /** {} */
  Service?: Maybe<Service>,
  /** {} */
  Team?: Maybe<Team>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>,
  /** 
 * {"description":"Anche se non ho creato l'oggetto in HUB può essere
   * valorizzato, altrimenti prendere il valore dell'oggetto associato
   * sopra","max":15,"min":15}
 */
  accountSfid?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  note?: Maybe<Scalars['String']>,
};

/** Automatic internal action performed */
export enum EmailDomainAction {
  WaitAndNotify = 'WAIT_AND_NOTIFY',
  Accept = 'ACCEPT',
  ConsumerOnly = 'CONSUMER_ONLY'
}

/** A connection to a list of items. */
export type EmailDomainConnection = {
   __typename?: 'EmailDomainConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EmailDomainEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type EmailDomainCreateInput = {
  /** {"description":"@TODO DESCRIBE ME","required":true,"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  emailDomain: Scalars['String'],
  /** {} */
  accountType?: Maybe<AccountType>,
  /** {} */
  action?: Maybe<EmailDomainAction>,
  /** {} */
  Retailer?: Maybe<RefInputObject>,
  /** {} */
  Company?: Maybe<RefInputObject>,
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>,
  /** {} */
  Service?: Maybe<RefInputObject>,
  /** {} */
  Team?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>,
  /** 
 * {"description":"Anche se non ho creato l'oggetto in HUB può essere
   * valorizzato, altrimenti prendere il valore dell'oggetto associato
   * sopra","max":15,"min":15}
 */
  accountSfid?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  note?: Maybe<Scalars['String']>,
};

/** An edge in a connection. */
export type EmailDomainEdge = {
   __typename?: 'EmailDomainEdge',
  /** The item at the end of the edge */
  node?: Maybe<EmailDomain>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type EmailDomainFilterInput = {
  /** Contained in emailDomain */
  emailDomain?: Maybe<Scalars['String']>,
  accountTypes?: Maybe<Array<AccountType>>,
  actions?: Maybe<Array<EmailDomainAction>>,
};

export enum EmailDomainOrderBy {
  EmailDomain = 'emailDomain',
  AccountType = 'accountType',
  Action = 'action'
}

export type EmailDomainPatchInput = {
  /** {"description":"@TODO DESCRIBE ME","required":true,"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  emailDomain?: Maybe<Scalars['String']>,
  /** {} */
  accountType?: Maybe<AccountType>,
  /** {} */
  action?: Maybe<EmailDomainAction>,
  /** {} */
  Retailer?: Maybe<RefInputObject>,
  /** {} */
  Company?: Maybe<RefInputObject>,
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>,
  /** {} */
  Service?: Maybe<RefInputObject>,
  /** {} */
  Team?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>,
  /** 
 * {"description":"Anche se non ho creato l'oggetto in HUB può essere
   * valorizzato, altrimenti prendere il valore dell'oggetto associato
   * sopra","max":15,"min":15}
 */
  accountSfid?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  note?: Maybe<Scalars['String']>,
};

/** {"description":"@TODO","canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type EuCategory = Node & {
   __typename?: 'EuCategory',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {} */
  HtmlTemplate: HtmlTemplate,
};

/** A connection to a list of items. */
export type EuCategoryConnection = {
   __typename?: 'EuCategoryConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EuCategoryEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type EuCategoryCreateInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {} */
  HtmlTemplate: RefInputObject,
};

/** An edge in a connection. */
export type EuCategoryEdge = {
   __typename?: 'EuCategoryEdge',
  /** The item at the end of the edge */
  node?: Maybe<EuCategory>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type EuCategoryPatchInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {} */
  HtmlTemplate?: Maybe<RefInputObject>,
};

/** EU Indications */
export enum EuIndication {
  Pdo = 'PDO',
  Pgi = 'PGI',
  Tsg = 'TSG'
}

/** Possible exclusion reasons */
export enum ExclusionReason {
  Price = 'PRICE',
  Packaging = 'PACKAGING',
  Logistic = 'LOGISTIC',
  Certifications = 'CERTIFICATIONS',
  ProductiveCapability = 'PRODUCTIVE_CAPABILITY',
  Other = 'OTHER'
}

export type FacetValueCount = {
   __typename?: 'FacetValueCount',
  value?: Maybe<Scalars['String']>,
  count?: Maybe<Scalars['Int']>,
};

/** {"description":"Account che fa servizi accessori","canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type Fair = Node & {
   __typename?: 'Fair',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO"} */
  slug: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  edition?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":2100,"min":2000} */
  year: Scalars['Int'],
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_BEGIN"} */
  begin: Scalars['DateTime'],
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_END"} */
  end: Scalars['DateTime'],
  /** {} */
  targetSites: Array<TargetSite>,
  /** 
 * {"description":"Es.: il valore \"/tag/sial2018/\" fa prendere le news relative
   * all'azienda da \"https://www.italianfood.net/tag/sial2018/\" o
   * \"https://www.foodweb.it/tag/sial2018/\"","max":200}
 */
  newsPath?: Maybe<Scalars['String']>,
  /** {"description":"GoodClasses available for Brand's Goods"} */
  goodClasses: Array<GoodClass>,
  /** {"description":"EditorialSections directly linked"} */
  editorialSections: Array<EditorialSection>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country: Country,
  /** {} */
  StateProvince?: Maybe<StateProvince>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address1: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
  /** {"max":10,"forceCase":"LOWER"} */
  ourPavilion?: Maybe<Scalars['String']>,
  /** {"max":10,"forceCase":"LOWER"} */
  ourStand?: Maybe<Scalars['String']>,
  /** {} */
  ourNote?: Maybe<Scalars['String']>,
  /** {"description":"Not actual Company but intermediate object with pavillion, stand, etc."} */
  participations?: Maybe<FairParticipationConnection>,
  /** {} */
  visits?: Maybe<Array<FairVisit>>,
  /** {"description":"Not actual News but intermediate object with count"} */
  newsCitations?: Maybe<Array<NewsCitation>>,
};


/** {"description":"Account che fa servizi accessori","canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type FairParticipationsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<FairParticipationsFilterInput>
};

/** A connection to a list of items. */
export type FairConnection = {
   __typename?: 'FairConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FairEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type FairCreateInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  edition?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":2100,"min":2000} */
  year: Scalars['Int'],
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_BEGIN"} */
  begin: Scalars['DateTime'],
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_END"} */
  end: Scalars['DateTime'],
  /** {} */
  targetSites?: Maybe<Array<TargetSite>>,
  /** 
 * {"description":"Es.: il valore \"/tag/sial2018/\" fa prendere le news relative
   * all'azienda da \"https://www.italianfood.net/tag/sial2018/\" o
   * \"https://www.foodweb.it/tag/sial2018/\"","max":200}
 */
  newsPath?: Maybe<Scalars['String']>,
  /** {"description":"GoodClasses available for Brand's Goods"} */
  goodClasses?: Maybe<Array<RefInputObject>>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country: RefInputObject,
  /** {} */
  StateProvince?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address1: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
  /** {"max":10,"forceCase":"LOWER"} */
  ourPavilion?: Maybe<Scalars['String']>,
  /** {"max":10,"forceCase":"LOWER"} */
  ourStand?: Maybe<Scalars['String']>,
  /** {} */
  ourNote?: Maybe<Scalars['String']>,
};

/** An edge in a connection. */
export type FairEdge = {
   __typename?: 'FairEdge',
  /** The item at the end of the edge */
  node?: Maybe<Fair>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type FairFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  hasMyVisit?: Maybe<Scalars['Boolean']>,
  isConcluded?: Maybe<Scalars['Boolean']>,
  timespanMin?: Maybe<Scalars['DateTime']>,
  timespanMax?: Maybe<Scalars['DateTime']>,
  Country?: Maybe<Array<RefInputObject>>,
  minParticipants?: Maybe<Scalars['Int']>,
};

/** 
 * {"description":"Se la Company partecipa alla Fair con indicazione di padiglione,
 * stand e note fra cui il contatto commerciale presente in fiera","canCreateRoles":{"INTERNAL":"MARKETING_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE","PRODUCER":"EDIT"}}
 */
export type FairParticipation = Node & {
   __typename?: 'FairParticipation',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  Fair: Fair,
  /** {} */
  Company: Company,
  /** {"max":10,"forceCase":"LOWER"} */
  pavilion?: Maybe<Scalars['String']>,
  /** {"max":10,"forceCase":"LOWER"} */
  stand?: Maybe<Scalars['String']>,
  /** {} */
  note?: Maybe<Scalars['String']>,
  /** {} */
  visits: Array<FairVisit>,
};

/** A connection to a list of items. */
export type FairParticipationConnection = {
   __typename?: 'FairParticipationConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FairParticipationEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type FairParticipationCreateInput = {
  /** {} */
  Fair: RefInputObject,
  /** {} */
  Company: RefInputObject,
  /** {"max":10,"forceCase":"LOWER"} */
  pavilion?: Maybe<Scalars['String']>,
  /** {"max":10,"forceCase":"LOWER"} */
  stand?: Maybe<Scalars['String']>,
  /** {} */
  note?: Maybe<Scalars['String']>,
};

/** An edge in a connection. */
export type FairParticipationEdge = {
   __typename?: 'FairParticipationEdge',
  /** The item at the end of the edge */
  node?: Maybe<FairParticipation>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type FairParticipationPatchInput = {
  /** {} */
  Fair?: Maybe<RefInputObject>,
  /** {} */
  Company?: Maybe<RefInputObject>,
  /** {"max":10,"forceCase":"LOWER"} */
  pavilion?: Maybe<Scalars['String']>,
  /** {"max":10,"forceCase":"LOWER"} */
  stand?: Maybe<Scalars['String']>,
  /** {} */
  note?: Maybe<Scalars['String']>,
};

export type FairParticipationsFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
};

export type FairPatchInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  edition?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":2100,"min":2000} */
  year?: Maybe<Scalars['Int']>,
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_BEGIN"} */
  begin?: Maybe<Scalars['DateTime']>,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_END"} */
  end?: Maybe<Scalars['DateTime']>,
  /** {} */
  targetSites?: Maybe<Array<TargetSite>>,
  /** 
 * {"description":"Es.: il valore \"/tag/sial2018/\" fa prendere le news relative
   * all'azienda da \"https://www.italianfood.net/tag/sial2018/\" o
   * \"https://www.foodweb.it/tag/sial2018/\"","max":200}
 */
  newsPath?: Maybe<Scalars['String']>,
  /** {"description":"GoodClasses available for Brand's Goods"} */
  goodClasses?: Maybe<Array<RefInputObject>>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country?: Maybe<RefInputObject>,
  /** {} */
  StateProvince?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address1?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
  /** {"max":10,"forceCase":"LOWER"} */
  ourPavilion?: Maybe<Scalars['String']>,
  /** {"max":10,"forceCase":"LOWER"} */
  ourStand?: Maybe<Scalars['String']>,
  /** {} */
  ourNote?: Maybe<Scalars['String']>,
};

/** {"description":"User/Team-Object linking table + thumb, note, etc.","canCreateRoles":{"INTERNAL":"ADMIN_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"canPatchRoles":{"INTERNAL":"ADMIN_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"canDeleteRoles":{"INTERNAL":"ADMIN_BASE","BUYER":true,"BUYER_PL_ITALIA":true}} */
export type FairVisit = Node & {
   __typename?: 'FairVisit',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  User: User,
  /** {} */
  Fair: Fair,
  /** {"description":"Not actual Company but intermediate object with pavillion, stand, etc."} */
  fairParticipations: Array<FairParticipation>,
};

/** A connection to a list of items. */
export type FairVisitConnection = {
   __typename?: 'FairVisitConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FairVisitEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type FairVisitCreateInput = {
  /** {} */
  Fair: RefInputObject,
  /** {"description":"Not actual Company but intermediate object with pavillion, stand, etc."} */
  fairParticipations?: Maybe<Array<RefInputObject>>,
};

/** An edge in a connection. */
export type FairVisitEdge = {
   __typename?: 'FairVisitEdge',
  /** The item at the end of the edge */
  node?: Maybe<FairVisit>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type FairVisitFilterInput = {
  User?: Maybe<RefInputObject>,
  Fair?: Maybe<RefInputObject>,
};

/** {"description":"User/Team-Object linking table + thumb, note, etc.","canCreateRoles":{"INTERNAL":"ADMIN_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"canPatchRoles":{"INTERNAL":"ADMIN_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"canDeleteRoles":{"INTERNAL":"ADMIN_BASE","BUYER":true,"BUYER_PL_ITALIA":true}} */
export type FairVisitParticipation = Node & {
   __typename?: 'FairVisitParticipation',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  FairVisit: FairVisit,
  /** {} */
  FairParticipation?: Maybe<FairParticipation>,
  /** {"description":"@TODO DESCRIBE ME"} */
  done: Scalars['Boolean'],
};

export type FairVisitParticipationCreateInput = {
  /** {} */
  FairParticipation?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME"} */
  done: Scalars['Boolean'],
};

export type FairVisitParticipationPatchInput = {
  /** {} */
  FairParticipation?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME"} */
  done?: Maybe<Scalars['Boolean']>,
};

export type FairVisitPatchInput = {
  /** {} */
  Fair?: Maybe<RefInputObject>,
  /** {"description":"Not actual Company but intermediate object with pavillion, stand, etc."} */
  fairParticipations?: Maybe<Array<RefInputObject>>,
};

/** Possible final positions for award candidacies */
export enum FinalPosition {
  Winner = 'WINNER',
  Second = 'SECOND',
  Third = 'THIRD'
}

/** {"description":"User/Team-Object linking table + vote, note, etc.","canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"canSetStatusRoles":{"status":{"DRAFT":{"INTERNAL":"DIRECTORY_BASE"},"PUBLISHED":{"INTERNAL":"DIRECTORY_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"HIGHLIGHTED":{"INTERNAL":"EDITORIAL_EXTRA"},"DELETED":{"INTERNAL":"DIRECTORY_BASE"}}},"canPatchNeedCurrentStatusAccess":"status"} */
export type FrequentSearch = Node & {
   __typename?: 'FrequentSearch',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"readonly":true} */
  collection: FrequentSearchCollection,
  /** {"required":true,"min":1} */
  targetSites: Array<TargetSite>,
  /** {"description":"Nome","required":true,"max":150,"min":3} */
  name: Scalars['String'],
  /** {"description":"Nome italiano","required":true,"max":150,"min":3} */
  nameIta?: Maybe<Scalars['String']>,
  /** {} */
  akas?: Maybe<Scalars['String']>,
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>,
  /** {"max":1000} */
  description?: Maybe<Scalars['String']>,
  /** {} */
  linkUrl?: Maybe<Scalars['URL']>,
  /** {} */
  linkRoles: Array<AccessRole>,
  /** {} */
  status: FrequentSearchStatus,
  /** {} */
  relatedFair?: Maybe<Fair>,
  /** {} */
  isDynamic: Scalars['Boolean'],
  /** {} */
  systemType?: Maybe<FrequentSearchSystemType>,
  /** {} */
  User?: Maybe<User>,
  /** {} */
  Team?: Maybe<Team>,
  /** {} */
  goodClasses: Array<GoodClass>,
  /** {} */
  goodFeatureCertifications: Array<GoodFeatureCertification>,
  /** {} */
  geoIndications: Array<GeoIndication>,
  /** {} */
  companyCertifications: Array<CompanyCertification>,
  /** {} */
  plProducer?: Maybe<Scalars['Boolean']>,
  /** {} */
  fairs: Array<Fair>,
  /** {} */
  awards: Array<Award>,
  /** {} */
  awardFinalPositions: Array<FinalPosition>,
  /** {} */
  preservations: Array<Preservation>,
  /** {} */
  trasformationLevels: Array<TransformationLevel>,
  /** {} */
  stateProvinces: Array<StateProvince>,
  /** {} */
  textSearch?: Maybe<Scalars['String']>,
  /** {} */
  reveneueRanges: Array<CompanyRevenueRange>,
  /** {} */
  pricePositionings: Array<PricePositioning>,
  /** {} */
  highlightReasons: Array<HighlightReasons>,
  /** {} */
  companies: Array<Company>,
  /** {} */
  goods: Array<Good>,
  /** {} */
  retailers: Array<Retailer>,
  /** {} */
  companyGroups: Array<CompanyGroup>,
  /** {} */
  services: Array<Service>,
};

/** FrequentSearch's possible collections */
export enum FrequentSearchCollection {
  Good = 'GOOD',
  Company = 'COMPANY',
  GeoIndication = 'GEO_INDICATION',
  News = 'NEWS'
}

/** A connection to a list of items. */
export type FrequentSearchConnection = {
   __typename?: 'FrequentSearchConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FrequentSearchEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type FrequentSearchCreateInput = {
  /** {"readonly":true} */
  collection: FrequentSearchCollection,
  /** {"required":true,"min":1} */
  targetSites?: Maybe<Array<TargetSite>>,
  /** {"description":"Nome","required":true,"max":150,"min":3} */
  name: Scalars['String'],
  /** {"description":"Nome italiano","required":true,"max":150,"min":3} */
  nameIta?: Maybe<Scalars['String']>,
  /** {} */
  akas?: Maybe<Scalars['String']>,
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** {"max":1000} */
  description?: Maybe<Scalars['String']>,
  /** {} */
  linkUrl?: Maybe<Scalars['URL']>,
  /** {} */
  linkRoles?: Maybe<Array<AccessRole>>,
  /** {} */
  relatedFair?: Maybe<RefInputObject>,
  /** {} */
  goodClasses?: Maybe<Array<RefInputObject>>,
  /** {} */
  goodFeatureCertifications?: Maybe<Array<RefInputObject>>,
  /** {} */
  geoIndications?: Maybe<Array<RefInputObject>>,
  /** {} */
  companyCertifications?: Maybe<Array<RefInputObject>>,
  /** {} */
  plProducer?: Maybe<Scalars['Boolean']>,
  /** {} */
  fairs?: Maybe<Array<RefInputObject>>,
  /** {} */
  awards?: Maybe<Array<RefInputObject>>,
  /** {} */
  awardFinalPositions?: Maybe<Array<FinalPosition>>,
  /** {} */
  preservations?: Maybe<Array<Preservation>>,
  /** {} */
  trasformationLevels?: Maybe<Array<TransformationLevel>>,
  /** {} */
  stateProvinces?: Maybe<Array<RefInputObject>>,
  /** {} */
  textSearch?: Maybe<Scalars['String']>,
  /** {} */
  reveneueRanges?: Maybe<Array<CompanyRevenueRange>>,
  /** {} */
  pricePositionings?: Maybe<Array<PricePositioning>>,
  /** {} */
  highlightReasons?: Maybe<Array<HighlightReasons>>,
  /** {} */
  companies?: Maybe<Array<RefInputObject>>,
  /** {} */
  goods?: Maybe<Array<RefInputObject>>,
  /** {} */
  retailers?: Maybe<Array<RefInputObject>>,
  /** {} */
  companyGroups?: Maybe<Array<RefInputObject>>,
  /** {} */
  services?: Maybe<Array<RefInputObject>>,
  FrequentSearch?: Maybe<RefInputObject>,
};

/** An edge in a connection. */
export type FrequentSearchEdge = {
   __typename?: 'FrequentSearchEdge',
  /** The item at the end of the edge */
  node?: Maybe<FrequentSearch>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type FrequentSearchFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  collection?: Maybe<FrequentSearchCollection>,
  targetSite?: Maybe<TargetSite>,
  /** TRUE - solo currentUser, FALSE - solo !currentUser */
  mine?: Maybe<Scalars['Boolean']>,
  statuses?: Maybe<Array<FrequentSearchStatus>>,
  /** TRUE - solo isDynamic, FALSE - solo !isDynamic */
  isDynamic?: Maybe<Scalars['Boolean']>,
  systemTypes?: Maybe<Array<FrequentSearchSystemType>>,
  noUser?: Maybe<Scalars['Boolean']>,
  User?: Maybe<RefInputObject>,
  Team?: Maybe<RefInputObject>,
  relatedFair?: Maybe<RefInputObject>,
  /** !isDynamic and contains passed item */
  containsItem?: Maybe<RefInputObject>,
};

export type FrequentSearchPatchInput = {
  /** {"required":true,"min":1} */
  targetSites?: Maybe<Array<TargetSite>>,
  /** {"description":"Nome","required":true,"max":150,"min":3} */
  name?: Maybe<Scalars['String']>,
  /** {"description":"Nome italiano","required":true,"max":150,"min":3} */
  nameIta?: Maybe<Scalars['String']>,
  /** {} */
  akas?: Maybe<Scalars['String']>,
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** {"max":1000} */
  description?: Maybe<Scalars['String']>,
  /** {} */
  linkUrl?: Maybe<Scalars['URL']>,
  /** {} */
  linkRoles?: Maybe<Array<AccessRole>>,
  /** {} */
  relatedFair?: Maybe<RefInputObject>,
  /** {} */
  goodClasses?: Maybe<Array<RefInputObject>>,
  /** {} */
  goodFeatureCertifications?: Maybe<Array<RefInputObject>>,
  /** {} */
  geoIndications?: Maybe<Array<RefInputObject>>,
  /** {} */
  companyCertifications?: Maybe<Array<RefInputObject>>,
  /** {} */
  plProducer?: Maybe<Scalars['Boolean']>,
  /** {} */
  fairs?: Maybe<Array<RefInputObject>>,
  /** {} */
  awards?: Maybe<Array<RefInputObject>>,
  /** {} */
  awardFinalPositions?: Maybe<Array<FinalPosition>>,
  /** {} */
  preservations?: Maybe<Array<Preservation>>,
  /** {} */
  trasformationLevels?: Maybe<Array<TransformationLevel>>,
  /** {} */
  stateProvinces?: Maybe<Array<RefInputObject>>,
  /** {} */
  textSearch?: Maybe<Scalars['String']>,
  /** {} */
  reveneueRanges?: Maybe<Array<CompanyRevenueRange>>,
  /** {} */
  pricePositionings?: Maybe<Array<PricePositioning>>,
  /** {} */
  highlightReasons?: Maybe<Array<HighlightReasons>>,
  /** {} */
  companies?: Maybe<Array<RefInputObject>>,
  /** {} */
  goods?: Maybe<Array<RefInputObject>>,
  /** {} */
  retailers?: Maybe<Array<RefInputObject>>,
  /** {} */
  companyGroups?: Maybe<Array<RefInputObject>>,
  /** {} */
  services?: Maybe<Array<RefInputObject>>,
};

/** FrequentSearch's possible statues */
export enum FrequentSearchStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Highlighted = 'HIGHLIGHTED',
  Deleted = 'DELETED'
}

/** If FrequentSearch is system-defined this is the type */
export enum FrequentSearchSystemType {
  Favorites = 'FAVORITES'
}

/** 
 * {"description":"Aggiorno su SF in base alle EditorialSection come per Company
 * sulla GeographicalIndication (non sf_account_id)\nUser può gestire:\nlogo (media
 * di chi è?????!!!!)\ntemplate_values (tramite form del
 * HtmlTemplate)\nvat_number\nwebsite\nsocial_*\nindirizzo (headquarter)\n*flag
 * visualizzazione elementi (TODO)\n\n","canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"},"canSetStatusRoles":{"status":{"STUB":{"INTERNAL":"EDITORIAL_BASE"},"DRAFT":{"INTERNAL":"EDITORIAL_BASE"},"WAITING_PUBLICATION":{"INTERNAL":"EDITORIAL_BASE"},"PUBLISHED":{"INTERNAL":"EDITORIAL_EXTRA"},"HIDDEN":{"INTERNAL":"EDITORIAL_BASE"},"DELETED":{"INTERNAL":"EDITORIAL_BASE"}}},"canPatchNeedCurrentStatusAccess":"status"}
 */
export type GeoIndication = Node & {
   __typename?: 'GeoIndication',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"Salesforce object ID","max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>,
  /** {"description":"@TODO"} */
  slug: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>,
  /** {} */
  status: NewsStatus,
  /** 
 * {"description":"Also Known As\nUsato nella ricerca come alias minore (minor
   * peso) del nome\nUno per riga\nNon visibile, compilabile o editabile salvo
   * utenti interni","multiline":true}
 */
  akas?: Maybe<Scalars['String']>,
  /** {"readonly":true} */
  euIndication?: Maybe<EuIndication>,
  /** {"readonly":true} */
  italianIndication?: Maybe<ItalianIndication>,
  /** {"description":"JSON"} */
  descriptionValues?: Maybe<Scalars['String']>,
  /** {"description":"Cache html template compilato"} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"Cache html template compilato"} */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"Solo app aziende"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  socialInstagramPage?: Maybe<Scalars['URL']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  socialFacebookPage?: Maybe<Scalars['URL']>,
  /** {"readonly":true} */
  EuCategory: EuCategory,
  /** {} */
  Country: Country,
  /** {} */
  StateProvince?: Maybe<StateProvince>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address1: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
  /** {} */
  goods: Array<Good>,
  /** {"description":"GeoIndication's Companies."} */
  companies: Array<Company>,
  /** {"description":"Not actual User but intermediate object with user capabilities"} */
  managedByUsers: Array<ManagedByUser>,
  /** {"description":"Not actual News but intermediate object with count"} */
  newsCitations?: Maybe<Array<NewsCitation>>,
};

/** A connection to a list of items. */
export type GeoIndicationConnection = {
   __typename?: 'GeoIndicationConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GeoIndicationEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type GeoIndicationCreateInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** 
 * {"description":"Also Known As\nUsato nella ricerca come alias minore (minor
   * peso) del nome\nUno per riga\nNon visibile, compilabile o editabile salvo
   * utenti interni","multiline":true}
 */
  akas?: Maybe<Scalars['String']>,
  /** {"readonly":true} */
  euIndication?: Maybe<EuIndication>,
  /** {"readonly":true} */
  italianIndication?: Maybe<ItalianIndication>,
  /** {"description":"JSON"} */
  descriptionValues?: Maybe<Scalars['String']>,
  /** {"description":"Solo app aziende"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  socialInstagramPage?: Maybe<Scalars['URL']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  socialFacebookPage?: Maybe<Scalars['URL']>,
  /** {"readonly":true} */
  EuCategory: RefInputObject,
  /** {} */
  Country: RefInputObject,
  /** {} */
  StateProvince?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address1: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
  /** {"description":"GeoIndication's Companies."} */
  companies?: Maybe<Array<RefInputObject>>,
};

/** An edge in a connection. */
export type GeoIndicationEdge = {
   __typename?: 'GeoIndicationEdge',
  /** The item at the end of the edge */
  node?: Maybe<GeoIndication>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type GeoIndicationFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  euIndications?: Maybe<Array<EuIndication>>,
  italianIndications?: Maybe<Array<ItalianIndication>>,
  Company?: Maybe<RefInputObject>,
  Country?: Maybe<RefInputObject>,
  StateProvince?: Maybe<RefInputObject>,
  statuses?: Maybe<Array<NewsStatus>>,
};

export enum GeoIndicationOrderBy {
  Name = 'name',
  StateProvince = 'StateProvince',
  EuIndication = 'euIndication',
  ItalianIndication = 'italianIndication',
  Status = 'status'
}

export type GeoIndicationPatchInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** 
 * {"description":"Also Known As\nUsato nella ricerca come alias minore (minor
   * peso) del nome\nUno per riga\nNon visibile, compilabile o editabile salvo
   * utenti interni","multiline":true}
 */
  akas?: Maybe<Scalars['String']>,
  /** {"description":"JSON"} */
  descriptionValues?: Maybe<Scalars['String']>,
  /** {"description":"Solo app aziende"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  socialInstagramPage?: Maybe<Scalars['URL']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  socialFacebookPage?: Maybe<Scalars['URL']>,
  /** {} */
  Country?: Maybe<RefInputObject>,
  /** {} */
  StateProvince?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address1?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
  /** {"description":"GeoIndication's Companies."} */
  companies?: Maybe<Array<RefInputObject>>,
};

/** {"description":"Funzione Clona Good\nBrand modificabile in qualsiasi momeno","canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"}} */
export type Good = Node & {
   __typename?: 'Good',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true} */
  Brand?: Maybe<Brand>,
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageMedia?: Maybe<Media>,
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageItaMedia?: Maybe<Media>,
  /** {"mediaType":"GOOD_IMAGE","ordered":true} */
  additionalImagesMedia: Array<Media>,
  /** {"mediaType":"GOOD_IMAGE","ordered":true} */
  additionalImagesItaMedia: Array<Media>,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  GeoIndication?: Maybe<GeoIndication>,
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true} */
  GoodClass?: Maybe<GoodClass>,
  /** {"description":"Good's features. Visible to buyers only or in premium companies"} */
  goodFeatureCertifications: Array<GoodFeatureCertification>,
  /** {"description":"Linea di prodotto","max":50,"min":3} */
  range?: Maybe<Scalars['String']>,
  /** {"description":"@TODO"} */
  slug: Scalars['String'],
  /** {"description":"@TODO"} */
  slugIta: Scalars['String'],
  /** {"description":"Nome in etichetta","required":true,"max":150,"min":3} */
  name: Scalars['String'],
  /** {"description":"Nome in etichetta italia","required":true,"max":150,"min":3} */
  nameIta?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":200}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":200}
 */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":500,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":500,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {"description":"In giorni (indicativo perché dipende dai tempi per arrivare sullo scaffale)","max":36500,"min":0} */
  shelfLifeLength: Scalars['Int'],
  /** {} */
  extraMedia?: Maybe<Media>,
  /** {} */
  extraItaMedia?: Maybe<Media>,
  /** {} */
  formats: Array<GoodFormat>,
  /** {"description":"Good's production branches"} */
  productionBranches: Array<Branch>,
  /** {} */
  highlightedGoods: Array<HighlightedGood>,
  /** {"description":"Good's candidacies in varius awards"} */
  candidacies: Array<Candidacy>,
  /** {"description":"Not actual News but intermediate object with count"} */
  newsCitations?: Maybe<Array<NewsCitation>>,
  /** {} */
  userObjectSubscriptions?: Maybe<Array<UserObjectSubscription>>,
  /** {} */
  userNotes?: Maybe<Array<UserNote>>,
};

/** 
 * {"description":"Categorizzazione GS1 + leggera personalizzazione togliendo il
 * decore attribute\nContiene solo gli ultimi livelli.\nPer la visualizzazione ad
 * albero raggruppo per level_1,2,3,4 saltando i livelli che hanno una sola opzione possibile.","canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}}
 */
export type GoodClass = Node & {
   __typename?: 'GoodClass',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  nameIta: Scalars['String'],
  /** 
 * {"description":"Also Known As\nUsato nella ricerca come alias minore (minor
   * peso) del nome\nUno per riga\nNon visibile, compilabile o editabile salvo
   * utenti interni\nPer le tabelle di sistema contiene anche le traduzioni a scopo
   * di full-text search.\nTutte le lingue stanno insieme"}
 */
  akas?: Maybe<Scalars['String']>,
  /** {"description":"GS1 segment"} */
  level1Code: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  level1Desc: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  level1DescIta: Scalars['String'],
  /** {"description":"GS1 family"} */
  level2Code?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level2Desc?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level2DescIta?: Maybe<Scalars['String']>,
  /** {"description":"GS1 class"} */
  level3Code?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level3Desc?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level3DescIta?: Maybe<Scalars['String']>,
  /** {"description":"GS1 brick (not always present)"} */
  level4Code?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level4Desc?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level4DescIta?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Da presentare quando compare come ultimo livello di una
   * visualizzazione ad albero (i livelli precedenti aiutano a spiegare)"}
 */
  shortname: Scalars['String'],
  /** 
 * {"description":"Da presentare quando compare come ultimo livello di una
   * visualizzazione ad albero (i livelli precedenti aiutano a spiegare)"}
 */
  shortnameIta: Scalars['String'],
  /** {} */
  preservation?: Maybe<Preservation>,
  /** {} */
  trasformationLevel?: Maybe<TransformationLevel>,
  /** {"description":"EditorialSection linked to GoodClasses"} */
  editorialSections: Array<EditorialSection>,
};

/** A connection to a list of items. */
export type GoodClassConnection = {
   __typename?: 'GoodClassConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GoodClassEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type GoodClassCreateInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  nameIta: Scalars['String'],
  /** 
 * {"description":"Also Known As\nUsato nella ricerca come alias minore (minor
   * peso) del nome\nUno per riga\nNon visibile, compilabile o editabile salvo
   * utenti interni\nPer le tabelle di sistema contiene anche le traduzioni a scopo
   * di full-text search.\nTutte le lingue stanno insieme"}
 */
  akas?: Maybe<Scalars['String']>,
  /** {"description":"GS1 segment"} */
  level1Code: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  level1Desc: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  level1DescIta: Scalars['String'],
  /** {"description":"GS1 family"} */
  level2Code?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level2Desc?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level2DescIta?: Maybe<Scalars['String']>,
  /** {"description":"GS1 class"} */
  level3Code?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level3Desc?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level3DescIta?: Maybe<Scalars['String']>,
  /** {"description":"GS1 brick (not always present)"} */
  level4Code?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level4Desc?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level4DescIta?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Da presentare quando compare come ultimo livello di una
   * visualizzazione ad albero (i livelli precedenti aiutano a spiegare)"}
 */
  shortname: Scalars['String'],
  /** 
 * {"description":"Da presentare quando compare come ultimo livello di una
   * visualizzazione ad albero (i livelli precedenti aiutano a spiegare)"}
 */
  shortnameIta: Scalars['String'],
  /** {} */
  preservation?: Maybe<Preservation>,
  /** {} */
  trasformationLevel?: Maybe<TransformationLevel>,
};

/** An edge in a connection. */
export type GoodClassEdge = {
   __typename?: 'GoodClassEdge',
  /** The item at the end of the edge */
  node?: Maybe<GoodClass>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type GoodClassPatchInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  nameIta?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Also Known As\nUsato nella ricerca come alias minore (minor
   * peso) del nome\nUno per riga\nNon visibile, compilabile o editabile salvo
   * utenti interni\nPer le tabelle di sistema contiene anche le traduzioni a scopo
   * di full-text search.\nTutte le lingue stanno insieme"}
 */
  akas?: Maybe<Scalars['String']>,
  /** {"description":"GS1 segment"} */
  level1Code?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level1Desc?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level1DescIta?: Maybe<Scalars['String']>,
  /** {"description":"GS1 family"} */
  level2Code?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level2Desc?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level2DescIta?: Maybe<Scalars['String']>,
  /** {"description":"GS1 class"} */
  level3Code?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level3Desc?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level3DescIta?: Maybe<Scalars['String']>,
  /** {"description":"GS1 brick (not always present)"} */
  level4Code?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level4Desc?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  level4DescIta?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Da presentare quando compare come ultimo livello di una
   * visualizzazione ad albero (i livelli precedenti aiutano a spiegare)"}
 */
  shortname?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Da presentare quando compare come ultimo livello di una
   * visualizzazione ad albero (i livelli precedenti aiutano a spiegare)"}
 */
  shortnameIta?: Maybe<Scalars['String']>,
  /** {} */
  preservation?: Maybe<Preservation>,
  /** {} */
  trasformationLevel?: Maybe<TransformationLevel>,
};

/** A connection to a list of items. */
export type GoodConnection = {
   __typename?: 'GoodConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GoodEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type GoodCreateInput = {
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true} */
  Brand?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageMedia?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageItaMedia?: Maybe<RefInputObject>,
  /** {"mediaType":"GOOD_IMAGE","ordered":true} */
  additionalImagesMedia?: Maybe<Array<RefInputObject>>,
  /** {"mediaType":"GOOD_IMAGE","ordered":true} */
  additionalImagesItaMedia?: Maybe<Array<RefInputObject>>,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  GeoIndication?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true} */
  GoodClass?: Maybe<RefInputObject>,
  /** {"description":"Good's features. Visible to buyers only or in premium companies"} */
  goodFeatureCertifications?: Maybe<Array<RefInputObject>>,
  /** {"description":"Linea di prodotto","max":50,"min":3} */
  range?: Maybe<Scalars['String']>,
  /** {"description":"Nome in etichetta","required":true,"max":150,"min":3} */
  name: Scalars['String'],
  /** {"description":"Nome in etichetta italia","required":true,"max":150,"min":3} */
  nameIta?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":200}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":200}
 */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":500,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":500,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {"description":"In giorni (indicativo perché dipende dai tempi per arrivare sullo scaffale)","max":36500,"min":0} */
  shelfLifeLength: Scalars['Int'],
  /** {} */
  extraMedia?: Maybe<RefInputObject>,
  /** {} */
  extraItaMedia?: Maybe<RefInputObject>,
  /** {"description":"Good's production branches"} */
  productionBranches?: Maybe<Array<RefInputObject>>,
  /** {"description":"Good's candidacies in varius awards"} */
  candidacies?: Maybe<Array<RefInputObject>>,
};

/** An edge in a connection. */
export type GoodEdge = {
   __typename?: 'GoodEdge',
  /** The item at the end of the edge */
  node?: Maybe<Good>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type GoodFeatureCertification = Node & {
   __typename?: 'GoodFeatureCertification',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  nameIta: Scalars['String'],
  /** 
 * {"description":"Also Known As\nUsato nella ricerca come alias minore (minor
   * peso) del nome\nUno per riga\nNon visibile, compilabile o editabile salvo
   * utenti interni\nPer le tabelle di sistema contiene anche le traduzioni a scopo
   * di full-text search.","multiline":true}
 */
  akas?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
};

/** A connection to a list of items. */
export type GoodFeatureCertificationConnection = {
   __typename?: 'GoodFeatureCertificationConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GoodFeatureCertificationEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type GoodFeatureCertificationCreateInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  nameIta: Scalars['String'],
  /** 
 * {"description":"Also Known As\nUsato nella ricerca come alias minore (minor
   * peso) del nome\nUno per riga\nNon visibile, compilabile o editabile salvo
   * utenti interni\nPer le tabelle di sistema contiene anche le traduzioni a scopo
   * di full-text search.","multiline":true}
 */
  akas?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
};

/** An edge in a connection. */
export type GoodFeatureCertificationEdge = {
   __typename?: 'GoodFeatureCertificationEdge',
  /** The item at the end of the edge */
  node?: Maybe<GoodFeatureCertification>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type GoodFeatureCertificationPatchInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  nameIta?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Also Known As\nUsato nella ricerca come alias minore (minor
   * peso) del nome\nUno per riga\nNon visibile, compilabile o editabile salvo
   * utenti interni\nPer le tabelle di sistema contiene anche le traduzioni a scopo
   * di full-text search.","multiline":true}
 */
  akas?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
};

/** @TODO DESCRIBE ME */
export type GoodFilterInput = {
  /** Contained in name or nameIta */
  name?: Maybe<Scalars['String']>,
  producerChangedAfter?: Maybe<Scalars['DateTime']>,
  Brand?: Maybe<RefInputObject>,
  Company?: Maybe<RefInputObject>,
  eanPresent?: Maybe<Scalars['Boolean']>,
};

/** 
 * {"description":"Pseudo-referenza\nGestita in gruppo a livello di interfaccia:\n-
 * Inserimento multiplo tramite multi-picklist (comunque valore vero, non indice ->
 * possibili moltiplicazioni su peso)\n- Consultazione multipla raggruppando per
 * campi non-cu_per_selling_pack (magari tramite icona)","canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"}}
 */
export type GoodFormat = Node & {
   __typename?: 'GoodFormat',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  Good: Good,
  /** {} */
  cuMesureUnit: GoodUnit,
  /** {"description":"Usata per la Consumer Unit","max":1000000,"min":1} */
  cuMesureValue: Scalars['Int'],
  /** 
 * {"description":"Inserimento multiplo tramite multi-picklist (comunque valore
   * vero, non indice -> possibili moltiplicazioni su peso)\nConsultazione multipla
   * raggruppando per gli altri campi","max":48,"min":1}
 */
  cuPerSellingPack: Scalars['Int'],
  /** {"description":"International Article Number","max":15,"min":13,"formatRegexStr":"^[0-9]*$"} */
  ean?: Maybe<Scalars['String']>,
  /** {"description":"Pulire e ridurre molto","max":45,"forceCase":"LOWER"} */
  flavor?: Maybe<Scalars['String']>,
  /** {"description":"Pulire e ridurre molto (italiano)","max":45,"forceCase":"LOWER"} */
  flavorIta?: Maybe<Scalars['String']>,
};

export type GoodFormatCreateInput = {
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  Good: RefInputObject,
  /** {} */
  cuMesureUnit: GoodUnit,
  /** {"description":"Usata per la Consumer Unit","max":1000000,"min":1} */
  cuMesureValue: Scalars['Int'],
  /** 
 * {"description":"Inserimento multiplo tramite multi-picklist (comunque valore
   * vero, non indice -> possibili moltiplicazioni su peso)\nConsultazione multipla
   * raggruppando per gli altri campi","max":48,"min":1}
 */
  cuPerSellingPack: Scalars['Int'],
  /** {"description":"International Article Number","max":15,"min":13,"formatRegexStr":"^[0-9]*$"} */
  ean?: Maybe<Scalars['String']>,
};

export type GoodFormatPatchInput = {
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  Good?: Maybe<RefInputObject>,
  /** {} */
  cuMesureUnit?: Maybe<GoodUnit>,
  /** {"description":"Usata per la Consumer Unit","max":1000000,"min":1} */
  cuMesureValue?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"Inserimento multiplo tramite multi-picklist (comunque valore
   * vero, non indice -> possibili moltiplicazioni su peso)\nConsultazione multipla
   * raggruppando per gli altri campi","max":48,"min":1}
 */
  cuPerSellingPack?: Maybe<Scalars['Int']>,
  /** {"description":"International Article Number","max":15,"min":13,"formatRegexStr":"^[0-9]*$"} */
  ean?: Maybe<Scalars['String']>,
};

export enum GoodOrderBy {
  Name = 'name',
  NameIta = 'nameIta'
}

export type GoodPatchInput = {
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true} */
  Brand?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageMedia?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageItaMedia?: Maybe<RefInputObject>,
  /** {"mediaType":"GOOD_IMAGE","ordered":true} */
  additionalImagesMedia?: Maybe<Array<RefInputObject>>,
  /** {"mediaType":"GOOD_IMAGE","ordered":true} */
  additionalImagesItaMedia?: Maybe<Array<RefInputObject>>,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  GeoIndication?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true} */
  GoodClass?: Maybe<RefInputObject>,
  /** {"description":"Good's features. Visible to buyers only or in premium companies"} */
  goodFeatureCertifications?: Maybe<Array<RefInputObject>>,
  /** {"description":"Linea di prodotto","max":50,"min":3} */
  range?: Maybe<Scalars['String']>,
  /** {"description":"Nome in etichetta","required":true,"max":150,"min":3} */
  name?: Maybe<Scalars['String']>,
  /** {"description":"Nome in etichetta italia","required":true,"max":150,"min":3} */
  nameIta?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":200}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":200}
 */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":500,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":500,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {"description":"In giorni (indicativo perché dipende dai tempi per arrivare sullo scaffale)","max":36500,"min":0} */
  shelfLifeLength?: Maybe<Scalars['Int']>,
  /** {} */
  extraMedia?: Maybe<RefInputObject>,
  /** {} */
  extraItaMedia?: Maybe<RefInputObject>,
  /** {"description":"Good's production branches"} */
  productionBranches?: Maybe<Array<RefInputObject>>,
  /** {"description":"Good's candidacies in varius awards"} */
  candidacies?: Maybe<Array<RefInputObject>>,
};

export type GoodsDir = Good;

/** A connection to a list of items. */
export type GoodsDirConnection = {
   __typename?: 'goodsDirConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GoodsDirEdge>>>,
  total?: Maybe<Scalars['Int']>,
  facet?: Maybe<GoodsDirFacets>,
  relatedFrequentSearches?: Maybe<Array<Maybe<FrequentSearch>>>,
};

/** An edge in a connection. */
export type GoodsDirEdge = {
   __typename?: 'goodsDirEdge',
  /** The item at the end of the edge */
  node?: Maybe<GoodsDir>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
  score: Scalars['Float'],
};

export type GoodsDirFacets = {
   __typename?: 'goodsDirFacets',
  goodClass?: Maybe<Array<FacetValueCount>>,
  preservation?: Maybe<Array<FacetValueCount>>,
  trasformationLevel?: Maybe<Array<FacetValueCount>>,
  goodFeatureCertifications?: Maybe<Array<FacetValueCount>>,
  pricePositioning?: Maybe<Array<FacetValueCount>>,
  awardFinalPositions?: Maybe<Array<FacetValueCount>>,
  highlightReasons?: Maybe<Array<FacetValueCount>>,
  stateProvinces?: Maybe<Array<FacetValueCount>>,
};

/** @TODO DESCRIBE ME */
export type GoodsFilterType = {
  targetSite?: Maybe<TargetSite>,
  items?: Maybe<Array<RefInputObject>>,
  FrequentSearch?: Maybe<RefInputObject>,
  textSearch?: Maybe<Scalars['String']>,
  companies?: Maybe<Array<RefInputObject>>,
  brands?: Maybe<Array<RefInputObject>>,
  goodClasses?: Maybe<Array<RefInputObject>>,
  preservations?: Maybe<Array<Preservation>>,
  trasformationLevels?: Maybe<Array<TransformationLevel>>,
  goodFeatureCertifications?: Maybe<Array<RefInputObject>>,
  pricePositionings?: Maybe<Array<PricePositioning>>,
  highlightReasons?: Maybe<Array<HighlightReasons>>,
  geoIndications?: Maybe<Array<RefInputObject>>,
  awards?: Maybe<Array<RefInputObject>>,
  awardFinalPositions?: Maybe<Array<FinalPosition>>,
  fairs?: Maybe<Array<RefInputObject>>,
  stateProvinces?: Maybe<Array<RefInputObject>>,
};

export enum GoodUnit {
  Grams = 'GRAMS',
  Milliliter = 'MILLILITER',
  Milligrams = 'MILLIGRAMS'
}

/** // TODO */
export enum GridSize {
  Col_1 = 'COL_1',
  Col_2 = 'COL_2',
  Col_3 = 'COL_3'
}

/** {"description":"Prodotti mostrati sui targetSite","canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"ACCOUNT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"ACCOUNT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"ACCOUNT"}} */
export type HighlightedGood = Node & {
   __typename?: 'HighlightedGood',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME (reference)","readonly":true} */
  Good: Good,
  /** {"readonly":true} */
  targetSite: TargetSite,
  /** {} */
  highlightReasons: Array<HighlightReasons>,
  /** {"description":"3 Reasons Why (1)","max":50} */
  claim1?: Maybe<Scalars['String']>,
  /** {"description":"3 Reasons Why (2)","max":50} */
  claim2?: Maybe<Scalars['String']>,
  /** {"description":"3 Reasons Why (3)","max":50} */
  claim3?: Maybe<Scalars['String']>,
};

export type HighlightedGoodCreateInput = {
  /** {"description":"@TODO DESCRIBE ME (reference)","readonly":true} */
  Good: RefInputObject,
  /** {"readonly":true} */
  targetSite: TargetSite,
  /** {} */
  highlightReasons?: Maybe<Array<HighlightReasons>>,
  /** {"description":"3 Reasons Why (1)","max":50} */
  claim1?: Maybe<Scalars['String']>,
  /** {"description":"3 Reasons Why (2)","max":50} */
  claim2?: Maybe<Scalars['String']>,
  /** {"description":"3 Reasons Why (3)","max":50} */
  claim3?: Maybe<Scalars['String']>,
};

export type HighlightedGoodPatchInput = {
  /** {} */
  highlightReasons?: Maybe<Array<HighlightReasons>>,
  /** {"description":"3 Reasons Why (1)","max":50} */
  claim1?: Maybe<Scalars['String']>,
  /** {"description":"3 Reasons Why (2)","max":50} */
  claim2?: Maybe<Scalars['String']>,
  /** {"description":"3 Reasons Why (3)","max":50} */
  claim3?: Maybe<Scalars['String']>,
};

/** Possible Good hightlight reasons */
export enum HighlightReasons {
  NewProduct = 'NEW_PRODUCT',
  RenovatedRecipe = 'RENOVATED_RECIPE',
  RenovatedPackaging = 'RENOVATED_PACKAGING',
  SpecialEdition = 'SPECIAL_EDITION',
  NewSizes = 'NEW_SIZES'
}

/** 
 * {"description":"Saranno circa 9-11 per le GeoIndication +altre?\nPer ora
 * possiamo usare un template offline da compilare sulla base di un csv e poi copiarlo nella description","canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}}
 */
export type HtmlTemplate = Node & {
   __typename?: 'HtmlTemplate',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"Contiene html/componenti per impostare/modificare i values (client-side-only)"} */
  form: Scalars['String'],
  /** {"description":"JSON oppure NULL => {}","multiline":true} */
  defaultValues?: Maybe<Scalars['String']>,
  /** {"description":"Javascript (form_values) -> values|errors","multiline":true} */
  validator?: Maybe<Scalars['String']>,
  /** {"description":"Template che dai values genera l'HTML (client-side [preview] e server-side [ufficiale])","multiline":true} */
  renderTemplate: Scalars['String'],
  /** {"description":"Template che dai values genera l'HTML (client-side [preview] e server-side [ufficiale])","multiline":true} */
  renderTemplateIta: Scalars['String'],
  /** {"description":"Template che dai values genera l'HTML (client-side [preview] e server-side [ufficiale])","multiline":true} */
  renderTemplateDem?: Maybe<Scalars['String']>,
  /** {"description":"Template che dai values genera l'HTML (client-side [preview] e server-side [ufficiale])","multiline":true} */
  renderTemplateDemIta?: Maybe<Scalars['String']>,
};

/** A connection to a list of items. */
export type HtmlTemplateConnection = {
   __typename?: 'HtmlTemplateConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<HtmlTemplateEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type HtmlTemplateCreateInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"Contiene html/componenti per impostare/modificare i values (client-side-only)"} */
  form: Scalars['String'],
  /** {"description":"JSON oppure NULL => {}","multiline":true} */
  defaultValues?: Maybe<Scalars['String']>,
  /** {"description":"Javascript (form_values) -> values|errors","multiline":true} */
  validator?: Maybe<Scalars['String']>,
  /** {"description":"Template che dai values genera l'HTML (client-side [preview] e server-side [ufficiale])","multiline":true} */
  renderTemplate: Scalars['String'],
  /** {"description":"Template che dai values genera l'HTML (client-side [preview] e server-side [ufficiale])","multiline":true} */
  renderTemplateIta: Scalars['String'],
  /** {"description":"Template che dai values genera l'HTML (client-side [preview] e server-side [ufficiale])","multiline":true} */
  renderTemplateDem?: Maybe<Scalars['String']>,
  /** {"description":"Template che dai values genera l'HTML (client-side [preview] e server-side [ufficiale])","multiline":true} */
  renderTemplateDemIta?: Maybe<Scalars['String']>,
};

/** An edge in a connection. */
export type HtmlTemplateEdge = {
   __typename?: 'HtmlTemplateEdge',
  /** The item at the end of the edge */
  node?: Maybe<HtmlTemplate>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type HtmlTemplatePatchInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {"description":"Contiene html/componenti per impostare/modificare i values (client-side-only)"} */
  form?: Maybe<Scalars['String']>,
  /** {"description":"JSON oppure NULL => {}","multiline":true} */
  defaultValues?: Maybe<Scalars['String']>,
  /** {"description":"Javascript (form_values) -> values|errors","multiline":true} */
  validator?: Maybe<Scalars['String']>,
  /** {"description":"Template che dai values genera l'HTML (client-side [preview] e server-side [ufficiale])","multiline":true} */
  renderTemplate?: Maybe<Scalars['String']>,
  /** {"description":"Template che dai values genera l'HTML (client-side [preview] e server-side [ufficiale])","multiline":true} */
  renderTemplateIta?: Maybe<Scalars['String']>,
  /** {"description":"Template che dai values genera l'HTML (client-side [preview] e server-side [ufficiale])","multiline":true} */
  renderTemplateDem?: Maybe<Scalars['String']>,
  /** {"description":"Template che dai values genera l'HTML (client-side [preview] e server-side [ufficiale])","multiline":true} */
  renderTemplateDemIta?: Maybe<Scalars['String']>,
};

/** {"description":"Indirizzo","canCreateRoles":false,"canPatchRoles":false,"canDeleteRoles":false} */
export type Indirizzo = Node & {
   __typename?: 'Indirizzo',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"Record ID","required":false,"readonly":true,"max":18} */
  Id?: Maybe<Scalars['String']>,
  /** {"description":"Deleted","required":false,"readonly":true} */
  IsDeleted?: Maybe<Scalars['Boolean']>,
  /** {"description":"Nome","required":false,"max":80} */
  Name?: Maybe<Scalars['String']>,
  /** {"description":"Via","max":150} */
  Via?: Maybe<Scalars['String']>,
  /** {"description":"Città","max":22} */
  Citt?: Maybe<Scalars['String']>,
  /** {"description":"Provincia","max":3} */
  Provincia?: Maybe<Scalars['String']>,
  /** {"description":"CAP","max":12} */
  CAP?: Maybe<Scalars['String']>,
  /** {"description":"Paese","max":100} */
  Paese?: Maybe<Scalars['String']>,
  /** {"description":"Note","max":100} */
  Note?: Maybe<Scalars['String']>,
  /** {"description":"Tipo"} */
  Tipo?: Maybe<Indirizzo__C_Tipo__C_Enum>,
  /** {"description":"Regione","max":20} */
  Regione?: Maybe<Scalars['String']>,
  /** {} */
  Abbonamenti: Array<Abbonamento>,
  /** {} */
  accountCompany?: Maybe<Company>,
  /** {} */
  accountCompanyGroup?: Maybe<CompanyGroup>,
  /** {} */
  accountService?: Maybe<Service>,
  /** {} */
  accountRetailer?: Maybe<Retailer>,
  /** {} */
  accountGeoIndication?: Maybe<GeoIndication>,
  /** {} */
  referenteUser?: Maybe<User>,
};

/** SF picklist */
export enum Indirizzo__C_Tipo__C_Enum {
  /** Sede legale */
  SedeLegale = 'Sede_legale',
  /** Sede operativa */
  SedeOperativa = 'Sede_operativa',
  /** Spedizioni */
  Spedizioni = 'Spedizioni',
  /** Filiale */
  Filiale = 'Filiale',
  /** Privato */
  Privato = 'Privato',
  /** Stabilimento di Produzione */
  StabilimentoDiProduzione = 'Stabilimento_di_Produzione',
  /** Dipartimento Logistico */
  DipartimentoLogistico = 'Dipartimento_Logistico'
}

export type Indirizzo_From_Abbonamento_Type = {
  /** {"description":"Deleted","required":false,"readonly":true} */
  IsDeleted?: Maybe<Scalars['Boolean']>,
  /** {"description":"Nome","required":false,"max":80} */
  Name?: Maybe<Scalars['String']>,
  /** {"description":"Via","max":150} */
  Via?: Maybe<Scalars['String']>,
  /** {"description":"Città","max":22} */
  Citt?: Maybe<Scalars['String']>,
  /** {"description":"Provincia","max":3} */
  Provincia?: Maybe<Scalars['String']>,
  /** {"description":"CAP","max":12} */
  CAP?: Maybe<Scalars['String']>,
  /** {"description":"Paese","max":100} */
  Paese?: Maybe<Scalars['String']>,
  /** {"description":"Note","max":100} */
  Note?: Maybe<Scalars['String']>,
  /** {"description":"Tipo"} */
  Tipo?: Maybe<Indirizzo__C_Tipo__C_Enum>,
  /** {"description":"Regione","max":20} */
  Regione?: Maybe<Scalars['String']>,
  /** {} */
  accountCompany?: Maybe<RefInputObject>,
  /** {} */
  accountCompanyGroup?: Maybe<RefInputObject>,
  /** {} */
  accountService?: Maybe<RefInputObject>,
  /** {} */
  accountRetailer?: Maybe<RefInputObject>,
  /** {} */
  accountGeoIndication?: Maybe<RefInputObject>,
  /** {} */
  referenteUser?: Maybe<RefInputObject>,
};

/** Client-side code load proper form based on this value */
export enum InputTemplate {
  NewsletterGeneric = 'NEWSLETTER_GENERIC'
}


/** User possible internal Capabilities */
export enum InternalCapability {
  UsersBase = 'USERS_BASE',
  UsersExtra = 'USERS_EXTRA',
  MarketingBase = 'MARKETING_BASE',
  MarketingExtra = 'MARKETING_EXTRA',
  EditorialBase = 'EDITORIAL_BASE',
  EditorialExtra = 'EDITORIAL_EXTRA',
  DirectoryBase = 'DIRECTORY_BASE',
  DirectoryExtra = 'DIRECTORY_EXTRA',
  AdminBase = 'ADMIN_BASE',
  AdminExtra = 'ADMIN_EXTRA'
}

/** Italian Indications */
export enum ItalianIndication {
  Docg = 'DOCG',
  Doc = 'DOC',
  Igt = 'IGT',
  BevandaSpiritosaIg = 'BEVANDA_SPIRITOSA_IG',
  Pat = 'PAT',
  PresidioSlowFood = 'PRESIDIO_SLOW_FOOD',
  ArcaSlowFood = 'ARCA_SLOW_FOOD'
}

/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"EDITORIAL_EXTRA"}} */
export type Magazine = Node & {
   __typename?: 'Magazine',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  MagazineCollection: MagazineCollection,
  /** {"dtPrecision":"FULL"} */
  publicationDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"@TODO"} */
  slug: Scalars['String'],
  /** {"description":"Es.: '01 Gennaio 2019', '07 Luglio-Agosto 2018'","max":30} */
  name: Scalars['String'],
  /** {} */
  title: Scalars['String'],
  /** {"description":"Per quanti giorni dalla pubblicazione questo Magazine restana \"new\"","max":2147483647,"min":-2147483647} */
  customHighlightDays?: Maybe<Scalars['Int']>,
  /** {"description":"1 per il primo numero mai pubblicato di questa rivista e così via","min":1,"max":2147483647} */
  issueNumber?: Maybe<Scalars['Int']>,
  /** {"description":"1 per il primo numero dell'anno e così via","min":1,"max":2147483647} */
  numOfTheYear?: Maybe<Scalars['Int']>,
  /** {"max":2147483647,"min":-2147483647} */
  numPages?: Maybe<Scalars['Int']>,
  /** {"description":"Se il PDF/sfogliabile ha il battente va messo a 2","max":2147483647,"min":-2147483647} */
  prefixPages?: Maybe<Scalars['Int']>,
  /** {} */
  customVisibilityType?: Maybe<VisibilityType>,
  /** {"description":"@TODO DESCRIBE ME"} */
  customVisibilityArgs?: Maybe<Scalars['String']>,
  /** {} */
  fullUrl?: Maybe<Scalars['URL']>,
  /** {} */
  previewUrl?: Maybe<Scalars['URL']>,
  /** {} */
  dropboxPath?: Maybe<Scalars['String']>,
  /** {"description":"Magazine PDF as Media","mediaType":"PDF_WEB"} */
  Media?: Maybe<Media>,
  /** {"description":"Magazine preview PDF as Media","mediaType":"PDF_WEB"} */
  previewMedia?: Maybe<Media>,
  /** {} */
  source: NewsSource,
  /** {"description":"@TODO DESCRIBE ME"} */
  externalSourceId?: Maybe<Scalars['Int']>,
  /** {"mediaType":"MAGAZINE_COVER"} */
  featuredImageMedia?: Maybe<Media>,
  /** {"description":"For imported posts"} */
  featuredImageUrl?: Maybe<Scalars['String']>,
  /** {"description":"JSON - for imported posts\n<size: string>: { width, height, source_url }"} */
  featuredImageSizes?: Maybe<Scalars['String']>,
  /** {"allowFormInlining":true} */
  magazineContents?: Maybe<Array<MagazineContent>>,
  /** {} */
  banners?: Maybe<Array<Banner>>,
};

/** {"description":"Come raggruppo i Magazine per la visibilità/edicola","canCreateRoles":{"INTERNAL":"EDITORIAL_EXTRA"},"canPatchRoles":{"INTERNAL":"EDITORIAL_EXTRA"},"canDeleteRoles":{"INTERNAL":"EDITORIAL_EXTRA"}} */
export type MagazineCollection = Node & {
   __typename?: 'MagazineCollection',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  BrandSf: Abbonamento__C_Rivista__C_Enum,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME (reference)","mediaType":"MAGAZINE_COVER"} */
  featuredImageMedia?: Maybe<Media>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Anno di inizio pubblicazione per calcolare a livello di
   * Magazine l'anno del numero in questione","max":2021,"min":1950}
 */
  startYear?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"Per quanti giorni dalla pubblicazione i Magazine di questa
   * collection restano \"new\"","max":2147483647,"min":-2147483647}
 */
  highlightDays?: Maybe<Scalars['Int']>,
  /** {} */
  visibilityType: VisibilityType,
  /** {"description":"@TODO DESCRIBE ME"} */
  visibilityArgs?: Maybe<Scalars['String']>,
  /** {"description":"Di solito comanda questo","max":2147483647,"min":-2147483647} */
  priority: Scalars['Int'],
};

/** A connection to a list of items. */
export type MagazineCollectionConnection = {
   __typename?: 'MagazineCollectionConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MagazineCollectionEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type MagazineCollectionCreateInput = {
  /** {} */
  BrandSf: Abbonamento__C_Rivista__C_Enum,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME (reference)","mediaType":"MAGAZINE_COVER"} */
  featuredImageMedia?: Maybe<RefInputObject>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Anno di inizio pubblicazione per calcolare a livello di
   * Magazine l'anno del numero in questione","max":2021,"min":1950}
 */
  startYear?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"Per quanti giorni dalla pubblicazione i Magazine di questa
   * collection restano \"new\"","max":2147483647,"min":-2147483647}
 */
  highlightDays?: Maybe<Scalars['Int']>,
  /** {} */
  visibilityType: VisibilityType,
  /** {"description":"@TODO DESCRIBE ME"} */
  visibilityArgs?: Maybe<Scalars['String']>,
  /** {"description":"Di solito comanda questo","max":2147483647,"min":-2147483647} */
  priority: Scalars['Int'],
};

/** An edge in a connection. */
export type MagazineCollectionEdge = {
   __typename?: 'MagazineCollectionEdge',
  /** The item at the end of the edge */
  node?: Maybe<MagazineCollection>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type MagazineCollectionFilterInput = {
  BrandSf?: Maybe<Abbonamento__C_Rivista__C_Enum>,
  /** Contained in BrandSf or name */
  name?: Maybe<Scalars['String']>,
};

export type MagazineCollectionPatchInput = {
  /** {} */
  BrandSf?: Maybe<Abbonamento__C_Rivista__C_Enum>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME (reference)","mediaType":"MAGAZINE_COVER"} */
  featuredImageMedia?: Maybe<RefInputObject>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Anno di inizio pubblicazione per calcolare a livello di
   * Magazine l'anno del numero in questione","max":2021,"min":1950}
 */
  startYear?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"Per quanti giorni dalla pubblicazione i Magazine di questa
   * collection restano \"new\"","max":2147483647,"min":-2147483647}
 */
  highlightDays?: Maybe<Scalars['Int']>,
  /** {} */
  visibilityType?: Maybe<VisibilityType>,
  /** {"description":"@TODO DESCRIBE ME"} */
  visibilityArgs?: Maybe<Scalars['String']>,
  /** {"description":"Di solito comanda questo","max":2147483647,"min":-2147483647} */
  priority?: Maybe<Scalars['Int']>,
};

/** A connection to a list of items. */
export type MagazineConnection = {
   __typename?: 'MagazineConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MagazineEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

/** {"description":"Magazine table of contents item.","canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"EDITORIAL_BASE"}} */
export type MagazineContent = Node & {
   __typename?: 'MagazineContent',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  Magazine?: Maybe<Magazine>,
  /** {} */
  name: Scalars['String'],
  /** {"max":2147483647,"min":-2147483647} */
  pageNumber?: Maybe<Scalars['Int']>,
};

export type MagazineContent_From_Magazine_Type = {
  /** {} */
  name: Scalars['String'],
  /** {"max":2147483647,"min":-2147483647} */
  pageNumber?: Maybe<Scalars['Int']>,
};

export type MagazineContentCreateInput = {
  /** {} */
  Magazine?: Maybe<RefInputObject>,
  /** {} */
  name: Scalars['String'],
  /** {"max":2147483647,"min":-2147483647} */
  pageNumber?: Maybe<Scalars['Int']>,
};

export type MagazineContentPatchInput = {
  /** {} */
  Magazine?: Maybe<RefInputObject>,
  /** {} */
  name?: Maybe<Scalars['String']>,
  /** {"max":2147483647,"min":-2147483647} */
  pageNumber?: Maybe<Scalars['Int']>,
};

export type MagazineCreateInput = {
  /** {} */
  MagazineCollection: RefInputObject,
  /** {"dtPrecision":"FULL"} */
  publicationDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"Es.: '01 Gennaio 2019', '07 Luglio-Agosto 2018'","max":30} */
  name: Scalars['String'],
  /** {} */
  title: Scalars['String'],
  /** {"description":"Per quanti giorni dalla pubblicazione questo Magazine restana \"new\"","max":2147483647,"min":-2147483647} */
  customHighlightDays?: Maybe<Scalars['Int']>,
  /** {"description":"1 per il primo numero mai pubblicato di questa rivista e così via","min":1,"max":2147483647} */
  issueNumber?: Maybe<Scalars['Int']>,
  /** {"description":"1 per il primo numero dell'anno e così via","min":1,"max":2147483647} */
  numOfTheYear?: Maybe<Scalars['Int']>,
  /** {"max":2147483647,"min":-2147483647} */
  numPages?: Maybe<Scalars['Int']>,
  /** {"description":"Se il PDF/sfogliabile ha il battente va messo a 2","max":2147483647,"min":-2147483647} */
  prefixPages?: Maybe<Scalars['Int']>,
  /** {} */
  customVisibilityType?: Maybe<VisibilityType>,
  /** {"description":"@TODO DESCRIBE ME"} */
  customVisibilityArgs?: Maybe<Scalars['String']>,
  /** {} */
  fullUrl?: Maybe<Scalars['URL']>,
  /** {} */
  previewUrl?: Maybe<Scalars['URL']>,
  /** {} */
  dropboxPath?: Maybe<Scalars['String']>,
  /** {"description":"Magazine PDF as Media","mediaType":"PDF_WEB"} */
  Media?: Maybe<RefInputObject>,
  /** {"description":"Magazine preview PDF as Media","mediaType":"PDF_WEB"} */
  previewMedia?: Maybe<RefInputObject>,
  /** {"mediaType":"MAGAZINE_COVER"} */
  featuredImageMedia?: Maybe<RefInputObject>,
  /** {"allowFormInlining":true} */
  magazineContents?: Maybe<Array<RefInputObject>>,
  /** {"allowFormInlining":true} */
  magazineContents__inline?: Maybe<Array<MagazineContent_From_Magazine_Type>>,
};

/** An edge in a connection. */
export type MagazineEdge = {
   __typename?: 'MagazineEdge',
  /** The item at the end of the edge */
  node?: Maybe<Magazine>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type MagazineFilterInput = {
  BrandSf?: Maybe<Abbonamento__C_Rivista__C_Enum>,
  MagazineCollection?: Maybe<Array<RefInputObject>>,
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  /** Contained in title */
  title?: Maybe<Scalars['String']>,
  isPublished?: Maybe<Scalars['Boolean']>,
  publishedAfter?: Maybe<Scalars['DateTime']>,
  publishedBefore?: Maybe<Scalars['DateTime']>,
};

export type MagazinePatchInput = {
  /** {} */
  MagazineCollection?: Maybe<RefInputObject>,
  /** {"dtPrecision":"FULL"} */
  publicationDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"Es.: '01 Gennaio 2019', '07 Luglio-Agosto 2018'","max":30} */
  name?: Maybe<Scalars['String']>,
  /** {} */
  title?: Maybe<Scalars['String']>,
  /** {"description":"Per quanti giorni dalla pubblicazione questo Magazine restana \"new\"","max":2147483647,"min":-2147483647} */
  customHighlightDays?: Maybe<Scalars['Int']>,
  /** {"description":"1 per il primo numero mai pubblicato di questa rivista e così via","min":1,"max":2147483647} */
  issueNumber?: Maybe<Scalars['Int']>,
  /** {"description":"1 per il primo numero dell'anno e così via","min":1,"max":2147483647} */
  numOfTheYear?: Maybe<Scalars['Int']>,
  /** {"max":2147483647,"min":-2147483647} */
  numPages?: Maybe<Scalars['Int']>,
  /** {"description":"Se il PDF/sfogliabile ha il battente va messo a 2","max":2147483647,"min":-2147483647} */
  prefixPages?: Maybe<Scalars['Int']>,
  /** {} */
  customVisibilityType?: Maybe<VisibilityType>,
  /** {"description":"@TODO DESCRIBE ME"} */
  customVisibilityArgs?: Maybe<Scalars['String']>,
  /** {} */
  fullUrl?: Maybe<Scalars['URL']>,
  /** {} */
  previewUrl?: Maybe<Scalars['URL']>,
  /** {} */
  dropboxPath?: Maybe<Scalars['String']>,
  /** {"description":"Magazine PDF as Media","mediaType":"PDF_WEB"} */
  Media?: Maybe<RefInputObject>,
  /** {"description":"Magazine preview PDF as Media","mediaType":"PDF_WEB"} */
  previewMedia?: Maybe<RefInputObject>,
  /** {"mediaType":"MAGAZINE_COVER"} */
  featuredImageMedia?: Maybe<RefInputObject>,
  /** {"allowFormInlining":true} */
  magazineContents?: Maybe<Array<RefInputObject>>,
  /** {"allowFormInlining":true} */
  magazineContents__inline?: Maybe<Array<MagazineContent_From_Magazine_Type>>,
};

/** {"description":"User-Company linking table + capabilities. Default read-only capability.","canCreateRoles":{"INTERNAL":"USERS_EXTRA"},"canPatchRoles":{"INTERNAL":"USERS_EXTRA","PRODUCER":"MANAGE"},"canDeleteRoles":{"INTERNAL":"USERS_EXTRA","PRODUCER":"MANAGE"}} */
export type ManagedByUser = Node & {
   __typename?: 'ManagedByUser',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  User: User,
  /** {} */
  capabilities: Array<Capability>,
  /** {} */
  Company?: Maybe<Company>,
  /** {} */
  CompanyGroup?: Maybe<CompanyGroup>,
  /** {} */
  Service?: Maybe<Service>,
  /** {} */
  Retailer?: Maybe<Retailer>,
  /** {} */
  GeoIndication?: Maybe<GeoIndication>,
};

export type ManagedByUserCreateInput = {
  /** {} */
  User: RefInputObject,
  /** {} */
  capabilities?: Maybe<Array<Capability>>,
  /** {} */
  Company?: Maybe<RefInputObject>,
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>,
  /** {} */
  Service?: Maybe<RefInputObject>,
  /** {} */
  Retailer?: Maybe<RefInputObject>,
  /** {} */
  GeoIndication?: Maybe<RefInputObject>,
};

export type ManagedByUserPatchInput = {
  /** {} */
  User?: Maybe<RefInputObject>,
  /** {} */
  capabilities?: Maybe<Array<Capability>>,
  /** {} */
  Company?: Maybe<RefInputObject>,
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>,
  /** {} */
  Service?: Maybe<RefInputObject>,
  /** {} */
  Retailer?: Maybe<RefInputObject>,
  /** {} */
  GeoIndication?: Maybe<RefInputObject>,
};

/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":true,"PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":true,"PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":true,"PRODUCER":"EDIT"}} */
export type Media = Node & {
   __typename?: 'Media',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  type: MediaType,
  /** {} */
  Company?: Maybe<Company>,
  /** {"description":"caption or origFileName"} */
  name: Scalars['String'],
  /** {"description":"Didascalia"} */
  caption?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  origFileName: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  timestamp: Scalars['DateTime'],
  /** {"description":"URL to original media"} */
  origUrl: Scalars['URL'],
  /** {"description":"URL to LARGE thumb"} */
  largeThumbUrl?: Maybe<Scalars['URL']>,
  /** {"description":"URL to MEDIUM thumb"} */
  mediumThumbUrl?: Maybe<Scalars['URL']>,
  /** {"description":"URL to SMALL thumb"} */
  smallThumbUrl?: Maybe<Scalars['URL']>,
  /** {"description":"URL to VECTOR thumb"} */
  vecThumbUrl?: Maybe<Scalars['URL']>,
};

/** A connection to a list of items. */
export type MediaConnection = {
   __typename?: 'MediaConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MediaEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type MediaCreateInput = {
  type: MediaType,
  /** @TODO DESCRIBE ME */
  Company?: Maybe<RefInputObject>,
  /** @TODO DESCRIBE ME */
  caption?: Maybe<Scalars['String']>,
  file?: Maybe<Scalars['Upload']>,
};

/** An edge in a connection. */
export type MediaEdge = {
   __typename?: 'MediaEdge',
  /** The item at the end of the edge */
  node?: Maybe<Media>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type MediaFilterInput = {
  /** Contained in name o origFileName */
  name?: Maybe<Scalars['String']>,
  type?: Maybe<MediaType>,
  Company?: Maybe<RefInputObject>,
};

export enum MediaOrderBy {
  Name = 'name',
  Timestamp = 'timestamp'
}

export type MediaPatchInput = {
  /** {"description":"Didascalia"} */
  caption?: Maybe<Scalars['String']>,
};

/** Supported Media types */
export enum MediaType {
  Logo = 'LOGO',
  GoodImage = 'GOOD_IMAGE',
  Icon = 'ICON',
  PdfWeb = 'PDF_WEB',
  PdfGeneric = 'PDF_GENERIC',
  BannerFull = 'BANNER_FULL',
  BannerHalf = 'BANNER_HALF',
  BannerLeaderboard = 'BANNER_LEADERBOARD',
  BannerPopup = 'BANNER_POPUP',
  News = 'NEWS',
  MagazineCover = 'MAGAZINE_COVER',
  LogoPrint = 'LOGO_PRINT',
  GoodPrintImage = 'GOOD_PRINT_IMAGE',
  GalleryGeneric = 'GALLERY_GENERIC',
  MailImage = 'MAIL_IMAGE'
}

export type Mutation = {
   __typename?: 'Mutation',
  nodeDelete?: Maybe<Scalars['Int']>,
  bannerViewCreate?: Maybe<BannerView>,
  mediaCreate?: Maybe<Media>,
  mediaPatch?: Maybe<Media>,
  userCreate?: Maybe<User>,
  userPatch?: Maybe<User>,
  userSetStatus?: Maybe<User>,
  userChangePassword?: Maybe<Scalars['Int']>,
  managedByUserCreate?: Maybe<ManagedByUser>,
  managedByUserPatch?: Maybe<ManagedByUser>,
  goodClassCreate?: Maybe<GoodClass>,
  goodClassPatch?: Maybe<GoodClass>,
  goodFeatureCertificationCreate?: Maybe<GoodFeatureCertification>,
  goodFeatureCertificationPatch?: Maybe<GoodFeatureCertification>,
  companyCertificationCreate?: Maybe<CompanyCertification>,
  companyCertificationPatch?: Maybe<CompanyCertification>,
  newsCitationCreate?: Maybe<NewsCitation>,
  newsCitationPatch?: Maybe<NewsCitation>,
  userObjectSubscriptionCreate?: Maybe<UserObjectSubscription>,
  userObjectSubscriptionPatch?: Maybe<UserObjectSubscription>,
  userNoteCreate?: Maybe<UserNote>,
  userNotePatch?: Maybe<UserNote>,
  companyCreate?: Maybe<Company>,
  companyPatch?: Maybe<Company>,
  companyYearCreate?: Maybe<CompanyYear>,
  companyYearPatch?: Maybe<CompanyYear>,
  companyLevelCreate?: Maybe<CompanyLevel>,
  companyLevelPatch?: Maybe<CompanyLevel>,
  brandCreate?: Maybe<Brand>,
  brandPatch?: Maybe<Brand>,
  branchCreate?: Maybe<Branch>,
  branchPatch?: Maybe<Branch>,
  goodCreate?: Maybe<Good>,
  goodPatch?: Maybe<Good>,
  goodFormatCreate?: Maybe<GoodFormat>,
  goodFormatPatch?: Maybe<GoodFormat>,
  highlightedGoodCreate?: Maybe<HighlightedGood>,
  highlightedGoodPatch?: Maybe<HighlightedGood>,
  awardCreate?: Maybe<Award>,
  awardPatch?: Maybe<Award>,
  awardSetStatus?: Maybe<Award>,
  awardCategoryCreate?: Maybe<AwardCategory>,
  awardCategoryPatch?: Maybe<AwardCategory>,
  awardResultCreate?: Maybe<AwardResult>,
  awardResultPatch?: Maybe<AwardResult>,
  awardInvitedUserCreate?: Maybe<AwardInvitedUser>,
  awardInvitedUserPatch?: Maybe<AwardInvitedUser>,
  awardInvitedCompanyCreate?: Maybe<AwardInvitedCompany>,
  awardInvitedCompanyPatch?: Maybe<AwardInvitedCompany>,
  candidacyCreate?: Maybe<Candidacy>,
  candidacyPatch?: Maybe<Candidacy>,
  candidacyRatingCreate?: Maybe<CandidacyRating>,
  candidacyRatingPatch?: Maybe<CandidacyRating>,
  companyGroupCreate?: Maybe<CompanyGroup>,
  companyGroupPatch?: Maybe<CompanyGroup>,
  serviceCreate?: Maybe<Service>,
  servicePatch?: Maybe<Service>,
  retailerCreate?: Maybe<Retailer>,
  retailerPatch?: Maybe<Retailer>,
  teamCreate?: Maybe<Team>,
  teamPatch?: Maybe<Team>,
  euCategoryCreate?: Maybe<EuCategory>,
  euCategoryPatch?: Maybe<EuCategory>,
  geoIndicationCreate?: Maybe<GeoIndication>,
  geoIndicationPatch?: Maybe<GeoIndication>,
  geoIndicationSetStatus?: Maybe<GeoIndication>,
  fairCreate?: Maybe<Fair>,
  fairPatch?: Maybe<Fair>,
  fairParticipationCreate?: Maybe<FairParticipation>,
  fairParticipationPatch?: Maybe<FairParticipation>,
  editorialSectionCreate?: Maybe<EditorialSection>,
  editorialSectionPatch?: Maybe<EditorialSection>,
  editorialTagCreate?: Maybe<EditorialTag>,
  editorialTagPatch?: Maybe<EditorialTag>,
  htmlTemplateCreate?: Maybe<HtmlTemplate>,
  htmlTemplatePatch?: Maybe<HtmlTemplate>,
  newsFormatCreate?: Maybe<NewsFormat>,
  newsFormatPatch?: Maybe<NewsFormat>,
  newsSetStatus?: Maybe<News>,
  bannerCreate?: Maybe<Banner>,
  bannerPatch?: Maybe<Banner>,
  bannerSetStatus?: Maybe<Banner>,
  widgetCreate?: Maybe<Widget>,
  widgetPatch?: Maybe<Widget>,
  abbonamentoCreate?: Maybe<Abbonamento>,
  abbonamentoPatch?: Maybe<Abbonamento>,
  magazineCreate?: Maybe<Magazine>,
  magazinePatch?: Maybe<Magazine>,
  magazineCollectionCreate?: Maybe<MagazineCollection>,
  magazineCollectionPatch?: Maybe<MagazineCollection>,
  magazineContentCreate?: Maybe<MagazineContent>,
  magazineContentPatch?: Maybe<MagazineContent>,
  newsletterCreate?: Maybe<Newsletter>,
  newsletterPatch?: Maybe<Newsletter>,
  newsletterIssueCreate?: Maybe<NewsletterIssue>,
  newsletterIssuePatch?: Maybe<NewsletterIssue>,
  newsletterIssueSetStatus?: Maybe<NewsletterIssue>,
  newsletterIssueHasNewsCreate?: Maybe<NewsletterIssueHasNews>,
  newsletterIssueHasNewsPatch?: Maybe<NewsletterIssueHasNews>,
  emailDomainCreate?: Maybe<EmailDomain>,
  emailDomainPatch?: Maybe<EmailDomain>,
  toSCreate?: Maybe<ToS>,
  toSPatch?: Maybe<ToS>,
  questionSetCreate?: Maybe<QuestionSet>,
  answerSetPatch?: Maybe<AnswerSet>,
  frequentSearchCreate?: Maybe<FrequentSearch>,
  frequentSearchPatch?: Maybe<FrequentSearch>,
  frequentSearchSetStatus?: Maybe<FrequentSearch>,
  frequentSearchAddRemoveItems?: Maybe<Scalars['Int']>,
  fairVisitCreate?: Maybe<FairVisit>,
  fairVisitPatch?: Maybe<FairVisit>,
  fairVisitParticipationCreate?: Maybe<FairVisitParticipation>,
  fairVisitParticipationPatch?: Maybe<FairVisitParticipation>,
  userAchievementCreate?: Maybe<UserAchievement>,
  sampleRequestCreate?: Maybe<SampleRequest>,
  sampleRequestPatch?: Maybe<SampleRequest>,
  sampleRequestSetStatus?: Maybe<SampleRequest>,
  companyBadgeCreate?: Maybe<CompanyBadge>,
  companyBadgePatch?: Maybe<CompanyBadge>,
  distributionChannelCreate?: Maybe<DistributionChannel>,
  distributionChannelPatch?: Maybe<DistributionChannel>,
  typeformCreate?: Maybe<Typeform>,
  typeformPatch?: Maybe<Typeform>,
  quotePay?: Maybe<Quote>,
  quoteLineItemCreate?: Maybe<QuoteLineItem>,
  quoteLineItemPatch?: Maybe<QuoteLineItem>,
  prospectPatch?: Maybe<Prospect>,
};


export type MutationNodeDeleteArgs = {
  id: Scalars['ID']
};


export type MutationBannerViewCreateArgs = {
  values: BannerViewCreateInput
};


export type MutationMediaCreateArgs = {
  values: MediaCreateInput
};


export type MutationMediaPatchArgs = {
  id: Scalars['ID'],
  changes: MediaPatchInput
};


export type MutationUserCreateArgs = {
  values: UserCreateInput
};


export type MutationUserPatchArgs = {
  id: Scalars['ID'],
  changes: UserPatchInput
};


export type MutationUserSetStatusArgs = {
  id: Scalars['ID'],
  newStatus: UserStatus
};


export type MutationUserChangePasswordArgs = {
  id: Scalars['ID'],
  newPassword: Scalars['String']
};


export type MutationManagedByUserCreateArgs = {
  values: ManagedByUserCreateInput
};


export type MutationManagedByUserPatchArgs = {
  id: Scalars['ID'],
  changes: ManagedByUserPatchInput
};


export type MutationGoodClassCreateArgs = {
  values: GoodClassCreateInput
};


export type MutationGoodClassPatchArgs = {
  id: Scalars['ID'],
  changes: GoodClassPatchInput
};


export type MutationGoodFeatureCertificationCreateArgs = {
  values: GoodFeatureCertificationCreateInput
};


export type MutationGoodFeatureCertificationPatchArgs = {
  id: Scalars['ID'],
  changes: GoodFeatureCertificationPatchInput
};


export type MutationCompanyCertificationCreateArgs = {
  values: CompanyCertificationCreateInput
};


export type MutationCompanyCertificationPatchArgs = {
  id: Scalars['ID'],
  changes: CompanyCertificationPatchInput
};


export type MutationNewsCitationCreateArgs = {
  values: NewsCitationCreateInput
};


export type MutationNewsCitationPatchArgs = {
  id: Scalars['ID'],
  changes: NewsCitationPatchInput
};


export type MutationUserObjectSubscriptionCreateArgs = {
  values: UserObjectSubscriptionCreateInput
};


export type MutationUserObjectSubscriptionPatchArgs = {
  id: Scalars['ID'],
  changes: UserObjectSubscriptionPatchInput
};


export type MutationUserNoteCreateArgs = {
  values: UserNoteCreateInput
};


export type MutationUserNotePatchArgs = {
  id: Scalars['ID'],
  changes: UserNotePatchInput
};


export type MutationCompanyCreateArgs = {
  values: CompanyCreateInput
};


export type MutationCompanyPatchArgs = {
  id: Scalars['ID'],
  changes: CompanyPatchInput
};


export type MutationCompanyYearCreateArgs = {
  values: CompanyYearCreateInput
};


export type MutationCompanyYearPatchArgs = {
  id: Scalars['ID'],
  changes: CompanyYearPatchInput
};


export type MutationCompanyLevelCreateArgs = {
  values: CompanyLevelCreateInput
};


export type MutationCompanyLevelPatchArgs = {
  id: Scalars['ID'],
  changes: CompanyLevelPatchInput
};


export type MutationBrandCreateArgs = {
  values: BrandCreateInput
};


export type MutationBrandPatchArgs = {
  id: Scalars['ID'],
  changes: BrandPatchInput
};


export type MutationBranchCreateArgs = {
  values: BranchCreateInput
};


export type MutationBranchPatchArgs = {
  id: Scalars['ID'],
  changes: BranchPatchInput
};


export type MutationGoodCreateArgs = {
  values: GoodCreateInput
};


export type MutationGoodPatchArgs = {
  id: Scalars['ID'],
  changes: GoodPatchInput
};


export type MutationGoodFormatCreateArgs = {
  values: GoodFormatCreateInput
};


export type MutationGoodFormatPatchArgs = {
  id: Scalars['ID'],
  changes: GoodFormatPatchInput
};


export type MutationHighlightedGoodCreateArgs = {
  values: HighlightedGoodCreateInput
};


export type MutationHighlightedGoodPatchArgs = {
  id: Scalars['ID'],
  changes: HighlightedGoodPatchInput
};


export type MutationAwardCreateArgs = {
  values: AwardCreateInput
};


export type MutationAwardPatchArgs = {
  id: Scalars['ID'],
  changes: AwardPatchInput
};


export type MutationAwardSetStatusArgs = {
  id: Scalars['ID'],
  newStatus: AwardStatus
};


export type MutationAwardCategoryCreateArgs = {
  values: AwardCategoryCreateInput
};


export type MutationAwardCategoryPatchArgs = {
  id: Scalars['ID'],
  changes: AwardCategoryPatchInput
};


export type MutationAwardResultCreateArgs = {
  values: AwardResultCreateInput
};


export type MutationAwardResultPatchArgs = {
  id: Scalars['ID'],
  changes: AwardResultPatchInput
};


export type MutationAwardInvitedUserCreateArgs = {
  values: AwardInvitedUserCreateInput
};


export type MutationAwardInvitedUserPatchArgs = {
  id: Scalars['ID'],
  changes: AwardInvitedUserPatchInput
};


export type MutationAwardInvitedCompanyCreateArgs = {
  values: AwardInvitedCompanyCreateInput
};


export type MutationAwardInvitedCompanyPatchArgs = {
  id: Scalars['ID'],
  changes: AwardInvitedCompanyPatchInput
};


export type MutationCandidacyCreateArgs = {
  values: CandidacyCreateInput
};


export type MutationCandidacyPatchArgs = {
  id: Scalars['ID'],
  changes: CandidacyPatchInput
};


export type MutationCandidacyRatingCreateArgs = {
  values: CandidacyRatingCreateInput
};


export type MutationCandidacyRatingPatchArgs = {
  id: Scalars['ID'],
  changes: CandidacyRatingPatchInput
};


export type MutationCompanyGroupCreateArgs = {
  values: CompanyGroupCreateInput
};


export type MutationCompanyGroupPatchArgs = {
  id: Scalars['ID'],
  changes: CompanyGroupPatchInput
};


export type MutationServiceCreateArgs = {
  values: ServiceCreateInput
};


export type MutationServicePatchArgs = {
  id: Scalars['ID'],
  changes: ServicePatchInput
};


export type MutationRetailerCreateArgs = {
  values: RetailerCreateInput
};


export type MutationRetailerPatchArgs = {
  id: Scalars['ID'],
  changes: RetailerPatchInput
};


export type MutationTeamCreateArgs = {
  values: TeamCreateInput
};


export type MutationTeamPatchArgs = {
  id: Scalars['ID'],
  changes: TeamPatchInput
};


export type MutationEuCategoryCreateArgs = {
  values: EuCategoryCreateInput
};


export type MutationEuCategoryPatchArgs = {
  id: Scalars['ID'],
  changes: EuCategoryPatchInput
};


export type MutationGeoIndicationCreateArgs = {
  values: GeoIndicationCreateInput
};


export type MutationGeoIndicationPatchArgs = {
  id: Scalars['ID'],
  changes: GeoIndicationPatchInput
};


export type MutationGeoIndicationSetStatusArgs = {
  id: Scalars['ID'],
  newStatus: NewsStatus
};


export type MutationFairCreateArgs = {
  values: FairCreateInput
};


export type MutationFairPatchArgs = {
  id: Scalars['ID'],
  changes: FairPatchInput
};


export type MutationFairParticipationCreateArgs = {
  values: FairParticipationCreateInput
};


export type MutationFairParticipationPatchArgs = {
  id: Scalars['ID'],
  changes: FairParticipationPatchInput
};


export type MutationEditorialSectionCreateArgs = {
  values: EditorialSectionCreateInput
};


export type MutationEditorialSectionPatchArgs = {
  id: Scalars['ID'],
  changes: EditorialSectionPatchInput
};


export type MutationEditorialTagCreateArgs = {
  values: EditorialTagCreateInput
};


export type MutationEditorialTagPatchArgs = {
  id: Scalars['ID'],
  changes: EditorialTagPatchInput
};


export type MutationHtmlTemplateCreateArgs = {
  values: HtmlTemplateCreateInput
};


export type MutationHtmlTemplatePatchArgs = {
  id: Scalars['ID'],
  changes: HtmlTemplatePatchInput
};


export type MutationNewsFormatCreateArgs = {
  values: NewsFormatCreateInput
};


export type MutationNewsFormatPatchArgs = {
  id: Scalars['ID'],
  changes: NewsFormatPatchInput
};


export type MutationNewsSetStatusArgs = {
  id: Scalars['ID'],
  newStatus: NewsStatus
};


export type MutationBannerCreateArgs = {
  values: BannerCreateInput
};


export type MutationBannerPatchArgs = {
  id: Scalars['ID'],
  changes: BannerPatchInput
};


export type MutationBannerSetStatusArgs = {
  id: Scalars['ID'],
  newStatus: BooleanEnum
};


export type MutationWidgetCreateArgs = {
  values: WidgetCreateInput
};


export type MutationWidgetPatchArgs = {
  id: Scalars['ID'],
  changes: WidgetPatchInput
};


export type MutationAbbonamentoCreateArgs = {
  values: AbbonamentoCreateInput
};


export type MutationAbbonamentoPatchArgs = {
  id: Scalars['ID'],
  changes: AbbonamentoPatchInput
};


export type MutationMagazineCreateArgs = {
  values: MagazineCreateInput
};


export type MutationMagazinePatchArgs = {
  id: Scalars['ID'],
  changes: MagazinePatchInput
};


export type MutationMagazineCollectionCreateArgs = {
  values: MagazineCollectionCreateInput
};


export type MutationMagazineCollectionPatchArgs = {
  id: Scalars['ID'],
  changes: MagazineCollectionPatchInput
};


export type MutationMagazineContentCreateArgs = {
  values: MagazineContentCreateInput
};


export type MutationMagazineContentPatchArgs = {
  id: Scalars['ID'],
  changes: MagazineContentPatchInput
};


export type MutationNewsletterCreateArgs = {
  values: NewsletterCreateInput
};


export type MutationNewsletterPatchArgs = {
  id: Scalars['ID'],
  changes: NewsletterPatchInput
};


export type MutationNewsletterIssueCreateArgs = {
  values: NewsletterIssueCreateInput
};


export type MutationNewsletterIssuePatchArgs = {
  id: Scalars['ID'],
  changes: NewsletterIssuePatchInput
};


export type MutationNewsletterIssueSetStatusArgs = {
  id: Scalars['ID'],
  newStatus: NewsStatus
};


export type MutationNewsletterIssueHasNewsCreateArgs = {
  values: NewsletterIssueHasNewsCreateInput
};


export type MutationNewsletterIssueHasNewsPatchArgs = {
  id: Scalars['ID'],
  changes: NewsletterIssueHasNewsPatchInput
};


export type MutationEmailDomainCreateArgs = {
  values: EmailDomainCreateInput
};


export type MutationEmailDomainPatchArgs = {
  id: Scalars['ID'],
  changes: EmailDomainPatchInput
};


export type MutationToSCreateArgs = {
  values: ToSCreateInput
};


export type MutationToSPatchArgs = {
  id: Scalars['ID'],
  changes: ToSPatchInput
};


export type MutationQuestionSetCreateArgs = {
  values: QuestionSetCreateInput
};


export type MutationAnswerSetPatchArgs = {
  id: Scalars['ID'],
  changes: AnswerSetPatchInput
};


export type MutationFrequentSearchCreateArgs = {
  values: FrequentSearchCreateInput
};


export type MutationFrequentSearchPatchArgs = {
  id: Scalars['ID'],
  changes: FrequentSearchPatchInput
};


export type MutationFrequentSearchSetStatusArgs = {
  id: Scalars['ID'],
  newStatus: FrequentSearchStatus
};


export type MutationFrequentSearchAddRemoveItemsArgs = {
  items: Array<RefInputObject>,
  addToFrequentSearches?: Maybe<Array<RefInputObject>>,
  removeFromFrequentSearches?: Maybe<Array<RefInputObject>>
};


export type MutationFairVisitCreateArgs = {
  values: FairVisitCreateInput
};


export type MutationFairVisitPatchArgs = {
  id: Scalars['ID'],
  changes: FairVisitPatchInput
};


export type MutationFairVisitParticipationCreateArgs = {
  values: FairVisitParticipationCreateInput
};


export type MutationFairVisitParticipationPatchArgs = {
  id: Scalars['ID'],
  changes: FairVisitParticipationPatchInput
};


export type MutationUserAchievementCreateArgs = {
  values: UserAchievementCreateInput
};


export type MutationSampleRequestCreateArgs = {
  values: SampleRequestCreateInput
};


export type MutationSampleRequestPatchArgs = {
  id: Scalars['ID'],
  changes: SampleRequestPatchInput
};


export type MutationSampleRequestSetStatusArgs = {
  id: Scalars['ID'],
  newStatus: SampleRequestStatus
};


export type MutationCompanyBadgeCreateArgs = {
  values: CompanyBadgeCreateInput
};


export type MutationCompanyBadgePatchArgs = {
  id: Scalars['ID'],
  changes: CompanyBadgePatchInput
};


export type MutationDistributionChannelCreateArgs = {
  values: DistributionChannelCreateInput
};


export type MutationDistributionChannelPatchArgs = {
  id: Scalars['ID'],
  changes: DistributionChannelPatchInput
};


export type MutationTypeformCreateArgs = {
  values: TypeformCreateInput
};


export type MutationTypeformPatchArgs = {
  id: Scalars['ID'],
  changes: TypeformPatchInput
};


export type MutationQuotePayArgs = {
  id: Scalars['ID'],
  stripeCardToken: Scalars['String'],
  billingEmail: Scalars['Email'],
  billingVat: Scalars['Email'],
  recurring: Scalars['Boolean']
};


export type MutationQuoteLineItemCreateArgs = {
  values: QuoteLineItemCreateInput
};


export type MutationQuoteLineItemPatchArgs = {
  id: Scalars['ID'],
  changes: QuoteLineItemPatchInput
};


export type MutationProspectPatchArgs = {
  email?: Maybe<Scalars['Email']>,
  piListSubscriptionChanges: Array<PiListSubscriptionChange>
};

/** {"description":"@TODO DESCRIBE ME","canDeleteRoles":{"INTERNAL":"EDITORIAL_EXTRA"},"canSetStatusRoles":{"status":{"STUB":{"INTERNAL":"EDITORIAL_BASE"},"DRAFT":{"INTERNAL":"EDITORIAL_BASE"},"WAITING_PUBLICATION":{"INTERNAL":"EDITORIAL_BASE"},"PUBLISHED":{"INTERNAL":"EDITORIAL_EXTRA"},"HIDDEN":{"INTERNAL":"EDITORIAL_BASE"},"DELETED":{"INTERNAL":"EDITORIAL_BASE"}}},"canPatchNeedCurrentStatusAccess":"status"} */
export type News = Node & {
   __typename?: 'News',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"readonly":true} */
  NewsFormat: NewsFormat,
  /** 
 * {"description":"Può esserci anche se non è ancora published per le uscite
   * programmate non ancora approvate (es.:Lanci)","dtPrecision":"FULL"}
 */
  publicationDate?: Maybe<Scalars['DateTime']>,
  /** {} */
  status: NewsStatus,
  /** {"description":"Base html (strong/..)","html":true,"htmlLevel":"BASE"} */
  title: Scalars['String'],
  /** {"description":"Base HTML (strong/...)","html":true,"htmlLevel":"BASE_MULTILINE"} */
  excerpt?: Maybe<Scalars['String']>,
  /** {"description":"Se c'è HtmlTemplate qui ci va la cache renderizzata","html":true,"htmlLevel":"LINK_EMBED"} */
  body?: Maybe<Scalars['String']>,
  /** {} */
  source: NewsSource,
  /** {"description":"@TODO DESCRIBE ME"} */
  externalSourceId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME (reference)","required":true,"mediaType":"NEWS"} */
  featuredImageMedia?: Maybe<Media>,
  /** {"description":"For imported posts"} */
  featuredImageUrl?: Maybe<Scalars['String']>,
  /** {"description":"JSON - for imported posts\n<size: string>: { width, height, source_url }"} */
  featuredImageSizes?: Maybe<Scalars['String']>,
  /** {} */
  CreatorUser: User,
  /** {} */
  PublisherUser?: Maybe<User>,
  /** {} */
  primaryEditorialSection?: Maybe<EditorialSection>,
  /** {"description":"Default en_US","readonly":true} */
  langCode: Scalars['String'],
  /** {"readonly":true} */
  target: TargetSite,
  /** {} */
  Permalink?: Maybe<Permalink>,
  /** {"description":"JSON"} */
  seoMeta?: Maybe<Scalars['String']>,
  /** {"description":"Data entro cui tutto dev'essere pronto","dtPrecision":"DAY_END"} */
  deadlineDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"Se TRUE non deve essere visibie al pubblico"} */
  isSfAbandoned: Scalars['Boolean'],
  /** {"description":"JSON (per News che vanno su template es. Lanches)"} */
  htmlTemplateValues?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  orderPriority: Scalars['Int'],
  /** {} */
  editorialSections: Array<EditorialSection>,
  /** {} */
  editorialTags: Array<EditorialTag>,
  /** {} */
  embeddedMedias: Array<Media>,
  /** {"description":"Not actual citated object but intermediate object with count"} */
  citations: Array<NewsCitation>,
};

/** A connection to a list of items. */
export type News_2Connection = {
   __typename?: 'News_2Connection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<News_2Edge>>>,
  total?: Maybe<Scalars['Int']>,
};

/** An edge in a connection. */
export type News_2Edge = {
   __typename?: 'News_2Edge',
  /** The item at the end of the edge */
  node?: Maybe<News>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** {"description":"Citation object in News with same citation conut.","canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"EDITORIAL_BASE"}} */
export type NewsCitation = Node & {
   __typename?: 'NewsCitation',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  News: News,
  /** {"description":"Quante volte è citato/a nella News","min":1,"max":2147483647} */
  count?: Maybe<Scalars['Int']>,
  /** {} */
  Company?: Maybe<Company>,
  /** {} */
  Brand?: Maybe<Brand>,
  /** {} */
  Good?: Maybe<Good>,
  /** {} */
  GeoIndication?: Maybe<GeoIndication>,
  /** {} */
  Fair?: Maybe<Fair>,
  /** {} */
  Retailer?: Maybe<Retailer>,
  /** {} */
  Service?: Maybe<Service>,
  /** {} */
  CompanyGroup?: Maybe<CompanyGroup>,
};

export type NewsCitationCreateInput = {
  /** {} */
  News: RefInputObject,
  /** {"description":"Quante volte è citato/a nella News","min":1,"max":2147483647} */
  count?: Maybe<Scalars['Int']>,
  /** {} */
  Company?: Maybe<RefInputObject>,
  /** {} */
  Brand?: Maybe<RefInputObject>,
  /** {} */
  Good?: Maybe<RefInputObject>,
  /** {} */
  GeoIndication?: Maybe<RefInputObject>,
  /** {} */
  Fair?: Maybe<RefInputObject>,
  /** {} */
  Retailer?: Maybe<RefInputObject>,
  /** {} */
  Service?: Maybe<RefInputObject>,
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>,
};

export type NewsCitationPatchInput = {
  /** {} */
  News?: Maybe<RefInputObject>,
  /** {"description":"Quante volte è citato/a nella News","min":1,"max":2147483647} */
  count?: Maybe<Scalars['Int']>,
  /** {} */
  Company?: Maybe<RefInputObject>,
  /** {} */
  Brand?: Maybe<RefInputObject>,
  /** {} */
  Good?: Maybe<RefInputObject>,
  /** {} */
  GeoIndication?: Maybe<RefInputObject>,
  /** {} */
  Fair?: Maybe<RefInputObject>,
  /** {} */
  Retailer?: Maybe<RefInputObject>,
  /** {} */
  Service?: Maybe<RefInputObject>,
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>,
};

/** A connection to a list of items. */
export type NewsConnection = {
   __typename?: 'NewsConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NewsEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

/** An edge in a connection. */
export type NewsEdge = {
   __typename?: 'NewsEdge',
  /** The item at the end of the edge */
  node?: Maybe<News>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type NewsFilterInput = {
  NewsFormat?: Maybe<Array<RefInputObject>>,
  /** Contained in title or excerpt */
  text?: Maybe<Scalars['String']>,
  statuses?: Maybe<Array<NewsStatus>>,
  CreatorUser?: Maybe<RefInputObject>,
  targetSite?: Maybe<TargetSite>,
  EditorialSection?: Maybe<Array<RefInputObject>>,
  EditorialTag?: Maybe<Array<RefInputObject>>,
  isSfAbandoned?: Maybe<Scalars['Boolean']>,
};

/** 
 * {"description":"Enum:\n1 - generic_post (?)\n2 - intervista\n5 - flash\n21 -
 * video generico\n22 - video intervista\n201 - lancio di prodotto con DEM\n202 -
 * lancio di prodotto senza DEM\n301 - impresa che cresce\n30X - impresa che
 * ???\n...\n501 - comunicato stampa Company\n511 - comunicato stampa
 * GeoIndication\n521 - comunicato stampa Fair","canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}}
 */
export type NewsFormat = Node & {
   __typename?: 'NewsFormat',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {} */
  HtmlTemplate?: Maybe<HtmlTemplate>,
  /** {"description":"@TODO DESCRIBE ME"} */
  saleable: Scalars['Boolean'],
};

/** A connection to a list of items. */
export type NewsFormatConnection = {
   __typename?: 'NewsFormatConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NewsFormatEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type NewsFormatCreateInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {} */
  HtmlTemplate?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME"} */
  saleable: Scalars['Boolean'],
};

/** An edge in a connection. */
export type NewsFormatEdge = {
   __typename?: 'NewsFormatEdge',
  /** The item at the end of the edge */
  node?: Maybe<NewsFormat>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type NewsFormatPatchInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {} */
  HtmlTemplate?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME"} */
  saleable?: Maybe<Scalars['Boolean']>,
};

/** {"description":"@TODO","canCreateRoles":{"INTERNAL":"MARKETING_EXTRA"},"canPatchRoles":{"INTERNAL":"MARKETING_EXTRA"},"canDeleteRoles":{"INTERNAL":"MARKETING_EXTRA"}} */
export type Newsletter = Node & {
   __typename?: 'Newsletter',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {} */
  target: TargetSite,
  /** {} */
  inputTemplate: InputTemplate,
  /** {} */
  values?: Maybe<Scalars['String']>,
};

/** // TODO */
export enum NewsletterAcceptedFlag {
  General = 'GENERAL'
}

/** A connection to a list of items. */
export type NewsletterConnection = {
   __typename?: 'NewsletterConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NewsletterEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type NewsletterCreateInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {} */
  target: TargetSite,
  /** {} */
  inputTemplate: InputTemplate,
  /** {} */
  values?: Maybe<Scalars['String']>,
};

/** An edge in a connection. */
export type NewsletterEdge = {
   __typename?: 'NewsletterEdge',
  /** The item at the end of the edge */
  node?: Maybe<Newsletter>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** {"description":"@TODO","canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"EDITORIAL_EXTRA"},"canSetStatusRoles":{"status":{"STUB":{"INTERNAL":"EDITORIAL_BASE"},"DRAFT":{"INTERNAL":"EDITORIAL_BASE"},"WAITING_PUBLICATION":{"INTERNAL":"EDITORIAL_BASE"},"PUBLISHED":{"INTERNAL":"EDITORIAL_EXTRA"},"HIDDEN":{"INTERNAL":"EDITORIAL_BASE"},"DELETED":{"INTERNAL":"EDITORIAL_BASE"}}},"canPatchNeedCurrentStatusAccess":"status"} */
export type NewsletterIssue = Node & {
   __typename?: 'NewsletterIssue',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** 
 * {"description":"Può esserci anche se non è ancora published per le uscite
   * programmate non ancora approvate (es.:Lanci)","dtPrecision":"FULL"}
 */
  publicationDate?: Maybe<Scalars['DateTime']>,
  /** {} */
  status: NewsStatus,
  /** {} */
  Newsletter: Newsletter,
  /** {"max":100} */
  name: Scalars['String'],
  /** {"description":"Base html (strong/..)","max":100,"html":true,"htmlLevel":"BASE"} */
  hiddenIntro: Scalars['String'],
  /** {} */
  values?: Maybe<Scalars['String']>,
};

/** A connection to a list of items. */
export type NewsletterIssueConnection = {
   __typename?: 'NewsletterIssueConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NewsletterIssueEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type NewsletterIssueCreateInput = {
  /** 
 * {"description":"Può esserci anche se non è ancora published per le uscite
   * programmate non ancora approvate (es.:Lanci)","dtPrecision":"FULL"}
 */
  publicationDate?: Maybe<Scalars['DateTime']>,
  /** {} */
  Newsletter: RefInputObject,
  /** {"max":100} */
  name: Scalars['String'],
  /** {"description":"Base html (strong/..)","max":100,"html":true,"htmlLevel":"BASE"} */
  hiddenIntro: Scalars['String'],
  /** {} */
  values?: Maybe<Scalars['String']>,
};

/** An edge in a connection. */
export type NewsletterIssueEdge = {
   __typename?: 'NewsletterIssueEdge',
  /** The item at the end of the edge */
  node?: Maybe<NewsletterIssue>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type NewsletterIssueFilterInput = {
  Newsletter?: Maybe<Array<RefInputObject>>,
  /** Contained in subject */
  subject?: Maybe<Scalars['String']>,
  statuses?: Maybe<Array<NewsStatus>>,
};

/** {"description":"NewsletterIssue-Company linking table + capabilities. Default read-only capability.","canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"EDITORIAL_BASE"}} */
export type NewsletterIssueHasNews = Node & {
   __typename?: 'NewsletterIssueHasNews',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  NewsletterIssue: NewsletterIssue,
  /** {} */
  News?: Maybe<News>,
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  order: Scalars['Int'],
  /** {"description":"Base html (strong/..)","html":true,"htmlLevel":"BASE"} */
  customTitle?: Maybe<Scalars['String']>,
  /** {"description":"Base HTML (strong/...)","html":true,"htmlLevel":"BASE_MULTILINE"} */
  customExcerpt?: Maybe<Scalars['String']>,
  /** {"html":true,"htmlLevel":"LINK_EMBED"} */
  customBody?: Maybe<Scalars['String']>,
};

export type NewsletterIssueHasNewsCreateInput = {
  /** {} */
  NewsletterIssue: RefInputObject,
  /** {} */
  News?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  order: Scalars['Int'],
  /** {"description":"Base html (strong/..)","html":true,"htmlLevel":"BASE"} */
  customTitle?: Maybe<Scalars['String']>,
  /** {"description":"Base HTML (strong/...)","html":true,"htmlLevel":"BASE_MULTILINE"} */
  customExcerpt?: Maybe<Scalars['String']>,
  /** {"html":true,"htmlLevel":"LINK_EMBED"} */
  customBody?: Maybe<Scalars['String']>,
};

export type NewsletterIssueHasNewsPatchInput = {
  /** {} */
  NewsletterIssue?: Maybe<RefInputObject>,
  /** {} */
  News?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  order?: Maybe<Scalars['Int']>,
  /** {"description":"Base html (strong/..)","html":true,"htmlLevel":"BASE"} */
  customTitle?: Maybe<Scalars['String']>,
  /** {"description":"Base HTML (strong/...)","html":true,"htmlLevel":"BASE_MULTILINE"} */
  customExcerpt?: Maybe<Scalars['String']>,
  /** {"html":true,"htmlLevel":"LINK_EMBED"} */
  customBody?: Maybe<Scalars['String']>,
};

export type NewsletterIssuePatchInput = {
  /** 
 * {"description":"Può esserci anche se non è ancora published per le uscite
   * programmate non ancora approvate (es.:Lanci)","dtPrecision":"FULL"}
 */
  publicationDate?: Maybe<Scalars['DateTime']>,
  /** {} */
  Newsletter?: Maybe<RefInputObject>,
  /** {"max":100} */
  name?: Maybe<Scalars['String']>,
  /** {"description":"Base html (strong/..)","max":100,"html":true,"htmlLevel":"BASE"} */
  hiddenIntro?: Maybe<Scalars['String']>,
  /** {} */
  values?: Maybe<Scalars['String']>,
};

export type NewsletterPatchInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {} */
  target?: Maybe<TargetSite>,
  /** {} */
  inputTemplate?: Maybe<InputTemplate>,
  /** {} */
  values?: Maybe<Scalars['String']>,
};

/** News's possible source */
export enum NewsSource {
  Hub = 'HUB',
  WpIfn = 'WP_IFN',
  WpFoodweb = 'WP_FOODWEB',
  WpFoodserviceweb = 'WP_FOODSERVICEWEB',
  PaperIfn = 'PAPER_IFN'
}

/** News's possible statues */
export enum NewsStatus {
  Stub = 'STUB',
  Draft = 'DRAFT',
  WaitingPublication = 'WAITING_PUBLICATION',
  Published = 'PUBLISHED',
  Hidden = 'HIDDEN',
  Deleted = 'DELETED'
}

/** @TODO DESCRIBE ME */
export type NewsViewInput = {
  /** Contained in title or excerpt */
  text?: Maybe<Scalars['String']>,
  EditorialSections?: Maybe<Array<RefInputObject>>,
  EditorialTags?: Maybe<Array<RefInputObject>>,
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'],
};

/** {} */
export type ObjectHistory = Node & {
   __typename?: 'ObjectHistory',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  User: User,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"FULL"} */
  timestamp: Scalars['DateTime'],
  /** {} */
  action: ObjectHistoryAction,
  /** {} */
  collection: Scalars['String'],
  /** {} */
  objectGid: Scalars['ID'],
  /** {} */
  Object?: Maybe<Node>,
  /** {} */
  params?: Maybe<Scalars['String']>,
};

export enum ObjectHistoryAction {
  Create = 'CREATE',
  Patch = 'PATCH',
  SetStatus = 'SET_STATUS',
  Delete = 'DELETE',
  Subcreate = 'SUBCREATE',
  Merge = 'MERGE'
}

export enum ObjectHistoryCollection {
  Abbonamento = 'Abbonamento',
  AbbonamentoPeriodo = 'AbbonamentoPeriodo',
  AnswerSet = 'AnswerSet',
  AtecoCode = 'AtecoCode',
  Award = 'Award',
  AwardCategory = 'AwardCategory',
  AwardInvitedCompany = 'AwardInvitedCompany',
  AwardInvitedUser = 'AwardInvitedUser',
  AwardResult = 'AwardResult',
  Banner = 'Banner',
  BannerView = 'BannerView',
  Branch = 'Branch',
  Brand = 'Brand',
  CampaignMember = 'CampaignMember',
  Candidacy = 'Candidacy',
  CandidacyRating = 'CandidacyRating',
  Company = 'Company',
  CompanyBadge = 'CompanyBadge',
  CompanyCertification = 'CompanyCertification',
  CompanyGroup = 'CompanyGroup',
  CompanyLevel = 'CompanyLevel',
  CompanyYear = 'CompanyYear',
  Contact = 'Contact',
  Country = 'Country',
  DistributionChannel = 'DistributionChannel',
  EditorialSection = 'EditorialSection',
  EditorialTag = 'EditorialTag',
  EmailDomain = 'EmailDomain',
  EuCategory = 'EuCategory',
  Fair = 'Fair',
  FairParticipation = 'FairParticipation',
  FairVisit = 'FairVisit',
  FairVisitParticipation = 'FairVisitParticipation',
  FrequentSearch = 'FrequentSearch',
  GeoIndication = 'GeoIndication',
  Good = 'Good',
  GoodClass = 'GoodClass',
  GoodFeatureCertification = 'GoodFeatureCertification',
  GoodFormat = 'GoodFormat',
  HighlightedGood = 'HighlightedGood',
  HtmlTemplate = 'HtmlTemplate',
  Indirizzo = 'Indirizzo',
  Magazine = 'Magazine',
  MagazineCollection = 'MagazineCollection',
  MagazineContent = 'MagazineContent',
  ManagedByUser = 'ManagedByUser',
  Media = 'Media',
  News = 'News',
  NewsCitation = 'NewsCitation',
  NewsFormat = 'NewsFormat',
  Newsletter = 'Newsletter',
  NewsletterIssue = 'NewsletterIssue',
  NewsletterIssueHasNews = 'NewsletterIssueHasNews',
  ObjectHistory = 'ObjectHistory',
  Permalink = 'Permalink',
  PiList = 'PiList',
  PricebookEntry = 'PricebookEntry',
  Prospect = 'Prospect',
  QuestionSet = 'QuestionSet',
  Quote = 'Quote',
  QuoteLineItem = 'QuoteLineItem',
  Retailer = 'Retailer',
  SampleRequest = 'SampleRequest',
  Service = 'Service',
  StateProvince = 'StateProvince',
  Team = 'Team',
  ToS = 'ToS',
  Typeform = 'Typeform',
  User = 'User',
  UserAchievement = 'UserAchievement',
  UserNote = 'UserNote',
  UserObjectSubscription = 'UserObjectSubscription',
  Widget = 'Widget'
}

/** A connection to a list of items. */
export type ObjectHistoryConnection = {
   __typename?: 'ObjectHistoryConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ObjectHistoryEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

/** An edge in a connection. */
export type ObjectHistoryEdge = {
   __typename?: 'ObjectHistoryEdge',
  /** The item at the end of the edge */
  node?: Maybe<ObjectHistory>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type ObjectHistoryFilterInput = {
  actions?: Maybe<Array<ObjectHistoryAction>>,
  collections?: Maybe<Array<ObjectHistoryCollection>>,
  userRoles?: Maybe<Array<UserRole>>,
  User?: Maybe<RefInputObject>,
  Object?: Maybe<RefInputObject>,
  /** Cerco in quelle fatte da utenti che gestiscono Company invitate a quel Award */
  Award?: Maybe<RefInputObject>,
  timestampMin?: Maybe<Scalars['DateTime']>,
  timestampMax?: Maybe<Scalars['DateTime']>,
};

/** Information about pagination in a connection. */
export type PageInfo = {
   __typename?: 'PageInfo',
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'],
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'],
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>,
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>,
};

/** {"description":"@TODO DESCRIBE ME"} */
export type Permalink = Node & {
   __typename?: 'Permalink',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"URL senza hostname (l'hostname deriva dal target)"} */
  url: Scalars['String'],
  /** {} */
  targetSite: TargetSite,
  /** {"description":"@TODO DESCRIBE ME"} */
  collection: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  objectId: Scalars['Int'],
};

/** {"description":"@TODO DESCRIBE ME"} */
export type PiList = Node & {
   __typename?: 'PiList',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {} */
  piId?: Maybe<Scalars['String']>,
  /** {"description":"Uso interno"} */
  name: Scalars['String'],
  /** {"description":"Mostrato all'utente"} */
  title?: Maybe<Scalars['String']>,
  /** {} */
  description?: Maybe<Scalars['String']>,
  /** {} */
  isPublic?: Maybe<Scalars['Boolean']>,
  /** {} */
  isDynamic?: Maybe<Scalars['Boolean']>,
  /** {} */
  isCrmVisible?: Maybe<Scalars['Boolean']>,
};

/** A connection to a list of items. */
export type PiListConnection = {
   __typename?: 'PiListConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PiListEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

/** An edge in a connection. */
export type PiListEdge = {
   __typename?: 'PiListEdge',
  /** The item at the end of the edge */
  node?: Maybe<PiList>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** {"description":"@TODO DESCRIBE ME"} */
export type PiListSubscription = {
   __typename?: 'PiListSubscription',
  /** {} */
  PiList: PiList,
  /** {} */
  didOptIn?: Maybe<Scalars['Boolean']>,
  /** {} */
  didOptOut?: Maybe<Scalars['Boolean']>,
};

export type PiListSubscriptionChange = {
  PiList: RefInputObject,
  optIn: Scalars['Boolean'],
};

/** Preservation types */
export enum Preservation {
  Perishable = 'PERISHABLE',
  Frozen = 'FROZEN',
  ShelfStable = 'SHELF_STABLE'
}

/** {"description":"Price Book Entry","canCreateRoles":false,"canPatchRoles":false,"canDeleteRoles":false} */
export type PricebookEntry = Node & {
   __typename?: 'PricebookEntry',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"Price Book Entry ID","required":false,"readonly":true,"max":18} */
  Id?: Maybe<Scalars['String']>,
  /** {"description":"Product Name","readonly":true,"max":255} */
  Name?: Maybe<Scalars['String']>,
  /** {"description":"List Price","required":true,"max":2147483647,"min":-2147483647} */
  UnitPrice: Scalars['Int'],
  /** {"description":"Active","required":false} */
  IsActive?: Maybe<Scalars['Boolean']>,
  /** {} */
  QuoteLineItems: Array<QuoteLineItem>,
  /** {"description":"Brand"} */
  Product2Brand?: Maybe<Product2_Brand__C_EnumFiltered>,
  /** 
 * {"description":"Data di Scadenza : Prodotti Brand Area Rivista = 10 gg prima
   * inizio mese competenza\r\nProdotti Brand Area Digitale = 7 gg prima
   * pubblicazione","dtPrecision":"DAY_END"}
 */
  Product2Data_di_Scadenza?: Maybe<Scalars['DateTime']>,
  /** {"description":"Tipologia"} */
  Product2Tipologia?: Maybe<Product2_Tipologia__C_Enum>,
  /** {"description":"Brand Area"} */
  Product2Brand_Area?: Maybe<Product2_Brand_Area__C_Enum>,
  /** {"description":"Data Inizio Competenza","dtPrecision":"DAY_END"} */
  Product2Data_Inizio_Competenza?: Maybe<Scalars['DateTime']>,
  /** {"description":"Data Fine Competenza","dtPrecision":"DAY_END"} */
  Product2Data_Fine_Competenza?: Maybe<Scalars['DateTime']>,
  /** {"description":"Famiglia"} */
  Product2Famiglia?: Maybe<Product2_Famiglia__C_Enum>,
  /** {"description":"Tipo Inserzione","required":true,"max":60} */
  Product2Tipo: Scalars['String'],
};

/** A connection to a list of items. */
export type PricebookEntryConnection = {
   __typename?: 'PricebookEntryConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PricebookEntryEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

/** An edge in a connection. */
export type PricebookEntryEdge = {
   __typename?: 'PricebookEntryEdge',
  /** The item at the end of the edge */
  node?: Maybe<PricebookEntry>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type PricebookEntryFilterInput = {
  PricebookSfId?: Maybe<Scalars['String']>,
  Product2Brand?: Maybe<Product2_Brand__C_EnumFiltered>,
  Product2Brand_Area?: Maybe<Product2_Brand_Area__C_Enum>,
  Product2Tipologia?: Maybe<Product2_Tipologia__C_Enum>,
  Product2Tipo?: Maybe<Scalars['String']>,
  promoCodes?: Maybe<Array<Scalars['String']>>,
};

export enum PricebookEntryOrderBy {
  Name = 'Name',
  UnitPrice = 'UnitPrice',
  Product2Brand = 'Product2Brand',
  Product2BrandArea = 'Product2Brand_Area',
  Product2DataDiScadenza = 'Product2Data_di_Scadenza',
  Product2DataInizioCompetenza = 'Product2Data_Inizio_Competenza',
  Product2DataFineCompetenza = 'Product2Data_Fine_Competenza'
}

export enum PricePositioning {
  Discount = 'DISCOUNT',
  Mainstream = 'MAINSTREAM',
  Premium = 'PREMIUM',
  Luxury = 'LUXURY'
}

/** SF picklist */
export enum Product2_Brand__C_EnumFiltered {
  /** FOOD */
  Food = 'FOOD',
  /** FOODSERVICE */
  Foodservice = 'FOODSERVICE',
  /** DOLCESALATO */
  Dolcesalato = 'DOLCESALATO',
  /** ITALIANFOOD.NET */
  ItalianfoodNet = 'ITALIANFOOD_NET',
  /** FIOR FIORE IN CUCINA */
  FiorFioreInCucina = 'FIOR_FIORE_IN_CUCINA',
  /** PROGETTI */
  Progetti = 'PROGETTI',
  /** FOOD LABS */
  FoodLabs = 'FOOD_LABS',
  /** P&B */
  PB = 'P_B',
  /** BAR BUSINESS */
  BarBusiness = 'BAR_BUSINESS',
  /** FOOD EDITORE */
  FoodEditore = 'FOOD_EDITORE'
}

/** SF picklist */
export enum Product2_Brand_Area__C_Enum {
  /** Rivista */
  Rivista = 'Rivista',
  /** Digitale */
  Digitale = 'Digitale',
  /** Digitale ITA */
  DigitaleIta = 'Digitale_ITA',
  /** Digitale INT */
  DigitaleInt = 'Digitale_INT',
  /** Abbonamenti */
  Abbonamenti = 'Abbonamenti',
  /** Eventi */
  Eventi = 'Eventi',
  /** Content */
  Content = 'Content',
  /** Promo */
  Promo = 'Promo',
  /** Strategia e Progetti Integrati */
  StrategiaEProgettiIntegrati = 'Strategia_e_Progetti_Integrati',
  /** Distribuzione Con Reso */
  DistribuzioneConReso = 'Distribuzione_Con_Reso',
  /** Distribuzione Senza Reso */
  DistribuzioneSenzaReso = 'Distribuzione_Senza_Reso',
  /** Estero */
  Estero = 'Estero',
  /** Servizi Editoriali */
  ServiziEditoriali = 'Servizi_Editoriali',
  /** Produzione Editoriale Multicanale */
  ProduzioneEditorialeMulticanale = 'Produzione_Editoriale_Multicanale'
}

/** SF picklist */
export enum Product2_Famiglia__C_Enum {
  /** Food */
  Food = 'Food',
  /** Out Of Home */
  OutOfHome = 'Out_Of_Home',
  /** Content Marketing and Promo */
  ContentMarketingAndPromo = 'Content_Marketing_and_Promo',
  /** Editoria */
  Editoria = 'Editoria'
}

/** SF picklist */
export enum Product2_Tipologia__C_Enum {
  /** Periodico */
  Periodico = 'Periodico',
  /** Digitale */
  Digitale = 'Digitale',
  /** Speciale */
  Speciale = 'Speciale',
  /** Food Summit */
  FoodSummit = 'Food_Summit',
  /** Premiato Prodotto Food */
  PremiatoProdottoFood = 'Premiato_Prodotto_Food',
  /** Altri Eventi */
  AltriEventi = 'Altri_Eventi',
  /** Foto - Video */
  FotoVideo = 'Foto___Video',
  /** Pubblicazioni */
  Pubblicazioni = 'Pubblicazioni',
  /** Post */
  Post = 'Post',
  /** Concorso a premi */
  ConcorsoAPremi = 'Concorso_a_premi',
  /** Materiale POP */
  MaterialePop = 'Materiale_POP',
  /** In Store Promotion */
  InStorePromotion = 'In_Store_Promotion',
  /** Gadget */
  Gadget = 'Gadget',
  /** Editoriale */
  Editoriale = 'Editoriale',
  /** Raccolta Pubblicitaria */
  RaccoltaPubblicitaria = 'Raccolta_Pubblicitaria',
  /** Progettuale */
  Progettuale = 'Progettuale',
  /** Riviste */
  Riviste = 'Riviste',
  /** Showcase */
  Showcase = 'Showcase'
}

/** {"description":"@TODO DESCRIBE ME","canPatchRoles":true} */
export type Prospect = Node & {
   __typename?: 'Prospect',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {} */
  piId?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  email: Scalars['Email'],
  /** {} */
  piListSubscriptions?: Maybe<Array<PiListSubscription>>,
};

export type Query = {
   __typename?: 'Query',
  /** Fetches an object given its ID */
  node?: Maybe<Node>,
  /** Fetches objects given their IDs */
  nodes: Array<Maybe<Node>>,
  bannerViews?: Maybe<BannerViewConnection>,
  medias?: Maybe<MediaConnection>,
  users?: Maybe<UserConnection>,
  currentUser?: Maybe<User>,
  goodClasses?: Maybe<GoodClassConnection>,
  goodFeatureCertifications?: Maybe<GoodFeatureCertificationConnection>,
  companyCertifications?: Maybe<CompanyCertificationConnection>,
  userObjectSubscriptions?: Maybe<UserObjectSubscriptionConnection>,
  userNotes?: Maybe<UserNoteConnection>,
  companies?: Maybe<CompanyConnection>,
  brands?: Maybe<BrandConnection>,
  goods?: Maybe<GoodConnection>,
  awards?: Maybe<AwardConnection>,
  candidacies?: Maybe<CandidacyConnection>,
  awardCategoryCandidaciesToRate: Array<Maybe<Candidacy>>,
  atecoCodes?: Maybe<AtecoCodeConnection>,
  candidacyRatings?: Maybe<CandidacyRatingConnection>,
  companyGroups?: Maybe<CompanyGroupConnection>,
  services?: Maybe<ServiceConnection>,
  retailers?: Maybe<RetailerConnection>,
  teams?: Maybe<TeamConnection>,
  euCategories?: Maybe<EuCategoryConnection>,
  geoIndications?: Maybe<GeoIndicationConnection>,
  fairs?: Maybe<FairConnection>,
  countries?: Maybe<CountryConnection>,
  stateProvinces?: Maybe<StateProvinceConnection>,
  editorialSections?: Maybe<EditorialSectionConnection>,
  editorialSectionTree: Array<EditorialSection>,
  editorialTags?: Maybe<EditorialTagConnection>,
  htmlTemplates?: Maybe<HtmlTemplateConnection>,
  newsFormats?: Maybe<NewsFormatConnection>,
  /** Fetches an object given its Permalink URL */
  url2node?: Maybe<Node>,
  news?: Maybe<NewsConnection>,
  newsView?: Maybe<News_2Connection>,
  banners?: Maybe<BannerConnection>,
  /** Get banner to show at current user in current context */
  bannersView: Array<Banner>,
  widgets?: Maybe<WidgetConnection>,
  /** Get widgets to show at current user in current context */
  widgetsView: Array<Widget>,
  abbonamenti?: Maybe<AbbonamentoConnection>,
  magazines?: Maybe<MagazineConnection>,
  magazineCollections?: Maybe<MagazineCollectionConnection>,
  newsletters?: Maybe<NewsletterConnection>,
  newsletterIssues?: Maybe<NewsletterIssueConnection>,
  emailDomains?: Maybe<EmailDomainConnection>,
  objectHistories?: Maybe<ObjectHistoryConnection>,
  toSs?: Maybe<ToSConnection>,
  questionSets?: Maybe<QuestionSetConnection>,
  answerSets?: Maybe<AnswerSetConnection>,
  frequentSearchs?: Maybe<FrequentSearchConnection>,
  fairVisits?: Maybe<FairVisitConnection>,
  userAchievements?: Maybe<UserAchievementConnection>,
  sampleRequests?: Maybe<SampleRequestConnection>,
  companyBadges?: Maybe<CompanyBadgeConnection>,
  typeforms?: Maybe<TypeformConnection>,
  pricebookEntries?: Maybe<PricebookEntryConnection>,
  quotes?: Maybe<QuoteConnection>,
  piLists?: Maybe<PiListConnection>,
  companiesDir?: Maybe<CompaniesDirConnection>,
  goodsDir?: Maybe<GoodsDirConnection>,
};


export type QueryNodeArgs = {
  id: Scalars['ID']
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryBannerViewsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<BannerViewFilterInput>
};


export type QueryMediasArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<MediaFilterInput>,
  orderBy?: Maybe<MediaOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryUsersArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<UserFilterInput>,
  orderBy?: Maybe<UserOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryGoodClassesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SearchGoodClassInput>
};


export type QueryGoodFeatureCertificationsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SearchByNameInput>
};


export type QueryCompanyCertificationsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SearchByNameInput>
};


export type QueryUserObjectSubscriptionsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<UserObjectSubscriptionFilterInput>
};


export type QueryUserNotesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<UserNoteFilterInput>
};


export type QueryCompaniesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<CompanyFilterInput>,
  orderBy?: Maybe<CompanyOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryBrandsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<BrandFilterInput>
};


export type QueryGoodsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<GoodFilterInput>,
  orderBy?: Maybe<GoodOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryAwardsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<AwardFilterInput>,
  orderBy?: Maybe<AwardOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryCandidaciesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<CandidacyFilterInput>,
  orderBy?: Maybe<CandidacyOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryAwardCategoryCandidaciesToRateArgs = {
  AwardCategory: RefInputObject
};


export type QueryAtecoCodesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<AtecoCodeFilterInput>,
  orderBy?: Maybe<AtecoCodeOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryCandidacyRatingsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<CandidacyRatingFilterInput>,
  orderBy?: Maybe<CandidacyRatingOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryCompanyGroupsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<CompanyGroupFilterInput>,
  orderBy?: Maybe<CompanyGroupOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryServicesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ServiceFilterInput>,
  orderBy?: Maybe<ServiceOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryRetailersArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<RetailerFilterInput>,
  orderBy?: Maybe<RetailerOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryTeamsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SearchByNameInput>
};


export type QueryEuCategoriesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SearchByNameInput>
};


export type QueryGeoIndicationsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<GeoIndicationFilterInput>,
  orderBy?: Maybe<GeoIndicationOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryFairsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<FairFilterInput>
};


export type QueryCountriesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<CountryFilterInput>,
  orderBy?: Maybe<CountryOrderByEnum>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryStateProvincesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<StateProvinceFilterInput>,
  orderBy?: Maybe<StateProvinceOrderByEnum>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryEditorialSectionsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<EditorialSectionFilterInput>,
  orderBy?: Maybe<EditorialSectionOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryEditorialSectionTreeArgs = {
  EditorialBrand: EditorialBrand
};


export type QueryEditorialTagsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<EditorialTagFilterInput>
};


export type QueryHtmlTemplatesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SearchByNameInput>
};


export type QueryNewsFormatsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SearchByNameInput>
};


export type QueryUrl2nodeArgs = {
  url: Scalars['String']
};


export type QueryNewsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<NewsFilterInput>
};


export type QueryNewsViewArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<NewsViewInput>
};


export type QueryBannersArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<BannerFilterInput>,
  orderBy?: Maybe<BannerOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryBannersViewArgs = {
  filter: BannerViewInput
};


export type QueryWidgetsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<WidgetFilterInput>,
  orderBy?: Maybe<WidgetOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryWidgetsViewArgs = {
  filter: WidgetViewInput
};


export type QueryAbbonamentiArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<AbbonamentoFilterInput>
};


export type QueryMagazinesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<MagazineFilterInput>
};


export type QueryMagazineCollectionsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<MagazineCollectionFilterInput>
};


export type QueryNewslettersArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SearchByNameInput>
};


export type QueryNewsletterIssuesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<NewsletterIssueFilterInput>
};


export type QueryEmailDomainsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<EmailDomainFilterInput>,
  orderBy?: Maybe<EmailDomainOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryObjectHistoriesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ObjectHistoryFilterInput>
};


export type QueryToSsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SearchByNameInput>
};


export type QueryQuestionSetsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<QuestionSetFilterInput>
};


export type QueryAnswerSetsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<AnswerSetFilterInput>
};


export type QueryFrequentSearchsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<FrequentSearchFilterInput>
};


export type QueryFairVisitsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<FairVisitFilterInput>
};


export type QueryUserAchievementsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<UserAchievementFilterInput>
};


export type QuerySampleRequestsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SampleRequestFilterInput>
};


export type QueryCompanyBadgesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<CompanyBadgeFilterInput>
};


export type QueryTypeformsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<TypeformFilterInput>
};


export type QueryPricebookEntriesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<PricebookEntryFilterInput>,
  orderBy?: Maybe<PricebookEntryOrderBy>,
  orderDesc?: Maybe<Scalars['Boolean']>
};


export type QueryQuotesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryPiListsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryCompaniesDirArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<CompaniesFilterType>
};


export type QueryGoodsDirArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<GoodsFilterType>
};

/** {"description":"Usato per Ask the Company","canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA","BUYER":true,"BUYER_PL_ITALIA":true,"ANONYMUS":true,"CONSUMER":true,"PRODUCER":true},"canPatchRoles":{"INTERNAL":"DIRECTORY_EXTRA","BUYER":true,"BUYER_PL_ITALIA":true},"canDeleteRoles":{"INTERNAL":"DIRECTORY_EXTRA"}} */
export type QuestionSet = Node & {
   __typename?: 'QuestionSet',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"readonly":true} */
  parentQuestionSet?: Maybe<QuestionSet>,
  /** {} */
  targetSite: TargetSite,
  /** {} */
  User: User,
  /** {} */
  Team?: Maybe<Team>,
  /** {} */
  Retailer?: Maybe<Retailer>,
  /** {"description":"Data/ora di invio","dtPrecision":"FULL"} */
  sentDate: Scalars['DateTime'],
  /** {"description":"Data entro cui è possibile rispondere","dtPrecision":"DAY_END"} */
  deadlineDate: Scalars['DateTime'],
  /** {"readonly":true} */
  requests: Array<QuestionSetRequest>,
  /** {"readonly":true,"allowFormInlining":true} */
  answerSets: Array<AnswerSet>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question1?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question2?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question3?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question4?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question5?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question6?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question7?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question8?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question9?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question10?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question11?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question12?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question13?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question14?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question15?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question16?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question17?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question18?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question19?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question20?: Maybe<Scalars['String']>,
};

/** A connection to a list of items. */
export type QuestionSetConnection = {
   __typename?: 'QuestionSetConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QuestionSetEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type QuestionSetCreateInput = {
  /** {"readonly":true} */
  parentQuestionSet?: Maybe<RefInputObject>,
  /** {"readonly":true} */
  requests?: Maybe<Array<QuestionSetRequest>>,
  /** {"readonly":true,"allowFormInlining":true} */
  answerSets?: Maybe<Array<RefInputObject>>,
  /** {"readonly":true,"allowFormInlining":true} */
  answerSets__inline?: Maybe<Array<AnswerSet_From_QuestionSet_Type>>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question1?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question2?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question3?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question4?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question5?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question6?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question7?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question8?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question9?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question10?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question11?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question12?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question13?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question14?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question15?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question16?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question17?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question18?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question19?: Maybe<Scalars['String']>,
  /** {"readonly":true,"multiline":false,"max":150} */
  question20?: Maybe<Scalars['String']>,
  userEmail?: Maybe<Scalars['Email']>,
  userFirstName?: Maybe<Scalars['String']>,
  userLastName?: Maybe<Scalars['String']>,
  userCompanyName?: Maybe<Scalars['String']>,
  userCountry?: Maybe<Scalars['String']>,
  userNewsletterAccepted?: Maybe<Scalars['Boolean']>,
};

/** An edge in a connection. */
export type QuestionSetEdge = {
   __typename?: 'QuestionSetEdge',
  /** The item at the end of the edge */
  node?: Maybe<QuestionSet>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type QuestionSetFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  isClosed?: Maybe<Scalars['Boolean']>,
};

/** Possible requests */
export enum QuestionSetRequest {
  Catalog = 'CATALOG',
  Price = 'PRICE',
  Resellers = 'RESELLERS',
  Contact = 'CONTACT'
}

/** {"description":"Quote","canCreateRoles":{"INTERNAL":"MARKETING_EXTRA","PRODUCER":"PURCHASE","BUYER":true,"BUYER_PL_ITALIA":true,"CONSUMER":true,"ANONYMUS":true},"canPatchRoles":false,"canDeleteRoles":false} */
export type Quote = Node & {
   __typename?: 'Quote',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"Quote ID","required":false,"readonly":true,"max":18} */
  Id?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Quote Name : inserire almeno un codice alfanumerico. Si
   * rinominerà automaticamente col nome dell'account e la data
   * odierna.","required":true,"max":255}
 */
  Name: Scalars['String'],
  /** {"description":"Quote Number","required":false,"readonly":true,"max":30} */
  QuoteNumber?: Maybe<Scalars['String']>,
  /** 
 * {"description":"Description : Note a Contratto: Attenzione, quello che
   * scrivete va sul contratto.\r\nPer appunti e note di gestione interna usare
   * descrizione Prodotto o Opportunità","multiline":true,"max":32000}
 */
  Description?: Maybe<Scalars['String']>,
  /** {"description":"Total Price"} */
  TotalPrice?: Maybe<Scalars['Int']>,
  /** {"description":"Bill To Name","max":255} */
  BillingName?: Maybe<Scalars['String']>,
  /** {"description":"Discount","readonly":true} */
  Discount?: Maybe<Scalars['Float']>,
  /** {"description":"Condizioni di pagamento","required":false} */
  Condizioni_di_pagamento?: Maybe<Quote_Condizioni_Di_Pagamento__C_Enum>,
  /** {"allowFormInlining":true} */
  QuoteLineItems: Array<QuoteLineItem>,
  /** {} */
  referenteUser?: Maybe<User>,
  /** {} */
  accountCompany?: Maybe<Company>,
  /** {} */
  accountCompanyGroup?: Maybe<CompanyGroup>,
  /** {} */
  accountService?: Maybe<Service>,
  /** {} */
  accountRetailer?: Maybe<Retailer>,
  /** {} */
  accountGeoIndication?: Maybe<GeoIndication>,
};

/** SF picklist */
export enum Quote_Condizioni_Di_Pagamento__C_Enum {
  /** BONIFICO BANCARIO 30 GG DF. FM. */
  BonificoBancario_30GgDfFm = 'BONIFICO_BANCARIO_30_GG_DF__FM_',
  /** BONIFICO BANCARIO 60 GG DF. FM. */
  BonificoBancario_60GgDfFm = 'BONIFICO_BANCARIO_60_GG_DF__FM_',
  /** BONIFICO BANCARIO 90 GG DF. FM. */
  BonificoBancario_90GgDfFm = 'BONIFICO_BANCARIO_90_GG_DF__FM_',
  /** RICEVUTA BANCARIA 30 GG DF.FM. */
  RicevutaBancaria_30GgDfFm = 'RICEVUTA_BANCARIA_30_GG_DF_FM_',
  /** RICEVUTA BANCARIA 60 GG DF.FM. */
  RicevutaBancaria_60GgDfFm = 'RICEVUTA_BANCARIA_60_GG_DF_FM_',
  /** RICEVUTA BANCARIA 90 GG DF.FM. */
  RicevutaBancaria_90GgDfFm = 'RICEVUTA_BANCARIA_90_GG_DF_FM_',
  /** COMPENSAZIONE PARTITE */
  CompensazionePartite = 'COMPENSAZIONE_PARTITE',
  /** ASSEGNO BANCARIO */
  AssegnoBancario = 'ASSEGNO_BANCARIO',
  /** ADDEBITO CARTA DI CREDITO */
  AddebitoCartaDiCredito = 'ADDEBITO_CARTA_DI_CREDITO',
  /** ADDEBITO C/POSTALE */
  AddebitoCPostale = 'ADDEBITO_C_POSTALE',
  /** ADDEBITO DENARO CONTANTE */
  AddebitoDenaroContante = 'ADDEBITO_DENARO_CONTANTE',
  /** ADDEBITO BONIFICO BANCARIO */
  AddebitoBonificoBancario = 'ADDEBITO_BONIFICO_BANCARIO',
  /** BONIFICO BANCARIO */
  BonificoBancario = 'BONIFICO_BANCARIO',
  /** DENARO CONTANTE */
  DenaroContante = 'DENARO_CONTANTE',
  /** B.B. 50% ALLA CONSEGNA + B.B. 50% 60 GG DATA FATTURA FM */
  BB_50AllaConsegnaBB_50_60GgDataFatturaFm = 'B_B__50__ALLA_CONSEGNA___B_B__50__60_GG_DATA_FATTURA_FM',
  /** B.B. 50% ALLA CONSEGNA + B.B. 50% A 30 GG DATA FATTURA */
  BB_50AllaConsegnaBB_50A_30GgDataFattura = 'B_B__50__ALLA_CONSEGNA___B_B__50__A_30_GG_DATA_FATTURA',
  /** BONIFICO BANCARIO DATA FISSA */
  BonificoBancarioDataFissa = 'BONIFICO_BANCARIO_DATA_FISSA',
  /** BONIFICO BANCARIO 120 GG DF.FM. */
  BonificoBancario_120GgDfFm = 'BONIFICO_BANCARIO_120_GG_DF_FM_',
  /** BONIFICO BANCARIO 150 GG DF.FM. */
  BonificoBancario_150GgDfFm = 'BONIFICO_BANCARIO_150_GG_DF_FM_',
  /** BONIFICO BANCARIO 180 GG.DF.FM */
  BonificoBancario_180GgDfFm = 'BONIFICO_BANCARIO_180_GG_DF_FM',
  /** BONIFICO BANCARIO 120 GG DATA FATTURA */
  BonificoBancario_120GgDataFattura = 'BONIFICO_BANCARIO_120_GG_DATA_FATTURA',
  /** BONIFICO BANCARIO 15 GG DATA FATTURA FINE MESE */
  BonificoBancario_15GgDataFatturaFineMese = 'BONIFICO_BANCARIO_15_GG_DATA_FATTURA_FINE_MESE',
  /** BONIFICO BANCARIO 30 GG DATA FATTURA */
  BonificoBancario_30GgDataFattura = 'BONIFICO_BANCARIO_30_GG_DATA_FATTURA',
  /** BONIFICO BANCARIO 30 GG DF.FM. + 15 MESE SUCCESSIVO */
  BonificoBancario_30GgDfFm_15MeseSuccessivo = 'BONIFICO_BANCARIO_30_GG_DF_FM____15_MESE_SUCCESSIVO',
  /** BONIFICO BANCARIO 30/60 GG DF FM */
  BonificoBancario_30_60GgDfFm = 'BONIFICO_BANCARIO_30_60_GG_DF_FM',
  /** BONIFICO BANCARIO 60 GG DATA FATTURA */
  BonificoBancario_60GgDataFattura = 'BONIFICO_BANCARIO_60_GG_DATA_FATTURA',
  /** BONIFICO BANCARIO 90 GG DATA FATTURA */
  BonificoBancario_90GgDataFattura = 'BONIFICO_BANCARIO_90_GG_DATA_FATTURA',
  /** BONIFICO BANCARIO 90/120 GG DF.FM. */
  BonificoBancario_90_120GgDfFm = 'BONIFICO_BANCARIO_90_120_GG_DF_FM_',
  /** RICEVUTA BANCARIA 120 GG DF.FM. */
  RicevutaBancaria_120GgDfFm = 'RICEVUTA_BANCARIA_120_GG_DF_FM_',
  /** RICEVUTA BANCARIA 150 GG.DF FM */
  RicevutaBancaria_150GgDfFm = 'RICEVUTA_BANCARIA_150_GG_DF_FM',
  /** RICEVUTA BANCARIA 30/60/90 GG DF.FM. */
  RicevutaBancaria_30_60_90GgDfFm = 'RICEVUTA_BANCARIA_30_60_90_GG_DF_FM_',
  /** RICEVUTA BANCARIA 60/90 GG DF.FM. */
  RicevutaBancaria_60_90GgDfFm = 'RICEVUTA_BANCARIA_60_90_GG_DF_FM_',
  /** RIMESSA DIRETTA RICEVIMENTO FATTURA */
  RimessaDirettaRicevimentoFattura = 'RIMESSA_DIRETTA_RICEVIMENTO_FATTURA',
  /** FATTURE INFRAGRUPPO */
  FattureInfragruppo = 'FATTURE_INFRAGRUPPO'
}

/** A connection to a list of items. */
export type QuoteConnection = {
   __typename?: 'QuoteConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QuoteEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

/** An edge in a connection. */
export type QuoteEdge = {
   __typename?: 'QuoteEdge',
  /** The item at the end of the edge */
  node?: Maybe<Quote>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** {"description":"Quote Line Item","canCreateRoles":false,"canPatchRoles":false,"canDeleteRoles":false} */
export type QuoteLineItem = Node & {
   __typename?: 'QuoteLineItem',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"Quote Line Item ID","required":false,"readonly":true,"max":18} */
  Id?: Maybe<Scalars['String']>,
  /** {"description":"Deleted","required":false,"readonly":true} */
  IsDeleted?: Maybe<Scalars['Boolean']>,
  /** {"description":"Line Item Number","required":false,"readonly":true,"max":255} */
  LineNumber?: Maybe<Scalars['String']>,
  /** {"description":"Quote ID","required":true,"readonly":true} */
  Quote: Quote,
  /** {"description":"Price Book Entry ID","required":true,"readonly":true} */
  PricebookEntry: PricebookEntry,
  /** {"description":"Quantity","required":true} */
  Quantity: Scalars['Float'],
  /** {"description":"Sales Price","required":true,"max":2147483647,"min":-2147483647} */
  UnitPrice: Scalars['Int'],
  /** {"description":"Discount"} */
  Discount?: Maybe<Scalars['Float']>,
  /** {"description":"Line Item Description","max":255} */
  Description?: Maybe<Scalars['String']>,
  /** {"description":"Date","dtPrecision":"DAY_END"} */
  ServiceDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"Sort Order","readonly":true,"max":2147483647,"min":-2147483647} */
  SortOrder?: Maybe<Scalars['Int']>,
  /** {"description":"List Price","readonly":true,"max":2147483647,"min":-2147483647} */
  ListPrice?: Maybe<Scalars['Int']>,
  /** {"description":"Subtotal ","readonly":true,"max":2147483647,"min":-2147483647} */
  Subtotal?: Maybe<Scalars['Int']>,
  /** {"description":"Total Price","readonly":true,"max":2147483647,"min":-2147483647} */
  TotalPrice?: Maybe<Scalars['Int']>,
  /** {"description":"Issue","max":1300} */
  Mese_di_uscita?: Maybe<Scalars['String']>,
  /** {"description":"Anno","max":1300} */
  Anno?: Maybe<Scalars['String']>,
  /** {"description":"Tipo inserzione","max":1300} */
  Tipo_prodotto_del?: Maybe<Scalars['String']>,
  /** {"description":"Stato","max":1300} */
  Stato?: Maybe<Scalars['String']>,
  /** {"description":"Famiglia","max":1300} */
  Famiglia?: Maybe<Scalars['String']>,
  /** {"description":"Brand","max":1300} */
  Brand?: Maybe<Scalars['String']>,
  /** {"description":"IVA 22%"} */
  IVA_22?: Maybe<Scalars['Int']>,
  /** {"description":"Prezzo IVA inclusa"} */
  Prezzo_finale?: Maybe<Scalars['Int']>,
  /** {"description":"Cambia Stato Prodotto a Listino","max":255} */
  Cambia_Stato_Prodotto_a_Listino?: Maybe<Scalars['String']>,
  /** {"description":"Sconto"} */
  Sconto?: Maybe<Scalars['Float']>,
  /** {"description":"Provvigione agente"} */
  Provvigione_agente?: Maybe<Scalars['Float']>,
  /** {"description":"Prezzo di Listino","max":2147483647,"min":-2147483647} */
  Prezzo_di_listino?: Maybe<Scalars['Int']>,
  /** {"description":"Brand Area","max":1300} */
  Brand_Area?: Maybe<Scalars['String']>,
  /** {"description":"Tipologia","max":1300} */
  Tipologia?: Maybe<Scalars['String']>,
  /** {"description":"Anno Text QLI","max":4} */
  Anno_QLI?: Maybe<Scalars['String']>,
  /** {"description":"Brand TEXT QLI","max":100} */
  Brand_TEXT_QLI?: Maybe<Scalars['String']>,
  /** {"description":"Total Price NET-NET"} */
  Total_Price_NET_NET?: Maybe<Scalars['Int']>,
  /** {"description":"Listino del QLI","max":1300} */
  Listino_del_QLI?: Maybe<Scalars['String']>,
  /** {"description":"Listino del QLI TEXT","max":11} */
  Listino_del_QLI_TEXT?: Maybe<Scalars['String']>,
  /** {"description":"Data di Fatturazione","dtPrecision":"DAY_END"} */
  Data_di_Fatturazione?: Maybe<Scalars['DateTime']>,
  /** {"description":"Codice Conto Contabilità","max":1300} */
  Codice_Conto_Contabilit?: Maybe<Scalars['String']>,
  /** {"description":"Provvigione Capo Area"} */
  Provvigione_Capo_Area?: Maybe<Scalars['Float']>,
  /** {"description":"Data di Scadenza","dtPrecision":"DAY_END"} */
  Data_di_Scadenza?: Maybe<Scalars['DateTime']>,
  /** {"description":"isUnique","required":true} */
  IsUnique: Scalars['Boolean'],
  /** {"description":"N° Prodotti Esposti"} */
  Candidature?: Maybe<Scalars['Float']>,
  /** {"description":"Data Inizio Competenza","dtPrecision":"DAY_END"} */
  Data_Inizio_Competenza?: Maybe<Scalars['DateTime']>,
  /** {"description":"Data Fine Competenza","dtPrecision":"DAY_END"} */
  Data_Fine_Competenza?: Maybe<Scalars['DateTime']>,
  /** {"description":"Data di Pagamento","dtPrecision":"DAY_END"} */
  Data_di_Pagamento?: Maybe<Scalars['DateTime']>,
  /** {} */
  Abbonamento_Periodi: Array<AbbonamentoPeriodo>,
};

export type QuoteLineItemCreateInput = {
  /** {"description":"Deleted","required":false,"readonly":true} */
  IsDeleted?: Maybe<Scalars['Boolean']>,
  /** {"description":"Line Item Number","required":false,"readonly":true,"max":255} */
  LineNumber?: Maybe<Scalars['String']>,
  /** {"description":"Quote ID","required":true,"readonly":true} */
  Quote: RefInputObject,
  /** {"description":"Price Book Entry ID","required":true,"readonly":true} */
  PricebookEntry: RefInputObject,
  /** {"description":"Quantity","required":true} */
  Quantity: Scalars['Float'],
  /** {"description":"Sales Price","required":true,"max":2147483647,"min":-2147483647} */
  UnitPrice: Scalars['Int'],
  /** {"description":"Discount"} */
  Discount?: Maybe<Scalars['Float']>,
  /** {"description":"Line Item Description","max":255} */
  Description?: Maybe<Scalars['String']>,
  /** {"description":"Date","dtPrecision":"DAY_END"} */
  ServiceDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"Sort Order","readonly":true,"max":2147483647,"min":-2147483647} */
  SortOrder?: Maybe<Scalars['Int']>,
  /** {"description":"List Price","readonly":true,"max":2147483647,"min":-2147483647} */
  ListPrice?: Maybe<Scalars['Int']>,
  /** {"description":"Subtotal ","readonly":true,"max":2147483647,"min":-2147483647} */
  Subtotal?: Maybe<Scalars['Int']>,
  /** {"description":"Total Price","readonly":true,"max":2147483647,"min":-2147483647} */
  TotalPrice?: Maybe<Scalars['Int']>,
  /** {"description":"Cambia Stato Prodotto a Listino","max":255} */
  Cambia_Stato_Prodotto_a_Listino?: Maybe<Scalars['String']>,
  /** {"description":"Prezzo di Listino","max":2147483647,"min":-2147483647} */
  Prezzo_di_listino?: Maybe<Scalars['Int']>,
  /** {"description":"Anno Text QLI","max":4} */
  Anno_QLI?: Maybe<Scalars['String']>,
  /** {"description":"Brand TEXT QLI","max":100} */
  Brand_TEXT_QLI?: Maybe<Scalars['String']>,
  /** {"description":"Listino del QLI TEXT","max":11} */
  Listino_del_QLI_TEXT?: Maybe<Scalars['String']>,
  /** {"description":"Data di Fatturazione","dtPrecision":"DAY_END"} */
  Data_di_Fatturazione?: Maybe<Scalars['DateTime']>,
  /** {"description":"Data di Scadenza","dtPrecision":"DAY_END"} */
  Data_di_Scadenza?: Maybe<Scalars['DateTime']>,
  /** {"description":"Data Inizio Competenza","dtPrecision":"DAY_END"} */
  Data_Inizio_Competenza?: Maybe<Scalars['DateTime']>,
  /** {"description":"Data Fine Competenza","dtPrecision":"DAY_END"} */
  Data_Fine_Competenza?: Maybe<Scalars['DateTime']>,
  /** {"description":"Data di Pagamento","dtPrecision":"DAY_END"} */
  Data_di_Pagamento?: Maybe<Scalars['DateTime']>,
};

export type QuoteLineItemPatchInput = {
  /** {"description":"Quantity","required":true} */
  Quantity?: Maybe<Scalars['Float']>,
  /** {"description":"Sales Price","required":true,"max":2147483647,"min":-2147483647} */
  UnitPrice?: Maybe<Scalars['Int']>,
  /** {"description":"Discount"} */
  Discount?: Maybe<Scalars['Float']>,
  /** {"description":"Line Item Description","max":255} */
  Description?: Maybe<Scalars['String']>,
  /** {"description":"Date","dtPrecision":"DAY_END"} */
  ServiceDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"Cambia Stato Prodotto a Listino","max":255} */
  Cambia_Stato_Prodotto_a_Listino?: Maybe<Scalars['String']>,
  /** {"description":"Prezzo di Listino","max":2147483647,"min":-2147483647} */
  Prezzo_di_listino?: Maybe<Scalars['Int']>,
  /** {"description":"Anno Text QLI","max":4} */
  Anno_QLI?: Maybe<Scalars['String']>,
  /** {"description":"Brand TEXT QLI","max":100} */
  Brand_TEXT_QLI?: Maybe<Scalars['String']>,
  /** {"description":"Listino del QLI TEXT","max":11} */
  Listino_del_QLI_TEXT?: Maybe<Scalars['String']>,
  /** {"description":"Data di Fatturazione","dtPrecision":"DAY_END"} */
  Data_di_Fatturazione?: Maybe<Scalars['DateTime']>,
  /** {"description":"Data di Scadenza","dtPrecision":"DAY_END"} */
  Data_di_Scadenza?: Maybe<Scalars['DateTime']>,
  /** {"description":"Data Inizio Competenza","dtPrecision":"DAY_END"} */
  Data_Inizio_Competenza?: Maybe<Scalars['DateTime']>,
  /** {"description":"Data Fine Competenza","dtPrecision":"DAY_END"} */
  Data_Fine_Competenza?: Maybe<Scalars['DateTime']>,
  /** {"description":"Data di Pagamento","dtPrecision":"DAY_END"} */
  Data_di_Pagamento?: Maybe<Scalars['DateTime']>,
};

/** Ref to an existing object */
export type RefInputObject = {
  id: Scalars['ID'],
};

/** {"description":"Account di Retailer (può esserci anche il corrispondente in Company se fa PL)","canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type Retailer = Node & {
   __typename?: 'Retailer',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"Salesforce object ID","max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>,
  /** {"description":"@TODO"} */
  slug: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME","required":true,"max":80,"min":2} */
  name: Scalars['String'],
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>,
  /** {"readonly":true} */
  type: RetailerType,
  /** {"description":"@TODO DESCRIBE ME","required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'],
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>,
  /** {"description":"@TODO DESCRIBE ME","max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country: Country,
  /** {} */
  StateProvince?: Maybe<StateProvince>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","required":true} */
  city?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address1: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
  /** {"description":"Not actual User but intermediate object with user capabilities"} */
  managedByUsers: Array<ManagedByUser>,
  /** {"description":"Not actual News but intermediate object with count"} */
  newsCitations?: Maybe<Array<NewsCitation>>,
  /** {} */
  userObjectSubscriptions?: Maybe<Array<UserObjectSubscription>>,
  /** {} */
  userNotes?: Maybe<Array<UserNote>>,
};

/** A connection to a list of items. */
export type RetailerConnection = {
   __typename?: 'RetailerConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RetailerEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type RetailerCreateInput = {
  /** {"description":"@TODO DESCRIBE ME","required":true,"max":80,"min":2} */
  name: Scalars['String'],
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** {"readonly":true} */
  type: RetailerType,
  /** {"description":"@TODO DESCRIBE ME","required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'],
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>,
  /** {"description":"@TODO DESCRIBE ME","max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country: RefInputObject,
  /** {} */
  StateProvince?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","required":true} */
  city?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address1: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
};

/** An edge in a connection. */
export type RetailerEdge = {
   __typename?: 'RetailerEdge',
  /** The item at the end of the edge */
  node?: Maybe<Retailer>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type RetailerFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  producerChangedAfter?: Maybe<Scalars['DateTime']>,
  types?: Maybe<Array<RetailerType>>,
  Country?: Maybe<Array<RefInputObject>>,
};

export enum RetailerOrderBy {
  Name = 'name',
  Type = 'type'
}

export type RetailerPatchInput = {
  /** {"description":"@TODO DESCRIBE ME","required":true,"max":80,"min":2} */
  name?: Maybe<Scalars['String']>,
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>,
  /** {"description":"@TODO DESCRIBE ME","max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country?: Maybe<RefInputObject>,
  /** {} */
  StateProvince?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","required":true} */
  city?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address1?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
};

/** Retailer possible types */
export enum RetailerType {
  FsGAltri = 'FS_G_ALTRI',
  FsGBeverage = 'FS_G_BEVERAGE',
  FsGCaffeETe = 'FS_G_CAFFE_E_TE',
  FsGCarniESalumi = 'FS_G_CARNI_E_SALUMI',
  FsGDolciario = 'FS_G_DOLCIARIO',
  FsGGourmet = 'FS_G_GOURMET',
  FsGLatteEDerivati = 'FS_G_LATTE_E_DERIVATI',
  FsGOrtofrutta = 'FS_G_ORTOFRUTTA',
  FsGPesce = 'FS_G_PESCE',
  FsGSurgelati = 'FS_G_SURGELATI',
  RCC = 'R_C_C',
  RmaBroker = 'RMA_BROKER',
  RmaCedec = 'RMA_CEDEC',
  RmaCentraleDacquisto = 'RMA_CENTRALE_DACQUISTO',
  RmaConsorzio = 'RMA_CONSORZIO',
  RmaECommerce = 'RMA_E_COMMERCE',
  RmaGruppoInternazionale = 'RMA_GRUPPO_INTERNAZIONALE',
  RmaGruppoNazionale = 'RMA_GRUPPO_NAZIONALE',
  RmaGruppoRegionale = 'RMA_GRUPPO_REGIONALE',
  RmaImportatore = 'RMA_IMPORTATORE',
  RmaLandlord = 'RMA_LANDLORD',
  RmaWholesaler = 'RMA_WHOLESALER',
  RmsBroker = 'RMS_BROKER',
  RmsECommerce = 'RMS_E_COMMERCE',
  RmsGruppoNazionale = 'RMS_GRUPPO_NAZIONALE',
  RmsGruppoRegionale = 'RMS_GRUPPO_REGIONALE',
  RmsImportatore = 'RMS_IMPORTATORE',
  RmsLandlord = 'RMS_LANDLORD',
  RmsWholesaler = 'RMS_WHOLESALER',
  VDLocale = 'V_D_LOCALE',
  VDCentrale = 'V_D_CENTRALE'
}

/** {"description":"Usato per Ask the Company","canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA","BUYER":true,"BUYER_PL_ITALIA":true},"canPatchRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canSetStatusRoles":{"status":{"PENDING":{"INTERNAL":"EDITORIAL_BASE","PRODUCER":"EDIT"},"ACCEPTED":{"INTERNAL":"EDITORIAL_BASE","PRODUCER":"EDIT"},"REJECTED":{"INTERNAL":"EDITORIAL_BASE","PRODUCER":"EDIT"}}}} */
export type SampleRequest = Node & {
   __typename?: 'SampleRequest',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  targetSite: TargetSite,
  /** {"readonly":true} */
  Company: Company,
  /** {} */
  User: User,
  /** {} */
  Team?: Maybe<Team>,
  /** {} */
  Retailer?: Maybe<Retailer>,
  /** {} */
  status: SampleRequestStatus,
  /** {"description":"Data/ora di invio","dtPrecision":"FULL"} */
  sentDate: Scalars['DateTime'],
  /** {"required":true,"multiline":true,"max":500} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","required":true} */
  address1?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","required":true} */
  city?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  stateProvince?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","required":true} */
  country?: Maybe<Scalars['String']>,
};

/** A connection to a list of items. */
export type SampleRequestConnection = {
   __typename?: 'SampleRequestConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SampleRequestEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type SampleRequestCreateInput = {
  /** {"readonly":true} */
  Company: RefInputObject,
  /** {"required":true,"multiline":true,"max":500} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","required":true} */
  address1?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","required":true} */
  city?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  stateProvince?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","required":true} */
  country?: Maybe<Scalars['String']>,
};

/** An edge in a connection. */
export type SampleRequestEdge = {
   __typename?: 'SampleRequestEdge',
  /** The item at the end of the edge */
  node?: Maybe<SampleRequest>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type SampleRequestFilterInput = {
  Company?: Maybe<Array<RefInputObject>>,
  User?: Maybe<Array<RefInputObject>>,
  Team?: Maybe<Array<RefInputObject>>,
  Retailer?: Maybe<Array<RefInputObject>>,
  statuses?: Maybe<Array<SampleRequestStatus>>,
};

export type SampleRequestPatchInput = {
  /** {"required":true,"multiline":true,"max":500} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","required":true} */
  address1?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","required":true} */
  city?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  stateProvince?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","required":true} */
  country?: Maybe<Scalars['String']>,
};

/** SampleRequest's possible statues */
export enum SampleRequestStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED'
}

/** Filter by name */
export type SearchByNameInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
};

/** Filter by name, Company_id and/or Brand_id */
export type SearchGoodClassInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  Brand?: Maybe<RefInputObject>,
  Company?: Maybe<RefInputObject>,
};

/** {"description":"Account che fa servizi accessori","canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type Service = Node & {
   __typename?: 'Service',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"Salesforce object ID","max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>,
  /** {"description":"@TODO"} */
  slug: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME","required":true,"max":80,"min":2} */
  name: Scalars['String'],
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>,
  /** {"readonly":true} */
  type: ServiceType,
  /** {"description":"@TODO DESCRIBE ME","required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'],
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>,
  /** {"description":"@TODO DESCRIBE ME","max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country: Country,
  /** {} */
  StateProvince?: Maybe<StateProvince>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address1: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
  /** {"description":"Not actual User but intermediate object with user capabilities"} */
  managedByUsers: Array<ManagedByUser>,
  /** {"description":"Not actual News but intermediate object with count"} */
  newsCitations?: Maybe<Array<NewsCitation>>,
  /** {} */
  userObjectSubscriptions?: Maybe<Array<UserObjectSubscription>>,
  /** {} */
  userNotes?: Maybe<Array<UserNote>>,
};

/** A connection to a list of items. */
export type ServiceConnection = {
   __typename?: 'ServiceConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ServiceEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type ServiceCreateInput = {
  /** {"description":"@TODO DESCRIBE ME","required":true,"max":80,"min":2} */
  name: Scalars['String'],
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** {"readonly":true} */
  type: ServiceType,
  /** {"description":"@TODO DESCRIBE ME","required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'],
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>,
  /** {"description":"@TODO DESCRIBE ME","max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country: RefInputObject,
  /** {} */
  StateProvince?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address1: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
};

/** An edge in a connection. */
export type ServiceEdge = {
   __typename?: 'ServiceEdge',
  /** The item at the end of the edge */
  node?: Maybe<Service>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type ServiceFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  producerChangedAfter?: Maybe<Scalars['DateTime']>,
  types?: Maybe<Array<ServiceType>>,
  Country?: Maybe<Array<RefInputObject>>,
};

export enum ServiceOrderBy {
  Name = 'name',
  Type = 'type'
}

export type ServicePatchInput = {
  /** {"description":"@TODO DESCRIBE ME","required":true,"max":80,"min":2} */
  name?: Maybe<Scalars['String']>,
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  description?: Maybe<Scalars['String']>,
  /** 
 * {"description":"No richtext, al max bold/italic\nValidazione: limitare
   * maiuscolo (<30%) e lunghezza","multiline":true,"max":250}
 */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>,
  /** {"description":"richtext","max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>,
  /** {"description":"@TODO DESCRIBE ME","max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>,
  /** {} */
  Country?: Maybe<RefInputObject>,
  /** {} */
  StateProvince?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME","max":15} */
  postalCode?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  city?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address1?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  address2?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lat?: Maybe<Scalars['Float']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  lng?: Maybe<Scalars['Float']>,
};

/** Service possible types */
export enum ServiceType {
  AttrezzatureFoodService = 'ATTREZZATURE_FOOD_SERVICE',
  AttrezzatureRetail = 'ATTREZZATURE_RETAIL',
  CentroMedia = 'CENTRO_MEDIA',
  ConsulenzeFormazioneHr = 'CONSULENZE_FORMAZIONE_HR',
  Energia = 'ENERGIA',
  Fiere = 'FIERE',
  MarketingPromoz = 'MARKETING_PROMOZ',
  RealEstate = 'REAL_ESTATE',
  RicercheDiMercato = 'RICERCHE_DI_MERCATO',
  ScuoleEUniversita = 'SCUOLE_E_UNIVERSITA',
  ServiziFinanziari = 'SERVIZI_FINANZIARI',
  ServiziInformatici = 'SERVIZI_INFORMATICI',
  ServiziLegali = 'SERVIZI_LEGALI',
  ServiziLogistici = 'SERVIZI_LOGISTICI',
  Telecomunicazioni = 'TELECOMUNICAZIONI'
}

/** Possible SocialTypes */
export enum SocialType {
  Google = 'GOOGLE',
  Linkedin = 'LINKEDIN',
  Facebook = 'FACEBOOK'
}

/** {"description":"@TODO DESCRIBE ME"} */
export type StateProvince = Node & {
   __typename?: 'StateProvince',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  nameIta: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME","max":10} */
  code?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  region?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  regionIta?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":4,"min":1} */
  nielsenArea?: Maybe<Scalars['Int']>,
  /** {} */
  Country: Country,
};

/** A connection to a list of items. */
export type StateProvinceConnection = {
   __typename?: 'StateProvinceConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StateProvinceEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

/** An edge in a connection. */
export type StateProvinceEdge = {
   __typename?: 'StateProvinceEdge',
  /** The item at the end of the edge */
  node?: Maybe<StateProvince>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type StateProvinceFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  /** Contained in nameIta */
  nameIta?: Maybe<Scalars['String']>,
  /** Contained in region */
  region?: Maybe<Scalars['String']>,
  /** Contained in regionIta */
  regionIta?: Maybe<Scalars['String']>,
  nielsenArea?: Maybe<Scalars['Int']>,
  Country?: Maybe<RefInputObject>,
};

export enum StateProvinceOrderByEnum {
  Name = 'name',
  NameIta = 'nameIta',
  Region = 'region',
  RegionIta = 'regionIta',
  NielsenArea = 'nielsenArea',
  Country = 'Country'
}

export type Subscription = {
   __typename?: 'Subscription',
  changed?: Maybe<Change>,
};

export enum TargetMedia {
  Website = 'WEBSITE',
  Newsletter = 'NEWSLETTER'
}

export enum TargetSite {
  Ifn = 'IFN',
  Hub = 'HUB',
  Vote = 'VOTE',
  Foodweb = 'FOODWEB',
  Foodserviceweb = 'FOODSERVICEWEB',
  Newsletter = 'NEWSLETTER'
}

/** {"description":"Condivide UserNote e feedback","canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type Team = Node & {
   __typename?: 'Team',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {} */
  Retailer?: Maybe<Retailer>,
  /** {} */
  users: Array<User>,
};

/** A connection to a list of items. */
export type TeamConnection = {
   __typename?: 'TeamConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TeamEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type TeamCreateInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {} */
  Retailer?: Maybe<RefInputObject>,
};

/** An edge in a connection. */
export type TeamEdge = {
   __typename?: 'TeamEdge',
  /** The item at the end of the edge */
  node?: Maybe<Team>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type TeamPatchInput = {
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {} */
  Retailer?: Maybe<RefInputObject>,
};

/** {"description":"Storico dei terms of Service","canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type ToS = Node & {
   __typename?: 'ToS',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"Internal use only"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_BEGIN"} */
  startDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionExtra?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionExtraIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionChanges?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionChangesIta?: Maybe<Scalars['String']>,
};

/** A connection to a list of items. */
export type ToSConnection = {
   __typename?: 'ToSConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ToSEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type ToSCreateInput = {
  /** {"description":"Internal use only"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_BEGIN"} */
  startDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionExtra?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionExtraIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionChanges?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionChangesIta?: Maybe<Scalars['String']>,
};

/** An edge in a connection. */
export type ToSEdge = {
   __typename?: 'ToSEdge',
  /** The item at the end of the edge */
  node?: Maybe<ToS>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type ToSPatchInput = {
  /** {"description":"Internal use only"} */
  name?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_BEGIN"} */
  startDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  description?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionExtra?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionExtraIta?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionChanges?: Maybe<Scalars['String']>,
  /** {"description":"richtext","html":true,"htmlLevel":"FULL"} */
  descriptionChangesIta?: Maybe<Scalars['String']>,
};

/** Transformation levels */
export enum TransformationLevel {
  Unprepared = 'UNPREPARED',
  Prepared = 'PREPARED',
  PreparedNotReadyToEat = 'PREPARED_NOT_READY_TO_EAT',
  PreparedReadyToEat = 'PREPARED_READY_TO_EAT'
}

/** {"description":"Typeform usato per sondaggi o altro","canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"}} */
export type Typeform = Node & {
   __typename?: 'Typeform',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  targetSite: TargetSite,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {} */
  TypeformId?: Maybe<Scalars['String']>,
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'],
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>,
  /** {"dtPrecision":"DAY_END"} */
  resultsStartDate?: Maybe<Scalars['DateTime']>,
  /** {"dtPrecision":"DAY_END"} */
  resultsExpirationDate?: Maybe<Scalars['DateTime']>,
};

/** A connection to a list of items. */
export type TypeformConnection = {
   __typename?: 'TypeformConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TypeformEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type TypeformCreateInput = {
  /** {} */
  targetSite: TargetSite,
  /** {"description":"@TODO DESCRIBE ME"} */
  name: Scalars['String'],
  /** {} */
  TypeformId?: Maybe<Scalars['String']>,
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'],
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>,
  /** {"dtPrecision":"DAY_END"} */
  resultsStartDate?: Maybe<Scalars['DateTime']>,
  /** {"dtPrecision":"DAY_END"} */
  resultsExpirationDate?: Maybe<Scalars['DateTime']>,
};

/** An edge in a connection. */
export type TypeformEdge = {
   __typename?: 'TypeformEdge',
  /** The item at the end of the edge */
  node?: Maybe<Typeform>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type TypeformFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  isOpen?: Maybe<Scalars['Boolean']>,
  hasOpenResults?: Maybe<Scalars['Boolean']>,
  targetSite?: Maybe<TargetSite>,
};

export type TypeformPatchInput = {
  /** {} */
  targetSite?: Maybe<TargetSite>,
  /** {"description":"@TODO DESCRIBE ME"} */
  name?: Maybe<Scalars['String']>,
  /** {} */
  TypeformId?: Maybe<Scalars['String']>,
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate?: Maybe<Scalars['DateTime']>,
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>,
  /** {"dtPrecision":"DAY_END"} */
  resultsStartDate?: Maybe<Scalars['DateTime']>,
  /** {"dtPrecision":"DAY_END"} */
  resultsExpirationDate?: Maybe<Scalars['DateTime']>,
};



/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":true},"canPatchRoles":{"INTERNAL":true},"canDeleteRoles":{"INTERNAL":true},"canSetStatusRoles":{"status":{"NOT_VERIFIED":{"INTERNAL":true},"WAITING_REVIEW":{"INTERNAL":true},"ACTIVE":{"INTERNAL":true},"DISABLED":{"INTERNAL":true},"DELETED":{"INTERNAL":true}}}} */
export type User = Node & {
   __typename?: 'User',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"Salesforce object ID","max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>,
  /** {} */
  Contact?: Maybe<Contact>,
  /** {"description":"CampaignMember of userContact"} */
  campaignMemberships: Array<CampaignMember>,
  /** {"description":"First Name"} */
  firstName?: Maybe<Scalars['String']>,
  /** {"description":"Last Name"} */
  lastName?: Maybe<Scalars['String']>,
  /** {"description":"Full Name or email"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME","readonly":true,"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  email: Scalars['Email'],
  /** {} */
  socialType?: Maybe<SocialType>,
  /** {} */
  role: UserRole,
  /** {} */
  status: UserStatus,
  /** {} */
  approvedToS?: Maybe<ToS>,
  /** {} */
  newsletterAccepted: Array<NewsletterAcceptedFlag>,
  /** 
 * {"description":"Usato per EmailDomain + Le comunicazioni di default le mando
   * qui e se non è valorizzato a User.email","formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"}
 */
  workEmail?: Maybe<Scalars['Email']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  langCode?: Maybe<Scalars['String']>,
  /** {} */
  internalCapabilities: Array<InternalCapability>,
  /** {"description":"Creation datetime","dtPrecision":"FULL"} */
  createdOn?: Maybe<Scalars['DateTime']>,
  /** {"description":"Last login datetime","dtPrecision":"FULL"} */
  lastLogin?: Maybe<Scalars['DateTime']>,
  /** {} */
  Team?: Maybe<Team>,
  /** {"description":"User achievements"} */
  userAchievements: Array<UserAchievement>,
  /** {"description":"Not actual Company/CompanyGroup/Service but intermediate object with user capabilities"} */
  managedByUsers: Array<ManagedByUser>,
  /** {} */
  Prospect?: Maybe<Prospect>,
};


/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":true},"canPatchRoles":{"INTERNAL":true},"canDeleteRoles":{"INTERNAL":true},"canSetStatusRoles":{"status":{"NOT_VERIFIED":{"INTERNAL":true},"WAITING_REVIEW":{"INTERNAL":true},"ACTIVE":{"INTERNAL":true},"DISABLED":{"INTERNAL":true},"DELETED":{"INTERNAL":true}}}} */
export type UserCampaignMembershipsArgs = {
  campaigns?: Maybe<Array<RefInputObject>>,
  esiti?: Maybe<Array<CampaignMember_Esito_Campaign__C_Enum>>
};


/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":true},"canPatchRoles":{"INTERNAL":true},"canDeleteRoles":{"INTERNAL":true},"canSetStatusRoles":{"status":{"NOT_VERIFIED":{"INTERNAL":true},"WAITING_REVIEW":{"INTERNAL":true},"ACTIVE":{"INTERNAL":true},"DISABLED":{"INTERNAL":true},"DELETED":{"INTERNAL":true}}}} */
export type UserUserAchievementsArgs = {
  achievements?: Maybe<Array<Scalars['String']>>
};


/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":true},"canPatchRoles":{"INTERNAL":true},"canDeleteRoles":{"INTERNAL":true},"canSetStatusRoles":{"status":{"NOT_VERIFIED":{"INTERNAL":true},"WAITING_REVIEW":{"INTERNAL":true},"ACTIVE":{"INTERNAL":true},"DISABLED":{"INTERNAL":true},"DELETED":{"INTERNAL":true}}}} */
export type UserManagedByUsersArgs = {
  currentOnly?: Maybe<Scalars['Boolean']>
};

/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":true,"PRODUCER":true,"BUYER":true,"BUYER_PL_ITALIA":true,"CONSUMER":true}} */
export type UserAchievement = Node & {
   __typename?: 'UserAchievement',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME (reference)"} */
  User?: Maybe<User>,
  /** {} */
  achievement: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME"} */
  timestamp: Scalars['DateTime'],
};

/** A connection to a list of items. */
export type UserAchievementConnection = {
   __typename?: 'UserAchievementConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserAchievementEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type UserAchievementCreateInput = {
  /** {} */
  achievement: Scalars['String'],
};

/** An edge in a connection. */
export type UserAchievementEdge = {
   __typename?: 'UserAchievementEdge',
  /** The item at the end of the edge */
  node?: Maybe<UserAchievement>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type UserAchievementFilterInput = {
  User?: Maybe<Array<RefInputObject>>,
  achievements?: Maybe<Array<Scalars['String']>>,
  timespanMin?: Maybe<Scalars['DateTime']>,
  timespanMax?: Maybe<Scalars['DateTime']>,
};

/** A connection to a list of items. */
export type UserConnection = {
   __typename?: 'UserConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type UserCreateInput = {
  /** {"description":"First Name"} */
  firstName?: Maybe<Scalars['String']>,
  /** {"description":"Last Name"} */
  lastName?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","readonly":true,"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  email: Scalars['Email'],
  /** {} */
  role: UserRole,
  /** {} */
  newsletterAccepted?: Maybe<Array<NewsletterAcceptedFlag>>,
  /** {} */
  internalCapabilities?: Maybe<Array<InternalCapability>>,
  /** {} */
  Team?: Maybe<RefInputObject>,
};

/** An edge in a connection. */
export type UserEdge = {
   __typename?: 'UserEdge',
  /** The item at the end of the edge */
  node?: Maybe<User>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type UserFilterInput = {
  /** Contained in Full Name */
  name?: Maybe<Scalars['String']>,
  /** Contained in Full Name or E-mail or Work-email */
  nameOrEmail?: Maybe<Scalars['String']>,
  /** Contained in E-mail or Work-email */
  email?: Maybe<Scalars['String']>,
  roles?: Maybe<Array<UserRole>>,
  statuses?: Maybe<Array<UserStatus>>,
};

/** {"description":"User/Team-Object linking table + vote, note, etc.","canCreateRoles":{"INTERNAL":"MARKETING_EXTRA","PRODUCER":"MANAGE","CONSUMER":true,"BUYER":true,"BUYER_PL_ITALIA":true,"ANONYMUS":true},"canPatchRoles":{"INTERNAL":"MARKETING_EXTRA","PRODUCER":"MANAGE","CONSUMER":true,"BUYER":true,"BUYER_PL_ITALIA":true},"canDeleteRoles":{"INTERNAL":"MARKETING_EXTRA","PRODUCER":"MANAGE","CONSUMER":true,"BUYER":true,"BUYER_PL_ITALIA":true}} */
export type UserNote = Node & {
   __typename?: 'UserNote',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  User: User,
  /** {} */
  Team?: Maybe<Team>,
  /** {"description":"Se non è condivisa con il Team"} */
  isPrivate: Scalars['Boolean'],
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  vote?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  note?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"FULL"} */
  timestamp: Scalars['DateTime'],
  /** 
 * {"description":"Usato per note condivise quando chi le ha fatte lascia il
   * Team: lui non le vede più, ma ne ha un Clone privato"}
 */
  isAbandoned: Scalars['Boolean'],
  /** {} */
  Company?: Maybe<Company>,
  /** {} */
  Good?: Maybe<Good>,
  /** {} */
  Retailer?: Maybe<Retailer>,
  /** {} */
  CompanyGroup?: Maybe<CompanyGroup>,
  /** {} */
  Service?: Maybe<Service>,
};

/** User note possible collections */
export enum UserNoteCollection {
  Company = 'Company',
  Good = 'Good',
  Retailer = 'Retailer',
  CompanyGroup = 'CompanyGroup',
  Service = 'Service'
}

/** A connection to a list of items. */
export type UserNoteConnection = {
   __typename?: 'UserNoteConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserNoteEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type UserNoteCreateInput = {
  /** {"description":"Se non è condivisa con il Team"} */
  isPrivate: Scalars['Boolean'],
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  vote?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  note?: Maybe<Scalars['String']>,
  /** {} */
  Company?: Maybe<RefInputObject>,
  /** {} */
  Good?: Maybe<RefInputObject>,
  /** {} */
  Retailer?: Maybe<RefInputObject>,
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>,
  /** {} */
  Service?: Maybe<RefInputObject>,
  workEmail?: Maybe<Scalars['Email']>,
};

/** An edge in a connection. */
export type UserNoteEdge = {
   __typename?: 'UserNoteEdge',
  /** The item at the end of the edge */
  node?: Maybe<UserNote>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type UserNoteFilterInput = {
  isPrivate?: Maybe<Scalars['Boolean']>,
  isAbandoned?: Maybe<Scalars['Boolean']>,
  User?: Maybe<RefInputObject>,
  Team?: Maybe<RefInputObject>,
  collection?: Maybe<UserNoteCollection>,
  Object?: Maybe<RefInputObject>,
  timestampMin?: Maybe<Scalars['DateTime']>,
  timestampMax?: Maybe<Scalars['DateTime']>,
};

export type UserNotePatchInput = {
  /** {"description":"Se non è condivisa con il Team"} */
  isPrivate?: Maybe<Scalars['Boolean']>,
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  vote?: Maybe<Scalars['Int']>,
  /** {"description":"@TODO DESCRIBE ME"} */
  note?: Maybe<Scalars['String']>,
  /** {} */
  Company?: Maybe<RefInputObject>,
  /** {} */
  Good?: Maybe<RefInputObject>,
  /** {} */
  Retailer?: Maybe<RefInputObject>,
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>,
  /** {} */
  Service?: Maybe<RefInputObject>,
};

/** {"description":"User/Team-Object linking table + thumb, note, etc.","canCreateRoles":{"INTERNAL":true,"PRODUCER":true,"BUYER":true,"BUYER_PL_ITALIA":true,"CONSUMER":true},"canPatchRoles":{"INTERNAL":true,"PRODUCER":true,"BUYER":true,"BUYER_PL_ITALIA":true,"CONSUMER":true},"canDeleteRoles":{"INTERNAL":true,"PRODUCER":true,"BUYER":true,"BUYER_PL_ITALIA":true,"CONSUMER":true}} */
export type UserObjectSubscription = Node & {
   __typename?: 'UserObjectSubscription',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {} */
  User: User,
  /** {} */
  flags: Array<UserObjectSubscriptionFlag>,
  /** {} */
  Company?: Maybe<Company>,
  /** {} */
  Good?: Maybe<Good>,
  /** {} */
  Retailer?: Maybe<Retailer>,
  /** {} */
  CompanyGroup?: Maybe<CompanyGroup>,
  /** {} */
  Service?: Maybe<Service>,
};

/** A connection to a list of items. */
export type UserObjectSubscriptionConnection = {
   __typename?: 'UserObjectSubscriptionConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserObjectSubscriptionEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type UserObjectSubscriptionCreateInput = {
  /** {} */
  flags?: Maybe<Array<UserObjectSubscriptionFlag>>,
  /** {} */
  Company?: Maybe<RefInputObject>,
  /** {} */
  Good?: Maybe<RefInputObject>,
  /** {} */
  Retailer?: Maybe<RefInputObject>,
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>,
  /** {} */
  Service?: Maybe<RefInputObject>,
};

/** An edge in a connection. */
export type UserObjectSubscriptionEdge = {
   __typename?: 'UserObjectSubscriptionEdge',
  /** The item at the end of the edge */
  node?: Maybe<UserObjectSubscription>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type UserObjectSubscriptionFilterInput = {
  User?: Maybe<RefInputObject>,
  flags?: Maybe<Array<UserObjectSubscriptionFlag>>,
  collection?: Maybe<UserNoteCollection>,
  Object?: Maybe<RefInputObject>,
};

/** // TODO */
export enum UserObjectSubscriptionFlag {
  Bookmark = 'BOOKMARK',
  Follow = 'FOLLOW'
}

export type UserObjectSubscriptionPatchInput = {
  /** {} */
  flags?: Maybe<Array<UserObjectSubscriptionFlag>>,
  /** {} */
  Company?: Maybe<RefInputObject>,
  /** {} */
  Good?: Maybe<RefInputObject>,
  /** {} */
  Retailer?: Maybe<RefInputObject>,
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>,
  /** {} */
  Service?: Maybe<RefInputObject>,
};

export enum UserOrderBy {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Status = 'status',
  Role = 'role',
  CreatedOn = 'createdOn',
  LastLogin = 'lastLogin'
}

export type UserPatchInput = {
  /** {"description":"First Name"} */
  firstName?: Maybe<Scalars['String']>,
  /** {"description":"Last Name"} */
  lastName?: Maybe<Scalars['String']>,
  /** {} */
  role?: Maybe<UserRole>,
  /** {} */
  newsletterAccepted?: Maybe<Array<NewsletterAcceptedFlag>>,
  /** {} */
  internalCapabilities?: Maybe<Array<InternalCapability>>,
  /** {} */
  Team?: Maybe<RefInputObject>,
};

/** User possible roles */
export enum UserRole {
  Anonymus = 'ANONYMUS',
  Internal = 'INTERNAL',
  Producer = 'PRODUCER',
  Buyer = 'BUYER',
  BuyerPlItalia = 'BUYER_PL_ITALIA',
  Consumer = 'CONSUMER'
}

/** User statuses */
export enum UserStatus {
  NotVerified = 'NOT_VERIFIED',
  WaitingReview = 'WAITING_REVIEW',
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Deleted = 'DELETED'
}

/** User possible relations used for visibility */
export enum VisibilityRelations {
  LinkedToFair = 'LINKED_TO_FAIR',
  LinkedToAwardCandidacy = 'LINKED_TO_AWARD_CANDIDACY',
  LinkedToAwardEvent = 'LINKED_TO_AWARD_EVENT',
  LinkedToAwardVote = 'LINKED_TO_AWARD_VOTE',
  LinkedToCompany = 'LINKED_TO_COMPANY',
  LinkedToGeoIndication = 'LINKED_TO_GEO_INDICATION',
  TargetForFair = 'TARGET_FOR_FAIR',
  TargetForAwardCandidacy = 'TARGET_FOR_AWARD_CANDIDACY',
  TargetForAwardEvent = 'TARGET_FOR_AWARD_EVENT',
  TargetForAwardVote = 'TARGET_FOR_AWARD_VOTE',
  TargetForCompany = 'TARGET_FOR_COMPANY',
  TargetForGeoIndication = 'TARGET_FOR_GEO_INDICATION'
}

/** User possible roles used for visibility */
export enum VisibilityRole {
  AnonymusOnly = 'ANONYMUS_ONLY',
  UserOnly = 'USER_ONLY',
  ProducerOnly = 'PRODUCER_ONLY',
  BuyerOnly = 'BUYER_ONLY'
}

export enum VisibilityType {
  Anonymus = 'ANONYMUS',
  User = 'USER',
  Typeform = 'TYPEFORM',
  SfItem = 'SF_ITEM',
  SfCampaign = 'SF_CAMPAIGN',
  SfCampaignPresente = 'SF_CAMPAIGN_PRESENTE'
}

/** {"description":"@TODO DESCRIBE ME","canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type Widget = Node & {
   __typename?: 'Widget',
  /** {"description":"The ID of an object"} */
  id: Scalars['ID'],
  /** {"description":"@TODO DESCRIBE ME"} */
  localId?: Maybe<Scalars['Int']>,
  /** {"description":"Solo uso interno"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'],
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>,
  /** {} */
  targetSite: TargetSite,
  /** {} */
  locations: Array<WidgetLocation>,
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  orderPriority: Scalars['Int'],
  /** {} */
  gridSize: GridSize,
  /** {} */
  styleType: WidgetStyleType,
  /** {} */
  styleColor: WidgetStyleColor,
  /** {"description":"For style (color/icon)"} */
  styleEditorialSection?: Maybe<EditorialSection>,
  /** {"description":"@TODO DESCRIBE ME"} */
  styleAdditionalClasses?: Maybe<Scalars['String']>,
  /** {"description":"Base html (strong/..)","html":true,"htmlLevel":"FULL"} */
  title?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":255} */
  titleUrl?: Maybe<Scalars['URL']>,
  /** {"description":"Base html (strong/..)","html":true,"htmlLevel":"FULL"} */
  footer?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":255} */
  footerUrl?: Maybe<Scalars['URL']>,
  /** {"description":"NULL for dynamic content\nHTML for static content","html":true,"htmlLevel":"FULL"} */
  body?: Maybe<Scalars['String']>,
  /** {"description":"GraphQL query for body rendering","multiline":true} */
  query?: Maybe<Scalars['String']>,
  /** {"description":"Javascript path in query result to extract entities"} */
  entitiesPath?: Maybe<Scalars['String']>,
  /** {"description":"title or title rendered with query","html":true,"htmlLevel":"FULL"} */
  titleRendered?: Maybe<Scalars['String']>,
  /** {"description":"footer or footer rendered with query","html":true,"htmlLevel":"FULL"} */
  footerRendered?: Maybe<Scalars['String']>,
  /** {"description":"body or body rendered with query","html":true,"htmlLevel":"FULL"} */
  bodyRendered?: Maybe<Scalars['String']>,
  /** {"description":"entities extracted from query using entitiesPath"} */
  entities?: Maybe<Array<Node>>,
  /** {"description":"type of entities extracted from query using entitiesPath"} */
  entitiesType?: Maybe<Scalars['String']>,
  /** {} */
  visibilityByRole?: Maybe<VisibilityRole>,
  /** {} */
  visibilityByRelation?: Maybe<VisibilityRelations>,
  /** {} */
  visibilityByMinLevel?: Maybe<CompanyLevelEnum>,
  /** {} */
  visibilityByMaxLevel?: Maybe<CompanyLevelEnum>,
  /** {} */
  visibilityByMinCapability?: Maybe<Capability>,
  /** {} */
  Company?: Maybe<Company>,
  /** {} */
  GeoIndication?: Maybe<GeoIndication>,
  /** {} */
  Fair?: Maybe<Fair>,
  /** {} */
  Award?: Maybe<Award>,
};

/** A connection to a list of items. */
export type WidgetConnection = {
   __typename?: 'WidgetConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WidgetEdge>>>,
  total?: Maybe<Scalars['Int']>,
};

export type WidgetCreateInput = {
  /** {"description":"Solo uso interno"} */
  name: Scalars['String'],
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'],
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>,
  /** {} */
  targetSite: TargetSite,
  /** {} */
  locations?: Maybe<Array<WidgetLocation>>,
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  orderPriority: Scalars['Int'],
  /** {} */
  gridSize: GridSize,
  /** {} */
  styleType: WidgetStyleType,
  /** {} */
  styleColor: WidgetStyleColor,
  /** {"description":"For style (color/icon)"} */
  styleEditorialSection?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME"} */
  styleAdditionalClasses?: Maybe<Scalars['String']>,
  /** {"description":"Base html (strong/..)","html":true,"htmlLevel":"FULL"} */
  title?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":255} */
  titleUrl?: Maybe<Scalars['URL']>,
  /** {"description":"Base html (strong/..)","html":true,"htmlLevel":"FULL"} */
  footer?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":255} */
  footerUrl?: Maybe<Scalars['URL']>,
  /** {"description":"NULL for dynamic content\nHTML for static content","html":true,"htmlLevel":"FULL"} */
  body?: Maybe<Scalars['String']>,
  /** {"description":"GraphQL query for body rendering","multiline":true} */
  query?: Maybe<Scalars['String']>,
  /** {"description":"Javascript path in query result to extract entities"} */
  entitiesPath?: Maybe<Scalars['String']>,
  /** {} */
  visibilityByRole?: Maybe<VisibilityRole>,
  /** {} */
  visibilityByRelation?: Maybe<VisibilityRelations>,
  /** {} */
  visibilityByMinLevel?: Maybe<CompanyLevelEnum>,
  /** {} */
  visibilityByMaxLevel?: Maybe<CompanyLevelEnum>,
  /** {} */
  visibilityByMinCapability?: Maybe<Capability>,
  /** {} */
  Company?: Maybe<RefInputObject>,
  /** {} */
  GeoIndication?: Maybe<RefInputObject>,
  /** {} */
  Fair?: Maybe<RefInputObject>,
  /** {} */
  Award?: Maybe<RefInputObject>,
};

/** An edge in a connection. */
export type WidgetEdge = {
   __typename?: 'WidgetEdge',
  /** The item at the end of the edge */
  node?: Maybe<Widget>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** @TODO DESCRIBE ME */
export type WidgetFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>,
  /** Started and not expired at */
  targetDate?: Maybe<Scalars['DateTime']>,
  targetSite?: Maybe<TargetSite>,
  location?: Maybe<WidgetLocation>,
  Company?: Maybe<RefInputObject>,
  GeoIndication?: Maybe<RefInputObject>,
  Fair?: Maybe<RefInputObject>,
  Award?: Maybe<RefInputObject>,
};

/** // TODO */
export enum WidgetLocation {
  Homepage = 'HOMEPAGE',
  Dashboard = 'DASHBOARD',
  EditorialSection = 'EDITORIAL_SECTION',
  FairDirectory = 'FAIR_DIRECTORY',
  SupplierDirectory = 'SUPPLIER_DIRECTORY',
  ProductDirectory = 'PRODUCT_DIRECTORY'
}

export enum WidgetOrderBy {
  Name = 'name',
  StartDate = 'startDate',
  ExpirationDate = 'expirationDate',
  OrderPriority = 'orderPriority'
}

export type WidgetPatchInput = {
  /** {"description":"Solo uso interno"} */
  name?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_BEGIN"} */
  startDate?: Maybe<Scalars['DateTime']>,
  /** {"description":"@TODO DESCRIBE ME","dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>,
  /** {} */
  targetSite?: Maybe<TargetSite>,
  /** {} */
  locations?: Maybe<Array<WidgetLocation>>,
  /** {"description":"@TODO DESCRIBE ME","max":2147483647,"min":-2147483647} */
  orderPriority?: Maybe<Scalars['Int']>,
  /** {} */
  gridSize?: Maybe<GridSize>,
  /** {} */
  styleType?: Maybe<WidgetStyleType>,
  /** {} */
  styleColor?: Maybe<WidgetStyleColor>,
  /** {"description":"For style (color/icon)"} */
  styleEditorialSection?: Maybe<RefInputObject>,
  /** {"description":"@TODO DESCRIBE ME"} */
  styleAdditionalClasses?: Maybe<Scalars['String']>,
  /** {"description":"Base html (strong/..)","html":true,"htmlLevel":"FULL"} */
  title?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":255} */
  titleUrl?: Maybe<Scalars['URL']>,
  /** {"description":"Base html (strong/..)","html":true,"htmlLevel":"FULL"} */
  footer?: Maybe<Scalars['String']>,
  /** {"description":"@TODO DESCRIBE ME","max":255} */
  footerUrl?: Maybe<Scalars['URL']>,
  /** {"description":"NULL for dynamic content\nHTML for static content","html":true,"htmlLevel":"FULL"} */
  body?: Maybe<Scalars['String']>,
  /** {"description":"GraphQL query for body rendering","multiline":true} */
  query?: Maybe<Scalars['String']>,
  /** {"description":"Javascript path in query result to extract entities"} */
  entitiesPath?: Maybe<Scalars['String']>,
  /** {} */
  visibilityByRole?: Maybe<VisibilityRole>,
  /** {} */
  visibilityByRelation?: Maybe<VisibilityRelations>,
  /** {} */
  visibilityByMinLevel?: Maybe<CompanyLevelEnum>,
  /** {} */
  visibilityByMaxLevel?: Maybe<CompanyLevelEnum>,
  /** {} */
  visibilityByMinCapability?: Maybe<Capability>,
  /** {} */
  Company?: Maybe<RefInputObject>,
  /** {} */
  GeoIndication?: Maybe<RefInputObject>,
  /** {} */
  Fair?: Maybe<RefInputObject>,
  /** {} */
  Award?: Maybe<RefInputObject>,
};

/** // TODO */
export enum WidgetStyleColor {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Alt = 'ALT',
  Alt2 = 'ALT2',
  ByEditorialSection = 'BY_EDITORIAL_SECTION',
  Gradient1 = 'GRADIENT1',
  Gradient2 = 'GRADIENT2'
}

/** // TODO */
export enum WidgetStyleType {
  Bordered = 'BORDERED',
  Unboxed = 'UNBOXED',
  Magazine = 'MAGAZINE',
  Big_4Small = 'BIG_4SMALL',
  Carusel = 'CARUSEL'
}

/** @TODO DESCRIBE ME */
export type WidgetViewInput = {
  locations?: Maybe<Array<WidgetLocation>>,
};
