export enum PaymentLevels {
	Hidden = 'HIDDEN',
	Free = 'FREE',
	PaidL1 = 'PAID_L1',
	PaidL2 = 'PAID_L2',
	PaidL3 = 'PAID_L3',
	PaidL4 = 'PAID_L4'
}

export enum CompanyDirType {
	Company = 'Company',
	Brand = 'Brand',
}
