import React from 'react';
import { ACTIONS, generateUrl } from '../../utils/urls';
import { ENTITY } from '../../utils/entities';
import { GenericHead, jsonldStringEscape } from './GenericHead';
import { Good, FinalPosition, CompanyLevelEnum } from '../../server-types';

interface IGoodDetailHeadProps {
	entity: Good;
}

const finalPosition2rating: {
	[fp in FinalPosition]: number;
} = {
	[FinalPosition.Winner]: 10,
	[FinalPosition.Second]: 9,
	[FinalPosition.Third]: 8,
};

const level2rating: {
	[level in CompanyLevelEnum]: number;
} = {
	[CompanyLevelEnum.PaidL4]: 8,
	[CompanyLevelEnum.PaidL3]: 8,
	[CompanyLevelEnum.PaidL2]: 8,
	[CompanyLevelEnum.PaidL1]: 7,
	[CompanyLevelEnum.Free]: 6,
	[CompanyLevelEnum.Hidden]: 5,
};

/**
 * Do un voto al prodotto in base a
 * il livello dell'azienda (valutazione nostra)
 * ed eventuali risultati nei premi
 * @param entity
 */
function getAggregateRating(
	entity: Good,
): {
	ratingCount: number;
	reviewCount: number;
	ratingValue: number;
	bestRating: number;
	worstRating: number;
} {
	const finalPos: FinalPosition[] = [];
	entity.candidacies.forEach((candidacy) => {
		const res = candidacy.awardResults;
		res.forEach((result) => {
			if (result.finalPosition) {
				finalPos.push(result.finalPosition);
			}
		});
	});

	const level: CompanyLevelEnum =
		entity.Brand &&
		entity.Brand.Company.currentCompanyLevels &&
		entity.Brand.Company.currentCompanyLevels[0]
			? entity.Brand.Company.currentCompanyLevels[0].level
			: CompanyLevelEnum.Free;
	const ratings = [
		level2rating[level],
		...finalPos.map<number>((fp) => finalPosition2rating[fp]),
	];
	return {
		ratingCount: ratings.length,
		reviewCount: ratings.length,
		ratingValue: ratings.reduce((sum, r) => sum + r) / ratings.length,
		bestRating: 10,
		worstRating: 1,
	};
}

export const GoodDetailHead: React.FC<IGoodDetailHeadProps> = ({ entity }) => {
	const editorialSection =
		entity.GoodClass &&
		entity.GoodClass.editorialSections &&
		entity.GoodClass.editorialSections[0];

	const json: any = {
		'@context': 'http://schema.org',
		'@type': 'Product',
		name: jsonldStringEscape(entity.name),
		url: generateUrl(ENTITY.COMPANY, ACTIONS.DETAIL, entity.slug),
		category: editorialSection && editorialSection.name,
		sku: entity.id,

		aggregateRating: getAggregateRating(entity),
	};

	const description = entity.description || entity.descriptionLong;
	if (description) {
		json.description = jsonldStringEscape(description);
	}

	if (entity.featuredImageMedia) {
		json.image = [entity.featuredImageMedia.largeThumbUrl];
	}

	// Brand e' opzionale sul Good, quindi aggiungo gli elementi SEO solo se effettivamente esiste
	if (entity.Brand) {
		json.brand = {
			'@type': 'Thing',
			name: jsonldStringEscape(entity.Brand.name),
		};
		json.manufacturer = {
			'@type': 'Organization',
			name: jsonldStringEscape(entity.Brand.Company.name),
			logo:
				(entity.Brand &&
					entity.Brand.logoMedia &&
					entity.Brand.logoMedia.largeThumbUrl) ||
				(entity.Brand &&
					entity.Brand.Company &&
					entity.Brand.Company.logoMedia &&
					entity.Brand.Company.logoMedia.largeThumbUrl) ||
				'',
			url: generateUrl(ENTITY.COMPANY, ACTIONS.DETAIL, entity.Brand.Company.slug),
		};
	}

	// Google sembra non accettare il formato array quando c'è un solo elemento, quindi per unire GoodClass e
	// goodFeatureCertifications devo fare questo codice complicatp
	if (
		entity.GoodClass ||
		(Array.isArray(entity.goodFeatureCertifications) &&
			entity.goodFeatureCertifications.length > 0)
	) {
		const properties: any[] = [];

		if (entity.GoodClass && entity.GoodClass.preservation) {
			properties.push({
				'@type': 'PropertyValue',
				name: 'preservation mode',
				value: entity.GoodClass.preservation,
			});
		}
		if (
			Array.isArray(entity.goodFeatureCertifications) &&
			entity.goodFeatureCertifications.length > 0
		) {
			entity.goodFeatureCertifications.forEach((certification) =>
				properties.push({
					'@type': 'PropertyValue',
					name: certification.name,
					value: 'Yes',
				}),
			);
		}

		if (properties.length === 1) {
			json.additionalProperty = properties.length[0];
		} else if (properties.length > 1) {
			// questo controllo probabilmente e' inutile, ma preferisco essere sicuro
			json.additionalProperty = properties;
		}
	}

	return (
		<GenericHead
			title={entity.name}
			description={entity.description}
			image={entity.featuredImageMedia && entity.featuredImageMedia.largeThumbUrl}
			jsonld={[JSON.stringify(json)]}
		/>
	);
};
