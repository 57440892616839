import React from 'react';
import { Box, Button, ButtonLayouts, IconPositions } from '@food/ui';
import { registerClass, currySC } from '@food/css-manager';
import t from '../utils/labels';
import { IFNTheme } from '../utils/theme';
import { Company, Good, QuestionSetRequest } from '../server-types';
import { QuestionSetCreationModal } from './entities/QuestionSetCreationModal';

interface IContactSupplierBoxProps {
	company: Company;
	good?: Good;
	l1OrMore: boolean;
}

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	display: flex;
	width: 100%;
	justify-content: center;
	flex-direction: column;
	
	& > * {
		margin-bottom: .5rem;
		font-size: ${t.ratios.l}em;
	}
	
	.${sc('note-btn')} {
		width: 100%;
	}
`,
);

const sc = currySC(styleClass);

export const ContactSupplierBox: React.FC<IContactSupplierBoxProps> = ({
	company,
	good,
	l1OrMore,
}) => {
	const isCompany = good === undefined;

	return (
		<Box title={t`Actions`}>
			<div className={styleClass}>
				<QuestionSetCreationModal
					company={company}
					good={good}
					openComponent={({ opener }) => (
						<>
							<Button
								label={t`contact_us`}
								layout={ButtonLayouts.BUTTON}
								iconPos={IconPositions.RIGHT}
								icon={'people'}
								onClick={() => opener(t`contact_us`)}
								type={'success'}
								className={sc('note-btn')}
							/>
							{l1OrMore && (
								<Button
									label={t`request_catalogue`}
									type={'alt'}
									icon={'picture_as_pdf'}
									iconPos={IconPositions.RIGHT}
									onClick={() => opener(t`request_catalogue`, QuestionSetRequest.Catalog)}
								/>
							)}
							{l1OrMore && !isCompany && (
								<Button
									label={t`request_prices`}
									type={'alt'}
									iconPos={IconPositions.RIGHT}
									icon={'euro_symbol'}
									onClick={() => opener(t`request_prices`, QuestionSetRequest.Price)}
								/>
							)}
						</>
					)}
				/>
			</div>
		</Box>
	);
};
